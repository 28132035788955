import { TimeZoneEnum, CompanySizeEnum } from 'api';

const getCompanySizeLabel = (companySize: CompanySizeEnum) => {
  switch (companySize) {
    case CompanySizeEnum.From1to49:
      return '1 - 49';
    case CompanySizeEnum.From50to99:
      return '50 - 99';
    case CompanySizeEnum.From100to499:
      return '100 - 499';
    case CompanySizeEnum.From5to999:
      return '500 - 999';
    case CompanySizeEnum.From1000to4999:
      return '1000 - 4999';
    case CompanySizeEnum.Over5000:
      return '5000+';
  }
};

const getCompanySizeOptions = () => [
  {
    value: CompanySizeEnum.From1to49,
    label: getCompanySizeLabel(CompanySizeEnum.From1to49),
  },
  {
    value: CompanySizeEnum.From50to99,
    label: getCompanySizeLabel(CompanySizeEnum.From50to99),
  },
  {
    value: CompanySizeEnum.From100to499,
    label: getCompanySizeLabel(CompanySizeEnum.From100to499),
  },
  {
    value: CompanySizeEnum.From5to999,
    label: getCompanySizeLabel(CompanySizeEnum.From5to999),
  },
  {
    value: CompanySizeEnum.From1000to4999,
    label: getCompanySizeLabel(CompanySizeEnum.From1000to4999),
  },
  {
    value: CompanySizeEnum.Over5000,
    label: getCompanySizeLabel(CompanySizeEnum.Over5000),
  },
];

const getTimezoneLabel = (timezone: TimeZoneEnum) => {
  switch (timezone) {
    case TimeZoneEnum.UTC_Minus_12:
      return 'UTC-12:00 (International Date Line West)';
    case TimeZoneEnum.UTC_Minus_11:
      return 'UTC-11:00 (Coordinated Universal Time-11)';
    case TimeZoneEnum.UTC_Minus_10:
      return 'UTC-10:00 (Hawaii-Aleutian Standard Time)';
    case TimeZoneEnum.UTC_Minus_09:
      return 'UTC-09:00 (Alaska Standard Time)';
    case TimeZoneEnum.UTC_Minus_08:
      return 'UTC-08:00 (Pacific Standard Time)';
    case TimeZoneEnum.UTC_Minus_07:
      return 'UTC-07:00 (Mountain Standard Time)';
    case TimeZoneEnum.UTC_Minus_06:
      return 'UTC-06:00 (Central Standard Time)';
    case TimeZoneEnum.UTC_Minus_05:
      return 'UTC-05:00 (Eastern Standard Time)';
    case TimeZoneEnum.UTC_Minus_04:
      return 'UTC-04:00 (Atlantic Standard Time)';
    case TimeZoneEnum.UTC_Minus_03_30:
      return 'UTC-03:30 (Newfoundland Standard Time)';
    case TimeZoneEnum.UTC_Minus_03:
      return 'UTC-03:00 (Brasilia Standard Time)';
    case TimeZoneEnum.UTC_Minus_02:
      return 'UTC-02:00 (Coordinated Universal Time-02)';
    case TimeZoneEnum.UTC_Minus_01:
      return 'UTC-01:00 (Azores Standard Time)';
    case TimeZoneEnum.UTC_00:
      return 'UTC±00:00 (Greenwich Mean Time)';
    case TimeZoneEnum.UTC_Plus_01:
      return 'UTC+01:00 (Central European Time)';
    case TimeZoneEnum.UTC_Plus_02:
      return 'UTC+02:00 (Eastern European Time)';
    case TimeZoneEnum.UTC_Plus_03:
      return 'UTC+03:00 (Moscow Standard Time)';
    case TimeZoneEnum.UTC_Plus_03_30:
      return 'UTC+03:30 (Iran Standard Time)';
    case TimeZoneEnum.UTC_Plus_04:
      return 'UTC+04:00 (Azerbaijan Standard Time)';
    case TimeZoneEnum.UTC_Plus_04_30:
      return 'UTC+04:30 (Afghanistan Time)';
    case TimeZoneEnum.UTC_Plus_05:
      return 'UTC+05:00 (Pakistan Standard Time)';
    case TimeZoneEnum.UTC_Plus_05_30:
      return 'UTC+05:30 (Indian Standard Time)';
    case TimeZoneEnum.UTC_Plus_05_45:
      return 'UTC+05:45 (Nepal Time)';
    case TimeZoneEnum.UTC_Plus_06:
      return 'UTC+06:00 (Bangladesh Standard Time)';
    case TimeZoneEnum.UTC_Plus_06_30:
      return 'UTC+06:30 (Cocos Islands Time)';
    case TimeZoneEnum.UTC_Plus_07:
      return 'UTC+07:00 (Indochina Time)';
    case TimeZoneEnum.UTC_Plus_08:
      return 'UTC+08:00 (China Standard Time)';
    case TimeZoneEnum.UTC_Plus_09:
      return 'UTC+09:00 (Japan Standard Time)';
    case TimeZoneEnum.UTC_Plus_09_30:
      return 'UTC+09:30 (Australian Central Standard Time)';
    case TimeZoneEnum.UTC_Plus_10:
      return 'UTC+10:00 (Australian Eastern Standard Time)';
    case TimeZoneEnum.UTC_Plus_10_30:
      return 'UTC+10:30 (Lord Howe Standard Time)';
    case TimeZoneEnum.UTC_Plus_11:
      return 'UTC+11:00 (Norfolk Island Time)';
    case TimeZoneEnum.UTC_Plus_12:
      return 'UTC+12:00 (New Zealand Standard Time)';
    case TimeZoneEnum.UTC_Plus_12_45:
      return 'UTC+12:45 (Chatham Island Standard Time)';
    case TimeZoneEnum.UTC_Plus_13:
      return 'UTC+13:00 (Tonga Standard Time)';
    case TimeZoneEnum.UTC_Plus_14:
      return 'UTC+14:00 (Line Islands Time)';
  }
};

const getTimezoneOptions = () => [
  {
    value: TimeZoneEnum.UTC_Minus_12,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Minus_12),
  },
  {
    value: TimeZoneEnum.UTC_Minus_11,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Minus_11),
  },
  {
    value: TimeZoneEnum.UTC_Minus_10,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Minus_10),
  },
  {
    value: TimeZoneEnum.UTC_Minus_09,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Minus_09),
  },
  {
    value: TimeZoneEnum.UTC_Minus_08,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Minus_08),
  },
  {
    value: TimeZoneEnum.UTC_Minus_07,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Minus_07),
  },
  {
    value: TimeZoneEnum.UTC_Minus_06,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Minus_06),
  },
  {
    value: TimeZoneEnum.UTC_Minus_05,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Minus_05),
  },
  {
    value: TimeZoneEnum.UTC_Minus_04,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Minus_04),
  },
  {
    value: TimeZoneEnum.UTC_Minus_03_30,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Minus_03_30),
  },
  {
    value: TimeZoneEnum.UTC_Minus_03,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Minus_03),
  },
  {
    value: TimeZoneEnum.UTC_Minus_02,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Minus_02),
  },
  {
    value: TimeZoneEnum.UTC_Minus_01,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Minus_01),
  },
  {
    value: TimeZoneEnum.UTC_00,
    label: getTimezoneLabel(TimeZoneEnum.UTC_00),
  },
  {
    value: TimeZoneEnum.UTC_Plus_01,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Plus_01),
  },
  {
    value: TimeZoneEnum.UTC_Plus_02,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Plus_02),
  },
  {
    value: TimeZoneEnum.UTC_Plus_03,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Plus_03),
  },
  {
    value: TimeZoneEnum.UTC_Plus_03_30,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Plus_03_30),
  },
  {
    value: TimeZoneEnum.UTC_Plus_04,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Plus_04),
  },
  {
    value: TimeZoneEnum.UTC_Plus_04_30,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Plus_04_30),
  },
  {
    value: TimeZoneEnum.UTC_Plus_05,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Plus_05),
  },
  {
    value: TimeZoneEnum.UTC_Plus_05_30,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Plus_05_30),
  },
  {
    value: TimeZoneEnum.UTC_Plus_05_45,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Plus_05_45),
  },
  {
    value: TimeZoneEnum.UTC_Plus_06,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Plus_06),
  },
  {
    value: TimeZoneEnum.UTC_Plus_06_30,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Plus_06_30),
  },
  {
    value: TimeZoneEnum.UTC_Plus_07,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Plus_07),
  },
  {
    value: TimeZoneEnum.UTC_Plus_08,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Plus_08),
  },
  {
    value: TimeZoneEnum.UTC_Plus_09,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Plus_09),
  },
  {
    value: TimeZoneEnum.UTC_Plus_09_30,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Plus_09_30),
  },
  {
    value: TimeZoneEnum.UTC_Plus_10,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Plus_10),
  },
  {
    value: TimeZoneEnum.UTC_Plus_10_30,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Plus_10_30),
  },
  {
    value: TimeZoneEnum.UTC_Plus_11,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Plus_11),
  },
  {
    value: TimeZoneEnum.UTC_Plus_12,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Plus_12),
  },
  {
    value: TimeZoneEnum.UTC_Plus_12_45,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Plus_12_45),
  },
  {
    value: TimeZoneEnum.UTC_Plus_13,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Plus_13),
  },
  {
    value: TimeZoneEnum.UTC_Plus_14,
    label: getTimezoneLabel(TimeZoneEnum.UTC_Plus_14),
  },
];

const getCountryPhoneCode = (phoneCode?: string) => {
  switch (phoneCode) {
    case 'AF':
      return '93';
    case 'AX':
      return '--';
    case 'AL':
      return '355';
    case 'DZ':
      return '213';
    case 'AS':
      return '1-684';
    case 'AD':
      return '376';
    case 'AO':
      return '244';
    case 'AI':
      return '1-264';
    case 'AQ':
      return '672';
    case 'AG':
      return '1-268';
    case 'AR':
      return '54';
    case 'AM':
      return '374';
    case 'AW':
      return '297';
    case 'AU':
      return '61';
    case 'AT':
      return '43';
    case 'AZ':
      return '995';
    case 'BS':
      return '1-242';
    case 'BH':
      return '973';
    case 'BD':
      return '880';
    case 'BB':
      return '1-246';
    case 'BY':
      return '375';
    case 'BE':
      return '32';
    case 'BZ':
      return '501';
    case 'BJ':
      return '229';
    case 'BM':
      return '1-441';
    case 'BT':
      return '975';
    case 'BO':
      return '591';
    case 'BQ':
      return '591';
    case 'BA':
      return '387';
    case 'BW':
      return '267';
    case 'BV':
      return '--';
    case 'BR':
      return '55';
    case 'IO':
      return '246';
    case 'BN':
      return '--';
    case 'BG':
      return '359';
    case 'BF':
      return '226';
    case 'BI':
      return '257';
    case 'KH':
      return '855';
    case 'CM':
      return '237';
    case 'CA':
      return '1';
    case 'CV':
      return '238';
    case 'KY':
      return '1-345';
    //////
    case 'CF':
      return '236';
    case 'TD':
      return '235';
    case 'CL':
      return '56';
    case 'CN':
      return '86';
    case 'CX':
      return '61';
    case 'CC':
      return '61';
    case 'CO':
      return '57';
    case 'KM':
      return '269';
    case 'CG':
      return '242';
    case 'CD':
      return '243';
    case 'CK':
      return '682';
    case 'CR':
      return '506';
    case 'CI':
      return '--';
    case 'HR':
      return '385';
    case 'CU':
      return '53';
    case 'CW':
      return '599';
    case 'CY':
      return '357';
    case 'CZ':
      return '420';
    ////////
    case 'DK':
      return '45';
    case 'DJ':
      return '253';
    case 'DM':
      return '1-767';
    case 'DO':
      return '1-809';
    case 'EC':
      return '593';
    case 'EG':
      return '20';
    case 'SV':
      return '503';
    case 'GQ':
      return '240';
    case 'ER':
      return '291';
    case 'EE':
      return '372';
    case 'ET':
      return '251';
    case 'FK':
      return '500';
    case 'FO':
      return '298';
    case 'FJ':
      return '679';
    case 'FI':
      return '358';
    case 'FR':
      return '33';
    case 'GF':
      return '--';
    case 'PF':
      return '689';
    case 'TF':
      return '--';
    case 'GA':
      return '241';
    case 'GM':
      return '220';
    case 'GE':
      return '995';
    case 'DE':
      return '49';
    case 'GH':
      return '233';
    case 'GI':
      return '350';
    case 'GR':
      return '30';
    case 'GL':
      return '299';
    case 'GD':
      return '1-473';
    case 'GP':
      return '--';
    case 'GU':
      return '1-671';
    case 'GT':
      return '502';
    case 'GG':
      return '44-1481';
    case 'GN':
      return '224';
    case 'GW':
      return '245';
    case 'GY':
      return '592';
    case 'HT':
      return '509';
    case 'HM':
      return '--';
    case 'VA':
      return '379';
    case 'HN':
      return '504';
    case 'HK':
      return '852';
    default:
      return undefined;
  }
};

export {
  getCompanySizeOptions,
  getCompanySizeLabel,
  getTimezoneOptions,
  getCountryPhoneCode,
};
