import React from 'react';

import styles from 'components/search/SearchResults/EmptySearchResults/EmptySearchResults.module.less';

export default function EmptySearchResults() {
  return (
    <div className={styles.empty}>
      <div className={styles.emptyTitle}>No items matched your search.</div>
    </div>
  );
}
