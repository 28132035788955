import React, { memo, useCallback, useState } from 'react';
import { App, Button, Space } from 'antd';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import { StageStatusEnum } from 'api';
import { SetStageStatusModel } from 'redux/reducers/progressTrackerSlice';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

type Props = {
  status?: StageStatusEnum;
  id: string;
  onStageAction: (data: SetStageStatusModel) => Promise<void>;
  isAnyStageEnded?: boolean;
};

const checkIsEndStatus = (status?: StageStatusEnum) =>
  status === StageStatusEnum.End;

export default memo((props: Props) => {
  const { status, id, onStageAction, isAnyStageEnded } = props;
  const [isStageProcessing, setIsStageProcessing] = useState(false);
  const [isStageEnding, setIsStageEnding] = useState(false);
  const isLightTheme = useSelector(isLightThemeSelector);
  const { modal } = App.useApp();
  const isStageStatusEnd = checkIsEndStatus(status);

  const isStageStatusChanging = isStageEnding || isStageProcessing;

  const updateStageLoadingStatus = (
    isEndStage: boolean,
    isLoading: boolean,
  ) => {
    if (isEndStage) {
      setIsStageEnding(isLoading);
    } else {
      setIsStageProcessing(isLoading);
    }
  };

  const processStageAction = useCallback(
    async (status: StageStatusEnum) => {
      const isEndStatus = checkIsEndStatus(status);

      updateStageLoadingStatus(isEndStatus, true);

      await onStageAction({ stageId: id, status });

      updateStageLoadingStatus(isEndStatus, false);
    },
    [onStageAction, id, isStageStatusEnd],
  );

  const handleStageCompletion = async () => {
    if (isStageStatusChanging || isAnyStageEnded) return;

    await processStageAction(StageStatusEnum.Done);
  };

  const handleStageResetting = async (isStartStage?: boolean) => {
    if (isStageStatusChanging || (!isStartStage && isAnyStageEnded)) return;

    if (isStartStage) {
      await processStageAction(StageStatusEnum.Pending);
      return;
    }

    return modal.confirm({
      title: 'Are you sure you want to undo the stage completion?',
      icon: <span className="icon icon-info-circle confirm-icon" />,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      width: '496px',
      async onOk() {
        await processStageAction(StageStatusEnum.Pending);
      },
      onCancel() {},
    });
  };

  const handleEndStage = async () => {
    if (isStageStatusChanging || isAnyStageEnded) return;

    modal.confirm({
      content: 'Are you sure that you want to end this step?',
      icon: <span className="icon icon-info-circle confirm-icon" />,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),

      width: '496px',
      cancelText: 'Cancel',
      okText: 'End',
      async onOk() {
        await processStageAction(StageStatusEnum.End);
      },
    });
  };

  return (
    <div>
      {status === StageStatusEnum.Pending && (
        <Space direction="horizontal">
          <Button
            type="primary"
            size="middle"
            loading={isStageProcessing}
            onClick={handleStageCompletion}
            disabled={isStageEnding || isAnyStageEnded}
            className={cx({
              'disabled-btn': isAnyStageEnded && isLightTheme,
              'disabled-btn-light': isAnyStageEnded && !isLightTheme,
            })}
          >
            Complete
          </Button>
          {!isAnyStageEnded && (
            <Button
              size="middle"
              disabled={isStageProcessing}
              onClick={handleEndStage}
              loading={isStageEnding}
              className="end-btn"
            >
              End
            </Button>
          )}
        </Space>
      )}

      {status === StageStatusEnum.Done && (
        <Button
          size="middle"
          loading={isStageProcessing}
          disabled={isAnyStageEnded}
          onClick={() => handleStageResetting()}
          className={cx({
            'dark-btn': !isLightTheme,
            'disabled-btn': isAnyStageEnded && isLightTheme,
            'disabled-btn-light': isAnyStageEnded && !isLightTheme,
          })}
        >
          Reset
        </Button>
      )}

      {isStageStatusEnd && (
        <Button
          size="middle"
          loading={isStageProcessing}
          onClick={() => handleStageResetting(true)}
          className="start-btn"
        >
          Restart
        </Button>
      )}
    </div>
  );
});
