import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { App, Button, Pagination, Spin } from 'antd';
import { nanoid } from '@reduxjs/toolkit';
import { isNil } from 'lodash';

import { AppDispatch } from 'redux/store';
import {
  deactivateAllUserSessions,
  getUserSessions,
  deactivateUserSession,
} from 'redux/reducers/authenticationSlice';
import { PageDefaultSize } from 'constants/ApiConstant';
import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { userSelector } from 'redux/selectors/authenticationSelectors';
import { UserSessionResponseModel } from 'api';
import { getLocation } from 'utils/locationUtils';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

import styles from './UserSessionsSection.module.less';

export default function UserSessionsSection() {
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [userSessionList, setUserSessionList] = useState<
    UserSessionResponseModel[]
  >([]);
  const [isUserSessionsLoaded, setIsUserSessionsLoaded] = useState(false);
  const userInfo = useSelector(userSelector);
  const dispatch: AppDispatch = useDispatch();
  const { modal } = App.useApp();
  const isLightTheme = useSelector(isLightThemeSelector);

  const loadUserSessionList = async (pageNumber: number) => {
    const result = await dispatch(
      getUserSessions({
        pageSize: PageDefaultSize,
        pageNumber: pageNumber - 1,
        userId: userInfo?.id || '',
      }),
    );

    if (getUserSessions.fulfilled.match(result)) {
      setTotalCount(result.payload.totalCount || 0);
      setUserSessionList(result.payload.details || []);
      setCurrentPage(pageNumber);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
    setIsUserSessionsLoaded(true);
  };

  useEffect(() => {
    loadUserSessionList(1);
  }, []);

  const onEndSession = useCallback(
    async (sessionId: number) => {
      const result = await dispatch(deactivateUserSession(sessionId));

      if (deactivateUserSession.fulfilled.match(result)) {
        await loadUserSessionList(1);
      } else {
        AlertMessage.error(result.error.message || ApiErrorMessage);
      }
    },
    [dispatch],
  );

  const onEndSessions = useCallback(async () => {
    const result = await dispatch(deactivateAllUserSessions());

    if (deactivateAllUserSessions.fulfilled.match(result)) {
      await loadUserSessionList(1);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  }, [dispatch]);

  const onConfirmEndSession = useCallback((sessionId?: number) => {
    if (isNil(sessionId)) return;

    modal.confirm({
      content: <div>The session will be logged out. Continue?</div>,
      icon: <span className="icon icon-info-circle confirm-icon" />,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      width: '496px',
      cancelText: 'Cancel',
      okText: 'Yes',
      onOk: async () => await onEndSession(sessionId),
    });
  }, []);

  const onConfirmEndSessions = useCallback(() => {
    modal.confirm({
      content: <div>All sessions will be logged out. Continue?</div>,
      icon: <span className="icon icon-info-circle confirm-icon" />,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      width: '496px',
      cancelText: 'Cancel',
      okText: 'Yes',
      onOk: async () => await onEndSessions(),
    });
  }, []);

  return (
    <>
      {!isUserSessionsLoaded && <Spin />}
      <div className={cx(styles.title, styles.box)}>
        <div>Recent activity</div>

        <div>
          <Button
            onClick={onConfirmEndSessions}
            className={cx({ 'dark-btn-outline': !isLightTheme })}
          >
            End all sessions
          </Button>
        </div>
      </div>

      <div className={styles.activityWrap}>
        <table className={styles.activityTable}>
          <thead>
            <tr>
              <th>Status</th>
              <th>Device</th>
              <th>Location</th>
              <th>IP address</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {userSessionList.map((session) => (
              <tr key={nanoid()}>
                <td
                  className={cx({
                    [styles.active]: session.isActive,
                  })}
                >
                  {session.isActive ? 'Active' : 'Inactive'}
                </td>
                <td>{session.operationSystem}</td>
                <td>{getLocation(session.city, session.country)}</td>
                <td>{session.ipAddress}</td>
                <td>
                  {session.isActive && (
                    <div className={styles.btn}>
                      <Button
                        onClick={() => onConfirmEndSession(session.id)}
                        className={cx({ 'dark-btn-outline': !isLightTheme })}
                      >
                        End session
                      </Button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className={styles.pagination}>
        <Pagination
          current={currentPage}
          pageSize={20}
          total={totalCount}
          onChange={loadUserSessionList}
          showSizeChanger={false}
        />
      </div>
    </>
  );
}
