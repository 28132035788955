import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from './rootReducer';
import apiClient, { CountryModel } from 'api';

interface RegionState {
  countries: CountryModel[];
}

const initialState: RegionState = {
  countries: [],
};

export const getAllCountries = createAsyncThunk(
  'region/getAllCountries',
  async () => {
    return apiClient.getCountries();
  },
  {
    condition: (_, { getState }) => {
      const { region } = getState() as RootState;

      return !region.countries.length;
    },
  },
);

const regionSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllCountries.fulfilled, (state, action) => {
      state.countries = action.payload;
    });
  },
});

const { reducer } = regionSlice;
export default reducer;
