import React, { useState } from 'react';
import { Button, Divider, Form, Input } from 'antd';
import cx from 'classnames';
import queryString from 'query-string';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  LoginRequestModel,
  TokenResponseModel,
  TwoFACheckResponseModel,
} from 'api';
import AlertMessage from 'components/AlertMessage';
import Controller from 'components/form/Controller';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import TwoFALoginSection from 'features/auth/SignInPage/TwoFALoginSection';
import { getUserInfo, login } from 'redux/reducers/authenticationSlice';
import { getSpaceRole } from 'redux/reducers/roleSlice';
import { getAvailableSpace } from 'redux/reducers/spaceSlice';
import { AppDispatch } from 'redux/store';
import { fetchIpAddress } from 'utils/authUtils';

import commonStyles from '../auth.module.less';
import styles from './SignInPage.module.less';

import imgLogo from 'assets/img/landing/apdated-logo.svg';

interface SingInFormValues {
  email: string;
  password: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('Email is invalid')
    .max(120, 'Email should be 120 characters maximum')
    .required('Field is required'),
  password: Yup.string()
    .trim()
    .max(50, 'Password should be 50 characters maximum')
    .required('Field is required'),
});

export default function SignInPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const [twoFAInfo, setTwoFAInfo] = useState<TwoFACheckResponseModel>();

  const { email: emailQuery } = queryString.parse(location.search);

  const { handleSubmit, control, errors } = useForm<SingInFormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: {
      email: emailQuery ? decodeURIComponent(emailQuery as string) : '',
      password: '',
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const getInitialData = async () => {
    await dispatch(getAvailableSpace());
    await dispatch(getUserInfo());
    await dispatch(getSpaceRole());
  };

  const onSubmit = async (values: SingInFormValues) => {
    if (isLoading) return;
    setIsLoading(true);

    const userIpAddress = await fetchIpAddress();

    const data: LoginRequestModel = {
      email: values?.email.trim(),
      password: values?.password.trim(),
      ip: userIpAddress || '',
    };

    const result = await dispatch(login(data));

    if (login.fulfilled.match(result)) {
      const twoFaInfo = (result.payload as TokenResponseModel)?.twoFAModel;

      if (twoFaInfo) {
        setTwoFAInfo(twoFaInfo);
        return setIsLoading(false);
      }

      await getInitialData();
      setIsLoading(false);
      navigate('/', { replace: true });
    } else {
      setIsLoading(false);
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  return (
    <div className={commonStyles.authPage}>
      <div className={commonStyles.authWrap}>
        <div className={commonStyles.authRow}>
          <div className={commonStyles.authForm}>
            <div className={commonStyles.authFormWrap}>
              <div className={commonStyles.authFormBox}>
                <div className={commonStyles.authFormLogo}>
                  <img src={imgLogo} alt="logo" />
                </div>

                <div className={commonStyles.authFormTitle}>Log In</div>

                {!twoFAInfo && (
                  <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
                    <Controller
                      name="email"
                      control={control}
                      as={Input}
                      label="Email address"
                      size="large"
                      error={errors.email}
                      containerClassName="dark-input-group"
                    />

                    <Divider className="dark-divider mt-0" />

                    <Controller
                      name="password"
                      label="Password"
                      size="large"
                      control={control}
                      as={Input.Password}
                      error={errors.password}
                      containerClassName="dark-input-group"
                    />

                    <Link to="/forgot-password" className={styles.authLink}>
                      Forgot password?
                    </Link>

                    <div
                      className={cx(commonStyles.authBtnBox, styles.authBtnBox)}
                    >
                      <div className={commonStyles.authBtn}>
                        <Button
                          htmlType="submit"
                          block
                          type="primary"
                          loading={isLoading}
                        >
                          <h1>Log in</h1>
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}

                {twoFAInfo && <TwoFALoginSection twoFAInfo={twoFAInfo} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
