import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import AuthRoute from 'components/routes/AuthRoute';
import PrivateRoute from 'components/routes/PrivateRoute';
import ProgressTrackerCreatePage from 'features/progressTracker/ProgressTrackerCreatePage';
import ProgressTrackerDetailsPage from 'features/progressTracker/ProgressTrackerDetailsPage';
import SharedProgressTrackerDetailsPage from 'features/progressTracker/SharedProgressTrackerDetailsPage';
import HomePage from 'features/home/HomePage';
import ProgressTrackerEditPage from 'features/progressTracker/ProgressTrackerEditPage';
import SearchPage from 'features/search/SearchPage';
import EmbeddedPTPage from 'features/embedded/EmbeddedPTPage';
import EmbeddedSubscribePage from 'features/embedded/EmbeddedSubscribePage';
import ProjectPage from 'features/project/ProjectPage';
import SignInPage from 'features/auth/SignInPage';
import SignUpPage from 'features/auth/SignUpPage';
import TermsAndConditions from 'features/termsAndConditions';
import ConfirmationPage from 'features/auth/ConfirmationPage';
import ForgotPasswordPage from 'features/auth/ForgotPasswordPage';
import TemplateDetailsPage from 'features/template/TemplateDetailsPage';
import TemplateCreatePage from 'features/template/TemplateCreatePage';
import TemplateEditPage from 'features/template/TemplateEditPage';
import ResetPasswordPage from 'features/auth/ResetPasswordPage';
import InvitePage from 'features/invite/InvitePage';
import NotFoundPage from 'components/NotFoundPage';
import ArchivedTrackersPage from './features/trackers/ArchivedTrackersPage';
import { RolesEnum } from 'enums/Roles.enum';
import AdminTrackersPage from 'features/admin/AdminTrackersPage';
import UserListPage from 'features/admin/UserListPage';
import AccountSettingsPage from 'features/account/AccountSettingsPage';
import BillingSettingsPage from 'features/auth/BillingSettingsPage';
import DeletedTrackersPage from 'features/trackers/DeletedTrackersPage';
import UnsubscribeFromUpdate from 'features/progressTracker/UnsubscribeFromUpdate';
import SpacesPage from 'features/admin/SpacesPage';
import TransactionsPage from 'features/admin/TransactionsPage';
import PricingPage from 'features/PricingPage';
import CancellationHistoryPage from 'features/admin/CancellationHistoryPage';
import UseTemplatesPage from 'features/template/UseTemplatesPage';
import UserDetailsPage from 'features/admin/UserDetailsPage';
import TeamManagementPage from 'features/project/TeamManagementPage';
import { UserSpaceRoleEnum } from 'api';
import TemplateLibraryDetailsPage from 'features/template/TemplateLibraryDetailsPage';
import TemplateListPage from 'features/template/TemplateListPage';
import AnalyticsPage from 'features/analytics/AnalyticsPage';

function Router() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />

      <Route
        path="/ptdetails/unsubscribe/:subscribeId"
        element={<UnsubscribeFromUpdate />}
      />

      <Route
        element={
          <PrivateRoute
            allowedRoles={[RolesEnum.Customer]}
            teammateRoles={[UserSpaceRoleEnum.SuperAdmin]}
          />
        }
      >
        <Route
          path="/team-management/:projectId"
          element={<TeamManagementPage />}
        />
      </Route>

      <Route element={<PrivateRoute allowedRoles={[RolesEnum.Customer]} />}>
        <Route
          path="/project/:projectId/tracker-create"
          element={<ProgressTrackerCreatePage />}
        />

        <Route
          path="/project/:projectId/tracker-edit/:progressTrackerId"
          element={<ProgressTrackerEditPage />}
        />

        <Route path="/project/:projectId" element={<ProjectPage />} />

        <Route
          path="/template/:templateId/details"
          element={<TemplateDetailsPage />}
        />

        <Route
          path="/template/:templateId/use"
          element={<UseTemplatesPage />}
        />

        <Route path="/archived-trackers" element={<ArchivedTrackersPage />} />

        <Route path="/billing-settings" element={<BillingSettingsPage />} />

        <Route path="/deleted-folder" element={<DeletedTrackersPage />} />

        <Route path="/analytics" element={<AnalyticsPage />} />
      </Route>

      <Route
        element={
          <PrivateRoute allowedRoles={[RolesEnum.Customer, RolesEnum.Admin]} />
        }
      >
        <Route
          path="/template/:templateId/edit"
          element={<TemplateEditPage />}
        />

        <Route path="/template/create" element={<TemplateCreatePage />} />

        <Route path="/templates" element={<TemplateListPage />} />

        <Route
          path="/template-library/:templateId/details"
          element={<TemplateLibraryDetailsPage />}
        />

        <Route path="/account-settings" element={<AccountSettingsPage />} />

        <Route
          path="/details/:progressTrackerId"
          element={<ProgressTrackerDetailsPage />}
        />
      </Route>

      <Route element={<AuthRoute />}>
        <Route path="/login" element={<SignInPage />} />

        <Route path="/sign_up" element={<SignUpPage />} />

        <Route path="/forgot-password" element={<ForgotPasswordPage />} />

        <Route path="/pricing" element={<PricingPage />} />
      </Route>

      <Route element={<PrivateRoute allowedRoles={[RolesEnum.Admin]} />}>
        <Route path="/trackers" element={<AdminTrackersPage />} />

        <Route path="/users" element={<UserListPage />} />

        <Route path="/user/:id" element={<UserDetailsPage />} />

        <Route path="/spaces" element={<SpacesPage />} />

        <Route path="/transactions" element={<TransactionsPage />} />

        <Route
          path="/cancelation-history"
          element={<CancellationHistoryPage />}
        />
      </Route>

      <Route
        path="/ptdetails/:progressTrackerId"
        element={<SharedProgressTrackerDetailsPage />}
      />

      <Route path="/search" element={<SearchPage />} />

      <Route
        path="/embedded-pt/:progressTrackerId"
        element={<EmbeddedPTPage />}
      />

      <Route
        path="/embedded-subscribe/:progressTrackerId"
        element={<EmbeddedSubscribePage />}
      />

      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />

      <Route path="/confirmation" element={<ConfirmationPage />} />

      <Route path="/reset-password" element={<ResetPasswordPage />} />

      <Route path="/invite" element={<InvitePage />} />

      <Route path="/not-found" element={<NotFoundPage />} />

      <Route path="*" element={<Navigate to="/not-found" />} />
    </Routes>
  );
}

export default React.memo(Router);
