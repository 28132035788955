import React from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { isNil } from 'lodash';

interface RenderCustomizedLabelProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
  value: number;
  payload: any;
}

interface DataModel {
  name: string;
  value: number;
  textColor: string;
}

const RADIAN = Math.PI / 180;

const innerRadius = 40;
const outerRadius = 94;

type Props = {
  data: DataModel[];
  colors: string[];
  strokeColors: string[];
};

export default function CustomHalfPieChart(props: Props) {
  const { data, colors: COLORS, strokeColors: STROKE_COLORS } = props;

  const renderCustomizedLabel = (props: RenderCustomizedLabelProps) => {
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      payload: { payload },
    } = props;

    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const hasValue = !isNil(payload.value) && payload.value !== 0;

    if (!hasValue) return;

    return (
      <text
        x={x}
        y={y}
        fill={payload.textColor}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {payload.value}
      </text>
    );
  };

  return (
    <ResponsiveContainer width={250} height={220}>
      <PieChart width={250} height={220}>
        <Pie
          data={data}
          dataKey="value"
          startAngle={180}
          endAngle={0}
          innerRadius={innerRadius - 15}
          outerRadius={outerRadius + 15}
          paddingAngle={1.5}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={STROKE_COLORS[index % COLORS.length]}
              strokeWidth={0}
            />
          ))}
        </Pie>

        <Pie
          data={data}
          startAngle={180}
          endAngle={0}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          paddingAngle={0}
          dataKey="value"
          label={renderCustomizedLabel}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={COLORS[index % COLORS.length]}
              strokeWidth={0}
            />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}
