import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import apiClient, {
  PreSetupTemplateEditRequestModel,
  PreSetupTemplateRequestModel,
  ProgressTrackerTemplateEditRequestModel,
  ProgressTrackerTemplateRequestModel,
  SortFieldEnum,
  SortOrderEnum,
} from 'api';
import { currentSpaceIdByStoreSelector } from 'redux/selectors/spacesSelectors';
import { ErrorType } from 'utils/toastUtils';

interface TemplateState {
  isTemplateFormDirty: boolean;
}

export interface ProgressTrackerCardRequestModelPaged {
  sortField: SortFieldEnum;
  sortOrder: SortOrderEnum;
  name?: string;
  pageNumber: number;
  pageSize: number;
  category?: number[];
  isDraft?: boolean;
}

const initialState: TemplateState = {
  isTemplateFormDirty: false,
};

export const getTemplates = createAsyncThunk(
  'template/getTemplates',
  async ({
    sortField,
    sortOrder,
    name,
    pageNumber,
    pageSize,
    isDraft,
  }: ProgressTrackerCardRequestModelPaged) => {
    return apiClient.getProgressTrackerTemplates(
      sortField,
      sortOrder,
      name,
      isDraft,
      pageSize,
      pageNumber,
      currentSpaceIdByStoreSelector(),
    );
  },
);

export interface PreSetupTemplatesModel {
  sortOrder: SortOrderEnum;
  name?: string;
  pageNumber: number;
  pageSize: number;
  categories?: number[];
  isAdmin: boolean;
}

export const getPreSetupTemplates = createAsyncThunk(
  'template/getPreSetupTemplates',
  async ({
    sortOrder,
    name,
    pageNumber,
    pageSize,
    categories,
    isAdmin,
  }: PreSetupTemplatesModel) => {
    return isAdmin
      ? apiClient.getPreSetupTemplatesByAdmin(
          sortOrder,
          name,
          categories,
          pageSize,
          pageNumber,
        )
      : apiClient.getPreSetupTemplates(
          sortOrder,
          name,
          categories,
          pageSize,
          pageNumber,
        );
  },
);

export const getPreSetupProgressTrackerTemplateDetails = createAsyncThunk(
  'template/getPreSetupProgressTrackerTemplateDetails',
  async (progressTrackerTemplateId: string) => {
    return await apiClient.getPreSetupProgressTrackerTemplateDetails(
      progressTrackerTemplateId,
    );
  },
);

export const getPreSetupProgressTrackerTemplateDetailsByAdmin =
  createAsyncThunk(
    'template/getPreSetupProgressTrackerTemplateDetailsByAdmin',
    async (progressTrackerTemplateId: string) => {
      return await apiClient.getPreSetupProgressTrackerTemplateDetailsByAdmin(
        progressTrackerTemplateId,
      );
    },
  );

export const getTemplateDetails = createAsyncThunk(
  'template/getTemplateDetails',
  async (progressTrackerTemplateId: string) => {
    return apiClient.getProgressTrackerTemplateDetails(
      progressTrackerTemplateId,
    );
  },
);

export const createTemplate = createAsyncThunk(
  'template/createTemplate',
  async (data: ProgressTrackerTemplateRequestModel) => {
    return apiClient.createProgressTrackerTemplate({
      ...data,
      spaceId: currentSpaceIdByStoreSelector(),
    });
  },
);

export const createPreSetupTemplate = createAsyncThunk<
  string,
  any,
  { rejectValue: ErrorType }
>(
  'template/createPreSetupTemplate',
  async (data: PreSetupTemplateRequestModel, { rejectWithValue }) => {
    try {
      return apiClient.createPreSetupTemplate(data);
    } catch (e: any) {
      return rejectWithValue(e);
    }
  },
);

export const updatePreSetupTemplate = createAsyncThunk<
  string,
  any,
  { rejectValue: ErrorType }
>(
  'template/updatePreSetupTemplate',
  async (data: PreSetupTemplateEditRequestModel, { rejectWithValue }) => {
    try {
      return apiClient.updatePreSetupTemplate(data);
    } catch (e: any) {
      return rejectWithValue(e);
    }
  },
);

export const deletePreSetupTemplate = createAsyncThunk(
  'template/deletePreSetupTemplate',
  async (templateId: string) => {
    return apiClient.deletePreSetupTemplate(templateId);
  },
);

export const deleteProgressTrackerTemplate = createAsyncThunk(
  'template/deleteProgressTrackerTemplate',
  async (templateId: string) => {
    return apiClient.deleteProgressTrackerTemplate(templateId);
  },
);

export const updateTemplate = createAsyncThunk(
  'template/updateTemplate',
  async (data: ProgressTrackerTemplateEditRequestModel) => {
    return apiClient.updateProgressTrackerTemplate(data);
  },
);

export const isReusableTemplateAvailable = createAsyncThunk(
  'template/isReusableTemplateAvailable',
  async () => {
    return apiClient.isReusableTemplateAvailable(
      currentSpaceIdByStoreSelector(),
    );
  },
);

const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    setTemplateFormDirty: (state, action: PayloadAction<boolean>) => {
      state.isTemplateFormDirty = action.payload;
    },
  },
});

const { reducer, actions } = templateSlice;

export const { setTemplateFormDirty } = actions;

export default reducer;
