import { useState } from 'react';
import { Button, Statistic } from 'antd';
import { useDispatch } from 'react-redux';

import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import {
  generateTwoFACodeForActivation,
  generateTwoFACodeForLogin,
} from 'redux/reducers/authenticationSlice';
import { AppDispatch } from 'redux/store';
import { TwoFATypeEnum } from 'api';

import styles from './ButtonWithTimer.module.less';

export enum ButtonWithTimerMode {
  Enable2FA = 1,
  Login = 2,
}

type Props = {
  mode?: ButtonWithTimerMode;
  codeId?: string;
  setCodeId: (codeId: string) => void;
};

export default function ButtonWithTimer(props: Props) {
  const { mode = ButtonWithTimerMode.Login, codeId, setCodeId } = props;
  const [timerValue, setTimerValue] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  const turnOnTimer = () => {
    setTimerValue(Date.now() + 30000);
  };

  const turnOffTimer = () => {
    setTimerValue(undefined);
  };

  const handleResendCode = async () => {
    setIsLoading(true);
    let action;
    let result;

    switch (mode) {
      case ButtonWithTimerMode.Enable2FA:
        action = generateTwoFACodeForActivation;
        result = await dispatch(
          generateTwoFACodeForActivation(TwoFATypeEnum.Email),
        );
        break;
      case ButtonWithTimerMode.Login:
        action = generateTwoFACodeForLogin;
        result = await dispatch(generateTwoFACodeForLogin(codeId || ''));
        break;
    }

    setIsLoading(false);

    if (action.fulfilled.match(result)) {
      turnOnTimer();
      setCodeId(result.payload.codeId || '');
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  return (
    <>
      {timerValue && (
        <div className={styles.newCode}>
          <i className="icon icon-check-filled" />
          <div>New code sent</div>
        </div>
      )}

      <div>
        <Button
          onClick={handleResendCode}
          disabled={Boolean(timerValue)}
          loading={isLoading}
          className="timer-btn"
        >
          <i className="icon icon-resend" />
          <span>Resend</span>
          {timerValue && (
            <Statistic.Countdown
              format="ss"
              value={timerValue}
              onFinish={turnOffTimer}
              prefix={<span>(</span>}
              suffix={<span>)</span>}
            />
          )}
        </Button>
      </div>
    </>
  );
}
