import React, { useMemo, useState } from 'react';
import { isArray, isNil, unset } from 'lodash';
import queryString from 'query-string';
import { Link, useLocation } from 'react-router-dom';
import { nanoid } from '@reduxjs/toolkit';

import { SortOrderEnum, UserListResponseModel, UserSortFieldEnum } from 'api';
import Breadcrumb from 'components/Breadcrumb';
import SearchInput from 'components/filters/SearchInput';
import SortingFilter, {
  SortingFilterValueType,
  SortingMode,
} from 'components/filters/SortingFilter';
import UserCard from './UserCard';
import { PageDefaultSize } from 'constants/ApiConstant';
import SearchLayout, {
  ChildrenProps,
  SearchLayoutMode,
} from 'components/search/SearchLayout';

enum UsersFiltersTypes {
  Term = 'Term',
  Sorting = 'sorting',
}

interface UsersFilters {
  [UsersFiltersTypes.Term]: string;
  [UsersFiltersTypes.Sorting]: SortingFilterValueType;
}

export default function UserListPage() {
  const location = useLocation();
  const { term, sortField, sortOrder } = queryString.parse(location.search);

  const initialValues: UsersFilters = {
    [UsersFiltersTypes.Term]: !isArray(term) && !isNil(term) ? term : '',
    [UsersFiltersTypes.Sorting]: {
      sortField:
        !isArray(sortField) && !isNil(sortField)
          ? +sortField
          : UserSortFieldEnum.FirstName,
      sortOrder:
        !isArray(sortOrder) && !isNil(sortOrder)
          ? +sortOrder
          : SortOrderEnum.Asc,
    },
  };

  const [filters, setFilters] = useState<UsersFilters>(initialValues);

  const handleSearch = (query: string) => {
    setFilters({
      ...filters,
      [UsersFiltersTypes.Term]: query,
    });
  };

  const handleSort = (sortParams: SortingFilterValueType) => {
    setFilters({
      ...filters,
      [UsersFiltersTypes.Sorting]: sortParams,
    });
  };

  const renderBreadcrumbs = () => (
    <Breadcrumb
      routes={[
        {
          title: <i className="icon icon-home" />,
          link: '/',
        },
        { title: 'User List' },
      ]}
    />
  );

  const renderData = (
    childrenProps: ChildrenProps<UserListResponseModel, any>,
  ) => (
    <div className="ticket-list">
      {childrenProps.details.map((user: UserListResponseModel) => (
        <Link to={`/user/${user.id}`} className="ticket-box" key={nanoid()}>
          <UserCard user={user} />
        </Link>
      ))}
    </div>
  );

  const renderSearchInput = () => (
    <SearchInput
      onChange={handleSearch}
      value={filters[UsersFiltersTypes.Term]}
      placeholder="Search users"
    />
  );

  const renderFilters = () => (
    <div>
      <SortingFilter
        value={filters[UsersFiltersTypes.Sorting]}
        onChange={handleSort}
        mode={SortingMode.Users}
      />
    </div>
  );

  const resultFilters = useMemo(() => {
    const newFilters = {
      query: filters[UsersFiltersTypes.Term],
      ...filters,
    };
    unset(newFilters, [UsersFiltersTypes.Term]);
    return newFilters;
  }, [filters]);

  return (
    <SearchLayout
      mode={SearchLayoutMode.Users}
      filters={resultFilters}
      minPageSize={PageDefaultSize}
      renderBreadcrumbsNode={renderBreadcrumbs}
      searchNode={renderSearchInput()}
      filtersNode={renderFilters()}
      columns={1}
    >
      {renderData}
    </SearchLayout>
  );
}
