import React from 'react';
import cx from 'classnames';
import Pagination, { LandingSectionsEnum } from '../Pagination/Pagination';

import styles from './KeepClientsInformedSection.module.less';
import commonStyles from '../../LandingPage/LandingPage.module.less';

export default function KeepClientsInformedSection() {
  return (
    <section
      className={styles.clientSection}
      id={LandingSectionsEnum.KeepClientsInformed}
    >
      <div className={commonStyles.container}>
        <div className={cx(styles.title, commonStyles.title)}>
          <h2>
            Keep your clients informed each <span>step of the way</span>
          </h2>
        </div>

        <ul className={styles.list}>
          <li>
            <div className={styles.listCount}>
              <i className="icon icon-triangle"></i>
            </div>
            <div className={styles.listText}>
              US Companies lose more than <strong>$62B</strong> annually due to
              poor customer service.
            </div>
          </li>
          <li>
            <div className={styles.listCount}>
              <i className="icon icon-triangle"></i>
            </div>
            <div className={styles.listText}>
              <strong> Lack of communication</strong> is one of the top reasons
              why customers feel frustrated.
            </div>
          </li>
          <li>
            <div className={styles.listCount}>
              <i className="icon icon-triangle"></i>
            </div>
            <div className={styles.listText}>
              Our software lets you manage the expectations of your customers &{' '}
              <strong>keep them informed </strong> at every step.
            </div>
          </li>
        </ul>
      </div>

      <Pagination
        className={styles.pagination}
        activeSection={LandingSectionsEnum.KeepClientsInformed}
      />
    </section>
  );
}
