import React from 'react';


import HeaderSection from './HeaderSection';
import KeepClientsInformedSection from './KeepClientsInformedSection';
import CustomizeSection from './CustomizeSection';
import BusinessGrowthSection from './BusinessGrowthSection';
// import EmbedItSection from './EmbedItSection';
import ScheduleUpdatesSection from './ScheduleUpdatesSection';
import InformYourClientSection from './InformYourClientSection';
import RequestDemoSection from './RequestDemoSection';
import AppFooter from 'components/AppLayout/AppFooter';

import styles from './LandingPage.module.less';

export default function LandingPage() {
  return (
    <>
      <div className={styles.page}>
        <HeaderSection />
        <KeepClientsInformedSection />
        <CustomizeSection />
        <BusinessGrowthSection />
        {/* <EmbedItSection /> */}
        <ScheduleUpdatesSection />
        <InformYourClientSection />
        <RequestDemoSection />
        <AppFooter />
      </div>
    </>
  );
}
