import { createSelector } from '@reduxjs/toolkit';

import { isProgressTrackerFormDirtySelector } from 'redux/selectors/progressTrackerSelectors';
import { isTemplateFormDirtySelector } from 'redux/selectors/templateSelectors';
import {
  isNewCreditCardFormDirtySelector,
  isProfileFormDirtySelector,
} from 'redux/selectors/aссountSelectors';

export const isRedirectBlockedSelector = createSelector(
  isProgressTrackerFormDirtySelector,
  isTemplateFormDirtySelector,
  isNewCreditCardFormDirtySelector,
  isProfileFormDirtySelector,
  (
    isEditProgressTrackerFormDirty,
    isEditTemplateFormDirty,
    isNewCreditCardFormDirty,
    isProfileFormDirty,
  ) =>
    isEditProgressTrackerFormDirty ||
    isEditTemplateFormDirty ||
    isNewCreditCardFormDirty ||
    isProfileFormDirty,
);
