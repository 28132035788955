import React, { useState } from 'react';
import { App, Button, Divider, Form, Input } from 'antd';
import cx from 'classnames';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';
import { redirect, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AppDispatch } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  getUserInfo,
  login,
  sendConfirmationAgain,
  signUp,
} from 'redux/reducers/authenticationSlice';
import AlertMessage from 'components/AlertMessage';
import Controller from 'components/form/Controller';

import PasswordValidationRulesBox from 'components/PasswordValidationRulesBox';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import {
  maxLength,
  PasswordValidationRules,
  requiredMessage,
} from 'constants/ValidationConstants';
import { getSpaceRole } from 'redux/reducers/roleSlice';
import { getAvailableSpace } from 'redux/reducers/spaceSlice';
import { getErrorMessages } from 'utils/toastUtils';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import { fetchIpAddress } from 'utils/authUtils';

import commonStyles from 'features/auth/auth.module.less';
import styles from 'features/auth/SignUpPage/SignUpPage.module.less';

import imgLogo from 'assets/img/landing/apdated-logo.svg';

interface SignUpFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

const validationSchema = Yup.object({
  firstName: Yup.string()
    .trim()
    .required(requiredMessage)
    .max(maxLength, `First name should be ${maxLength} characters maximum`),
  lastName: Yup.string()
    .trim()
    .required(requiredMessage)
    .max(maxLength, `Last name should be ${maxLength} characters maximum`),
  email: Yup.string()
    .trim()
    .email('Email is invalid')
    .max(maxLength, `Email should be ${maxLength} characters maximum`)
    .required(requiredMessage),
  password: Yup.string()
    .trim()
    .required(requiredMessage)
    .matches(PasswordValidationRules.MinimumCharacters)
    .matches(PasswordValidationRules.MaximumCharacters)
    .matches(PasswordValidationRules.LowercaseCharacter)
    .matches(PasswordValidationRules.SpecialCharacter)
    .matches(PasswordValidationRules.UppercaseCharacter)
    .matches(PasswordValidationRules.OneNumber),
});

export default function SignUpPage() {
  const location = useLocation();
  const { email: emailQuery } = queryString.parse(location.search);
  const inviteData = localStorage?.getItem('inviteId');
  const inviteId = inviteData ? JSON.parse(inviteData).inviteId : undefined;
  const { modal } = App.useApp();
  const isLightTheme = useSelector(isLightThemeSelector);

  const {
    handleSubmit,
    control,
    errors,
    watch,
    trigger,
    clearErrors,
    setValue,
  } = useForm<SignUpFormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: emailQuery ? decodeURIComponent(emailQuery as string) : '',
      password: '',
    },
    shouldUnregister: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordSectionVisible, setIsPasswordSectionVisible] =
    useState(false);
  const dispatch: AppDispatch = useDispatch();
  const watchPasswordField = watch('password');
  const [submittedData, setSubmittedData] = useState(
    null as SignUpFormValues | null,
  );

  const onSubmit = async (values: SignUpFormValues) => {
    if (isLoading) return;

    const data = {
      firstName: values?.firstName.trim(),
      lastName: values?.lastName.trim(),
      email: values?.email.trim(),
      password: values?.password.trim(),
      minsTimeOffset: dayjs().utcOffset(),
      inviteId: inviteId && emailQuery ? inviteId : undefined,
    };

    setIsLoading(true);
    const result = await dispatch(signUp(data));

    if (signUp.fulfilled.match(result)) {
      if (inviteId && emailQuery) {
        return await loginNewUser(data.email, data.password);
      }

      setSubmittedData(values);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }

    setIsLoading(false);
  };

  const loginNewUser = async (email: string, password: string) => {
    const userIpAddress = await fetchIpAddress();

    const result = await dispatch(
      login({ email, password, ip: userIpAddress || '' }),
    );

    if (login.fulfilled.match(result)) {
      await dispatch(getAvailableSpace());
      await dispatch(getUserInfo());
      await dispatch(getSpaceRole());
      setIsLoading(false);
      redirect('/');
    } else {
      setIsLoading(false);
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const onSendAgain = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    if (!submittedData) return;

    const result = await dispatch(
      sendConfirmationAgain({
        email: submittedData.email,
      }),
    );

    if (sendConfirmationAgain.fulfilled.match(result)) {
      AlertMessage.success('Confirmation email has been re-sent.');
    } else {
      const message = getErrorMessages(result);
      AlertMessage.error(message);
    }
  };

  const handleNext = async () => {
    await trigger();

    if (isEmpty(errors)) {
      setIsPasswordSectionVisible(true);
    }
  };

  const handleBack = () => {
    if (watchPasswordField) {
      return modal.confirm({
        title: 'Changes won’t be saved. Are you sure?',
        icon: <span className="icon icon-info-circle confirm-icon" />,
        className: cx('confirm-modal', {
          'confirm-modal-dark-button': !isLightTheme,
        }),
        width: '496px',
        onOk() {
          setValue('password', '');
          clearErrors('password');
          setIsPasswordSectionVisible(false);
        },
        onCancel() {},
      });
    }

    clearErrors('password');
    setIsPasswordSectionVisible(false);
  };

  return (
    <div className={commonStyles.authPage}>
      {!submittedData && (
        <div className={commonStyles.authWrap}>
          <div className={commonStyles.authRow}>
            <div className={commonStyles.authForm}>
              <div className={commonStyles.authFormWrap}>
                <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
                  <div className={commonStyles.authFormBox}>
                    <div className={commonStyles.authFormLogo}>
                      <img src={imgLogo} alt="logo" />
                    </div>

                    <div className={commonStyles.authFormTitle}>Sign Up</div>
                    {!isPasswordSectionVisible && (
                      <>
                        <Controller
                          name="firstName"
                          control={control}
                          as={Input}
                          label="First name"
                          size="large"
                          error={errors.firstName}
                          containerClassName="dark-input-group"
                        />
                        <Divider className="dark-divider mt-0" />
                        <Controller
                          name="lastName"
                          control={control}
                          as={Input}
                          label="Last name"
                          size="large"
                          error={errors.lastName}
                          containerClassName="dark-input-group"
                        />
                        <Divider className="dark-divider mt-0" />
                        <Controller
                          name="email"
                          control={control}
                          as={<Input disabled={!!emailQuery} />}
                          label="Email address"
                          size="large"
                          error={errors.email}
                          containerClassName="dark-input-group"
                        />
                        <div className={commonStyles.authBtnBox}>
                          <div className={commonStyles.authBtn}>
                            <Button type="primary" block onClick={handleNext}>
                              Next
                            </Button>
                          </div>
                        </div>
                      </>
                    )}
                    {isPasswordSectionVisible && (
                      <>
                        <Controller
                          name="password"
                          control={control}
                          as={Input.Password}
                          label="Password"
                          size="large"
                          error={errors.password}
                          showValidateError={
                            errors.password?.message === requiredMessage
                          }
                          containerClassName="dark-input-group"
                        />
                        <Divider className="dark-divider mt-0" />

                        <PasswordValidationRulesBox
                          passwordValue={watchPasswordField}
                        />

                        <div className={commonStyles.authBtnBox}>
                          <div className={commonStyles.authBtn}>
                            <Button block onClick={handleBack}>
                              Back
                            </Button>
                          </div>
                          <div className={commonStyles.authBtn}>
                            <Button
                              loading={isLoading}
                              htmlType="submit"
                              type="primary"
                              block
                            >
                              <h1>Sign Up</h1>
                            </Button>
                          </div>
                        </div>

                        <div className={styles.authFooterText}>
                          By clicking Sign Up, you confirm that you understand
                          and agree to Apdated{' '}
                          <a href="https://apdated.co/terms-and-conditions?section=master-terms">
                            {' '}
                            Terms and Conditions
                          </a>
                          .
                        </div>
                      </>
                    )}
                  </div>
                </Form>
              </div>
            </div>
            <div className={commonStyles.authFooter}>
              Please fill in you information
            </div>
          </div>
        </div>
      )}

      {submittedData && (
        <div className={commonStyles.authWrap}>
          <div className={commonStyles.authRow}>
            <div className={commonStyles.authForm}>
              <div className={commonStyles.authFormWrap}>
                <div className={commonStyles.authFormLogo}>
                  <img src={imgLogo} alt="logo" />
                </div>
                <div
                  className={cx(commonStyles.authFormTitle, styles.authText)}
                >
                  Thank you for registration!
                </div>

                {/* don't remove this, maybe it will be useful in the future */}
                {/*
                <div
                  className={cx(
                    commonStyles.authFormProgress,
                    styles.authProgress,
                  )}
                >
                  <div className="stageConfirm successful">
                    <i className="icon icon-check-single"></i>
                  </div>
                </div> */}
                <div className={styles.thankCaption}>
                  Please click the link sent to
                  <div>{submittedData.email}</div>
                  to confirm your registration.
                </div>
                <div className={cx(commonStyles.authBtnBox, styles.authBtnBox)}>
                  <div className={commonStyles.authBtn}>
                    <Button type="primary" block onClick={onSendAgain}>
                      Send it again
                    </Button>
                  </div>
                </div>
                <div className={styles.thankText}>
                  Haven’t received it? Please check Spam or let's try sending
                  again
                </div>
                <div className={styles.thankContact}>
                  Still having trouble? Email us: <br />
                  <a href="mailto:help@apdated.com">help@apdated.com</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
