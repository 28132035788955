import React from 'react';
import { useSelector } from 'react-redux';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import cx from 'classnames';
import { isNil } from 'lodash';

import {
  PreSetupPTTemplateCardResponseModel,
  ProgressTrackerTemplateCardResponseModel,
} from 'api';
import { getUsedTimes } from 'utils/trackerUtils';
import { TemplateEnum } from '../UserTemplatesListPage';
import { getCategoriesLabel, getCategoryColor } from 'utils/templateUtils';

type TemplateDetailsType =
  | PreSetupPTTemplateCardResponseModel
  | ProgressTrackerTemplateCardResponseModel;

type Props = {
  template: TemplateDetailsType;
  mode: TemplateEnum;
};

export default function TemplateCard(props: Props) {
  const isLightTheme = useSelector(isLightThemeSelector);
  const { template, mode } = props;
  const { name } = template;
  const isMyTemplate = mode === TemplateEnum.MyTemplates;

  const categoryId = (template as PreSetupPTTemplateCardResponseModel)
    ?.categoryId;

  return (
    <div
      className={cx('ticket', {
        inverse: !isLightTheme,
      })}
    >
      <div className="ticket__left">
        <div>
          <div className="ticket__name">{name}</div>
          {isMyTemplate && (
            <>
              <div className="ticket__item">
                <span>Used: </span>
                {getUsedTimes(
                  (template as ProgressTrackerTemplateCardResponseModel)
                    .usedTimes || 0,
                )}{' '}
              </div>
              <div className="ticket__item">
                <span>Project name:</span>{' '}
                {
                  (template as ProgressTrackerTemplateCardResponseModel)
                    .folderName
                }
              </div>
            </>
          )}
          {!isNil(categoryId) && (
            <div className="ticket__item">
              <span>Category:</span>{' '}
              <div className="ticket__circle">
                <div
                  className={cx('ticket-circle', getCategoryColor(categoryId))}
                >
                  {getCategoriesLabel(categoryId)}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
