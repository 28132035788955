import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { App, Button, Form } from 'antd';
import cx from 'classnames';
import { isEmpty, isString } from 'lodash';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentMethod, StripeCardNumberElement } from '@stripe/stripe-js';

import AlertMessage from 'components/AlertMessage';
import { NewCardForm } from '../NewCardForm';
import SettingsDetailsSection from 'components/settings/SettingsDetailsSection';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import {
  checkIsCardAlreadyAttached,
  createPaymentIntentForCardCheck,
  setDefaultPaymentCard,
  SetDefaultPaymentCardRequestModel,
} from 'redux/reducers/accountSlice';
import { userSelector } from 'redux/selectors/authenticationSelectors';
import { AppDispatch } from 'redux/store';
import {
  confirmCardPayment,
  NewCardFormValues,
  newCardValidationSchema,
  SuccessPaymentParams,
} from 'utils/creditCardUtils';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

type Props = {
  onCancelAdding: (isReloadNeeded?: boolean) => void;
};

export default function AddNewCardSection(props: Props) {
  const { onCancelAdding } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const elements = useElements();
  const stripe = useStripe();
  const dispatch: AppDispatch = useDispatch();
  const { modal } = App.useApp();
  const user = useSelector(userSelector);
  const isLightTheme = useSelector(isLightThemeSelector);

  const form = useForm<NewCardFormValues>({
    resolver: yupResolver(newCardValidationSchema),
    mode: 'all',
    defaultValues: {
      name: `${user?.firstName} ${user?.lastName}`,
      country: undefined,
      zip: '',
      isDefault: false,
    },
  });
  const { errors, formState, trigger, getValues, watch } = form;
  const { isDirty } = formState;
  const isDefaultWatched = watch('isDefault');

  const handleCancelAdding = () => {
    if (isSubmitting) return;

    if (isDirty) {
      return modal.confirm({
        title: 'Changes won’t be saved. Are you sure?',
        icon: <span className="icon icon-info-circle confirm-icon" />,
        className: cx('confirm-modal', {
          'confirm-modal-dark-button': !isLightTheme,
        }),
        width: '496px',
        onOk() {
          onCancelAdding();
        },
        onCancel() {},
      });
    }

    onCancelAdding();
  };

  const getIsCardAlreadyAttached = async (
    cardNumber: StripeCardNumberElement,
    name: string,
    country: string,
    zip: string,
  ) => {
    try {
      const tokenResponse = await stripe?.createToken(cardNumber, {
        name,
        address_country: country,
        address_zip: zip,
      });

      if (tokenResponse?.error && !tokenResponse.token) {
        return AlertMessage.error(
          `Cannot save the card. ${tokenResponse.error.message}`,
        );
      }

      const result = await dispatch(
        checkIsCardAlreadyAttached(tokenResponse?.token?.id),
      );

      if (!checkIsCardAlreadyAttached.fulfilled.match(result)) {
        AlertMessage.error(result?.payload?.response || ApiErrorMessage);
        return true;
      }

      return false;
    } catch {
      AlertMessage.error('Cannot save the card.');
    }
  };

  const onSubmit = async (values: NewCardFormValues) => {
    const { name, country, zip } = values;
    const cardNumber = elements?.getElement(CardNumberElement);

    if (!cardNumber) {
      AlertMessage.error('Invalid card.');
      return;
    }

    const isCardAlreadyAttached = await getIsCardAlreadyAttached(
      cardNumber,
      name,
      country,
      zip,
    );

    if (isCardAlreadyAttached) return;

    const result = await dispatch(
      createPaymentIntentForCardCheck(user?.id || ''),
    );

    if (!createPaymentIntentForCardCheck.fulfilled.match(result)) {
      AlertMessage.error(result.error.message || ApiErrorMessage);
      return;
    }

    const { clientSecret } = result.payload;

    if (!clientSecret) {
      AlertMessage.error('Invalid card.');
      return;
    }

    const paymentMethod = {
      card: cardNumber,
      billing_details: {
        name,
        address: { country: country || '', postal_code: zip },
      },
    };

    await confirmCardPayment(
      stripe,
      clientSecret,
      paymentMethod,
      undefined,
      onSuccessPayment,
    );
  };

  const onSuccessPayment = async ({ paymentMethod }: SuccessPaymentParams) => {
    if (isDefaultWatched) {
      await setCardAsDefault(paymentMethod || '');
    }

    AlertMessage.success('The card was successfully added.');
    onCancelAdding(true);
  };

  const setCardAsDefault = async (paymentMethod: PaymentMethod | string) => {
    const requestBody: SetDefaultPaymentCardRequestModel = {
      userId: user?.id || '',
      defaultPaymentMethodId: isString(paymentMethod)
        ? paymentMethod
        : paymentMethod.id,
    };

    const result = await dispatch(setDefaultPaymentCard(requestBody));
    if (!setDefaultPaymentCard.fulfilled.match(result)) {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const renderCustomSubmitButton = () => (
    <Button
      loading={isSubmitting}
      disabled={!isEmpty(errors)}
      className="dark-btn"
      size="large"
      htmlType="submit"
    >
      Save
    </Button>
  );

  const handleSave = async () => {
    const isValid = await trigger([
      'cardNumber',
      'expiryDate',
      'cvv',
      'name',
      'country',
      'zip',
    ]);

    if (isValid || isEmpty(errors)) {
      setIsSubmitting(true);
      const values = getValues();
      await onSubmit(values);
      setIsSubmitting(false);
    }
  };

  return (
    <Form layout="vertical" onFinish={handleSave}>
      <SettingsDetailsSection
        title="Manage payment info"
        description="Add your credit card for a faster checkout process."
        formState={formState}
        customSubmitButton={renderCustomSubmitButton()}
        onClose={handleCancelAdding}
      />

      <NewCardForm form={form} />
    </Form>
  );
}
