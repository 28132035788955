import React from 'react';
import { Modal, ModalProps } from 'antd';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

export type PTModalProps = ModalProps & {
  children: React.ReactNode;
};

export default function PTModal(props: PTModalProps) {
  const {
    children,
    cancelButtonProps,
    maskClosable = false,
    closable = false,
    destroyOnClose = true,
    ...other
  } = props;
  const isLightTheme = useSelector(isLightThemeSelector);

  return (
    <Modal
      maskClosable={maskClosable}
      cancelButtonProps={{
        ...cancelButtonProps,
        className: cx(cancelButtonProps?.className, {
          'dark-btn': !isLightTheme,
        }),
      }}
      closable={closable}
      destroyOnClose={destroyOnClose}
      {...other}
    >
      {children}
    </Modal>
  );
}
