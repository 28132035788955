import { SubscriptionTypeEnum } from 'api';

export const alertUser = (e: BeforeUnloadEvent) => {
  e.preventDefault();
  e.returnValue = true;
};

export const alertBeforeUnload = (isDirty: boolean) => {
  if (isDirty) window.addEventListener('beforeunload', alertUser);

  return () => window.removeEventListener('beforeunload', alertUser);
};

export const getDaysToDelete = (subscriptionType?: SubscriptionTypeEnum) => {
  switch (subscriptionType) {
    case SubscriptionTypeEnum.Enterprise:
      return 90;
    case SubscriptionTypeEnum.Advanced:
      return 30;
    default:
      return 7;
  }
};
