import React, { useEffect, useState } from 'react';
import { App, Divider, Form, Input, TreeSelect } from 'antd';
import cx from 'classnames';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as Yup from 'yup';
import { isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import Controller from 'components/form/Controller';
import PTModal, { PTModalProps } from 'components/PTModal';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import { AppDispatch } from 'redux/store';
import {
  createUserGroup,
  getProjectsBySpace,
  getUsersInSpace,
  updateUserGroup,
} from 'redux/reducers/projectSlice';
import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { ActiveUserResponseModel, FolderResponseModel } from 'api';
import { getFullName } from 'utils/personUtils';
import { mapUserGroupToRequestModel } from 'utils/groupUtils';
import { requiredMessage } from 'constants/ValidationConstants';

import styles from './UserGroupModal.module.less';

export interface UserGroupFormValues {
  users?: string[];
  name?: string;
  folders?: string[];
  groupId?: string;
}

const validationSchema = Yup.object().shape({
  users: Yup.array()
    .of(Yup.string())
    .min(1, requiredMessage)
    .required(requiredMessage),
  folders: Yup.array()
    .of(Yup.string())
    .min(1, requiredMessage)
    .required(requiredMessage),
  name: Yup.string()
    .trim()
    .required(requiredMessage)
    .max(50, 'Group name should be 50 characters maximum'),
});

type Props = {
  onCancel: () => void;
  initialValues?: UserGroupFormValues;
  onSuccess: () => void;
} & Omit<PTModalProps, 'children'>;

export default function UserGroupModal(props: Props) {
  const { onCancel, initialValues, onSuccess, open, ...otherModalProps } =
    props;
  const [isSaving, setIsSaving] = useState(false);
  const [listOfUser, setListOfUser] = useState<ActiveUserResponseModel[]>([]);
  const [listOfProject, setListOfProject] = useState<FolderResponseModel[]>([]);
  const dispatch: AppDispatch = useDispatch();
  const isEdit = !isNil(initialValues);
  const { errors, control, formState, handleSubmit } =
    useForm<UserGroupFormValues>({
      resolver: yupResolver(validationSchema),
      mode: 'all',
      shouldUnregister: false,
      defaultValues: {
        users: initialValues?.users || undefined,
        folders: initialValues?.folders || undefined,
        name: initialValues?.name || '',
      },
    });
  const isLightTheme = useSelector(isLightThemeSelector);
  const { modal } = App.useApp();

  const { isSubmitting, isDirty } = formState;

  const loadUsersInSpace = async () => {
    const result = await dispatch(getUsersInSpace());

    if (getUsersInSpace.fulfilled.match(result)) {
      setListOfUser(result.payload);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const loadProjectsBySpace = async () => {
    const result = await dispatch(getProjectsBySpace());

    if (getProjectsBySpace.fulfilled.match(result)) {
      setListOfProject(result.payload);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  useEffect(() => {
    if (!open) return;

    loadUsersInSpace();
    loadProjectsBySpace();
  }, [open]);

  const handleModalCancel = () => {
    if (isSubmitting) return;

    if (isDirty) {
      return modal.confirm({
        title: 'Changes won’t be saved. Are you sure?',
        icon: <span className="icon icon-info-circle confirm-icon" />,
        className: cx('confirm-modal', {
          'confirm-modal-dark-button': !isLightTheme,
        }),
        width: '496px',
        onOk() {
          onCancel();
        },
        onCancel() {},
      });
    }
    onCancel();
  };

  const handleSave = async (data: UserGroupFormValues) => {
    setIsSaving(true);

    const correctAction = isEdit ? updateUserGroup : createUserGroup;

    const values = mapUserGroupToRequestModel({
      ...data,
      groupId: initialValues?.groupId,
    });
    const result = await dispatch(correctAction(values));

    if (correctAction.fulfilled.match(result)) {
      onSuccess();
      onCancel();
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
    setIsSaving(false);
  };

  return (
    <PTModal
      {...otherModalProps}
      open={open}
      cancelText="Cancel"
      okText={isEdit ? 'Save' : 'Create'}
      onOk={handleSubmit(handleSave)}
      onCancel={handleModalCancel}
      okButtonProps={{ loading: isSaving }}
      title={isEdit ? 'Edit user group' : 'Create new user group'}
      className="modal-root"
    >
      <Form layout="vertical">
        <div className="modal-scroll-wrapper">
          <Controller
            containerClassName="dark-input-group"
            label="Group name"
            name="name"
            placeholder="Enter group name"
            as={Input}
            control={control}
            error={errors.name}
            size="large"
          />

          <Divider />

          <div className={styles.tree}>
            <Controller
              label="Select users"
              name="users"
              control={control}
              error={errors.users as any}
              as={
                <TreeSelect
                  size="large"
                  placeholder="Select please"
                  showSearch
                  treeCheckable={true}
                  multiple
                  treeData={listOfUser.map((user) => ({
                    key: user.id,
                    value: user.id,
                    title: getFullName(user.firstName, user.lastName),
                  }))}
                  popupClassName={cx(styles.treeDrop, {
                    [styles.dark]: !isLightTheme,
                  })}
                />
              }
            />
          </div>
          <Divider />

          <div className={styles.tree}>
            <Controller
              label="Select projects"
              name="folders"
              control={control}
              error={errors.folders as any}
              as={
                <TreeSelect
                  size="large"
                  placeholder="Select please"
                  showSearch
                  treeCheckable={true}
                  multiple
                  treeData={listOfProject.map((project) => ({
                    key: project.id,
                    value: project.id,
                    title: project.name,
                  }))}
                  popupClassName={cx(styles.treeDrop, {
                    [styles.dark]: !isLightTheme,
                  })}
                />
              }
            />
          </div>
        </div>
      </Form>
    </PTModal>
  );
}
