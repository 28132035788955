import React from 'react';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';

import { IS_PRODUCTION_ENV } from 'constants/env.constants';

import img from 'assets/img/landing/logo-white.svg';
import styles from './AppFooter.module.less';
import commonStyles from 'features/home/LandingPage/LandingPage.module.less';
import { AgreementTypeTabsEnum } from '../../../features/termsAndConditions/TermsAndConditions';

export default function AppFooter() {
  return (
    <section className={styles.footer}>
      <div className={commonStyles.container}>
        <div className={styles.footerBox}>
          <div className={styles.footerLogo}>
            <img src={img} alt="logo" />
          </div>

          <div className={styles.footerInner}>
            <ul className={styles.footerLinks}>
              <li>
                <a
                  href={
                    IS_PRODUCTION_ENV
                      ? `https://apdated.co/terms-and-conditions?section=${AgreementTypeTabsEnum.PrivacyPolicy}`
                      : `https://develop.apdated.co/terms-and-conditions?section=${AgreementTypeTabsEnum.PrivacyPolicy}`
                  }
                >
                  Privacy Policy
                </a>
              </li>

              <li>
                <a
                  href={
                    IS_PRODUCTION_ENV
                      ? 'https://api.apdated.co/external/api-docs/index.html'
                      : 'https://api-develop.apdated.co/external/api-docs/index.html'
                  }
                  target="_blank"
                >
                  Developer
                </a>
              </li>
              <li>
                <NavLink
                  to="https://help.apdated.co"
                  target="_blank"
                  className={({ isActive }) =>
                    cx({ [styles.active]: isActive })
                  }
                >
                  Help Center
                </NavLink>
              </li>
              {IS_PRODUCTION_ENV && (
                <li>
                  <a href="https://apdated.co/blog/" target="_blank">
                    Blog
                  </a>
                </li>
              )}
              <li className={styles.footerLinksCopy}>
                &copy; 2023 Apdated Inc
              </li>
            </ul>

            <ul className={styles.footerSocial}>
              <li>
                <a
                  href="https://www.facebook.com/profile.php?id=100067406201162"
                  target="_blank"
                >
                  <i className="icon icon-facebook" />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/apdated_co" target="_blank">
                  <i className="icon icon-twitter" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/apdated_inc/"
                  target="_blank"
                >
                  <i className="icon icon-instagram" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/apdated/"
                  target="_blank"
                >
                  <i className="icon icon-linkedin" />
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/@apdated" target="_blank">
                  <i className="icon icon-youtube" />
                </a>
              </li>
            </ul>

            <div className={styles.copy}>&copy; 2023 Apdated Inc</div>
          </div>
        </div>
      </div>
    </section>
  );
}
