import { useCallback, useMemo, useState } from 'react';
import { App, Button } from 'antd';
import Linkify, { LinkifyProps } from 'linkifyjs/react';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import { PTStageResponseModel, StageStatusEnum } from 'api';
import { useQueryMatch } from 'hooks/useQueryMatch';
import { getStageNumber } from './utils';
import StageBody from './StageBody';
import { getDateForUserTimezone } from 'utils/dateUtils';
import { getStagePercentage } from 'components/DetailsSection/utils';
import { SetStageStatusModel } from 'redux/reducers/progressTrackerSlice';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

import styles from '../ProgressTrackerView.module.less';

interface Props {
  stage: PTStageResponseModel;
  numberOfStage: number;
  onStageAction?: (data: SetStageStatusModel) => Promise<void>;
  isArrowHidden?: boolean;
  isControlsAvailable?: boolean;
  isCompletenessAvailable?: boolean;
}

export default function StageViewOld(props: Props) {
  const {
    stage,
    numberOfStage,
    onStageAction,
    isArrowHidden = false,
    isControlsAvailable = false,
    isCompletenessAvailable = true,
  } = props;
  const isMobile = useQueryMatch();
  const [isStageProcessing, setIsStageProcessing] = useState(false);
  const { modal } = App.useApp();
  const isLightTheme = useSelector(isLightThemeSelector);

  const processStageAction = useCallback(
    async (status: StageStatusEnum) => {
      if (!onStageAction) return;

      setIsStageProcessing(true);
      await onStageAction({ stageId: stage.id as string, status });
      setIsStageProcessing(false);
    },
    [onStageAction, stage.id],
  );

  const handleStageActionClick = useCallback(
    async (status: StageStatusEnum) => {
      if (isStageProcessing) return;

      if (status === StageStatusEnum.Pending) {
        return modal.confirm({
          title: 'Are you sure you want to undo the stage completion?',
          icon: <span className="icon icon-info-circle confirm-icon" />,
          className: cx('confirm-modal', {
            'confirm-modal-dark-button': !isLightTheme,
          }),
          width: '496px',
          onOk() {
            processStageAction(status);
          },
          onCancel() {},
        });
      }

      processStageAction(status);
    },
    [isStageProcessing, processStageAction],
  );

  const getCompleteButton = () =>
    isControlsAvailable && (
      <>
        {isMobile ? (
          <li>
            {stage.status === StageStatusEnum.Done ? (
              <i
                key="undo-button"
                onClick={() => handleStageActionClick(StageStatusEnum.Pending)}
                className="icon icon-undo"
              />
            ) : (
              <i
                key="done-button"
                onClick={() => handleStageActionClick(StageStatusEnum.Done)}
                className="icon icon-checkbox"
              />
            )}
          </li>
        ) : (
          <div className="popover__footer">
            <div>
              {stage.status === StageStatusEnum.Done ? (
                <Button
                  key="undo-button"
                  onClick={() =>
                    handleStageActionClick(StageStatusEnum.Pending)
                  }
                  loading={isStageProcessing}
                >
                  Reset
                </Button>
              ) : (
                <Button
                  key="done-button"
                  type="primary"
                  loading={isStageProcessing}
                  onClick={() => handleStageActionClick(StageStatusEnum.Done)}
                >
                  Done
                </Button>
              )}
            </div>
          </div>
        )}
      </>
    );

  const linkifyProps: LinkifyProps = {
    tagName: 'a',
    options: { attributes: { target: '_blank' } },
  };

  const popoverContent = useMemo(
    () => (
      <div>
        <div className="popover__head">
          <div className="popover__title">
            Stage {getStageNumber(numberOfStage)}
            {isCompletenessAvailable && ` - ${getStagePercentage(stage)} %`}
          </div>
          {stage.dueDate && (
            <div className="popover__date">
              Due date:
              {getDateForUserTimezone(stage.dueDate)}
            </div>
          )}
        </div>
        <div className="popover__body">
          <div className={styles.stageDetails}>
            {/*Try to remove spreading this props.*/}
            <Linkify {...linkifyProps}>
              <div className={styles.stageDetailsDesc}>{stage.description}</div>
            </Linkify>
          </div>
        </div>
        {getCompleteButton()}
      </div>
    ),
    [stage, numberOfStage, handleStageActionClick],
  );

  return (
    <div className={styles.stageBox}>
      <StageBody
        stage={stage}
        numberOfStage={numberOfStage}
        popoverContent={popoverContent}
        stageButtons={getCompleteButton()}
        isCompletenessAvailable={isCompletenessAvailable}
      />
      {!isArrowHidden && (
        <div className={styles.stageMore}>
          <div className={styles.stageMoreIcon}>
            <i
              className={cx('icon', {
                'icon-angle-right': !isMobile,
                'icon-angle-bottom': isMobile,
              })}
            />
          </div>
        </div>
      )}
    </div>
  );
}
