import React, { useCallback, useEffect, useState } from 'react';
import { Divider, Form } from 'antd';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { EmailIgnoreOptionsRequestModel } from 'api';
import AlertMessage from 'components/AlertMessage';
import Controller from 'components/form/Controller';
import Switch from 'components/form/Switch/Switch';
import SettingsDetailsSection from 'components/settings/SettingsDetailsSection';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { updateEmailIgnoreSettings } from 'redux/reducers/notificationSlice';
import { userSelector } from 'redux/selectors/authenticationSelectors';
import { AppDispatch } from 'redux/store';

import styles from './NotificationsTab.module.less';

const validationSchema = Yup.object().shape({
  isDeadlineNotificationsIgnored: Yup.boolean(),
});

enum NotificationsEnum {
  DeadlineNotifications = 'deadlineNotifications',
}

type NotificationsForm = {
  [NotificationsEnum.DeadlineNotifications]: boolean;
};

export default function NotificationsTab() {
  const [whichFieldIsChanging, setWhichFieldIsChanging] =
    useState<NotificationsEnum>();
  const user = useSelector(userSelector);
  const dispatch: AppDispatch = useDispatch();

  const { control, formState, handleSubmit, reset } =
    useForm<NotificationsForm>({
      mode: 'all',
      resolver: yupResolver(validationSchema),
      defaultValues: {
        [NotificationsEnum.DeadlineNotifications]:
          !user?.emailIgnoreOptions?.ignoreDeadlineNotification,
      },
    });
  const { errors } = formState;

  useEffect(() => {
    if (whichFieldIsChanging) handleSubmit(onSubmit)();
  }, [whichFieldIsChanging]);

  const onSubmit = async (values: NotificationsForm) => {
    const requestBody: EmailIgnoreOptionsRequestModel = {
      userId: user?.id,
      ignoreDeadlineNotification:
        !values[NotificationsEnum.DeadlineNotifications],
    };

    const result = await dispatch(updateEmailIgnoreSettings(requestBody));

    if (updateEmailIgnoreSettings.fulfilled.match(result)) {
      AlertMessage.success('The notification status was successfully changed.');
      reset(values);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
      reset();
    }

    setWhichFieldIsChanging(undefined);
  };

  const isLoading = useCallback(
    (fieldName: NotificationsEnum) => whichFieldIsChanging === fieldName,
    [whichFieldIsChanging],
  );

  const handleChangeField = (fieldName: NotificationsEnum) =>
    setWhichFieldIsChanging(fieldName);

  return (
    <>
      <SettingsDetailsSection
        title="Notification Settings "
        description="Project-related email notifications."
      />
      <Form>
        <Divider />

        <div className={styles.box}>
          <div className={styles.title}>Reminder to Complete Tasks</div>

          <div>
            <Controller
              name={NotificationsEnum.DeadlineNotifications}
              control={control}
              error={errors.deadlineNotifications}
              render={(props) => (
                <Switch
                  {...props}
                  onClick={() => {
                    handleChangeField(NotificationsEnum.DeadlineNotifications);
                  }}
                  loading={isLoading(NotificationsEnum.DeadlineNotifications)}
                />
              )}
            />
          </div>
        </div>
      </Form>
    </>
  );
}
