import React, { ReactNode, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { isNil } from 'lodash';

type Props = {
  children: ReactNode;
};

const getCorrectData = (
  pathname: string,
): { title?: string; meta?: string } => {
  switch (pathname) {
    case '/':
      return {
        title: 'Automated Status Communication Software | Apdated',
        meta: 'Streamline customer communication with Apdated. Customizable progress trackers, automated notifications, and integrations for informed and satisfied clients.',
      };
    case '/sign_up':
      return {
        title: 'Sign Up for Apdated | Keep Clients Informed',
        meta: 'Sign up for Apdated to keep clients informed at every step. Customize progress trackers, reduce admin work, and focus on business growth. Get reminders for approaching deadlines.',
      };
    case '/login':
      return { title: 'Login to Apdated' };
    case '/pricing':
      return {
        title: 'Pricing | Apdated',
        meta: 'Compare pricing plans for Apdated',
      };
    default:
      return { title: defaultTitle };
  }
};

const defaultTitle = 'Apdated';

export default function WrapperHelmetData(props: Props) {
  const { children } = props;
  const location = useLocation();
  const [data, setData] = useState<{ title?: string; meta?: string }>({
    title: defaultTitle,
  });

  useEffect(() => {
    setData(getCorrectData(location.pathname));
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        {!isNil(data.title) && <title>{data.title}</title>}
        {!isNil(data.meta) && <meta content={data.meta} charSet="utf-8" />}
      </Helmet>
      {children}
    </>
  );
}
