import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { isAuthenticatedSelector } from 'redux/selectors/authenticationSelectors';

// It redirects to the home page
// if account is authenticated and trying to access auth pages
export default function AuthRoute() {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const location = useLocation();

  return isAuthenticated ? (
    <Navigate
      to={{
        pathname: '/',
      }}
      state={{ from: location }}
    />
  ) : (
    <Outlet />
  );
}
