import React from 'react';
import { Layout } from 'antd';

import AppHeader from '../AppHeader';
import OnboardingWrapper from 'components/wrappers/OnboardingWrapper';
import RestrictionModal from 'features/account/AccountSettingsPage/RestrictionModal';
import ProfileSetupModal from 'features/auth/ProfileSetupModal';

const { Content } = Layout;

type Props = {
  children: React.ReactNode;
};

export default function MainLayout(props: Props) {
  const { children } = props;

  return (
    <Layout>
      <AppHeader />
      <RestrictionModal />
      <ProfileSetupModal />
      <OnboardingWrapper>
        <Content>{children}</Content>
      </OnboardingWrapper>
    </Layout>
  );
}
