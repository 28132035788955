import { nanoid } from '@reduxjs/toolkit';

export const getStageNumber = (numberOfStage: number) => {
  return numberOfStage > 9 ? `${numberOfStage}` : `0${numberOfStage}`;
};

const MOCK_ID_PREFIX = 'mock-';
export const generateMockId = () => {
  return `${MOCK_ID_PREFIX}${nanoid()}`;
};

export const isMockId = (id?: string) => {
  if (!id) return false;

  return id.startsWith(MOCK_ID_PREFIX);
};
