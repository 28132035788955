import { useCallback, useState } from 'react';
import { Dropdown, MenuProps } from 'antd';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';

import {
  ProgressTrackerResponseModel,
  PTStageResponseModel,
  SubscribeTypeEnum,
} from 'api';
import {
  setStageStatus,
  SetStageStatusModel,
} from 'redux/reducers/progressTrackerSlice';
import { AppDispatch } from 'redux/store';
import { StageViewOld } from '../Stage';
import { useResizableStages } from '../hooks/useResizableStages';
import { ProgressTrackerModeEnum } from 'enums/ProgressTrackerMode.enum';
import { TabletOrMobileScreenQuery } from 'constants/ScreenQuery';
import { EmbeddedEventEnum } from 'enums/EmbeddedTracker.enum';
import { useQueryMatch } from 'hooks/useQueryMatch';
import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import SubscribeModal from '../SubscribeModal';

import styles from '../ProgressTrackerView.module.less';

type Props = {
  details: ProgressTrackerResponseModel;
  mode?: ProgressTrackerModeEnum;
  onActionDone?: () => void;
  isEmbedded?: boolean;
  managementDropdown?: React.ReactNode;
};

export default function ProgressTrackerView(props: Props) {
  const {
    details,
    mode = ProgressTrackerModeEnum.Private,
    onActionDone,
    isEmbedded = false,
    managementDropdown,
  } = props;
  const { stages } = details;
  const dispatch: AppDispatch = useDispatch();
  const [dividedStages, countStagesPerRow] = useResizableStages(
    stages as PTStageResponseModel[],
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [subscribeType, setSubscribeType] = useState<SubscribeTypeEnum>();
  const isTabletOrMobile = useQueryMatch(TabletOrMobileScreenQuery);
  const { pathname } = useLocation();
  const isSearchPage = pathname === '/search';

  const handleStageAction = useCallback(
    async (data: SetStageStatusModel) => {
      const result = await dispatch(setStageStatus(data));

      if (!setStageStatus.fulfilled.match(result)) {
        AlertMessage.error(result.error.message || ApiErrorMessage);
        return;
      }

      await onActionDone?.();
    },
    [dispatch, onActionDone],
  );

  const handleSubscribeClick = useCallback((type: SubscribeTypeEnum) => {
    if (!isEmbedded) {
      setSubscribeType(type);
      setIsModalVisible(true);
      return;
    }

    window.parent.postMessage(
      { type: EmbeddedEventEnum.clickSubscribe, subscribeType: type },
      '*',
    );
  }, []);

  const handleSubscribeClose = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const subscribeMenu: MenuProps['items'] = [
    {
      key: 'sms',
      label: (
        <div onClick={() => handleSubscribeClick(SubscribeTypeEnum.Sms)}>
          <i className="icon icon-call" />
          SMS
        </div>
      ),
    },
    {
      key: 'email',
      label: (
        <div onClick={() => handleSubscribeClick(SubscribeTypeEnum.Email)}>
          <i className="icon icon-notify" />
          Email
        </div>
      ),
    },
  ];

  return (
    <>
      <div
        className={cx(styles.progressWrap, {
          [styles.searchPage]: isSearchPage,
        })}
      >
        <div className={styles.subscribeBox}>
          <div>{isTabletOrMobile && managementDropdown}</div>
          <div>
            <Dropdown menu={{ items: subscribeMenu }} trigger={['click']}>
              <div className={styles.subscribeBtn}>
                <span className={styles.subscribeBtnText}>Subscribe</span>
                <i className="icon icon-share-network" />
              </div>
            </Dropdown>
          </div>
        </div>

        {dividedStages.map((rowStages, index) => (
          <div
            className={cx(
              styles.stageContainer,
              index % 2 === 0 ? styles.leftToRight : styles.rightToLeft,
            )}
            key={`container-${index}`}
          >
            {rowStages.map((stage, stageIdx) => (
              <StageViewOld
                key={`stage-${stageIdx}`}
                stage={stage}
                numberOfStage={index * countStagesPerRow + stageIdx + 1}
                onStageAction={handleStageAction}
                isControlsAvailable={mode === ProgressTrackerModeEnum.Private}
                isArrowHidden={
                  index * countStagesPerRow + stageIdx + 1 === stages?.length
                }
              />
            ))}
          </div>
        ))}
      </div>

      {isModalVisible && (
        <SubscribeModal
          subscribeType={subscribeType}
          ptId={details?.id as string}
          onClose={handleSubscribeClose}
        />
      )}
    </>
  );
}
