import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import chunk from 'lodash/chunk';
import throttle from 'lodash/throttle';
import isNull from 'lodash/isNull';

import { PTStageResponseModel } from 'api';

interface BreakPoint {
  min: number;
  countPerRow: number;
  isOneRow?: boolean;
}

// there could be breakpoint any that we need
const breakpoints: BreakPoint[] = [
  {
    min: 320,
    countPerRow: 0,
    isOneRow: true,
  },
  {
    min: 767,
    countPerRow: 5,
  },
  {
    min: 1199,
    countPerRow: 6,
  },
  {
    min: 1599,
    countPerRow: 7,
  },
];

const getCountByWidth = (
  currentWidth: number,
  lastWidth: number,
  stagesLength: number,
): number | null => {
  const diff = Math.abs(Math.abs(currentWidth) - Math.abs(lastWidth));
  if (diff < 2) return null;

  if (currentWidth < lastWidth) {
    const sortedPoints = breakpoints
      //  we need increased array
      .sort((a, b) => a.min - b.min);

    const currentBreakPointIndex = sortedPoints.findIndex(
      ({ min }) => min > currentWidth,
    );

    if (currentBreakPointIndex < 0) return null;

    const correctIndex =
      currentBreakPointIndex === 0 ? 0 : currentBreakPointIndex - 1;

    return sortedPoints[correctIndex].isOneRow
      ? stagesLength
      : sortedPoints[correctIndex].countPerRow;
  }

  if (currentWidth > lastWidth) {
    const currentBreakPoint = breakpoints
      //  we need decreased array
      .sort((a, b) => b.min - a.min)
      .find(({ min }) => min < currentWidth);

    if (!currentBreakPoint) return null;

    return currentBreakPoint.isOneRow
      ? stagesLength
      : currentBreakPoint.countPerRow;
  }

  return null;
};

const maxPerRow = 7;

export const useResizableStages = <T = PTStageResponseModel>(stages: T[]) => {
  const lastWidthRef = useRef(window.innerWidth);
  const [countStagesPerRow, setCountStagesPerRow] = useState(
    getCountByWidth(window.innerWidth, lastWidthRef.current, stages.length) ||
      maxPerRow,
  );
  const dividedStages = useMemo(() => chunk(stages, countStagesPerRow), [
    countStagesPerRow,
    stages,
  ]);

  const handleResize = useCallback(
    throttle(() => {
      const newCountPerRow = getCountByWidth(
        window.innerWidth,
        lastWidthRef.current,
        stages.length,
      );

      lastWidthRef.current = window.innerWidth;

      if (isNull(newCountPerRow)) return;

      setCountStagesPerRow(newCountPerRow);
    }, 100),
    [stages.length],
  );

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line
  }, [handleResize]);

  return [dividedStages, countStagesPerRow] as [T[][], number];
};
