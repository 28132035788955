import { ReactNode, useEffect, useState } from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

type Props = {
  children: ReactNode;
};

export default function StripeContainer(props: Props) {
  const { children } = props;
  const [stripeObj, setStripeObj] = useState<Stripe | null>(null);

  useEffect(() => {
    loadStripeObj();
  }, []);

  const loadStripeObj = async () => {
    const stripeObj = await loadStripe(
      process.env.REACT_APP_STRIPE_PUBLIC_KEY || '',
      {
        locale: 'en',
      },
    );

    setStripeObj(stripeObj);
  };

  return stripeObj && <Elements stripe={stripeObj}>{children}</Elements>;
}
