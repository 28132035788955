import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Spin } from 'antd';
import { uniqueId } from 'lodash';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import cx from 'classnames';

import HistoryCard from './HistoryCard';
import {
  AdminProgressTrackerHistoryResponseModel,
  AdminProgressTrackerHistoryResponseModelPagedResponseModel,
  ProgressTrackerHistorySortFieldEnum,
  SortOrderEnum,
} from 'api';
import AlertMessage from 'components/AlertMessage';
import { PageSize } from 'constants/ApiConstant';
import NoInitialData from 'components/NoInitialData';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import {
  getTrackerHistory,
  GetTrackerHistoryRequestModel,
} from 'redux/reducers/progressTrackerSlice';
import { AppDispatch } from 'redux/store';
import { DispatchedRequestType } from 'utils/searchUtils';
import { useSelector } from 'react-redux';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import { isAdminSelector } from 'redux/selectors/authenticationSelectors';

import styles from './TrackerHistorySection.module.less';

type Params = {
  progressTrackerId: string;
};

type Props = {
  scrollRef: RefObject<HTMLDivElement>;
};

export default function TrackerHistorySection(props: Props) {
  const isLightTheme = useSelector(isLightThemeSelector);
  const { scrollRef } = props;
  const [history, setHistory] = useState<
    AdminProgressTrackerHistoryResponseModel[]
  >([]);
  const [hasMore, setHasMore] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
  const { progressTrackerId } = useParams<Params>();
  const latestRequestRef = useRef<DispatchedRequestType>();
  const isAdmin = useSelector(isAdminSelector);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    initInfinityScroll();
  }, []);

  const initInfinityScroll = () => {
    setIsVisible(false);
    setHistory([]);
    setIsInitialDataLoaded(false);
    setHasMore(true);
    setTotalCount(0);

    const timer = setTimeout(() => {
      // to destroy infinite scroll
      setIsVisible(true);
    }, 0);

    return () => clearTimeout(timer);
  };

  const onSetData = useCallback(
    (payload: AdminProgressTrackerHistoryResponseModelPagedResponseModel) => {
      const loadedData = payload.details || [];
      const totalCount = payload.totalCount || 0;
      const newData = [...history, ...loadedData];
      const hasMoreRows =
        !!loadedData.length &&
        !!newData.length &&
        newData.length < totalCount &&
        loadedData.length > 0;

      setHasMore(hasMoreRows);
      setHistory(newData);
      setTotalCount(totalCount);
    },
    [history],
  );

  const onLoadMore = useCallback(
    async (pageNumber: number) => {
      latestRequestRef.current?.abort?.();

      const requestBody: GetTrackerHistoryRequestModel = {
        sortOrder: SortOrderEnum.Desc,
        sortField: ProgressTrackerHistorySortFieldEnum.CreatedDate,
        pageSize: PageSize,
        trackerId: progressTrackerId || '',
        pageNumber,
        isAdmin,
      };

      const request = dispatch(getTrackerHistory(requestBody));

      latestRequestRef.current = request;
      const result = await request;
      if (getTrackerHistory.fulfilled.match(result)) {
        onSetData(result.payload);
      } else {
        if (result.meta.aborted) return;

        setHasMore(false);
        AlertMessage.error(result.error.message || ApiErrorMessage);
      }

      setIsInitialDataLoaded(true);
    },
    [
      dispatch,
      getTrackerHistory,
      onSetData,
      progressTrackerId,
      latestRequestRef,
      isAdmin,
    ],
  );

  return (
    <div className={styles.history}>
      {isInitialDataLoaded && !history.length && (
        <NoInitialData text={'There is no history for this tracker.'} />
      )}

      {isVisible && (
        <InfiniteScroll
          initialLoad={!isInitialDataLoaded}
          loadMore={onLoadMore}
          maxLength={totalCount}
          pageStart={-1}
          hasMore={hasMore}
          loader={<Spin key={uniqueId()} />}
          useWindow={false}
          getScrollParent={() => scrollRef.current}
          threshold={-100}
          className={cx(styles.list, {
            [styles.listDark]: !isLightTheme,
          })}
        >
          {history.map((h) => (
            <HistoryCard key={h.id} historyDetails={h} />
          ))}
        </InfiniteScroll>
      )}
    </div>
  );
}
