import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SubscriptionResponseModel, SubscriptionTypeEnum } from 'api';
import PTModal, { PTModalProps } from 'components/PTModal';
import { userSelector } from 'redux/selectors/authenticationSelectors';
import { AppDispatch } from 'redux/store';
import { updateSubscription } from 'redux/reducers/accountSlice';
import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { getDateForUserTimezone, NEXT_BILLING_DATE_DATE_FORMAT } from 'utils/dateUtils';

// import styles from './PricingDowngradeModal.module.less';

type Props = {
  subscription: SubscriptionResponseModel | null;
  onClose: () => void;
  onSuccess: () => void;
} & Omit<PTModalProps, 'children'>;

export default function PricingDowngradeModal(props: Props) {
  const { subscription, onClose, onSuccess, ...otherModalProps } = props;
  const user = useSelector(userSelector);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  const handleDowngradeClick = async () => {
    setIsFormSubmitting(true);

    const result = await dispatch(
      updateSubscription({
        priceId: subscription?.prices?.[0].priceId,
      }),
    );

    if (!updateSubscription.fulfilled.match(result)) {
      AlertMessage.error(result.error.message || ApiErrorMessage);
      setIsFormSubmitting(false);
      return;
    }

    await onSuccess();

    AlertMessage.success(
      `Your pricing plan will be downgraded to the ${
        subscription?.name
      } on ${getDateForUserTimezone(
        user?.nextPaymentDate,
        NEXT_BILLING_DATE_DATE_FORMAT,
      )}.`,
    );
    setIsFormSubmitting(false);
    onClose();
  };

  const getMessage = () => {
    switch (subscription?.type) {
      case SubscriptionTypeEnum.Basic:
        return (
          <>
            <p>
              The <b>Basic</b> plan supports only 1 user per company profile.
              You will lose the ability to add users and manage your company
              from other accounts.
            </p>
            <p>
              If you cancel your subscription now you will still have all paid
              plan features until{' '}
              <b>
                {getDateForUserTimezone(
                  user?.nextPaymentDate,
                  NEXT_BILLING_DATE_DATE_FORMAT,
                )}
              </b>
              . After the specified date, this company profile will be
              downgraded to the selected plan with all restrictions applied. A
              full list of restrictions can be viewed on the Pricing page.
            </p>
          </>
        );

      default:
        return (
          <>
            <p>
              If you downgrade your subscription now you will still have all
              current plan features until{' '}
              <b>
                {getDateForUserTimezone(
                  user?.nextPaymentDate,
                  NEXT_BILLING_DATE_DATE_FORMAT,
                )}
              </b>
              . Please note that after downgrading your plan, you may lose
              access to some features at the end of your current billing cycle.
            </p>
            <p>
              Downgrading your plan may impact the availability of items in the
              deleted folder. Please be aware that they may be deleted forever.
            </p>
          </>
        );
    }
  };

  return (
    <PTModal
      {...otherModalProps}
      className="modal-root"
      cancelText="Cancel"
      okText="Downgrade"
      okButtonProps={{ loading: isFormSubmitting }}
      onOk={handleDowngradeClick}
      onCancel={onClose}
    >
      <div className="modal-scroll-wrapper">
        <div>{getMessage()}</div>
      </div>
    </PTModal>
  );
}
