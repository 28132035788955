import React from 'react';
import { Button, ButtonProps } from 'antd';
import { useSelector } from 'react-redux';

import { SubscriptionResponseModel, SubscriptionTypeEnum } from 'api';
import { userSelector } from 'redux/selectors/authenticationSelectors';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import cx from 'classnames';

type Props = {
  subscription?: SubscriptionResponseModel;
  onUpgrade: (subscription: SubscriptionResponseModel) => void;
  onDowngrade: (subscription: SubscriptionResponseModel) => void;
} & ButtonProps;

export default function SubscriptionButton(props: Props) {
  const isLightTheme = useSelector(isLightThemeSelector);
  const { subscription, onUpgrade, onDowngrade, ...buttonProps } = props;
  const user = useSelector(userSelector);
  const userSubscriptionType = user?.subscriptionType;

  const getPricingPlanButtonProps = () => {
    if (!userSubscriptionType || !subscription?.type) return;
    const subscriptionType = subscription.type;

    if (subscriptionType === userSubscriptionType) {
      return {
        children: 'Current plan',
        disabled: true,
      };
    }

    if (subscriptionType < userSubscriptionType) {
      return {
        children: 'Select',
        onClick: () => onDowngrade(subscription),
      };
    }

    if (subscriptionType > userSubscriptionType) {
      return {
        children:
          subscriptionType === SubscriptionTypeEnum.Enterprise
            ? 'Contact Us'
            : 'Select',
        onClick: () => onUpgrade(subscription),
      };
    }
  };

  if (!userSubscriptionType || !subscription?.type) return null;

  return (
    <Button size="large" {...getPricingPlanButtonProps()} {...buttonProps}   className={cx({ 'dark-btn-outline': !isLightTheme })} />
  );
}
