import React from 'react';
import { Col, Input, Row } from 'antd';
import cx from 'classnames';

import { PaymentMethodResponseModel, SubscriptionResponseModel } from 'api';
import SettingsDetailsSection from 'components/settings/SettingsDetailsSection';
import { CardMode, CreditCard } from 'components/payments/CreditCard';
import PriceDetailsSection from 'features/auth/BillingSettingsPage/PricingTab/PricingPaymentDetails/PriceDetailsSection';
import { PriceTypesEnum } from 'enums/PriceTypesEnum';

import styles from '../PricingTab.module.less';

type Props = {
  subscription?: SubscriptionResponseModel;
  onCancelUpgrading: () => void;
  onCardChange: () => void;
  creditCard: PaymentMethodResponseModel;
  priceType?: PriceTypesEnum;
};

export default function PricingPaymentDetails(props: Props) {
  const {
    subscription,
    onCancelUpgrading,
    onCardChange,
    creditCard,
    priceType,
  } = props;

  return (
    <>
      <SettingsDetailsSection
        title={`Select ${subscription?.name} Plan`}
        description="For power users who want to do even more."
      />

      <div className={styles.details}>
        <div className={styles.detailsCard}>
          <CreditCard
            card={creditCard}
            key={creditCard.id}
            mode={CardMode.Edit}
            onChangeCreditCard={onCardChange}
          />
        </div>
        <div className={styles.detailsInfo}>
          <Row gutter={[10, 20]}>
            <Col xs={24}>
              <div className={styles.label}>Name on card</div>
              <div className={cx(styles.input, 'dark-input-group')}>
                <Input
                  aria-label="Name"
                  disabled
                  value={creditCard.customerName}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={[10, 0]}>
            <Col xs={24} md={12}>
              <div className={styles.label}>Country</div>
              <div className={cx(styles.input, 'dark-input-group')}>
                <Input
                  disabled
                  aria-label="Country"
                  value={creditCard.countryName || ''}
                />
              </div>
            </Col>

            <Col xs={24} md={12}>
              <div className={styles.label}>ZIP/Postal code</div>
              <div className={cx(styles.input, 'dark-input-group')}>
                <Input
                  disabled
                  aria-label="ZIP/Postal code"
                  value={creditCard.postalCode}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <PriceDetailsSection
        creditCardId={creditCard.id || ''}
        onCancelUpgrading={onCancelUpgrading}
        priceId={subscription?.prices?.[0].priceId}
        priceType={priceType}
      />
    </>
  );
}
