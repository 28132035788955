import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import apiClient, {
  CreateUserCommentRequestModel,
  SortOrderEnum,
  UserSortFieldEnum,
} from 'api';

export interface UserListRequestModel {
  term?: string;
  sortField: UserSortFieldEnum;
  sortOrder: SortOrderEnum;
  pageNumber: number;
  pageSize: number;
}

export const getActiveUsersInSpace = createAsyncThunk(
  'user/getActiveUsersInSpace',
  async (spaceId: string) => {
    return apiClient.getActiveUsersInSpace(spaceId);
  },
);

export const getUsers = createAsyncThunk(
  'user/getUsers',
  async ({
    term,
    sortOrder,
    sortField,
    pageNumber,
    pageSize,
  }: UserListRequestModel) => {
    return apiClient.getUserList(
      term,
      sortField,
      sortOrder,
      pageSize,
      pageNumber,
    );
  },
);

export const getUser = createAsyncThunk(
  'user/getUser',
  async (userId: string) => {
    return apiClient.getUserInfo(userId);
  },
);

export interface GetUserCommentsRequest {
  userId: string;
  pageNumber: number;
  pageSize: number;
}

export const getUserComments = createAsyncThunk(
  'user/getUserComments',
  async ({ userId, pageSize, pageNumber }: GetUserCommentsRequest) => {
    return apiClient.getUserComments(userId, pageSize, pageNumber);
  },
);

export interface AddCommentToUserRequest {
  userId: string;
  body: CreateUserCommentRequestModel;
}

export const addCommentToUser = createAsyncThunk(
  'user/addCommentToUser',
  async ({ userId, body }: AddCommentToUserRequest) => {
    return apiClient.addCommentToUser(userId, body);
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState: null,
  reducers: {},
});

const { reducer } = userSlice;
export default reducer;
