import React, { useEffect, useState } from 'react';
import { Spin, Switch } from 'antd';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { SubscriptionTypeEnum } from 'api';
import { DesktopScreenQuery } from 'constants/ScreenQuery';
import { PriceTypesEnum } from 'enums/PriceTypesEnum';
import PricingTableMobile from 'features/auth/BillingSettingsPage/PricingTab/PricingTableMobile';
import PricingTableDesktop from 'features/auth/BillingSettingsPage/PricingTab/PricingTableDesktop';
import { useQueryMatch } from 'hooks/useQueryMatch';
import {
  isSubscriptionsLoadingSelector,
  subscriptionsSelector,
} from 'redux/selectors/aссountSelectors';
import { AppDispatch } from 'redux/store';
import { onLoadSubscriptions } from 'utils/subscriptionUtils';

import styles from 'features/auth/BillingSettingsPage/PricingTab/PricingTab.module.less';
import classes from './PricingPage.module.less';

export default function PricingPage() {
  const [isAnnual, setIsAnnual] = useState(true);
  const isMobile = useQueryMatch(DesktopScreenQuery);
  const dispatch: AppDispatch = useDispatch();
  const subscriptions = useSelector(subscriptionsSelector);
  const isSubscriptionsLoading = useSelector(isSubscriptionsLoadingSelector);

  const priceType = isAnnual ? PriceTypesEnum.Annual : PriceTypesEnum.Monthly;

  const basicSubscription = subscriptions?.find(
    (s) => s.type === SubscriptionTypeEnum.Basic,
  );
  const standardSubscription = subscriptions?.find(
    (s) =>
      s.type === SubscriptionTypeEnum.Standard &&
      s.prices?.[0]?.name === priceType,
  );
  const advancedSubscription = subscriptions?.find(
    (s) =>
      s.type === SubscriptionTypeEnum.Advanced &&
      s.prices?.[0]?.name === priceType,
  );
  const enterpriseSubscription = subscriptions?.find(
    (s) => s.type === SubscriptionTypeEnum.Enterprise,
  );

  const basicPrice = basicSubscription?.prices?.[0].price;
  const standardPrice = standardSubscription?.prices?.[0].price;
  const advancedPrice = advancedSubscription?.prices?.[0].price;

  const monthlyStandardPrice =
    isAnnual && standardPrice ? Math.round(standardPrice / 12) : standardPrice;
  const monthlyAdvancedPrice =
    isAnnual && advancedPrice ? Math.round(advancedPrice / 12) : advancedPrice;

  useEffect(() => {
    onLoadSubscriptions();
  }, [dispatch]);

  if (isSubscriptionsLoading) return <Spin />;

  return (
    <div className={cx(classes.page, 'page-wrapper', 'scroll-box')}>
      <div className={cx(classes.wrapper)}>
        <div className={classes.bannerWrapper}>
          <div className={classes.banner}>
            <div>
              <h1>Compare our plans and pricing</h1>
            </div>
          </div>
          <div className={styles.box}>
            <div className={styles.switch}>
              <div className={isAnnual ? '' : styles.switchChecked}>
                Billed monthly
              </div>
              <div>
                <Switch
                  onChange={() => setIsAnnual(!isAnnual)}
                  checked={isAnnual}
                  className="icon-switch"
                />
              </div>
              <div className={isAnnual ? styles.switchChecked : ''}>
                Billed annually
              </div>
            </div>
            <div className={cx('label', isAnnual ? 'annually' : 'monthly')}>
              <span>Get a 10%</span> discount by selecting an annual plan
            </div>
          </div>
        </div>

        <>
          {isMobile ? (
            <PricingTableMobile
              prices={{
                basicPrice,
                standardPrice: monthlyStandardPrice,
                advancedPrice: monthlyAdvancedPrice,
              }}
              subscriptions={{
                basicSubscription,
                standardSubscription,
                advancedSubscription,
                enterpriseSubscription,
              }}
            />
          ) : (
            <PricingTableDesktop
              prices={{
                basicPrice,
                standardPrice: monthlyStandardPrice,
                advancedPrice: monthlyAdvancedPrice,
              }}
              subscriptions={{
                basicSubscription,
                standardSubscription,
                advancedSubscription,
                enterpriseSubscription,
              }}
            />
          )}
        </>
      </div>
    </div>
  );
}
