const typeConversionToNumberArray = (
  value?: string | (string | null)[] | null,
) => {
  if (!value) return [];
  if (Array.isArray(value)) {
    return value.map((el) => Number(el));
  } else {
    return [Number(value)];
  }
};
export { typeConversionToNumberArray };
