import axios from 'axios';
import mimeTypes from 'mime-types';
import { isArray, last } from 'lodash';
import pluralize from 'pluralize';
import { BaseUploadFile, FileValue } from 'components/wrappers/BaseFileUpload';
import { nanoid } from '@reduxjs/toolkit';
import { UploadFile } from 'antd/lib/upload/interface';

import { AttachmentResponseModel, FileParameter } from 'api';

export const DEFAULT_DOWNLOAD_FILE_ERROR_MESSAGE =
  'Something went wrong, please try again.';

interface DownloadFileModel {
  downloadUrl: string;
  fileName?: string;
  includeFileExtension?: boolean;
  errorMessage?: string;
}

export const downloadFileByObjectURL = async (
  objectURL: string,
  fileName: string = '',
) => {
  const link = document.createElement('a');

  link.href = objectURL;
  link.download = fileName;

  // mozilla requires link to be in the dom for downloading
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadFile = async ({
  downloadUrl,
  fileName = '',
  includeFileExtension = false,
  errorMessage = DEFAULT_DOWNLOAD_FILE_ERROR_MESSAGE,
}: DownloadFileModel) => {
  try {
    const { data: fileBlob } = await axios({
      method: 'get',
      url: downloadUrl,
      responseType: 'blob',
    });

    if (includeFileExtension) {
      fileName = `${fileName}.${mimeTypes.extension(fileBlob.type)}`;
    }

    const url = URL.createObjectURL(fileBlob);

    downloadFileByObjectURL(url, fileName);
  } catch {
    throw new Error(errorMessage);
  }
};

export const getFileExt = (fileName: string = '') => {
  if (!fileName) return '';

  const fileNameArray = fileName.split('.');

  return fileNameArray[fileNameArray.length - 1];
};

export interface BaseUploadMessages {
  uploadFailed: (fileName: string) => string;
  fileSizeLimitError: (fileName: string, fileSize: number) => string;
  fileUnsupportedError: (fileName: string) => string;
  maxFileCounterError: (maxFileCount: number) => string;
  imageDimensionsError: string;
}

interface BaseUploadMessagesOptions {
  fileTypes: Array<string>;
}

const getJoinedFileTypes = (fileTypes: Array<string>) => {
  if (fileTypes.length === 1) {
    return fileTypes[0];
  }

  const fileTypesCopy = [...fileTypes];
  const lastFileType = last(fileTypesCopy);
  const withoutLastFileType = fileTypesCopy.splice(0, fileTypesCopy.length - 1);

  return `${withoutLastFileType.join(', ')} and ${lastFileType}`;
};

export const getFileUploadMessages = (
  options: BaseUploadMessagesOptions,
): BaseUploadMessages => {
  const { fileTypes } = options;

  const fileTypesCount = fileTypes.length;
  const joinedFileTypes = getJoinedFileTypes(fileTypes);

  return {
    uploadFailed: (fileName: string) => `${fileName} file upload failed.`,
    fileSizeLimitError: (fileName: string, fileSize: number) =>
      `${fileName} file is too large, maximum file size is ${fileSize} MB.`,
    fileUnsupportedError: (fileName: string) =>
      `${fileName} file is not supported, acceptable file ${pluralize(
        'type',
        fileTypesCount,
      )} ${pluralize('is', fileTypesCount)} ${joinedFileTypes}.`,
    maxFileCounterError: (maxFileCount: number) =>
      `You can upload no more than ${maxFileCount} files. Please try again with the allowed quantity.`,
    imageDimensionsError: 'Image height and image width must be equal.',
  };
};

export const generateUploadFileList = (
  files: AttachmentResponseModel | AttachmentResponseModel[],
): BaseUploadFile[] => {
  files = isArray(files) ? files : [files];

  return files.map((file) => ({
    uid: nanoid(),
    size: 1,
    url: '',
    type: '',
    status: 'done',
    name: file.fileName as string,
    response: {
      s3Key: file.s3Key || '',
      getUrl: file.getFileUrl,
      putUrl: '',
      contentType: '',
    },
  }));
};

export const getS3Keys = (fileList: UploadFile[]): FileValue[] => {
  return fileList
    .map(
      (file: UploadFile): FileValue => ({
        fileName: file.name,
        s3Key: file.response?.s3Key,
        id: file.response?.id,
      }),
    )
    .filter((file) => file.s3Key);
};

export const getFilesForManualDownload = (
  fileList: UploadFile[],
): FileParameter[] => {
  if (!fileList?.length) return [];

  return fileList.map((file) => {
    return { fileName: file.name, data: file.originFileObj as Blob };
  });
};

export const getFileForLogoDownload = (
  fileList: UploadFile[],
): AttachmentResponseModel[] => {
  if (!fileList?.length) return [];

  return fileList.map(({ name, response }) => ({
    fileName: name,
    id: response?.id,
    getFileUrl: response?.getUrl,
  }));
};

export const mapFilesResponseToInitialValues = (
  files?: AttachmentResponseModel[],
): FileValue[] => {
  if (!files?.length) return [];

  return files.map((file) => ({
    s3Key: file.s3Key || '',
    fileName: file.fileName || '',
    id: file.id || '',
  }));
};

export const getFileNameWithoutExt = (fileName: string = '') => {
  return fileName.indexOf('.') > -1
    ? fileName.substring(0, fileName.lastIndexOf('.')).trim()
    : fileName;
};
