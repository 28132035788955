import React from 'react';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';

import { useFetchTemplatesDetails } from 'hooks/useFetchTemplatesDetails';
import Breadcrumb from 'components/Breadcrumb';
import TemplateDetailsSection from '../TemplateDetailsPage/TemplateDetailsSection';
import StagesViewSection, {
  StagesMode,
} from 'components/ProgressTracker/StagesViewSection';
import {
  TemplateEnum,
  TemplateSections,
} from '../TemplateListPage/UserTemplatesListPage';
import { isAdminSelector } from 'redux/selectors/authenticationSelectors';
import AdminTemplateLibraryDetailsPage from 'features/admin/AdminTemplateLibraryDetailsPage';

type ParamsProps = {
  templateId: string;
};

export default function TemplateLibraryDetailsPage() {
  const { templateId } = useParams<ParamsProps>();
  const [templateDetails, isLoading] = useFetchTemplatesDetails({
    templateId: templateId || '',
    mode: TemplateEnum.TemplateLibrary,
  });
  const isAdmin = useSelector(isAdminSelector);

  if (isLoading) return <Spin />;

  if (!templateDetails) return null;

  return (
    <div className="page-wrapper scroll-box ">
      <div className="page-box page-holder">
        <Breadcrumb
          routes={[
            {
              title: <i className="icon icon-arrow-left" />,
              link: `/templates?tab=${TemplateSections.TemplateLibrary}`,
            },
          ]}
        />

        {isAdmin && (
          <AdminTemplateLibraryDetailsPage template={templateDetails} />
        )}

        {!isAdmin && (
          <TemplateDetailsSection
            template={templateDetails}
            mode={TemplateEnum.TemplateLibrary}
          />
        )}

        <StagesViewSection
          details={templateDetails}
          mode={StagesMode.VIEW_TEMPLATE}
        />
      </div>
    </div>
  );
}
