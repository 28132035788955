import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useInterval } from 'rooks';

import { ProgressTrackerResponseModel } from 'api';
import { AppDispatch } from 'redux/store';
import { getPTDetailsBySearch } from 'redux/reducers/progressTrackerSlice';
import ProgressTrackerView from 'components/ProgressTracker/ProgressTrackerView';
import { ProgressTrackerModeEnum } from 'enums/ProgressTrackerMode.enum';
import DetailsSectionMinimized from 'components/DetailsSection/DetailsSectionMinimized';
import { FetchPTDetailsInterval } from 'constants/ApiConstant';

import styles from './EmbeddedPTPage.module.less';

export default function EmbeddedPTPage() {
  const { progressTrackerId } = useParams<any>();
  const [progressTracker, setProgressTracker] =
    useState<ProgressTrackerResponseModel | null>(null);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    loadDetailsToUpdate();
  }, [progressTrackerId]);

  useInterval(
    () => {
      if (!progressTracker) return;
      return loadDetailsToUpdate();
    },
    FetchPTDetailsInterval,
    true,
  );

  const loadDetailsToUpdate = async () => {
    if (!progressTrackerId) return;

    const result = await dispatch(getPTDetailsBySearch(progressTrackerId));

    if (!getPTDetailsBySearch.fulfilled.match(result)) {
      return;
    }

    setProgressTracker(result.payload);
  };

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <p className={styles.title}>Hi ;)</p>
        <p>
          You can add these steps to your tracker. You can also add it to your
          folder or project.
        </p>
      </div>

      <div className={styles.wrap}>
        {progressTracker && (
          <>
            <div className={styles.miniDetails}>
              <DetailsSectionMinimized details={progressTracker} />
            </div>

            <div className={styles.detailsName}>{progressTracker.name}</div>

            <div className={styles.scrollBox}>
              <ProgressTrackerView
                details={progressTracker}
                mode={ProgressTrackerModeEnum.Public}
                isEmbedded
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
