import React, { useEffect, useRef } from 'react';
import { Tabs } from 'antd';
import { isString } from 'lodash';
import queryString from 'query-string';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Tab } from 'rc-tabs/lib/interface';

import useSearchQueryMatch from 'hooks/useSearchQueryMatch';
import MyTemplateListTab from '../MyTemplateListTab';
import TemplateLibraryTab from '../TemplateLibraryTab';
import Breadcrumb from 'components/Breadcrumb';

import styles from './UserTemplatesListPage.module.less';

export enum TemplateEnum {
  MyTemplates = 1,
  TemplateLibrary = 2,
}

export enum TemplateSections {
  MyTemplates = 'all',
  TemplateLibrary = 'library',
  TemplateDrafts = 'drafts',
}

const TABS = Object.values(TemplateSections);
const DEFAULT_TAB = TemplateSections.TemplateLibrary;

export default function UserTemplatesListPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const pageSectionMatch = useSearchQueryMatch(TABS, 'tab');
  const activeKey = query.tab;
  const infiniteScrollParentRef = useRef(null);

  useEffect(() => {
    if (!pageSectionMatch) {
      navigate(`${location.pathname}?tab=${DEFAULT_TAB}`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSectionMatch]);

  const getTabs = (): Tab[] => [
    {
      key: TemplateSections.TemplateLibrary,
      label: (
        <Link
          to={{
            search: queryString.stringify({
              tab: TemplateSections.TemplateLibrary,
            }),
          }}
        >
          Template Library
        </Link>
      ),
      children: <TemplateLibraryTab scroll={infiniteScrollParentRef} />,
    },
    {
      key: TemplateSections.MyTemplates,
      label: (
        <Link
          to={{
            search: queryString.stringify({
              tab: TemplateSections.MyTemplates,
            }),
          }}
        >
          My Templates
        </Link>
      ),
      children: <MyTemplateListTab scroll={infiniteScrollParentRef} />,
    },
    {
      key: TemplateSections.TemplateDrafts,
      label: (
        <Link
          to={{
            search: queryString.stringify({
              tab: TemplateSections.TemplateDrafts,
            }),
          }}
        >
          Template Drafts
        </Link>
      ),
      children: <MyTemplateListTab scroll={infiniteScrollParentRef} isDraft />,
    },
  ];

  const getBreadcrumbTitle = (activeKey?: TemplateSections) => {
    switch (activeKey) {
      case TemplateSections.TemplateLibrary:
        return 'Template Library';
      case TemplateSections.MyTemplates:
        return 'My Templates';
      case TemplateSections.TemplateDrafts:
        return 'Template Drafts';
    }
  };

  return (
    <div className="page-wrapper scroll-box" ref={infiniteScrollParentRef}>
      <div className="page-box page-holder">
        <Breadcrumb
          routes={[
            {
              title: <i className="icon icon-home" />,
              link: '/',
            },
            {
              title: getBreadcrumbTitle(activeKey as TemplateSections),
            },
          ]}
        />

        <div className={styles.tabs}>
          <Tabs
            activeKey={isString(activeKey) ? activeKey : DEFAULT_TAB}
            animated={false}
            destroyInactiveTabPane
            items={getTabs()}
          />
        </div>
      </div>
    </div>
  );
}
