import React from 'react';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import { getSelectedFilterValues } from 'utils/filterUtils';
import { getCategoriesOptions, getCategoryColor } from 'utils/templateUtils';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

export enum CategoryFilterOptions {
  All = 0,
}

type Props = {
  onChange: (data: number[]) => void;
  value: number[];
  placeholder?: string;
};

export default function CategoriesFilter(props: Props) {
  const isLightTheme = useSelector(isLightThemeSelector);
  const { onChange, value, placeholder } = props;

  const handleChange = (values: number[]) => {
    const selectedFilterValues = getSelectedFilterValues(
      values,
      CategoryFilterOptions.All,
    );

    onChange(selectedFilterValues);
  };

  return (
    <div className={cx('multiple-select', { 'dark-multiple': !isLightTheme })}>
      <Select
        mode="multiple"
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        optionFilterProp="children"
        showSearch
      >
        <>
          <Select.Option
            key={CategoryFilterOptions.All}
            value={CategoryFilterOptions.All}
          >
            All
          </Select.Option>
          {getCategoriesOptions().map((category) => (
            <Select.Option
              key={category.value}
              value={category.value}
              className={cx(getCategoryColor(category.value))}
            >
              {category.label}
            </Select.Option>
          ))}
        </>
      </Select>
    </div>
  );
}
