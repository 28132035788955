import { Input } from 'antd';
import { useCallback } from 'react';
import { InputProps } from 'antd/lib/input';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';

import { phoneFormatter } from './utils';

type Props = InputProps & ControllerRenderProps<FieldValues>;

// should be used as render props for controller react-hook-form
export default function PhoneInput (props: Props) {
  const { onChange: $onChange, ref,  ...other } = props;

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const correctValue = phoneFormatter(value);

    $onChange(correctValue);
  }, []);


  return <Input ref={ref} {...other} onChange={onChange} />;
};
