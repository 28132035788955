import { App, Form, Radio, Space } from 'antd';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { BillingCycleEnum } from 'api';
import AlertMessage from 'components/AlertMessage';
import Controller from 'components/form/Controller';
import PTModal, { PTModalProps } from 'components/PTModal';
import RadioGroup from 'components/form/RadioGroup';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { confirmIcon } from 'constants/ConfirmationConstants';
import { requiredMessage } from 'constants/ValidationConstants';
import { updateSubscription } from 'redux/reducers/accountSlice';
import { userSelector } from 'redux/selectors/authenticationSelectors';
import { getUserInfo } from 'redux/reducers/authenticationSlice';
import { subscriptionsSelector } from 'redux/selectors/aссountSelectors';
import { currentSpaceIdByStoreSelector } from 'redux/selectors/spacesSelectors';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import { AppDispatch } from 'redux/store';
import { onLoadSubscriptions } from 'utils/subscriptionUtils';

import styles from 'features/account/AccountSettingsPage/ProfileTab/CancelSubscriptionModal/CancelSubscriptionModal.module.less';

const validationSchema = Yup.object().shape({
  billingCycle: Yup.number().required(requiredMessage),
});

type ChangeBillingCycleFormValues = {
  billingCycle: BillingCycleEnum;
};

type Props = {
  onClose: () => void;
  onOpen: () => void;
} & Omit<PTModalProps, 'children'>;

export default function ChangeBillingCycleModal(props: Props) {
  const { onClose, onOpen, ...otherModalProps } = props;
  const user = useSelector(userSelector);
  const userBillingCycle = user?.billingCycle;
  const dispatch: AppDispatch = useDispatch();
  const { modal } = App.useApp();
  const subscriptions = useSelector(subscriptionsSelector);
  const isLightTheme = useSelector(isLightThemeSelector);

  const { control, handleSubmit, formState } =
    useForm<ChangeBillingCycleFormValues>({
      mode: 'all',
      resolver: yupResolver(validationSchema),
      defaultValues: {
        billingCycle: userBillingCycle || BillingCycleEnum.Monthly,
      },
    });
  const { isDirty, errors } = formState;

  const getPriceId = async (billingCycle: BillingCycleEnum) => {
    await onLoadSubscriptions();

    return subscriptions?.find(
      (s) =>
        s.type === user?.subscriptionType &&
        s.prices?.[0].name === BillingCycleEnum[billingCycle],
    )?.prices?.[0].priceId;
  };

  const onChangeBillingCycle = async (billingCycle: BillingCycleEnum) => {
    const priceId = await getPriceId(billingCycle);

    if (priceId) {
      const result = await dispatch(
        updateSubscription({
          spaceId: currentSpaceIdByStoreSelector(),
          priceId,
        }),
      );

      if (updateSubscription.fulfilled.match(result)) {
        await dispatch(getUserInfo());
        AlertMessage.success(
          'You have successfully changed your billing cycle. Your new billing ' +
            'cycle will begin after your current subscription ends',
        );
      } else {
        AlertMessage.error(result.error.message || ApiErrorMessage);
      }
    }
  };

  const handleChangeBillingCycle = (values: ChangeBillingCycleFormValues) => {
    onClose();

    if (isDirty) {
      const { billingCycle } = values;

      return modal.confirm({
        title: `Are you sure you want to change your billing cycle to 
        ${BillingCycleEnum[billingCycle]}?`,
        icon: confirmIcon,
        className: cx('confirm-modal', {
          'confirm-modal-dark-button': !isLightTheme,
        }),
        async onOk() {
          await onChangeBillingCycle(billingCycle);
        },
        onCancel: onOpen,
        okText: 'Yes',
      });
    }
  };

  return (
    <PTModal
      {...otherModalProps}
      className={cx('modal-root')}
      title="Billing Cycle"
      onOk={handleSubmit(handleChangeBillingCycle)}
      okText="Save"
      onCancel={onClose}
      zIndex={999}
    >
      <Form layout="vertical">
        <div className="modal-scroll-wrapper">
          <div className={styles.titleBilling}>
            Select your preferred billing cycle:
          </div>

          <Controller
            name="billingCycle"
            control={control}
            error={errors.billingCycle}
            render={(field) => (
              <RadioGroup {...field}>
                <Space direction="vertical">
                  <Radio value={BillingCycleEnum.Monthly}>Monthly</Radio>
                  <Radio value={BillingCycleEnum.Annual}>
                    Annual <span className={styles.accent}>SAVE 10%</span>
                  </Radio>
                </Space>
              </RadioGroup>
            )}
          />
        </div>
      </Form>
    </PTModal>
  );
}
