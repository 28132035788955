import { isNil } from 'lodash';

import {
  UserGroupDetailsResponseModel,
  UserGroupRequestModel,
  UserGroupUpdateRequestModel,
} from 'api';
import { UserGroupFormValues } from 'components/modals/UserGroupModal/UserGroupModal';

export const mapUserGroupToRequestModel = (
  values: UserGroupFormValues,
): UserGroupRequestModel | UserGroupUpdateRequestModel => {
  return {
    id: values.groupId,
    name: values.name,
    users: values.users?.map((userId) => ({ userId })),
    folders: values.folders?.map((folderId) => ({ folderId })),
  };
};

export const mapUserGroupToInitialValues = (
  data: UserGroupDetailsResponseModel,
): UserGroupFormValues => {
  return {
    groupId: data.id || '',
    name: data.name || '',
    folders: !isNil(data.folders)
      ? data.folders
          ?.map((folderDetails) => folderDetails.folderId || '')
          .filter(Boolean)
      : undefined,
    users: !isNil(data.users)
      ? data.users
          ?.map((userDetails) => userDetails.userId || '')
          .filter(Boolean)
      : undefined,
  };
};
