import { useEffect } from 'react';
import { Form, InputNumber, Spin } from 'antd';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Controller from 'components/form/Controller';
import PTModal, { PTModalProps } from 'components/PTModal';
import { requiredMessage } from 'constants/ValidationConstants';
import { RestrictionModalTypeEnum } from 'features/account/AccountSettingsPage/RestrictionModal';
import { openRestrictionModal } from 'redux/reducers/accountSlice';
import { isTrackerManagerSelector } from 'redux/selectors/roleSelectors';
import {
  availableCountToCreateSelector,
  isTrackerRestrictionsLoadingSelector,
} from 'redux/selectors/progressTrackerSelectors';
import { AppDispatch } from 'redux/store';
import { onCheckTrackerRestrictions } from 'utils/trackerUtils';

import styles from './UseTemplateQuantityModal.module.less';
import { TemplateEnum } from '../TemplateListPage/UserTemplatesListPage';

const validationSchema = Yup.object().shape({
  quantity: Yup.number()
    .required(requiredMessage)
    .max(100, 'Maximum quantity is 100')
    .positive('Quantity should be positive'),
});

type FormValues = {
  quantity: number;
};

type Params = {
  templateId: string;
};

type Props = {
  onClose: (isCancelling?: boolean) => void;
  onOpen: () => void;
  mode?: TemplateEnum;
} & Omit<PTModalProps, 'children'>;

export default function UseTemplateQuantityModal(props: Props) {
  const {
    onClose,
    onOpen,
    mode = TemplateEnum.MyTemplates,
    open,
    ...otherModalProps
  } = props;
  const isTrackerManager = useSelector(isTrackerManagerSelector);
  const availableCountToCreate = useSelector(availableCountToCreateSelector);
  const isTrackerRestrictionsLoading = useSelector(
    isTrackerRestrictionsLoadingSelector,
  );
  const { templateId } = useParams<Params>();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const isMyTemplate = mode === TemplateEnum.MyTemplates;

  const { control, handleSubmit, formState } = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      quantity: 1,
    },
  });
  const { errors, isValid } = formState;

  useEffect(() => {
    if (!open) return;

    onCheckTrackerRestrictions();
  }, [open]);

  const onNextClick = (values: FormValues) => {
    if (availableCountToCreate && values.quantity > availableCountToCreate) {
      onClose();
      const message = isTrackerManager
        ? 'You have selected more trackers than left available in your plan this month. ' +
          'Please decrease the number of trackers or contact space admin.'
        : 'You have selected more trackers than left available in your plan this month. ' +
          'Please decrease the number of trackers or upgrade your account.';
      dispatch(
        openRestrictionModal({
          type: RestrictionModalTypeEnum.CreateTrackerFromTemplate,
          message,
          onClose: onOpen,
        }),
      );
      return;
    }

    const url = isMyTemplate
      ? `/template/${templateId}/use`
      : `/template-library/${templateId}/use`;

    navigate(url, {
      state: {
        quantity: values.quantity,
      },
    });
    onClose();
  };

  return (
    <PTModal
      {...otherModalProps}
      open={open}
      className="modal-root"
      title="Create tracker(s)"
      onOk={handleSubmit(onNextClick)}
      okButtonProps={{ disabled: !isValid || isTrackerRestrictionsLoading }}
      onCancel={() => onClose(true)}
      closable
      okText="Next"
    >
      {isTrackerRestrictionsLoading && <Spin />}

      {!isTrackerRestrictionsLoading && (
        <Form layout="vertical">
          <div className="modal-scroll-wrapper">
            <p>
              Enter the number of trackers you want to create using this
              template
            </p>

            <Controller
              name="quantity"
              label="Quantity"
              error={errors.quantity}
              control={control}
              render={(field) => (
                <InputNumber className={styles.number} {...field} />
              )}
            />
          </div>
        </Form>
      )}
    </PTModal>
  );
}
