import { AsyncThunk } from '@reduxjs/toolkit';

import { cardHeight } from 'constants/SearchConstants';
import { SearchLayoutMode } from 'components/search/SearchLayout/SearchLayout';
import {
  getAdminProjects,
  getArchivedTrackers,
  getDeletedTrackers,
  getProjectsPaged,
} from 'redux/reducers/projectSlice';
import { getUsers } from 'redux/reducers/userSlice';
import {
  getAdminProgressTrackers,
  getProjectPTsPaged,
} from 'redux/reducers/progressTrackerSlice';
import { getAdminSpaces } from 'redux/reducers/spaceSlice';

export type DispatchedRequestType = { abort(reason?: string): void };

export type ComponentData = {
  title: string;
  requestOptions: any;
  getDataThunk: AsyncThunk<any, any, {}>;
  queryFieldName?: string;
  description?: string;
};

export const getPageSizeByWindowHeight = (
  unnecessaryHeight: number,
  itemHeight: number = cardHeight,
) => Math.round((window.parent.innerHeight - unnecessaryHeight) / itemHeight);

export const getComponentData = (
  mode: SearchLayoutMode,
  getRequestOptions: (searchFieldName?: string) => {},
  isAdmin: boolean,
): ComponentData => {
  switch (mode) {
    case SearchLayoutMode.Archive:
      return {
        title: 'Archive',
        getDataThunk: getArchivedTrackers,
        requestOptions: getRequestOptions(),
        queryFieldName: 'trackerName',
      };
    case SearchLayoutMode.Projects:
      return {
        title: 'Projects',
        getDataThunk: isAdmin ? getAdminProjects : getProjectsPaged,
        requestOptions: getRequestOptions(),
        queryFieldName: 'projectName',
      };
    case SearchLayoutMode.Users:
      return {
        title: 'Users',
        getDataThunk: getUsers,
        requestOptions: getRequestOptions('term'),
      };
    case SearchLayoutMode.Trackers:
      return {
        title: 'Trackers',
        getDataThunk: isAdmin ? getAdminProgressTrackers : getProjectPTsPaged,
        requestOptions: getRequestOptions(),
        queryFieldName: 'trackerName',
      };
    case SearchLayoutMode.DeletedTrackers:
      return {
        title: 'Deleted folder',
        getDataThunk: getDeletedTrackers,
        requestOptions: getRequestOptions(),
        queryFieldName: 'trackerName',
      };
    case SearchLayoutMode.Spaces:
      return {
        title: 'Spaces',
        getDataThunk: getAdminSpaces,
        requestOptions: getRequestOptions(),
        queryFieldName: 'spaceName',
      };
  }
};
