import React from 'react';
import { Button, Progress } from 'antd';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';

import styles from './HeaderSection.module.less';
import commonStyles from '../../LandingPage/LandingPage.module.less';

import img from 'assets/img/landing/progress-new.svg';
import people from 'assets/img/landing/people.svg';
import peopleSecond from 'assets/img/landing/people-2.svg';

export default function HeaderSection() {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate('/sign_up');
  };

  return (
    <section className={styles.heroSection}>
      <div className={cx(commonStyles.iconCard, styles.heroIcon)}>
        <i className="icon icon-triangle"></i>
      </div>

      <div className={commonStyles.container}>
        <h1 className={styles.heroTitle}>
          Automate Status
          <div className={styles.heroTitleAccent}>Updates Effectively</div>
        </h1>
        <div className={styles.heroSubTitle}>
          <span>
            <h2>Streamline communications</h2>
          </span>
        </div>
        <p className={styles.heroCaption}>
          <h3>
            Apdated's AI-driven application automates the way businesses keep their customers in the loop.
          </h3>
        </p>

        <div className={styles.heroBtn}>
          <Button type="primary" size="large" block onClick={handleGetStarted}>
            Get started for free
            <i className="icon icon-arrow-right"></i>
          </Button>
        </div>
      </div>

      <div className={cx('ticket', styles.heroCard)}>
        <div className="ticket__left">
          <div className="ticket__progress">
            <Progress
              type="circle"
              percent={100}
              format={(percent) => `${percent}%`}
              strokeColor={'#00AA63'}
              strokeWidth={10}
              size={54}
            />
          </div>
          <div>
            <div className="ticket__name">Tax Services Onboarding</div>

            <div className="ticket__item">
              <span>Trackers:</span> 1
            </div>
          </div>
        </div>

        <div className="ticket__right">
          <div className="ticket__shared">Shared with</div>

          <img src={people} alt="people" />
        </div>
      </div>

      <div className={cx('ticket', styles.heroCard, styles.heroCardSecond)}>
        <div className="ticket__left">
          <div className="ticket__progress">
            <Progress
              type="circle"
              percent={45}
              format={(percent) => `${percent}%`}
              strokeColor={'#FEC021'}
              strokeWidth={10}
              size={54}
            />
          </div>
          <div>
            <div className="ticket__name">Loan Application</div>

            <div className="ticket__item">
              <span>Trackers:</span> 8
            </div>
          </div>
        </div>

        <div className="ticket__right">
          <div className="ticket__shared">Shared with</div>
          <img src={peopleSecond} alt="people" />
        </div>
      </div>

      <div className={cx('ticket', styles.heroCard, styles.heroCardThird)}>
        <div className="ticket__left">
          <div className="ticket__progress">
            <Progress
              type="circle"
              percent={75}
              format={(percent) => `${percent}%`}
              strokeColor={'#FEC021'}
              strokeWidth={10}
              size={54}
            />
          </div>
          <div className={styles.heroCardThirdBox}>
            <div className="ticket__name">Event Planning</div>

            <div className="ticket__item">
              <span>Due date: </span> 10.02.2022
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
