import React from 'react';
import cx from 'classnames';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import Pagination, { LandingSectionsEnum } from '../Pagination/Pagination';

import styles from './CustomizeSection.module.less';
import commonStyles from '../../LandingPage/LandingPage.module.less';

export default function CustomizeSection() {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate('/sign_up');
  };

  return (
    <section
      className={styles.customizeSection}
      id={LandingSectionsEnum.Customize}
    >
      <div className={styles.wrapper}>
        <div className={commonStyles.container}>
          <div className={styles.content}>
            <div className={cx(styles.title, commonStyles.title)}>
              <h2>
                Fully <span>customizable</span> INDUSTRY TEMPLATES
              </h2>
            </div>
            <div className={styles.text}>
              <p>
                <h3>
                  Whether you’re a law firm or a college admissions office, you
                  can use Apdated’s customizable and responsive templates to get
                  started. Choose from 100+ templates and simply drag and drop
                  to customize anything.
                </h3>
              </p>
              <div className={styles.heroBtn}>
                <Button
                  type="primary"
                  size="large"
                  block
                  onClick={handleGetStarted}
                >
                  Get started for free
                  <i className="icon icon-arrow-right"></i>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Pagination
        className={styles.pagination}
        activeSection={LandingSectionsEnum.Customize}
      />
    </section>
  );
}
