import React, { useCallback, useRef } from 'react';
import { Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'redux/store';
import {
  hasMoreSelector,
  lastNotificationIdSelector,
  notificationsSelector,
} from 'redux/selectors/notificationSelectors';
import { getNotifications } from 'redux/reducers/notificationSlice';
import NotificationCard from './NotificationCard';

import styles from '../HeaderNotifications.module.less';

type Props = {
  onNotificationClick: () => void;
};

export default React.memo((props: Props) => {
  const { onNotificationClick } = props;
  const dispatch: AppDispatch = useDispatch();
  const infiniteScrollParentRef = useRef(null);
  const hasMore = useSelector(hasMoreSelector);
  const notifications = useSelector(notificationsSelector);
  const lastNotificationId = useSelector(lastNotificationIdSelector);

  const onLoadMore = useCallback(
    () => {
      if (!lastNotificationId) return;

      dispatch(
        getNotifications(lastNotificationId),
      );
    },
    [lastNotificationId, dispatch],
  );

  return (
    <div className={styles.notificationList} ref={infiniteScrollParentRef}>
      {!Boolean(notifications.length) && (
        <div className={styles.notifyEmpty}>
          <div className={styles.notifyEmptyProgress}>
            <strong>0%</strong>
          </div>
          <div className={styles.notifyEmptyTitle}>No new notifications.</div>
          <div>
            As you get going, they will appear here instantly.
          </div>
        </div>
      )}

      <InfiniteScroll
        pageStart={0}
        loadMore={onLoadMore}
        hasMore={hasMore}
        loader={<Spin key={0} />}
        useWindow={false}
        getScrollParent={() => infiniteScrollParentRef.current}
      >
        {notifications.map((notification) => (
          <NotificationCard
            key={notification.id}
            notification={notification}
            onNotificationClick={onNotificationClick}
          />
        ))}
      </InfiniteScroll>
    </div>
  )
});
