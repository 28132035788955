import React, { useState } from 'react';
import { Form } from 'antd';
import Controller from 'components/form/Controller';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import cx from 'classnames';

import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import {
  generateTwoFACodeForActivation,
  getUserInfo,
} from 'redux/reducers/authenticationSlice';
import { userSelector } from 'redux/selectors/authenticationSelectors';
import { AppDispatch } from 'redux/store';
import DisableTwoFAModal from 'features/account/AccountSettingsPage/SecurityTab/DisableTwoFAModal';
import EnableTwoFAModal from 'features/account/AccountSettingsPage/SecurityTab/EnableTwoFAModal';
import TwoFAInstructionModal from './TwoFAInstructionModal';
import { TwoFATypeEnum } from 'api';
import Checkbox from 'components/form/Checkbox/Checkbox';
import { currentSpaceIdByStoreSelector } from 'redux/selectors/spacesSelectors';

import styles from '../SecurityTab.module.less';

const validationSchema = Yup.object().shape({
  isEmailTwoFA: Yup.boolean(),
  isQrCodeTwoFA: Yup.boolean(),
});

interface TwoFAForm {
  isEmailTwoFA: boolean;
  isQrCodeTwoFA: boolean;
}

export default function TwoFASection() {
  const [isTwoFAInstructionModalOpen, setIsTwoFAInstructionModalOpen] =
    useState(false);
  const [isEnableTwoFAModalOpen, setIsEnableTwoFAModalOpen] = useState(false);
  const [isDisableTwoFAModalOpen, setIsDisableTwoFAModalOpen] = useState(false);
  const [twoFACodeId, setTwoFACodeId] = useState<string>();
  const user = useSelector(userSelector);
  const dispatch: AppDispatch = useDispatch();

  const { control, formState, watch, setValue } = useForm<TwoFAForm>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      isEmailTwoFA: user?.twoFAType === TwoFATypeEnum.Email,
      isQrCodeTwoFA: user?.twoFAType === TwoFATypeEnum.Authenticator,
    },
  });
  const { errors } = formState;
  const isEmailTwoFAWatched = watch('isEmailTwoFA');
  const isQrCodeTwoFAWatched = watch('isQrCodeTwoFA');

  const handleTwoFAInstructionModalOpen = () => {
    setIsTwoFAInstructionModalOpen(true);
  };

  const handleTwoFAInstructionModalClose = () => {
    setIsTwoFAInstructionModalOpen(false);
  };

  const handleCloseEnableTwoFAModal = () => {
    setIsEnableTwoFAModalOpen(false);
    setTwoFACodeId(undefined);
  };

  const handleCloseDisableTwoFAModal = () => {
    setIsDisableTwoFAModalOpen(false);
  };

  const handleChangeEmailTwoFAStatus = async (checked: boolean) => {
    if (checked) {
      const result = await dispatch(
        generateTwoFACodeForActivation(TwoFATypeEnum.Email),
      );

      if (generateTwoFACodeForActivation.fulfilled.match(result)) {
        setTwoFACodeId(result.payload.codeId);
        setIsEnableTwoFAModalOpen(true);
      } else {
        AlertMessage.error(result.error.message || ApiErrorMessage);
      }
    } else {
      setIsDisableTwoFAModalOpen(true);
    }
  };

  const handleChangeQrCodeTwoFAStatus = (checked: boolean) => {
    if (checked) return handleTwoFAInstructionModalOpen();
    setIsDisableTwoFAModalOpen(true);
  };

  const updateTwoFAStatus = (isEmail: boolean, isQrCode: boolean) => {
    setValue('isEmailTwoFA', isEmail);
    setValue('isQrCodeTwoFA', isQrCode);
    dispatch(getUserInfo(currentSpaceIdByStoreSelector()));

    if (isQrCode) {
      handleTwoFAInstructionModalClose();
    }
  };

  const onSuccessTwoFADisabled = () => {
    updateTwoFAStatus(false, false);
  };

  const onSuccessEmailTwoFAEnable = () => {
    updateTwoFAStatus(true, false);
  };

  const onSuccessQrCodeTwoFAEnable = () => {
    updateTwoFAStatus(false, true);
  };

  return (
    <>
      <TwoFAInstructionModal
        open={isTwoFAInstructionModalOpen}
        onClose={handleTwoFAInstructionModalClose}
        onSuccess={onSuccessQrCodeTwoFAEnable}
      />

      <EnableTwoFAModal
        open={isEnableTwoFAModalOpen}
        onClose={handleCloseEnableTwoFAModal}
        twoFACodeId={twoFACodeId}
        onSuccess={onSuccessEmailTwoFAEnable}
      />

      <DisableTwoFAModal
        open={isDisableTwoFAModalOpen}
        onClose={handleCloseDisableTwoFAModal}
        onSuccess={onSuccessTwoFADisabled}
        twoFAType={
          isEmailTwoFAWatched
            ? TwoFATypeEnum.Email
            : TwoFATypeEnum.Authenticator
        }
      />

      <div className={styles.auth}>
        <Form layout="vertical">
          <div className={styles.authText}>
            <h4>Two-Factor Security</h4>
            <div>
              Two-factor authentication enhances your account security by
              requiring an additional code for logins on unrecognized devices.
            </div>
          </div>
          <div className={styles.authBox}>
            <div
              className={cx(styles.item, {
                [styles.selected]: isEmailTwoFAWatched,
              })}
            >
              {isEmailTwoFAWatched && (
                <div className={styles.label}>Selected</div>
              )}
              <Controller
                name="isEmailTwoFA"
                control={control}
                error={errors.isEmailTwoFA}
                render={(field) => (
                  <Checkbox
                    {...field}
                    onChange={(checked) =>
                      handleChangeEmailTwoFAStatus(checked)
                    }
                  >
                    <div className={styles.title}>
                      Enable Email Authentication
                    </div>
                    <div>
                      Enable Email Authentication - Two-Factor Security Use an
                      extra layer of security via a unique code that will be
                      sent to your email as part of your Two-Factor
                      Authentication (2FA) when logging in or resetting your
                      password.
                    </div>
                  </Checkbox>
                )}
              />
            </div>

            <div
              className={cx(styles.item, {
                [styles.selected]: isQrCodeTwoFAWatched,
              })}
            >
              {isQrCodeTwoFAWatched && (
                <div className={styles.label}>Selected</div>
              )}
              <Controller
                name="isQrCodeTwoFA"
                control={control}
                error={errors.isQrCodeTwoFA}
                render={(field) => (
                  <Checkbox
                    {...field}
                    onChange={(checked) =>
                      handleChangeQrCodeTwoFAStatus(checked)
                    }
                  >
                    <div className={styles.title}>Use Mobile App</div>
                    <div>
                      We’ll recommend an app to download if you don’t have one.
                      It will generate a code that you’ll enter when you log in.
                    </div>
                  </Checkbox>
                )}
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}
