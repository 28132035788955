import React from 'react';
import { Form, Radio } from 'antd';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { PaymentMethodResponseModel } from 'api';
import SettingsDetailsSection from 'components/settings/SettingsDetailsSection';
import { CardMode, CreditCard } from 'components/payments/CreditCard';
import RadioGroup from 'components/form/RadioGroup';
import Controller from 'components/form/Controller';
import { requiredMessage } from 'constants/ValidationConstants';
import { MAX_CARD_NUMBER } from 'constants/PricingConstants';

import styles from './ChangeCreaditCardSection.module.less';

const validationSchema = Yup.object().shape({
  creditCard: Yup.object().required(requiredMessage),
});

type FormValues = {
  creditCard: PaymentMethodResponseModel;
};

type Props = {
  creditCards?: PaymentMethodResponseModel[];
  currentCard?: PaymentMethodResponseModel;
  onChangeCurrentCard: (newCard: PaymentMethodResponseModel) => void;
  onAddNewCardClick: () => void;
  onCancel: () => void;
  title: string;
  description: string;
};

export default function ChangeCreditCardSection(props: Props) {
  const {
    creditCards,
    onChangeCurrentCard,
    onAddNewCardClick,
    currentCard,
    onCancel,
    title,
    description,
  } = props;

  const { control, formState, errors, handleSubmit } = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: { creditCard: currentCard },
  });

  return (
    <Form
      onFinish={handleSubmit((values) =>
        onChangeCurrentCard(values.creditCard),
      )}
    >
      <SettingsDetailsSection
        title={title}
        description={description}
        formState={formState}
        onClose={onCancel}
      />

      <div className="card-list">
        <Controller
          containerClassName={styles.card}
          name="creditCard"
          control={control}
          error={errors.creditCard?.id}
          render={(field) => (
            <RadioGroup {...field}>
              {creditCards?.map((card) => (
                <Radio.Button value={card}>
                  <div key={card.id} className={styles.cardWrapper}>
                    <CreditCard card={card} mode={CardMode.View} />
                  </div>
                </Radio.Button>
              ))}
            </RadioGroup>
          )}
        />
      </div>

      {creditCards && creditCards.length < MAX_CARD_NUMBER && (
        <div className="card-box">
          <div className="card card--btn" onClick={onAddNewCardClick}>
            <div className={styles.icon}>
              <i className="icon icon-plus" />
            </div>
            <div>Add new card</div>
          </div>
        </div>
      )}
    </Form>
  );
}
