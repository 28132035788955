import React, { useEffect, useState } from 'react';
import { App, Button, Form, Row, Spin } from 'antd';
import pluralize from 'pluralize';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as Yup from 'yup';
import { isEmpty, isNil } from 'lodash';

import {
  PreSetupPTTemplateDetailsResponseModel,
  ProgressTrackerBaseModel,
  ProgressTrackerListRequestModel,
  ProgressTrackerTemplateRequestModel,
  ProgressTrackerTemplateResponseModel,
  PTStageRequestModel,
} from 'api';
import AlertMessage from 'components/AlertMessage';
import {
  ProgressTrackerFormValues,
  StageFormValues,
} from 'components/ProgressTracker/ProgressTrackerEdit';
import { generateMockId } from 'components/ProgressTracker/Stage/utils';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { RestrictionModalTypeEnum } from 'features/account/AccountSettingsPage/RestrictionModal';
import {
  AdditionalTrackerRestrictionProps,
  withTrackerRestrictionsControl,
} from 'hocs/withTrackerRestrictionsControl';
import StageListPopover from './StageListPopover';
import {
  createTemplate,
  getPreSetupProgressTrackerTemplateDetails,
  getPreSetupProgressTrackerTemplateDetailsByAdmin,
  getTemplateDetails,
} from 'redux/reducers/templateSlice';
import { AppDispatch } from 'redux/store';
import UseTemplateQuantityModal from 'features/template/UseTemplateQuantityModal';
import { currentSpaceIdByStoreSelector } from 'redux/selectors/spacesSelectors';
import { createProgressTrackerList } from 'redux/reducers/progressTrackerSlice';
import { isTrackerRestrictionsLoadingSelector } from 'redux/selectors/progressTrackerSelectors';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import { TemplateEnum } from '../TemplateListPage/UserTemplatesListPage';
import ProjectListModal from '../TemplateEdit/ProjectListModal';
import { createProject } from 'redux/reducers/projectSlice';
import AddProjectModal from '../TemplateEdit/AddProjectModal';
import { getErrorMessages } from 'utils/toastUtils';
import { isAdminSelector } from 'redux/selectors/authenticationSelectors';

import styles from './UseTemplatesPage.module.less';

const getValidationSchema = (isTemplateFromLibrary: boolean) =>
  Yup.object().shape(
    {
      folderId: Yup.string()
        .nullable()
        .when('folderName', {
          is: (folderName: string) =>
            isNil(folderName) && isTemplateFromLibrary,
          then: Yup.string().required('Field is required'),
        }),
      folderName: Yup.string()
        .nullable()
        .when('folderId', {
          is: (folderId: string) => isNil(folderId) && isTemplateFromLibrary,
          then: Yup.string().required('Field is required'),
        })
        .max(30, 'Project name should be 30 characters maximum'),
    },
    [['folderName', 'folderId']],
  );

export type UseTemplateFormValues = {
  trackers: ProgressTrackerFormValues[];
  folderId?: string;
  folderName?: string;
};

type Props = AdditionalTrackerRestrictionProps & {
  mode?: TemplateEnum;
};

type Params = {
  templateId: string;
};

type TemplateInterface =
  | PreSetupPTTemplateDetailsResponseModel
  | ProgressTrackerTemplateResponseModel;

const UseTemplatesPage = React.memo(function (props: Props) {
  const { canCreate, mode = TemplateEnum.MyTemplates } = props;
  const [isAddProjectModalOpen, setIsAddProjectModalOpen] = useState(false);
  const [isProjectListModalOpen, setIsProjectListModalOpen] = useState(false);
  const [templateDetails, setTemplateDetails] = useState<TemplateInterface>();
  const [isTemplateDetailsLoading, setIsTemplateDetailsLoading] =
    useState(false);
  const [isQuantityModalOpen, setIsQuantityModalOpen] = useState(false);
  const isTrackerRestrictionsLoading = useSelector(
    isTrackerRestrictionsLoadingSelector,
  );
  const isAdmin = useSelector(isAdminSelector);
  const isLightTheme = useSelector(isLightThemeSelector);
  const { state } = useLocation();
  const { templateId } = useParams<Params>();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { modal } = App.useApp();
  const isMyTemplate = mode === TemplateEnum.MyTemplates;

  const formMethods = useForm<UseTemplateFormValues>({
    mode: 'all',
    resolver: yupResolver(getValidationSchema(!isMyTemplate)),
    shouldUnregister: false,
    //We cannot use yup resolver because of performance issue
  });
  const { control, formState, reset, handleSubmit, trigger } = formMethods;
  const { isDirty, errors, isSubmitting } = formState;

  const { fields } = useFieldArray<ProgressTrackerFormValues>({
    control,
    name: 'trackers',
  });

  const handleCloseProjectListModal = () => {
    setIsProjectListModalOpen(false);
    resetField('folderId');
  };

  const getCorrectAction = () => {
    if (isMyTemplate) return getTemplateDetails;

    return isAdmin
      ? getPreSetupProgressTrackerTemplateDetailsByAdmin
      : getPreSetupProgressTrackerTemplateDetails;
  };

  const loadTemplateDetails = async () => {
    setIsTemplateDetailsLoading(true);

    const correctAction = getCorrectAction();

    const result = await dispatch(correctAction(templateId || ''));
    setIsTemplateDetailsLoading(false);

    if (!correctAction.fulfilled.match(result)) {
      return AlertMessage.error(result.error.message || ApiErrorMessage);
    }

    setTemplateDetails(result.payload);
  };

  useEffect(() => {
    loadTemplateDetails();
  }, [templateId]);

  useEffect(() => {
    if (!state?.quantity && canCreate) {
      return setIsQuantityModalOpen(true);
    }
  }, [state?.quantity, canCreate]);

  useEffect(() => {
    if (!state?.quantity || !canCreate) return;

    const stages: StageFormValues[] =
      templateDetails?.stages?.map((stage) => ({
        id: generateMockId(),
        name: stage.name || '',
        description: stage.description || '',
        dueDate: null,
        isManuallyAdded: false,
      })) || [];

    const trackers: ProgressTrackerFormValues[] = [
      ...Array(state.quantity),
    ].map(() => ({
      name: '',
      stages,
    }));

    const resetData: UseTemplateFormValues = {
      trackers,
    };

    reset(resetData);
  }, [reset, templateDetails, state?.quantity, canCreate]);

  const handleCancelClick = () => {
    const url = isMyTemplate
      ? `/template/${templateId}/details`
      : `/template-library/${templateId}/details`;

    const onCancel = () => navigate(url);

    if (isDirty) {
      return modal.confirm({
        title: 'Changes won’t be saved. Are you sure?',
        icon: <span className="icon icon-info-circle confirm-icon" />,
        className: cx('confirm-modal', {
          'confirm-modal-dark-button': !isLightTheme,
        }),
        width: '496px',
        onOk: onCancel,
      });
    }

    onCancel();
  };

  const handleCloseQuantityModal = (isCancelling: boolean = false) => {
    setIsQuantityModalOpen(false);

    if (isCancelling) {
      const url = isMyTemplate
        ? `/template/${templateId}/details`
        : `/template-library/${templateId}/details`;

      navigate(url);
    }
  };

  const handleOpenQuantityModal = () => setIsQuantityModalOpen(true);

  const mapStages = (stages: StageFormValues[]): PTStageRequestModel[] =>
    stages.map((stage, idx) => ({
      attachments: stage.attachments,
      name: stage.name.trim(),
      description: stage.description.trim(),
      sequence: idx + 1,
      dueDate: stage.dueDate as any,
    }));

  const onCreateProgressTrackerList = async (
    values: UseTemplateFormValues,
    folderId = (templateDetails as ProgressTrackerTemplateResponseModel)
      ?.folderId,
  ) => {
    const progressTrackers: ProgressTrackerBaseModel[] = values.trackers.map(
      (tracker) => ({
        name: tracker.name,
        folderId: folderId,
        stages: mapStages(tracker.stages),
      }),
    );

    const requestBody: ProgressTrackerListRequestModel = {
      progressTrackerTemplateId: templateId,
      spaceId: currentSpaceIdByStoreSelector(),
      progressTrackers,
      isPreSetup: !isMyTemplate,
    };

    const result = await dispatch(createProgressTrackerList(requestBody));

    if (createProgressTrackerList.fulfilled.match(result)) {
      AlertMessage.success(
        `${pluralize('progress tracker', values.trackers.length, true)}
        successfully created.`,
      );

      if (!isMyTemplate) await onSaveTemplateToMyList(folderId);

      navigate('.', {
        state: {
          quantity: undefined,
        },
      });
      navigate(`/project/${folderId}`);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const createNewProject = async (folderName: string) => {
    const result = await dispatch(createProject({ name: folderName }));

    if (createProject.fulfilled.match(result)) {
      return result.payload;
    } else {
      const message = getErrorMessages(result);
      AlertMessage.error(message);
    }
  };

  const onCreateProject = async (values: UseTemplateFormValues) => {
    const projectName = values.folderName?.trim();
    const projectId = values.folderId?.trim();

    if (projectName) {
      const projectId = await createNewProject(projectName);
      return (
        !isNil(projectId) &&
        (await onCreateProgressTrackerList(values, projectId))
      );
    }

    await onCreateProgressTrackerList(values, projectId);
  };

  const onSubmit = async (values: UseTemplateFormValues) => {
    await trigger(['name', 'trackers']);

    const isMyTemplateWithoutFolder =
      isMyTemplate &&
      !(templateDetails as ProgressTrackerTemplateRequestModel)?.folderId;

    if (!isMyTemplate || isMyTemplateWithoutFolder) {
      isEmpty(errors) && setIsProjectListModalOpen(true);
    } else {
      await onCreateProject(values);
    }
  };

  const handleSave = async () => {
    await trigger();
    await handleSubmit(onCreateProject)();
  };

  const handleAddProjectModalOpen = () => {
    setIsAddProjectModalOpen(true);
  };

  const handleAddProjectModalClose = () => {
    setIsAddProjectModalOpen(false);
    setIsProjectListModalOpen(true);
    resetField('folderId');
    resetField('folderName');
  };

  const resetField = (fieldName: string) => {
    formMethods.clearErrors(fieldName);
    formMethods.setValue(fieldName, undefined);
  };

  const onSaveTemplateToMyList = async (folderId?: string) => {
    const requestBody: ProgressTrackerTemplateRequestModel = {
      stages: templateDetails?.stages,
      folderId,
      name: templateDetails?.name,
    };
    const result = await dispatch(createTemplate(requestBody));

    if (createTemplate.fulfilled.match(result)) {
      AlertMessage.success('The template was successfully added.');
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  if (isTemplateDetailsLoading || isTrackerRestrictionsLoading) return <Spin />;

  return (
    <>
      <ProjectListModal
        open={isProjectListModalOpen}
        onOk={handleSave}
        onCancel={handleCloseProjectListModal}
        onAddProjectModalOpen={handleAddProjectModalOpen}
        control={control}
        error={errors.folderId}
        isTemplateFromLibrary={!isMyTemplate}
        resetField={!isMyTemplate ? resetField : undefined}
      />

      <AddProjectModal
        open={isAddProjectModalOpen}
        onOk={handleSave}
        control={control}
        error={errors.folderName}
        onClose={handleAddProjectModalClose}
      />

      <UseTemplateQuantityModal
        open={isQuantityModalOpen}
        onClose={handleCloseQuantityModal}
        onOpen={handleOpenQuantityModal}
        mode={mode}
      />

      <div className="page-wrapper scroll-box ">
        <div className="page-box page-holder">
          {state?.quantity && (
            <FormProvider {...formMethods}>
              <Form onFinish={handleSubmit(onSubmit)}>
                <div className={styles.btnBox}>
                  <div>
                    <Button
                      size="large"
                      onClick={handleCancelClick}
                      className={cx({ 'dark-btn-outline': !isLightTheme })}
                    >
                      Cancel
                    </Button>
                  </div>

                  <div>
                    <Button
                      loading={isSubmitting}
                      size="large"
                      className="dark-btn"
                      disabled={!fields.length || !isDirty}
                      htmlType="submit"
                    >
                      Create
                    </Button>
                  </div>
                </div>

                <Row gutter={[10, 0]}>
                  {fields.map((field, index) => (
                    <StageListPopover
                      index={index}
                      defaultValue={field.name}
                      key={field.id}
                    />
                  ))}
                </Row>
              </Form>
            </FormProvider>
          )}
        </div>
      </div>
    </>
  );
});

export default withTrackerRestrictionsControl({
  restrictionType: RestrictionModalTypeEnum.CreateTrackerFromTemplate,
  getRedirectUrlOnClose: (paramId?: string) => `/template/${paramId}/details`,
  paramName: 'templateId',
})(UseTemplatesPage);
