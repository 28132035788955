import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Form } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import cx from 'classnames';

import PTModal, { PTModalProps } from 'components/PTModal';
import { AppDispatch } from 'redux/store';
import { enableTwoFA } from 'redux/reducers/authenticationSlice';
import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import Controller from 'components/form/Controller';
import MaskedInput from 'components/form/MaskedInput/MaskedInput';
import { requiredMessage, twoFACodeMask } from 'constants/ValidationConstants';
import { TwoFATypeEnum } from 'api';
import { userSelector } from 'redux/selectors/authenticationSelectors';

import styles from '../../SecurityTab.module.less';

interface FormValues {
  code: string;
}

type Props = {
  onClose: () => void;
  onSuccess: () => void;
} & Omit<PTModalProps, 'children'>;

const validationSchema = Yup.object().shape({
  code: Yup.string()
    .trim()
    .required(requiredMessage)
    .min(6, 'Code should be 6 characters minimum'),
});

export default function TwoFAQrCodeConfirmModal(props: Props) {
  const { onClose, onSuccess, ...otherModalProps } = props;
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector(userSelector);
  const { control, handleSubmit, errors } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: {
      code: undefined,
    },
  });

  const onSubmit = async (data: FormValues) => {
    const codeWithoutDashes = data.code.replace(/-/g, '');

    const result = await dispatch(
      enableTwoFA({
        code: codeWithoutDashes,
        twoFaType: TwoFATypeEnum.Authenticator,
        userId: user?.id,
      }),
    );

    if (enableTwoFA.fulfilled.match(result)) {
      onSuccess();
      onClose();
      AlertMessage.success(
        'Mobile Two-Factor Authentication was successfully enabled.',
      );
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  return (
    <PTModal
      {...otherModalProps}
      onCancel={onClose}
      title="Instruction"
      okText="Enable"
      cancelText="Back"
      className="modal-root"
      onOk={handleSubmit(onSubmit)}
    >
      <div className="modal-scroll-wrapper">
        <Form layout="vertical">
          <div className={styles.instruction}>
            <div>Get your code from your authentication app.</div>
            <p>Enter the 6-digit code generated by your authentication app.</p>
          </div>

          <Controller
            label="Code"
            name="code"
            control={control}
            render={(props) => (
              <MaskedInput
                {...props}
                placeholder="XXX-XXX"
                mask={twoFACodeMask}
              />
            )}
            error={errors.code}
            containerClassName={cx(styles.input, 'dark-input-group')}
          />
        </Form>
      </div>
    </PTModal>
  );
}
