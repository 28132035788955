import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Button, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

import {
  SpaceLastActivityResponseModel,
  SpaceLastActivityResponseModelPagedResponseModel,
} from 'api';
import { getSpaceLastActivity } from 'redux/reducers/dashboardSlice';
import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { AppDispatch } from 'redux/store';
import { PageDefaultSize } from 'constants/ApiConstant';
import { formatDate, groupActivitiesByDate } from 'utils/dashboardUtils';

import {
  getActivityHeadText,
  getActivityIcon,
} from '../LastActivitySection/LastActivitySection';

import styles from './LastActivityWidget.module.less';
import classes from '../LastActivitySection/LastActivitySection.module.less';

type Props = {
  onClose: () => void;
  innerScroll: React.MutableRefObject<null>;
};

export default function LastActivityWidget(props: Props) {
  const { onClose, innerScroll } = props;
  const [isVisible, setIsVisible] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [lastActivityList, setLastActivityList] = useState<
    SpaceLastActivityResponseModel[]
  >([]);
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);

  const dispatch: AppDispatch = useDispatch();

  const groupedActivities = groupActivitiesByDate(lastActivityList);

  const isLightTheme = useSelector(isLightThemeSelector);

  useEffect(() => {
    initInfinityScroll();
  }, []);

  const initInfinityScroll = () => {
    setIsVisible(false);
    setLastActivityList([]);
    setIsInitialDataLoaded(false);
    setHasMore(true);

    const timer = setTimeout(() => {
      // to destroy infinite scroll
      setIsVisible(true);
    }, 0);

    return () => clearTimeout(timer);
  };

  const onSetData = async (
    result: SpaceLastActivityResponseModelPagedResponseModel,
  ) => {
    const loadedData = result.details || [];
    const totalCount = result.totalCount || 0;
    const newData = [...lastActivityList, ...loadedData];
    const hasMoreRows = newData.length < totalCount;

    setHasMore(hasMoreRows);
    setLastActivityList(newData);
  };

  const onLoadMore = async (pageNumber: number) => {
    const result = await dispatch(
      getSpaceLastActivity({
        pageNumber,
        pageSize: PageDefaultSize,
      }),
    );

    if (getSpaceLastActivity.fulfilled.match(result)) {
      await onSetData(result.payload);
    } else {
      setHasMore(false);
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
    setIsInitialDataLoaded(true);
  };

  return (
    <>
      <div className={styles.widgetHead}>
        <div
          className={cx(styles.btn, {
            [styles.dark]: !isLightTheme,
          })}
        >
          <Button onClick={onClose}>
            <i className="icon icon-close" />
          </Button>
        </div>
        <div>Activity Log</div>
      </div>

      {isVisible && (
        <InfiniteScroll
          initialLoad={!isInitialDataLoaded}
          loadMore={onLoadMore}
          pageStart={-1}
          hasMore={hasMore}
          loader={<Spin key={0} />}
          useWindow={false}
          getScrollParent={() => innerScroll?.current}
          threshold={-10}
        >
          <div className={styles.widgetBody}>
            {Object.keys(groupedActivities).map((date) => {
              if (!groupedActivities[date]?.length) return null;

              return (
                <div key={date}>
                  <h2 className={classes.date}>{date}</h2>
                  {groupedActivities[date]?.map((activity, index) => (
                    <div
                      key={(activity.userId || '') + index}
                      className={classes.item}
                    >
                      {getActivityIcon(activity)}
                      <div>
                        {getActivityHeadText(activity)}
                        <p>{formatDate(activity?.activityDate)}</p>
                      </div>
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        </InfiniteScroll>
      )}
    </>
  );
}
