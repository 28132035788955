import React from 'react';
import cx from 'classnames';

import styles from './DetailsButton.module.less';

type Props = {
  isDetailsHidden: boolean;
  onVisibilityChange: (isVisible: boolean) => void;
};

export default function DetailsButton(props: Props) {
  const { isDetailsHidden, onVisibilityChange } = props;

  const handleClickDetailsButton = () => {
    if (!onVisibilityChange) return;

    const className = 'fogging';

    if (!isDetailsHidden) {
      document.body.classList.add(className);
    } else {
      document.body.classList.remove(className);
    }

    onVisibilityChange(!isDetailsHidden);
  };

  return (
    <div onClick={handleClickDetailsButton} className={styles.button}>
      <div
        className={cx(styles.icon, {
          [styles.closed]: isDetailsHidden,
          [styles.opened]: !isDetailsHidden,
        })}
      />
    </div>
  );
}
