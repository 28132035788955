import React, { ReactNode, useEffect } from 'react';
import { App, Tabs } from 'antd';
import { isString } from 'lodash';
import { useSelector } from 'react-redux';
import { Tab } from 'rc-tabs/lib/interface';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import cx from 'classnames';

import {
  SettingsTabEnum,
  trackerManagerMessage,
} from 'constants/SettingsConstants';
import CreditCardsTab from 'features/auth/BillingSettingsPage/CreditCardsTab';
import PricingTab from 'features/auth/BillingSettingsPage/PricingTab';
import InvoiceHistoryTab from 'features/auth/BillingSettingsPage/InvoiceHistoryTab';
import NotificationsTab from 'features/account/AccountSettingsPage/NotificationsTab';
import ProfileTab from 'features/account/AccountSettingsPage/ProfileTab';
import SecurityTab from 'features/account/AccountSettingsPage/SecurityTab';
import { isRedirectBlockedSelector } from 'redux/selectors/commonSelectors';
import UserManagementSection from 'features/account/AccountSettingsPage/UserManagementSection';

type Props = {
  availableTabs: { [key in SettingsTabEnum as string]: boolean };
  defaultTab: SettingsTabEnum;
};

export default function SettingsLayout(props: Props) {
  const isLightTheme = useSelector(isLightThemeSelector);
  const { availableTabs, defaultTab } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { section } = queryString.parse(location.search);
  const isPageSectionMatch = isString(section) && section in availableTabs;
  const isRedirectBlocked = useSelector(isRedirectBlockedSelector);
  const { modal } = App.useApp();

  useEffect(() => {
    if (!isPageSectionMatch) {
      const pathname = `${location.pathname}?${queryString.stringify({
        section: defaultTab,
      })}`;
      navigate(pathname, { replace: true });
    }
  }, [isPageSectionMatch, defaultTab]);

  const onTabChange = (e: React.MouseEvent<HTMLAnchorElement>, tab: string) => {
    if (isRedirectBlocked) {
      e.preventDefault();
      return modal.confirm({
        title: 'Changes won’t be saved. Are you sure?',
        icon: <span className="icon icon-info-circle confirm-icon" />,
        className: cx('confirm-modal', {
          'confirm-modal-dark-button': !isLightTheme,
        }),
        width: '496px',
        onOk() {
          setActiveTabParams(tab);
        },
        onCancel() {},
      });
    }
  };

  const setActiveTabParams = (tab: string) => {
    const pathname = `${location.pathname}?${queryString.stringify({
      section: tab,
    })}`;
    navigate(pathname, { replace: true });
  };

  const getTabContent = (Component: ReactNode, tabAvailability: boolean) =>
    tabAvailability ? Component : trackerManagerMessage;

  const getTabs = (): Tab[] => {
    const allTabs: Tab[] = [
      {
        key: SettingsTabEnum.CreditCard,
        label: (
          <Link
            className="tab-link"
            onClick={(e) => onTabChange(e, SettingsTabEnum.CreditCard)}
            to={{
              search: queryString.stringify({
                section: SettingsTabEnum.CreditCard,
              }),
            }}
            replace
          >
            Credit card
          </Link>
        ),
        children: <CreditCardsTab />,
      },
      {
        key: SettingsTabEnum.Pricing,
        label: (
          <Link
            className="tab-link"
            onClick={(e) => onTabChange(e, SettingsTabEnum.Pricing)}
            to={{
              search: queryString.stringify({
                section: SettingsTabEnum.Pricing,
              }),
            }}
          >
            Pricing
          </Link>
        ),
        children: getTabContent(
          <PricingTab />,
          availableTabs[SettingsTabEnum.Pricing],
        ),
      },
      {
        key: SettingsTabEnum.Payments,
        label: (
          <Link
            className="tab-link"
            onClick={(e) => onTabChange(e, SettingsTabEnum.Payments)}
            to={{
              search: queryString.stringify({
                section: SettingsTabEnum.Payments,
              }),
            }}
          >
            Payments
          </Link>
        ),
        children: <InvoiceHistoryTab />,
      },
      {
        key: SettingsTabEnum.Profile,
        label: (
          <Link
            className="tab-link"
            onClick={(e) => onTabChange(e, SettingsTabEnum.Profile)}
            to={{
              search: queryString.stringify({
                section: SettingsTabEnum.Profile,
              }),
              pathname: '.',
            }}
            replace
          >
            Profile
          </Link>
        ),
        children: getTabContent(
          <ProfileTab />,
          availableTabs[SettingsTabEnum.Profile],
        ),
      },
      {
        key: SettingsTabEnum.UserManagement,
        label: (
          <Link
            className="tab-link"
            onClick={(e) => onTabChange(e, SettingsTabEnum.UserManagement)}
            to={{
              search: queryString.stringify({
                section: SettingsTabEnum.UserManagement,
              }),
            }}
          >
            User Management
          </Link>
        ),
        children: getTabContent(
          <UserManagementSection />,
          availableTabs[SettingsTabEnum.UserManagement],
        ),
      },
      {
        key: SettingsTabEnum.Security,
        label: (
          <Link
            className="tab-link"
            onClick={(e) => onTabChange(e, SettingsTabEnum.Security)}
            to={{
              search: queryString.stringify({
                section: SettingsTabEnum.Security,
              }),
            }}
          >
            Security
          </Link>
        ),
        children: getTabContent(
          <SecurityTab />,
          availableTabs[SettingsTabEnum.Security],
        ),
      },
      {
        key: SettingsTabEnum.Notifications,
        label: (
          <Link
            className="tab-link"
            onClick={(e) => onTabChange(e, SettingsTabEnum.Notifications)}
            to={{
              search: queryString.stringify({
                section: SettingsTabEnum.Notifications,
              }),
            }}
          >
            Notifications
          </Link>
        ),
        children: getTabContent(
          <NotificationsTab />,
          availableTabs[SettingsTabEnum.Notifications],
        ),
      },
    ];

    return allTabs.filter((tab) => tab.key in availableTabs);
  };

  return (
    <div className="page-wrapper scroll-box white-bg">
      <div className="page-box page-holder">
        <div className="page-title h2">Settings</div>
        <div className={cx('tabs-main', { inverse: !isLightTheme })}>
          <Tabs
            tabPosition="left"
            activeKey={isString(section) ? section : defaultTab}
            destroyInactiveTabPane
            items={getTabs()}
          />
        </div>
      </div>
    </div>
  );
}
