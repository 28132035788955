import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form, Input } from 'antd';
import * as Yup from 'yup';
import cx from 'classnames';

import { LandingSectionsEnum } from '../Pagination/Pagination';
import AlertMessage from 'components/AlertMessage';
import Controller from 'components/form/Controller';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { maxLength, requiredMessage } from 'constants/ValidationConstants';
import { sendLetsGetYouApdatedForm } from 'redux/reducers/authenticationSlice';
import { AppDispatch } from 'redux/store';

import styles from './RequestDemoSection.module.less';
import commonStyles from '../../LandingPage/LandingPage.module.less';

import img from 'assets/img/landing/logo-white.svg';
import imgLogo from 'assets/img/landing/apdated-logo.svg';

interface GetApdatedFormValues {
  name: string;
  email: string;
  formMessage: string;
}

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required(requiredMessage)
    .max(maxLength, `Last name should be ${maxLength} characters maximum`),
  email: Yup.string()
    .trim()
    .email('Email is invalid')
    .max(maxLength, `Email should be ${maxLength} characters maximum`)
    .required(requiredMessage),
  formMessage: Yup.string()
    .trim()
    .required(requiredMessage)
    .max(1200, 'Message should be 1200 characters maximum'),
});

export default function RequestDemoSection() {
  const { errors, control, handleSubmit, reset } =
    useForm<GetApdatedFormValues>({
      resolver: yupResolver(validationSchema),
      mode: 'all',
      defaultValues: {
        name: '',
        email: '',
        formMessage: '',
      },
    });
  const dispatch: AppDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isLearnMoreInfoSent, setIsLearnMoreInfoSent] = useState(false);

  const onSubmit = async ({
    name,
    email,
    formMessage,
  }: GetApdatedFormValues) => {
    setIsLoading(true);

    const result = await dispatch(
      sendLetsGetYouApdatedForm({ name, email, message: formMessage }),
    );

    setIsLoading(false);

    if (sendLetsGetYouApdatedForm.fulfilled.match(result)) {
      setIsLearnMoreInfoSent(true);
      reset();
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  return (
    <section className={cx(styles.footerSection, commonStyles.container)}>
      <div className={styles.form} id={LandingSectionsEnum.StartTracker}>
        <div>
          <div className={styles.formSide}>
            <div
              className={cx(
                styles.formTitle,
                commonStyles.title,
                commonStyles.titleDark,
              )}
            >
             <h2>Let's get you</h2>
            </div>
            <div className={styles.logo}>
              <img src={imgLogo} alt="logo" />
            </div>
          </div>
          <ul className={styles.contactList}>
            <li>
              <address>
                <i className="icon icon-message"></i>
                <span>info@apdated.co</span>
              </address>
            </li>

            <li>
              <address>
                <i className="icon icon-pin"></i>
                <span>Fort Lauderdale, FL</span>
              </address>
            </li>
          </ul>
        </div>

        {isLearnMoreInfoSent && (
          <div className={styles.formPopup}>
            <div className={styles.formPopupContent}>
              <div className="success-popup__btn">
                <Button
                  type="link"
                  className={styles.formPopupBtn}
                  onClick={() => setIsLearnMoreInfoSent(false)}
                >
                  <i className="icon icon-close" />
                </Button>
              </div>
              <h2 className={styles.formPopupTitle}>Thank you!</h2>
              <div className={styles.formPopupText}>
                We’ll get back to you as soon as possible.
              </div>
            </div>
          </div>
        )}

        <div className={styles.formBox}>
          <Form layout="vertical">
            <Controller
              name="name"
              control={control}
              as={Input}
              placeholder={'Your name'}
              error={errors.name}
              tabIndex={-1}
              containerClassName={cx(styles.input, 'dark-input-group')}
            />
            <Controller
              name="email"
              control={control}
              as={Input}
              placeholder={'Your email address'}
              error={errors.email}
              tabIndex={-1}
              containerClassName={cx(styles.input, 'dark-input-group')}
            />
            <Controller
              name="formMessage"
              control={control}
              as={Input.TextArea}
              placeholder={'Message'}
              error={errors.formMessage}
              tabIndex={-1}
              containerClassName={cx(styles.input, 'dark-input-group')}
            />
            <Button
              loading={isLoading}
              htmlType="submit"
              block
              className="dark-btn"
              size="large"
              onClick={handleSubmit(onSubmit)}
            >
              Send
            </Button>
          </Form>
        </div>
      </div>
    </section>
  );
}
