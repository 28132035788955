import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export default function DetailsPanelBody(props: Props) {
  const { children } = props;

  return (
    <div className="details-card__content">{children}</div>
  );
}