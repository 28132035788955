import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  notificationsSelector,
  unreadNotificationsCountSelector,
} from 'redux/selectors/notificationSelectors';
import { AppDispatch } from 'redux/store';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import {
  clearAllNotifications,
  getUnreadNotificationsCount,
  readAllNotifications,
} from 'redux/reducers/notificationSlice';
import AlertMessage from 'components/AlertMessage';

import styles from './NotificationListHeader.module.less';

export default function NotificationListHeader() {
  const dispatch: AppDispatch = useDispatch();
  const notifications = useSelector(notificationsSelector);
  const unreadNotificationsCount = useSelector(
    unreadNotificationsCountSelector,
  );

  const handleReadAllNotifications = useCallback(async () => {
    const result = await dispatch(readAllNotifications());
    await dispatch(getUnreadNotificationsCount());

    if (!readAllNotifications.fulfilled.match(result)) {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  }, [dispatch]);

  const handleClearAllNotifications = useCallback(async () => {
    const result = await dispatch(clearAllNotifications());

    if (!clearAllNotifications.fulfilled.match(result)) {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  }, [dispatch]);

  const renderHeaderButtons = () => {
    if (!notifications.length) return null;

    return !!unreadNotificationsCount ? (
      <div onClick={handleReadAllNotifications}>Read all</div>
    ) : (
      <div onClick={handleClearAllNotifications}>Clear all</div>
    );
  };

  return (
    <div className={styles.notifyPopoverHead}>
      <div>Notifications - <strong>{unreadNotificationsCount}</strong></div>

      <div className={styles.notifyPopoverHeadBtn}>{renderHeaderButtons()}</div>
    </div>
  );
}
