import React, { useMemo, useState } from 'react';
import { Row } from 'antd';
import { isArray, isNil, unset } from 'lodash';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import {
  AdminProgressTrackerResponseModel,
  ProgressTrackerSortFieldEnum,
  SortOrderEnum,
} from 'api';
import Breadcrumb from 'components/Breadcrumb';
import SearchInput from 'components/filters/SearchInput';
import SortingFilter, {
  SortingFilterValueType,
  SortingMode,
} from 'components/filters/SortingFilter/SortingFilter';
import TrackerCard from 'features/admin/AdminTrackersPage/TrackerCard';
import { PageDefaultSize } from 'constants/ApiConstant';
import SearchLayout, {
  ChildrenProps,
  SearchLayoutMode,
} from 'components/search/SearchLayout';

enum TrackersFiltersTypes {
  TrackerName = 'trackerName',
  Sorting = 'sorting',
}

interface TrackersFilters {
  [TrackersFiltersTypes.TrackerName]: string;
  [TrackersFiltersTypes.Sorting]: SortingFilterValueType;
}

export default function AdminTrackersPage() {
  const location = useLocation();
  const { trackerName, sortField, sortOrder } = queryString.parse(
    location.search,
  );

  const initialValues: TrackersFilters = {
    [TrackersFiltersTypes.TrackerName]:
      !isArray(trackerName) && !isNil(trackerName) ? trackerName : '',
    [TrackersFiltersTypes.Sorting]: {
      sortField:
        !isArray(sortField) && !isNil(sortField)
          ? +sortField
          : ProgressTrackerSortFieldEnum.ProgressTrackerName,
      sortOrder:
        !isArray(sortOrder) && !isNil(sortOrder)
          ? +sortOrder
          : SortOrderEnum.Asc,
    },
  };
  const [filters, setFilters] = useState<TrackersFilters>(initialValues);

  const handleSearch = (query: string) => {
    setFilters({
      ...filters,
      [TrackersFiltersTypes.TrackerName]: query,
    });
  };

  const handleSort = (sortParams: SortingFilterValueType) => {
    setFilters({
      ...filters,
      [TrackersFiltersTypes.Sorting]: sortParams,
    });
  };

  const renderBreadcrumbs = () => (
    <Breadcrumb
      routes={[
        { title: <i className="icon icon-home" />, link: '/' },
        { title: 'Trackers' },
      ]}
    />
  );

  const renderSearchInput = () => (
    <SearchInput
      onChange={handleSearch}
      value={filters[TrackersFiltersTypes.TrackerName]}
      placeholder="Search trackers"
    />
  );

  const renderSorting = () => (
    <div>
      <SortingFilter
        value={filters[TrackersFiltersTypes.Sorting]}
        onChange={handleSort}
        mode={SortingMode.AdminTrackers}
      />
    </div>
  );

  const renderData = (
    childrenProps: ChildrenProps<AdminProgressTrackerResponseModel, any>,
  ) => (
    <Row gutter={[20, 20]}>
      {childrenProps.details.map((tracker) => (
        <TrackerCard tracker={tracker} key={tracker.id} />
      ))}
    </Row>
  );

  const resultFilters = useMemo(() => {
    const newFilters = {
      query: filters[TrackersFiltersTypes.TrackerName],
      ...filters,
    };
    unset(newFilters, [TrackersFiltersTypes.TrackerName]);
    return newFilters;
  }, [filters]);

  return (
    <SearchLayout
      mode={SearchLayoutMode.Trackers}
      filters={resultFilters}
      columns={1}
      minPageSize={PageDefaultSize}
      renderBreadcrumbsNode={renderBreadcrumbs}
      filtersNode={renderSorting()}
      searchNode={renderSearchInput()}
    >
      {renderData}
    </SearchLayout>
  );
}
