import React, { useCallback, useState } from 'react';
import { App, Button, Dropdown, Layout, MenuProps, Switch } from 'antd';
import cx from 'classnames';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Accessor from 'components/accessors';
import ChangeSpaceDropdown from 'components/ChangeSpaceDropdown';
import { DesktopScreenQuery } from 'constants/ScreenQuery';
import { RolesEnum } from 'enums/Roles.enum';
import { HomePageSteps, OnboardingPagesEnum } from 'enums/OnboardingEnums';
import HeaderNotifications from 'features/notification/HeaderNotifications';
import { useQueryMatch } from 'hooks/useQueryMatch';
import { isRedirectBlockedSelector } from 'redux/selectors/commonSelectors';
import {
  logout,
  setOnboardingPagesChecked,
} from 'redux/reducers/authenticationSlice';
import {
  isAdminSelector,
  userSelector,
} from 'redux/selectors/authenticationSelectors';
import { activeTourStepSelector } from 'redux/selectors/onboardingSelector';
import {
  isCustomLogoLoadingSelector,
  logoUrlSelector,
} from 'redux/selectors/progressTrackerSelectors';
import { isSuperAdminSelector } from 'redux/selectors/roleSelectors';
import { AppDispatch } from 'redux/store';
import { isHomePage } from 'utils/pathnameUtils';
import { startOnboarding } from 'redux/reducers/onboardingSlice';
import { IS_PRODUCTION_ENV } from 'constants/env.constants';
import { LayoutModeEnum } from 'enums/LayoutModeEnum';
import { setLayoutMode } from 'redux/reducers/themeSlice';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

import 'features/onboarding/Ondoarding.less';
import imgLogoNew from 'assets/img/landing/apdated-logo-header.svg';

import AccountSettings from 'assets/img/menu/profile.png';
import Archive from 'assets/img/menu/archive.png';
import BillingSettings from 'assets/img/menu/money-bill.png';
import DeletedFolder from 'assets/img/menu/deleted-folder.png';
import Wizard from 'assets/img/menu/wizard.png';
import Logout from 'assets/img/menu/log-out.png';
import DarkMode from 'assets/img/menu/moon.png';
import HelpCenter from 'assets/img/menu/help-center.png';
import ApiCode from 'assets/img/api_code.svg';
import Blogging from 'assets/img/blogging.svg';

import styles from './AppHeader.module.less';

type Props = {
  handleSidebarChange?: (isOpen: boolean) => void;
  isSidebarOpen?: boolean;
};

export default function AppHeader(props: Props) {
  const { handleSidebarChange, isSidebarOpen } = props;
  const { pathname } = useLocation();
  const user = useSelector(userSelector);
  const dispatch: AppDispatch = useDispatch();
  const { modal } = App.useApp();
  const isRedirectBlocked = useSelector(isRedirectBlockedSelector);
  const isAdmin = useSelector(isAdminSelector);
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const logoUrl = useSelector(logoUrlSelector);
  const navigate = useNavigate();
  const isCustomLogoLoading = useSelector(isCustomLogoLoadingSelector);
  const activeTourStep = useSelector(activeTourStepSelector);
  const isLightTheme = useSelector(isLightThemeSelector);
  const isDesktop = useQueryMatch(DesktopScreenQuery);
  const isMobile = useQueryMatch();
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);

  const showConfirmation = (onConfirm: Function) => {
    modal.confirm({
      title: 'Changes won’t be saved. Are you sure?',
      icon: <span className="icon icon-info-circle confirm-icon" />,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      width: '496px',
      onOk() {
        onConfirm();
      },
      onCancel() {},
    });
  };

  const handleRedirect = (
    e: React.MouseEvent,
    path: string,
    isNewTab: boolean = false,
  ) => {
    const onRedirect = () =>
      isNewTab ? window.open(path, '_blank') : navigate(path);

    if (isRedirectBlocked) {
      e.preventDefault();
      return showConfirmation(onRedirect);
    }
  };

  const handleStartOnboarding = () => {
    const onStart = () => {
      navigate('/');
      dispatch(
        startOnboarding({
          onboardingPage: OnboardingPagesEnum.Home,
          firstStep: HomePageSteps.Projects,
          isManual: true,
        }),
      );
      dispatch(setOnboardingPagesChecked({ isHomePageChecked: false }));
    };

    if (isRedirectBlocked) {
      return showConfirmation(onStart);
    }

    onStart();
  };

  const handleLogout = useCallback(() => {
    const onLogout = async () => await dispatch(logout());

    if (isRedirectBlocked) {
      return showConfirmation(onLogout);
    }

    onLogout();
  }, [dispatch, isRedirectBlocked]);

  const handleChangeLayoutMode = () => {
    dispatch(
      setLayoutMode(isLightTheme ? LayoutModeEnum.Dark : LayoutModeEnum.Light),
    );
  };

  const getSettingsMenu = (): MenuProps['items'] => {
    const addSpaceSelector = (menuItems: MenuProps['items'] = []) => {
      if (isDesktop) {
        return [
          {
            key: 'spaces',
            label: (
              <>
                <p>Select space</p>
                <ChangeSpaceDropdown
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  onChange={() => setIsAccountMenuOpen(false)}
                />
              </>
            ),
          },
          ...menuItems,
        ];
      }

      return menuItems;
    };

    const settingsMenu = [
      {
        key: 'account-settings',
        label: (
          <Link
            to="/account-settings"
            onClick={(e) => handleRedirect(e, '/account-settings')}
            className={styles.menuBox}
          >
            <div className={styles.menuImg}>
              <img src={AccountSettings} alt="Account settings" />
            </div>
            Account settings
          </Link>
        ),
      },
      {
        key: 'layout-mode',
        label: (
          <div className={styles.menuBox}>
            <div className={styles.menuImg}>
              <img src={DarkMode} alt="Dark mode" />
            </div>

            <div className={styles.darkMode}>
              <div>Dark mode</div>
              <div className={styles.switch}>
                <Switch
                  checked={!isLightTheme}
                  onChange={handleChangeLayoutMode}
                  size="small"
                />
              </div>
            </div>
          </div>
        ),
      },
      {
        key: 'help-center',
        label: (
          <a
            href="https://help.apdated.co"
            className={styles.menuBox}
            target="_blank"
          >
            <div className={styles.menuImg}>
              <img src={HelpCenter} alt="Help Center" />
            </div>
            Help Center
          </a>
        ),
      },
      {
        key: 'logout',
        label: (
          <div onClick={handleLogout} className={styles.menuBox}>
            <div className={styles.menuImg}>
              <img src={Logout} alt="Logout" />
            </div>
            Logout
          </div>
        ),
      },
    ];

    if (isAdmin) {
      return settingsMenu;
    }

    const folders = [
      {
        key: 'archived-trackers',
        label: (
          <Link
            to="/archived-trackers"
            onClick={(e) => handleRedirect(e, '/archived-trackers')}
            className={styles.menuBox}
          >
            <div className={styles.menuImg}>
              <img src={Archive} alt="Archive" />
            </div>
            Archive
          </Link>
        ),
      },
      {
        key: 'deleted-folder',
        label: (
          <Link
            to="/deleted-folder"
            onClick={(e) => handleRedirect(e, '/deleted-folder')}
            className={styles.menuBox}
          >
            <div className={styles.menuImg}>
              <img src={DeletedFolder} alt="DeletedFolder" />
            </div>
            Deleted folder
          </Link>
        ),
      },
      {
        key: 'onboarding-wizard',
        label: (
          <div onClick={handleStartOnboarding} className={styles.menuBox}>
            <div className={styles.menuImg}>
              <img src={Wizard} alt="Wizard" />
            </div>
            Onboarding wizard
          </div>
        ),
      },
    ];

    settingsMenu.splice(1, 0, ...folders);

    if (!isSuperAdmin) {
      return addSpaceSelector(settingsMenu);
    } else {
      settingsMenu.splice(1, 0, {
        key: 'billing-settings',
        label: (
          <Link
            to="/billing-settings"
            onClick={(e) => handleRedirect(e, '/billing-settings')}
            className={styles.menuBox}
          >
            <div className={styles.menuImg}>
              <img src={BillingSettings} alt="BillingSettings" />
            </div>
            Billing settings
          </Link>
        ),
      });
      return addSpaceSelector(settingsMenu);
    }
  };

  const resourcesItems = [
    {
      key: 'Help Center',
      label: (
        <a href="https://help.apdated.co" className={styles.menuBox}>
          <div className={styles.menuImg}>
            <img src={HelpCenter} alt="Help Center" />
          </div>
          Help Center
        </a>
      ),
    },
    {
      key: 'Blog',
      label: (
        <a href="https://apdated.co/blog" className={styles.menuBox}>
          <div className={styles.menuImg}>
            <img src={Blogging} alt="Help Center" />
          </div>
          Blog
        </a>
      ),
    },
    {
      key: 'Developer',
      label: (
        <a
          href={
            IS_PRODUCTION_ENV
              ? 'https://api.apdated.co/external/api-docs/index.html'
              : 'https://api-develop.apdated.co/external/api-docs/index.html'
          }
          target="_blank"
          className={styles.menuBox}
        >
          <div className={styles.menuImg}>
            <img src={ApiCode} alt="Help Center" />
          </div>
          Developer
        </a>
      ),
    },
  ];

  return (
    <>
      <div className={styles.weglot}></div>

      {isHomePage(pathname) && !user && (
        <div className={styles.narrow}>
          Learn how
          <a
            target="_blank"
            rel="noreferrer"
            className={styles.narrowLink}
            href="https://apdated.co/blog/case-study-apdated-saves-client-51000-per-month/"
          >
            Apdated Saves Client $51,000 Per Month
          </a>
        </div>
      )}

      <Layout.Header
        className={cx(styles.header, {
          [styles.headerDarken]: !user,
          [styles.headerDarkMode]: !isLightTheme,
          ['onboardingHeader']:
            (isHomePage(pathname) &&
              activeTourStep === HomePageSteps.Templates) ||
            activeTourStep === HomePageSteps.Account ||
            activeTourStep === HomePageSteps.Analytics,
        })}
      >
        {(!isAdmin || (isAdmin && isMobile)) && (
          <div
            className={cx({
              [styles.headerLogoBox]: !logoUrl && !isCustomLogoLoading,
              [styles.headerLogoBoxDark]: !isLightTheme,
            })}
          >
            <strong className={styles.headerLogo}>
              <Link to="/" onClick={(e) => handleRedirect(e, '/')}>
                {!isCustomLogoLoading && (
                  <img
                    className={cx({ [styles.customLogo]: logoUrl })}
                    src={!user && logoUrl ? logoUrl : imgLogoNew}
                    alt="logo"
                  />
                )}
              </Link>
            </strong>
          </div>
        )}
        {isAdmin && isMobile && (
          <div className={styles.burger}>
            {isSidebarOpen ? (
              <Button
                onClick={() =>
                  handleSidebarChange && handleSidebarChange(false)
                }
              >
                <i className="icon icon-close" />
              </Button>
            ) : (
              <Button
                onClick={() => handleSidebarChange && handleSidebarChange(true)}
              >
                <i className="icon icon-burger" />
              </Button>
            )}
          </div>
        )}
        {user && (
          <>
            <ul className={styles.navList}>
              <Accessor allowedRoles={[RolesEnum.Customer]}>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      cx({
                        [styles.activeLink]: isActive,
                      })
                    }
                    to="/"
                    onClick={(e) => handleRedirect(e, '/')}
                  >
                    <i className="icon icon-home" />
                    <span data-text="Home">Home</span>
                  </NavLink>
                </li>
                <li
                  className={cx(
                    {
                      ['onboardingTemplate']:
                        isHomePage(pathname) &&
                        activeTourStep === HomePageSteps.Templates,
                    },
                    {
                      onboardingTemplateDark: !isLightTheme,
                    },
                  )}
                >
                  <NavLink
                    className={({ isActive }) =>
                      cx({
                        [styles.activeLink]: isActive,
                      })
                    }
                    to="/templates"
                    onClick={(e) => handleRedirect(e, '/templates')}
                  >
                    <i className="icon icon-template" />
                    <span data-text="Templates">Templates</span>
                  </NavLink>
                </li>
              </Accessor>
              <Accessor allowedRoles={[RolesEnum.Customer]}>
                <li
                  className={cx(
                    {
                      ['onboardingTemplate']:
                        isHomePage(pathname) &&
                        activeTourStep === HomePageSteps.Analytics,
                    },
                    {
                      onboardingTemplateDark: !isLightTheme,
                    },
                  )}
                >
                  <NavLink
                    className={({ isActive }) =>
                      cx({
                        [styles.activeLink]: isActive,
                      })
                    }
                    to="/analytics"
                    onClick={(e) => handleRedirect(e, '/analytics')}
                  >
                    <i className="icon icon-template" />
                    <span data-text="Analytics">Analytics</span>
                  </NavLink>
                </li>
              </Accessor>
            </ul>

            <div className={styles.rightBox}>
              <div className={styles.headerProfile}>
                <Accessor allowedRoles={[RolesEnum.Customer]}>
                  {!isDesktop && <ChangeSpaceDropdown />}
                  <div className={styles.headerProfileNotify}>
                    <HeaderNotifications />
                  </div>
                </Accessor>
                <div
                  className={cx(
                    styles.profileDrop,
                    {
                      ['onboardingProfile']:
                        isHomePage(pathname) &&
                        activeTourStep === HomePageSteps.Account,
                    },
                    {
                      onboardingProfileDark: !isLightTheme,
                    },
                  )}
                >
                  <Dropdown
                    menu={{
                      items: getSettingsMenu(),
                    }}
                    open={isAccountMenuOpen}
                    onOpenChange={setIsAccountMenuOpen}
                    placement="bottomRight"
                    trigger={['click']}
                    overlayClassName={styles.profileDropMenu}
                  >
                    <div className={styles.headerProfileImg}>
                      <i className="icon icon-Profile" />
                    </div>
                  </Dropdown>
                </div>
              </div>
            </div>
          </>
        )}

        {!user && (
          <>
            <div className={styles.rightBox}>
              <ul className={styles.headerMenu}>
                <li>
                  <Dropdown
                    menu={{
                      items: resourcesItems,
                    }}
                    trigger={['click']}
                    placement="bottomRight"
                  >
                    <div>
                      Resources <i className="icon icon-angle-down" />
                    </div>
                  </Dropdown>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      cx(
                        {
                          [styles.activeLink]: isActive,
                        },
                        styles['no-auth-header-item'],
                      )
                    }
                    to="/pricing"
                    onClick={(e) => handleRedirect(e, '/pricing')}
                  >
                    <span data-text="Pricing">Pricing</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      cx(
                        {
                          [styles.activeLink]: isActive,
                        },
                        styles['no-auth-header-item'],
                      )
                    }
                    to="/sign_up"
                    onClick={(e) => handleRedirect(e, '/sign_up')}
                  >
                    <span data-text="Register">Register</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      cx(
                        {
                          [styles.activeLink]: isActive,
                        },
                        styles['no-auth-header-item'],
                      )
                    }
                    to="/login"
                    onClick={(e) => handleRedirect(e, '/login')}
                  >
                    <span data-text="Login">Login</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </>
        )}
      </Layout.Header>
    </>
  );
}
