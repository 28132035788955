import React, { ReactNode } from 'react';
import { Collapse } from 'antd';

import styles from '../TrackerChanges.module.less';

const { Panel } = Collapse;

type Props = {
  key: string;
  header: ReactNode;
  oldValue?: ReactNode;
  newValue?: ReactNode;
  children?: ReactNode;
};

export default function TrackerChangesCard(props: Props) {
  const { key, header, oldValue, newValue, children } = props;

  const renderCollapseContent = () => {
    if (oldValue && newValue) {
      return (
        <div className={styles.box}>
          <div className={styles.left}>{oldValue}</div>

          <div className={styles.right}>
            <div>
              <i className="icon icon-arrow-right"></i>
              {newValue}
            </div>
          </div>
        </div>
      );
    }

    if (children) {
      return children;
    }
  };

  return (
    <div className={styles.card}>
      <Collapse>
        <Panel collapsible="header" key={key} header={<div>{header}</div>}>
          {renderCollapseContent()}
        </Panel>
      </Collapse>
    </div>
  );
}
