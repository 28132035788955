import React from 'react';
import { Progress } from 'antd';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { AdminProgressTrackerResponseModel } from 'api';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import { getPercentage } from 'utils/percentageUtils';
import { getDateForUserTimezone } from 'utils/dateUtils';
import { getCorrectStrokeColor } from 'utils/trackerUtils';

type Props = {
  tracker: AdminProgressTrackerResponseModel;
};

export default function TrackerCard(props: Props) {
  const isLightTheme = useSelector(isLightThemeSelector);
  const { tracker } = props;
  const {
    id,
    completenessStatus,
    createdDate,
    uncompletedStageTotalCount,
    name,
    folderName,
    projectOwner,
    isAnyStageEnded,
  } = tracker;

  const percentage = getPercentage(completenessStatus ?? 0);

  return (
    <Link
      to={`/details/${id}`}
      className={cx('ticket ticket--shadow', {
        inverse: !isLightTheme,
      })}
    >
      <div className="ticket__left">
        <div className="ticket__progress">
          <Progress
            type="circle"
            percent={percentage}
            format={(percent) => `${percent}%`}
            strokeColor={getCorrectStrokeColor(percentage, isAnyStageEnded)}
            strokeWidth={10}
            size={54}
          />
        </div>
        <div>
          <div>
            <div className="ticket__name">{name}</div>
          </div>

          <div className="ticket__item">
            <span>Created date: </span>
            {getDateForUserTimezone(createdDate)}
          </div>
          <div className="ticket__item">
            <span>Stages remaining: </span>
            {uncompletedStageTotalCount}
          </div>
        </div>
      </div>
      <div className="ticket__right">
        <div className="ticket__inner">
          <div className="ticket__item">
            <span>Created by: </span>
            {projectOwner}
          </div>
          <div className="ticket__item">
            <span>Project: </span>
            {folderName}
          </div>
        </div>
      </div>
    </Link>
  );
}
