import React, { ReactNode, useImperativeHandle, useRef, useState } from 'react';
import { Upload } from 'antd';
import * as Yup from 'yup';
import cx from 'classnames';
import {
  RcFile,
  ShowUploadListInterface,
  UploadChangeParam,
  UploadFile,
  UploadProps,
} from 'antd/lib/upload/interface';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import useDeepCompareEffect from 'use-deep-compare-effect';
import axios, { Canceler } from 'axios';
import { debounce, isObject, omit } from 'lodash';
import { nanoid } from '@reduxjs/toolkit';
import { FieldErrors } from 'react-hook-form/dist/types';

import { AttachmentResponseModel } from 'api';
import AlertMessage from 'components/AlertMessage';
import {
  BaseUploadMessages,
  getFileExt,
  getFileNameWithoutExt,
} from 'utils/fileUtils';

export enum UploadMode {
  InstantDownload = 1,
  ManualDownload = 2,
  ImageLogoMode = 3,
}

export const BaseFileYupObject = Yup.object({
  fileName: Yup.string(),
  s3Key: Yup.string(),
  id: Yup.string(),
});

type OwnProps = {
  value?: FileValue[];
  disableOnLoading?: boolean;
};

export interface FileValue {
  id: string;
  fileName: string;
  s3Key: string;
}

export interface FileDetails {
  fileName: string;
  fileExtension: string;
}

export type BaseFileUploadProps = {
  children?: (props: UploadItemProps) => ReactNode;
  mode?: UploadMode;
  isDraggable?: boolean;
  error?: FieldErrors | string;
  maxFileCount?: number;
  acceptedFileSize: number;
  isUpdatedDefaultFileList?: boolean;
  acceptedFileFormat: string[];
  defaultFileList?: BaseUploadFile[];
  uploadLinkFunction?: Function;
  getRequestBody?: (file: FileDetails) => {};
  messages: BaseUploadMessages;
  getUploadList?: (
    props: UploadItemProps,
  ) => boolean | ShowUploadListInterface | undefined;
} & UploadProps;

type FileUploadProps = BaseFileUploadProps & OwnProps;

export type UploadItemProps = {
  isUploading: boolean;
  isLoading: boolean;
  loadingFileKeys: IFilesStatus;
  uploadingFileKeys: IFilesStatus;
};

interface ICancelRequests {
  [key: string]: Canceler;
}

interface IFilesStatus {
  [key: string]: boolean;
}

export type BaseUploadFile = UploadFile<AttachmentResponseModel>;

const { Dragger } = Upload;
const CancelToken = axios.CancelToken;

export default React.forwardRef((props: FileUploadProps, ref) => {
  const {
    name,
    onChange,
    children,
    maxFileCount = 1,
    acceptedFileSize,
    acceptedFileFormat,
    isDraggable = false,
    value,
    messages,
    uploadLinkFunction,
    getUploadList,
    error,
    defaultFileList = [],
    getRequestBody = (file: FileDetails) => file,
    disableOnLoading = false, // can be overrided by direct disabled property
    fileList: files = defaultFileList,
    mode = UploadMode.InstantDownload,
    ...rest
  } = props;
  const [fileList, setFileList] = useState<BaseUploadFile[]>(defaultFileList);
  const [loadingFileKeys, setLoadingFileKeys] = useState<IFilesStatus>({}); // getting link + uploading
  const [uploadingFileKeys, setUploadingFileKeys] = useState<IFilesStatus>({}); // only uploading
  const isLoading = Object.values(loadingFileKeys).some(Boolean);
  const isUploading = Object.values(uploadingFileKeys).some(Boolean);
  const acceptedUploadFormat = acceptedFileFormat.join(',');
  const cancelRef = useRef<ICancelRequests>({});
  const isMultipleUpload = maxFileCount > 1;
  const showError = !!error && !isLoading;
  const uploaderId = `file-upload-${nanoid()}`;

  useDeepCompareEffect(() => {
    setFileList(files);
  }, [files]);

  useImperativeHandle(ref, () => ({
    handleOpenFileDialog() {
      const upload = document.getElementById(uploaderId) as HTMLInputElement;
      upload.click();
    },
  }));

  const handleLoadingFile = (file: RcFile, isLoading: boolean) =>
    setLoadingFileKeys((loadingFileKeys: any) => ({
      ...loadingFileKeys,
      [file.uid]: isLoading,
    }));

  const handleUploadingFile = (file: RcFile, isUploading: boolean) =>
    setUploadingFileKeys((uploadingFileKeys: any) => ({
      ...uploadingFileKeys,
      [file.uid]: isUploading,
    }));

  const removeLoadingFileKey = (fileKey: string) =>
    setLoadingFileKeys((loadingFileKeys) => omit(loadingFileKeys, [fileKey]));

  const removeUploadingFileKey = (fileKey: string) =>
    setUploadingFileKeys((uploadingFileKeys) =>
      omit(uploadingFileKeys, [fileKey]),
    );

  const clearFileKey = (fileKey: string) => {
    removeLoadingFileKey(fileKey);
    removeUploadingFileKey(fileKey);
  };

  const getFileType = (file: RcFile | File) =>
    file.type || `.${getFileExt(file.name)}`;

  const isFileFormatValid = (file: RcFile | File) => {
    const type = getFileType(file);

    return acceptedFileFormat.some(
      (format: string) => format.toLowerCase() === type.toLowerCase(),
    );
  };

  const isImageProportionsValid = (file: File | RcFile) => {
    return new Promise<boolean>((resolve) => {
      const img = new Image();
      const objectUrl = window.URL.createObjectURL(file);
      img.onload = function () {
        resolve(img.height === img.width);
      };
      img.src = objectUrl;
    });
  };

  const isFileSizeValid = (file: File | RcFile) =>
    file.size <= 1024 * 1000 * acceptedFileSize;

  const isFileCounterValid = (newFileList: RcFile[] | UploadFile[]) => {
    const fileListCounter = fileList.concat(newFileList).length;

    return maxFileCount >= fileListCounter;
  };

  const debounceErrorMessage = debounce(
    (message) => AlertMessage.error(message),
    100,
  );

  const hasValidationError = async (
    file: File | RcFile,
    fileList: RcFile[],
  ) => {
    const errorMessages: string[] = [];

    if (isMultipleUpload && !isFileCounterValid(fileList)) {
      debounceErrorMessage(messages.maxFileCounterError(maxFileCount));
      return true;
    }
    if (!isFileSizeValid(file)) {
      errorMessages.push(
        messages.fileSizeLimitError(file.name, acceptedFileSize),
      );
    }

    if (!isFileFormatValid(file)) {
      errorMessages.push(messages.fileUnsupportedError(file.name));
    }

    if (mode === UploadMode.ImageLogoMode) {
      const isProportionsValid = await isImageProportionsValid(file);
      !isProportionsValid && errorMessages.push(messages.imageDimensionsError);
    }

    const hasError = errorMessages.length > 0;

    if (hasError) {
      AlertMessage.error(errorMessages.join(' '));
    }

    return hasError;
  };

  const handleBeforeUpload = async (
    file: RcFile,
    fileList: RcFile[],
  ): Promise<Promise<void> | boolean> => {
    const hasError = await hasValidationError(file, fileList);

    if (hasError) {
      return Promise.reject();
    }

    if (!isMultipleUpload) {
      setFileList([]);
    }

    if (mode === UploadMode.ManualDownload) return false;

    return Promise.resolve();
  };

  const handleChange = (info: UploadChangeParam) => {
    const { fileList } = info;

    const newFileList = fileList.filter(
      (file: BaseUploadFile) => file.status !== 'error',
    );
    const fileInfo = { ...info, fileList: newFileList };

    setFileList(newFileList);
    onChange && onChange(fileInfo);
  };

  const handleRequest = async (options: UploadRequestOption): Promise<void> => {
    if (!uploadLinkFunction) return;

    const { onSuccess, onError, onProgress } = options;
    const file = options.file as RcFile;
    const fileExt = getFileExt(file.name);
    const fileName = getFileNameWithoutExt(file.name);
    handleLoadingFile(file, true);

    try {
      const uploadRequestBody = getRequestBody({
        fileExtension: fileExt,
        fileName,
      });
      const {
        s3Key,
        contentType,
        putFileUrl,
        getFileUrl,
        id,
        fileName: fileNameFromApi,
      } = await uploadLinkFunction(uploadRequestBody);
      handleUploadingFile(file, true);

      const result: any = await axios.put(putFileUrl, file, {
        onUploadProgress: (e) => {
          onProgress?.({ ...e, percent: (e.loaded / e.total) * 100 });
        },
        headers: {
          'Content-Type': contentType,
        },
        cancelToken: new CancelToken(function executor(c) {
          cancelRef.current[file.uid] = c;
        }),
      });

      onSuccess?.(
        {
          ...result.body,
          s3Key,
          getUrl: getFileUrl,
          id,
          fileName: fileNameFromApi,
        },
        result,
      );
    } catch (err: any) {
      if (axios.isCancel(err)) return;
      AlertMessage.error(messages.uploadFailed(file.name));
      onError?.(err);
    } finally {
      handleLoadingFile(file, false);
      handleUploadingFile(file, false);
    }
  };

  const cancelRequest = (fileUid: string) => {
    if (!cancelRef.current[fileUid]) return;

    cancelRef.current[fileUid]();

    delete cancelRef.current[fileUid];
  };

  const handleRemove = (file: BaseUploadFile) => {
    const { uid } = file;

    // Do not remove file while getting links
    if (loadingFileKeys[uid] && !uploadingFileKeys[uid]) return false;

    clearFileKey(uid);
    cancelRequest(uid);

    return true;
  };

  const renderUploadItem = () => {
    const props: UploadItemProps = {
      isUploading,
      isLoading,
      loadingFileKeys,
      uploadingFileKeys,
    };
    const UploadItemComponent = isDraggable ? Dragger : Upload;

    return (
      <>
        <UploadItemComponent
          id={uploaderId}
          name={name}
          multiple={isMultipleUpload}
          maxCount={maxFileCount}
          className={cx('upload-list-inline')}
          fileList={fileList}
          disabled={disableOnLoading && isLoading}
          onRemove={handleRemove}
          onChange={handleChange}
          customRequest={handleRequest}
          accept={acceptedUploadFormat}
          showUploadList={getUploadList ? getUploadList(props) : false}
          beforeUpload={(file, fileList) =>
            handleBeforeUpload(file, fileList).then()
          }
          {...rest}
        >
          {children && children(props)}
        </UploadItemComponent>

        {showError && (
          <div className="ant-form-item-explain ant-form-item-explain-error">
            {isObject(error) ? (error as FieldErrors).message : error}
          </div>
        )}
      </>
    );
  };

  return <>{renderUploadItem()}</>;
});
