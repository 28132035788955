import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiClient, { SpaceRoleResponseModel, UserSpaceRoleEnum } from 'api';

import { logout } from 'redux/reducers/authenticationSlice';
import { currentSpaceIdByStoreSelector } from '../selectors/spacesSelectors';
import { transferSpaceOwnership } from 'redux/reducers/spaceSlice';

interface RoleState {
  roleInfo: SpaceRoleResponseModel;
}

const initialState: RoleState = {
  roleInfo: {
    roleId: undefined,
    roleName: undefined,
  },
};

export const getSpaceRole = createAsyncThunk('role/getSpaceRole', async () => {
  return apiClient.getSpaceRole(currentSpaceIdByStoreSelector());
});

const handleGetSpaceRole = (state: RoleState, role: SpaceRoleResponseModel) => {
  state.roleInfo = role;
};

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSpaceRole.fulfilled, (state, action) => {
      handleGetSpaceRole(state, action.payload);
    });
    builder.addCase(logout.fulfilled, (state) => {
      Object.assign(state, initialState);
    });
    builder.addCase(transferSpaceOwnership.fulfilled, (state) => {
      state.roleInfo.roleId = UserSpaceRoleEnum.Basic;
      state.roleInfo.roleName = 'Basic';
    });
  },
});

const { reducer } = roleSlice;

export default reducer;
