import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { THEME_KEY } from 'constants/ThemeConstants';
import { LayoutModeEnum } from 'enums/LayoutModeEnum';
import { EnumHelpers } from 'utils/enumUtils';
import localStorage from 'utils/localStorage';
import { saveThemeToStorage } from 'utils/themeUtils';
import { logout } from 'redux/reducers/authenticationSlice';

interface StylesState {
  layoutMode: LayoutModeEnum;
}

const getInitialState = (): StylesState => {
  const theme = localStorage?.getItem(THEME_KEY);

  if (theme && EnumHelpers.getEnumValues(LayoutModeEnum).includes(theme)) {
    return { layoutMode: theme as LayoutModeEnum };
  } else {
    saveThemeToStorage(LayoutModeEnum.Light);
    return { layoutMode: LayoutModeEnum.Light };
  }
};

const initialState = getInitialState();

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setLayoutMode: (state, action: PayloadAction<LayoutModeEnum>) => {
      state.layoutMode = action.payload;
      saveThemeToStorage(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      state.layoutMode = LayoutModeEnum.Light;
      saveThemeToStorage(LayoutModeEnum.Light);
    });
  },
});

const { actions, reducer } = themeSlice;

export const { setLayoutMode } = actions;
export default reducer;
