import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { FloatButton, Spin, Tabs } from 'antd';
import { Tab } from 'rc-tabs/lib/interface';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';

import TermsAndConditionsTab from './TermsAndConditionsTab';
import { AgreementResponseModel, AgreementTypeEnum } from 'api';
import { AppDispatch } from 'redux/store';
import { getAgreementByType } from 'redux/reducers/authenticationSlice';
import useSearchQueryMatch from 'hooks/useSearchQueryMatch';
import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';

import styles from './TermsAndConditions.module.less';

export enum AgreementTypeTabsEnum {
  MasterTerms = 'master-terms',
  PrivacyPolicy = 'privacy-policy',
  Eula = 'eula',
}

interface AgreementsDetailsModel {
  masterTerms: string;
  privacyPolicy: string;
  eula: string;
}

const defaultActiveTab = AgreementTypeTabsEnum.MasterTerms;

const PAGE_SECTIONS = Object.values(AgreementTypeTabsEnum);

export default function TermsAndConditions() {
  const [isLoading, setIsLoading] = useState(false);
  const [agreementsDetails, setAgreementsDetails] =
    useState<AgreementsDetailsModel>({
      masterTerms: '',
      privacyPolicy: '',
      eula: '',
    });
  const navigate = useNavigate();
  const location = useLocation();
  const isPageSectionMatch = useSearchQueryMatch(PAGE_SECTIONS, 'section');
  const query = queryString.parse(location.search);
  const section = query.section as AgreementTypeTabsEnum;

  const [activeTab, setActiveTab] = useState<AgreementTypeTabsEnum>(
    section || defaultActiveTab,
  );
  const dispatch: AppDispatch = useDispatch();

  const tabItems: Tab[] = [
    {
      key: AgreementTypeTabsEnum.MasterTerms,
      label: 'Master Terms',
      children: (
        <TermsAndConditionsTab
          agreementHtml={agreementsDetails?.masterTerms || ''}
        />
      ),
    },
    {
      key: AgreementTypeTabsEnum.PrivacyPolicy,
      label: 'Privacy Policy',
      children: (
        <TermsAndConditionsTab
          agreementHtml={agreementsDetails.privacyPolicy || ''}
        />
      ),
    },
    {
      key: AgreementTypeTabsEnum.Eula,
      label: 'End User License Agreement',
      children: (
        <TermsAndConditionsTab agreementHtml={agreementsDetails.eula || ''} />
      ),
    },
  ];

  useEffect(() => {
    setActiveTab(section || defaultActiveTab);
  }, [section]);

  useEffect(() => {
    if (!isPageSectionMatch) {
      navigate(
        { search: queryString.stringify({ section: defaultActiveTab }) },
        {
          replace: true,
        },
      );
    }
    // eslint-disable-next-line
  }, [isPageSectionMatch]);

  const mapAgreementDetailsToDisplayModel = (
    agreementDetails: AgreementResponseModel,
  ) => {
    if (!agreementDetails) return;

    switch (agreementDetails.agreementType) {
      case AgreementTypeEnum.MasterTerms:
        setAgreementsDetails((prevState: AgreementsDetailsModel) => {
          return {
            ...prevState,
            masterTerms: agreementDetails.agreementHtml || '',
          };
        });
        break;
      case AgreementTypeEnum.PrivacyPolicy:
        setAgreementsDetails((prevState: AgreementsDetailsModel) => {
          return {
            ...prevState,
            privacyPolicy: agreementDetails.agreementHtml || '',
          };
        });
        break;
      case AgreementTypeEnum.Eula:
        setAgreementsDetails((prevState: AgreementsDetailsModel) => {
          return { ...prevState, eula: agreementDetails.agreementHtml || '' };
        });
    }
  };

  const loadAgreement = async (type: AgreementTypeEnum) => {
    const result = await dispatch(getAgreementByType(type));

    if (getAgreementByType.fulfilled.match(result)) {
      return mapAgreementDetailsToDisplayModel(result.payload);
    }
    AlertMessage.error(result.error.message || ApiErrorMessage);
  };

  const loadAgreements = async () => {
    setIsLoading(true);
    await Promise.all([
      loadAgreement(AgreementTypeEnum.MasterTerms),
      loadAgreement(AgreementTypeEnum.PrivacyPolicy),
      loadAgreement(AgreementTypeEnum.Eula),
    ]);
    setIsLoading(false);
  };

  useEffect(() => {
    loadAgreements();
  }, []);

  const getAgreementTitle = () => {
    switch (activeTab) {
      case AgreementTypeTabsEnum.MasterTerms:
        return 'Master Terms';
      case AgreementTypeTabsEnum.PrivacyPolicy:
        return 'Privacy Policy';
      case AgreementTypeTabsEnum.Eula:
        return 'End User License Agreement';
    }
  };

  const handleTabChange = (tab: string) => {
    navigate({ search: `section=${tab}` }, { replace: true });
  };

  return (
    <div className={cx(styles.termsAndConditions, 'page-wrapper')}>
      {isLoading && <Spin size="large" />}
      {!isLoading &&
        Object.values(agreementsDetails).filter(Boolean).length && (
          <div className="grid-row">
            <div className={styles.leftBox}>
              <h1 className={cx(styles.text, 'h1')}>{getAgreementTitle()}</h1>
            </div>
            <div className={styles.tabsHolderWrap}>
              <Tabs
                activeKey={activeTab}
                onChange={handleTabChange}
                className={styles.tabsHolder}
                items={tabItems}
              />
            </div>

            <FloatButton.BackTop
              target={() =>
                (document.getElementsByClassName(
                  'ant-tabs-content-holder',
                )[0] as HTMLElement) || window
              }
            >
              <button className={cx(styles.button, styles.buttonVisible)}>
                <i className="icon icon-angle-top" />
              </button>
            </FloatButton.BackTop>
          </div>
        )}
    </div>
  );
}
