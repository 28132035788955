import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { App, Button, Input } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useWillUnmount } from 'rooks';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import cx from 'classnames';

import {
  ProgressTrackerEditRequestModel,
  ProgressTrackerRequestModel,
  ProgressTrackerResponseModel,
} from 'api';
import {
  createPT,
  setProgressTrackerFormDirty,
  updatePT,
} from 'redux/reducers/progressTrackerSlice';
import { AppDispatch } from 'redux/store';
import Breadcrumb from 'components/Breadcrumb';
import Controller from 'components/form/Controller';
import { StageEdit } from 'components/ProgressTracker/Stage';
import AlertMessage from 'components/AlertMessage';
import {
  generateMockId,
  isMockId,
} from 'components/ProgressTracker/Stage/utils';
import { StageEditMode } from 'components/ProgressTracker/Stage/StageEdit';
import {
  BaseFileYupObject,
  FileValue,
} from 'components/wrappers/BaseFileUpload';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { emptyStage } from 'constants/TrackerConstants';
import { requiredMessage } from 'constants/ValidationConstants';
import { alertBeforeUnload } from 'utils/confirmationUtils';
import { mapFilesResponseToInitialValues } from 'utils/fileUtils';

import styles from './ProgressTrackerEdit.module.less';

export interface StageFormValues {
  id: string;
  name: string;
  dueDate: Dayjs | string | null;
  description: string;
  isManuallyAdded: boolean;
  attachments?: FileValue[];
}

export interface ProgressTrackerFormValues {
  name: string;
  stages: StageFormValues[];
}

export enum ViewMode {
  ProgressTracker = 1,
  Template = 2,
  CreateProgressTracker = 3,
}

type Props = {
  initialValues?: ProgressTrackerResponseModel;
  projectId: string;
  progressTrackerId?: string;
  mode: ViewMode;
};

export const stageValidation = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(30, 'Stage name should be 30 characters maximum')
    .required(requiredMessage),
  dueDate: Yup.date().nullable(),
  description: Yup.string()
    .trim()
    .required(requiredMessage)
    .max(280, 'Stage description should be 280 characters maximum'),
  attachments: Yup.array().of(BaseFileYupObject),
});

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required(requiredMessage)
    .max(120, 'Customer name should be 120 characters maximum'),
  stages: Yup.array().of(stageValidation),
});

const initialEmptyValues: ProgressTrackerFormValues = {
  name: '',
  stages: Array.from({ length: 4 }, () => ({
    ...emptyStage,
    id: generateMockId(),
  })),
};

export default function ProgressTrackerEdit(props: Props) {
  const isLightTheme = useSelector(isLightThemeSelector);
  const { initialValues, progressTrackerId, projectId, mode } = props;
  const isEditMode = !!initialValues;
  const isTrackerUpdate = mode === ViewMode.ProgressTracker && isEditMode;
  const templateId = mode === ViewMode.Template ? initialValues?.id : undefined;

  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { modal } = App.useApp();

  const { control, handleSubmit, formState, trigger, reset } =
    useForm<ProgressTrackerFormValues>({
      resolver: yupResolver(validationSchema),
      mode: 'all',
    });
  const { isSubmitting, isDirty, touched, errors } = formState;

  const { fields, remove, append, swap } = useFieldArray<
    StageFormValues,
    'key'
  >({
    control,
    name: 'stages',
    keyName: 'key',
  });

  const isAddingDisabled = fields.length >= 20;

  const progressTrackerToInitialValues = () => {
    const formInitialValues = isEditMode
      ? {
          name:
            mode === ViewMode.ProgressTracker ||
            mode === ViewMode.CreateProgressTracker
              ? initialValues?.name
              : '',
          stages: initialValues?.stages?.map((stage) => ({
            id: stage.id,
            name: stage.name,
            dueDate: dayjs(stage.dueDate).isValid()
              ? dayjs(stage.dueDate)
              : stage.dueDate,
            description: stage.description,
            attachments: mapFilesResponseToInitialValues(stage.attachments),
          })),
        }
      : initialEmptyValues;

    reset(formInitialValues);
  };

  useEffect(progressTrackerToInitialValues, []);

  useEffect(() => {
    dispatch(setProgressTrackerFormDirty(isDirty));

    return alertBeforeUnload(isDirty);
  }, [isDirty]);

  useWillUnmount(() => {
    dispatch(setProgressTrackerFormDirty(false));
  });

  const handleDeleteStageClick = useCallback(
    (index: number) => {
      modal.confirm({
        content: 'Are you sure you want to delete the stage?',
        icon: <span className="icon icon-info-circle confirm-icon" />,
        className: cx('confirm-modal', {
          'confirm-modal-dark-button': !isLightTheme,
        }),

        width: '496px',
        cancelText: 'Cancel',
        okText: 'Delete',
        onOk: () => remove(index),
      });
    },
    [remove],
  );
  const handleAddStageClick = () => {
    append({ ...emptyStage, id: generateMockId(), isManuallyAdded: true });
  };

  const getAction = () => {
    if (isTrackerUpdate) return updatePT;

    return createPT;
  };

  const onSubmit: SubmitHandler<ProgressTrackerFormValues> = async (
    values: ProgressTrackerFormValues,
  ) => {
    const stages = values.stages.map((s, idx) => {
      const stageId = fields[idx].id;

      const status = initialValues?.stages?.find(
        (initStage) => initStage.id === stageId,
      )?.status;

      return {
        name: s.name.trim(),
        dueDate: s.dueDate as any,
        description: s.description.trim(),
        sequence: idx + 1,
        attachments: s.attachments,
        id: !isMockId(stageId) ? stageId : undefined,
        status,
      };
    });

    const data: ProgressTrackerRequestModel | ProgressTrackerEditRequestModel =
      {
        id: progressTrackerId,
        name: values.name,
        folderId: projectId,
        templateId,
        stages,
      };

    const action = getAction();
    const result = await dispatch(action(data));

    if (!action.fulfilled.match(result)) {
      AlertMessage.error(result.error.message || ApiErrorMessage);
      return;
    }

    const messageText = isTrackerUpdate
      ? 'Progress tracker successfully updated.'
      : 'Progress tracker successfully created.';

    AlertMessage.success(messageText);
    navigate(`/details/${result.payload}`);
  };

  const handleSave = () => {
    trigger();
    handleSubmit(onSubmit)();
  };

  const showConfirmation = (onConfirm: Function) => {
    modal.confirm({
      title: 'Changes won’t be saved. Are you sure?',
      icon: <span className="icon icon-info-circle confirm-icon" />,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      width: '496px',
      onOk() {
        onConfirm();
      },
      onCancel() {},
    });
  };

  const handleBackClick = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => {
    const onRedirect = () => navigate(-1);

    if (isDirty) {
      e.preventDefault();
      return showConfirmation(onRedirect);
    }
  };

  const getBackLink = () => {
    if (isEditMode && mode === ViewMode.ProgressTracker)
      return `/details/${progressTrackerId}`;

    if (isEditMode && mode === ViewMode.Template)
      return `/template/${initialValues?.id}/details`;

    return `/project/${projectId}`;
  };

  return (
    <div className="page-wrapper scroll-box ">
      <div className="page-box page-holder">
        <div onClickCapture={handleBackClick}>
          <Breadcrumb
            routes={[
              {
                title: <i className="icon icon-arrow-left" />,
                link: getBackLink(),
              },
            ]}
          />
        </div>

        <div
          className={cx('banner mb-10', {
            inverse: !isLightTheme,
          })}
        >
          <div className="banner__left">
            <div className={styles.detailsInput}>
              <Controller
                name="name"
                render={(field) => (
                  <Input
                    {...field}
                    placeholder="Enter Customer name"
                    prefix={<i className={styles.cursor} />}
                  />
                )}
                control={control}
                error={errors.name}
              />
            </div>
          </div>

          <div className="banner__actions">
            <Button
              loading={isSubmitting}
              disabled={!fields.length}
              onClick={handleSave}
              className="dark-btn"
              size="large"
            >
              Save
            </Button>
          </div>
        </div>

        <div className="cards-list">
          {fields.map((field, index) => (
            <StageEdit
              key={field?.key}
              field={field}
              fieldName={`stages[${index}]`}
              control={control}
              index={index}
              error={errors.stages?.[index]}
              touched={!!touched.stages?.[index]}
              onDelete={handleDeleteStageClick}
              onDrop={swap}
              mode={StageEditMode.ProgressTrackerEdit}
            />
          ))}
        </div>

        <div className={styles.btn}>
          <div className={styles.btnWrap}>
            <Button
              type="link"
              onClick={handleAddStageClick}
              disabled={isAddingDisabled}
              title={
                isAddingDisabled
                  ? 'There can only be a maximum of 20 stages for each tracker'
                  : undefined
              }
            >
              <div className={styles.btnAdd}>
                <div className={styles.btnCircle}>
                  <i className="icon icon-plus" />
                </div>
                <div className={styles.btnText}>Add row</div>
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
