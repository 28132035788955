import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Router from 'Router';
import WithoutHeaderLayout from './WithoutHeaderLayout';
import { isAdminSelector } from 'redux/selectors/authenticationSelectors';
import MainLayout from './MainLayout';
import AdminLayout from './AdminLayout';

export default function AppLayout() {
  const { pathname } = useLocation();
  const iaAdmin = useSelector(isAdminSelector);

  const Layout = useMemo(() => {
    if (iaAdmin) return AdminLayout;

    if (
      pathname?.includes('/embedded-pt/') ||
      pathname?.includes('/embedded-subscribe/')
    ) {
      return WithoutHeaderLayout;
    }

    return MainLayout;
  }, [pathname]);

  return (
    <Layout>
      <Router />
    </Layout>
  );
}
