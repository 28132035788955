import React, { useMemo, useState } from 'react';
import { Col, Row } from 'antd';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { isArray, isNil, unset } from 'lodash';

import ProjectCard from './ProjectCard';
import { FolderListResponseModel, SortFieldEnum, SortOrderEnum } from 'api';
import SearchInput from 'components/filters/SearchInput';
import SortingFilter, {
  SortingFilterValueType,
  SortingMode,
} from 'components/filters/SortingFilter/SortingFilter';
import SearchLayout, {
  ChildrenProps,
  SearchLayoutMode,
} from 'components/search/SearchLayout';
import Breadcrumb from 'components/Breadcrumb';

enum ProjectsFiltersTypes {
  Name = 'name',
  Sorting = 'sorting',
}

interface ProjectsFilters {
  [ProjectsFiltersTypes.Name]: string;
  [ProjectsFiltersTypes.Sorting]: SortingFilterValueType;
}

export default function ProjectListPage() {
  const location = useLocation();
  const { name, sortField, sortOrder } = queryString.parse(location.search);

  const initialValues: ProjectsFilters = {
    [ProjectsFiltersTypes.Name]: !isArray(name) && !isNil(name) ? name : '',
    [ProjectsFiltersTypes.Sorting]: {
      sortField:
        !isArray(sortField) && !isNil(sortField)
          ? +sortField
          : SortFieldEnum.Name,
      sortOrder:
        !isArray(sortOrder) && !isNil(sortOrder)
          ? +sortOrder
          : SortOrderEnum.Asc,
    },
  };
  const [filters, setFilters] = useState<ProjectsFilters>(initialValues);

  const handleSearch = (query: string) => {
    setFilters({
      ...filters,
      [ProjectsFiltersTypes.Name]: query,
    });
  };

  const handleSort = (sortParams: SortingFilterValueType) => {
    setFilters({
      ...filters,
      [ProjectsFiltersTypes.Sorting]: sortParams,
    });
  };

  const renderBreadcrumb = () => (
    <Breadcrumb
      routes={[{ title: <i className="icon icon-home" /> }]}
    />
  );

  const renderSorting = () => (
    <div>
      <SortingFilter
        value={filters[ProjectsFiltersTypes.Sorting]}
        onChange={handleSort}
        mode={SortingMode.AdminProjects}
      />
    </div>
  );

  const renderSearchInput = () => (
    <SearchInput
      onChange={handleSearch}
      value={filters[ProjectsFiltersTypes.Name]}
      placeholder="Search projects"
    />
  );

  const renderData = (
    childrenProps: ChildrenProps<FolderListResponseModel, any>,
  ) => (
    <Row gutter={[20, 20]}>
      {childrenProps.details.map((project) => (
        <Col md={12} xs={24} key={project.id}>
          <ProjectCard project={project} />
        </Col>
      ))}
    </Row>
  );

  const resultFilters = useMemo(() => {
    const newFilters = {
      query: filters[ProjectsFiltersTypes.Name],
      ...filters,
    };
    unset(newFilters, [ProjectsFiltersTypes.Name]);
    return newFilters;
  }, [filters]);

  return (
    <SearchLayout
      mode={SearchLayoutMode.Projects}
      filters={resultFilters}
      columns={2}
      renderBreadcrumbsNode={renderBreadcrumb}
      searchNode={renderSearchInput()}
      filtersNode={renderSorting()}
    >
      {renderData}
    </SearchLayout>
  );
}
