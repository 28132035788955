import React, { useEffect } from 'react';
import { Col, Divider, Form, Input, Row, Select } from 'antd';
import cx from 'classnames';
import { UseFormMethods } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useWillUnmount } from 'rooks';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';

import FormStripeElement from 'components/stripe/FormStripeElement';
import Checkbox from 'components/form/Checkbox';
import Controller from 'components/form/Controller';
import { AppDispatch } from 'redux/store';
import { countriesSelector } from 'redux/selectors/regionSelectors';
import { getAllCountries } from 'redux/reducers/regionSlice';
import { setNewCreditCardFormDirty } from 'redux/reducers/accountSlice';
import { alertBeforeUnload } from 'utils/confirmationUtils';
import { NewCardFormValues } from 'utils/creditCardUtils';

import styles from 'features/auth/BillingSettingsPage/CreditCardsTab/CreditCardsTab.module.less';

type Props = {
  form: UseFormMethods<NewCardFormValues>;
};

export default function NewCardForm(props: Props) {
  const { form } = props;
  const { formState, control, errors } = form;
  const { isDirty } = formState;
  const countries = useSelector(countriesSelector);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCountries());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setNewCreditCardFormDirty(isDirty));

    return alertBeforeUnload(isDirty);
  }, [isDirty]);

  useWillUnmount(() => {
    dispatch(setNewCreditCardFormDirty(false));
  });

  return (
    <Form layout="vertical">
      <div className={styles.billingCard}>
        <Row gutter={10}>
          <Col xs={24}>
            <FormStripeElement
              component={CardNumberElement}
              form={form}
              label="Card number"
              name="cardNumber"
              options={{
                showIcon: true,
                placeholder: '4848 4848 4848 4848',
              }}
              error={errors.cardNumber}
              className="ant-input stripe"
              containerClassName={cx(styles.input, 'dark-input-group')}
            />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col xs={24} md={12}>
            <Controller
              name="name"
              control={control}
              error={errors.name}
              label="Name on card"
              placeholder="John Wilson"
              as={Input}
              containerClassName={cx(styles.input, 'dark-input-group')}
            />
          </Col>
          <Col xs={12} md={6}>
            <FormStripeElement
              component={CardExpiryElement}
              form={form}
              label="Expiry date"
              name="expiryDate"
              error={errors.expiryDate}
              className="ant-input stripe"
              containerClassName={cx(styles.input, 'dark-input-group')}
            />
          </Col>
          <Col xs={12} md={6}>
            <FormStripeElement
              component={CardCvcElement}
              form={form}
              label="CVV code"
              name="cvv"
              error={errors.cvv}
              options={{ placeholder: '123' }}
              className="ant-input stripe"
              containerClassName={cx(styles.input, 'dark-input-group')}
            />
          </Col>
        </Row>

        <Divider className="lighten-divider mt-0" />

        <Row gutter={10}>
          <Col xs={24} md={12}>
            <Controller
              label="Country"
              name="country"
              control={control}
              error={errors.country}
              containerClassName={styles.input}
              as={
                <Select
                  size="large"
                  placeholder="Select country or region"
                  showSearch
                  optionFilterProp="children"
                >
                  {countries.map((country) => (
                    <Select.Option
                      key={`country-option-${country.id}`}
                      value={country.id || ''}
                    >
                      {country.name}
                    </Select.Option>
                  ))}
                </Select>
              }
            />
          </Col>

          <Col xs={24} md={12}>
            <Controller
              label="ZIP/Postal code"
              name="zip"
              error={errors.zip}
              control={control}
              placeholder="Select Zip/Postal code"
              as={Input}
              containerClassName={cx(styles.input, 'dark-input-group')}
            />
          </Col>
        </Row>

        <Row>
          <Controller
            name="isDefault"
            control={control}
            error={errors.isDefault}
            render={(field) => (
              <Checkbox {...field}>Set as default payment method</Checkbox>
            )}
          />
        </Row>
      </div>
    </Form>
  );
}
