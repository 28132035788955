import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { isNil, isString } from 'lodash';
import { Tab } from 'rc-tabs/lib/interface';

import { SettingsTabEnum } from 'constants/SettingsConstants';
import UserManagementTab from './UserManagementTab';
import UserGroupsTab from './UserGroupsTab';
import useSearchQueryMatch from 'hooks/useSearchQueryMatch';
import { InviteUsersFormValues } from '../InviteUsersModal';
import LicensePaymentSection from '../LicensePaymentSection';

import styles from './UserManagementSection.module.less';

enum UserManagementSections {
  Users = 'users',
  UserGroups = 'user-groups',
}

const TABS = Object.values(UserManagementSections);
const DEFAULT_TAB = UserManagementSections.Users;

export default function UserManagementSection() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const pageSectionMatch = useSearchQueryMatch(TABS, 'tab');
  const activeKey = query.tab;
  const [licensePaymentUsersDetails, setLicensePaymentUsersDetails] =
    useState<InviteUsersFormValues>();

  const handleLicensePaymentSectionView = (values: InviteUsersFormValues) => {
    setLicensePaymentUsersDetails(values);
  };

  const handleLicensePaymentSectionHide = () => {
    setLicensePaymentUsersDetails(undefined);
  };

  useEffect(() => {
    if (!pageSectionMatch && query.section === SettingsTabEnum.UserManagement) {
      navigate(
        `${location.pathname}?section=${SettingsTabEnum.UserManagement}&tab=${DEFAULT_TAB}`,
        { replace: true },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSectionMatch, query.section]);

  const getTabs = (): Tab[] => [
    {
      key: UserManagementSections.Users,
      label: (
        <Link
          to={{
            search: queryString.stringify({
              tab: UserManagementSections.Users,
              section: SettingsTabEnum.UserManagement,
            }),
          }}
        >
          Users
        </Link>
      ),
      children: (
        <UserManagementTab
          handleLicensePaymentSectionView={handleLicensePaymentSectionView}
        />
      ),
    },
    {
      key: UserManagementSections.UserGroups,
      label: (
        <Link
          to={{
            search: queryString.stringify({
              tab: UserManagementSections.UserGroups,
              section: SettingsTabEnum.UserManagement,
            }),
          }}
        >
          User groups
        </Link>
      ),
      children: <UserGroupsTab />,
    },
  ];

  return (
    <>
      {!isNil(licensePaymentUsersDetails) && (
        <LicensePaymentSection
          usersDetails={licensePaymentUsersDetails}
          onClose={handleLicensePaymentSectionHide}
        />
      )}
      {isNil(licensePaymentUsersDetails) && (
        <div className={styles.wrapper}>
          <div className={styles.menu}>
            <Tabs
              activeKey={isString(activeKey) ? activeKey : DEFAULT_TAB}
              animated={false}
              destroyInactiveTabPane
              items={getTabs()}
            />
          </div>
        </div>
      )}
    </>
  );
}
