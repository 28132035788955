import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  PreSetupPTTemplateDetailsResponseModel,
  ProgressTrackerTemplateResponseModel,
} from 'api';
import { isNil } from 'lodash';

import { AppDispatch } from 'redux/store';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import AlertMessage from 'components/AlertMessage';
import {
  getPreSetupProgressTrackerTemplateDetails,
  getPreSetupProgressTrackerTemplateDetailsByAdmin,
  getTemplateDetails,
} from 'redux/reducers/templateSlice';
import { getSpaceRole } from 'redux/reducers/roleSlice';
import { getUserInfo } from 'redux/reducers/authenticationSlice';
import { currentSpaceNameIdSelector } from 'redux/selectors/spacesSelectors';
import { TemplateEnum } from 'features/template/TemplateListPage/UserTemplatesListPage';
import { isAdminSelector } from 'redux/selectors/authenticationSelectors';

type TemplateArguments = {
  templateId: string;
  isInitialLoad?: boolean;
  mode?: TemplateEnum;
};

type detailsType =
  | PreSetupPTTemplateDetailsResponseModel
  | ProgressTrackerTemplateResponseModel;

export const useFetchTemplatesDetails = ({
  templateId,
  isInitialLoad = true,
  mode = TemplateEnum.MyTemplates,
}: TemplateArguments) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const isAdmin = useSelector(isAdminSelector);
  const [details, setTemplateDetails] = useState<detailsType>();
  const { spaceId } = useSelector(currentSpaceNameIdSelector);

  useEffect(() => {
    const initialLoad = async () => {
      setIsLoading(true);
      await loadTemplateDetails();
      setIsLoading(false);
    };

    isInitialLoad && initialLoad();
    // eslint-disable-next-line
  }, [templateId]);

  useEffect(() => {
    const detailsSpaceId = (details as ProgressTrackerTemplateResponseModel)
      ?.spaceId;

    if (isNil(detailsSpaceId)) return;

    if (details && detailsSpaceId !== spaceId) {
      (async () => {
        const result = await dispatch(getUserInfo(detailsSpaceId));

        if (getUserInfo.fulfilled.match(result)) {
          dispatch(getSpaceRole());
        } else {
          AlertMessage.error(result.error.message || ApiErrorMessage);
        }
      })();
    }
  }, [details]);

  const getCorrectAction = () => {
    if (mode === TemplateEnum.MyTemplates) return getTemplateDetails;

    return isAdmin
      ? getPreSetupProgressTrackerTemplateDetailsByAdmin
      : getPreSetupProgressTrackerTemplateDetails;
  };

  const loadTemplateDetails = async () => {
    setIsLoading(true);

    const correctAction = getCorrectAction();

    const result = await dispatch(correctAction(templateId));
    setIsLoading(false);

    if (!correctAction.fulfilled.match(result)) {
      AlertMessage.error(result.error.message || ApiErrorMessage);
      return;
    }
    setTemplateDetails(result.payload);
  };

  return [details, isLoading, loadTemplateDetails] as [
    detailsType,
    boolean,
    () => Promise<void>,
  ];
};
