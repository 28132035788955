import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import styles from '../TermsAndConditions.module.less';

type Props = {
  agreementHtml: string;
};
export default function TermsAndConditionsTab(props: Props) {
  const { agreementHtml } = props;
  const [isScrollToTopButtonVisible, setIsScrollToTopButtonVisible] =
    useState(false);
  const contentRef: any = useRef();

  const toggleScrollToTopButtonVisibility = () => {
    setIsScrollToTopButtonVisible(contentRef.current.scrollTop > 900);
  };

  useEffect(() => {
    contentRef.current.addEventListener(
      'scroll',
      toggleScrollToTopButtonVisibility,
    );
  }, []);

  const handleScrollToTop = () => {
    contentRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="page-inner grid-scroll" ref={contentRef}>
      <div className={styles.content}>
        <button
          className={cx(styles.button, {
            [styles.buttonVisible]: isScrollToTopButtonVisible,
          })}
          onClick={handleScrollToTop}
        >
          <i className="icon icon-angle-top" />
        </button>
        <div
          dangerouslySetInnerHTML={{
            __html: agreementHtml,
          }}
        />
      </div>
    </div>
  );
}
