import React, { useMemo, useState } from 'react';
import { differenceWith, isEqual, uniqueId, unset } from 'lodash';

import {
  AdminProgressTrackerHistoryResponseModel,
  PTStageEditRequestModel,
  StageStatusEnum,
} from 'api';
import AlertMessage from 'components/AlertMessage';
import { trackerHistoryDateFormat } from 'constants/TrackerConstants';
import { downloadFile } from 'utils/fileUtils';
import TrackerChangesCard from 'features/progressTracker/ProgressTrackerDetailsPage/TrackerHistorySection/TrackerChanges/TrackerChangesCard/TrackerChangesCard';
import { getDateForUserTimezone } from 'utils/dateUtils';

import styles from './TrackerChanges.module.less';

type Props = {
  historyDetails: AdminProgressTrackerHistoryResponseModel;
};

type StageDifference = {
  oldStage: PTStageEditRequestModel;
  newStage: PTStageEditRequestModel;
};

export default React.memo(function (props: Props) {
  const { historyDetails } = props;
  const { updatedByName, createdDate, details, id } = historyDetails;
  const historyId = id || uniqueId();
  const [stageWithUpdatedStatus, setStageWithUpdatedStatus] =
    useState<PTStageEditRequestModel>();

  const oldStages = details?.oldData?.stages || [];
  const newStages = details?.newData?.stages || [];

  const isNewTrackerName = details?.oldData?.name !== details?.newData?.name;
  const isMovedToAnotherProject =
    details?.oldData?.folderId !== details?.newData?.folderId;

  const deletedStages = differenceWith(
    oldStages,
    newStages,
    (oldStage, newStage) => {
      return oldStage.id === newStage.id;
    },
  );

  const addedStages = differenceWith(
    newStages,
    oldStages,
    (newStage, oldStage) => {
      return newStage.id === oldStage.id;
    },
  );

  const changedStages: StageDifference[] = useMemo(
    () =>
      newStages.reduce((acc: StageDifference[], currentStage) => {
        const oldValue = oldStages.find(
          (oldStage) => oldStage.id === currentStage.id,
        );
        const isTrackerNew = !oldValue;
        if (isTrackerNew) return acc;

        const isNewStageStatus = currentStage.status !== oldValue.status;

        if (isNewStageStatus) {
          setStageWithUpdatedStatus(currentStage);
          return acc;
        }

        const currentStageCopy = { ...currentStage };
        const oldValueCopy = { ...oldValue };

        unset(currentStageCopy, 'sequence');
        unset(oldValueCopy, 'sequence');

        const isChanged = !isEqual(currentStageCopy, oldValueCopy);

        if (isChanged) {
          return [
            ...acc,
            {
              oldStage: oldValue,
              newStage: currentStage,
            },
          ];
        }

        return acc;
      }, []),
    [],
  );

  const isAddedStagesOnly = useMemo(
    () =>
      addedStages.length &&
      !deletedStages.length &&
      !changedStages.length &&
      !isNewTrackerName,
    [isNewTrackerName, addedStages, deletedStages, changedStages],
  );

  const isDeletedStagesOnly = useMemo(
    () =>
      deletedStages.length &&
      !changedStages.length &&
      !addedStages.length &&
      !isNewTrackerName,
    [deletedStages, changedStages, addedStages, isNewTrackerName],
  );

  const isChangedTrackerNameOnly = useMemo(
    () =>
      isNewTrackerName &&
      !deletedStages.length &&
      !changedStages.length &&
      !addedStages.length,
    [isNewTrackerName, deletedStages, changedStages, addedStages],
  );

  const hasSeveralChanges = useMemo(
    () =>
      [
        changedStages.length,
        addedStages.length,
        deletedStages.length,
        isNewTrackerName,
      ].filter(Boolean).length >= 2,
    [changedStages, addedStages, deletedStages, isNewTrackerName],
  );

  const handleDownloadAttachment = async (
    fileName: string,
    getFileUrl?: string,
  ) => {
    if (!getFileUrl) return;

    try {
      await downloadFile({
        downloadUrl: getFileUrl,
        fileName: fileName,
      });
    } catch (e: any) {
      AlertMessage.error(e.message);
    }
  };

  const displayTrackerChanges = () => {
    if (isMovedToAnotherProject) {
      return (
        <TrackerChangesCard
          key={historyId}
          header={
            <>
              <span className={styles.bold}>{updatedByName}</span> moved the
              tracker to another project at
              {getDateForUserTimezone(createdDate, trackerHistoryDateFormat)}
            </>
          }
          oldValue={details?.oldData?.folderName}
          newValue={details?.newData?.folderName}
        />
      );
    }

    if (isChangedTrackerNameOnly) {
      return (
        <TrackerChangesCard
          key={historyId}
          header={
            <>
              <span className={styles.bold}>{updatedByName}</span> updated
              tracker name at
              {getDateForUserTimezone(createdDate, trackerHistoryDateFormat)}
            </>
          }
          oldValue={details?.oldData?.name}
          newValue={details?.newData?.name}
        />
      );
    }

    if (stageWithUpdatedStatus) {
      const stageStatus =
        stageWithUpdatedStatus.status === StageStatusEnum.Done
          ? 'completed'
          : 'reset';

      return (
        <div className={styles.card} key={historyId}>
          {getDateForUserTimezone(createdDate, trackerHistoryDateFormat)}{' '}
          <span>{updatedByName}</span> {stageStatus} stage{' '}
          {stageWithUpdatedStatus.sequence}
        </div>
      );
    }

    if (isAddedStagesOnly) {
      const isPlural = addedStages.length > 1;

      return (
        <TrackerChangesCard
          key={historyId}
          header={
            <>
              {getDateForUserTimezone(createdDate, trackerHistoryDateFormat)}{' '}
              <span className={styles.bold}>{updatedByName} </span> added new
              stage{isPlural && 's'}
            </>
          }
        >
          {addedStages.map((addedStage) => (
            <div key={addedStage?.id}>
              <div>
                Stage {addedStage?.sequence} <strong>{addedStage?.name}</strong>
              </div>
            </div>
          ))}
        </TrackerChangesCard>
      );
    }

    if (isDeletedStagesOnly) {
      const isPlural = deletedStages.length > 1;

      return (
        <TrackerChangesCard
          key={historyId}
          header={
            <>
              {getDateForUserTimezone(createdDate, trackerHistoryDateFormat)}{' '}
              <span className={styles.bold}>{updatedByName}</span> removed stage
              {isPlural && 's'}
            </>
          }
        >
          {deletedStages.map((deletedStage) => (
            <div key={deletedStage.id}>{deletedStage.name}</div>
          ))}
        </TrackerChangesCard>
      );
    }

    if (changedStages.length || hasSeveralChanges) {
      return (
        <TrackerChangesCard
          key={historyId}
          header={
            <>
              {getDateForUserTimezone(createdDate, trackerHistoryDateFormat)}{' '}
              <span className={styles.bold}>{updatedByName}</span> updated
              tracker
            </>
          }
        >
          {changedStages.map(({ oldStage, newStage }) => {
            const isNameChanged = oldStage.name !== newStage.name;
            const isDescriptionChanged =
              oldStage.description !== newStage.description;
            const isDueDateChanged = !isEqual(
              oldStage.dueDate,
              newStage.dueDate,
            );
            const isAttachmentChanged = !isEqual(
              oldStage.attachments,
              newStage.attachments,
            );

            return (
              <div className={styles.box} key={uniqueId()}>
                <div className={styles.left}>
                  {isNameChanged && (
                    <div>
                      Stage {oldStage.sequence} name: {oldStage.name}
                    </div>
                  )}
                  {isDescriptionChanged && (
                    <div>
                      Stage {oldStage.sequence} description:{' '}
                      {oldStage.description}
                    </div>
                  )}
                  {isDueDateChanged && (
                    <div>
                      Stage {oldStage.sequence} due date{' '}
                      {oldStage.dueDate
                        ? getDateForUserTimezone(
                            oldStage.dueDate,
                            trackerHistoryDateFormat,
                          )
                        : 'none'}
                    </div>
                  )}
                  {isAttachmentChanged && (
                    <div>
                      Stage {oldStage.sequence} attachment:{' '}
                      {oldStage.attachments?.length
                        ? oldStage.attachments.map(
                            ({ fileName, id, getFileUrl }) => (
                              <span
                                key={id}
                                className={
                                  getFileUrl ? styles.active : styles.disabled
                                }
                                onClick={() =>
                                  handleDownloadAttachment(
                                    fileName || '',
                                    getFileUrl,
                                  )
                                }
                              >
                                {fileName}
                              </span>
                            ),
                          )
                        : 'none'}
                    </div>
                  )}
                </div>
                <div className={styles.right}>
                  {isNameChanged && (
                    <div>
                      <i className="icon icon-arrow-right"></i>
                      Stage {newStage.sequence} name: {newStage.name}
                    </div>
                  )}
                  {isDescriptionChanged && (
                    <div>
                      <i className="icon icon-arrow-right"></i>
                      Stage {newStage.sequence} description:{' '}
                      {newStage.description}
                    </div>
                  )}
                  {isDueDateChanged && (
                    <div>
                      <i className="icon icon-arrow-right"></i>
                      <div>
                        Stage {newStage.sequence} due date{' '}
                        {newStage.dueDate
                          ? getDateForUserTimezone(
                              newStage.dueDate,
                              trackerHistoryDateFormat,
                            )
                          : 'none'}
                      </div>
                    </div>
                  )}
                  {isAttachmentChanged && (
                    <div>
                      <i className="icon icon-arrow-right"></i>
                      Stage {newStage.sequence} attachment:{' '}
                      {newStage.attachments?.length
                        ? newStage.attachments.map(
                            ({ fileName, id, getFileUrl }) => (
                              <span
                                key={id}
                                className={
                                  getFileUrl ? styles.active : styles.disabled
                                }
                                onClick={() =>
                                  handleDownloadAttachment(
                                    fileName || '',
                                    getFileUrl,
                                  )
                                }
                              >
                                {' '}
                                {fileName}
                              </span>
                            ),
                          )
                        : 'none'}
                    </div>
                  )}
                </div>
              </div>
            );
          })}

          {isNewTrackerName && (
            <div className={styles.box}>
              <div className={styles.left}>
                Tracker name {details?.oldData?.name}
              </div>
              <div className={styles.right}>
                <div>
                  <i className="icon icon-arrow-right"></i>
                  Tracker name {details?.newData?.name}
                </div>
              </div>
            </div>
          )}

          {!!addedStages.length &&
            addedStages.map((addedStage) => (
              <div key={addedStage.id} className={styles.box}>
                <div className={styles.left}>
                  Added stage {addedStage.sequence}.
                </div>
                <div className={styles.right}>
                  Stage name: {addedStage.name}
                </div>
              </div>
            ))}

          {!!deletedStages.length &&
            deletedStages.map((deletedStage) => (
              <div key={deletedStage.id} className={styles.box}>
                <div className={styles.left}>
                  Deleted stage {deletedStage.sequence}.
                </div>

                <div className={styles.right}>
                  Stage name:
                  {deletedStage.name}
                </div>
              </div>
            ))}
        </TrackerChangesCard>
      );
    }
  };

  return <>{displayTrackerChanges()}</>;
});
