import { ReactNode } from 'react';
import { Checkbox, Dropdown } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { isEmpty } from 'lodash';

import styles from './CheckboxDropdown.module.less';

export type ItemType = {
  key: string | number;
  value: string | number;
  title: ReactNode;
};

type Props = {
  values: CheckboxValueType[];
  onChange: (values: CheckboxValueType[]) => void;
  items: ItemType[];
  children: ReactNode;
};

export default function CheckboxDropdown(props: Props) {
  const { values, onChange, items, children } = props;

  return (
    <Checkbox.Group value={values} onChange={onChange}>
      <Dropdown
        trigger={['click']}
        menu={{
          items: items.map(({ value, key, title }) => ({
            label: (
              <span
                className={styles.dropdownItem}
                onClick={(e) => e.stopPropagation()}
              >
                <Checkbox value={value}>{title}</Checkbox>
              </span>
            ),
            key,
          })),
        }}
      >
        <div
          style={{
            fontWeight: isEmpty(values) ? 300 : 600,
            cursor: 'pointer',
          }}
        >
          {children}
        </div>
      </Dropdown>
    </Checkbox.Group>
  );
}
