import React, {
  BaseSyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

import {
  CompletenessFilterEnum,
  FolderResponseModel,
  FolderResponseModelPagedResponseModel,
  SortFieldEnum,
  SortOrderEnum,
} from 'api';
import AlertMessage from 'components/AlertMessage';
import { PageDefaultSize } from 'constants/ApiConstant';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { ALL_VALUES_KEY } from 'constants/FilterConstants';
import { AnalyticFiltersTypes } from 'features/analytics/AnalyticsSection/AnalyticsSection';
import {
  getProjectsPaged,
  GetProjectsPagedModel,
} from 'redux/reducers/projectSlice';
import { AppDispatch } from 'redux/store';

import styles from 'features/analytics/AnalyticsSection/AnalyticsSection.module.less';

type Props = {
  handleChange: (filterName: AnalyticFiltersTypes, value: string) => void;
  value: string;
};

export default React.memo((props: Props) => {
  const isLightTheme = useSelector(isLightThemeSelector);
  const { handleChange, value } = props;
  const [projects, setProjects] = useState<FolderResponseModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const dispatch: AppDispatch = useDispatch();

  const selectOptions = [
    { value: ALL_VALUES_KEY, label: <div>Project Name</div> },
    ...projects.map((project) => ({
      value: project.id,
      label: project?.name,
    })),
  ];

  useEffect(() => {
    onLoadMore(pageNumber);
  }, []);

  const onSetProjects = useCallback(
    (payload: FolderResponseModelPagedResponseModel) => {
      const loadedProjects = payload.details || [];
      const totalCount = payload.totalCount || 0;
      const newData = [...projects, ...loadedProjects];
      const hasMoreRows =
        !!newData.length &&
        newData.length < totalCount &&
        loadedProjects.length > 0;

      setHasMore(hasMoreRows);
      setProjects(newData);
    },
    [projects],
  );

  const onLoadMore = useCallback(
    async (pageNumber: number) => {
      setIsLoading(true);

      const requestBody: GetProjectsPagedModel = {
        completenessFilter: CompletenessFilterEnum.All,
        sortField: SortFieldEnum.Name,
        sortOrder: SortOrderEnum.Asc,
        pageSize: PageDefaultSize,
        pageNumber,
      };

      const result = await dispatch(getProjectsPaged(requestBody));

      if (getProjectsPaged.fulfilled.match(result)) {
        onSetProjects(result.payload);
        setPageNumber(pageNumber + 1);
      } else {
        setHasMore(false);
        AlertMessage.error(result.error.message || ApiErrorMessage);
      }

      setIsLoading(false);
    },
    [dispatch, getProjectsPaged, onSetProjects],
  );

  const handlePopupScroll = async (event: BaseSyntheticEvent) => {
    const clientHeight = event.target.clientHeight;
    const scrollHeight = event.target.scrollHeight;
    const scrollTop = event.target.scrollTop;

    if (scrollTop + clientHeight === scrollHeight && hasMore && !isLoading) {
      await onLoadMore(pageNumber);
    }
  };

  return (
    <div
      className={cx(styles.headerDrop, {
        [styles.headerDropDark]: !isLightTheme,
      })}
    >
      <Select
        style={{
          fontWeight: value === ALL_VALUES_KEY ? 300 : 600,
        }}
        value={value}
        onChange={(value) => handleChange(AnalyticFiltersTypes.Project, value)}
        options={selectOptions}
        loading={isLoading}
        onPopupScroll={handlePopupScroll}
      />
    </div>
  );
});
