import styles from './SearchPage.module.less';

export default function NothingFound() {
  return (
    <div className={styles.noFoundWrapp}>
      <div className={styles.noFound}>
        <div className={styles.capture}>
          <span></span>
          <strong>Oops!</strong>
          <span></span>
        </div>
        <h1 className={styles.title}>Looks like this tracker does not exist</h1>
        <div className={styles.subtitle}>Please try entering another tracker ID</div>
      </div>
    </div>
  );
}
