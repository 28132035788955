import React from 'react';
import { useSelector } from 'react-redux';

import { getStepsByPage } from 'utils/onboardingUtils';
import {
  onboardingPageSelector,
  tourProgressSelector,
} from 'redux/selectors/onboardingSelector';

import styles from './OnboardingProgress.module.less';

const logoStripesAttributes = [
  {
    d: 'M52.3395 43.7852L51.459 46.2564L51.8159 46.6221L53.2414 45.4175L52.3395 43.7852Z',
    fill: '#FCD228',
  },
  {
    d: 'M45.918 46.5205H46.5394L50.7751 40.9849L49.5327 38.751L45.918 46.5205Z',
    fill: '#FCD228',
  },
  {
    d: 'M46.8128 33.8706L40.439 46.1094L40.9156 46.4021L48.1128 36.2043L46.8128 33.8706Z',
    fill: '#FCD228',
  },
  {
    d: 'M44.1902 29.1665L35.0068 46.1048L35.4845 46.3969L45.5294 31.5715L44.1902 29.1665Z',
    fill: '#FCD228',
  },
  {
    d: 'M41.545 24.4224L29.6375 46.1035L30.1167 46.3936L42.8997 26.8567L41.545 24.4224Z',
    fill: '#FCD228',
  },
  {
    d: 'M38.8159 19.5244L24.0007 46.1022L24.4801 46.3918L40.1839 21.9786L38.8159 19.5244Z',
    fill: '#FCD228',
  },
  {
    d: 'M36.2407 14.9072L18.8542 46.1026L19.3352 46.39L37.6163 17.3762L36.2407 14.9072Z',
    fill: '#FCD228',
  },
  {
    d: 'M33.5415 10.0605L13.4541 46.1026L13.936 46.3883L34.9262 12.5485L33.5415 10.0605Z',
    fill: '#FCD228',
  },
  {
    d: 'M32.2977 7.83445L30.9094 5.33643L8.1875 46.1027L8.67032 46.3872L32.2115 7.97466L32.2977 7.83445Z',
    fill: '#FCD228',
  },
  {
    d: 'M28.2551 0.578125L2.88184 46.1024L3.36527 46.3859L29.65 3.07911L28.2551 0.578125Z',
    fill: '#FCD228',
  },
];

export default function OnboardnigProgress() {
  const tourProgress = useSelector(tourProgressSelector);
  const onboardingPage = useSelector(onboardingPageSelector);
  const stepsNumber = getStepsByPage(onboardingPage).length;
  const progressStep = Math.round(logoStripesAttributes.length / stepsNumber);
  const progressCounter = progressStep * tourProgress.length;

  return (
    <div className={styles.progressWrapper}>
      <div className={styles.progressCircle}>
        <svg
          width="56"
          height="51"
          viewBox="0 0 56 51"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_9545_175494)">
            {logoStripesAttributes
              .map((attributes, index) => (
                <path
                  {...attributes}
                  key={index}
                  fillOpacity={index + 1 < progressCounter ? '1' : '0.1'}
                />
              ))
              .reverse()}
          </g>
          <defs>
            <filter
              id="filter0_d_9545_175494"
              x="0.881836"
              y="0.578125"
              width="54.3596"
              height="50.0439"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="2" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0868839 0 0 0 0 0.037875 0 0 0 0 0.225 0 0 0 1 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_9545_175494"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_9545_175494"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
    </div>
  );
}
