import React from 'react';

import Accessor from 'components/accessors';
import { RolesEnum } from '../../../enums/Roles.enum';
import AdminTemplatesPage from 'features/admin/AdminTemplatesPage';
import UserTemplatesListPage from './UserTemplatesListPage';

export default function TemplateListPage() {
  return (
    <>
      <Accessor allowedRoles={[RolesEnum.Admin]}>
        <AdminTemplatesPage />
      </Accessor>

      <Accessor allowedRoles={[RolesEnum.Customer]}>
        <UserTemplatesListPage />
      </Accessor>
    </>
  );
}
