import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { isNil } from 'lodash';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

export const activityFormat = 'hh:mm A';
export const dateFormat = 'MM/DD/YYYY';

export const COMMENT_DATE_FORMAT = 'MMMM DD, YYYY [at] hh.mm A';

export const NEXT_BILLING_DATE_DATE_FORMAT = 'MMMM DD, YYYY';

export const BILLING_DATE_FORMAT = 'MMM DD, YYYY HH:mm:ss';

export const getDateFromNow = (date?: Date) => {
  return dayjs(date).fromNow(false);
};

export const formatDateForUserTimezone = (date?: string | Date) => {
  const tz = dayjs.tz.guess();
  const dayjsLocal = dayjs(date);
  return dayjsLocal.tz(tz);
};

export const getDateForUserTimezone = (
  date?: string | Date,
  defaultDateFormat = dateFormat,
) => {
  if (isNil(date)) return null;

  const formattedDate = formatDateForUserTimezone(date);
  return dayjs(formattedDate).format(defaultDateFormat);
};
