import { memo, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDidUpdate } from 'rooks';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { App, Divider, Form, Input, Radio, Spin } from 'antd';
import cx from 'classnames';

import {
  SpaceRoleResponseModel,
  UserSpaceInvitationResponseModel,
  UserSpaceRoleEnum,
} from 'api';
import Controller from 'components/form/Controller';
import PTModal, { PTModalProps } from 'components/PTModal';
import RadioGroup from 'components/form/RadioGroup';
import AlertMessage from 'components/AlertMessage';
import { confirmIcon } from 'constants/ConfirmationConstants';
import { requiredMessage } from 'constants/ValidationConstants';
import { changeSpaceRole, getRolesToInvite } from 'redux/reducers/inviteSlice';
import { AppDispatch } from 'redux/store';
import { currentSpaceNameIdSelector } from 'redux/selectors/spacesSelectors';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { getRoleDescription } from 'utils/roleUtils';

import styles from './EditInvitedUserModal.module.less';

export enum InviteEditeUserModalMode {
  Invite = 1,
  Edit = 2,
}

enum SpaceRoleNames {
  Associate = UserSpaceRoleEnum.Basic,
  Admin = UserSpaceRoleEnum.Admin,
}

type Props = {
  onClose: () => void;
  onSuccess: (
    mode: InviteEditeUserModalMode,
    newRoleId?: UserSpaceRoleEnum,
    newRoleName?: string,
  ) => Promise<void>;
  mode?: InviteEditeUserModalMode;
  invitedUser?: UserSpaceInvitationResponseModel;
} & Omit<PTModalProps, 'children'>;

interface FormValues {
  email: string;
  roleId: UserSpaceRoleEnum;
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required(requiredMessage)
    .email('Email format is invalid')
    .max(120, 'Email should be 120 characters maximum'),
  roleId: Yup.string().required(requiredMessage),
});

export default memo((props: Props) => {
  const { onClose, onSuccess, mode, invitedUser, open, ...otherModalProps } =
    props;
  const modalTitle = mode && `${InviteEditeUserModalMode[mode]} user`;

  const {
    errors,
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const dispatch: AppDispatch = useDispatch();
  const { modal } = App.useApp();

  const [isPending, setIsPending] = useState(false);
  const [rolesToInvite, setRolesToInvite] = useState<SpaceRoleResponseModel[]>(
    [],
  );
  const [errorFromServer, setErrorFromServer] = useState('');

  const { spaceId } = useSelector(currentSpaceNameIdSelector);
  const isLightTheme = useSelector(isLightThemeSelector);

  useEffect(() => {
    if (!open) return;

    const fetchRolesToInvite = async () => {
      const result = await dispatch(getRolesToInvite());

      if (getRolesToInvite.fulfilled.match(result)) {
        setRolesToInvite(result.payload);
      } else {
        onClose();
        AlertMessage.error(result.error.message || ApiErrorMessage);
      }
    };
    fetchRolesToInvite();
  }, [open]);

  useDidUpdate(() => {
    reset({
      email: invitedUser?.inviteEmail,
      roleId: invitedUser?.roleId,
    });
  }, [invitedUser]);

  const handleEditUserRole = async (values: FormValues) => {
    modal.confirm({
      title: 'The role for this user will be changed. Are you sure?',
      icon: confirmIcon,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      okText: 'Yes',
      async onOk() {
        await onEditUserRole(values);
      },
    });
  };

  const onEditUserRole = async (values: FormValues) => {
    const result = await dispatch(
      changeSpaceRole({
        spaceId,
        userId: invitedUser?.userId,
        roleId: values.roleId,
      }),
    );

    if (changeSpaceRole.fulfilled.match(result)) {
      await onSuccess(
        InviteEditeUserModalMode.Edit,
        +values.roleId,
        SpaceRoleNames[values.roleId],
      );
      onClose();
      AlertMessage.success('The role was successfully changed.');
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const onSubmit = async (values: FormValues) => {
    setIsPending(true);

    switch (mode) {
      case InviteEditeUserModalMode.Edit:
        await handleEditUserRole(values);
        break;
    }

    setIsPending(false);
  };

  const handleOk = () => {
    handleSubmit(onSubmit)();
  };

  const handleModalCancel = () => {
    if (isSubmitting) return;

    if (isDirty) {
      return modal.confirm({
        title: 'Changes won’t be saved. Are you sure?',
        icon: <span className="icon icon-info-circle confirm-icon" />,
        className: cx('confirm-modal', {
          'confirm-modal-dark-button': !isLightTheme,
        }),
        okText: 'Yes',
        width: '496px',
        onOk() {
          onClose();
        },
        onCancel() {},
      });
    }
    onClose();
  };

  const onEmail = () => {
    if (errorFromServer) setErrorFromServer('');
  };

  return (
    <PTModal
      {...otherModalProps}
      open={open}
      okText="Save"
      onOk={handleOk}
      onCancel={handleModalCancel}
      okButtonProps={{ loading: isPending }}
      title={modalTitle}
      closable
      className="modal-root"
    >
      <Form layout="vertical">
        <div className="modal-scroll-wrapper">
          <Controller
            name="email"
            control={control}
            error={errorFromServer ? errorFromServer : errors.email}
            label="Email"
            placeholder="Enter email address"
            disabled={mode === InviteEditeUserModalMode.Edit}
            as={<Input onInput={onEmail} />}
            size="large"
            containerClassName="dark-input-group"
          />

          <Divider />

          <Controller
            name="roleId"
            control={control}
            error={errors.roleId}
            label="Assign Role"
            render={(field) => (
              <RadioGroup {...field} className={styles.radioGroup}>
                {rolesToInvite.length ? (
                  rolesToInvite.map(({ roleId, roleName }) => (
                    <Radio key={roleId} value={roleId}>
                      <div className={styles.title}>{roleName}</div>
                      <div>{getRoleDescription(roleId)}</div>
                    </Radio>
                  ))
                ) : (
                  <Spin />
                )}
                ))
              </RadioGroup>
            )}
          />
        </div>
      </Form>
    </PTModal>
  );
});
