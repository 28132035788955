import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'redux/reducers/rootReducer';
import { RolesEnum } from 'enums/Roles.enum';
import { UserSpaceRoleEnum } from 'api';
import { roleInfoSelector } from './roleSelectors';

export const userSelector = (state: RootState) => state.auth.user;

export const isSignAgreementSelector = (state: RootState) =>
  state.auth.isSignAgreement;

export const spaceNameSelector = createSelector(
  userSelector,
  (user) => user?.spaceName,
);

export const spaceIdSelector = createSelector(
  userSelector,
  (user) => user?.spaceId,
);

export const isAuthenticatedSelector = createSelector(
  userSelector,
  (user) => !!user,
);

export const userRoleSelector = (state: RootState) => state.auth.role;

export const isAdminSelector = (state: RootState) =>
  state.auth.role === RolesEnum.Admin;

export const hasRequiredRoleSelector = (roles: RolesEnum[]) =>
  createSelector(userRoleSelector, (role) => {
    return role && roles.includes(role);
  });

export const hasRequiredTeammateRoleSelector = (roles: UserSpaceRoleEnum[]) =>
  createSelector(roleInfoSelector, (role) => {
    return role && roles.includes(role.roleId || -1);
  });
