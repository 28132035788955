import React from 'react';
import cx from 'classnames';

type Props = {
  icon: string;
  title: string;
  onClick: () => void;
};

export default function DetailsPanelHeader(props: Props) {
  const { icon, title, onClick } = props;

  const handleCopyClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <div className="details-card__row">
      <i className={cx(`icon details-card__icon ${icon}`)} />
      <div className="details-card__title">{title}</div>
      <i
        className="icon icon-copy details-card__copy"
        onClick={handleCopyClick}
      />
    </div>
  );
}
