import React from 'react';
import { useSelector } from 'react-redux';

import { isAuthenticatedSelector } from 'redux/selectors/authenticationSelectors';
import UserHomePage from '../UserHomePage';
import LandingPage from '../LandingPage';
import ProjectListPage from 'features/admin/ProjectListPage';
import Accessor from 'components/accessors';
import { RolesEnum } from 'enums/Roles.enum';

export default function HomePage() {
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  if (!isAuthenticated) return <LandingPage />;

  return (
    <>
      <Accessor allowedRoles={[RolesEnum.Customer]}>
        <UserHomePage />
      </Accessor>

      <Accessor allowedRoles={[RolesEnum.Admin]}>
        <ProjectListPage />
      </Accessor>
    </>
  );
}
