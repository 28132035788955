import jwt_decode from 'jwt-decode';

import { TokenResponseModel } from 'api';
import { RolesEnum } from 'enums/Roles.enum';
import localStorage from 'utils/localStorage';

const TOKEN_SHIFT_TIME = 15000; // 15 sec

export function isTokenExpired(decodedToken: any) {
  const safeShift = TOKEN_SHIFT_TIME;
  const currentTime = Date.now();

  return currentTime > decodedToken.exp * 1000 - safeShift;
}

export const getRole = () => {
  const token = localStorage?.getItem('jwt-token');

  if (!token) return null;

  const decodedToken = jwt_decode<{ role: RolesEnum }>(token);

  return decodedToken.role;
};

export const saveToken = (response: TokenResponseModel) => {
  const { token } = response;

  localStorage?.setItem('jwt-token', token || '');
};

export const fetchIpAddress = async () => {
  return await fetch('https://api.ipify.org/?format=json')
    .then((response) => response.json())
    .then((data) => {
      return data.ip;
    })
    .catch(() => {});
};
