import React, { useCallback, useEffect, useState } from 'react';
import { App, Button, Col, Divider, Form, Input, Row, Spin } from 'antd';
import cx from 'classnames';
import { isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStripe } from '@stripe/react-stripe-js';

import {
  SubscriptionRequestModel,
  SubscriptionSumDescriptionResponseModel,
  SubscriptionTypeEnum,
} from 'api';
import AlertMessage from 'components/AlertMessage';
import Controller from 'components/form/Controller';
import SubmitButton from 'components/SubmitButton';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import {
  confirmSubscription,
  createSubscription,
  updateSubscription,
} from 'redux/reducers/accountSlice';
import { getUserInfo } from 'redux/reducers/authenticationSlice';
import { getPriceDetails as getPriceDetailsApi } from 'redux/reducers/transactionSlice';
import {
  spaceIdSelector,
  userSelector,
} from 'redux/selectors/authenticationSelectors';
import { AppDispatch } from 'redux/store';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import {
  confirmCardPayment,
  getBillingCycleRequestValue,
  SuccessPaymentParams,
} from 'utils/creditCardUtils';
import { PriceTypesEnum } from 'enums/PriceTypesEnum';

import styles from './PriceDetailsSection.module.less';

const validationSchema = Yup.object().shape({
  couponCode: Yup.string().trim(),
});

export type PaymentLocationState = {
  isPaidSuccessfully: boolean;
};

type FormValues = {
  promotionCode: string;
};

type Props = {
  priceId?: string;
  creditCardId: string;
  onCancelUpgrading: () => void;
  priceType?: PriceTypesEnum;
};

export default function PriceDetailsSection(props: Props) {
  const { priceId, creditCardId, onCancelUpgrading, priceType } = props;
  const [isInitialPriceLoaded, setIsInitialPriceLoaded] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const [isPriceGetting, setIsPriceGetting] = useState(false);
  const [promotionMessage, setPromotionMessage] = useState<string>('');
  const [priceDetails, setPriceDetails] =
    useState<SubscriptionSumDescriptionResponseModel>();
  const [appliedPromo, setAppliedPromo] = useState<string>();

  const subtotalPrice = ((priceDetails?.subtotal || 0) / 100).toFixed(2);
  const totalPrice = ((priceDetails?.totalSum || 0) / 100).toFixed(2);
  const tax = ((priceDetails?.taxSum || 0) / 100).toFixed(2);
  const promotionCodeDetails = priceDetails?.promotionCode;

  const spaceId = useSelector(spaceIdSelector);
  const user = useSelector(userSelector);
  const isLightTheme = useSelector(isLightThemeSelector);
  const subscriptionType = user?.subscriptionType;
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const stripe = useStripe();
  const { modal } = App.useApp();

  const {
    control,
    formState,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    reset,
  } = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      promotionCode: '',
    },
  });
  const { errors, isDirty } = formState;
  const watchedPromotionCode = watch('promotionCode');

  const loadInitialPrice = useCallback(async () => {
    await getPriceDetails();

    setIsInitialPriceLoaded(true);
  }, []);

  useEffect(() => {
    loadInitialPrice();
  }, [loadInitialPrice]);

  const getPriceDetails = async (promotionCode?: string) => {
    setIsPriceGetting(true);
    const result = await dispatch(
      getPriceDetailsApi({
        priceId: priceId || '',
        promotionCode,
        defaultPaymentMethod: creditCardId,
        isUpgradeRequested: true,
        requestedBillingCycle: getBillingCycleRequestValue(priceType),
      }),
    );

    if (getPriceDetailsApi.fulfilled.match(result)) {
      setPriceDetails(result.payload);

      if (promotionCode) {
        const resultPromotionCode = result.payload?.promotionCode;
        const subtotal = result.payload.subtotal || 0;
        const tax = result.payload.taxSum || 0;

        if (resultPromotionCode) {
          clearErrors();
          setPromotionMessage(
            `You got a ${resultPromotionCode?.discountPercent}% discount from $${(
              (subtotal + tax) /
              100
            ).toFixed(2)}`,
          );
          setAppliedPromo(promotionCode);
        } else {
          setPromotionMessage('');
          setAppliedPromo(undefined);
          setError('promotionCode', { message: 'This promo is not valid.' });
        }

        reset({ promotionCode: watchedPromotionCode }, { errors: true });
      }
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
    setIsPriceGetting(false);
  };

  const handleUpgradeCancel = () => {
    if (isDirty) {
      return modal.confirm({
        title: 'Changes won’t be saved. Are you sure?',
        icon: <span className="icon icon-info-circle confirm-icon" />,
        className: cx('confirm-modal', {
          'confirm-modal-dark-button': !isLightTheme,
        }),
        width: '496px',
        onOk() {
          onCancelUpgrading();
        },
        onCancel() {},
      });
    }

    onCancelUpgrading();
  };

  const handlePay = useCallback(async () => {
    setIsPaying(true);

    const requestBody: SubscriptionRequestModel = {
      priceId,
      defaultPaymentMethod: creditCardId,
      spaceId: spaceId || '',
      promotionCode: appliedPromo,
    };

    let action;
    let result;

    if (subscriptionType === SubscriptionTypeEnum.Basic) {
      action = createSubscription;
      result = await dispatch(createSubscription(requestBody));
    } else {
      action = updateSubscription;
      result = await dispatch(updateSubscription(requestBody));
    }

    if (!action.fulfilled.match(result)) {
      AlertMessage.error(result.error.message || ApiErrorMessage);
      setIsPaying(false);
      return;
    }

    if (isEmpty(result.payload) || !result.payload) {
      await dispatch(getUserInfo(user?.spaceId));
      setIsPaying(false);
      navigate(
        { search },
        {
          state: { isPaidSuccessfully: true },
          replace: true,
        },
      );
      return;
    }

    const { clientSecret, errorMessage, intentType } = result.payload;

    if (!clientSecret || !intentType || errorMessage) {
      AlertMessage.error(errorMessage || 'Invalid card.');
      setIsPaying(false);
      return;
    }

    await confirmCardPayment(
      stripe,
      clientSecret,
      creditCardId,
      intentType,
      onSuccessPayment,
    );
    setIsPaying(false);
  }, []);

  const onSuccessPayment = async ({
    intentId,
    intentType,
  }: SuccessPaymentParams) => {
    if (!intentType || !intentId) return;

    await dispatch(
      confirmSubscription({
        intentId,
        spaceId: spaceId || '',
        intentType,
      }),
    );

    await dispatch(getUserInfo(user?.spaceId));

    setIsPaying(false);
    navigate(
      { search },
      {
        state: { isPaidSuccessfully: true },
        replace: true,
      },
    );
  };

  if (!isInitialPriceLoaded) return <Spin />;

  return (
    <div className={styles.wrapper}>
      <Form layout="vertical" onFinish={handleSubmit(handlePay)}>
        <Row gutter={[40, 24]}>
          <Col xs={24} lg={12}>
            <div className={styles.title}>Summary</div>
            <div className={styles.table}>
              <div className={styles.row}>
                <div>Subtotal</div>
                <strong>${subtotalPrice}</strong>
              </div>
              <div className={styles.row}>
                <div>Tax</div>
                <strong>${tax}</strong>
              </div>
              <Divider />
              <div className={styles.row}>
                <div>Total</div>
                <div className={styles.box}>
                  {promotionCodeDetails && (
                    <div className={styles.discount}>
                      ${(+subtotalPrice + +tax).toFixed(2)}
                    </div>
                  )}
                  <strong>${totalPrice}</strong>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} lg={12} className={styles.line}>
            <div>
              <Controller
                name="promotionCode"
                control={control}
                error={errors.promotionCode}
                message={promotionMessage}
                label="Have a promo? Enter your code."
                containerClassName={cx(styles.input, 'dark-input-group', {
                  [styles.dark]: !isLightTheme,
                })}
                addonAfter={
                  <Button
                    onClick={() => getPriceDetails(watchedPromotionCode)}
                    disabled={isPaying || !isDirty}
                    loading={isPriceGetting}
                  >
                    Apply
                  </Button>
                }
                as={Input}
              />
            </div>
            <div className={styles.btn}>
              <div>
                <Button
                  size="large"
                  disabled={isPaying}
                  onClick={handleUpgradeCancel}
                  className={cx({ 'dark-btn-outline': !isLightTheme })}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <SubmitButton
                  formState={formState}
                  disabled={isPriceGetting}
                  loading={!isInitialPriceLoaded}
                  size="large"
                  type="primary"
                >
                  Pay now ${totalPrice}
                </SubmitButton>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
