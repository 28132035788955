import { useParams } from 'react-router-dom';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';

import TemplateEdit from '../TemplateEdit';
import { useFetchTemplatesDetails } from 'hooks/useFetchTemplatesDetails';
import { isAdminSelector } from 'redux/selectors/authenticationSelectors';
import { TemplateEnum } from '../TemplateListPage/UserTemplatesListPage';

type ParamsProps = {
  templateId: string;
};

export default function TemplateEditPage() {
  const { templateId } = useParams<ParamsProps>();
  const isAdmin = useSelector(isAdminSelector);

  const [templateDetails, isLoading] = useFetchTemplatesDetails({
    templateId: templateId || '',
    mode: isAdmin ? TemplateEnum.TemplateLibrary : TemplateEnum.MyTemplates,
  });

  if (isLoading) return <Spin />;

  if (!templateDetails) return null;

  return <TemplateEdit initialValues={templateDetails} />;
}
