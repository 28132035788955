import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import pluralize from 'pluralize';
import { isNil } from 'lodash';
import { Button, Col, Row, Spin } from 'antd';

import ProjectsCompletenessChart from './ProjectsCompletenessChart';
import TrackersCompletenessChart from './TrackersCompletenessChart';
import ProjectsChart from './ProjectsChart';
import { getSpaceCountersAnalytics } from 'redux/reducers/dashboardSlice';
import { AppDispatch } from 'redux/store';
import { SpaceAnalyticsResponseModel } from 'api';
import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import LastActivitySection from './LastActivitySection';
import Breadcrumb from 'components/Breadcrumb';
import { exportTrackersExcel } from 'redux/reducers/projectSlice';
import {
  DEFAULT_DOWNLOAD_FILE_ERROR_MESSAGE,
  downloadFileByObjectURL,
} from 'utils/fileUtils';
import { userSelector } from 'redux/selectors/authenticationSelectors';

import AffairsBriefcaseImage from '../../../assets/img/affairs_briefcase.svg';
import AnalyticsImage from '../../../assets/img/analytics.svg';
import ClientsImage from '../../../assets/img/clients_customers.svg';

import styles from './DashboardSection.module.less';

export default function   DashboardSection() {
  const dispatch: AppDispatch = useDispatch();
  const [isInitialLoaded, setIsInitialLoaded] = useState(false);
  const [spaceAnalyticsInfo, setSpaceAnalyticsInfo] =
    useState<SpaceAnalyticsResponseModel>();
  const user = useSelector(userSelector);

  const loadSpaceCountersAnalytics = useCallback(async () => {
    const result = await dispatch(getSpaceCountersAnalytics());

    if (getSpaceCountersAnalytics.fulfilled.match(result)) {
      setSpaceAnalyticsInfo(result.payload);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
    setIsInitialLoaded(true);
  }, []);

  const projectsCount = pluralize(
    'Project',
    spaceAnalyticsInfo?.foldersCount || 0,
    false,
  );

  const clientsCount = pluralize(
    'Client',
    spaceAnalyticsInfo?.trackersCount || 0,
    false,
  );

  const trackersCount = pluralize(
    'Tracker',
    spaceAnalyticsInfo?.trackersCount || 0,
    false,
  );

  useEffect(() => {
    loadSpaceCountersAnalytics();
  }, []);

  const downloadTrackersExcel = async () => {
    const timezone = user?.userProfileDetails?.timeZone;
    const result = await dispatch(exportTrackersExcel(timezone));

    if (exportTrackersExcel.fulfilled.match(result)) {
      try {
        const { data, fileName } = result.payload;
        const url = URL.createObjectURL(data);

        await downloadFileByObjectURL(url, fileName);
      } catch {
        AlertMessage.error(DEFAULT_DOWNLOAD_FILE_ERROR_MESSAGE);
      }
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  return (
    <>
      <div className={styles.head}>
        <Breadcrumb
          routes={[
            {
              title: <i className="icon icon-home" />,
              link: '/',
            },
            {
              title: 'Dashboard',
            },
          ]}
        />
        <div className={styles.headBtn}>
          <Button
            onClick={downloadTrackersExcel}
            className="with-icon banner-btn"
            size="large"
          >
            <i className="icon icon-load" />
            Download Client Details
          </Button>
        </div>
      </div>

      {!isInitialLoaded && <Spin />}

      {isInitialLoaded && (
        <div className={styles.wrap}>
          <div className={styles.tab}>
            <h1>{projectsCount}</h1>
            <div className={styles.box}>
              <div className={styles.project}>
                <h2>{spaceAnalyticsInfo?.foldersCount}</h2>
                <div>Total projects</div>
              </div>
              <div className={styles.img}>
                <img src={AffairsBriefcaseImage} alt="Total projects" />
              </div>
            </div>
          </div>
          <div className={styles.tab}>
            <h1>{clientsCount} ({trackersCount})</h1>

            <div className={styles.box}>
              <div className={styles.project}>
                <h2>{spaceAnalyticsInfo?.trackersCount}</h2>
                <div>Total clients</div>
              </div>
              <div className={styles.img}>
                <img src={ClientsImage} alt="Total projects" />
              </div>
            </div>
          </div>
          <div className={styles.tab}>
            <h1>Average completion rate</h1>

            <div className={styles.box}>
              <div className={styles.project}>
                <h2>{spaceAnalyticsInfo?.trackersAvgCompletedPercent}% </h2>
                <div>Complete</div>
              </div>
              <div className={styles.img}>
                <img src={AnalyticsImage} alt="Total projects" />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={styles.wrapper}>
        <Row gutter={[30, 30]}>
          <Col xs={24} md={12}>
            <Row gutter={[0, 30]} className='d-flex'>
              {!isNil(spaceAnalyticsInfo) && (
                <Col xs={24}>
                  <ProjectsCompletenessChart
                    spaceAnalyticsInfo={spaceAnalyticsInfo}
                  />
                </Col>
              )}
              {!isNil(spaceAnalyticsInfo) && (
                <Col xs={24}>
                  <TrackersCompletenessChart
                    spaceAnalyticsInfo={spaceAnalyticsInfo}
                  />
                </Col>
              )}
            </Row>
          </Col>

          <Col xs={24} md={12} className="d-flex">
            <LastActivitySection />
          </Col>

          <ProjectsChart />
        </Row>
      </div>
    </>
  );
}
