import { IndustriesEnum } from 'api';

const getCategoryColor = (industry?: IndustriesEnum) => {
  switch (industry) {
    case IndustriesEnum.Education:
      return 'color-Education';
    case IndustriesEnum.Legal:
      return 'color-Legal';
    case IndustriesEnum.ProfessionalServices:
      return 'color-ProfessionalServices';
    case IndustriesEnum.GovernmentAndPublicSector:
      return 'color-GovernmentAndPublicSector';
    case IndustriesEnum.FinanceAndBanking:
      return 'color-FinanceAndBanking';
    case IndustriesEnum.Healthcare:
      return 'color-Healthcare';
    case IndustriesEnum.RealEstate:
      return 'color-RealEstate';
    case IndustriesEnum.ECommerce:
      return 'color-ECommerce';
    case IndustriesEnum.Manufacturing:
      return 'color-Manufacturing';
    case IndustriesEnum.HumanResources:
      return 'color-HumanResources';
    case IndustriesEnum.TransportationAndLogistics:
      return 'color-TransportationAndLogistics';
    case IndustriesEnum.Insurance:
      return 'color-Insurance';
    case IndustriesEnum.InformationTechnology:
      return 'color-InformationTechnology';
    case IndustriesEnum.Aviation:
      return 'color-Aviation';
    case IndustriesEnum.Staffing:
      return 'color-Staffing';
    case IndustriesEnum.Accounting:
      return 'color-Accounting';
    case IndustriesEnum.Retail:
      return 'color-Retail';
    case IndustriesEnum.HospitalityAndTourism:
      return 'color-HospitalityAndTourism';
    case IndustriesEnum.MediaAndEntertainment:
      return 'color-MediaAndEntertainment';
    case IndustriesEnum.NonProfitAndCharity:
      return 'color-NonProfitAndCharity';
    case IndustriesEnum.EnergyAndUtilities:
      return 'color-EnergyAndUtilities';
    case IndustriesEnum.Consulting:
      return 'color-Consulting';
    case IndustriesEnum.Sales:
      return 'color-Sales';
    case IndustriesEnum.MarketingAndAdvertising:
      return 'color-MarketingAndAdvertising';
    case IndustriesEnum.FoodAndBeverage:
      return 'color-FoodAndBeverage';
    case IndustriesEnum.Agriculture:
      return 'color-Agriculture';
    case IndustriesEnum.Religion:
      return 'color-Religion';
    case IndustriesEnum.Construction:
      return 'color-Construction';
  }
};

const getCategoriesLabel = (industry?: IndustriesEnum) => {
  switch (industry) {
    case IndustriesEnum.Education:
      return 'Education';
    case IndustriesEnum.Legal:
      return 'Legal';
    case IndustriesEnum.ProfessionalServices:
      return 'Professional Services';
    case IndustriesEnum.GovernmentAndPublicSector:
      return 'Government and Public Sector';
    case IndustriesEnum.FinanceAndBanking:
      return 'Finance and Banking';
    case IndustriesEnum.Healthcare:
      return 'Healthcare';
    case IndustriesEnum.RealEstate:
      return 'Real Estate';
    case IndustriesEnum.ECommerce:
      return 'E-Commerce';
    case IndustriesEnum.Manufacturing:
      return 'Manufacturing';
    case IndustriesEnum.HumanResources:
      return 'Human Resources';
    case IndustriesEnum.TransportationAndLogistics:
      return 'Transportation and Logistics';
    case IndustriesEnum.Insurance:
      return 'Insurance';
    case IndustriesEnum.InformationTechnology:
      return 'Information Technology';
    case IndustriesEnum.Aviation:
      return 'Aviation';
    case IndustriesEnum.Staffing:
      return 'Staffing';
    case IndustriesEnum.Accounting:
      return 'Accounting';
    case IndustriesEnum.Retail:
      return 'Retail';
    case IndustriesEnum.HospitalityAndTourism:
      return 'Hospitality and Tourism';
    case IndustriesEnum.MediaAndEntertainment:
      return 'Media and Entertainment';
    case IndustriesEnum.NonProfitAndCharity:
      return 'Non profit and Charity';
    case IndustriesEnum.EnergyAndUtilities:
      return 'Energy and Utilities';
    case IndustriesEnum.Consulting:
      return 'Consulting';
    case IndustriesEnum.Sales:
      return 'Sales';
    case IndustriesEnum.MarketingAndAdvertising:
      return 'Marketing and Advertising';
    case IndustriesEnum.FoodAndBeverage:
      return 'Food and Beverage';
    case IndustriesEnum.Agriculture:
      return 'Agriculture';
    case IndustriesEnum.Religion:
      return 'Religion';
    case IndustriesEnum.Construction:
      return 'Construction';
  }
};

const getCategoriesOptions = () => [
  {
    value: IndustriesEnum.Education,
    label: getCategoriesLabel(IndustriesEnum.Education),
  },
  {
    value: IndustriesEnum.Legal,
    label: getCategoriesLabel(IndustriesEnum.Legal),
  },
  {
    value: IndustriesEnum.ProfessionalServices,
    label: getCategoriesLabel(IndustriesEnum.ProfessionalServices),
  },
  {
    value: IndustriesEnum.GovernmentAndPublicSector,
    label: getCategoriesLabel(IndustriesEnum.GovernmentAndPublicSector),
  },
  {
    value: IndustriesEnum.FinanceAndBanking,
    label: getCategoriesLabel(IndustriesEnum.FinanceAndBanking),
  },
  {
    value: IndustriesEnum.Healthcare,
    label: getCategoriesLabel(IndustriesEnum.Healthcare),
  },
  {
    value: IndustriesEnum.RealEstate,
    label: getCategoriesLabel(IndustriesEnum.RealEstate),
  },
  {
    value: IndustriesEnum.ECommerce,
    label: getCategoriesLabel(IndustriesEnum.ECommerce),
  },
  {
    value: IndustriesEnum.Manufacturing,
    label: getCategoriesLabel(IndustriesEnum.Manufacturing),
  },
  {
    value: IndustriesEnum.HumanResources,
    label: getCategoriesLabel(IndustriesEnum.HumanResources),
  },
  {
    value: IndustriesEnum.TransportationAndLogistics,
    label: getCategoriesLabel(
      IndustriesEnum.TransportationAndLogistics,
    ),
  },
  {
    value: IndustriesEnum.Insurance,
    label: getCategoriesLabel(IndustriesEnum.Insurance),
  },
  {
    value: IndustriesEnum.InformationTechnology,
    label: getCategoriesLabel(IndustriesEnum.InformationTechnology),
  },
  {
    value: IndustriesEnum.Aviation,
    label: getCategoriesLabel(IndustriesEnum.Aviation),
  },
  {
    value: IndustriesEnum.Staffing,
    label: getCategoriesLabel(IndustriesEnum.Staffing),
  },
  {
    value: IndustriesEnum.Accounting,
    label: getCategoriesLabel(IndustriesEnum.Accounting),
  },
  {
    value: IndustriesEnum.Retail,
    label: getCategoriesLabel(IndustriesEnum.Retail),
  },
  {
    value: IndustriesEnum.HospitalityAndTourism,
    label: getCategoriesLabel(IndustriesEnum.HospitalityAndTourism),
  },
  {
    value: IndustriesEnum.MediaAndEntertainment,
    label: getCategoriesLabel(IndustriesEnum.MediaAndEntertainment),
  },
  {
    value: IndustriesEnum.NonProfitAndCharity,
    label: getCategoriesLabel(IndustriesEnum.NonProfitAndCharity),
  },
  {
    value: IndustriesEnum.EnergyAndUtilities,
    label: getCategoriesLabel(IndustriesEnum.EnergyAndUtilities),
  },
  {
    value: IndustriesEnum.Consulting,
    label: getCategoriesLabel(IndustriesEnum.Consulting),
  },
  {
    value: IndustriesEnum.Sales,
    label: getCategoriesLabel(IndustriesEnum.Sales),
  },
  {
    value: IndustriesEnum.MarketingAndAdvertising,
    label: getCategoriesLabel(IndustriesEnum.MarketingAndAdvertising),
  },
  {
    value: IndustriesEnum.FoodAndBeverage,
    label: getCategoriesLabel(IndustriesEnum.FoodAndBeverage),
  },
  {
    value: IndustriesEnum.Agriculture,
    label: getCategoriesLabel(IndustriesEnum.Agriculture),
  },
  {
    value: IndustriesEnum.Religion,
    label: getCategoriesLabel(IndustriesEnum.Religion),
  },
  {
    value: IndustriesEnum.Construction,
    label: getCategoriesLabel(IndustriesEnum.Construction),
  },
];

export { getCategoryColor, getCategoriesOptions, getCategoriesLabel };
