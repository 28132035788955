import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import apiClient, {
  CompletenessFilterEnum,
  FolderRequestModel,
  FolderSortFieldEnum,
  FolderUpdateRequestModel,
  SortFieldEnum,
  SortOrderEnum,
  UserGroupRequestModel,
  UserGroupUpdateRequestModel,
} from 'api';
import { currentSpaceIdByStoreSelector } from 'redux/selectors/spacesSelectors';
import { ErrorType } from 'utils/toastUtils';

interface ProjectState {}

const initialState: ProjectState = {};

interface GetAdminProjectsRequestModel {
  sortField: FolderSortFieldEnum;
  sortOrder: SortOrderEnum;
  name?: string;
  pageNumber: number;
  pageSize: number;
}

export const exportTrackersExcel = createAsyncThunk(
  'project/exportTrackersExcel',
  async (timezone?: number) => {
    return apiClient.exportTrackersExcel(
      currentSpaceIdByStoreSelector(),
      timezone,
    );
  },
);

export const getProjects = createAsyncThunk('project/getProjects', async () => {
  return apiClient.getFoldersByUser(currentSpaceIdByStoreSelector());
});

export const getProjectsWithoutTemplates = createAsyncThunk(
  'project/getProjectsWithoutTemplates',
  async () => {
    return apiClient.getFoldersByUserWithoutTemplates(
      currentSpaceIdByStoreSelector(),
    );
  },
);

export const checkProjectsRestriction = createAsyncThunk(
  'project/checkProjectsRestriction',
  async () => {
    return apiClient.checkFoldersRestriction(currentSpaceIdByStoreSelector());
  },
);

export interface GetProjectsPagedModel {
  completenessFilter: CompletenessFilterEnum;
  sortField: SortFieldEnum;
  sortOrder: SortOrderEnum;
  pageNumber: number;
  name?: string;
  loadAll?: boolean;
  spaceId?: string;
  pageSize: number;
}

export const getProjectsPaged = createAsyncThunk(
  'project/getProjectsPaged',
  async ({
    completenessFilter,
    sortField,
    sortOrder,
    pageNumber,
    name,
    loadAll,
    pageSize,
  }: GetProjectsPagedModel) => {
    return apiClient.getFoldersByUserPaged(
      completenessFilter,
      sortField,
      sortOrder,
      name,
      loadAll ? undefined : pageSize,
      pageNumber,
      currentSpaceIdByStoreSelector(),
    );
  },
);

export interface UserGroupListBySpaceIdOptions {
  projectId: string;
  pageSize: number;
  pageNumber: number;
}

export const getUserGroupListForProject = createAsyncThunk(
  'project/getUserGroupListForProject',
  async ({
    pageNumber,
    projectId,
    pageSize,
  }: UserGroupListBySpaceIdOptions) => {
    return apiClient.getUserGroupListBySpaceIdAndVisibilityByFolderId(
      currentSpaceIdByStoreSelector(),
      projectId,
      pageSize,
      pageNumber,
    );
  },
);

export interface UserListFromUserGroupByGroupIdOptions {
  userGroupId: string;
  projectId: string;
}

export const getUserListFromUserGroupByGroupId = createAsyncThunk(
  'project/getUserGroupListForProject',
  async ({ userGroupId, projectId }: UserListFromUserGroupByGroupIdOptions) => {
    return apiClient.getUserListFromUserGroupByGroupIdAndFolderId(
      userGroupId,
      projectId,
    );
  },
);

interface GetUserGroupListBySpaceOption {
  name?: string;
  pageSize: number;
  pageNumber: number;
}

export const getUserGroupListBySpace = createAsyncThunk(
  'project/getUserGroupListBySpace',
  async ({ name, pageSize, pageNumber }: GetUserGroupListBySpaceOption) => {
    return apiClient.getUserGroupListBySpaceId(
      currentSpaceIdByStoreSelector(),
      name,
      pageSize,
      pageNumber,
    );
  },
);

export const updateUserGroup = createAsyncThunk(
  'project/updateUserGroup',
  async (data: UserGroupUpdateRequestModel) => {
    return await apiClient.updateUserGroup({
      ...data,
    });
  },
);

export const createUserGroup = createAsyncThunk(
  'project/createUserGroup',
  async (data: UserGroupRequestModel) => {
    return await apiClient.createUserGroup({
      ...data,
      spaceId: currentSpaceIdByStoreSelector(),
    });
  },
);

export const getUsersInSpace = createAsyncThunk(
  'project/getUsersInSpace',
  async () => apiClient.getUsersInSpace(currentSpaceIdByStoreSelector()),
);

export const getProjectsBySpace = createAsyncThunk(
  'project/getProjectsBySpace',
  async () => apiClient.getFoldersBySpace(currentSpaceIdByStoreSelector()),
);

interface UserProjectAccessParams {
  projectId: string;
  userId: string;
  userGroupId: string;
}

export const allowUserAccessToProject = createAsyncThunk(
  'project/allowUserAccessToProject',
  async ({ projectId, userId, userGroupId }: UserProjectAccessParams) => {
    return apiClient.changeVisibilityRestrictionByUserGroupIdAndUserId(
      projectId,
      userGroupId,
      userId,
      true,
    );
  },
);

export const removeUserAccessToProject = createAsyncThunk(
  'project/removeUserAccessToProject',
  async ({ userId, projectId, userGroupId }: UserProjectAccessParams) => {
    return apiClient.changeVisibilityRestrictionByUserGroupIdAndUserId(
      projectId,
      userGroupId,
      userId,
      false,
    );
  },
);

interface UserGroupProjectAccessParams {
  userGroupId: string;
  projectId: string;
}

export const allowUserGroupAccessToProject = createAsyncThunk(
  'project/allowUserGroupAccessToProject',
  async ({ userGroupId, projectId }: UserGroupProjectAccessParams) => {
    return apiClient.addFolderToUserGroup(userGroupId, { folderId: projectId });
  },
);

export const removeUserGroupAccessToProject = createAsyncThunk(
  'project/removeUserGroupAccessToProject',
  async ({ userGroupId, projectId }: UserGroupProjectAccessParams) => {
    return apiClient.deleteFolderFromUserGroup(userGroupId, projectId);
  },
);

export const createProject = createAsyncThunk<
  string,
  FolderRequestModel,
  { rejectValue: ErrorType }
>(
  'project/createProject',
  async (data: FolderRequestModel, { rejectWithValue }) => {
    try {
      return await apiClient.createFolder({
        ...data,
        spaceId: currentSpaceIdByStoreSelector(),
      });
    } catch (e: any) {
      return rejectWithValue(e);
    }
  },
);

export const updateProject = createAsyncThunk(
  'project/updateProject',
  async (data: FolderUpdateRequestModel) => {
    return apiClient.updateFolder(data);
  },
);

export const getUserGroupById = createAsyncThunk(
  'project/getUserGroupById',
  async (userGroupId: string) => {
    return apiClient.getUserGroupById(userGroupId);
  },
);

export const deleteUserGroup = createAsyncThunk(
  'project/deleteUserGroup',
  async (userGroupId: string) => {
    return apiClient.deleteUserGroup(userGroupId);
  },
);

interface GetArchiveTrackersOptions {
  pageNumber: number;
  pageSize: number;
  completenessFilter: CompletenessFilterEnum;
  sortField: SortFieldEnum;
  sortOrder: SortOrderEnum;
  name?: string;
}

export const getArchivedTrackers = createAsyncThunk(
  'project/getArchivedTrackers',
  async (data: GetArchiveTrackersOptions) => {
    return apiClient.getArchivedTrackerList(
      currentSpaceIdByStoreSelector(),
      data.completenessFilter,
      data.sortField,
      data.sortOrder,
      data.name,
      data.pageSize,
      data.pageNumber,
    );
  },
);

export const getDeletedTrackers = createAsyncThunk(
  'project/getDeletedTrackers',
  async (data: GetArchiveTrackersOptions) => {
    return apiClient.getDeletedTrackers(
      currentSpaceIdByStoreSelector(),
      data.completenessFilter,
      data.sortField,
      data.sortOrder,
      data.name,
      data.pageSize,
      data.pageNumber,
    );
  },
);

export const restoreDeletedTracker = createAsyncThunk(
  'project/restoreDeletedTracker',
  async (trackerId: string) => {
    return apiClient.restoreFromDelete(trackerId);
  },
);

export const getAdminProjects = createAsyncThunk(
  'project/getAdminProjects',
  async ({
    sortField,
    sortOrder,
    name,
    pageNumber,
    pageSize,
  }: GetAdminProjectsRequestModel) => {
    return apiClient.getFolderList(
      sortField,
      sortOrder,
      name,
      pageSize,
      pageNumber,
    );
  },
);

export const archiveTracker = createAsyncThunk(
  'project/archiveTracker',
  async (trackerId: string) => {
    return apiClient.archiveProgressTracker(trackerId);
  },
);

export const unArchiveTracker = createAsyncThunk(
  'project/unArchiveTracker',
  async (trackerId: string) => {
    return apiClient.unarchiveProgressTracker(trackerId);
  },
);

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {},
});

const { reducer } = projectSlice;

export default reducer;
