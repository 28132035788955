import { useState } from 'react';
import { Button, Form } from 'antd';
import cx from 'classnames';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';

import AlertMessage from 'components/AlertMessage';
import Controller from 'components/form/Controller';
import ButtonWithTimer from 'components/ButtonWithTimer';
import MaskedInput from 'components/form/MaskedInput';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { twoFACodeMask } from 'constants/ValidationConstants';
import { twoFACodeValidationSchema } from 'features/account/AccountSettingsPage/SecurityTab/EnableTwoFAModal';
import {
  getUserInfo,
  loginWithTwoFA,
} from 'redux/reducers/authenticationSlice';
import { getSpaceRole } from 'redux/reducers/roleSlice';
import { getAvailableSpace } from 'redux/reducers/spaceSlice';
import { AppDispatch } from 'redux/store';
import { TwoFACheckResponseModel, TwoFATypeEnum } from 'api';

import commonStyles from 'features/auth/auth.module.less';
import styles from '../SignInPage.module.less';

type TwoFALoginForm = {
  code: string;
};

type Props = {
  twoFAInfo: TwoFACheckResponseModel;
};

export default function TwoFALoginSection(props: Props) {
  const { twoFAInfo } = props;
  const twoFACodeId = twoFAInfo.codeId;

  const [isLoading, setIsLoading] = useState(false);
  const [codeId, setCodeId] = useState(twoFACodeId);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const { handleSubmit, control, errors, formState } = useForm<TwoFALoginForm>({
    resolver: yupResolver(twoFACodeValidationSchema),
    mode: 'all',
    defaultValues: {
      code: '',
    },
  });
  const { isValid } = formState;

  const getInitialData = async () => {
    await dispatch(getAvailableSpace());
    await dispatch(getUserInfo());
    await dispatch(getSpaceRole());
  };

  const onSubmit = async (values: TwoFALoginForm) => {
    setIsLoading(true);
    const { code } = values;
    const codeWithoutDashes = code.replace(/-/g, '');

    const result = await dispatch(
      loginWithTwoFA({
        codeId,
        code: codeWithoutDashes,
        twoFaType: twoFAInfo.twoFaType,
        userId: twoFAInfo.userId,
      }),
    );

    if (loginWithTwoFA.fulfilled.match(result)) {
      await getInitialData();
      setIsLoading(false);
      navigate('/');
    } else {
      setIsLoading(false);
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
      <Controller
        name="code"
        label="Authentication code"
        size="large"
        control={control}
        render={(props) => (
          <MaskedInput
            {...props}
            mask={twoFACodeMask}
            placeholder="Enter code"
          />
        )}
        error={errors.code}
        containerClassName={cx(styles.input, 'dark-input-group')}
      />

      <div className={styles.notice}>
        Enter the 6-digit authentication code that you received through either
        your registered email address or mobile app authenticator.
      </div>

      {twoFAInfo.twoFaType === TwoFATypeEnum.Email && (
        <div className={styles.timerBox}>
          <ButtonWithTimer codeId={codeId} setCodeId={setCodeId} />
        </div>
      )}

      <div className={commonStyles.authBtnBox}>
        <div className={commonStyles.authBtn}>
          <Button
            htmlType="submit"
            block
            type="primary"
            disabled={!isValid}
            loading={isLoading}
          >
            Log in
          </Button>
        </div>
      </div>
    </Form>
  );
}
