import React, { useState } from 'react';
import { Layout } from 'antd';

import OnboardingWrapper from 'components/wrappers/OnboardingWrapper';
import AppHeader from '../AppHeader';
import Sidebar from './Sidebar';

const { Content } = Layout;

type Props = {
  children: React.ReactNode;
};

export default function AdminLayout(props: Props) {
  const { children } = props;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleSidebarChange = (isOpen: boolean) => {
    setIsSidebarOpen(isOpen);
  };

  return (
    <Layout className="layout">
      <Sidebar
        isSidebarOpen={isSidebarOpen}
        handleSidebarChange={handleSidebarChange}
      />
      <Layout>
        <AppHeader
          handleSidebarChange={handleSidebarChange}
          isSidebarOpen={isSidebarOpen}
        />
        <OnboardingWrapper>
          <Content>{children}</Content>
        </OnboardingWrapper>
      </Layout>
    </Layout>
  );
}
