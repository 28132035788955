import React from 'react';
import { Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppDispatch } from 'redux/store';
import { useWillUnmount } from 'rooks';

import StagesViewSection, {
  StagesMode,
} from 'components/ProgressTracker/StagesViewSection';
import DetailsSection from 'components/DetailsSection/DetailsSection';
import { PTDetailsMode, useFetchPTDetails } from 'hooks/useFetchPTDetails';
import { setCustomLogoUrl } from 'redux/reducers/progressTrackerSlice';

type ParamsProps = {
  progressTrackerId: string;
};

export default function SharedProgressTrackerDetailsPage() {
  const { progressTrackerId } = useParams<ParamsProps>();
  const [details, isLoading, loadPTDetails] = useFetchPTDetails(
    progressTrackerId || '',
    PTDetailsMode.Public,
  );
  const dispatch: AppDispatch = useDispatch();

  useWillUnmount(() => {
    dispatch(setCustomLogoUrl(undefined));
  });

  if (isLoading) return <Spin />;

  if (!details) return null;

  return (
    <div className="page-wrapper scroll-box ">
      <div className="page-box page-holder">
        <DetailsSection details={details} isActions={false} />

        <StagesViewSection
          details={details}
          onActionDone={loadPTDetails}
          mode={StagesMode.VIEW_SHARED}
        />
      </div>
    </div>
  );
}
