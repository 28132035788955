import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { isString } from 'lodash';
import { Spin } from 'antd';

import ProgressTrackerEdit, {
  ViewMode,
} from 'components/ProgressTracker/ProgressTrackerEdit';
import { RestrictionModalTypeEnum } from 'features/account/AccountSettingsPage/RestrictionModal/RestrictionModal';
import { withTrackerRestrictionsControl } from 'hocs/withTrackerRestrictionsControl';
import { useFetchTemplateDetails } from 'hooks/useFetchTemplateDetails';

type ParamsProps = {
  projectId: string;
  templateId: string;
};

function ProgressTrackerCreatePage() {
  const { projectId } = useParams<ParamsProps>();
  const location = useLocation();
  const { templateId } = queryString.parse(location.search);
  const progressTrackerId = isString(templateId) ? templateId : '';
  const [details, isLoading] = useFetchTemplateDetails(progressTrackerId);

  if (isLoading) return <Spin />;

  return (
    <ProgressTrackerEdit
      projectId={projectId || ''}
      mode={ViewMode.CreateProgressTracker}
      initialValues={details}
    />
  );
}

export default withTrackerRestrictionsControl({
  restrictionType: RestrictionModalTypeEnum.CreateTracker,
})(ProgressTrackerCreatePage);
