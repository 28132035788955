import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Input, Popover, Spin } from 'antd';
import cx from 'classnames';
import { isString, startCase, uniqueId } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';

import {
  CancellationReasonResponseModel,
  SortOrderEnum,
  SubscriptionTypeEnum,
  UserCancellationReasonResponseModel,
} from 'api';
import AlertMessage from 'components/AlertMessage';
import Breadcrumb from 'components/Breadcrumb';
import SortingFilter, {
  SortingFilterValueType,
  SortingMode,
} from 'components/filters/SortingFilter';
import NoInitialData from 'components/NoInitialData';
import { MIN_SEARCH_LENGTH } from 'components/search/SearchLayout';
import { PageDefaultSize } from 'constants/ApiConstant';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import CheckboxDropdown, { ItemType } from 'components/CheckboxDropdown';
import RangePicker, { DateRangeType } from 'components/RangePicker';
import { AppDispatch } from 'redux/store';
import {
  getCancellationReasons as getCancellationReasonsApi,
  getSubscriptionCancellations,
  GetSubscriptionCancellationsRequestModel,
} from 'redux/reducers/accountSlice';
import {
  getDateForUserTimezone,
  NEXT_BILLING_DATE_DATE_FORMAT,
} from 'utils/dateUtils';
import { EnumHelpers } from 'utils/enumUtils';
import { DispatchedRequestType } from 'utils/searchUtils';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

import classes from 'features/analytics/AnalyticsSection/AnalyticsSection.module.less';

export enum CancellationHistorySortFieldEnum {
  CancelDate = 1,
}

enum CancellationFiltersTypes {
  Search = 'search',
  Subscriptions = 'subscriptions',
  EndDateRange = 'endDateRange',
  CancelDateRange = 'cancelDateRange',
  Reasons = 'reasons',
  Sorting = 'sorting',
}

type FilterValuesType =
  | SubscriptionTypeEnum[]
  | number[]
  | SortingFilterValueType
  | DateRangeType
  | string;

interface CancellationFilters {
  [CancellationFiltersTypes.Search]: string;
  [CancellationFiltersTypes.Subscriptions]: SubscriptionTypeEnum[];
  [CancellationFiltersTypes.EndDateRange]?: DateRangeType;
  [CancellationFiltersTypes.CancelDateRange]?: DateRangeType;
  [CancellationFiltersTypes.Reasons]: number[];
  [CancellationFiltersTypes.Sorting]: SortingFilterValueType;
}

export default function CancellationHistoryPage() {
  const isLightTheme = useSelector(isLightThemeSelector);

  const [cancellations, setCancellations] = useState<
    UserCancellationReasonResponseModel[]
  >([]);
  const [cancellationReasons, setCancellationReasons] = useState<
    CancellationReasonResponseModel[]
  >([]);
  const [isReasonsLoading, setIsReasonsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
  const [isFilterUsed, setIsFilterUsed] = useState(false);
  const [isEndRangePickerOpen, setIsEndRangePickerOpen] = useState(false);
  const [isCancelRangePickerOpen, setIsCancelRangePickerOpen] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const infiniteScrollParentRef = useRef(null);
  const latestRequestRef = useRef<DispatchedRequestType>();

  const { search } = queryString.parse(location.search);

  const initialFilters: CancellationFilters = {
    [CancellationFiltersTypes.Search]: isString(search) ? search : '',
    [CancellationFiltersTypes.Reasons]: [],
    [CancellationFiltersTypes.Subscriptions]: [],
    [CancellationFiltersTypes.Sorting]: {
      sortField: CancellationHistorySortFieldEnum.CancelDate,
      sortOrder: SortOrderEnum.Desc,
    },
  };
  const [filters, setFilters] = useState<CancellationFilters>(initialFilters);

  const isSearchMode =
    filters[CancellationFiltersTypes.Search].length >= MIN_SEARCH_LENGTH;

  const emptySearchResults =
    (isFilterUsed || isSearchMode) && isInitialDataLoaded && !totalCount;

  const notFoundData =
    isInitialDataLoaded && !totalCount && !isFilterUsed && !emptySearchResults;

  useEffect(() => {
    getCancellationReasons();
  }, []);

  useEffect(() => {
    initInfinityScroll();
  }, [filters]);

  useEffect(() => {
    const search = queryString.stringify({
      [CancellationFiltersTypes.Search]:
        filters[CancellationFiltersTypes.Search],
    });

    navigate({ search }, { replace: true });
  }, [filters[CancellationFiltersTypes.Search]]);

  const getCancellationReasons = async () => {
    setIsReasonsLoading(true);
    const result = await dispatch(getCancellationReasonsApi());

    if (getCancellationReasonsApi.fulfilled.match(result)) {
      setCancellationReasons(result.payload);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }

    setIsReasonsLoading(false);
  };

  const onSetData = useCallback(
    (result) => {
      const loadedData = result.payload.details || [];
      const totalCount = result.payload.totalCount || 0;
      const newData = [...cancellations, ...loadedData];
      const hasMoreRows =
        !!newData.length &&
        newData.length < totalCount &&
        loadedData.length > 0;

      setHasMore(hasMoreRows);
      setCancellations(newData);
      setTotalCount(totalCount);
    },
    [cancellations],
  );

  const initInfinityScroll = () => {
    setIsVisible(false);
    setCancellations([]);
    setIsInitialDataLoaded(false);
    setHasMore(true);
    setTotalCount(0);

    const timer = setTimeout(() => {
      // to destroy infinite scroll
      setIsVisible(true);
    }, 0);

    return () => clearTimeout(timer);
  };

  const handleFilter = (
    filterName: CancellationFiltersTypes,
    value?: FilterValuesType,
  ) => {
    setFilters({ ...filters, [filterName]: value });

    if (filterName !== CancellationFiltersTypes.Search) {
      setIsFilterUsed(true);
    }
  };

  const onLoadMore = useCallback(
    async (pageNumber: number) => {
      latestRequestRef.current?.abort?.();

      const requestBody: GetSubscriptionCancellationsRequestModel = {
        search: filters[CancellationFiltersTypes.Search],
        cancelFrom: filters[CancellationFiltersTypes.CancelDateRange]?.from,
        cancelTo: filters[CancellationFiltersTypes.CancelDateRange]?.to,
        subscriptionEndFrom:
          filters[CancellationFiltersTypes.EndDateRange]?.from,
        subscriptionEndTo: filters[CancellationFiltersTypes.EndDateRange]?.to,
        sortOrder: filters[CancellationFiltersTypes.Sorting].sortOrder,
        reasons: filters[CancellationFiltersTypes.Reasons],
        subscriptions: filters[CancellationFiltersTypes.Subscriptions],
        pageSize: PageDefaultSize,
        pageNumber,
      };
      const request = dispatch(getSubscriptionCancellations(requestBody));

      latestRequestRef.current = request;
      const result = await request;

      if (getSubscriptionCancellations.fulfilled.match(result)) {
        onSetData(result);
      } else {
        if (result.meta.aborted) return;

        setHasMore(false);
        AlertMessage.error(result.error.message || ApiErrorMessage);
      }

      setIsInitialDataLoaded(true);
    },
    [dispatch, getSubscriptionCancellations, onSetData],
  );

  const handleCloseEndRangePicker = () => setIsEndRangePickerOpen(false);

  const handleCloseCancelRangePicker = () => setIsCancelRangePickerOpen(false);

  return (
    <div className="page-wrapper scroll-box ">
      <div className="page-box page-holder">
        <Breadcrumb
          routes={[
            {
              title: <i className="icon icon-home" />,
              link: '/',
            },
            { title: 'Cancelation History' },
          ]}
        />
        <div
          className={cx('banner', {
            inverse: !isLightTheme,
          })}
        >
          <div className="banner__left">
            <div className="banner__count">{totalCount}</div>
            <div>
              <div className="banner__name">Cancelation History</div>
              <div className="banner__item">
                View subscription cancelation requests submitted by users
              </div>
            </div>
          </div>
        </div>

        <div className={classes.wrapper}>
          <div className={cx(classes.box, classes.border)}>
            <div className={classes.search}>
              <Input
                onChange={(e) =>
                  handleFilter(CancellationFiltersTypes.Search, e.target.value)
                }
                value={filters[CancellationFiltersTypes.Search]}
                placeholder="Search by space name"
                prefix={<i className="icon icon-search" />}
              />
            </div>
          </div>
          <div className={classes.box}>
            <div className={classes.title}>Cancelation history</div>
            <div className={classes.filter}>
              <SortingFilter
                onChange={(value) =>
                  handleFilter(CancellationFiltersTypes.Sorting, value)
                }
                value={filters[CancellationFiltersTypes.Sorting]}
                mode={SortingMode.CancellationHistory}
              />
            </div>
          </div>

          <div className={classes.tableBox} ref={infiniteScrollParentRef}>
            <table
              className={cx(classes.table, {
                [classes.inverse]: !isLightTheme,
              })}
            >
              <thead>
                <tr>
                  <th className={classes.tableHead}>Space Name</th>
                  <th className={cx(classes.tableHead, classes.centeredCell)}>
                    <Popover
                      destroyTooltipOnHide={{ keepParent: true }}
                      showArrow={false}
                      onOpenChange={(isOpen) =>
                        setIsCancelRangePickerOpen(isOpen)
                      }
                      open={isCancelRangePickerOpen}
                      placement="bottom"
                      trigger="click"
                      content={
                        <RangePicker
                          onApplyDateRange={(dateRange: DateRangeType) =>
                            handleFilter(
                              CancellationFiltersTypes.CancelDateRange,
                              dateRange,
                            )
                          }
                          defaultFromDate={
                            filters[CancellationFiltersTypes.CancelDateRange]
                              ?.from
                          }
                          defaultToDate={
                            filters[CancellationFiltersTypes.CancelDateRange]
                              ?.to
                          }
                          onCloseRangePicker={handleCloseCancelRangePicker}
                        />
                      }
                      overlayInnerStyle={{ width: 569 }}
                    >
                      <div
                        className={cx(classes.dateItem, classes.centeredCell, {
                          [classes.bold]:
                            filters[CancellationFiltersTypes.CancelDateRange]
                              ?.from ||
                            filters[CancellationFiltersTypes.CancelDateRange]
                              ?.to,
                        })}
                      >
                        Cancel Date{' '}
                        <i className="icon icon-caret-down-solid"></i>
                      </div>
                    </Popover>
                  </th>

                  <th className={cx(classes.tableHead, classes.centeredCell)}>
                    <Popover
                      destroyTooltipOnHide={{ keepParent: true }}
                      showArrow={false}
                      onOpenChange={(isOpen) => setIsEndRangePickerOpen(isOpen)}
                      open={isEndRangePickerOpen}
                      placement="bottom"
                      trigger="click"
                      content={
                        <RangePicker
                          isSubscriptionEnd
                          onApplyDateRange={(dateRange: DateRangeType) =>
                            handleFilter(
                              CancellationFiltersTypes.EndDateRange,
                              dateRange,
                            )
                          }
                          defaultFromDate={
                            filters[CancellationFiltersTypes.EndDateRange]?.from
                          }
                          defaultToDate={
                            filters[CancellationFiltersTypes.EndDateRange]?.to
                          }
                          onCloseRangePicker={handleCloseEndRangePicker}
                        />
                      }
                      overlayInnerStyle={{ width: 569 }}
                    >
                      <div
                        className={cx(classes.dateItem, classes.centeredCell, {
                          [classes.bold]:
                            filters[CancellationFiltersTypes.EndDateRange]
                              ?.from ||
                            filters[CancellationFiltersTypes.EndDateRange]?.to,
                        })}
                      >
                        Subscription End{' '}
                        <i className="icon icon-caret-down-solid" />
                      </div>
                    </Popover>
                  </th>

                  <th className={cx(classes.tableHead, classes.centeredCell)}>
                    <div className={classes.headerDrop}>
                      <CheckboxDropdown
                        values={filters[CancellationFiltersTypes.Subscriptions]}
                        onChange={(e) =>
                          handleFilter(
                            CancellationFiltersTypes.Subscriptions,
                            e as SubscriptionTypeEnum[],
                          )
                        }
                        items={EnumHelpers.getEnumValues(
                          SubscriptionTypeEnum,
                        ).reduce((acc: ItemType[], prevValue) => {
                          if (prevValue !== SubscriptionTypeEnum.Basic) {
                            return [
                              ...acc,
                              {
                                value: prevValue,
                                key: prevValue,
                                title: startCase(
                                  SubscriptionTypeEnum[prevValue as number],
                                ),
                              },
                            ];
                          } else return acc;
                        }, [])}
                      >
                        Plan <i className="icon icon-caret-down-solid" />
                      </CheckboxDropdown>
                    </div>
                  </th>

                  <th className={classes.tableHead}>
                    <div className={classes.headerDrop}>
                      <CheckboxDropdown
                        values={filters[CancellationFiltersTypes.Reasons]}
                        onChange={(e) =>
                          handleFilter(
                            CancellationFiltersTypes.Reasons,
                            e as number[],
                          )
                        }
                        items={cancellationReasons.map((reason) => {
                          const id = reason.id || uniqueId();
                          return {
                            value: id,
                            key: id,
                            title: reason.reason,
                          };
                        })}
                      >
                        Reason <i className="icon icon-caret-down-solid" />
                      </CheckboxDropdown>
                    </div>
                  </th>
                </tr>
              </thead>

              {emptySearchResults && (
                <tr>
                  <td colSpan={6}>
                    <NoInitialData text="There is no information for your request." />
                  </td>
                </tr>
              )}

              {notFoundData && (
                <tr>
                  <td colSpan={6}>
                    <NoInitialData
                      text={`You currently do not have any cancelations.`}
                    />
                  </td>
                </tr>
              )}

              {isVisible && (
                <InfiniteScroll
                  loadMore={onLoadMore}
                  maxLength={totalCount}
                  initialLoad={!isInitialDataLoaded}
                  pageStart={-1}
                  hasMore={hasMore && !isReasonsLoading}
                  loader={
                    <tr key={uniqueId()}>
                      <td>
                        <Spin />
                      </td>
                    </tr>
                  }
                  useWindow={false}
                  getScrollParent={() => infiniteScrollParentRef.current}
                  threshold={100}
                  element="tbody"
                >
                  {cancellations.map((cancellation) => (
                    <tr key={uniqueId()}>
                      <td className={classes.tableCell}>
                        {cancellation.spaceName}
                      </td>
                      <td
                        className={cx(classes.tableCell, classes.centeredCell)}
                      >
                        {getDateForUserTimezone(
                          cancellation.requestedDate,
                          NEXT_BILLING_DATE_DATE_FORMAT,
                        )}
                      </td>
                      <td
                        className={cx(classes.tableCell, classes.centeredCell)}
                      >
                        {getDateForUserTimezone(
                          cancellation.actualCancellationDate,
                          NEXT_BILLING_DATE_DATE_FORMAT,
                        )}
                      </td>
                      <td
                        className={cx(
                          classes.tableCell,
                          classes.centeredCell,
                          classes.analytics,
                        )}
                      >
                        {cancellation.subscriptionName}
                      </td>
                      <td className={classes.tableCell}>
                        {cancellation.reason}
                      </td>
                    </tr>
                  ))}
                </InfiniteScroll>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
