import React from 'react';
import { AdminSpaceResponseModel } from 'api';
import { useSelector } from 'react-redux';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import cx from 'classnames';

type Props = {
  space: AdminSpaceResponseModel;
};

export default function SpaceCard(props: Props) {
  const isLightTheme = useSelector(isLightThemeSelector);
  const { space } = props;
  const {
    spaceName,
    progressTrackerTotalCount,
    userTotalCount,
    folderTotalCount,
  } = space;

  return (
    <div
      className={cx('ticket', {
        inverse: !isLightTheme,
      })}
    >
      <div>
        <div className="ticket__name">{spaceName}</div>
        <div className="ticket__item">
          <span>Project number:</span> {folderTotalCount}
        </div>
      </div>

      <div>
        <div className="ticket__item">
          <span>Tracker number:</span> {progressTrackerTotalCount}
        </div>
        <div className="ticket__item">
          <span>User number:</span> {userTotalCount}
        </div>
      </div>
    </div>
  );
}
