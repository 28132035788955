import React, { useState } from 'react';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';

import apiClient, { AttachmentResponseModel } from 'api';
import BaseFileUpload, {
  FileDetails,
  UploadMode,
} from 'components/wrappers/BaseFileUpload';
import {
  generateUploadFileList,
  getFileForLogoDownload,
  getFileUploadMessages,
} from 'utils/fileUtils';
import UploadButton from 'features/account/AccountSettingsPage/ProfileTab/UploadButton';

type Props = {
  uploaderRef: any;
  onChange: (logo?: AttachmentResponseModel) => void;
  onUploading: (isUploading: boolean) => void;
  logo?: AttachmentResponseModel;
  userId?: string;
};

export default function LogoUploader(props: Props) {
  const { logo, userId, uploaderRef, onChange, onUploading } = props;
  const initialFileList = logo ? generateUploadFileList(logo) : [];
  const [fileList, setFileList] = useState(initialFileList);

  const getUploadLinkFunction = () =>
    logo
      ? apiClient.updateUserAttachment.bind(apiClient)
      : apiClient.createUserAttachment.bind(apiClient);

  const getRequestBody = (fileDetails: FileDetails) => ({
    userId,
    attachment: fileDetails,
  });

  const handleChangeUpload = (
    info: UploadChangeParam<UploadFile<AttachmentResponseModel>>,
  ) => {
    const { fileList, file } = info;
    setFileList(fileList);

    if (file.status === 'done' && file.response) {
      const logo = getFileForLogoDownload(fileList);
      onChange(logo[0]);
    }
  };

  return (
    <BaseFileUpload
      ref={uploaderRef}
      defaultFileList={fileList}
      acceptedFileSize={5}
      acceptedFileFormat={['.jpg', '.jpeg', '.png', 'image/jpeg', 'image/png']}
      maxFileCount={1}
      messages={getFileUploadMessages({
        fileTypes: ['PNG', 'JPG', 'JPEG'],
      })}
      uploadLinkFunction={getUploadLinkFunction()}
      getRequestBody={getRequestBody}
      onChange={handleChangeUpload}
      mode={UploadMode.ImageLogoMode}
    >
      {(props) => <UploadButton {...props} onUploading={onUploading} />}
    </BaseFileUpload>
  );
}
