import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { App, Button, Input, Select } from 'antd';
import { isEmpty, isNil } from 'lodash';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useWillUnmount } from 'rooks';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import cx from 'classnames';

import ProjectListModal from './ProjectListModal';
import {
  AdminPreSetupPTTemplateDetailsResponseModel,
  PreSetupPTTemplateDetailsResponseModel,
  PreSetupTemplateEditRequestModel,
  PreSetupTemplateRequestModel,
  ProgressTrackerTemplateEditRequestModel,
  ProgressTrackerTemplateRequestModel,
  ProgressTrackerTemplateResponseModel,
  PTStageTemplateResponseModel,
} from 'api';
import AlertMessage from 'components/AlertMessage';
import Breadcrumb from 'components/Breadcrumb';
import Controller from 'components/form/Controller';
import { StageEdit } from 'components/ProgressTracker/Stage';
import {
  generateMockId,
  isMockId,
} from 'components/ProgressTracker/Stage/utils';
import { StageEditMode } from 'components/ProgressTracker/Stage/StageEdit';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import AddProjectModal from 'features/template/TemplateEdit/AddProjectModal';
import {
  createPreSetupTemplate,
  createTemplate as createTemplateApi,
  setTemplateFormDirty,
  updatePreSetupTemplate,
  updateTemplate as updateTemplateApi,
} from 'redux/reducers/templateSlice';
import { AppDispatch } from 'redux/store';
import { createProject } from 'redux/reducers/projectSlice';
import { alertBeforeUnload } from 'utils/confirmationUtils';
import { getErrorMessages } from 'utils/toastUtils';
import { requiredMessage } from 'constants/ValidationConstants';
import { isAdminSelector } from 'redux/selectors/authenticationSelectors';
import { getCategoriesOptions } from 'utils/templateUtils';
import Switch from 'components/form/Switch';

import styles from './TemplateEdit.module.less';

interface StageFormValue {
  id?: string;
  name: string;
  description: string;
}

export interface TemplateFormValues {
  name?: string;
  stages: StageFormValue[];
  folderId?: string;
  folderName?: string;
  category?: number;
  isVisible?: boolean;
}

type Props = {
  initialValues?: ProgressTrackerTemplateResponseModel;
};

const stageValidation = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(30, 'Stage name should be 30 characters maximum')
    .required(requiredMessage),
  description: Yup.string()
    .trim()
    .required(requiredMessage)
    .max(280, 'Stage description should be 280 characters maximum'),
});

const getValidationSchema = (isAdmin: boolean, isDraft: boolean) => {
  const baseSchema: {
    name: Yup.StringSchema;
    stages?: Yup.ArraySchema<any>;
    category?: Yup.StringSchema;
    isVisible?: Yup.BooleanSchema;
    folderId?: Yup.StringSchema;
    folderName?: Yup.StringSchema;
  } = {
    name: Yup.string()
      .trim()
      .required('Field is required')
      .max(120, 'Template name should be 120 characters maximum'),
  };

  if (!isDraft) {
    baseSchema.stages = Yup.array().of(stageValidation);
  }

  if (isAdmin) {
    return Yup.object().shape({
      ...baseSchema,
      category: Yup.string().required(requiredMessage),
      isVisible: Yup.boolean(),
    });
  }

  return Yup.object().shape(
    {
      ...baseSchema,
      folderId: Yup.string()
        .nullable()
        .when('folderName', {
          is: (folderName: string) => isNil(folderName),
          then: Yup.string().required('Field is required'),
        }),
      folderName: Yup.string()
        .nullable()
        .when('folderId', {
          is: (folderId: string) => isNil(folderId),
          then: Yup.string().required('Field is required'),
        })
        .max(30, 'Project name should be 30 characters maximum'),
    },
    [['folderName', 'folderId']],
  );
};

const initialStage: StageFormValue = {
  name: '',
  description: '',
  id: generateMockId(),
};

const getInitialEmptyValues = (isAdmin: boolean): TemplateFormValues => {
  const initialValues = {
    name: '',
    stages: Array.from({ length: 4 }, () => initialStage),
  };

  if (isAdmin) {
    return { ...initialValues, category: undefined, isVisible: false };
  }

  return {
    ...initialValues,
    folderId: undefined,
    folderName: undefined,
  };
};

export default function TemplateEdit(props: Props) {
  const [isSavingAsDraft, setIsSavingAsDraft] = useState(false);
  const isLightTheme = useSelector(isLightThemeSelector);
  const { initialValues: preInitialValues } = props;
  const [isProjectListModalOpen, setIsProjectListModalOpen] = useState(false);
  const [isAddProjectModalOpen, setIsAddProjectModalOpen] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const isAdmin = useSelector(isAdminSelector);
  const { modal } = App.useApp();
  const isEditMode = !!preInitialValues;

  const getInitialValues = () => {
    const initialValues: TemplateFormValues = {
      name: preInitialValues?.name,
      stages: [],
    };

    if (isAdmin) {
      const preValues =
        preInitialValues as AdminPreSetupPTTemplateDetailsResponseModel;

      return {
        ...initialValues,
        isVisible: preValues?.isVisible,
        category: preValues?.categoryId || undefined,
        stages: (preValues?.stages || []).map((stage) => ({
          name: stage?.name,
          description: stage?.description,
          id: stage?.id,
        })),
      };
    }

    const preStages =
      (preInitialValues?.stages as PTStageTemplateResponseModel[]) || [];

    return {
      ...initialValues,
      folderId: initialValues?.folderId || undefined,
      folderName: undefined,
      stages: preStages.map((stage) => ({
        name: stage.name,
        description: stage.description,
        id: stage.id,
      })),
    };
  };

  const initialEditionValues = getInitialValues();

  const defaultProject = useMemo(
    () =>
      isEditMode
        ? {
            id: preInitialValues?.folderId,
            name: preInitialValues?.folderName,
          }
        : undefined,
    [isEditMode, preInitialValues],
  );

  const { errors, control, trigger, handleSubmit, formState, watch } =
    useForm<TemplateFormValues>({
      resolver: yupResolver(getValidationSchema(isAdmin, isSavingAsDraft)),
      mode: 'all',
      defaultValues: isEditMode
        ? initialEditionValues
        : getInitialEmptyValues(isAdmin),
    });
  const { isSubmitting, isDirty, touched } = formState;
  const isVisibleValues = watch()?.isVisible;
  const isDraftValid = isNil(errors?.name) && isDirty;

  const { fields, remove, append, swap } = useFieldArray<StageFormValue, 'key'>(
    {
      control,
      name: 'stages',
      keyName: 'key',
    },
  );

  const isAddingDisabled = fields.length >= 20;

  useEffect(() => {
    dispatch(setTemplateFormDirty(isDirty));

    return alertBeforeUnload(isDirty);
  }, [isDirty]);

  useWillUnmount(() => {
    dispatch(setTemplateFormDirty(false));
  });

  const handleDeleteStageClick = useCallback(
    (index: number) => {
      modal.confirm({
        content: 'Are you sure you want to delete the stage?',
        icon: <span className="icon icon-info-circle confirm-icon" />,
        className: cx('confirm-modal', {
          'confirm-modal-dark-button': !isLightTheme,
        }),
        width: '496px',
        cancelText: 'Cancel',
        okText: 'Delete',
        onOk: () => remove(index),
      });
    },
    [remove],
  );

  const handleAddStageClick = () => {
    append(initialStage);
  };

  const showConfirmation = (onConfirm: Function) => {
    modal.confirm({
      title: 'Changes won’t be saved. Are you sure?',
      icon: <span className="icon icon-info-circle confirm-icon" />,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      width: '496px',
      onOk() {
        onConfirm();
      },
      onCancel() {},
    });
  };

  const handleBackClick = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => {
    const onRedirect = () => navigate(-1);

    if (isDirty) {
      e.preventDefault();
      return showConfirmation(onRedirect);
    }
  };

  const handleSaveClick = async () => {
    if (isAdmin) return handleSave();

    await trigger(['name', 'stages']);

    if (isEmpty(errors)) setIsProjectListModalOpen(true);
  };

  const handleSaveAsDraftClick = async () => {
    await trigger(['name']);

    if (isDraftValid) {
      setIsSavingAsDraft(true);
      setIsProjectListModalOpen(true);
    }
  };

  const editTemplate = async (values: TemplateFormValues) => {
    const stages = values.stages.map((stage, idx) => {
      const stageId = fields[idx].id;

      return {
        name: stage.name.trim(),
        description: stage.description.trim(),
        sequence: idx + 1,
        id: !isMockId(stageId) ? stageId : undefined,
      };
    });

    const data: ProgressTrackerTemplateEditRequestModel = {
      id: preInitialValues?.id,
      name: values.name,
      folderId: values.folderId,
      stages,
      isDraft: isSavingAsDraft,
    };

    const result = await dispatch(updateTemplateApi(data));

    if (updateTemplateApi.fulfilled.match(result)) {
      AlertMessage.success('Progress tracker template successfully updated.');
      navigate(`/template/${result.payload}/details`);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
      setIsAddProjectModalOpen(false);
      setIsProjectListModalOpen(true);
    }
  };

  const mapStagesToRequest = (stages: StageFormValue[]) => {
    return stages.map((stage, idx) => {
      return {
        name: stage.name.trim(),
        description: stage.description.trim(),
        sequence: idx + 1,
      };
    });
  };

  const createTemplate = async (values: TemplateFormValues) => {
    const data: ProgressTrackerTemplateRequestModel = {
      name: values.name?.trim(),
      folderId: values.folderId?.trim(),
      stages: mapStagesToRequest(values.stages),
      isDraft: isSavingAsDraft,
    };

    const result = await dispatch(createTemplateApi(data));

    if (createTemplateApi.fulfilled.match(result)) {
      AlertMessage.success('Progress tracker template successfully created.');
      navigate(`/template/${result.payload}/details`);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
      setIsAddProjectModalOpen(false);
      setIsProjectListModalOpen(true);
    }
  };

  const handleAddProjectModalOpen = () => {
    setIsAddProjectModalOpen(true);
  };

  const handleAddProjectModalClose = () => {
    setIsAddProjectModalOpen(false);
    setIsProjectListModalOpen(true);
  };

  const handleProjectListModalCancel = () => {
    setIsProjectListModalOpen(false);
    setIsSavingAsDraft(false);
  };

  const createNewProject = async (folderName: string) => {
    const result = await dispatch(createProject({ name: folderName }));

    if (createProject.fulfilled.match(result)) {
      return result.payload;
    } else {
      const message = getErrorMessages(result);
      AlertMessage.error(message);
    }
  };

  const onCreatePreSetupTemplate = async (values: TemplateFormValues) => {
    const data: PreSetupTemplateRequestModel = {
      category: values.category,
      stages: mapStagesToRequest(values.stages),
      name: values.name,
      isVisible: values.isVisible,
    };

    const result = await dispatch(createPreSetupTemplate(data));

    if (createPreSetupTemplate.fulfilled.match(result)) {
      AlertMessage.success('Progress tracker template successfully created.');
      navigate(`/template-library/${result.payload}/details`);
    } else {
      const message = getErrorMessages(result);
      AlertMessage.error(message);
    }
  };

  const onEditPreSetupTemplate = async (values: TemplateFormValues) => {
    const preValues =
      preInitialValues as PreSetupPTTemplateDetailsResponseModel;

    const stages = values.stages.map((stage, idx) => {
      const stageId = fields[idx].id;

      return {
        name: stage.name.trim(),
        description: stage.description.trim(),
        sequence: idx + 1,
        id: !isMockId(stageId) ? stageId : undefined,
      };
    });

    const data: PreSetupTemplateEditRequestModel = {
      id: preValues?.id,
      name: values.name,
      category: values.category,
      stages,
      isVisible: values?.isVisible,
    };

    const result = await dispatch(updatePreSetupTemplate(data));

    if (updatePreSetupTemplate.fulfilled.match(result)) {
      AlertMessage.success('Progress tracker template successfully updated.');
      navigate(`/template-library/${result.payload}/details`);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const onSubmit = async (values: TemplateFormValues) => {
    if (isAdmin)
      return isEditMode
        ? await onEditPreSetupTemplate(values)
        : await onCreatePreSetupTemplate(values);

    const folderName = values?.folderName?.trim();
    if (folderName) {
      const projectId = await createNewProject(folderName);

      if (projectId) {
        isEditMode
          ? await editTemplate({
              ...values,
              folderId: projectId,
            })
          : await createTemplate({
              ...values,
              folderId: projectId,
            });
      }
    } else {
      isEditMode ? await editTemplate(values) : await createTemplate(values);
    }
  };

  const handleSave = async () => {
    await handleSubmit(onSubmit)();
  };

  const getBackUrl = () => {
    if (isEditMode) {
      return isAdmin
        ? `/template-library/${preInitialValues?.id}/details`
        : `/template/${preInitialValues?.id}/details`;
    }

    return '/templates';
  };

  return (
    <div className="page-wrapper scroll-box ">
      <div className="page-box page-holder">
        <div onClickCapture={handleBackClick}>
          <Breadcrumb
            routes={[
              {
                title: <i className="icon icon-arrow-left" />,
                link: getBackUrl(),
              },
            ]}
          />
        </div>
        <div
          className={cx('banner mb-10', {
            inverse: !isLightTheme,
          })}
        >
          <div className="banner__left">
            <div className={styles.detailsInput}>
              <Controller
                name="name"
                render={(field) => (
                  <Input
                    {...field}
                    placeholder="Enter Template name"
                    prefix={<i className={styles.cursor} />}
                  />
                )}
                control={control}
                error={errors.name}
              />
            </div>
          </div>

          <div className="banner__actions">
            <div>
              <Button
                loading={isSubmitting}
                disabled={!isDraftValid}
                onClick={handleSaveAsDraftClick}
                className="dark-btn"
                size="large"
              >
                Save as Draft
              </Button>
            </div>

            <div>
              <Button
                loading={isSubmitting}
                disabled={!fields.length || !isDirty}
                onClick={handleSaveClick}
                className="dark-btn"
                size="large"
              >
                Save
              </Button>
            </div>
          </div>
        </div>
        {isAdmin && (
          <>
            <Controller
              name="isVisible"
              control={control}
              error={errors.isVisible}
              render={(props) => (
                <div className={styles.box}>
                  <div className={cx({ [styles.active]: !isVisibleValues })}>
                    Unpublish
                  </div>
                  <Switch {...props} className="icon-switch" />
                  <div className={cx({ [styles.active]: isVisibleValues })}>
                    Publish
                  </div>
                </div>
              )}
            />

            <Controller
              name="category"
              control={control}
              error={errors.category}
              as={
                <Select
                  size="large"
                  placeholder="Select category"
                  showSearch
                  optionFilterProp="children"
                >
                  {getCategoriesOptions().map((category) => (
                    <Select.Option
                      key={`category-option-${category.value}`}
                      value={category.value || ''}
                    >
                      {category.label}
                    </Select.Option>
                  ))}
                </Select>
              }
              containerClassName={styles.category}
            />
          </>
        )}

        <div className="cards-list">
          {fields.map((field, index) => (
            <StageEdit
              key={field.key}
              fieldName={`stages[${index}]`}
              index={index}
              control={control}
              error={errors.stages?.[index]}
              field={field}
              onDelete={handleDeleteStageClick}
              onDrop={swap}
              touched={!!touched.stages?.[index]}
              mode={StageEditMode.TemplateEdit}
            />
          ))}
        </div>

        <div className={styles.btn}>
          <div className={styles.btnWrap}>
            <Button
              type="link"
              onClick={handleAddStageClick}
              disabled={isAddingDisabled}
              title={
                isAddingDisabled
                  ? 'There can only be a maximum of 20 stages for each tracker'
                  : undefined
              }
            >
              <div className={styles.btnAdd}>
                <div className={styles.btnCircle}>
                  <i className="icon icon-plus" />
                </div>
                <div className={styles.btnText}>Add row</div>
              </div>
            </Button>
          </div>
        </div>

        <ProjectListModal
          open={isProjectListModalOpen}
          onOk={handleSave}
          onCancel={handleProjectListModalCancel}
          onAddProjectModalOpen={handleAddProjectModalOpen}
          control={control}
          error={errors.folderId}
          defaultProject={defaultProject}
        />

        <AddProjectModal
          open={isAddProjectModalOpen}
          control={control}
          error={errors.folderName}
          onClose={handleAddProjectModalClose}
          onOk={handleSave}
        />
      </div>
    </div>
  );
}
