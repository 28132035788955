import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Col, Row, Spin } from 'antd';
import { isEmpty, isNil, isObject } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import queryString from 'query-string';
import cx from 'classnames';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

import { CompletenessFilterEnum } from 'api';
import AlertMessage from 'components/AlertMessage';
import { SortingFilterValueType } from 'components/filters/SortingFilter';
import NoInitialData from 'components/NoInitialData';
import EmptySearchResults from 'components/search/SearchResults/EmptySearchResults';
import { PageSize } from 'constants/ApiConstant';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { detailsSectionId } from 'constants/SearchConstants';
import { getUserInfo } from 'redux/reducers/authenticationSlice';
import { getSpaceRole } from 'redux/reducers/roleSlice';
import {
  currentSpaceNameIdSelector,
  hasAnySpaceSelector,
} from 'redux/selectors/spacesSelectors';
import { AppDispatch } from 'redux/store';
import {
  DispatchedRequestType,
  getComponentData,
  getPageSizeByWindowHeight,
} from 'utils/searchUtils';
import { isAdminSelector } from 'redux/selectors/authenticationSelectors';

export enum SearchLayoutMode {
  Archive = 'archived_trackers',
  Projects = 'projects',
  Trackers = 'trackers',
  Users = 'users',
  DeletedTrackers = 'deleted_trackers',
  Spaces = 'spaces',
}

export const MIN_SEARCH_LENGTH = 1;

//When receiving this message redirects to the "Not found" page
const ProjectDoesNotExist = 'Project does not exist.';

export interface Filters {
  query: string;
  sorting?: SortingFilterValueType;
  completeness?: CompletenessFilterEnum;
}

export type ChildrenProps<D, T> = {
  data?: T;
  details: D[];
  isInitialDataLoaded?: boolean;
  isSearchMode?: boolean;
  emptySearchResults?: boolean;
  projectName?: string;
};

type Props<D, T> = {
  mode: SearchLayoutMode;
  filters: Filters;
  children: (props: ChildrenProps<D, T>) => ReactNode;
  columns: 1 | 2;
  searchNode: ReactNode;
  noInitialDataNode?: ReactNode;
  restRequestOptions?: object;
  minPageSize?: number;
  filtersNode?: ReactNode;
  renderBreadcrumbsNode?: (linkPart?: string) => ReactNode;
  renderActions?: (actionFunction: () => void) => ReactNode;
  isFilterUsed?: boolean;
  isInitInfScrollNeeded?: boolean;
  enableInitInfScroll?: () => void;
};

export default function SearchLayout<D, T>(props: Props<D, T>) {
  const isLightTheme = useSelector(isLightThemeSelector);
  const {
    children,
    filters,
    renderActions,
    enableInitInfScroll,
    mode,
    columns,
    searchNode,
    renderBreadcrumbsNode,
    filtersNode,
    restRequestOptions,
    noInitialDataNode,
    minPageSize = PageSize,
    isInitInfScrollNeeded = false,
    isFilterUsed = false,
  } = props;
  const [details, setDetails] = useState<D[]>([]);
  const [data, setData] = useState<T>();
  const [isLoading, setIsLoading] = useState(false);
  const [projectName, setProjectName] = useState<string>();
  const [hasMore, setHasMore] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const latestRequestRef = useRef<DispatchedRequestType>();
  const infiniteScrollParentRef = useRef(null);
  const { spaceId, spaceName } = useSelector(currentSpaceNameIdSelector);
  const hasAnySpace = useSelector(hasAnySpaceSelector);
  const isAdmin = useSelector(isAdminSelector);

  const detailsSectionHeight =
    document.getElementById(detailsSectionId)?.offsetHeight;
  const pageSizeByWindowHeight = getPageSizeByWindowHeight(
    detailsSectionHeight || 0,
  );

  const isSearchMode = filters.query.length >= MIN_SEARCH_LENGTH;

  const emptySearchResults =
    (isSearchMode || isFilterUsed) && isInitialDataLoaded && !totalCount;

  const notFoundData =
    isInitialDataLoaded && !totalCount && !isFilterUsed && !emptySearchResults;

  const getRequestOptions = (searchFieldName: string = 'name') => ({
    [searchFieldName]: filters.query.trim(),
    completenessFilter: filters?.completeness,
    sortField: filters?.sorting?.sortField,
    sortOrder: filters?.sorting?.sortOrder,
    pageSize:
      minPageSize > pageSizeByWindowHeight * columns
        ? minPageSize
        : pageSizeByWindowHeight * columns,
    ...restRequestOptions,
  });

  const componentData = getComponentData(mode, getRequestOptions, isAdmin);
  const {
    queryFieldName = 'query',
    requestOptions,
    title,
    getDataThunk,
    description,
  } = componentData;

  useEffect(() => {
    const queryData = { [queryFieldName]: filters.query };

    if (!isEmpty(filters.sorting) && isObject(filters.sorting)) {
      queryData.sortField = filters.sorting.sortField.toString();
      queryData.sortOrder = filters.sorting.sortOrder.toString();
    }

    if (filters.completeness) {
      queryData.completeness = filters.completeness.toString();
    }

    navigate(
      { search: queryString.stringify(queryData) },
      {
        replace: true,
      },
    );
    initInfinityScroll();
  }, [filters, navigate, queryFieldName]);

  useEffect(() => {
    if (isInitInfScrollNeeded) {
      initInfinityScroll();
      enableInitInfScroll?.();
    }
  }, [isInitInfScrollNeeded]);

  const changeSpace = async (accentSpace: string) => {
    const result = await dispatch(getUserInfo(accentSpace));

    if (getUserInfo.fulfilled.match(result)) {
      dispatch(getSpaceRole());
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const onSetData = useCallback(
    async (result) => {
      const loadedData = result.payload.details || [];
      const totalCount = result.payload.totalCount || 0;
      const newData = [...details, ...loadedData];
      const hasMoreRows =
        !!newData.length &&
        newData.length < totalCount &&
        loadedData.length > 0;

      setHasMore(hasMoreRows);
      setDetails(newData);
      setData(result.payload);
      setTotalCount(totalCount);

      //We need this state for a project details page
      if (result.payload?.folderName) {
        setProjectName(result.payload?.folderName);
      }

      if (result.payload?.spaceId && result.payload?.spaceId !== spaceId) {
        await changeSpace(result.payload.spaceId);
      }
    },
    [details, spaceId],
  );

  const onLoadMore = useCallback(
    async (pageNumber: number) => {
      if (spaceName) {
        if (pageNumber === 0 && !isSearchMode) {
          setIsLoading(true);
        }
        latestRequestRef.current?.abort?.();

        const request = dispatch(
          getDataThunk({ ...requestOptions, pageNumber }),
        );

        latestRequestRef.current = request;
        const result = await request;
        if (getDataThunk.fulfilled.match(result)) {
          await onSetData(result);
        } else {
          if (result.meta.aborted) return;

          setHasMore(false);
          AlertMessage.error(result.error.message || ApiErrorMessage);

          if (result.error.message === ProjectDoesNotExist) {
            navigate('/not-found');
          }
        }

        setIsLoading(false);
        setIsInitialDataLoaded(true);
      }
    },
    [spaceName, dispatch, getDataThunk, requestOptions, onSetData],
  );

  const initInfinityScroll = () => {
    setIsVisible(false);
    setDetails([]);
    setIsInitialDataLoaded(false);
    setHasMore(true);
    setTotalCount(0);

    const timer = setTimeout(() => {
      // to destroy SearchLayout
      setIsVisible(true);
    }, 0);

    return () => clearTimeout(timer);
  };

  const getNoDataNoun = (mode: SearchLayoutMode) => {
    return mode.replace('_', ' ');
  };

  return (
    <div className="page-wrapper scroll-box" ref={infiniteScrollParentRef}>
      <div className="page-box page-holder">
        <span id={detailsSectionId}>
          {renderBreadcrumbsNode?.(projectName)}
          <div
            className={cx('banner', {
              inverse: !isLightTheme,
            })}
          >
            <div className="banner__left">
              <div className="banner__count">{totalCount}</div>
              <div className="banner__name">{title}</div>
              {!isNil(description) && <div className="">{description}</div>}
            </div>
            {renderActions && (
              <div className="banner__actions">
                {renderActions(initInfinityScroll)}
              </div>
            )}
          </div>

          <div className="filters-actions">
            <Row gutter={[20, 20]}>
              <Col xs={24} sm={12}>
                <div className="filters-actions__wrap">{searchNode}</div>
              </Col>

              <Col xs={24} sm={12}>
                <div className="filters-actions__drop">{filtersNode}</div>
              </Col>
            </Row>
          </div>
        </span>

        <div>
          {emptySearchResults && <EmptySearchResults />}

          {notFoundData &&
            (noInitialDataNode || (
              <NoInitialData
                text={`You currently do not have any ${getNoDataNoun(mode)}.`}
              />
            ))}
        </div>

        {isVisible && hasAnySpace && (
          <InfiniteScroll
            initialLoad={!isInitialDataLoaded}
            maxLength={totalCount}
            pageStart={-1}
            loadMore={onLoadMore}
            hasMore={hasMore && !isLoading}
            loader={<Spin key={0} />}
            useWindow={false}
            getScrollParent={() => infiniteScrollParentRef.current}
            threshold={-200}
          >
            <>
              {isLoading && <Spin key={0} />}
              {children({
                details,
                isInitialDataLoaded,
                isSearchMode,
                emptySearchResults,
                projectName,
                data
              })}
            </>
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
}
