import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { find } from 'lodash';
import queryString from 'query-string';

import localStorage from 'utils/localStorage';
import { confirmInvite, getInfoByInvite } from 'redux/reducers/inviteSlice';
import AlertMessage from 'components/AlertMessage';
import { getAvailableSpace } from 'redux/reducers/spaceSlice';
import { isAuthenticatedSelector } from 'redux/selectors/authenticationSelectors';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { getUserInfo } from 'redux/reducers/authenticationSlice';
import { AppDispatch } from 'redux/store';

export default function InvitePage() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const { id } = queryString.parse(location.search);
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  useEffect(() => {
    if (isAuthenticated) {
      confirmInviteRequest();
    } else {
      getInfoByInviteRequest();
    }
  }, []);

  const getInfoByInviteRequest = async (isRedirect = true) => {
    const result = await dispatch(getInfoByInvite(id as string));

    if (getInfoByInvite.fulfilled.match(result)) {
      const { email, isRegistered } = result.payload;

      localStorage?.setItem(
        'inviteId',
        JSON.stringify({ inviteId: id, email }),
      );

      if (!isRedirect) return;

      if (isRegistered) {
        navigate(`/login?email=${encodeURIComponent(email as string)}`);
      } else {
        navigate(`/sign_up?email=${encodeURIComponent(email as string)}`);
      }
    }
  };

  const confirmInviteRequest = async () => {
    const result = await dispatch(confirmInvite({ inviteId: id as string }));

    if (confirmInvite.fulfilled.match(result)) {
      const availableSpacesResult: any = await dispatch(getAvailableSpace());
      const spaceId = result.payload;
      const spaceName = find(availableSpacesResult.payload, {
        id: spaceId,
      }).name;

      dispatch(getUserInfo(spaceId));
      AlertMessage.success(
        `You successfully accepted an invitation to ${spaceName} workspace`,
      );
    } else {
      getInfoByInviteRequest(false);
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
    navigate('/');
  };

  return <></>;
}
