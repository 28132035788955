import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Form } from 'antd';
import cx from 'classnames';

import AlertMessage from 'components/AlertMessage';
import ButtonWithTimer, {
  ButtonWithTimerMode,
} from 'components/ButtonWithTimer';
import Controller from 'components/form/Controller';
import MaskedInput from 'components/form/MaskedInput';
import PTModal, { PTModalProps } from 'components/PTModal';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { requiredMessage, twoFACodeMask } from 'constants/ValidationConstants';
import { enableTwoFA } from 'redux/reducers/authenticationSlice';
import { userSelector } from 'redux/selectors/authenticationSelectors';
import { AppDispatch } from 'redux/store';
import { obscureEmail } from 'utils/validationUtils';
import { TwoFATypeEnum } from 'api';

import styles from '../SecurityTab.module.less';

export const twoFACodeValidationSchema = Yup.object().shape({
  code: Yup.string()
    .trim()
    .required(requiredMessage)
    .min(6, 'Code should be 6 characters minimum'),
});

type EnableTwoFAForm = {
  code: string;
};

type Props = {
  onClose: () => void;
  twoFACodeId?: string;
  onSuccess: () => void;
} & Omit<PTModalProps, 'children'>;

export default function EnableTwoFAModal(props: Props) {
  const { onClose, twoFACodeId, onSuccess, ...otherModalProps } = props;
  const [codeId, setCodeId] = useState(twoFACodeId);
  const user = useSelector(userSelector);
  const dispatch: AppDispatch = useDispatch();

  const { control, formState, handleSubmit } = useForm<EnableTwoFAForm>({
    mode: 'all',
    resolver: yupResolver(twoFACodeValidationSchema),
    defaultValues: {
      code: undefined,
    },
  });
  const { errors, isSubmitting, isValid } = formState;

  const onSubmit = async (values: EnableTwoFAForm) => {
    const { code } = values;
    const codeWithoutDashes = code.replace(/-/g, '');

    const result = await dispatch(
      enableTwoFA({
        codeId,
        code: codeWithoutDashes,
        userId: user?.id,
        twoFaType: TwoFATypeEnum.Email,
      }),
    );

    if (enableTwoFA.fulfilled.match(result)) {
      onSuccess();
      onClose();
      AlertMessage.success(
        'Two-Factor Email Authentication was successfully enabled.',
      );
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  return (
    <PTModal
      {...otherModalProps}
      className={cx('modal-root', styles.modalTitle)}
      title="Enable Two-Factor Email Authentication"
      okText="Enable"
      onOk={handleSubmit(onSubmit)}
      okButtonProps={{
        disabled: !isValid,
      }}
      confirmLoading={isSubmitting}
      onCancel={onClose}
      zIndex={999}
    >
      <Form layout="vertical">
        <div className="modal-scroll-wrapper">
          <p>
            A security code has been sent to your{' '}
            {obscureEmail(user?.email || '')} email associated with this
            account. Please enter this code below to enable Two-Factor
            Authentication.
          </p>
          <Controller
            name="code"
            control={control}
            render={(props) => (
              <MaskedInput
                {...props}
                placeholder="XXX-XXX"
                mask={twoFACodeMask}
              />
            )}
            error={errors.code}
            containerClassName={cx(styles.input, 'dark-input-group')}
          />

          <div className={styles.timerBox}>
            <ButtonWithTimer
              mode={ButtonWithTimerMode.Enable2FA}
              codeId={codeId}
              setCodeId={setCodeId}
            />
          </div>
        </div>
      </Form>
    </PTModal>
  );
}
