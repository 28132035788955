const getSelectedFilterValues = <T>(types: T[], allValue: T): T[] => {
  if (types.includes(allValue)) {
    const allIndex = types.findIndex((element) => {
      return element === allValue;
    });

    if (allIndex === types.length - 1) {
      return [allValue];
    }
    types.splice(allIndex, 1);
  }
  return types;
};

export { getSelectedFilterValues };
