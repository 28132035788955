import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { App, Button, Divider, Form, Input, Spin } from 'antd';
import cx from 'classnames';
import { useForm } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { uniqueId } from 'lodash';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import { AppDispatch } from 'redux/store';
import {
  getAdminTrackerSubscribers,
  getTrackerSubscribers,
  GetTrackerSubscribersRequestModel,
  subscribeOnProgressTracker,
  unsubscribeTrackerUpdates,
} from 'redux/reducers/progressTrackerSlice';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import PTModal, { PTModalProps } from 'components/PTModal';
import AlertMessage from 'components/AlertMessage';
import {
  ProgressTrackerSubscriberResponseModel,
  SubscribeProgressTrackerRequestModel,
  SubscribeTypeEnum,
} from 'api';
import { PageSmallSize } from 'constants/ApiConstant';
import SearchInput from 'components/filters/SearchInput';
import Controller from 'components/form/Controller';
import PhoneInput from 'components/form/PhoneInput';
import SubmitButton from 'components/SubmitButton';
import NoInitialData from 'components/NoInitialData';
import { requiredMessage } from 'constants/ValidationConstants';
import { isAdminSelector } from 'redux/selectors/authenticationSelectors';
import { currentSpaceIdByStoreSelector } from 'redux/selectors/spacesSelectors';
import { DispatchedRequestType } from 'utils/searchUtils';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

import styles from './SubscribeModal.module.less';

interface SubscribeFormValues {
  phone?: string;
  email?: string;
}

const validationSmsSchema = Yup.object().shape({
  phone: Yup.string()
    .trim()
    .max(120, 'Phone number should be 120 characters maximum')
    .required(requiredMessage)
    .test(
      'isPhoneValid',
      'Phone number is incorrect',
      (phone) => !!phone?.match(/(^\+?)\d/g),
    ),
});

const validationEmailSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required(requiredMessage)
    .email('Email format is invalid')
    .max(120, 'Email should be 120 characters maximum'),
});

type Props = {
  onClose: () => void;
  ptId: string;
  subscribeType?: SubscribeTypeEnum;
  spaceId?: string;
} & Omit<PTModalProps, 'children'>;

export default function SubscribeModal(props: Props) {
  const {
    ptId,
    onClose,
    subscribeType,
    spaceId,
    open = true,
    ...otherModalProps
  } = props;
  const [searchQuery, setSearchQuery] = useState('');
  const [subscribers, setSubscribers] = useState<
    ProgressTrackerSubscriberResponseModel[]
  >([]);
  const [hasMore, setHasMore] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
  const [unsubscribeId, setUnsubscribeId] = useState<string>();
  const latestRequestRef = useRef<DispatchedRequestType>();
  const infiniteScrollParentRef = useRef(null);
  const isAdmin = useSelector(isAdminSelector);
  const isLightTheme = useSelector(isLightThemeSelector);
  const dispatch: AppDispatch = useDispatch();
  const { modal } = App.useApp();

  const currentValidationSchema =
    subscribeType === SubscribeTypeEnum.Email
      ? validationEmailSchema
      : validationSmsSchema;

  const { control, formState, reset, handleSubmit } =
    useForm<SubscribeFormValues>({
      resolver: yupResolver(currentValidationSchema),
      mode: 'all',
      defaultValues: {
        phone: '',
        email: '',
      },
    });

  const { isSubmitting, isDirty, errors } = formState;

  useEffect(() => {
    if (!open) return;

    initInfinityScroll();
  }, [searchQuery, open]);

  const initInfinityScroll = () => {
    setIsVisible(false);
    setSubscribers([]);
    setIsInitialDataLoaded(false);
    setHasMore(true);
    setTotalCount(0);

    const timer = setTimeout(() => {
      // to destroy infinite scroll
      setIsVisible(true);
    }, 0);

    return () => clearTimeout(timer);
  };

  const handleModalCancel = () => {
    if (isSubmitting || unsubscribeId) return;

    if (isDirty) {
      return modal.confirm({
        title: 'Changes won’t be saved. Are you sure?',
        icon: <span className="icon icon-info-circle confirm-icon" />,
        className: cx('confirm-modal', {
          'confirm-modal-dark-button': !isLightTheme,
        }),
        width: '496px',
        onOk() {
          onClose();
        },
        onCancel() {},
      });
    }
    onClose();
  };

  const subscribeTypeName = useMemo(() => {
    if (subscribeType === SubscribeTypeEnum.Sms) {
      return 'phone number';
    } else {
      return 'email address';
    }
  }, [subscribeType]);

  const onSetData = useCallback(
    async (result) => {
      const loadedData = result.payload.details || [];
      const totalCount = result.payload.totalCount || 0;
      const newData = [...subscribers, ...loadedData];
      const hasMoreRows =
        !!newData.length &&
        newData.length < totalCount &&
        loadedData.length > 0;

      setHasMore(hasMoreRows);
      setSubscribers(newData);
      setTotalCount(totalCount);
    },
    [subscribers],
  );

  const onLoadMore = useCallback(
    async (pageNumber: number) => {
      if (!subscribeType) return;
      latestRequestRef.current?.abort?.();

      const requestBody: GetTrackerSubscribersRequestModel = {
        pageNumber,
        pageSize: PageSmallSize,
        type: subscribeType,
        progressTrackerId: ptId,
        searchQuery,
        spaceId: isAdmin ? spaceId : currentSpaceIdByStoreSelector(),
      };

      const action = isAdmin
        ? getAdminTrackerSubscribers
        : getTrackerSubscribers;

      const request = dispatch(action(requestBody));

      latestRequestRef.current = request;
      const result = await request;
      if (getTrackerSubscribers.fulfilled.match(result)) {
        await onSetData(result);
      } else {
        if (result.meta.aborted) return;

        setHasMore(false);
        AlertMessage.error(result.error.message || ApiErrorMessage);
      }

      setIsInitialDataLoaded(true);
    },
    [dispatch, getTrackerSubscribers, onSetData],
  );

  const onSubmit = async (values: SubscribeFormValues) => {
    const requestBody: SubscribeProgressTrackerRequestModel = {
      progressTrackerId: ptId,
      type: subscribeType,
    };

    if (subscribeType === SubscribeTypeEnum.Email && values.email) {
      requestBody.email = values.email;
    }

    if (subscribeType === SubscribeTypeEnum.Sms && values.phone) {
      requestBody.phoneNumber = values.phone;
    }

    const result = await dispatch(subscribeOnProgressTracker(requestBody));

    if (subscribeOnProgressTracker.fulfilled.match(result)) {
      AlertMessage.success('The user was successfully subscribed.');
      reset();
      initInfinityScroll();
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const handleUnsubscribe = async (subscribeId: string) => {
    setUnsubscribeId(subscribeId);
    const result = await dispatch(unsubscribeTrackerUpdates(subscribeId));

    if (unsubscribeTrackerUpdates.fulfilled.match(result)) {
      AlertMessage.success('The recipient was successfully unsubscribed.');
      initInfinityScroll();
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
    setUnsubscribeId(undefined);
  };

  return (
    <PTModal
      {...otherModalProps}
      open={open}
      onCancel={handleModalCancel}
      footer={null}
      title={`Enter the ${subscribeTypeName}`}
      closable
      className={cx('modal-root', styles.modalTitle)}
    >
      <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
        <div className={styles.modal}>
          <div className={styles.box}>
            {subscribeType === SubscribeTypeEnum.Sms && (
              <div>
                <Controller
                  name="phone"
                  control={control}
                  error={errors.phone}
                  label="Phone number"
                  containerClassName={styles.input}
                  render={(field) => (
                    <PhoneInput {...field} placeholder="Enter phone number" />
                  )}
                  size="large"
                />
              </div>
            )}

            {subscribeType === SubscribeTypeEnum.Email && (
              <div>
                <Controller
                  name="email"
                  control={control}
                  error={errors.email}
                  label="Email"
                  placeholder="Enter email address"
                  as={Input}
                  size="large"
                  containerClassName={styles.input}
                />
              </div>
            )}

            <div className={styles.boxBtn}>
              <SubmitButton formState={formState} type="primary">
                Subscribe
              </SubmitButton>
            </div>
          </div>

          <Divider className="mt-0" />

          <div className={styles.wrap}>
            <div>
              Subscribed{' '}
              {subscribeType === SubscribeTypeEnum.Email
                ? 'emails'
                : 'phone numbers'}{' '}
              ({totalCount})
            </div>
            <div className={styles.search}>
              <SearchInput
                onChange={setSearchQuery}
                value={searchQuery}
                placeholder={`Search for ${subscribeTypeName}`}
              />
            </div>
          </div>

          <div className={styles.table} ref={infiniteScrollParentRef}>
            {isInitialDataLoaded && !subscribers.length && searchQuery && (
              <NoInitialData text="There is no subscriber for your request." />
            )}

            {isInitialDataLoaded && !subscribers.length && !searchQuery && (
              <NoInitialData text="No subscribers" />
            )}

            {isVisible && (
              <InfiniteScroll
                initialLoad={!isInitialDataLoaded}
                loadMore={onLoadMore}
                maxLength={totalCount}
                pageStart={-1}
                hasMore={hasMore}
                loader={<Spin key={uniqueId()} />}
                useWindow={false}
                getScrollParent={() => infiniteScrollParentRef.current}
                threshold={20}
              >
                {subscribers.map((subscriber) => (
                  <div className={styles.item} key={subscriber.id}>
                    <div>
                      {subscribeType === SubscribeTypeEnum.Sms
                        ? subscriber.phoneNumber
                        : subscriber.email}
                    </div>
                    <div className={styles.button}>
                      <Button
                        loading={unsubscribeId === subscriber.id}
                        type="ghost"
                        onClick={() => handleUnsubscribe(subscriber.id || '')}
                      >
                        <i className="icon icon-trash-can" />
                      </Button>
                    </div>
                  </div>
                ))}
              </InfiniteScroll>
            )}
          </div>

          <Divider />
        </div>
      </Form>
    </PTModal>
  );
}
