import React, { useEffect, useMemo, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { Tab } from 'rc-tabs/lib/interface';
import { Tabs } from 'antd';
import { isString } from 'lodash';
import { useSelector } from 'react-redux';

import useSearchQueryMatch from 'hooks/useSearchQueryMatch';
import AnalyticsSection from '../AnalyticsSection';
import DashboardSection from '../DashboardSection';
import { isAdminSelector } from 'redux/selectors/authenticationSelectors';
import { isSuperAdminSelector } from 'redux/selectors/roleSelectors';

import styles from './AnalyticsPage.module.less';

export enum AnalyticSections {
  Notifications = 'notifications',
  Dashboard = 'dashboard',
}

const getAvailableAnalyticsSections = (isSpaceOwner: boolean) =>
  isSpaceOwner
    ? Object.values(AnalyticSections)
    : [AnalyticSections.Notifications];

const getDefaultTab = (isSpaceOwner: boolean) =>
  isSpaceOwner ? AnalyticSections.Dashboard : AnalyticSections.Notifications;

export default function AnalyticsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const isAdmin = useSelector(isAdminSelector);
  const isSpaceOwner = !isAdmin && isSuperAdmin;
  const query = queryString.parse(location.search);
  const pageSectionMatch = useSearchQueryMatch(
    getAvailableAnalyticsSections(isSpaceOwner),
    'tab',
  );
  const DEFAULT_TAB = getDefaultTab(isSpaceOwner);

  const activeKey = query.tab;
  const infiniteScrollParentRef = useRef(null);

  useEffect(() => {
    if (!pageSectionMatch) {
      navigate(`${location.pathname}?tab=${DEFAULT_TAB}`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSectionMatch]);

  const tabs = useMemo((): Tab[] => {
    const tabs = [
      {
        key: AnalyticSections.Notifications,
        label: (
          <Link
            to={{
              search: queryString.stringify({
                tab: AnalyticSections.Notifications,
              }),
            }}
          >
            Notifications
          </Link>
        ),
        children: <AnalyticsSection />,
      },
    ];

    if (isSpaceOwner) {
      tabs.unshift({
        key: AnalyticSections.Dashboard,
        label: (
          <Link
            to={{
              search: queryString.stringify({
                tab: AnalyticSections.Dashboard,
              }),
            }}
          >
            Dashboard
          </Link>
        ),
        children: <DashboardSection />,
      });
    }

    return tabs;
  }, [isSpaceOwner]);

  return (
    <div className="page-wrapper scroll-box" ref={infiniteScrollParentRef}>
      <div className="page-box page-holder">
        <div className={styles.tabs}>
          <Tabs
            activeKey={isString(activeKey) ? activeKey : DEFAULT_TAB}
            animated={false}
            destroyInactiveTabPane
            items={tabs}
          />
        </div>
      </div>
    </div>
  );
}
