import * as React from 'react';
import { UploadFile } from 'antd/lib/upload/interface';
import { DeleteOutlined } from '@ant-design/icons';
import cx from 'classnames';

import { UploadItemProps } from '../BaseFileUpload';

export default function UploadList({
  uploadingFileKeys,
  loadingFileKeys,
}: UploadItemProps) {
  return {
    showRemoveIcon: true,
    removeIcon: (file: UploadFile) => (
      <DeleteOutlined
        className={cx({
          'disabled-icon':
            loadingFileKeys[file.uid] && !uploadingFileKeys[file.uid],
        })}
      />
    ),
  };
}
