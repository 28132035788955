import React, { memo } from 'react';
import { ProgressTrackerCardModel } from 'api';

import PTCard from 'features/progressTracker/PTCard';

type Props = {
  progressTrackers: ProgressTrackerCardModel[];
  projectId: string;
  handleDeleteClick: (id: string) => void;
  handleArchiveClick: (id: string) => void;
  handleMove: (id: string) => void;
};

export default memo(
  ({
    progressTrackers,
    projectId,
    handleArchiveClick,
    handleDeleteClick,
    handleMove,
  }: Props) => {
    return (
      <div className="ticket-list mt-0">
        {progressTrackers.map((pt) => (
          <div className="ticket-box" key={pt.id as string}>
            <PTCard
              pt={pt}
              projectId={projectId}
              onDelete={handleDeleteClick}
              onArchive={handleArchiveClick}
              onMove={handleMove}
            />
          </div>
        ))}
      </div>
    );
  },
);
