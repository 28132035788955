import { Button } from 'antd';
import { Link } from 'react-router-dom';

import styles from './SuccessUpgradeSection.module.less';

export default function SuccessUpgradeSection() {
  return (
    <div className="page-wrapper scroll-box ">
      <div className="page-box page-holder">
        <div className={styles.success}>
          <div className={styles.successBox}>
            <div className={styles.successIcon}>
              <i className="icon icon-check-filled" />
            </div>
            <div>
              <div className={styles.successTitle}>Thank you!</div>
              <div>Order details have been sent to your inbox.</div>
            </div>
          </div>
          <div className={styles.successBtn}>
            <Link to="/">
              <Button size="large" className="dark-btn">
                Go to Homepage
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
