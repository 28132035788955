import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

const useGoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    if (!TRACKING_ID) return;

    if (!window.GA_INITIALIZED) {
      ReactGA.initialize(TRACKING_ID);
      window.GA_INITIALIZED = true;
    }

    const pageName = location.pathname + location.search;

    ReactGA.set({ page: pageName });
    ReactGA.pageview(pageName);
  }, [location.pathname, location.search]);
};

export default useGoogleAnalytics;
