import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { getSubscriptions } from 'redux/reducers/accountSlice';
import store from 'redux/store';

export const onLoadSubscriptions = async () => {
  const result = await store.dispatch(getSubscriptions());

  if (!getSubscriptions.fulfilled.match(result) && !result.meta.condition) {
    AlertMessage.error(result.error.message || ApiErrorMessage);
  }
};
