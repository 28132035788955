import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RestrictionModalTypeEnum } from 'features/account/AccountSettingsPage/RestrictionModal';
import { PageDefaultSize } from 'constants/ApiConstant';
import { logout } from './authenticationSlice';
import apiClient, {
  AttachmentResponseModel,
  IntentTypeEnum,
  SortOrderEnum,
  SubscriptionRequestModel,
  SubscriptionResponseModel,
  SubscriptionTypeEnum,
  UpgradeToEnterpriseRequestModel,
  UserUpdateRequestModel,
} from 'api';
import { currentSpaceIdByStoreSelector } from 'redux/selectors/spacesSelectors';
import { RootState } from 'redux/reducers/rootReducer';
import dayjs from 'dayjs';

export interface GetSubscriptionCancellationsRequestModel {
  search?: string;
  sortOrder: SortOrderEnum;
  reasons?: number[];
  subscriptions?: SubscriptionTypeEnum[];
  cancelFrom?: Date;
  cancelTo?: Date;
  subscriptionEndFrom?: Date;
  subscriptionEndTo?: Date;
  pageSize: number;
  pageNumber: number;
}

type RestrictionModalState = {
  visible: boolean;
  type: RestrictionModalTypeEnum | null;
  message?: string;
  onClose?: () => void;
};

interface GetPaymentsRequestModel {
  userId: string;
  page?: string;
  desc?: boolean;
}

interface AccountState {
  subscriptions: SubscriptionResponseModel[];
  isSubscriptionsLoading: boolean;
  restrictionModalState: RestrictionModalState;
  isNewCreditCardFormDirty: boolean;
  isProfileFormDirty: boolean;
}

const initialState: AccountState = {
  subscriptions: [],
  isSubscriptionsLoading: false,
  restrictionModalState: { visible: false, type: null },
  isNewCreditCardFormDirty: false,
  isProfileFormDirty: false,
};

export const getLogo = createAsyncThunk<
  AttachmentResponseModel,
  void,
  { rejectValue: any }
>('account/getLogo', async (_, { rejectWithValue }) => {
  try {
    return await apiClient.getUserAttachment();
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const deleteLogo = createAsyncThunk(
  'account/deleteLogo',
  async (userId: string) => {
    return apiClient.deleteUserAttachment(userId);
  },
);

export const checkIsSubdomainVisible = createAsyncThunk(
  'account/checkIsSubdomainVisible',
  async (spaceId: string) => {
    return apiClient.canCreateOrUpdateSubdomain(spaceId);
  },
);

export const updateProfile = createAsyncThunk(
  'account/updateProfile',
  async (body: UserUpdateRequestModel) => {
    return apiClient.updateUserProfile(body);
  },
);

export const checkIsCardAlreadyAttached = createAsyncThunk<
  void,
  string | undefined,
  { rejectValue: { response: string } }
>(
  'account/checkIsCardAlreadyAttached',
  async (tokenId: string | undefined, { rejectWithValue }) => {
    try {
      return await apiClient.wasCardAlreadyAttached(tokenId);
    } catch (e: any) {
      return rejectWithValue(e);
    }
  },
);

export const createSubscription = createAsyncThunk(
  'account/createSubscription',
  async (data: SubscriptionRequestModel) => {
    return apiClient.createSubscription(data);
  },
);

export const confirmSubscription = createAsyncThunk(
  'account/confirmSubscription',
  async (body: {
    intentId: string;
    spaceId: string;
    intentType: IntentTypeEnum;
  }) => {
    return apiClient.confirmSubscription(
      body.intentId,
      body.spaceId,
      body.intentType,
    );
  },
);

export const getCancellationReasons = createAsyncThunk(
  'account/getCancellationReasons',
  async () => {
    return apiClient.getCancellationReasons();
  },
);

export const getSubscriptionCancellations = createAsyncThunk(
  'account/getSubscriptionCancellations',
  async ({
    search,
    sortOrder,
    reasons,
    cancelFrom,
    cancelTo,
    subscriptionEndFrom,
    subscriptionEndTo,
    subscriptions,
    pageSize,
    pageNumber,
  }: GetSubscriptionCancellationsRequestModel) => {
    return apiClient.getUserCancellationReasons(
      sortOrder,
      search,
      reasons,
      subscriptions,
      cancelTo,
      cancelFrom,
      subscriptionEndTo,
      subscriptionEndFrom,
      pageSize,
      pageNumber,
    );
  },
);

export const cancelSubscription = createAsyncThunk(
  'account/cancelSubscription',
  async (reasonId: number) => {
    return apiClient.cancelSubscription(
      currentSpaceIdByStoreSelector(),
      reasonId,
    );
  },
);

export const updateSubscription = createAsyncThunk(
  'account/updateSubscription',
  async (data: SubscriptionRequestModel) => {
    return apiClient.updateSubscription(data);
  },
);

export const getSubscriptions = createAsyncThunk(
  'account/getSubscriptions',
  async () => {
    return apiClient.getSubscriptions();
  },
  {
    condition: (_, { getState }) => {
      const { account } = getState() as RootState;

      return !account.subscriptions.length;
    },
  },
);

export const upgradeToEnterprise = createAsyncThunk(
  'account/upgradeToEnterprise',
  async (data: UpgradeToEnterpriseRequestModel) => {
    return apiClient.upgradeToEnterprise(data);
  },
);

export const getCardList = createAsyncThunk(
  'account/getCardList',
  async (userId: string) => {
    return apiClient.getCards(userId);
  },
);

export interface SetDefaultPaymentCardRequestModel {
  userId: string;
  defaultPaymentMethodId: string;
}

export const setDefaultPaymentCard = createAsyncThunk(
  'account/setDefaultPaymentCard',
  async ({
    userId,
    defaultPaymentMethodId,
  }: SetDefaultPaymentCardRequestModel) => {
    return apiClient.setPrimary(userId, defaultPaymentMethodId);
  },
);

export const deleteCreditCard = createAsyncThunk(
  'account/deleteCreditCard',
  async (paymentMethodId: string) => {
    return apiClient.deletePaymentMethod(paymentMethodId);
  },
);

export const createPaymentIntentForCardCheck = createAsyncThunk(
  'account/createPaymentIntentForCardCheck',
  async (userId: string) => {
    return apiClient.createPaymentIntent(userId);
  },
);

export const getPaymentHistory = createAsyncThunk(
  'account/getPaymentHistory',
  async ({ userId, page, desc = false }: GetPaymentsRequestModel) => {
    return apiClient.getPayments(userId, page, PageDefaultSize, desc);
  },
);

export const exportInvoiceHistory = createAsyncThunk(
  'account/downloadInvoiceHistory',
  async (userId: string) => {
    const tz = dayjs().utcOffset() / 60;
    return apiClient.exportPaymentsExcel(userId, tz);
  },
);

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setNewCreditCardFormDirty: (state, action: PayloadAction<boolean>) => {
      state.isNewCreditCardFormDirty = action.payload;
    },
    setProfileFormDirty: (state, action: PayloadAction<boolean>) => {
      state.isProfileFormDirty = action.payload;
    },
    openRestrictionModal: (
      state,
      action: PayloadAction<Omit<RestrictionModalState, 'visible'>>,
    ) => {
      state.restrictionModalState = {
        ...action.payload,
        visible: true,
      };
    },
    closeRestrictionModal: (state) => {
      state.restrictionModalState = {
        onClose: undefined,
        visible: false,
        type: null,
        message: undefined,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      Object.assign(state, initialState);
    });
    builder.addCase(getSubscriptions.fulfilled, (state, action) => {
      state.subscriptions = action.payload;
      state.isSubscriptionsLoading = false;
    });
    builder.addCase(getSubscriptions.pending, (state) => {
      state.isSubscriptionsLoading = true;
    });
    builder.addCase(getSubscriptions.rejected, (state) => {
      state.isSubscriptionsLoading = false;
    });
  },
});

const { actions, reducer } = accountSlice;

export const {
  openRestrictionModal,
  closeRestrictionModal,
  setNewCreditCardFormDirty,
  setProfileFormDirty,
} = actions;
export default reducer;
