import React, { RefObject, useCallback, useEffect, useState } from 'react';
import { App, Button, Form, Spin } from 'antd';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import cx from 'classnames';

import { AgreementResponseModel, AgreementTypeEnum } from 'api';
import AlertMessage from 'components/AlertMessage';
import Checkbox from 'components/form/Checkbox';
import Controller from 'components/form/Controller';
import PTModal from 'components/PTModal';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import {
  getAgreementByType,
  logout,
  signAgreement,
} from 'redux/reducers/authenticationSlice';
import { userSelector } from 'redux/selectors/authenticationSelectors';
import { AppDispatch } from 'redux/store';
import { isInviteForThisAccount } from 'utils/inviteUtils';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

import styles from './MasterTermsModal.module.less';

interface FormValues {
  isAgreeTerms: boolean;
}

const validationSchema = Yup.object({
  isAgreeTerms: Yup.boolean().required('Field is required'),
});

export default function MasterTermsModal() {
  const user = useSelector(userSelector);
  const isLightTheme = useSelector(isLightThemeSelector);
  const isUserInvited = isInviteForThisAccount(user?.email);
  const [isInitialLoaded, setIsInitialLoaded] = useState(false);
  const [isTermsRead, setIsTermsRead] = useState(false);
  const [agreementDetails, setAgreementDetails] =
    useState<AgreementResponseModel>();
  const dispatch: AppDispatch = useDispatch();
  const { modal } = App.useApp();
  const scrollRef: RefObject<HTMLDivElement> = useBottomScrollListener(
    () => {
      setIsTermsRead(true);
    },
    {
      offset: 10,
    },
  );

  const { handleSubmit, control, errors, watch } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      isAgreeTerms: false,
    },
    shouldUnregister: false,
    mode: 'all',
  });
  const watchIsAgreeTermsField = watch('isAgreeTerms');

  const loadMasterTerms = useCallback(async () => {
    const result = await dispatch(
      getAgreementByType(AgreementTypeEnum.MasterTerms),
    );

    setIsInitialLoaded(true);

    if (getAgreementByType.fulfilled.match(result)) {
      return setAgreementDetails(result.payload);
    }
    AlertMessage.error(result.error.message || ApiErrorMessage);
  }, [dispatch]);

  useEffect(() => {
    loadMasterTerms();
  }, [loadMasterTerms]);

  const handleModalCancel = () => {
    return modal.confirm({
      title: 'You will be logged out. Agree?',
      icon: <span className="icon icon-info-circle confirm-icon" />,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      okText: 'Yes',
      cancelText: 'No',
      width: '496px',
      onOk() {
        dispatch(logout());
      },
      onCancel() {},
    });
  };

  const onSubmit = async (data: FormValues) => {
    if (!data.isAgreeTerms) return;

    const result = await dispatch(signAgreement(agreementDetails?.id || ''));
    if (!signAgreement.fulfilled.match(result)) {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const handleOk = () => {
    handleSubmit(onSubmit)();
  };

  const renderFooter = () => {
    return (
      <div className={styles.modalFooter}>
        <div className={styles.modalFooterCheck}>
          <Controller
            name="isAgreeTerms"
            control={control}
            error={errors.isAgreeTerms}
            render={(props) => (
              <Checkbox disabled={!isTermsRead} {...props}>
                I have read and agree to the Master Terms
              </Checkbox>
            )}
          />
        </div>
        <div className={styles.modalFooterBtn}>
          <div>
            <Button onClick={handleModalCancel}>Cancel</Button>
          </div>
          <div>
            <Button
              disabled={!watchIsAgreeTermsField}
              onClick={handleOk}
              type="primary"
            >
              {isUserInvited ? 'Get started' : 'Agree'}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <PTModal
      open
      title="Master Terms"
      footer={renderFooter()}
      className={cx('modal-root', styles.modal)}
    >
      <Form layout="vertical">
        <div className={styles.modalScrollWrapper} ref={scrollRef}>
          {isInitialLoaded ? (
            <div className={styles.modalTerms}>
              <div
                dangerouslySetInnerHTML={{
                  __html: agreementDetails?.agreementHtml || '',
                }}
              />
              <div className={styles.modalText}>
                You can view the terms of the software license agreement at{' '}
                <a
                  href="https://apdated.co/terms-and-conditions"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://apdated.co/terms-and-conditions
                </a>
                .
              </div>
            </div>
          ) : (
            <Spin />
          )}
        </div>
      </Form>
    </PTModal>
  );
}
