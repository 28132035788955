import React, { useCallback, useEffect, useState } from 'react';
import { App, Button, Col, Divider, Form, Row, Spin } from 'antd';
import cx from 'classnames';
import { isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';

import {
  InviteUserListRequestModel,
  SubscriptionSumDescriptionResponseModel,
} from 'api';
import AlertMessage from 'components/AlertMessage';
import SubmitButton from 'components/SubmitButton';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { confirmSubscription } from 'redux/reducers/accountSlice';
import { getUserInfo } from 'redux/reducers/authenticationSlice';
import { getPriceDetails as getPriceDetailsApi } from 'redux/reducers/transactionSlice';
import { userSelector } from 'redux/selectors/authenticationSelectors';
import { AppDispatch } from 'redux/store';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import {
  confirmCardPayment,
  SuccessPaymentParams,
} from 'utils/creditCardUtils';
import {
  addInvitesSubscription,
  inviteNewUsers,
  InvitesSubscriptionOptions,
} from 'redux/reducers/inviteSlice';
import { currentSpaceNameIdSelector } from 'redux/selectors/spacesSelectors';
import { InviteUsersFormValues } from '../../InviteUsersModal';

import styles from './LicensePaymentForm.module.less';

type FormValues = {};

type Props = {
  priceId?: string;
  creditCardId: string;
  onCancelPayment: () => void;
  usersDetails: InviteUsersFormValues;
};

export default function LicensePaymentForm(props: Props) {
  const { priceId, creditCardId, onCancelPayment, usersDetails } = props;
  const [isInitialPriceLoaded, setIsInitialPriceLoaded] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const [isPriceGetting, setIsPriceGetting] = useState(false);
  const [priceDetails, setPriceDetails] =
    useState<SubscriptionSumDescriptionResponseModel>();
  const subtotalPrice = ((priceDetails?.subtotal || 0) / 100).toFixed(2);
  const totalPrice = ((priceDetails?.totalSum || 0) / 100).toFixed(2);
  const tax = ((priceDetails?.taxSum || 0) / 100).toFixed(2);
  const promotionCodeDetails = priceDetails?.promotionCode;
  const { spaceName, spaceId } = useSelector(currentSpaceNameIdSelector);

  const user = useSelector(userSelector);
  const isLightTheme = useSelector(isLightThemeSelector);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const stripe = useStripe();
  const { modal } = App.useApp();
  const inviteQuantity = usersDetails.users.length || 0;
  const { formState, handleSubmit } = useForm<FormValues>({
    mode: 'all',
  });
  const { isDirty } = formState;

  const loadInitialPrice = useCallback(async () => {
    await getPriceDetails();

    setIsInitialPriceLoaded(true);
  }, []);

  useEffect(() => {
    loadInitialPrice();
  }, [loadInitialPrice]);

  const getPriceDetails = async () => {
    setIsPriceGetting(true);

    const result = await dispatch(
      getPriceDetailsApi({
        priceId: priceId || '',
        defaultPaymentMethod: creditCardId,
        quantity: inviteQuantity,
      }),
    );

    if (getPriceDetailsApi.fulfilled.match(result)) {
      setPriceDetails(result.payload);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
    setIsPriceGetting(false);
  };

  const handleUpgradeCancel = () => {
    if (isDirty) {
      return modal.confirm({
        title: 'Changes won’t be saved. Are you sure?',
        icon: <span className="icon icon-info-circle confirm-icon" />,
        className: cx('confirm-modal', {
          'confirm-modal-dark-button': !isLightTheme,
        }),
        width: '496px',
        onOk() {
          onCancelPayment();
        },
        onCancel() {},
      });
    }

    onCancelPayment();
  };

  const onInviteNewUsers = async () => {
    const requestData: InviteUserListRequestModel = {
      usersToInvite: usersDetails.users.map((user) => ({
        inviteEmail: user.email,
        roleId: user.roleId,
      })),
      spaceId,
      spaceName,
    };
    const result = await dispatch(inviteNewUsers(requestData));

    if (inviteNewUsers.fulfilled.match(result)) {
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const handlePay = useCallback(async () => {
    setIsPaying(true);

    const requestBody: InvitesSubscriptionOptions = {
      priceId,
      inviteQuantity,
    };

    const result = await dispatch(addInvitesSubscription(requestBody));

    if (!addInvitesSubscription.fulfilled.match(result)) {
      AlertMessage.error(result.error.message || ApiErrorMessage);
      setIsPaying(false);
      return;
    }

    if (isEmpty(result.payload) || !result.payload) {
      await dispatch(getUserInfo(user?.spaceId));
      setIsPaying(false);
      navigate(
        { search },
        {
          state: { isPaidSuccessfully: true },
          replace: true,
        },
      );
      return;
    }

    const { clientSecret, errorMessage, intentType } = result.payload;

    if (!clientSecret || !intentType || errorMessage) {
      AlertMessage.error(errorMessage || 'Invalid card.');
      setIsPaying(false);
      return;
    }

    await confirmCardPayment(
      stripe,
      clientSecret,
      creditCardId,
      intentType,
      onSuccessPayment,
    );
    await onInviteNewUsers();

    setIsPaying(false);

    onCancelPayment();
  }, []);

  const onSuccessPayment = async ({
    intentId,
    intentType,
  }: SuccessPaymentParams) => {
    if (!intentType || !intentId) return;

    await dispatch(
      confirmSubscription({
        intentId,
        spaceId: spaceId || '',
        intentType,
      }),
    );

    await dispatch(getUserInfo(user?.spaceId));

    setIsPaying(false);
    navigate(
      { search },
      {
        state: { isPaidSuccessfully: true },
        replace: true,
      },
    );
  };

  if (!isInitialPriceLoaded) return <Spin />;

  return (
    <div className={styles.wrapper}>
      <Form layout="vertical" onFinish={handleSubmit(handlePay)}>
        <Row gutter={[40, 24]}>
          <Col xs={24} lg={12}>
            <div className={styles.title}>Summary</div>
            <div className={styles.table}>
              <div className={styles.row}>
                <div>Subtotal</div>
                <strong>${subtotalPrice}</strong>
              </div>
              <div className={styles.row}>
                <div>Tax</div>
                <strong>${tax}</strong>
              </div>
              <Divider />
              <div className={styles.row}>
                <div>Total</div>
                <div className={styles.box}>
                  {promotionCodeDetails && (
                    <div className={styles.discount}>
                      ${(+subtotalPrice + +tax).toFixed(2)}
                    </div>
                  )}
                  <strong>${totalPrice}</strong>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} lg={12} className={styles.line}>
            <div className={styles.btn}>
              <div>
                <Button
                  size="large"
                  disabled={isPaying}
                  onClick={handleUpgradeCancel}
                  className={cx({ 'dark-btn-outline': !isLightTheme })}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <SubmitButton
                  formState={formState}
                  disabled={isPriceGetting}
                  loading={!isInitialPriceLoaded}
                  size="large"
                  type="primary"
                >
                  Pay now ${totalPrice}
                </SubmitButton>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
