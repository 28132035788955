import { useSelector } from 'react-redux';

import SettingsLayout from 'components/settings/SettingsLayout';
import { SettingsTabEnum } from 'constants/SettingsConstants';
import {
  isSpaceAdminSelector,
  isSuperAdminSelector,
} from 'redux/selectors/roleSelectors';
import { isAdminSelector } from 'redux/selectors/authenticationSelectors';

export default function AccountSettingsPage() {
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const isSpaceAdmin = useSelector(isSpaceAdminSelector);
  const isAdmin = useSelector(isAdminSelector);
  const isSpaceOwner = !isAdmin && isSuperAdmin;
  const defaultTab = isSpaceOwner
    ? SettingsTabEnum.Profile
    : SettingsTabEnum.Security;

  const getSettingsTabs = () => {
    if (isSpaceOwner) {
      return {
        [SettingsTabEnum.Profile.toString()]: isSuperAdmin,
        [SettingsTabEnum.UserManagement.toString()]: isSuperAdmin,
        [SettingsTabEnum.Security.toString()]: isSuperAdmin,
        [SettingsTabEnum.Notifications.toString()]: isSuperAdmin,
      };
    }

    if (isSpaceAdmin) {
      return {
        [SettingsTabEnum.UserManagement.toString()]: isSpaceAdmin,
        [SettingsTabEnum.Security.toString()]: isSpaceAdmin,
        [SettingsTabEnum.Notifications.toString()]: isSpaceAdmin,
      };
    }

    if (isAdmin) {
      return {
        [SettingsTabEnum.Security.toString()]: isAdmin,
      };
    }

    return {
      [SettingsTabEnum.Security.toString()]: true,
      [SettingsTabEnum.Notifications.toString()]: true,
    };
  };

  return (
    <SettingsLayout availableTabs={getSettingsTabs()} defaultTab={defaultTab} />
  );
}
