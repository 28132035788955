import React from 'react';
import type { MenuProps } from 'antd';
import { Layout, Menu } from 'antd';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';

import {
  isCustomLogoLoadingSelector,
  logoUrlSelector,
} from 'redux/selectors/progressTrackerSelectors';
import { userSelector } from 'redux/selectors/authenticationSelectors';
import { useQueryMatch } from 'hooks/useQueryMatch';

import styles from './Sidebar.module.less';
import './Sidebar.less';

import imgLogoNew from '../../../../assets/img/landing/apdated-logo-header.svg';

const { Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

type Props = {
  isSidebarOpen: boolean;
  handleSidebarChange: (isOpen: boolean) => void;
};

export default function Sidebar(props: Props) {
  const { isSidebarOpen, handleSidebarChange } = props;
  const user = useSelector(userSelector);
  const logoUrl = useSelector(logoUrlSelector);
  const isCustomLogoLoading = useSelector(isCustomLogoLoadingSelector);
  const isMobile = useQueryMatch();

  const handleClick = () => {
    isMobile && handleSidebarChange(false);
  };

  const items: MenuItem[] = [
    {
      key: 'homeKey',
      title: '',
      label: (
        <NavLink
          to="/"
          className={({ isActive }) => (isActive ? 'selected' : undefined)}
        >
          <div className="side-icon">
            <i className="icon icon-home" />
          </div>
          <div>Home</div>
        </NavLink>
      ),
      onClick: handleClick,
    },
    {
      key: 'userListKey',
      title: '',
      label: (
        <NavLink
          to="/users"
          className={({ isActive }) => (isActive ? 'selected' : undefined)}
        >
          <div className="side-icon">
            <i className="icon icon-users" />
          </div>
          <div>User List</div>
        </NavLink>
      ),
      onClick: handleClick,
    },
    {
      key: 'trackersKey',
      title: '',
      label: (
        <NavLink
          to="/trackers"
          className={({ isActive }) => (isActive ? 'selected' : undefined)}
        >
          <div className="side-icon">
            <i className="icon icon-tracker" />
          </div>
          <div>Trackers</div>
        </NavLink>
      ),
      onClick: handleClick,
    },
    {
      key: 'transactionsKey',
      title: '',
      label: (
        <NavLink
          to="/transactions"
          className={({ isActive }) => (isActive ? 'selected' : undefined)}
        >
          <div className="side-icon">
            <i className="icon icon-transaction" />
          </div>
          <div>Transactions</div>
        </NavLink>
      ),
      onClick: handleClick,
    },
    {
      key: 'spacesKey',
      title: '',
      label: (
        <NavLink
          to="/spaces"
          className={({ isActive }) => (isActive ? 'selected' : undefined)}
        >
          <div className="side-icon">
            <i className="icon icon-more-square" />
          </div>
          <div>Spaces</div>
        </NavLink>
      ),
      onClick: handleClick,
    },
    {
      key: 'cancelationHistoryKey',
      title: '',
      label: (
        <NavLink
          to="/cancelation-history"
          className={({ isActive }) => (isActive ? 'selected' : undefined)}
        >
          <div className="side-icon">
            <i className="icon icon-history" />
          </div>
          <div>Cancellation history</div>
        </NavLink>
      ),
      onClick: handleClick,
    },
    {
      key: 'templatesKey',
      title: '',
      label: (
        <NavLink
          to="/templates"
          className={({ isActive }) => (isActive ? 'selected' : undefined)}
        >
          <div className="side-icon">
            <i className="icon icon-template" />
          </div>
          <div>Templates</div>
        </NavLink>
      ),
      onClick: handleClick,
    },
  ];

  const getSiderWidth = () => {
    if (isMobile) return 260;

    if (!isMobile) return 0;

    return undefined;
  };

  return (
    <Sider
      trigger={null}
      collapsed={!isMobile}
      className={cx('side-nav', styles.sidebar, {
        'mobile-nav': isMobile,
        [styles.position]: isMobile,
        [styles.isOpen]: isSidebarOpen,
      })}
      width={getSiderWidth()}
      collapsedWidth={isMobile ? 0 : 136}
    >
      {!isMobile && (
        <Link to="/" onClick={handleClick} className={styles.sidebarImg}>
          {!isCustomLogoLoading && (
            <img src={!user && logoUrl ? logoUrl : imgLogoNew} alt="logo" />
          )}
        </Link>
      )}

      <Menu items={items} />
    </Sider>
  );
}
