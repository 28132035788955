import React, { useCallback, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { App, Button, Dropdown, MenuProps, Progress, Tooltip } from 'antd';
import cx from 'classnames';

import {
  AdminProgressTrackerDetailedResponseModel,
  ProgressTrackerResponseModel,
  SubscribeTypeEnum,
} from 'api';
import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { getPTDueDate, getStagesToDo } from 'components/DetailsSection/utils';
import SubscribeModal from 'components/ProgressTracker/SubscribeModal';
import {
  archiveTrackerTitle,
  confirmIcon,
  deleteTrackerTitle,
} from 'constants/ConfirmationConstants';
import MoveProgressTrackerModal from 'features/progressTracker/ProgressTrackerDetailsPage/MoveProgressTrackerModal';
import { deleteProgressTracker } from 'redux/reducers/progressTrackerSlice';
import { archiveTracker } from 'redux/reducers/projectSlice';
import {
  isAdminSelector,
  userSelector,
} from 'redux/selectors/authenticationSelectors';
import { AppDispatch } from 'redux/store';
import { getDaysToDelete } from 'utils/confirmationUtils';
import { getDateForUserTimezone } from 'utils/dateUtils';
import { getCorrectStrokeColor, getEndedStage } from 'utils/trackerUtils';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import { getPercentage } from 'utils/percentageUtils';

type Props = {
  details:
    | ProgressTrackerResponseModel
    | AdminProgressTrackerDetailedResponseModel;
  isActions?: boolean;
};

export default function DetailsSection(props: Props) {
  const isLightTheme = useSelector(isLightThemeSelector);
  const { details, isActions = true } = props;
  const { createdDate, id, stages, completenessStatus = 0 } = details;
  const isAdmin = useSelector(isAdminSelector);
  const user = useSelector(userSelector);

  const [isMovePTModalVisible, setIsMovePTModalVisible] = useState(false);
  const [isSubscribeModalVisible, setIsSubscribeModalVisible] = useState(false);
  const [subscribeType, setSubscribeType] = useState<SubscribeTypeEnum>();
  const [isCopyTooltipOpen, setIsCopyTooltipOpen] = useState(false);
  const [isCopiedSuccessfully, setIsCopiedSuccessfully] = useState(false);

  const dueDate = getPTDueDate(details);
  const stagesRemaining = getStagesToDo(details);
  const roundedCompleteness = getPercentage(completenessStatus);
  const isAnyStageEnded = !!getEndedStage(stages);

  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { modal } = App.useApp();

  const handleMoveClick = () => {
    setIsMovePTModalVisible(true);
  };

  const handleCloseMovePTModal = () => setIsMovePTModalVisible(false);

  const onArchiveProgressTracker = async () => {
    if (!details?.id) return;

    const result = await dispatch(archiveTracker(details.id));

    if (archiveTracker.fulfilled.match(result)) {
      AlertMessage.success('Tracker was successfully archived.');
      navigate(`/project/${details.folderId}`);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const handleArchiveProgressTracker = () =>
    modal.confirm({
      title: archiveTrackerTitle,
      icon: confirmIcon,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      onOk: onArchiveProgressTracker,
      okText: 'Yes',
      onCancel() {},
    });

  const onDeleteProgressTracker = async () => {
    if (!details?.id) return;

    const result = await dispatch(deleteProgressTracker(details.id));

    if (deleteProgressTracker.fulfilled.match(result)) {
      AlertMessage.success('Tracker was successfully deleted.');
      navigate(`/project/${details.folderId}`);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const handleDeleteProgressTracker = () => {
    const daysToDelete = getDaysToDelete(user?.subscriptionType);

    return modal.confirm({
      title: deleteTrackerTitle(daysToDelete),
      icon: confirmIcon,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      onOk: onDeleteProgressTracker,
      onCancel() {},
      okText: 'Yes',
    });
  };

  const handleSubscribeClick = (type: SubscribeTypeEnum) => {
    setSubscribeType(type);
    setIsSubscribeModalVisible(true);
  };

  const handleSubscribeClose = () => {
    setIsSubscribeModalVisible(false);
  };

  const trackerManagementMenu: MenuProps['items'] = [
    {
      key: 'edit',
      label: (
        <Link to={`/project/${details.folderId}/tracker-edit/${id}`}>
          <i className="icon icon-edit" />
          Edit
        </Link>
      ),
    },
    {
      key: 'archive',
      label: (
        <div onClick={handleArchiveProgressTracker}>
          <i className="icon icon-archive" />
          Archive
        </div>
      ),
    },
    {
      key: 'move',
      label: (
        <div onClick={handleMoveClick}>
          <i className="icon icon-move" />
          Move
        </div>
      ),
    },
    {
      key: 'delete',
      label: (
        <div onClick={handleDeleteProgressTracker}>
          <i className="icon icon-delete" />
          Delete
        </div>
      ),
    },
  ];

  const handleCopyToClipboard = useCallback((text: string, result: boolean) => {
    setIsCopiedSuccessfully(result);
    setIsCopyTooltipOpen(true);

    setTimeout(() => setIsCopyTooltipOpen(false), 3000);
  }, []);

  const renderTrackerManagementDropdown = () => (
    <Dropdown
      menu={{ items: trackerManagementMenu }}
      trigger={['click']}
      placement="bottom"
    >
      <Button size="large" className="dark-btn">
        Options
      </Button>
    </Dropdown>
  );

  return (
    <>
      <MoveProgressTrackerModal
        open={isMovePTModalVisible}
        projectId={details?.id as string}
        onClose={handleCloseMovePTModal}
      />

      <SubscribeModal
        open={isSubscribeModalVisible}
        subscribeType={subscribeType}
        ptId={details.id || ''}
        onClose={handleSubscribeClose}
        spaceId={details?.spaceId}
      />

      <div
        className={cx('banner', {
          inverse: !isLightTheme,
        })}
      >
        <div className="banner__left">
          <div className="banner__progress">
            <Progress
              type="circle"
              percent={roundedCompleteness}
              strokeColor={getCorrectStrokeColor(
                roundedCompleteness,
                isAnyStageEnded,
              )}
              strokeWidth={10}
              size={54}
              format={(percent) => `${percent}%`}
            />
          </div>

          <div>
            <div className="banner__name mb-8">{details.name}</div>
            {dueDate && (
              <div className="banner__item">
                Due date: <span>{dueDate}</span>
              </div>
            )}
            {details.trackerId && (
              <div className="banner__item">
                TrackerID:{' '}
                <span>
                  {details.trackerId}{' '}
                  <CopyToClipboard
                    text={details.trackerId}
                    onCopy={handleCopyToClipboard}
                  >
                    <Tooltip
                      open={isCopyTooltipOpen}
                      title={isCopiedSuccessfully ? 'Copied' : 'Copy failed'}
                      showArrow
                    >
                      <i className="icon icon-copy cursor-pointer" />
                    </Tooltip>
                  </CopyToClipboard>
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="banner__right">
          {isAdmin && (
            <div>
              <div className="banner__item ">
                Project: <span>{details.folderName}</span>
              </div>
              <div className="banner__item ">
                Created by:{' '}
                <Link
                  to={`/user/${
                    (details as AdminProgressTrackerDetailedResponseModel)
                      .userId
                  }`}
                >
                  <span>
                    {
                      (details as AdminProgressTrackerDetailedResponseModel)
                        .trackerOwner
                    }
                  </span>
                </Link>
              </div>
            </div>
          )}

          <div>
            <div className="banner__item ">
              Created: <span>{getDateForUserTimezone(createdDate)}</span>
            </div>
            <div className="banner__item ">
              Stages remaining: <span>{stagesRemaining}</span>
            </div>
          </div>
          {isActions && (
            <div className="banner__box">
              <div className="banner__btn">
                <div>
                  <Button
                    type="ghost"
                    onClick={() =>
                      handleSubscribeClick(SubscribeTypeEnum.Email)
                    }
                  >
                    <i className="icon icon-email" />
                  </Button>
                </div>
                <div>
                  <Button
                    type="ghost"
                    onClick={() => handleSubscribeClick(SubscribeTypeEnum.Sms)}
                  >
                    <i className="icon icon-sms" />
                  </Button>
                </div>
              </div>
              {!isAdmin && (
                <div className="banner__actions">
                  <div>{renderTrackerManagementDropdown()}</div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
