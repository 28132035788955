import { RootState } from 'redux/reducers/rootReducer';
import { createSelector } from '@reduxjs/toolkit';

import { last } from 'lodash';

export const notificationsSelector = (state: RootState) =>
  state.notification.notifications;

export const isInitialDataLoadedSelector = (state: RootState) =>
  state.notification.isInitialDataLoaded;

export const isHeaderNotificationsVisibleSelector = (state: RootState) =>
  state.notification.isHeaderNotificationsVisible;

export const unreadNotificationsCountSelector = (state: RootState): number =>
  state.notification.unreadNotificationsCount;

export const lastNotificationIdSelector =  createSelector(
  notificationsSelector,
  (notifications): number | undefined => last(notifications)?.id,
);

export const hasMoreSelector = (state: RootState) => state.notification.hasMore;