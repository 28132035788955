import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import PricingPaymentDetails from '../PricingPaymentDetails';
import { PaymentMethodResponseModel, SubscriptionResponseModel } from 'api';
import AlertMessage from 'components/AlertMessage';
import {
  AddNewCardSection,
  ChangeCreditCardSection,
} from 'components/payments';
import StripeContainer from 'components/stripe/StripeContainer';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { getCardList } from 'redux/reducers/accountSlice';
import { userSelector } from 'redux/selectors/authenticationSelectors';
import { AppDispatch } from 'redux/store';
import { PriceTypesEnum } from 'enums/PriceTypesEnum';

type Props = {
  subscription?: SubscriptionResponseModel;
  onCancelUpgrading: (isReloadNeeded?: boolean) => void;
  priceType?: PriceTypesEnum;
};

export default function PricingUpgradeSection(props: Props) {
  const { subscription, onCancelUpgrading, priceType } = props;
  const user = useSelector(userSelector);
  const [creditCards, setCreditCards] =
    useState<PaymentMethodResponseModel[]>();
  const [currentCard, setCurrentCard] = useState<PaymentMethodResponseModel>();
  const [isAddingNewCreditCard, setIsAddingNewCreditCard] = useState(false);
  const [isChangingCurrentCard, setIsChangingCurrentCard] = useState(false);
  const [isError, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  const getCreditCards = async () => {
    setIsLoading(true);
    const result = await dispatch(getCardList(user?.id || ''));

    if (getCardList.fulfilled.match(result)) {
      setCreditCards(result.payload);
      setCurrentCard(result.payload[0]);
      setError(false);
    } else {
      setError(true);
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCreditCards();
  }, []);

  const handleCardChange = () => setIsChangingCurrentCard(true);

  const handleCancelChangingCurrentCard = () => setIsChangingCurrentCard(false);

  const handleAddNewCardClick = () => {
    handleCancelChangingCurrentCard();
    setIsAddingNewCreditCard(true);
  };

  const onCancelAdding = async (isAdded: boolean = false) => {
    if (isAdded) {
      await getCreditCards();
    }

    if (creditCards && creditCards?.length >= 1) {
      handleCardChange();
    }

    setIsAddingNewCreditCard(false);
  };

  const handleChangeCurrentCard = (
    newCurrentCard: PaymentMethodResponseModel,
  ) => {
    setCurrentCard(newCurrentCard);
    handleCancelChangingCurrentCard();
  };

  if (isError) onCancelUpgrading();

  if (isLoading) return <Spin />;

  return (
    <StripeContainer>
      {currentCard && !isAddingNewCreditCard && !isChangingCurrentCard && (
        <PricingPaymentDetails
          onCardChange={handleCardChange}
          onCancelUpgrading={onCancelUpgrading}
          subscription={subscription}
          creditCard={currentCard}
          priceType={priceType}
        />
      )}

      {(!currentCard || isAddingNewCreditCard) && (
        <AddNewCardSection onCancelAdding={onCancelAdding} />
      )}

      {isChangingCurrentCard && (
        <ChangeCreditCardSection
          title={`Select ${subscription?.name} Plan`}
          description="For power users who want to do even more."
          onAddNewCardClick={handleAddNewCardClick}
          creditCards={creditCards}
          onChangeCurrentCard={handleChangeCurrentCard}
          onCancel={handleCancelChangingCurrentCard}
        />
      )}
    </StripeContainer>
  );
}
