import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Button, Popover, Spin } from 'antd';

import { AppDispatch } from 'redux/store';
import {
  isHeaderNotificationsVisibleSelector,
  isInitialDataLoadedSelector,
  unreadNotificationsCountSelector,
} from 'redux/selectors/notificationSelectors';
import {
  getNotifications,
  getUnreadNotificationsCount,
  handleHeaderNotificationsVisible,
} from 'redux/reducers/notificationSlice';
import NotificationListHeader from './NotificationListHeader';
import NotificationList from './NotificationList';
import styles from './HeaderNotifications.module.less';
import { useQueryMatch } from 'hooks/useQueryMatch';
import { MobileScreenQuery } from 'constants/ScreenQuery';

const NOTIFICATIONS_MAX_COUNT = 999;

export default function HeaderNotifications() {
  const dispatch: AppDispatch = useDispatch();
  const isInitialDataLoaded = useSelector(isInitialDataLoadedSelector);
  const unreadNotificationsCount = useSelector(
    unreadNotificationsCountSelector,
  );
  const isHeaderNotificationsVisible = useSelector(
    isHeaderNotificationsVisibleSelector,
  );
  const isMobile = useQueryMatch(MobileScreenQuery);

  useEffect(() => {
    dispatch(getUnreadNotificationsCount());
    dispatch(getNotifications());
  }, [dispatch]);

  const handleVisibleChange = (visible: boolean) => {
    dispatch(handleHeaderNotificationsVisible(visible));
  };

  const hidePopover = useCallback(() => {
    dispatch(handleHeaderNotificationsVisible(false));
  }, [dispatch]);

  const renderContent = () => {
    if (!isInitialDataLoaded) return <Spin />;

    return <NotificationList onNotificationClick={hidePopover} />;
  };

  return (
    <Popover
      content={renderContent()}
      trigger="click"
      placement={isMobile ? undefined : 'bottomRight'}
      title={<NotificationListHeader />}
      open={isHeaderNotificationsVisible}
      onOpenChange={handleVisibleChange}
      overlayClassName={styles.notifyPopover}
    >
      <Button
        type="link"
        className={styles.notifyPopoverOpener}
        icon={
          <Badge
            count={unreadNotificationsCount}
            overflowCount={NOTIFICATIONS_MAX_COUNT}
          >
            <span className="icon icon-bell" />
          </Badge>
        }
      />
    </Popover>
  );
}
