import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { isArray, isNil } from 'lodash';
import { Button, Col, Row, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import cx from 'classnames';

import {
  ProgressTrackerTemplateCardResponseModel,
  ProgressTrackerTemplateCardResponseModelPagedResponseModel,
  SortFieldEnum,
  SortOrderEnum,
} from 'api';
import { AppDispatch } from 'redux/store';
import SortingFilter, {
  SortingFilterValueType,
  SortingMode,
} from 'components/filters/SortingFilter';
import noTemplates from 'assets/img/no-template.png';
import {
  getTemplates,
  isReusableTemplateAvailable,
  ProgressTrackerCardRequestModelPaged,
} from 'redux/reducers/templateSlice';
import { openRestrictionModal } from 'redux/reducers/accountSlice';
import { RestrictionModalTypeEnum } from 'features/account/AccountSettingsPage/RestrictionModal';
import SearchInput from 'components/filters/SearchInput';
import { MIN_SEARCH_LENGTH } from 'components/search/SearchLayout';
import TemplateCard from '../TemplateCard';
import EmptySearchResults from 'components/search/SearchResults/EmptySearchResults';
import NoInitialData from 'components/NoInitialData';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import { PageDefaultSize } from 'constants/ApiConstant';
import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { DispatchedRequestType } from 'utils/searchUtils';
import { useDidUpdate } from 'rooks';
import { TemplateEnum } from '../UserTemplatesListPage';

enum TemplatesFiltersTypes {
  Name = 'name',
  Sorting = 'sorting',
}

interface TemplatesFilters {
  [TemplatesFiltersTypes.Name]: string;
  [TemplatesFiltersTypes.Sorting]: SortingFilterValueType;
}

type Props = {
  scroll: any;
  isDraft?: boolean;
};

export default function MyTemplateListTab(props: Props) {
  const { scroll, isDraft } = props;
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isTemplateCreationCheckLoading, setIsTemplateCreationCheckLoading] =
    useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
  const [myTemplateList, setMyTemplateList] = useState<
    ProgressTrackerTemplateCardResponseModel[]
  >([]);
  const isLightTheme = useSelector(isLightThemeSelector);

  const { name, sortField, sortOrder, tab } = queryString.parse(
    location.search,
  );
  const latestRequestRef = useRef<DispatchedRequestType>();

  const initialValues: TemplatesFilters = {
    [TemplatesFiltersTypes.Name]: !isArray(name) && !isNil(name) ? name : '',
    [TemplatesFiltersTypes.Sorting]: {
      sortField:
        !isArray(sortField) && !isNil(sortField)
          ? +sortField
          : SortFieldEnum.Name,
      sortOrder:
        !isArray(sortOrder) && !isNil(sortOrder)
          ? +sortOrder
          : SortOrderEnum.Asc,
    },
  };
  const [filters, setFilters] = useState<TemplatesFilters>(initialValues);

  const isSearchMode =
    filters[TemplatesFiltersTypes.Name].length >= MIN_SEARCH_LENGTH;

  const emptySearchResults = isSearchMode && isInitialDataLoaded && !totalCount;

  const notFoundData =
    isInitialDataLoaded && !totalCount && !emptySearchResults;

  useDidUpdate(() => {
    const queryData = {
      [TemplatesFiltersTypes.Name]: filters[TemplatesFiltersTypes.Name],
      tab,
      sortOrder: filters[TemplatesFiltersTypes.Sorting].sortOrder,
      sortField: filters[TemplatesFiltersTypes.Sorting].sortField,
    };
    navigate(`${location.pathname}?${queryString.stringify(queryData)}`, {
      replace: true,
    });

    initInfinityScroll();
  }, [filters]);

  useEffect(() => {
    initInfinityScroll();
  }, []);

  const noInitialDataNode = (
    <div className="noData">
      <div className="image">
        <img src={noTemplates} alt="no-project" />
      </div>
      <div className="title">
        Connect your templates to their associated projects
      </div>
      <div>
        Create your first template by clicking on the “Create a template” button
      </div>
    </div>
  );

  const handleCreateTemplateClick = async () => {
    setIsTemplateCreationCheckLoading(true);
    const result = await dispatch(isReusableTemplateAvailable());
    setIsTemplateCreationCheckLoading(false);

    if (
      isReusableTemplateAvailable.fulfilled.match(result) &&
      !result.payload
    ) {
      dispatch(
        openRestrictionModal({ type: RestrictionModalTypeEnum.CreateTemplate }),
      );
      return;
    }

    navigate('/template/create');
  };

  const handleSearch = (query: string) => {
    setFilters({
      ...filters,
      [TemplatesFiltersTypes.Name]: query,
    });
  };

  const handleSort = (sortParams: SortingFilterValueType) => {
    setFilters({
      ...filters,
      [TemplatesFiltersTypes.Sorting]: sortParams,
    });
  };

  const onSetData = useCallback(
    (payload: ProgressTrackerTemplateCardResponseModelPagedResponseModel) => {
      const loadedData = payload.details || [];
      const totalCount = payload.totalCount || 0;
      const newData = [...myTemplateList, ...loadedData];
      const hasMoreRows =
        !!loadedData.length &&
        !!newData.length &&
        newData.length < totalCount &&
        loadedData.length > 0;
      setHasMore(hasMoreRows);
      setMyTemplateList(newData);
      setTotalCount(totalCount);
    },
    [myTemplateList],
  );

  const onLoadMore = useCallback(
    async (pageNumber: number) => {
      if (pageNumber === 0 && !isSearchMode) {
        setIsLoading(true);
      }
      latestRequestRef.current?.abort?.();

      const requestBody: ProgressTrackerCardRequestModelPaged = {
        pageNumber,
        pageSize: PageDefaultSize,
        category: [],
        name: filters[TemplatesFiltersTypes.Name],
        sortField: filters[TemplatesFiltersTypes.Sorting].sortField as any,
        sortOrder: filters[TemplatesFiltersTypes.Sorting].sortOrder,
        isDraft,
      };

      const request = dispatch(getTemplates(requestBody));

      latestRequestRef.current = request;
      const result = await request;

      if (getTemplates.fulfilled.match(result)) {
        onSetData(result.payload);
      } else {
        if (result.meta.aborted) return;

        setHasMore(false);
        AlertMessage.error(result.error.message || ApiErrorMessage);
      }

      setIsLoading(false);
      setIsInitialDataLoaded(true);
    },
    [dispatch, onSetData],
  );

  const initInfinityScroll = () => {
    setIsVisible(false);
    setMyTemplateList([]);
    setIsInitialDataLoaded(false);
    setHasMore(true);
    setTotalCount(0);

    const timer = setTimeout(() => {
      // to destroy SearchLayout
      setIsVisible(true);
    }, 0);

    return () => clearTimeout(timer);
  };

  return (
    <>
      <div
        className={cx('banner', {
          inverse: !isLightTheme,
        })}
      >
        <div className="banner__left">
          <div className="banner__count">{totalCount}</div>
          <h1 className="banner__name">
            {isDraft ? 'Template Drafts' : 'My Templates'}
          </h1>
        </div>
        {!isDraft && (
          <div className="banner__actions">
            <div>
              <Button
                loading={isTemplateCreationCheckLoading}
                size="large"
                onClick={handleCreateTemplateClick}
                className="dark-btn"
              >
                Create a template
              </Button>
            </div>
          </div>
        )}
      </div>

      <div className="filters-actions">
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={12}>
            <div className="filters-actions__wrap">
              <SearchInput
                onChange={handleSearch}
                value={filters[TemplatesFiltersTypes.Name]}
                placeholder="Search templates"
              />
            </div>
          </Col>

          <Col xs={24} sm={8} />

          <Col xs={24} sm={4}>
            <div className="filters-actions__drop">
              <SortingFilter
                value={filters[TemplatesFiltersTypes.Sorting]}
                onChange={handleSort}
                mode={SortingMode.Templates}
              />
            </div>
          </Col>
        </Row>
      </div>

      <div>
        {emptySearchResults && <EmptySearchResults />}

        {notFoundData &&
          (noInitialDataNode || (
            <NoInitialData text="You currently do not have any templates." />
          ))}
      </div>

      {isVisible && (
        <InfiniteScroll
          initialLoad={!isInitialDataLoaded}
          pageStart={-1}
          maxLength={totalCount}
          loadMore={onLoadMore}
          hasMore={hasMore && !isLoading}
          loader={<Spin key={0} />}
          useWindow={false}
          getScrollParent={() => scroll.current}
          threshold={-200}
        >
          <Row gutter={[20, 20]}>
            {myTemplateList.map((template) => (
              <Col md={12} xs={24} key={template.id}>
                <Link to={`/template/${template.id}/details`}>
                  <TemplateCard
                    template={template}
                    mode={TemplateEnum.MyTemplates}
                  />
                </Link>
              </Col>
            ))}
          </Row>
        </InfiniteScroll>
      )}
    </>
  );
}
