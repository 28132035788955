import React from 'react';
import { Col, Row } from 'antd';
import cx from 'classnames';

import { isValueValid } from 'utils/validationUtils';

import styles from 'features/auth/SignUpPage/SignUpPage.module.less';
import { PasswordValidationRules } from 'constants/ValidationConstants';

type Props = {
  passwordValue: string;
};

export default function PasswordValidationRulesBox(props: Props) {
  const { passwordValue } = props;
  return (
    <div className={styles.validationRule}>
      <Row gutter={20}>
        <Col
          xs={24}
          md={12}
          className={cx('validation-rule', {
            success: isValueValid(
              PasswordValidationRules.LowercaseCharacter,
              passwordValue,
            ),
          })}
        >
          <span className="circle" /> One lowercase letter (e.g. abc)
        </Col>
        <Col
          xs={24}
          md={12}
          className={cx('validation-rule', {
            success: isValueValid(
              PasswordValidationRules.SpecialCharacter,
              passwordValue,
            ),
          })}
        >
          <span className="circle" /> One special character (e.g. !@#)
        </Col>
        <Col
          xs={24}
          md={12}
          className={cx('validation-rule', {
            success: isValueValid(
              PasswordValidationRules.UppercaseCharacter,
              passwordValue,
            ),
          })}
        >
          <span className="circle" /> One uppercase letter (e.g. ABC)
        </Col>
        <Col
          xs={24}
          md={12}
          className={cx('validation-rule', {
            success: isValueValid(
              PasswordValidationRules.MinimumCharacters,
              passwordValue,
            ),
          })}
        >
          <span className="circle" /> 8 characters minimum
        </Col>
        <Col
          xs={24}
          md={12}
          className={cx('validation-rule', {
            success: isValueValid(
              PasswordValidationRules.OneNumber,
              passwordValue,
            ),
          })}
        >
          <span className="circle" /> One number (e.g. 123)
        </Col>
        <Col
          xs={24}
          md={12}
          className={cx('validation-rule', {
            success: isValueValid(
              PasswordValidationRules.MaximumCharacters,
              passwordValue,
            ),
          })}
        >
          <span className="circle" /> 50 characters maximum
        </Col>
      </Row>
    </div>
  );
}
