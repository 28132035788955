import React from 'react';
import { Form, Input } from 'antd';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import cx from 'classnames';

import AlertMessage from 'components/AlertMessage';
import Controller from 'components/form/Controller';
import PasswordValidationRulesBox from 'components/PasswordValidationRulesBox';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import {
  PasswordValidationRules,
  requiredMessage,
} from 'constants/ValidationConstants';
import { resetPassword } from 'redux/reducers/authenticationSlice';
import { AppDispatch } from 'redux/store';
import SubmitButton from 'components/SubmitButton';

import styles from '../SecurityTab.module.less';

const validationSchema = Yup.object({
  oldPassword: Yup.string().required(requiredMessage),
  newPassword: Yup.string()
    .required(requiredMessage)
    .matches(PasswordValidationRules.MinimumCharacters)
    .matches(PasswordValidationRules.MaximumCharacters)
    .matches(PasswordValidationRules.LowercaseCharacter)
    .matches(PasswordValidationRules.SpecialCharacter)
    .matches(PasswordValidationRules.UppercaseCharacter)
    .matches(PasswordValidationRules.OneNumber),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords do not match')
    .required(requiredMessage),
});

type ChangePasswordForm = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export default function ChangePasswordSection() {
  const dispatch: AppDispatch = useDispatch();

  const { control, formState, handleSubmit, watch, reset } =
    useForm<ChangePasswordForm>({
      mode: 'all',
      resolver: yupResolver(validationSchema),
      defaultValues: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
    });
  const { errors } = formState;

  const newPasswordWatchField = watch('newPassword');

  const onSubmit = async (values: ChangePasswordForm) => {
    const { newPassword, oldPassword } = values;

    const result = await dispatch(
      resetPassword({ currentPassword: oldPassword, newPassword }),
    );

    if (resetPassword.fulfilled.match(result)) {
      AlertMessage.success('Your password was successfully reset.');
      reset();
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
      className={styles.password}
    >
      <div className={styles.title}>Change password</div>

      <div className={styles.passwordInput}>
        <Controller
          label="Current password"
          placeholder="Enter current password"
          name="oldPassword"
          control={control}
          error={errors.oldPassword}
          as={Input.Password}
          containerClassName={cx(styles.input, 'dark-input-group')}
        />

        <Controller
          label="New password"
          placeholder="Enter new password"
          name="newPassword"
          control={control}
          error={errors.newPassword}
          showValidateError={errors.newPassword?.message === requiredMessage}
          as={Input.Password}
          containerClassName={cx(styles.input, 'dark-input-group')}
        />

        <Controller
          label="Re-enter new password"
          placeholder="Re-enter new password"
          name="confirmPassword"
          control={control}
          error={errors.confirmPassword}
          as={Input.Password}
          containerClassName={cx(styles.input, 'dark-input-group')}
        />
      </div>

      <div className={styles.passwordBtn}>
        <SubmitButton size="large" type="primary" formState={formState}>
          Change
        </SubmitButton>
      </div>

      <PasswordValidationRulesBox passwordValue={newPasswordWatchField} />
    </Form>
  );
}
