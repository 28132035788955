import fetchIntercept from 'fetch-intercept';
import jwt_decode from 'jwt-decode';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';

import store from 'redux/store';
import { isTokenExpired } from 'utils/authUtils';
import { getUserInfo, logout, refreshToken } from 'redux/reducers/authenticationSlice';
import localStorage from 'utils/localStorage';

// custom interceptor for 4XX errors
// fetch-intercept doesn't support async response interceptor
const fetch = window.fetch;
window.fetch = (...args) =>
  (async (args) => {
    const result = await fetch(...args);

    if (result?.status < 400 || result?.status > 499) {
      return result; // return original response
    }

    // modify response ONLY for 4XX errors
    let responseText = await result.text();

    if (responseText) {
      try {
        const jsonRes = JSON.parse(responseText);

        if (typeof jsonRes === 'object' && !jsonRes.message) {
          jsonRes.message = jsonRes.title || jsonRes.detail || ApiErrorMessage;
        }

        responseText = JSON.stringify(jsonRes);
      } catch {
        //If there is an error leave the responseText same
      }
    }

    return new Response(responseText || null, {
      status: result.status,
      statusText: result.statusText,
      headers: result.headers,
    });
  })(args);

// Fetch Interceptor
let isRefreshing = false;
let subscribers: Function[] = [];

function subscribeTokenRefresh(cb: Function) {
  subscribers.push(cb);
}

function onRefreshed(token: string) {
  subscribers.map((cb: Function) => cb(token));
}

fetchIntercept.register({
  request: async (url, config) => {
    const jwtToken = localStorage?.getItem('jwt-token');

    if (
      !jwtToken ||
      url.endsWith('/accounts/login') ||
      url.endsWith('/accounts/token/refresh') ||
      !url.startsWith(process.env.REACT_APP_API_BASE_URL || '') // attach JWT token only to our own APIs
    )
      return [url, config];

    const decodedToken: any = jwt_decode(jwtToken);
    const { user: userFromRedux } = store.getState().auth;

    if (userFromRedux?.id && decodedToken.nameid !== userFromRedux.id) {
      window.location.reload();

      // reject pending requests after page reloading
      // https://mybasepay.atlassian.net/browse/MBOM-828
      return await new Promise((resolve, reject) => {
        setTimeout(() => {
          reject();
        }, 1000);
      });
    }

    if (isTokenExpired(decodedToken)) {
      const requestSubscribers: any = new Promise((resolve) => {
        subscribeTokenRefresh((token: string) => {
          config.headers.Authorization = `bearer ${token}`;
          resolve([url, config]);
        });
      });

      if (!isRefreshing) {
        isRefreshing = true;
        let newToken: string = '';
        const result = await store.dispatch(refreshToken(jwtToken));

        if (refreshToken.fulfilled.match(result)) {
          if (!url.endsWith('/users/profile')) {
            store.dispatch(getUserInfo());
          }

          newToken = result.payload.token || '';
        } else {
          isRefreshing = false;
          subscribers = [];
          throw new Error(result.error.message || ApiErrorMessage);
        }

        isRefreshing = false;
        onRefreshed(newToken);
        subscribers = [];
      }

      return requestSubscribers;
    }

    config.headers.Authorization = `bearer ${jwtToken}`;
    return [url, config];
  },

  requestError: function (error) {
    // Called when an error occured during another 'request' interceptor call
    return Promise.reject(error);
  },

  response: function (response) {
    const url = response.request.url;

    if (response?.status === 401 && !url.endsWith('/accounts/logout')) {
      store.dispatch(logout());
    }

    return response;
  },

  responseError: function (error) {
    // Handle an fetch error
    return Promise.reject(error);
  },
});
