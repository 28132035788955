import React from 'react';
import { App, Button, Progress } from 'antd';
import { useSelector } from 'react-redux';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import cx from 'classnames';

import { ArchivedProgressTrackerCardModel } from 'api';
import {
  archiveTrackerTitle,
  confirmIcon,
} from 'constants/ConfirmationConstants';
import { getDateForUserTimezone } from 'utils/dateUtils';
import { getCorrectStrokeColor } from 'utils/trackerUtils';
import { getPercentage } from 'utils/percentageUtils';

type Props = {
  tracker: ArchivedProgressTrackerCardModel;
  onRestoreTracker: (trackerId: string) => Promise<void>;
  onArchiveTracker: (trackerId?: string) => Promise<void>;
};

export default function DeletedTrackerCard(props: Props) {
  const isLightTheme = useSelector(isLightThemeSelector);
  const { tracker, onArchiveTracker, onRestoreTracker } = props;
  const { id, percentageCompleteness = 0, isAnyStageEnded } = tracker;
  const { modal } = App.useApp();

  const roundedPercentage = getPercentage(percentageCompleteness);

  const handleRestoreClick = () => {
    return modal.confirm({
      title: 'Tracker will be restored. Continue?',
      icon: <span className="icon icon-info-circle confirm-icon" />,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      onOk: async () => {
        await onRestoreTracker(id || '');
      },
      okText: 'Yes',
      onCancel() {},
    });
  };

  const handleArchiveClick = () =>
    modal.confirm({
      title: archiveTrackerTitle,
      icon: confirmIcon,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      async onOk() {
        await onArchiveTracker(id);
      },
      okText: 'Yes',
      onCancel() {},
    });

  return (
    <div
      className={cx('ticket', {
        inverse: !isLightTheme,
      })}
    >
      <div className="ticket__left">
        <div className="ticket__progress">
          <Progress
            type="circle"
            percent={roundedPercentage}
            format={(percent) => `${percent}%`}
            strokeColor={getCorrectStrokeColor(
              roundedPercentage,
              isAnyStageEnded,
            )}
            strokeWidth={10}
            size={54}
          />
        </div>
        <div>
          <div className="ticket__name">{tracker?.name}</div>
          <div className="ticket__item">
            <span>Project:</span> {tracker?.folderName}
          </div>
          <div className="ticket__item">
            <span>Creation date: </span>
            {getDateForUserTimezone(tracker.createdDate)}
          </div>
        </div>
      </div>

      <div className="ticket__right">
        <div className="ticket__btn">
          <div>
            <Button type="ghost" onClick={handleRestoreClick}>
              <i className="icon icon-unarchived" />
            </Button>
          </div>
          <div>
            <Button type="ghost" onClick={handleArchiveClick}>
              <i className="icon icon-archive" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
