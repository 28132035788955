import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'antd';
import cx from 'classnames';

import AlertMessage from 'components/AlertMessage';
import PTModal, { PTModalProps } from 'components/PTModal';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { disableTwoFA } from 'redux/reducers/authenticationSlice';
import { AppDispatch } from 'redux/store';
import { TwoFATypeEnum } from 'api';

import styles from '../SecurityTab.module.less';

type Props = {
  onClose: () => void;
  onSuccess: () => void;
  twoFAType?: TwoFATypeEnum;
} & Omit<PTModalProps, 'children'>;

export default function DisableTwoFAModal(props: Props) {
  const { onClose, onSuccess, twoFAType, ...otherModalProps } = props;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  const handleDisableTwoFA = async () => {
    setIsLoading(true);
    const result = await dispatch(disableTwoFA());

    if (disableTwoFA.fulfilled.match(result)) {
      AlertMessage.success(
        twoFAType === TwoFATypeEnum.Email
          ? 'Email authentication was successfully disabled.'
          : 'Mobile authentication was successfully disabled.',
      );
      onSuccess();
      onClose();
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
    setIsLoading(false);
  };

  return (
    <PTModal
      {...otherModalProps}
      className={cx('modal-root', styles.modalTitle)}
      title={
        twoFAType === TwoFATypeEnum.Email
          ? 'Disable Two-Factor Email Authentication'
          : 'Disable Mobile Two-Factor Authentication'
      }
      okText="Disable"
      onOk={handleDisableTwoFA}
      confirmLoading={isLoading}
      onCancel={onClose}
      zIndex={999}
    >
      <Form layout="vertical">
        <div className="modal-scroll-wrapper">
          <p>
            Are you sure you want to disable the two-factor authentication? For
            security reasons, it is recommended to keep this setting enabled.
          </p>
        </div>
      </Form>
    </PTModal>
  );
}
