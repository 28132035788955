import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { HomePageSteps, OnboardingPagesEnum } from 'enums/OnboardingEnums';
import OnboardingHomePage from 'features/home/OnboardingHomePage';
import Onboarding from 'features/onboarding';
import { startOnboarding } from 'redux/reducers/onboardingSlice';
import {
  isOnboardingManualSelector,
  isOnboardingRunningSelector,
  isVisibleOnboardingPageSelector,
  onboardingPageSelector,
} from 'redux/selectors/onboardingSelector';
import {
  isSignAgreementSelector,
  userSelector,
} from 'redux/selectors/authenticationSelectors';
import { AppDispatch } from 'redux/store';

type Props = {
  children: React.ReactNode;
};

export default function OnboardingWrapper(props: Props) {
  const { children } = props;
  const { pathname } = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector(userSelector);
  const isSignAgreement = useSelector(isSignAgreementSelector);
  const onboardingPage = useSelector(onboardingPageSelector);
  const isVisibleOnboardingPage = useSelector(
    isVisibleOnboardingPageSelector(pathname),
  );
  const isOnboardingRunning = useSelector(isOnboardingRunningSelector);
  const isOnboardingManual = useSelector(isOnboardingManualSelector);

  useEffect(() => {
    if (isVisibleOnboardingPage && !isOnboardingManual) {
      startOnboardingByPath(pathname);
    }
  }, [isVisibleOnboardingPage, pathname, isSignAgreement, user]);

  const startOnboardingByPath = (path: string) => {
    switch (path) {
      case '/':
        //It's for the case when user registered and something went wrong
        // during the onboarding after that
        if (isSignAgreement && user?.spaceName)
          dispatch(
            startOnboarding({
              onboardingPage: OnboardingPagesEnum.Home,
              firstStep: HomePageSteps.Projects,
              isManual: false,
            }),
          );
        break;
    }
  };

  const renderOnboardingPage = (page: OnboardingPagesEnum) => {
    switch (page) {
      case OnboardingPagesEnum.Home:
        return <OnboardingHomePage />;
    }
  };

  if (user && isVisibleOnboardingPage)
    return (
      <>
        {isOnboardingRunning && <Onboarding />}
        {onboardingPage && renderOnboardingPage(onboardingPage)}
      </>
    );

  return <>{children}</>;
}
