import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import apiClient, {
  ActivateAccountRequestModel,
  AgreementTypeEnum,
  ForgotPasswordRequestModel,
  LetsGetYouApdatedRequestModel,
  LoginRequestModel,
  OnboardingPagesResponseModel,
  RegistrationRequestModel,
  ResetPasswordRequestModel,
  RestorePasswordRequestModel,
  SendConfirmationRegistrationRequestModel,
  SpaceRequestModel,
  TokenResponseModel,
  TwoFARequestModel,
  TwoFATypeEnum,
  UserResponseModel,
} from 'api';
import localStorage from 'utils/localStorage';
import { currentSpaceIdByStoreSelector } from '../selectors/spacesSelectors';
import { RolesEnum } from 'enums/Roles.enum';
import { getRole, saveToken } from 'utils/authUtils';
import { ErrorType } from 'utils/toastUtils';
import { setDefaultPaymentCard } from 'redux/reducers/accountSlice';

interface AuthState {
  user: UserResponseModel | null;
  isSignAgreement: boolean;
  role: RolesEnum | null;
}

const initialState: AuthState = {
  user: null,
  isSignAgreement: false,
  role: null,
};

interface UserSessionOptions {
  pageSize: number;
  pageNumber: number;
  userId: string;
}

export const getUserSessions = createAsyncThunk(
  'auth/getUserSessions',
  async ({ pageSize, pageNumber, userId }: UserSessionOptions) => {
    return apiClient.getUserSessions(pageSize, pageNumber, userId);
  },
);

export const deactivateUserSession = createAsyncThunk(
  'auth/deactivateUserSession',
  async (sessionId: number) => {
    return apiClient.deactivateUserSession(sessionId);
  },
);

export const deactivateAllUserSessions = createAsyncThunk(
  'auth/deactivateAllUserSessions',
  async () => {
    return apiClient.deactivateAllUserSessions();
  },
);

export const generateTwoFACodeForActivation = createAsyncThunk(
  'auth/generateTwoFACodeForActivation',
  async (twoFATType?: TwoFATypeEnum) => {
    return apiClient.generate2FACodeByUserId(twoFATType);
  },
);

export const generateTwoFACodeForLogin = createAsyncThunk(
  'auth/generateTwoFACodeForLogin',
  async (lastCodeId: string) => {
    return apiClient.generate2FACodeByLastCodeId(lastCodeId);
  },
);

export const enableTwoFA = createAsyncThunk(
  'auth/enableTwoFA',
  async (body: TwoFARequestModel) => {
    return apiClient.enable2FA(body);
  },
);

export const disableTwoFA = createAsyncThunk('auth/disableTwoFA', async () => {
  return apiClient.disable2FA();
});

export const loginWithTwoFA = createAsyncThunk(
  'auth/loginWithTwoFA',
  async (body: TwoFARequestModel, { dispatch }) => {
    const response = await apiClient.loginTwoFA(body);

    saveToken(response);
    await dispatch(checkIsSignedAgreement(AgreementTypeEnum.MasterTerms));
    return response.token;
  },
);

export const sendLetsGetYouApdatedForm = createAsyncThunk(
  'auth/sendLetsGetYouApdatedForm',
  async (data: LetsGetYouApdatedRequestModel) => {
    return apiClient.sendLetsGetYouApdatedForm(data);
  },
);

export const login = createAsyncThunk(
  'auth/login',
  async (
    data: LoginRequestModel,
    { dispatch },
  ): Promise<string | TokenResponseModel> => {
    const response = await apiClient.login(data);

    if (!response.token) return response;

    saveToken(response);

    await dispatch(checkIsSignedAgreement(AgreementTypeEnum.MasterTerms));
    return response.token;
  },
);

export const signUp = createAsyncThunk(
  'auth/signUp',
  async (data: RegistrationRequestModel) => {
    return await apiClient.registration(data);
  },
);

export const sendConfirmationAgain = createAsyncThunk<
  void,
  SendConfirmationRegistrationRequestModel,
  { rejectValue: ErrorType }
>(
  'auth/sendConfirmationAgain',
  async (
    data: SendConfirmationRegistrationRequestModel,
    { rejectWithValue },
  ) => {
    try {
      return await apiClient.sendRegistrationConfirmationAgain(data);
    } catch (e: any) {
      return rejectWithValue(e);
    }
  },
);

export const confirmAccount = createAsyncThunk(
  'auth/confirmAccount',
  async (data: ActivateAccountRequestModel) => {
    return await apiClient.confirmRegistration(data);
  },
);

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (data: ForgotPasswordRequestModel) => {
    return await apiClient.forgotPassword(data);
  },
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (body: ResetPasswordRequestModel) => {
    return apiClient.resetPassword(body);
  },
);

export const restorePassword = createAsyncThunk(
  'auth/restorePassword',
  async (data: RestorePasswordRequestModel) => {
    return await apiClient.restorePassword(data);
  },
);

export const setupSpaceName = createAsyncThunk(
  'auth/setupSpaceName',
  async (data: SpaceRequestModel) => {
    return apiClient.createSpace(data);
  },
);

interface RestorePasswordModel {
  userId: string;
  code: string;
}

export const isValidRestorePasswordLink = createAsyncThunk(
  'auth/isValidRestorePasswordLink',
  async ({ userId, code }: RestorePasswordModel) => {
    return await apiClient.isValidRestorePasswordLink(userId, code);
  },
);

export const getUserInfo = createAsyncThunk(
  'auth/getUserInfo',
  async (spaceId: string | null = null) => {
    return apiClient.getUserProfile(spaceId ?? currentSpaceIdByStoreSelector());
  },
);

export const signAgreement = createAsyncThunk(
  'auth/signAgreement',
  async (agreementId: string) => {
    return await apiClient.signAgreementByUserId({ id: agreementId });
  },
);

export const checkIsSignedAgreement = createAsyncThunk(
  'auth/checkIsSignedAgreement',
  async (agreementType: AgreementTypeEnum) => {
    return await apiClient.isSignedByUserIdAndTypeId(agreementType);
  },
);

export const getAgreementByType = createAsyncThunk(
  'auth/getAgreementByType',
  async (agreementType: AgreementTypeEnum) => {
    return await apiClient.getLastAgreementByType(agreementType);
  },
);

export const logout = createAsyncThunk('auth/logout', async () => {
  return await apiClient.logout();
});

const handleTokenRefresh = async (accessToken: string) => {
  const response = await apiClient.refreshToken({
    accessToken,
  });

  saveToken(response);

  return response;
};

export const refreshToken = createAsyncThunk(
  'auth/refreshToken',
  handleTokenRefresh,
);

const handleLogout = (state: AuthState) => {
  state.user = null;
  state.role = null;

  localStorage?.removeItem('jwt-token');
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setOnboardingPagesChecked: (
      state,
      action: PayloadAction<OnboardingPagesResponseModel>,
    ) => {
      if (!state.user) return;

      state.user.onboardingPages = {
        ...state.user.onboardingPages,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      state.user = action.payload;
      if (action.payload.spaceId) {
        localStorage?.setItem('space', action.payload.spaceId as string);
      }
      state.role = getRole();
    });
    builder.addCase(logout.fulfilled, (state) => {
      handleLogout(state);
    });
    builder.addCase(logout.rejected, (state) => {
      handleLogout(state);
    });
    builder.addCase(getUserInfo.rejected, (state) => {
      handleLogout(state);
    });
    builder.addCase(enableTwoFA.fulfilled, (state) => {
      if (state.user) state.user.is2FAEnabled = true;
    });
    builder.addCase(disableTwoFA.fulfilled, (state) => {
      if (state.user) state.user.is2FAEnabled = false;
    });
    builder.addCase(refreshToken.rejected, (state) => {
      handleLogout(state);
    });
    builder.addCase(checkIsSignedAgreement.fulfilled, (state, action) => {
      state.isSignAgreement = action.payload;
    });
    builder.addCase(signAgreement.fulfilled, (state) => {
      if (state.user) {
        state.isSignAgreement = true;
      }
    });
    builder.addCase(setDefaultPaymentCard.fulfilled, (state, action) => {
      if (state.user) {
        state.user.defaultPaymentMethodId = action.payload;
      }
    });
  },
});

const { actions, reducer } = authSlice;
const { setOnboardingPagesChecked } = actions;

export { setOnboardingPagesChecked };
export default reducer;
