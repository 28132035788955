import React, { Ref } from 'react';
import { Radio } from 'antd';
import { useCallback } from 'react';
import { RadioChangeEvent, RadioGroupProps } from 'antd/lib/radio';
import { ControllerRenderProps, FieldValues } from 'react-hook-form';

type Props = RadioGroupProps & ControllerRenderProps<FieldValues>;

export default React.forwardRef((props: Props, ref: Ref<HTMLDivElement>) => {
  const { onChange: $onChange, ...other } = props;

  const onChange = useCallback((e: RadioChangeEvent) => {
    const value = e.target.value;

    $onChange(value);
  }, []);

  return <Radio.Group {...other} ref={ref} onChange={onChange} />;
});
