import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

export default function useSearchQueryMatch<
  Params extends { [K in keyof Params]?: string } = {},
>(queryValues: string | string[], key: string) {
  const location = useLocation();
  const query = queryString.parse(location.search);

  return queryValues.includes(<string>query[key]);
}
