import { RootState } from 'redux/reducers/rootReducer';

export const restrictionModalStateSelector = (state: RootState) =>
  state.account.restrictionModalState;

export const isNewCreditCardFormDirtySelector = (state: RootState) =>
  state.account.isNewCreditCardFormDirty;

export const isProfileFormDirtySelector = (state: RootState) =>
  state.account.isProfileFormDirty;

export const subscriptionsSelector = (state: RootState) =>
  state.account.subscriptions;

export const isSubscriptionsLoadingSelector = (state: RootState) =>
  state.account.isSubscriptionsLoading;
