import React, { ReactNode, useState } from 'react';
import { Button, Dropdown } from 'antd';
import cx from 'classnames';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'lodash';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

import ImportWithCsvModal from 'components/modals/ImportWithCsvModal';
import { HomePageSteps } from 'enums/OnboardingEnums';
import { RestrictionModalTypeEnum } from 'features/account/AccountSettingsPage/RestrictionModal';
import ProjectModal, { ProjectModalMode } from 'features/project/ProjectModal';
import { useQueryMatch } from 'hooks/useQueryMatch';
import { openRestrictionModal } from 'redux/reducers/accountSlice';
import { activeTourStepSelector } from 'redux/selectors/onboardingSelector';
import { checkProjectsRestriction } from 'redux/reducers/projectSlice';
import { isSuperAdminSelector } from 'redux/selectors/roleSelectors';
import { AppDispatch } from 'redux/store';
import { isHomePage } from 'utils/pathnameUtils';

import 'features/onboarding/Ondoarding.less';

type Props = {
  isProjectButtonVisible?: boolean;
  isTrackerCreateButtonVisible?: boolean;
  handleDisplayProjectModal?: () => void;
  reloadList?: () => void;
  itemRenderer?: ReactNode;
  templateId?: string;
};

export type ParamsProps = {
  projectId: string;
};

export default function ActionsBox(props: Props) {
  const isLightTheme = useSelector(isLightThemeSelector);
  const {
    isProjectButtonVisible = true,
    isTrackerCreateButtonVisible = true,
    handleDisplayProjectModal,
    reloadList,
    itemRenderer,
    templateId,
  } = props;
  const dispatch: AppDispatch = useDispatch();
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const activeTourStep = useSelector(activeTourStepSelector);
  const { pathname } = useLocation();
  const isMobile = useQueryMatch();
  const { projectId } = useParams<ParamsProps>();
  const [isProjectModalVisible, setIsProjectModalVisible] = useState(false);
  const [isCsvModalVisible, setCsvModalVisible] = useState(false);
  const [isProjectCreationChecking, setIsProjectCreationChecking] =
    useState(false);

  const getCreateTrackerUrl = () => {
    if (!isNil(templateId))
      return `/project/${projectId}/tracker-create?templateId=${templateId}`;

    return `/project/${projectId}/tracker-create`;
  };

  const handleAddProjectClick = async () => {
    setIsProjectCreationChecking(true);
    const result = await dispatch(checkProjectsRestriction());
    setIsProjectCreationChecking(false);

    if (
      checkProjectsRestriction.fulfilled.match(result) &&
      !result.payload.isCanCreate
    ) {
      dispatch(
        openRestrictionModal({
          type: RestrictionModalTypeEnum.CreateProject,
        }),
      );
      return;
    }

    setIsProjectModalVisible(true);
  };

  const handleImportWithCsvOpen = () => {
    setCsvModalVisible(true);
  };

  const handleImportWithCsvCancel = (isReloadNeeded: boolean = false) => {
    setCsvModalVisible(false);
    isReloadNeeded && reloadList?.();
  };

  const projectActionsMenu = [
    {
      key: 'edit',
      label: (
        <div onClick={handleDisplayProjectModal}>
          <i className="icon icon-edit" />
          Edit
        </div>
      ),
    },
  ];

  const getProjectActionsMenuDropdown = () => (
    <Dropdown
      menu={{ items: projectActionsMenu }}
      trigger={['click']}
      placement="bottom"
    >
      <Button
        size="large"
        className={cx({ 'dark-btn-outline': !isLightTheme })}
      >
        Options
      </Button>
    </Dropdown>
  );

  return (
    <>
      <ProjectModal
        open={isProjectModalVisible}
        mode={ProjectModalMode.ADD}
        onClose={() => setIsProjectModalVisible(false)}
      />

      <ImportWithCsvModal
        open={isCsvModalVisible}
        onCancel={handleImportWithCsvCancel}
      />

      {isProjectButtonVisible && (
        <>
          <div>
            <Button
              className="with-icon banner-btn"
              size="large"
              onClick={handleImportWithCsvOpen}
            >
              <i className="icon icon-import"></i>
              Import with CSV
            </Button>
          </div>

          <div
            className={cx(
              {
                ['onboardingProject']:
                  isHomePage(pathname) &&
                  activeTourStep === HomePageSteps.Projects,
              },
              {
                onboardingProjectDark: !isLightTheme,
              },
            )}
          >
            <Button
              className="dark-btn-outline"
              loading={isProjectCreationChecking}
              size="large"
              onClick={handleAddProjectClick}
            >
              Add a project
            </Button>
          </div>
        </>
      )}

      {!isNil(itemRenderer) && itemRenderer}

      {isTrackerCreateButtonVisible && (
        <div>
          <Link to={getCreateTrackerUrl()}>
            <Button className="dark-btn" size="large">
              {isMobile ? 'Create a PT' : 'Create a tracker'}
            </Button>
          </Link>
        </div>
      )}
      {!isProjectButtonVisible && isSuperAdmin && (
        <div>{getProjectActionsMenuDropdown()}</div>
      )}
    </>
  );
}
