import React from 'react';
import Pagination, { LandingSectionsEnum } from '../Pagination/Pagination';

import img from 'assets/img/landing/circle.svg';
import line from 'assets/img/landing/line.png';

import stepOne from 'assets/img/landing/notification.svg';
import stepTwo from 'assets/img/landing/infinite.svg';
import stepThree from 'assets/img/landing/API.svg';

import cx from 'classnames';

import styles from './InformYourClientSection.module.less';
import commonStyles from '../../LandingPage/LandingPage.module.less';

export default function InformYourClientSection() {
  return (
    <section
      className={styles.informSection}
      id={LandingSectionsEnum.InformYourClient}
    >
      <Pagination
        className={styles.pagination}
        activeSection={LandingSectionsEnum.InformYourClient}
      />

      <div className={styles.happy}>
        <div className={commonStyles.container}>
          <div className={styles.text}>
            <div className={styles.subText}>An informed client leads to a</div>
            <div className={styles.tagline}>
              <div className={styles.taglineHappy}>
                H<i className="icon icon-triangle"></i>ppy
              </div>
              <div>client</div>
            </div>
            <p>Subscribed clients can get email/SMS alerts for any updates.</p>
          </div>
        </div>
      </div>

      <div className={cx(styles.advantages, commonStyles.container)}>
        <div
          className={cx(
            styles.titleBox,
            commonStyles.title,
            commonStyles.titleDark,
          )}
        >
          <h2>
            <div>Everything you need to keep your </div>
            <span>customers updated</span>
          </h2>
        </div>
        <div className={styles.row}>
          <div className={styles.card}>
            <div className={styles.cardCircle}>
              <img src={stepOne} alt="step one" />
            </div>
            <div className={styles.cardLine}>
              <img src={line} alt="line" />
            </div>
            <div className={styles.cardText}>
              <strong>Step 1</strong>
              <div>Proactive update notifications</div>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.cardCircle}>
              <img src={stepTwo} alt="step two" />
            </div>
            <div className={styles.cardLine}>
              <img src={line} alt="line" />
            </div>
            <div className={styles.cardText}>
              <strong>Step 2</strong>
              <div>Custom progress trackers and status updates</div>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.cardCircle}>
              <img src={stepThree} alt="step three" />
            </div>
            <div className={styles.cardLine}>
              <img src={line} alt="line" />
            </div>
            <div className={styles.cardText}>
              <strong>Step 3</strong>
              <div>Integrations to your systems via API</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
