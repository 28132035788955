import React, {
  BaseSyntheticEvent,
  CSSProperties,
  useMemo,
  useRef,
} from 'react';
import { Button, Input } from 'antd';
import cx from 'classnames';
import { ArrayField, Control, FieldErrors, useWatch } from 'react-hook-form';
import { useDrag, useDrop } from 'react-dnd';

import DatePicker from 'components/DatePicker';
import Controller from 'components/form/Controller';
import {
  StageFormValues,
  stageValidation,
} from 'components/ProgressTracker/ProgressTrackerEdit/ProgressTrackerEdit';
import {
  StageDescriptionRules,
  StageDueDateRules,
  StageNameRules,
} from 'constants/ValidationConstants';
import FileTrackerUploader from 'features/FileTrackerUploader';
import { dateFormat } from 'utils/dateUtils';
import { useSelector } from 'react-redux';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

import styles from './StageEdit.module.less';

export enum StageEditMode {
  ProgressTrackerEdit = 1,
  TemplateEdit = 2,
  TemplateUse = 3,
}

type Props = {
  index: number;
  control: Control<any>;
  field: Partial<ArrayField<StageFormValues, 'key'>>;
  error?: FieldErrors<StageFormValues>;
  touched: boolean;
  onDelete: (index: number) => void;
  fieldName: string;
  mode: StageEditMode;
  onDrop: (indexA: number, indexB: number) => void;
};

export type DragObject = {
  id: string;
  index: number;
};

export default React.memo((props: Props) => {
  const isLightTheme = useSelector(isLightThemeSelector);
  const {
    index,
    control,
    error,
    touched,
    field,
    mode,
    fieldName,
    onDelete,
    onDrop,
  } = props;

  const isTemplatePage = mode === StageEditMode.TemplateEdit;
  const isManualValidation = mode === StageEditMode.TemplateUse;

  const containerRef = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop({
    accept: 'card',
    hover: (item: DragObject) => {
      if (!containerRef.current) return;
      const hoverIndex = index;

      if (index === item.index) return;

      onDrop(item.index, index);
      item.index = hoverIndex;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: {
      id: field.id,
      index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const getStyle = (isDragging: boolean): CSSProperties => ({
    opacity: isDragging ? 0 : 1,
  });

  const stage = useWatch<StageFormValues>({
    control,
    name: fieldName,
  });
  const uploaderRef: any = useRef(null);

  const isValid = useMemo(() => {
    if (!stage) {
      return false;
    }

    return stageValidation.isValidSync(stage);
  }, [stage]);

  const isNewVisible = !touched && field?.isManuallyAdded;
  drag(drop(containerRef));

  const handleOpenFileDialog = () => {
    uploaderRef.current.handleOpenFileDialog();
  };

  const switchDraggable = (isDraggable: boolean) => {
    if (containerRef.current) containerRef.current.draggable = isDraggable;
  };

  return (
    <div className="cards-wrapper">
      <div className="numeration-wrapper">
        <div
          className={cx(
            'numeration',
            {
              inverse: !isLightTheme,
            },
            isValid && !isNewVisible ? 'filled' : 'empty',
          )}
        >
          {index + 1}
        </div>
      </div>
      <div
        className="cards"
        ref={containerRef}
        onDragStart={(event: BaseSyntheticEvent) => {
          if (event.target.tagName === 'INPUT') {
            event.preventDefault();
          }
        }}
        style={getStyle(isDragging)}
      >
        <div>
          {isNewVisible && <div className="cards__label">NEW</div>}

          <div className={styles.row}>
            <div className={styles.rowInner}>
              <Controller
                draggable
                rules={isManualValidation ? StageNameRules : undefined}
                name={`${fieldName}.name`}
                control={control}
                as={Input}
                error={error?.name}
                size="large"
                placeholder="Enter stage name"
                defaultValue={field.name}
                containerClassName="dark-input-group"
              />

              {!isTemplatePage && (
                <Controller
                  draggable
                  rules={isManualValidation ? StageDueDateRules : undefined}
                  onMouseDown={() => switchDraggable(false)}
                  onMouseLeave={() => switchDraggable(true)}
                  name={`${fieldName}.dueDate`}
                  control={control}
                  as={DatePicker}
                  format={dateFormat}
                  error={error?.dueDate}
                  size="large"
                  placeholder="Select date"
                  defaultValue={field.dueDate}
                />
              )}
            </div>

            <div className="cards__btn">
              {!isTemplatePage && (
                <div>
                  <Button type="ghost" onClick={handleOpenFileDialog}>
                    <i className="icon icon-clip" />
                  </Button>
                </div>
              )}
              <div>
                <Button type="ghost" onClick={() => onDelete(index)}>
                  <i className="icon icon-trash-can" />
                </Button>
              </div>
            </div>
          </div>
        </div>

        <Controller
          draggable
          rules={isManualValidation ? StageDescriptionRules : undefined}
          name={`${fieldName}.description`}
          control={control}
          as={Input}
          error={error?.description}
          size="large"
          placeholder="Enter stage description"
          defaultValue={field.description}
          containerClassName="dark-input-group"
        />

        {!isTemplatePage && (
          <div className="upload-wrapper">
            <FileTrackerUploader
              uploaderRef={uploaderRef}
              name={`${fieldName}.attachments`}
              control={control}
              error={error?.attachments}
              defaultValue={field.attachments}
            />
          </div>
        )}
      </div>
    </div>
  );
});
