import React, { useEffect, useState } from 'react';
import {
  FieldError,
  FieldName,
  FieldValues,
  UseFormMethods,
} from 'react-hook-form';
import { Form } from 'antd';
import { isEqual, isNil, isString } from 'lodash';
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';

interface Props<T, B extends FieldValues> {
  component: React.FunctionComponent<T>;
  form: UseFormMethods<B>;
  label: string;
  name: FieldName<B>;
  error?: FieldError | string;
  containerClassName?: string;
}

type EventHandler =
  | StripeCardNumberElementChangeEvent
  | StripeCardExpiryElementChangeEvent
  | StripeCardCvcElementChangeEvent;

const genericMemo: <T extends object, B extends FieldValues>(
  c: T,
  areEqual?: (
    prev: Readonly<React.PropsWithChildren<T & Props<T, B>>>,
    next: Readonly<React.PropsWithChildren<T & Props<T, B>>>,
  ) => boolean,
) => T = React.memo;

export default genericMemo(
  function <T extends object, B extends FieldValues>(props: T & Props<T, B>) {
    const {
      component: $BaseComponent,
      label,
      form,
      name,
      error,
      containerClassName,
      ...otherProps
    } = props;
    const [isFieldEmpty, setFieldEmpty] = useState(true);
    const isErrorString = isString(error);
    const hasError = isErrorString || (error as FieldError)?.message;

    useEffect(() => {
      if (!isFieldEmpty && !hasError) {
        form.clearErrors(name);
      }
    }, [isFieldEmpty, form]);

    const handleChange = (event: EventHandler) => {
      const errorMessage = event.error?.message;
      setFieldEmpty(event.empty);
      form.formState.isDirty = true;

      if (event.empty) {
        return form.setError(name, { message: 'Field is required' });
      }

      if (!isNil(errorMessage)) {
        return form.setError(name, { message: errorMessage });
      }
      form.clearErrors(name);
    };

    const handleBlur = () => {
      isFieldEmpty && form.setError(name, { message: 'Field is required' });
    };

    return (
      <Form.Item
        label={label}
        validateStatus={!hasError ? 'success' : 'error'}
        help={
          hasError && (
            <li>{isErrorString ? error : (error as FieldError)?.message}</li>
          )
        }
        className={containerClassName}
      >
        <$BaseComponent
          {...(otherProps as T)}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Form.Item>
    );
  },
  (prevProps, nextProps) => {
    return (
      isEqual(prevProps.error, nextProps.error) &&
      isEqual(prevProps.form.formState, nextProps.form.formState)
    );
  },
);
