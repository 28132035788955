import { Button, Input, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import cx from 'classnames';
import { useInterval } from 'rooks';

import { AppDispatch } from 'redux/store';
import { ProgressTrackerResponseModel } from 'api';
import { getPTDetailsBySearch } from 'redux/reducers/progressTrackerSlice';
import { DetailsSectionOld } from 'components/DetailsSection';
import ProgressTrackerView from 'components/ProgressTracker/ProgressTrackerView';
import { TabletOrMobileScreenQuery } from 'constants/ScreenQuery';
import { ProgressTrackerModeEnum } from 'enums/ProgressTrackerMode.enum';
import { searchPtName } from 'constants/QueryString';
import { useQueryMatch } from 'hooks/useQueryMatch';
import DetailsButton from 'components/DetailsButton';
import NothingFound from './NothingFound';
import { FetchPTDetailsInterval } from 'constants/ApiConstant';

import styles from './SearchPage.module.less';

const keyCodeEnter = 'Enter';

export default function SearchPage() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [progressTrackerId, setProgressTrackerId] = useState('');
  const [progressTracker, setProgressTracker] =
    useState<ProgressTrackerResponseModel | null>(null);
  const [isNothingFoundVisible, setIsNothingFoundVisible] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isDetailsHidden, setIsDetailsHidden] = useState(false);
  const isTabletOrMobile = useQueryMatch(TabletOrMobileScreenQuery);

  useEffect(() => {
    const queryString = new URLSearchParams(search);
    const id = queryString.get(searchPtName);

    if (!id) {
      setProgressTracker(null);
      return;
    }

    loadDetails(id);
  }, [search]);

  useInterval(
    () => {
      if (!progressTracker) return;
      return loadDetailsToUpdate();
    },
    FetchPTDetailsInterval,
    true,
  );

  const loadDetailsToUpdate = async () => {
    const queryString = new URLSearchParams(search);
    const id = queryString.get(searchPtName);

    if (!id) return;

    const result = await dispatch(getPTDetailsBySearch(id));

    if (!getPTDetailsBySearch.fulfilled.match(result)) {
      setProgressTracker(null);
      return;
    }

    setProgressTracker(result.payload);
  };

  const loadDetails = async (id: string) => {
    setIsNothingFoundVisible(false);
    setProgressTracker(null);

    setIsLoading(true);

    const result = await dispatch(getPTDetailsBySearch(id));
    setIsLoading(false);

    if (!getPTDetailsBySearch.fulfilled.match(result)) {
      setIsNothingFoundVisible(true);

      return;
    }

    setProgressTracker(result.payload);
  };

  const handleClear = () => {
    setProgressTrackerId('');
  };

  const handleSubmit = async () => {
    if (!progressTrackerId) return;

    handleClear();

    const queryString = new URLSearchParams(search);
    queryString.set('ptId', progressTrackerId);

    navigate(`/search?${queryString}`, { replace: true });
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === keyCodeEnter) {
      handleSubmit();
    }
  };

  return (
    <div className="page-wrapper">
      <div className={styles.searchGrid}>
        <div className={styles.searchWrapp}>
          <div className="h1">Hello, what we can find for you?</div>
          <div className={styles.mobileTitle}>
            <div>
              <span>A</span>LWAYS U<span>PDATED</span>
            </div>
            <div className={styles.mobileTitleMirror}>ALWAYS UPDATED</div>
          </div>
          <div className={styles.searchBox}>
            <Input
              value={progressTrackerId}
              placeholder="Search by ID"
              onChange={(e) => setProgressTrackerId(e.target.value)}
              onKeyPress={handleKeyPress}
              prefix={<i className="icon icon-search" />}
            />

            {!!progressTrackerId && (
              <i
                onClick={handleClear}
                className={cx('icon icon-close-square', styles.searchClear)}
              />
            )}

            <Button
              shape="circle"
              size="large"
              className="dark-btn"
              onClick={handleSubmit}
            >
              Go!
            </Button>
          </div>
        </div>
        {isLoading && <Spin />}
        {!isLoading && (
          <>
            {isNothingFoundVisible && <NothingFound />}
            {!isNothingFoundVisible && !!progressTracker && (
              <div className="grid-row">
                <div className="page-inner">
                  <DetailsSectionOld
                    isDetailsVisible={isDetailsHidden}
                    details={progressTracker}
                    mode={ProgressTrackerModeEnum.Public}
                  />
                  {isTabletOrMobile && (
                    <div className={styles.detailsHead}>
                      <div className={styles.detailsHeadName}>
                        {progressTracker.name}
                      </div>
                      <DetailsButton
                        isDetailsHidden={isDetailsHidden}
                        onVisibilityChange={setIsDetailsHidden}
                      />
                    </div>
                  )}
                </div>
                <div className="grid-scroll">
                  <ProgressTrackerView
                    details={progressTracker}
                    mode={ProgressTrackerModeEnum.Public}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
