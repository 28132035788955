import React, { useRef, useState } from 'react';
import { Card, Divider, Form } from 'antd';
import { isEmpty } from 'lodash';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import cx from 'classnames';

import {
  FileParameter,
  ProgressTrackerCreateFromFileResponseModel,
  TrackerCsvErrorModel,
} from 'api';
import AlertMessage from 'components/AlertMessage';
import FormFileUpload from 'components/form/FormFileUpload';
import PTModal, { PTModalProps } from 'components/PTModal';
import { UploadMode } from 'components/wrappers/BaseFileUpload';
import UploadList from 'components/wrappers/BaseFileUpload/UploadList';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import {
  createTrackersFromCsv,
  getCsvTemplateLink,
} from 'redux/reducers/progressTrackerSlice';
import { AppDispatch } from 'redux/store';
import { downloadFile, getFileUploadMessages } from 'utils/fileUtils';

import styles from './ImportWithCsvModal.module.less';

const fileValidation = Yup.object()
  .shape({
    fileName: Yup.string(),
    data: Yup.mixed(),
  })
  .required('This is a required field');

const validationSchema = Yup.object().shape({
  files: Yup.array().of(fileValidation),
});

type FormValues = {
  files: FileParameter[];
};

type Props = {
  onCancel: (isReloadNeeded?: boolean) => void;
} & Omit<PTModalProps, 'onCancel' | 'children'>;

const getSuccessMessage = (countCreated: number, totalCount: number) => {
  return `${countCreated} trackers were successfully created out of ${totalCount} lines.`;
};

const getDoubleCheckMessage = (errors: TrackerCsvErrorModel[]) => {
  const lines = errors.reduce((acc: number[], error) => {
    if (error.lineNumber) acc.push(error.lineNumber);
    return acc;
  }, []);

  if (!lines.length) return '';

  return `Please double-check the following lines in the document to fix errors: ${lines.join(
    ', ',
  )}.`;
};

const upgradePlanMessage =
  'Please upgrade your plan to create more trackers and projects.';

const emptyFileMessage =
  'Uploaded file does not contain any tracker information.';

export default function ImportWithCsvModal(props: Props) {
  const { onCancel, className, open = true, ...otherModalProps } = props;
  const [isLoading, setLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const uploaderRef: any = useRef(null);

  const { handleSubmit, control, errors, watch } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      files: undefined,
    },
    shouldUnregister: false,
    mode: 'all',
  });

  const filesWatch = watch('files');

  const handleDownloadCsvTemplateClick = async () => {
    const result = await dispatch(getCsvTemplateLink());

    if (getCsvTemplateLink.fulfilled.match(result)) {
      const link = result.payload.downloadUrl;
      const fileName = result.payload.fileName;

      await downloadFile({
        downloadUrl: link || '',
        fileName,
        includeFileExtension: true,
      });
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const handleOpenFileDialog = () => {
    uploaderRef.current.handleOpenFileDialog();
  };

  const getMessage = (response: ProgressTrackerCreateFromFileResponseModel) => {
    const { countCreated, totalCount, errors } = response;

    let message: string = '';

    if (countCreated && totalCount) {
      message += getSuccessMessage(countCreated, totalCount);
    }

    if (errors?.length) {
      message += ' ' + getDoubleCheckMessage(errors);

      const subscriptionError = errors.find((e) => e.lineNumber === 0);

      if (subscriptionError) message += ' ' + upgradePlanMessage;
    }

    if (!countCreated && !errors?.length) {
      message = emptyFileMessage;
    }

    return message.trim();
  };

  const onSubmit = async (data: FormValues) => {
    const file = data.files[0];

    setLoading(true);

    const result = await dispatch(createTrackersFromCsv(file));

    if (createTrackersFromCsv.fulfilled.match(result)) {
      const { payload } = result;
      const message = getMessage(payload);
      const isFileEmpty = !payload.countCreated && !payload.errors?.length;

      if (payload.errors?.length || isFileEmpty) {
        !isFileEmpty && onCancel(true);
        AlertMessage.warning(message);
      } else {
        onCancel(true);
        AlertMessage.success(message);
      }
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }

    setLoading(false);
  };

  return (
    <PTModal
      {...otherModalProps}
      open={open}
      title="Import with CSV"
      onCancel={() => onCancel()}
      okText="Upload"
      onOk={handleSubmit(onSubmit)}
      okButtonProps={{
        disabled: !filesWatch || isEmpty(filesWatch),
        loading: isLoading,
      }}
      className={cx('modal-root', styles.modal, className)}
    >
      <Form layout="vertical">
        <div className="modal-scroll-wrapper">
          <div className={styles.list}>
            <div>
              1. Download our {''}
              <span
                className={styles.listBtn}
                onClick={handleDownloadCsvTemplateClick}
              >
                .CSV template
              </span>
              {''} for reference.
            </div>

            <Divider />

            <div>
              <div className={styles.listTitle}>
                2. Fill in the CSV file according to the example on the template
                file. Please keep in mind the following validation rules:
              </div>

              <Card size="small" className={styles.listCard}>
                <div className={styles.listCardItem}>
                  <div>Project name: required; max size: 30</div>
                  <div>Tracker name: required; max size: 120</div>
                  <div>Stage name: required; max size: 30</div>
                  <div>Stage description: required; max size: 280</div>
                </div>
                <div>
                  <span>Note:</span> If there is an error found in one of the
                  lines, the corresponding tracker won't be created.
                </div>
              </Card>
            </div>

            <Divider />

            <div>3. Click Upload and select your CSV file to import it.</div>

            <Divider />

            <div className={styles.listBlock}>
              <div className={styles.listTitle}>
                4. New trackers will appear in the corresponding projects listed
                on your imported CSV file.
              </div>

              <div className={styles.card}>
                <div className={styles.cardBtn}>
                  <i
                    className="icon icon-export"
                    onClick={handleOpenFileDialog}
                  />
                  <span>Choose your CSV</span>
                </div>
                <div className="upload-wrapper">
                  <FormFileUpload
                    name="files"
                    error={errors.files}
                    acceptedFileSize={5}
                    acceptedFileFormat={[
                      '.csv',
                      'text/csv',
                      'application/vnd.ms-excel',
                      'text/x-csv',
                      'text/comma-separated-values',
                      'text/x-comma-separated-values',
                    ]}
                    maxFileCount={1}
                    messages={getFileUploadMessages({ fileTypes: ['CSV'] })}
                    uploaderRef={uploaderRef}
                    getUploadList={UploadList}
                    control={control}
                    mode={UploadMode.ManualDownload}
                  >
                    {() => <></>}
                  </FormFileUpload>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </PTModal>
  );
}
