import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { App, Divider, Select } from 'antd';
import cx from 'classnames';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { AppDispatch } from 'redux/store';
import { getProjects } from 'redux/reducers/projectSlice';
import { moveProgressTracker } from 'redux/reducers/progressTrackerSlice';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { FolderResponseModel } from 'api';
import PTModal, { PTModalProps } from 'components/PTModal';
import Controller from 'components/form/Controller';
import AlertMessage from 'components/AlertMessage';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import { PTDetailsMode, useFetchPTDetails } from 'hooks/useFetchPTDetails';

import styles from './MoveProgressTrackerModal.module.less';

type Props = {
  projectId: string;
  onClose: () => void;
  onMove?: (id: string) => void;
} & Omit<PTModalProps, 'children'>;

interface MoveProgressTrackerFormValues {
  projectId?: string;
}

const validationSchema = Yup.object().shape({
  projectId: Yup.string().required('Field is required'),
});

export default function MoveProgressTrackerModal(props: Props) {
  const {
    onClose,
    onMove = () => {},
    projectId,
    open = true,
    ...otherModalProps
  } = props;
  const [details, _isLoading, loadPTDetails] = useFetchPTDetails(
    projectId,
    PTDetailsMode.Private,
    open,
  );
  const { errors, control, trigger, handleSubmit, formState } =
    useForm<MoveProgressTrackerFormValues>({
      resolver: yupResolver(validationSchema),
      mode: 'all',
      defaultValues: {
        projectId: undefined,
      },
    });
  const { isSubmitting, isDirty } = formState;
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState<FolderResponseModel[]>([]);
  const dispatch: AppDispatch = useDispatch();
  const { modal } = App.useApp();
  const selectContainer: any = useRef();
  const availableProjects = useMemo(
    () => projects.filter((project) => project.id !== details?.folderId),
    [projects, details?.folderId],
  );
  const isLightTheme = useSelector(isLightThemeSelector);

  const loadProjects = async () => {
    const result = await dispatch(getProjects());

    if (!getProjects.fulfilled.match(result)) {
      AlertMessage.error(result.error.message || ApiErrorMessage);
      return;
    }

    setProjects(result.payload);
  };

  useEffect(() => {
    if (!open) return;

    loadProjects();
  }, [open]);

  const handleModalCancel = () => {
    if (isSubmitting) return;

    if (isDirty) {
      return modal.confirm({
        title: 'Changes won’t be saved. Are you sure?',
        icon: <span className="icon icon-info-circle confirm-icon" />,
        className: cx('confirm-modal', {
          'confirm-modal-dark-button': !isLightTheme,
        }),
        width: '496px',
        onOk() {
          onClose();
        },
        onCancel() {},
      });
    }
    onClose();
  };

  const handleMove = () => {
    trigger();
    handleSubmit(onMoveProgressTracker)();
  };

  const onMoveProgressTracker = async ({
    projectId,
  }: MoveProgressTrackerFormValues) => {
    setIsLoading(true);

    const result = await dispatch(
      moveProgressTracker({
        progressTrackerId: details.id as string,
        body: {
          progressTrackerId: details.id as string,
          folderId: projectId,
        },
      }),
    );

    setIsLoading(false);

    if (moveProgressTracker.fulfilled.match(result)) {
      onClose();
      AlertMessage.success(
        'Tracker was successfully moved to a new parent project.',
      );
      onMove(details.id ?? '');
      loadPTDetails();
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  return (
    <PTModal
      {...otherModalProps}
      open={open}
      cancelText="Cancel"
      okText="Move"
      onOk={handleMove}
      onCancel={handleModalCancel}
      okButtonProps={{ loading: isLoading }}
      title="Move tracker"
      closable
      className="modal-root"
    >
      <div ref={selectContainer} className="modal-scroll-wrapper">
        <p>
          Select the new parent project for this tracker from the list below.
        </p>
        <div className={styles.title}>Current project:</div>
        <div className={styles.name}>{details?.folderName}</div>
        <Divider className="dark-divider" />
        <div className="dark-input-group">
          <div className="pseudo-label">Select new project</div>
          <Controller
            name="projectId"
            control={control}
            error={errors.projectId}
            as={
              <Select
                size="large"
                listHeight={160}
                showSearch
                getPopupContainer={() => selectContainer.current}
                optionFilterProp="children"
                onClick={(e) => e.stopPropagation()}
              >
                {availableProjects.map((project) => (
                  <Select.Option key={project.id} value={project.id || ''}>
                    {project.name}
                  </Select.Option>
                ))}
              </Select>
            }
          />
        </div>
      </div>
    </PTModal>
  );
}
