import dayjs from 'dayjs';

import {
  SpaceFolderTrackersCountResponseModel,
  SpaceLastActivityResponseModel,
} from 'api';
import { activityFormat, formatDateForUserTimezone } from './dateUtils';

export interface ProjectChartModel {
  name: string;
  totalCount: number;
}

const mapProjectsInfoToChartModel = (
  data: SpaceFolderTrackersCountResponseModel[],
): ProjectChartModel[] => {
  return data.map((projectInfo) => ({
    name: projectInfo.folderName || '',
    totalCount: projectInfo.trackersCount || 0,
  }));
};

interface GroupedActivities {
  today: SpaceLastActivityResponseModel[];
  yesterday: SpaceLastActivityResponseModel[];
  [formattedDate: string]: SpaceLastActivityResponseModel[] | undefined;
}

const groupActivitiesByDate = (
  lastActivityList: SpaceLastActivityResponseModel[],
) => {
  const groupedActivities: GroupedActivities = {
    today: [],
    yesterday: [],
  };

  const todayDate = dayjs();

  lastActivityList.forEach((activity) => {
    const formattedDate = formatDateForUserTimezone(activity.activityDate);

    const activityDate = dayjs(formattedDate);
    const diffDays = todayDate.diff(activityDate, 'day');

    if (diffDays === 0) {
      groupedActivities.today.push(activity);
    } else if (diffDays === 1) {
      groupedActivities.yesterday.push(activity);
    } else {
      const formattedDate = activityDate.format('MMMM DD, YYYY');
      if (!groupedActivities[formattedDate]) {
        groupedActivities[formattedDate] = [];
      }
      groupedActivities[formattedDate]?.push(activity);
    }
  });

  return groupedActivities;
};

const formatDate = (date?: string | Date) => {
  const formattedDate = formatDateForUserTimezone(date);
  const activityDate = dayjs(formattedDate);
  const now = dayjs();

  if (activityDate.isSame(now, 'day')) {
    return activityDate.fromNow();
  } else {
    return activityDate.format(activityFormat);
  }
};

export { mapProjectsInfoToChartModel, groupActivitiesByDate, formatDate };
