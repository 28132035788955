import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient, {
  ConfirmInviteRequestModel,
  EditUserSpaceRoleRequestModel,
  InviteUserListRequestModel,
} from 'api';
import { currentSpaceIdByStoreSelector } from 'redux/selectors/spacesSelectors';

export const getInfoByInvite = createAsyncThunk(
  'invite/getInfoByInvite',
  async (inviteId: string) => apiClient.getInfoByInvite(inviteId),
);

export const confirmInvite = createAsyncThunk(
  'invite/confirmInvite',
  async (data: ConfirmInviteRequestModel) => apiClient.confirmInvite(data),
);

export const deleteInvitedUser = createAsyncThunk(
  'invite/deleteInvitedUser',
  async (userInviteId: string) => {
    return apiClient.deleteUserFromSpace({
      userInviteId,
      spaceId: currentSpaceIdByStoreSelector(),
    });
  },
);

export const changeSpaceRole = createAsyncThunk(
  'invite/changeSpaceRole',
  async (body: EditUserSpaceRoleRequestModel) => {
    return apiClient.updateUserSpaceRole(body);
  },
);

interface getInvitedUsersArguments {
  pageSize?: number;
  pageCount: number;
}

export const getInvitedUsers = createAsyncThunk(
  'invite/getInvitedUsers',
  async ({ pageCount, pageSize = 20 }: getInvitedUsersArguments) =>
    apiClient.getInvitationList(
      currentSpaceIdByStoreSelector(),
      pageSize,
      pageCount,
    ),
);

export const getRolesToInvite = createAsyncThunk(
  'invite/getRolesToInvite',
  async () => apiClient.getRolesToInvite(),
);

export const getLeftoverInvitations = createAsyncThunk(
  'invite/getLeftoverInvitations',
  async () => apiClient.checkInviteRestriction(currentSpaceIdByStoreSelector()),
);

export const inviteUserAgain = createAsyncThunk(
  'invite/inviteUserAgain',
  async (inviteId: string) => apiClient.inviteUserAgain(inviteId),
);

export interface InvitesSubscriptionOptions {
  inviteQuantity: number;
  priceId?: string;
}

export const addInvitesSubscription = createAsyncThunk(
  'invite/addInvitesSubscription',
  async ({ priceId, inviteQuantity }: InvitesSubscriptionOptions) =>
    apiClient.addInvitesSubscription(
      currentSpaceIdByStoreSelector(),
      inviteQuantity,
      priceId,
    ),
);

export const inviteNewUsers = createAsyncThunk(
  'invite/inviteNewUsers',
  async (data: InviteUserListRequestModel) => {
    return apiClient.inviteNewUsers(data);
  },
);
