import { RootState } from 'redux/reducers/rootReducer';

export const isProgressTrackerFormDirtySelector = (state: RootState) =>
  state.progressTracker.isProgressTrackerFormDirty;

export const logoUrlSelector = (state: RootState) =>
  state.progressTracker.customLogoUrl;

export const isCustomLogoLoadingSelector = (state: RootState) =>
  state.progressTracker.isCustomLogoLoading;

export const canCreateSelector = (state: RootState) =>
  state.progressTracker.trackerRestrictions?.isCanCreate;

export const availableCountToCreateSelector = (state: RootState) =>
  state.progressTracker.trackerRestrictions?.availableCountToCreate;

export const isTrackerRestrictionsLoadingSelector = (state: RootState) =>
  state.progressTracker.isTrackerRestrictionsLoading;
