import React, { useCallback, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Divider, Form, Input, Spin } from 'antd';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import queryString from 'query-string';

import { AppDispatch } from 'redux/store';
import Controller from 'components/form/Controller';
import {
  isValidRestorePasswordLink,
  restorePassword,
} from 'redux/reducers/authenticationSlice';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import AlertMessage from 'components/AlertMessage';
import PasswordValidationRulesBox from 'components/PasswordValidationRulesBox';
import { PasswordValidationRules } from 'constants/ValidationConstants';

import commonStyles from '../auth.module.less';
import imgLogo from 'assets/img/landing/apdated-logo.svg';

interface FormValues {
  password: string;
}

const requiredMessage = 'Field is required';

const validationSchema = Yup.object({
  password: Yup.string()
    .trim()
    .required(requiredMessage)
    .matches(PasswordValidationRules.MinimumCharacters)
    .matches(PasswordValidationRules.MaximumCharacters)
    .matches(PasswordValidationRules.LowercaseCharacter)
    .matches(PasswordValidationRules.SpecialCharacter)
    .matches(PasswordValidationRules.UppercaseCharacter)
    .matches(PasswordValidationRules.OneNumber),
});

export default function ResetPasswordPage() {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { uuid, code } = queryString.parse(location.search);
  const isUrlValid = !!uuid && !!code;
  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, control, errors, watch, formState } =
    useForm<FormValues>({
      resolver: yupResolver(validationSchema),
      mode: 'all',
      defaultValues: {
        password: '',
      },
    });
  const { isDirty } = formState;

  const checkIsLinkValid = useCallback(async () => {
    if (!isUrlValid) return;

    setIsLoading(true);

    const result = await dispatch(
      isValidRestorePasswordLink({
        code: code as string,
        userId: uuid as string,
      }),
    );

    if (isValidRestorePasswordLink.fulfilled.match(result)) {
      if (!result.payload) {
        AlertMessage.error('Link expired.');
        navigate('/login', { replace: true });
        return;
      }

      setIsLoading(false);
    } else {
      navigate('/login', { replace: true });
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  }, [uuid, code, dispatch, isUrlValid]);

  useEffect(() => {
    checkIsLinkValid();
  }, [checkIsLinkValid]);

  const onSubmit = async ({ password }: FormValues) => {
    const result = await dispatch(
      restorePassword({
        code: code as string,
        userId: uuid as string,
        password,
      }),
    );

    if (restorePassword.fulfilled.match(result)) {
      navigate('/login', { replace: true });
      AlertMessage.success(
        'Your password has been changed. Please log in to continue.',
      );
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  if (isLoading) return <Spin size="large" />;

  if (!isUrlValid) {
    return <h3 className="no-data-msg">Incorrect URL</h3>;
  }

  const watchPasswordField = watch('password');

  return (
    <div className={commonStyles.authPage}>
      <div className={commonStyles.authWrap}>
        <div className={commonStyles.authRow}>
          <div className={commonStyles.authForm}>
            <div className={commonStyles.authFormWrap}>
              <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
                <div className={commonStyles.authFormBox}>
                  <div className={commonStyles.authFormLogo}>
                    <img src={imgLogo} alt="logo" />
                  </div>
                  <div className={commonStyles.authFormTitle}>
                    Create a new password
                  </div>

                  <Controller
                    name="password"
                    control={control}
                    as={Input.Password}
                    label="New password"
                    size="large"
                    error={errors.password}
                    showValidateError={
                      errors.password?.message === requiredMessage
                    }
                    containerClassName="dark-input-group"
                  />

                  <Divider className="dark-divider mt-0" />

                  <PasswordValidationRulesBox
                    passwordValue={watchPasswordField}
                  />

                  <div className={commonStyles.authBtnBox}>
                    <div className={commonStyles.authBtn}>
                      <Button htmlType="submit" type="primary" block>
                        Confirm
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
