import React, { useEffect, useState } from 'react';
import { App, Button, Dropdown, Pagination, Spin } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import { useNavigate } from 'react-router-dom';

import EditInvitedUserModal, {
  InviteEditeUserModalMode,
} from '../../EditInvitedUserModal';
import {
  InviteStatusEnum,
  SubscriptionTypeEnum,
  UserSpaceInvitationResponseModel,
  UserSpaceRoleEnum,
} from 'api';
import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { confirmIcon } from 'constants/ConfirmationConstants';
import { DesktopScreenQuery } from 'constants/ScreenQuery';
import { SettingsTabEnum } from 'constants/SettingsConstants';
import { useQueryMatch } from 'hooks/useQueryMatch';
import {
  deleteInvitedUser,
  getInvitedUsers,
  inviteUserAgain,
} from 'redux/reducers/inviteSlice';
import { AppDispatch } from 'redux/store';
import InviteUsersModal, {
  InviteUsersFormValues,
} from '../../InviteUsersModal';
import { userSelector } from 'redux/selectors/authenticationSelectors';
import { subscriptionsSelector } from 'redux/selectors/aссountSelectors';
import { onLoadSubscriptions } from 'utils/subscriptionUtils';

import styles from './UserManagementTab.module.less';
import noTeammates from 'assets/img/teammates.png';

type Props = {
  handleLicensePaymentSectionView: (values: InviteUsersFormValues) => void;
};

function UserManagementTab(props: Props) {
  const { handleLicensePaymentSectionView } = props;
  const user = useSelector(userSelector);
  const isLightTheme = useSelector(isLightThemeSelector);
  const dispatch: AppDispatch = useDispatch();
  const { modal } = App.useApp();
  const [invitedUsers, setInvitedUsers] = useState<
    UserSpaceInvitationResponseModel[]
  >([]);
  const navigate = useNavigate();
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const subscriptions = useSelector(subscriptionsSelector);
  const [isInvitedUsersLoaded, setInvitedUsersLoaded] = useState(false);
  const [inviteEditUserModalMode, setInviteEditUserModalMode] =
    useState<InviteEditeUserModalMode>();
  const [invitedUser, setInvitedUser] =
    useState<UserSpaceInvitationResponseModel>();
  const hasNoInvitedUsers = !invitedUsers.length && isInvitedUsersLoaded;
  const hasAnyInvitedUser = !!invitedUsers.length && isInvitedUsersLoaded;
  const basicSubscription = subscriptions?.find(
    (s) => s.type === SubscriptionTypeEnum.Basic,
  );

  const fetchInvitedUsers = async (pageCount: number) => {
    const result = await dispatch(
      getInvitedUsers({ pageCount: pageCount - 1 }),
    );
    if (getInvitedUsers.fulfilled.match(result)) {
      setInvitedUsers(result.payload.details || []);
      setTotalCount(result.payload.totalCount || 0);
      setPageNumber(pageCount);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
    setInvitedUsersLoaded(true);
  };

  const isMobile = useQueryMatch(DesktopScreenQuery);

  useEffect(() => {
    fetchInvitedUsers(1);
  }, []);

  const inviteAgain = async (inviteId: string) => {
    const result = await dispatch(inviteUserAgain(inviteId));

    if (inviteUserAgain.fulfilled.match(result)) {
      AlertMessage.success('You successfully resent invite.');
    } else {
      AlertMessage.error(result.error?.message || ApiErrorMessage);
    }
  };

  const openCantCreateInviteConfirm = () => {
    modal.confirm({
      title:
        'You have reached the limit of users in your space. Please upgrade your space subscription.',
      icon: <span className="icon icon-info-circle confirm-icon" />,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      width: '496px',
      okText: 'Upgrade subscription',
      onOk() {
        navigate(`/billing-settings?section=${SettingsTabEnum.Pricing}`);
      },
      onCancel() {},
    });
  };

  const handleInviteUserClick = () => {
    if (user?.subscriptionId === basicSubscription?.id)
      return openCantCreateInviteConfirm();

    setInviteEditUserModalMode(InviteEditeUserModalMode.Invite);
  };

  const handleEditSpaceRoleClick = (
    invitedUser: UserSpaceInvitationResponseModel,
  ) => {
    setInvitedUser(invitedUser);
    setInviteEditUserModalMode(InviteEditeUserModalMode.Edit);
  };

  const onInviteEditModalClose = () => {
    setInviteEditUserModalMode(undefined);
    setInvitedUser(undefined);
  };

  const getStatusText = (status: InviteStatusEnum) => {
    switch (status) {
      case InviteStatusEnum.Active:
        return 'Active';
      case InviteStatusEnum.Pending:
        return 'Pending';
    }
  };

  useEffect(() => {
    onLoadSubscriptions();
  }, []);

  const handleDeleteUser = async (invitedUserId: string) => {
    return modal.confirm({
      title: 'Are you sure you want to delete this user?',
      icon: confirmIcon,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      async onOk() {
        await onDeleteUser(invitedUserId);
      },
      onCancel() {},
      okText: 'Yes',
    });
  };

  const onDeleteUser = async (invitedUserId: string) => {
    const result = await dispatch(deleteInvitedUser(invitedUserId));

    if (deleteInvitedUser.fulfilled.match(result)) {
      if (invitedUsers.length === 1 && pageNumber !== 1) {
        setPageNumber(pageNumber - 1);
        await fetchInvitedUsers(pageNumber - 1);
      } else {
        await fetchInvitedUsers(pageNumber);
      }
      AlertMessage.success(
        'The user was successfully deleted from your space.',
      );
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const onInviteEditSucceed = async (
    mode: InviteEditeUserModalMode,
    newRoleId?: UserSpaceRoleEnum,
    newRoleName?: string,
  ) => {
    if (mode === InviteEditeUserModalMode.Invite) {
      await fetchInvitedUsers(1);
    } else {
      if (!invitedUser) return;

      const invitedUserIndex = invitedUsers.indexOf(invitedUser);

      const newInvitedUsers = [...invitedUsers];

      newInvitedUsers[invitedUserIndex] = {
        ...invitedUser,
        roleId: newRoleId,
        roleName: newRoleName,
      };

      setInvitedUsers(newInvitedUsers);
    }
  };

  const getActions = (user: UserSpaceInvitationResponseModel) => {
    const actions: ItemType[] = [
      {
        key: 'delete',
        label: (
          <>
            <i className="icon icon-trash-can" /> Delete
          </>
        ),
        onClick: () => handleDeleteUser(user.id || ''),
      },
    ];

    if (user.status === InviteStatusEnum.Active) {
      actions.unshift({
        key: 'edit',
        label: (
          <>
            <i className="icon icon-pencil" /> Edit
          </>
        ),
        onClick: () => handleEditSpaceRoleClick(user),
      });
    } else {
      actions.unshift({
        key: 'resend',
        label: (
          <>
            <i className="icon icon-unarchived" /> Resend
          </>
        ),
        onClick: () => inviteAgain(user.id || ''),
      });
    }

    return actions;
  };

  return (
    <>
      <div
        className={cx('banner', {
          inverse: !isLightTheme,
        })}
      >
        <div className="banner__left">
          <div>
            <div className="banner__name mb-8">User management</div>
            <div>Manage access for all users under your account.</div>
          </div>
        </div>

        <InviteUsersModal
          open={inviteEditUserModalMode === InviteEditeUserModalMode.Invite}
          onClose={onInviteEditModalClose}
          onSuccess={(values) => {
            handleLicensePaymentSectionView(values);
            onInviteEditModalClose();
          }}
        />

        <EditInvitedUserModal
          open={inviteEditUserModalMode === InviteEditeUserModalMode.Edit}
          onSuccess={onInviteEditSucceed}
          onClose={onInviteEditModalClose}
          mode={inviteEditUserModalMode}
          invitedUser={invitedUser}
        />

        <div className="banner__actions">
          <div>
            <Button
              onClick={handleInviteUserClick}
              className={cx({
                'dark-btn': isLightTheme,
                'ant-btn-primary': !isLightTheme,
              })}
              size="large"
            >
              Invite users
            </Button>
          </div>
        </div>
      </div>

      {!isInvitedUsersLoaded && <Spin />}

      {hasNoInvitedUsers && (
        <div className="noData">
          <div className="image">
            <img src={noTeammates} alt="no-invited-users" />
          </div>
          <div className="title">
            Teamwork divides tasks and multiplies success.
          </div>
          <div className="text">
            Click on the
            <Button
              onClick={handleInviteUserClick}
              type="link"
              className={styles.btn}
            >
              Invite users
            </Button>
            button to add users.
          </div>
        </div>
      )}

      {hasAnyInvitedUser && (
        <div
          className={
            isMobile ? cx('modal-scroll-wrapper', styles.tab) : styles.tab
          }
        >
          <div className={styles.tableHead}>
            <div className={cx(styles.tableRow, styles.xsNone)}>
              <div className={styles.tableCol}>Name</div>
              <div className={styles.tableCol}>Email</div>
              <div className={styles.tableCol}>Role</div>
              <div className={styles.tableCol}>Status</div>
              <div className={cx(styles.tableCol, styles.actions)}>Actions</div>
            </div>
            <div className={cx(styles.tableRow, styles.lgNone)}>
              <div className={styles.tableCol}>Users</div>
            </div>
          </div>
          <div className={styles.tableBody}>
            {invitedUsers.map((user) => {
              const { id, firstName, lastName, inviteEmail, roleName, status } =
                user;

              return (
                <div className={styles.tableRow} key={id}>
                  <div className={styles.tableCol}>
                    {`${firstName ?? ''} ${lastName ?? ''}`}
                  </div>
                  <div className={styles.tableCol}>{inviteEmail}</div>
                  <div className={cx(styles.tableCol, styles.role)}>
                    {roleName}
                  </div>
                  <div
                    className={cx(styles.tableCol, {
                      [styles.active]: status === InviteStatusEnum.Active,
                      [styles.pending]: status === InviteStatusEnum.Pending,
                    })}
                  >
                    {getStatusText(status as InviteStatusEnum)}
                  </div>
                  <div className={cx(styles.tableCol, styles.actions)}>
                    <Dropdown
                      menu={{
                        items: getActions(user),
                      }}
                      trigger={['click']}
                      placement="bottom"
                    >
                      <Button type="ghost">
                        {' '}
                        <i className="icon icon-dots" />
                      </Button>
                    </Dropdown>
                  </div>
                </div>
              );
            })}
          </div>

          <div className={styles.pagination}>
            <Pagination
              current={pageNumber}
              pageSize={20}
              total={totalCount}
              onChange={fetchInvitedUsers}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default React.memo(UserManagementTab);
