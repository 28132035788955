import React, { useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

import { AdminUserInfoResponseModel } from 'api';
import AlertMessage from 'components/AlertMessage';
import Breadcrumb from 'components/Breadcrumb';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import CommentsSection from './CommentsSection';
import { getUser } from 'redux/reducers/userSlice';
import { AppDispatch } from 'redux/store';
import {
  getDateForUserTimezone,
  NEXT_BILLING_DATE_DATE_FORMAT,
} from 'utils/dateUtils';

import classes from 'features/analytics/AnalyticsSection/AnalyticsSection.module.less';

type Params = {
  id: string;
};

enum SubscriptionStatuses {
  Active = 'Active',
  Suspended = 'Suspended',
}

export default function UserDetailsPage() {
  const isLightTheme = useSelector(isLightThemeSelector);
  const [userDetails, setUserDetails] = useState<AdminUserInfoResponseModel>();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams<Params>();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const pageScrollRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    if (!id) return navigate('/users');

    setIsLoading(true);
    const result = await dispatch(getUser(id));

    if (getUser.fulfilled.match(result)) {
      setUserDetails(result.payload);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }

    setIsLoading(false);
  };

  const getStatusClassName = (subscriptionStatus: string) => {
    switch (subscriptionStatus) {
      case SubscriptionStatuses.Active:
        return classes.active;
      case SubscriptionStatuses.Suspended:
        return classes.suspended;
    }
  };

  if (isLoading) return <Spin />;

  if (!userDetails) return null;

  return (
    <div className="page-wrapper scroll-box">
      <div className="page-box page-holder" ref={pageScrollRef}>
        <Breadcrumb
          routes={[
            {
              title: <i className="icon icon-home" />,
              link: '/',
            },
            {
              title: 'Users',
              link: '/users',
            },
            {
              title: `${userDetails.firstName} ${userDetails.lastName}`,
            },
          ]}
        />

        <div
          className={cx('banner', {
            inverse: !isLightTheme,
          })}
        >
          <div className="banner__left">
            <div className="banner__profile">
              <div className="banner__image">
                {userDetails.spaceLogoUrl ? (
                  <img
                    width={58}
                    height={58}
                    src={userDetails.spaceLogoUrl}
                    alt="Company logo"
                  />
                ) : (
                  <i className="icon icon-profile" />
                )}
              </div>
              <div className="banner__content">
                <div className="name">
                  {userDetails.firstName} {userDetails.lastName}
                </div>
                <div>
                  <div className="item">
                    <span>Email:</span>
                    <a href={`mailto:${userDetails.email}`}>
                      {userDetails.email}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.list}>
          <div className={classes.wrapper}>
            <div className={classes.box}>
              <div className={classes.title}>General</div>
            </div>
            <div className={cx(classes.tableBox, classes.minHeight)}>
              <table
                className={cx(classes.table, {
                  [classes.inverse]: !isLightTheme,
                })}
              >
                <thead>
                  <tr>
                    <th className={classes.tableHead}>Company name</th>
                  </tr>
                </thead>
                <tr>
                  <td className={classes.tableCell}>{userDetails.spaceName}</td>
                </tr>
              </table>
            </div>
          </div>
          <div className={classes.wrapper}>
            <div className={classes.box}>
              <div className={classes.title}>Billing</div>
            </div>
            <div className={cx(classes.tableBox, classes.minHeight)}>
              <table
                className={cx(classes.table, {
                  [classes.inverse]: !isLightTheme,
                })}
              >
                <thead>
                  <tr>
                    <th className={classes.tableHead}>Plan</th>
                    <th className={classes.tableHead}>Status</th>
                    <th className={classes.tableHead}>Next Renewal Date</th>
                    <th className={classes.tableHead}>Registration Date</th>
                    <th className={classes.tableHead}>Last Login Date</th>
                  </tr>
                </thead>
                <tr>
                  <td className={classes.tableCell}>
                    {userDetails.subscriptionName}
                  </td>
                  <td
                    className={cx(
                      classes.tableCell,
                      getStatusClassName(userDetails.subscriptionStatus || ''),
                    )}
                  >
                    {userDetails.subscriptionStatus || '-'}
                  </td>
                  <td className={classes.tableCell}>
                    {userDetails.nextPaymentDate
                      ? getDateForUserTimezone(
                          userDetails?.nextPaymentDate,
                          NEXT_BILLING_DATE_DATE_FORMAT,
                        )
                      : '-'}
                  </td>
                  <td className={classes.tableCell}>
                    {getDateForUserTimezone(
                      userDetails.createdDate,
                      NEXT_BILLING_DATE_DATE_FORMAT,
                    )}
                  </td>
                  <td className={classes.tableCell}>
                    {userDetails.lastLoginDate
                      ? getDateForUserTimezone(
                          userDetails?.lastLoginDate,
                          NEXT_BILLING_DATE_DATE_FORMAT,
                        )
                      : '-'}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div className={classes.wrapper}>
            <div className={classes.box}>
              <div className={classes.title}>Account Summary</div>
            </div>
            <div className={cx(classes.tableBox, classes.minHeight)}>
              <table
                className={cx(classes.table, {
                  [classes.inverse]: !isLightTheme,
                })}
              >
                <thead>
                  <tr>
                    <th className={classes.tableHead}>Spaces</th>
                    <th className={classes.tableHead}>Projects</th>
                    <th className={classes.tableHead}>Trackers</th>
                    <th className={classes.tableHead}>Templates</th>
                    <th className={classes.tableHead}>Invited Users</th>
                    <th className={classes.tableHead}>Lifetime Spend</th>
                  </tr>
                </thead>
                <tr>
                  <td className={classes.tableCell}>
                    {userDetails.spaces?.length}
                  </td>
                  <td className={classes.tableCell}>
                    {userDetails.numberOfCreatedProjects}
                  </td>
                  <td className={classes.tableCell}>
                    {userDetails.numberOfCreatedTrackers}
                  </td>
                  <td className={classes.tableCell}>
                    {userDetails.numberOfCreatedTemplates}
                  </td>
                  <td className={classes.tableCell}>
                    {userDetails.numberOfInvitedUsers || '-'}
                  </td>
                  <td className={cx(classes.tableCell, classes.suspended)}>
                    $ {userDetails.lifetimeSpend}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <CommentsSection scrollRef={pageScrollRef} />
      </div>
    </div>
  );
}
