import { Form } from 'antd';
import cx from 'classnames';
import { isString } from 'lodash';
import {
  Controller as $Controller,
  ControllerProps,
  FieldError,
  FieldValues,
} from 'react-hook-form';

export type FormControllerProps<
  TAs extends
    | React.ReactElement
    | React.ComponentType<any>
    | 'input'
    | 'select'
    | 'textarea' = any,
  TFieldValues extends FieldValues = FieldValues,
> = ControllerProps<TAs, TFieldValues> & {
  error?: FieldError | string;
  message?: string;
  label?: string;
  containerClassName?: string;
  showValidateError?: boolean;
};

export default function Controller(props: FormControllerProps) {
  const {
    error,
    touched,
    label,
    containerClassName,
    message,
    showValidateError = true,
    ...other
  } = props;
  const isErrorString = isString(error);
  const hasError = isErrorString || (error as FieldError)?.message;
  const isMessageVisible = !!message || (hasError && showValidateError);
  const errorMessage = isErrorString ? error : (error as FieldError)?.message;
  const validateStatus = hasError && showValidateError ? 'error' : 'success';

  return (
    <Form.Item
      validateStatus={validateStatus}
      help={
        isMessageVisible && (
          <li className={cx({ ['success']: validateStatus === 'success' })}>
            {message || errorMessage}
          </li>
        )
      }
      label={label}
      className={containerClassName}
    >
      <$Controller {...other} />
    </Form.Item>
  );
}
