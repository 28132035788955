import { UserListResponseModel } from 'api';
import { useSelector } from 'react-redux';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import cx from 'classnames';

type Props = {
  user: UserListResponseModel;
};

export default function UserCard(props: Props) {
  const isLightTheme = useSelector(isLightThemeSelector);
  const { user } = props;
  return (
    <div
      className={cx('ticket ticket--full', {
        inverse: !isLightTheme,
      })}
    >
      <div className="ticket__name">{user.spaceName}</div>

      <div className="ticket__main">
        <div className="ticket__inner">
          <div>
            <div className="ticket__item">
              <span>First name: </span>
              {user.firstName}
            </div>

            <div className="ticket__item">
              <span>Last name: </span>
              {user.lastName}
            </div>
          </div>

          <div>
            <div className="ticket__item">
              <span>Email: </span>
              {user.email}
            </div>
            <div className="ticket__item">
              <span>Current plan: </span>
              {user.currentPlan}
            </div>
          </div>
        </div>

        <div>
          {/* <Button type="ghost">
            <i className="icon icon-dots" />
          </Button> */}
        </div>
      </div>
    </div>
  );
}
