import React from 'react';

import { IS_PRODUCTION_ENV } from 'constants/env.constants';
import { SubscriptionTableData } from 'features/auth/BillingSettingsPage/PricingTab/PricingTab';

const checkIcon = React.createElement('i', { className: 'icon icon-checkbox' });

export const MAX_CARD_NUMBER = 3;

export const featureList: SubscriptionTableData[] = [
  {
    featureName: null,
    basic: null,
    standard: null,
    title: 'TRACKERS',
    advanced: null,
    enterprise: null,
  },
  {
    featureName: 'Create',
    basic: 5,
    standard: 50,
    advanced: 200,
    enterprise: 'Unlimited',
  },
  {
    featureName: 'Read',
    basic: checkIcon,
    standard: checkIcon,
    advanced: checkIcon,
    enterprise: checkIcon,
  },
  {
    featureName: 'Update',
    basic: checkIcon,
    standard: checkIcon,
    advanced: checkIcon,
    enterprise: checkIcon,
  },
  {
    featureName: 'Delete',
    basic: checkIcon,
    standard: checkIcon,
    advanced: checkIcon,
    enterprise: checkIcon,
  },
  {
    featureName: 'Move',
    basic: checkIcon,
    standard: checkIcon,
    advanced: checkIcon,
    enterprise: checkIcon,
  },
  {
    featureName: 'Subscribe email address',
    basic: checkIcon,
    standard: checkIcon,
    advanced: checkIcon,
    enterprise: checkIcon,
  },
  {
    featureName: 'Subscribe SMS',
    basic: checkIcon,
    standard: checkIcon,
    advanced: checkIcon,
    enterprise: checkIcon,
  },
  {
    featureName: 'Archive',
    basic: checkIcon,
    standard: checkIcon,
    advanced: checkIcon,
    enterprise: checkIcon,
  },
  {
    featureName: 'Import via .CSV file',
    basic: checkIcon,
    standard: checkIcon,
    advanced: checkIcon,
    enterprise: checkIcon,
  },
  {
    featureName: null,
    basic: null,
    standard: null,
    title: 'PROJECTS',
    advanced: null,
    enterprise: null,
  },
  {
    featureName: 'Create',
    basic: 1,
    standard: 5,
    advanced: 10,
    enterprise: 'Unlimited',
  },
  {
    featureName: 'Edit',
    basic: checkIcon,
    standard: checkIcon,
    advanced: checkIcon,
    enterprise: checkIcon,
  },
  {
    featureName: null,
    basic: null,
    standard: null,
    title: 'TEMPLATES',
    advanced: null,
    enterprise: null,
  },
  {
    featureName: 'Create',
    basic: null,
    standard: checkIcon,
    advanced: checkIcon,
    enterprise: checkIcon,
  },
  {
    featureName: 'Use',
    basic: null,
    standard: checkIcon,
    advanced: checkIcon,
    enterprise: checkIcon,
  },
  {
    featureName: 'Update',
    basic: null,
    standard: checkIcon,
    advanced: checkIcon,
    enterprise: checkIcon,
  },
  {
    featureName: 'Delete',
    basic: null,
    standard: checkIcon,
    advanced: checkIcon,
    enterprise: checkIcon,
  },
  {
    featureName: null,
    basic: null,
    standard: null,
    title: 'SPACE USERS',
    advanced: null,
    enterprise: null,
  },
  {
    featureName: 'Users',
    basic: 1,
    standard: 'Up to 2 users',
    advanced: 'Up to 5 users',
    enterprise: '10+ users',
  },
  {
    featureName: 'Invite users',
    basic: null,
    standard: checkIcon,
    advanced: checkIcon,
    enterprise: checkIcon,
  },
  {
    featureName: 'Manage (switch roles, delete)',
    basic: null,
    standard: checkIcon,
    advanced: checkIcon,
    enterprise: checkIcon,
  },
  {
    featureName: null,
    basic: null,
    standard: null,
    title: 'SPACE NOTIFICATIONS',
    advanced: null,
    enterprise: null,
  },
  {
    featureName: 'Email',
    basic: checkIcon,
    standard: checkIcon,
    advanced: checkIcon,
    enterprise: checkIcon,
  },
  {
    featureName: 'Text',
    basic: null,
    standard: checkIcon,
    advanced: checkIcon,
    enterprise: checkIcon,
  },
  {
    featureName: 'WhatsApp',
    basic: null,
    standard: checkIcon,
    advanced: checkIcon,
    enterprise: checkIcon,
  },
  {
    featureName: null,
    basic: null,
    standard: null,
    title: 'API INTEGRATION',
    advanced: null,
    enterprise: null,
  },
  {
    featureName: React.createElement('a', {
      href: IS_PRODUCTION_ENV
        ? 'https://api.apdated.co/external/api-docs/index.html'
        : 'https://api-develop.apdated.co/external/api-docs/index.html',
      children: 'Whole action list included into API',
      title: 'Whole action list included into API',
    }),
    basic: null,
    standard: null,
    advanced: checkIcon,
    enterprise: checkIcon,
  },
  {
    featureName: null,
    basic: null,
    standard: null,
    title: 'ARCHIVE',
    advanced: null,
    enterprise: null,
  },
  {
    featureName: 'Archive',
    basic: checkIcon,
    standard: checkIcon,
    advanced: checkIcon,
    enterprise: checkIcon,
  },
  {
    featureName: 'Restore',
    basic: checkIcon,
    standard: checkIcon,
    advanced: checkIcon,
    enterprise: checkIcon,
  },
  {
    featureName: null,
    basic: null,
    standard: null,
    title: 'STORAGE FOR DELETED ITEMS',
    advanced: null,
    enterprise: null,
  },
  {
    featureName: 'Delete (move deleted items to temporary storage)',
    basic: checkIcon,
    standard: checkIcon,
    advanced: checkIcon,
    enterprise: checkIcon,
  },
  {
    featureName: 'Restore deleted items',
    basic: checkIcon,
    standard: checkIcon,
    advanced: checkIcon,
    enterprise: checkIcon,
  },
  {
    featureName: 'Temporary storage duration',
    basic: '7 days',
    standard: '7 days',
    advanced: '30 days',
    enterprise: '90 days',
  },
  {
    featureName: null,
    basic: null,
    standard: null,
    title: 'WHITE-LABEL TRACKERS (COMING SOON)',
    advanced: null,
    enterprise: null,
  },
  {
    featureName: 'Customize tracker widget (background, fonts, logo, etc.)',
    basic: 'TBA',
    standard: 'TBA',
    advanced: 'TBA',
    enterprise: 'TBA',
  },
  {
    featureName: 'Branded notifications',
    basic: null,
    standard: checkIcon,
    advanced: checkIcon,
    enterprise: checkIcon,
  },
  {
    featureName: 'Use custom trackers on 3rd-party resources',
    basic: 'TBA',
    standard: 'TBA',
    advanced: 'TBA',
    enterprise: 'TBA',
  },
];
