import React, { useMemo, useState } from 'react';
import { Col, Row } from 'antd';
import { isArray, isNil, unset } from 'lodash';
import queryString from 'query-string';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { AppDispatch } from 'redux/store';
import {
  archiveTracker,
  restoreDeletedTracker,
} from 'redux/reducers/projectSlice';
import DeletedTrackerCard from './DeletedTrackerCard';
import {
  ArchivedProgressTrackerCardModel,
  CompletenessFilterEnum,
  SortFieldEnum,
  SortOrderEnum,
} from 'api';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import AlertMessage from 'components/AlertMessage';
import SearchInput from 'components/filters/SearchInput';
import CompletenessFilter from 'components/filters/CompletenessFilter';
import SortingFilter, {
  SortingFilterValueType,
  SortingMode,
} from 'components/filters/SortingFilter/SortingFilter';
import SearchLayout, {
  ChildrenProps,
  SearchLayoutMode,
} from 'components/search/SearchLayout';

enum DeletedTrackersFiltersTypes {
  TrackerName = 'trackerName',
  Sorting = 'sorting',
  Completeness = 'completeness',
}

interface DeletedTrackersFilters {
  [DeletedTrackersFiltersTypes.TrackerName]: string;
  [DeletedTrackersFiltersTypes.Sorting]: SortingFilterValueType;
  [DeletedTrackersFiltersTypes.Completeness]: CompletenessFilterEnum;
}

export default function DeletedTrackersPage() {
  const [isFilterUsed, setIsFilterUsed] = useState(false);
  const [isInitInfScrollNeeded, setIsInitInfScrollNeeded] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const { trackerName, sortField, sortOrder, completeness } = queryString.parse(
    location.search,
  );

  const initialValues: DeletedTrackersFilters = {
    [DeletedTrackersFiltersTypes.TrackerName]:
      !isArray(trackerName) && !isNil(trackerName) ? trackerName : '',
    [DeletedTrackersFiltersTypes.Sorting]: {
      sortField:
        !isArray(sortField) && !isNil(sortField)
          ? +sortField
          : SortFieldEnum.Name,
      sortOrder:
        !isArray(sortOrder) && !isNil(sortOrder)
          ? +sortOrder
          : SortOrderEnum.Asc,
    },
    [DeletedTrackersFiltersTypes.Completeness]:
      !isArray(completeness) && !isNil(completeness)
        ? +completeness
        : CompletenessFilterEnum.All,
  };
  const [filters, setFilters] = useState<DeletedTrackersFilters>(initialValues);

  const initInfScroll = () => setIsInitInfScrollNeeded(true);

  const enableInitInfScroll = () => setIsInitInfScrollNeeded(false);

  const onChangeCompletenessFilter = (value: CompletenessFilterEnum) => {
    setFilters({
      ...filters,
      [DeletedTrackersFiltersTypes.Completeness]: value,
    });
    setIsFilterUsed(true);
  };

  const handleSearch = (query: string) => {
    setFilters({
      ...filters,
      [DeletedTrackersFiltersTypes.TrackerName]: query,
    });
  };

  const handleSort = (sortParams: SortingFilterValueType) => {
    setFilters({
      ...filters,
      [DeletedTrackersFiltersTypes.Sorting]: sortParams,
    });
  };

  const onRestoreTracker = async (trackerId: string) => {
    const result = await dispatch(restoreDeletedTracker(trackerId));

    if (restoreDeletedTracker.fulfilled.match(result)) {
      initInfScroll();
      AlertMessage.success('The tracker was successfully restored.');
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const onArchiveTracker = async (trackerId?: string) => {
    const result = await dispatch(archiveTracker(trackerId || ''));

    if (archiveTracker.fulfilled.match(result)) {
      initInfScroll();
      AlertMessage.success('The tracker was successfully archived.');
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const renderSearchInput = () => (
    <SearchInput
      onChange={handleSearch}
      value={filters[DeletedTrackersFiltersTypes.TrackerName]}
      placeholder="Search trackers"
    />
  );

  const renderFilters = () => (
    <>
      <div>
        <SortingFilter
          value={filters[DeletedTrackersFiltersTypes.Sorting]}
          onChange={handleSort}
          mode={SortingMode.Projects}
        />
      </div>
      <div>
        <CompletenessFilter
          value={filters[DeletedTrackersFiltersTypes.Completeness]}
          onChange={onChangeCompletenessFilter}
        />
      </div>
    </>
  );

  const renderData = (
    childrenProps: ChildrenProps<ArchivedProgressTrackerCardModel, any>,
  ) => {
    return (
      <Row gutter={[20, 20]}>
        {childrenProps.details.map((tracker) => (
          <Col md={12} xs={24} key={tracker.id}>
            <DeletedTrackerCard
              tracker={tracker}
              onArchiveTracker={onArchiveTracker}
              onRestoreTracker={onRestoreTracker}
            />
          </Col>
        ))}
      </Row>
    );
  };

  const resultFilters = useMemo(() => {
    const newFilters = {
      query: filters[DeletedTrackersFiltersTypes.TrackerName],
      ...filters,
    };
    unset(newFilters, [DeletedTrackersFiltersTypes.TrackerName]);
    return newFilters;
  }, [filters]);

  return (
    <SearchLayout
      mode={SearchLayoutMode.DeletedTrackers}
      filters={resultFilters}
      columns={2}
      isFilterUsed={isFilterUsed}
      isInitInfScrollNeeded={isInitInfScrollNeeded}
      enableInitInfScroll={enableInitInfScroll}
      searchNode={renderSearchInput()}
      filtersNode={renderFilters()}
    >
      {renderData}
    </SearchLayout>
  );
}
