export type EnumValue = string | number;
// enum can be numeric, string or heterogeneous
export type Enum<T> = Record<keyof T, any> & { [k: number]: string };

export class EnumHelpers {
  public static getEnumStringKeys<T extends Enum<T>>(enumObj: T) {
    return Object.keys(enumObj).filter((key) =>
      Number.isNaN(parseInt(key, 10)),
    );
  }

  public static getEnumValues<T extends Enum<T>, S extends EnumValue>(
    enumObj: T,
  ): Array<S> {
    const keys = this.getEnumStringKeys(enumObj);

    // @ts-ignore
    return keys.map((key: string) => enumObj[key]);
  }

  public static getNextEnumValue<T extends Enum<T>, S extends EnumValue>(
    enumObj: T,
    enumValue: S,
  ): S {
    const values = this.getEnumValues<T, S>(enumObj);
    const indexOfEnumValue = values.findIndex((value) => value === enumValue);

    return values[indexOfEnumValue + 1];
  }
}
