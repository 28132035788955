import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import apiClient, { BillingCycleEnum } from 'api';
import { currentSpaceIdByStoreSelector } from 'redux/selectors/spacesSelectors';

interface GetAdminTransactionsRequestModel {
  pageNumber?: string;
  pageSize: number;
}

interface GetPriceDetailsRequestModel {
  priceId: string;
  promotionCode?: string;
  defaultPaymentMethod?: string;
  quantity?: number;
  requestedBillingCycle?: BillingCycleEnum;
  isUpgradeRequested?: boolean;
  isInviteRequested?: boolean;
}

export const getAdminTransactions = createAsyncThunk(
  'transaction/getAdminTransactions',
  async ({ pageNumber, pageSize }: GetAdminTransactionsRequestModel) => {
    return apiClient.getPaymentsByAdmin(pageSize, pageNumber);
  },
);

export const exportTransactionsToExcel = createAsyncThunk(
  'transaction/exportTransactionsToExcel',
  async () => {
    return apiClient.exportPaymentsExcelByAdmin();
  },
);

export const getPriceDetails = createAsyncThunk(
  'transaction/getPriceDetails',
  async ({
    priceId,
    promotionCode,
    defaultPaymentMethod,
    quantity,
    isInviteRequested,
    isUpgradeRequested,
    requestedBillingCycle,
  }: GetPriceDetailsRequestModel) => {
    return apiClient.getSumDescriptionUpcomingSubscription(
      priceId,
      promotionCode,
      currentSpaceIdByStoreSelector(),
      defaultPaymentMethod,
      isUpgradeRequested,
      isInviteRequested,
      quantity,
      requestedBillingCycle,
    );
  },
);

const transactionSlice = createSlice({
  name: 'transaction',
  initialState: {},
  reducers: {},
});

const { reducer } = transactionSlice;
export default reducer;
