import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import apiClient, {
  MessageHistorySortFieldEnum,
  MessageStatusEnum,
  MessageTypeEnum,
  SortOrderEnum,
} from 'api';
import { currentSpaceIdByStoreSelector } from 'redux/selectors/spacesSelectors';
import { PageDefaultSize } from 'constants/ApiConstant';
import { MessageTypesEnum } from 'features/analytics/AnalyticsSection/AnalyticsSection';
import dayjs from 'dayjs';

interface ProjectDashboardRequestBody {
  projectId: string;
  pageSize: number;
}

interface DashboardState {}

const initialState: DashboardState = {};

export const getOverviewDashboardData = createAsyncThunk(
  'dashboard/getOverviewDashboardData',
  async (pageSize: number) => {
    return apiClient.getOverviewDashboard(pageSize);
  },
);

export const getProjectDashboardData = createAsyncThunk(
  'dashboard/getProjectDashboardData',
  async ({ projectId, pageSize }: ProjectDashboardRequestBody) => {
    return apiClient.getProjectOverviewDashboard(projectId, pageSize);
  },
);

export interface GetEmailSmsAnalyticsRequest {
  sortField: MessageHistorySortFieldEnum;
  sortOrder: SortOrderEnum;
  messageStatus?: MessageStatusEnum;
  messageSubject?: MessageTypesEnum[];
  projectId?: string;
  from?: Date;
  to?: Date;
  search: string;
  messageType: MessageTypeEnum;
  pageNumber: number;
}

export type ExportMessageLogsRequestModel = Omit<
  GetEmailSmsAnalyticsRequest,
  'pageNumber'
>;

export const exportMessageLogs = createAsyncThunk(
  'dashboard/exportMessageLogs',
  async ({
    sortField,
    sortOrder,
    messageStatus,
    messageSubject,
    messageType,
    search,
    from,
    to,
    projectId,
  }: ExportMessageLogsRequestModel) => {
    const tz = dayjs().utcOffset() / 60;
    return apiClient.exportMessagesToExcel(
      currentSpaceIdByStoreSelector(),
      sortField,
      sortOrder,
      messageStatus,
      from,
      to,
      messageSubject,
      projectId,
      search,
      messageType,
      undefined,
      undefined,
      tz,
    );
  },
);

export const getEmailSmsAnalytics = createAsyncThunk(
  'dashboard/getEmailSmsAnalytics',
  async ({
    sortField,
    sortOrder,
    messageStatus,
    messageSubject,
    projectId,
    messageType,
    pageNumber,
    search,
    from,
    to,
  }: GetEmailSmsAnalyticsRequest) => {
    return apiClient.getMessagesBySpaceOrUserId(
      currentSpaceIdByStoreSelector(),
      sortField,
      sortOrder,
      messageStatus,
      from,
      to,
      messageSubject,
      projectId,
      search,
      messageType,
      PageDefaultSize,
      pageNumber,
    );
  },
);

interface GetProjectChart {
  pageSize: number;
  pageNumber: number;
}

export const getSpaceFoldersAnalytics = createAsyncThunk(
  'dashboard/getSpaceFoldersAnalytics',
  async ({ pageNumber, pageSize }: GetProjectChart) => {
    return apiClient.getSpaceFoldersAnalytics(
      pageSize,
      pageNumber,
      currentSpaceIdByStoreSelector(),
    );
  },
);

export const getSpaceCountersAnalytics = createAsyncThunk(
  'dashboard/getSpaceCountersAnalytics',
  async () => {
    return apiClient.getSpaceCountersAnalytics(currentSpaceIdByStoreSelector());
  },
);

interface SpaceLastActivityOptions {
  pageSize: number;
  pageNumber: number;
}
export const getSpaceLastActivity = createAsyncThunk(
  'dashboard/getSpaceCountersAnalytics',
  async ({ pageNumber, pageSize }: SpaceLastActivityOptions) => {
    return apiClient.getSpaceLastActivity(
      pageSize,
      pageNumber,
      currentSpaceIdByStoreSelector(),
    );
  },
);

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
});

const { reducer } = dashboardSlice;

export default reducer;
