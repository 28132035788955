import React, { useCallback, useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  LabelList,
  ResponsiveContainer,
} from 'recharts';
import { Button, Spin, Col } from 'antd';
import pluralize from 'pluralize';
import { ceil, maxBy } from 'lodash';
import { useDidUpdate } from 'rooks';

import { useDispatch } from 'react-redux';
import { getSpaceFoldersAnalytics } from 'redux/reducers/dashboardSlice';
import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { PageDefaultSize } from 'constants/ApiConstant';
import { AppDispatch } from 'redux/store';
import {
  mapProjectsInfoToChartModel,
  ProjectChartModel,
} from 'utils/dashboardUtils';

import styles from './ProjectsChart.module.less';

export default function ProjectsChart() {
  const [isInitialLoaded, setIsInitialLoaded] = useState(false);
  const [projects, setProjects] = useState<ProjectChartModel[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const maxTotalCount = maxBy(projects, 'totalCount')?.totalCount || 0;
  const maxYDomain =
    maxTotalCount % 2 === 0 ? maxTotalCount : maxTotalCount + 1;
  const isFirstPage = currentPage === 1;
  const totalPages = ceil(totalCount / PageDefaultSize);
  const isLastPage = currentPage === totalPages;

  const dispatch: AppDispatch = useDispatch();

  const projectsCount = pluralize('project', totalCount || 0, false);

  const loadProjectsChart = useCallback(async (pageNumber: number) => {
    const result = await dispatch(
      getSpaceFoldersAnalytics({
        pageNumber: pageNumber - 1,
        pageSize: PageDefaultSize,
      }),
    );

    if (getSpaceFoldersAnalytics.fulfilled.match(result)) {
      setTotalCount(result.payload.totalCount || 0);
      setProjects(mapProjectsInfoToChartModel(result.payload.details || []));
      setCurrentPage(pageNumber);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  }, []);

  useDidUpdate(() => {
    loadProjectsChart(currentPage);
  }, [currentPage]);

  useEffect(() => {
    initLoadProjectsChart();
  }, []);

  const initLoadProjectsChart = useCallback(async () => {
    await loadProjectsChart(1);
    setIsInitialLoaded(true);
  }, []);

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    const totalPages = Math.ceil(totalCount / PageDefaultSize);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderLegend = () => {
    return (
      <div className={styles.chartFooter}>
        <div className={styles.chartLabel}>Number of clients</div>
        <div className={styles.chartBox}>
          <div className={styles.btn}>
            <Button onClick={handlePrev} disabled={isFirstPage}>
              <i className="icon icon-arrow-circle-left" />
            </Button>
          </div>
          <div className={styles.btn}>
            <Button onClick={handleNext} disabled={isLastPage}>
              <i className="icon icon-arrow-circle-right" />
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const CustomXAxisTick = ({ x, y, payload }: any) => {
    const name = payload.value;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
          {name}
        </text>
      </g>
    );
  };

  if (!isInitialLoaded) return <Spin />;

  const fillProjectsToPageSize = (projects: ProjectChartModel[]) => {
    const projectsLength = projects.length;

    if (projectsLength < PageDefaultSize) {
      const emptyItems = Array(PageDefaultSize - projectsLength).fill({});
      return [...projects, ...emptyItems];
    }
    return projects;
  };

  return (
    <Col xs={24}>
      <div className={styles.chart}>
        <div className={styles.chartTitle}>
          <h1>Projects</h1>
          <div className={styles.chartProjects}>
            <div>{totalCount}</div>
            <div>{projectsCount}</div>
          </div>
        </div>

        <ResponsiveContainer width="100%" height="80%">
          <BarChart height={300} data={fillProjectsToPageSize(projects)}>
            <defs>
              <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#5A7EF2" />
                <stop offset="100%" stopColor="#C15AF2" />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="none" opacity={0.3} />
            <XAxis dataKey="name" tick={<CustomXAxisTick />}/>
            <YAxis domain={[0, maxYDomain]} interval={1} />
            <Legend content={renderLegend} />
            <Bar dataKey="totalCount" fill="url(#gradient)" radius={[4, 4, 0, 0]}>
              <LabelList dataKey="totalCount" position="top" />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Col>
  );
}
