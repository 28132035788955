import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Form, Input, Spin } from 'antd';
import { uniqueId } from 'lodash';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import cx from 'classnames';

import {
  ProgressTrackerCommentResponseModel,
  ProgressTrackerCommentResponseModelPagedResponseModel,
} from 'api';
import AlertMessage from 'components/AlertMessage';
import Controller from 'components/form/Controller';
import NoInitialData from 'components/NoInitialData';
import SubmitButton from 'components/SubmitButton';
import { PageDefaultSize } from 'constants/ApiConstant';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { trackerCommentDateFormat } from 'constants/TrackerConstants';
import { requiredMessage } from 'constants/ValidationConstants';
import { ParamsType } from 'features/progressTracker/ProgressTrackerDetailsPage';
import {
  addCommentToTracker,
  AddCommentToTrackerModel,
  getTrackerComments,
  getTrackerCommentsForAdmin,
  GetTrackerCommentsModel,
} from 'redux/reducers/progressTrackerSlice';
import {
  isAdminSelector,
  userSelector,
} from 'redux/selectors/authenticationSelectors';
import { AppDispatch } from 'redux/store';
import { DispatchedRequestType } from 'utils/searchUtils';
import classes from 'features/admin/UserDetailsPage/CommentsSection/CommentSection.module.less';
import { getDateForUserTimezone } from 'utils/dateUtils';

const commentValidation = Yup.object().shape({
  comment: Yup.string()
    .max(200, 'Comment should be 200 characters maximum')
    .required(requiredMessage)
    .trim(),
});

type CommentForm = {
  comment: string;
};

type Props = {
  scrollRef: RefObject<HTMLDivElement>;
};

export default function CommentsSection(props: Props) {
  const { scrollRef } = props;
  const [comments, setComments] = useState<
    ProgressTrackerCommentResponseModel[]
  >([]);
  const [hasMore, setHasMore] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
  const user = useSelector(userSelector);
  const isAdmin = useSelector(isAdminSelector);
  const { progressTrackerId } = useParams<ParamsType>();
  const latestRequestRef = useRef<DispatchedRequestType>();
  const dispatch: AppDispatch = useDispatch();

  const { control, handleSubmit, formState, reset } = useForm<CommentForm>({
    resolver: yupResolver(commentValidation),
    mode: 'all',
    defaultValues: { comment: '' },
  });
  const { errors } = formState;

  useEffect(() => {
    initInfinityScroll();
  }, []);

  const initInfinityScroll = () => {
    setIsVisible(false);
    setComments([]);
    setIsInitialDataLoaded(false);
    setHasMore(true);
    setTotalCount(0);

    const timer = setTimeout(() => {
      // to destroy infinite scroll
      setIsVisible(true);
    }, 0);

    return () => clearTimeout(timer);
  };

  const onSetData = useCallback(
    (payload: ProgressTrackerCommentResponseModelPagedResponseModel) => {
      const loadedData = payload.details || [];
      const totalCount = payload.totalCount || 0;
      const newData = [...comments, ...loadedData];
      const hasMoreRows =
        !!loadedData.length &&
        !!newData.length &&
        newData.length < totalCount &&
        loadedData.length > 0;

      setHasMore(hasMoreRows);
      setComments(newData);
      setTotalCount(totalCount);
    },
    [comments],
  );

  const onLoadMore = useCallback(
    async (pageNumber: number) => {
      latestRequestRef.current?.abort?.();

      const requestBody: GetTrackerCommentsModel = {
        pageSize: PageDefaultSize,
        trackerId: progressTrackerId || '',
        pageNumber,
      };

      const action = isAdmin ? getTrackerCommentsForAdmin : getTrackerComments;

      const request = dispatch(action(requestBody));

      latestRequestRef.current = request;
      const result = await request;
      if (action.fulfilled.match(result)) {
        onSetData(result.payload);
      } else {
        if (result.meta.aborted) return;

        setHasMore(false);
        AlertMessage.error(result.error.message || ApiErrorMessage);
      }

      setIsInitialDataLoaded(true);
    },
    [
      dispatch,
      getTrackerComments,
      getTrackerCommentsForAdmin,
      onSetData,
      progressTrackerId,
      latestRequestRef,
    ],
  );

  const onSubmit = async (values: CommentForm) => {
    const requestBody: AddCommentToTrackerModel = {
      trackerId: progressTrackerId || '',
      body: {
        comment: values.comment,
        userId: user?.id,
      },
    };

    const result = await dispatch(addCommentToTracker(requestBody));

    if (addCommentToTracker.fulfilled.match(result)) {
      initInfinityScroll();
      reset();
      AlertMessage.success('The comment successfully added.');
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  return (
    <div>
      <Form onFinish={handleSubmit(onSubmit)}>
        <Controller
          name="comment"
          control={control}
          error={errors.comment}
          showValidateError={errors.comment?.type !== 'required'}
          containerClassName={cx(classes.input, 'dark-input-group')}
          render={(field) => (
            <Input
              {...field}
              placeholder="Add a comment"
              suffix={<SubmitButton formState={formState}>Add</SubmitButton>}
            />
          )}
        />
      </Form>

      {isInitialDataLoaded && !comments.length && (
        <NoInitialData text={'There are no comments for this tracker.'} />
      )}

      {isVisible && (
        <InfiniteScroll
          initialLoad={!isInitialDataLoaded}
          loadMore={onLoadMore}
          maxLength={totalCount}
          pageStart={-1}
          hasMore={hasMore}
          loader={<Spin key={uniqueId()} />}
          useWindow={false}
          getScrollParent={() => scrollRef.current}
          threshold={-100}
          className={cx({
            [classes.wrapper]: isInitialDataLoaded && comments.length,
          })}
        >
          {comments.map((comment) => (
            <div key={comment.id} className={classes.item}>
              <strong>
                {comment.firstName} {comment.lastName}
              </strong>{' '}
              {getDateForUserTimezone(
                comment.createdDate,
                trackerCommentDateFormat,
              )}
              <p>{comment.comment}</p>
            </div>
          ))}
        </InfiniteScroll>
      )}
    </div>
  );
}
