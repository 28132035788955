import React, { useMemo, useState } from 'react';
import { App, Col, Row } from 'antd';
import cx from 'classnames';
import { isArray, isNil, unset } from 'lodash';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'redux/store';
import { unArchiveTracker } from 'redux/reducers/projectSlice';
import ArchivedTrackerCard from './ArchivedTrackerCard';
import {
  ArchivedProgressTrackerCardModel,
  CompletenessFilterEnum,
  SortFieldEnum,
  SortOrderEnum,
} from 'api';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import AlertMessage from 'components/AlertMessage';
import { deleteProgressTracker } from 'redux/reducers/progressTrackerSlice';
import SearchInput from 'components/filters/SearchInput';
import CompletenessFilter from 'components/filters/CompletenessFilter';
import SortingFilter, {
  SortingFilterValueType,
  SortingMode,
} from 'components/filters/SortingFilter';
import SearchLayout, {
  ChildrenProps,
  SearchLayoutMode,
} from 'components/search/SearchLayout';
import {
  confirmIcon,
  deleteTrackerTitle,
} from 'constants/ConfirmationConstants';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import { userSelector } from 'redux/selectors/authenticationSelectors';
import { getDaysToDelete } from 'utils/confirmationUtils';

enum ArchivedTrackersFiltersTypes {
  TrackerName = 'trackerName',
  Sorting = 'sorting',
  Completeness = 'completeness',
}

interface ArchivedTrackersFilters {
  [ArchivedTrackersFiltersTypes.TrackerName]: string;
  [ArchivedTrackersFiltersTypes.Sorting]: SortingFilterValueType;
  [ArchivedTrackersFiltersTypes.Completeness]: CompletenessFilterEnum;
}

export default function ArchivedTrackersPage() {
  const [isInitInfScrollNeeded, setIsInitInfScrollNeeded] = useState(false);
  const [isFilterUsed, setIsFilterUsed] = useState(false);
  const user = useSelector(userSelector);
  const isLightTheme = useSelector(isLightThemeSelector);
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const { modal } = App.useApp();
  const { trackerName, sortField, sortOrder, completeness } = queryString.parse(
    location.search,
  );

  const initialValues: ArchivedTrackersFilters = {
    [ArchivedTrackersFiltersTypes.TrackerName]:
      !isArray(trackerName) && !isNil(trackerName) ? trackerName : '',
    [ArchivedTrackersFiltersTypes.Sorting]: {
      sortField:
        !isArray(sortField) && !isNil(sortField)
          ? +sortField
          : SortFieldEnum.Name,
      sortOrder:
        !isArray(sortOrder) && !isNil(sortOrder)
          ? +sortOrder
          : SortOrderEnum.Asc,
    },
    [ArchivedTrackersFiltersTypes.Completeness]:
      !isArray(completeness) && !isNil(completeness)
        ? +completeness
        : CompletenessFilterEnum.All,
  };
  const [filters, setFilters] =
    useState<ArchivedTrackersFilters>(initialValues);

  const onUnArchiveTrackerProgressTracker = async (trackerId: string) => {
    const result = await dispatch(unArchiveTracker(trackerId));

    if (unArchiveTracker.fulfilled.match(result)) {
      AlertMessage.success(
        'Tracker was successfully unarchived to its project.',
      );
      setIsInitInfScrollNeeded(true);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const handleUnArchiveClick = (trackerId: string) => {
    return modal.confirm({
      title: 'Tracker will be unarchived to its project. Continue?',
      icon: <span className="icon icon-info-circle confirm-icon" />,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      onOk: async () => {
        await onUnArchiveTrackerProgressTracker(trackerId);
      },
      okText: 'Yes',
      onCancel() {},
    });
  };

  const onDeleteProgressTracker = async (trackerId?: string) => {
    const result = await dispatch(deleteProgressTracker(trackerId || ''));

    if (deleteProgressTracker.fulfilled.match(result)) {
      setIsInitInfScrollNeeded(true);
      AlertMessage.success('Tracker was successfully deleted.');
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const handleDeleteProgressTracker = async (trackerId: string) => {
    const daysToDelete = getDaysToDelete(user?.subscriptionType);

    return modal.confirm({
      title: deleteTrackerTitle(daysToDelete),
      icon: confirmIcon,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      async onOk() {
        await onDeleteProgressTracker(trackerId);
      },
      onCancel() {},
      okText: 'Yes',
    });
  };

  const onChangeCompletenessFilter = (value: CompletenessFilterEnum) => {
    setFilters({
      ...filters,
      [ArchivedTrackersFiltersTypes.Completeness]: value,
    });
    setIsFilterUsed(true);
  };

  const handleSearch = (query: string) => {
    setFilters({
      ...filters,
      [ArchivedTrackersFiltersTypes.TrackerName]: query,
    });
  };

  const handleSort = (sortParams: SortingFilterValueType) => {
    setFilters({
      ...filters,
      [ArchivedTrackersFiltersTypes.Sorting]: sortParams,
    });
  };

  const enableInitInfScroll = () => setIsInitInfScrollNeeded(false);

  const renderArchivedTrackers = (
    childrenProps: ChildrenProps<ArchivedProgressTrackerCardModel, any>,
  ) => {
    return (
      <Row gutter={[20, 20]}>
        {childrenProps.details.map((tracker) => (
          <Col md={12} xs={24} key={tracker.id}>
            <ArchivedTrackerCard
              tracker={tracker}
              handleDeleteClick={handleDeleteProgressTracker}
              handleUnArchiveClick={handleUnArchiveClick}
            />
          </Col>
        ))}
      </Row>
    );
  };

  const renderSearchInput = () => (
    <SearchInput
      onChange={handleSearch}
      value={filters[ArchivedTrackersFiltersTypes.TrackerName]}
      placeholder="Search trackers"
    />
  );

  const renderFilters = () => (
    <>
      <div>
        <SortingFilter
          value={filters[ArchivedTrackersFiltersTypes.Sorting]}
          onChange={handleSort}
          mode={SortingMode.Projects}
        />
      </div>
      <div>
        <CompletenessFilter
          value={filters[ArchivedTrackersFiltersTypes.Completeness]}
          onChange={onChangeCompletenessFilter}
        />
      </div>
    </>
  );

  const resultFilters = useMemo(() => {
    const newFilters = {
      query: filters[ArchivedTrackersFiltersTypes.TrackerName],
      ...filters,
    };
    unset(newFilters, [ArchivedTrackersFiltersTypes.TrackerName]);
    return newFilters;
  }, [filters]);

  return (
    <SearchLayout
      mode={SearchLayoutMode.Archive}
      filters={resultFilters}
      isFilterUsed={isFilterUsed}
      isInitInfScrollNeeded={isInitInfScrollNeeded}
      enableInitInfScroll={enableInitInfScroll}
      searchNode={renderSearchInput()}
      filtersNode={renderFilters()}
      columns={2}
    >
      {renderArchivedTrackers}
    </SearchLayout>
  );
}
