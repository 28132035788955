import React from 'react';
import { Col, Row } from 'antd';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

import { CompletenessFilterEnum, SortFieldEnum, SortOrderEnum } from 'api';
import ActionsBox from 'components/ActionsBox';
import Breadcrumb from 'components/Breadcrumb';
import CompletenessFilter from 'components/filters/CompletenessFilter';
import SearchInput from 'components/filters/SearchInput';
import SortingFilter, { SortingMode } from 'components/filters/SortingFilter';
import { HOME_PAGE_ONOBOARDING_PROJECTS } from 'constants/OnboardingConstants';
import { detailsSectionId } from 'constants/SearchConstants';
import { HomePageSteps } from 'enums/OnboardingEnums';
import ProjectCard from 'features/project/ProjectCard/ProjectCard';
import { activeTourStepSelector } from 'redux/selectors/onboardingSelector';
import 'features/onboarding/Ondoarding.less';

export default function OnboardingHomePage() {
  const activeTourStep = useSelector(activeTourStepSelector);
  const isLightTheme = useSelector(isLightThemeSelector);

  return (
    <div className="page-wrapper scroll-box ">
      <div className="page-box page-holder">
        <span id={detailsSectionId}>
          <Breadcrumb routes={[{ title: <i className="icon icon-home" /> }]} />

          <div
            className={cx('banner', {
              inverse: !isLightTheme,
            })}
          >
            <div className="banner__left">
              <div className="banner__count">
                {HOME_PAGE_ONOBOARDING_PROJECTS.length}
              </div>
              <div className="banner__name">Projects</div>
            </div>
            <div className="banner__actions">
              <ActionsBox isTrackerCreateButtonVisible={false} />
            </div>
          </div>

          <div className="filters-actions">
            <Row gutter={[20, 20]}>
              <Col xs={24} sm={12}>
                <div className="filters-actions__wrap">
                  <SearchInput
                    onChange={() => {}}
                    value={''}
                    placeholder="Search projects"
                  />
                </div>
              </Col>

              <Col xs={24} sm={12}>
                <div className="filters-actions__drop">
                  {' '}
                  <div>
                    <SortingFilter
                      value={{
                        sortField: SortFieldEnum.Name,
                        sortOrder: SortOrderEnum.Asc,
                      }}
                      onChange={() => {}}
                      mode={SortingMode.Projects}
                    />
                  </div>
                  <div
                    className={cx(
                      {
                        ['completenessOnboarding']:
                          activeTourStep === HomePageSteps.Filters,
                      },
                      {
                        completenessOnboardingDark: !isLightTheme,
                      },
                    )}
                  >
                    <CompletenessFilter
                      value={CompletenessFilterEnum.All}
                      onChange={() => {}}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </span>

        <div>
          <Row gutter={[20, 20]}>
            {HOME_PAGE_ONOBOARDING_PROJECTS.map((project, index) => (
              <Col md={12} xs={24} key={index}>
                <ProjectCard project={project} />
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
}
