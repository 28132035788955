import React, { useCallback } from 'react';
import { App, Avatar, Button } from 'antd';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';

import { UserGroupUserResponseModel } from 'api';
import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { DesktopScreenQuery } from 'constants/ScreenQuery';
import { useQueryMatch } from 'hooks/useQueryMatch';
import {
  allowUserAccessToProject,
  removeUserAccessToProject,
} from 'redux/reducers/projectSlice';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import { AppDispatch } from 'redux/store';

import styles from '../TeamManagementPage.module.less';

type Props = {
  listOfTeammate: UserGroupUserResponseModel[];
  projectId: string;
  onSuccess: (groupId: string) => Promise<void>;
};

export default function UserGroupDetails(props: Props) {
  const { listOfTeammate, projectId, onSuccess } = props;
  const dispatch: AppDispatch = useDispatch();
  const groupId = listOfTeammate[0]?.groupId || '';

  const { modal } = App.useApp();
  const isLightTheme = useSelector(isLightThemeSelector);
  const isMobile = useQueryMatch(DesktopScreenQuery);

  const onAllowAccess = useCallback(
    async (userId: string) => {
      const result = await dispatch(
        allowUserAccessToProject({
          projectId: projectId || '',
          userId,
          userGroupId: groupId,
        }),
      );

      if (allowUserAccessToProject.fulfilled.match(result)) {
        await onSuccess(groupId);
        AlertMessage.success(
          'Access to the project has been granted to the user.',
        );
      } else {
        AlertMessage.error(result.error.message || ApiErrorMessage);
      }
    },
    [projectId, dispatch, groupId],
  );

  const onRemoveAccess = useCallback(
    async (userId: string) => {
      const result = await dispatch(
        removeUserAccessToProject({
          projectId: projectId || '',
          userId,
          userGroupId: groupId,
        }),
      );

      if (removeUserAccessToProject.fulfilled.match(result)) {
        await onSuccess(groupId);
        AlertMessage.success(
          'Access to the project has been revoked from the user.',
        );
      } else {
        AlertMessage.error(result.error.message || ApiErrorMessage);
      }
    },
    [projectId, dispatch, groupId],
  );

  const onConfirmAllowAccess = useCallback((userId: string) => {
    modal.confirm({
      content: (
        <div>
          This user has been granted access to the project and can now
          collaborate with other team members.
        </div>
      ),
      icon: <span className="icon icon-info-circle confirm-icon" />,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      width: '496px',
      cancelText: 'Cancel',
      okText: 'Ok',
      onOk: async () => await onAllowAccess(userId),
    });
  }, []);

  const onConfirmRemoveAccess = useCallback((userId: string) => {
    modal.confirm({
      content: (
        <div>
          This user can no longer collaborate with other team members or gain
          access to this project. Their previous history logs will be retained
          within the system.
        </div>
      ),
      icon: <span className="icon icon-info-circle confirm-icon" />,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      width: '496px',
      cancelText: 'Cancel',
      okText: 'Ok',
      onOk: () => onRemoveAccess(userId),
    });
  }, []);

  return (
    <div
      className={isMobile ? cx('modal-scroll-wrapper', styles.tab) : styles.tab}
    >
      <div className={styles.tableHead}>
        <div className={cx(styles.tableRow, styles.xsNone)}>
          <div className={styles.tableCol}>User name</div>
          <div className={styles.tableCol}>Email</div>
          <div className={cx(styles.tableCol, styles.actions)}>Actions</div>
        </div>
        <div className={cx(styles.tableRow, styles.lgNone)}>
          <div className={styles.tableCol}>Users</div>
        </div>
      </div>
      <div className={cx(styles.tableBody, styles.team)}>
        {listOfTeammate.map((teammate) => (
          <div className={styles.tableRow} key={nanoid()}>
            <div className={styles.tableCol}>
              <div className={styles.avatar}>
                <Avatar src={teammate.imageGetUrl}>
                  {teammate.userName?.charAt(0)}
                </Avatar>
              </div>
              {teammate.userName}
            </div>
            <div className={styles.tableCol}>{teammate.email}</div>

            <div className={cx(styles.tableCol, styles.actions)}>
              {teammate.isFolderVisible ? (
                <div>
                  <Button
                    type="ghost"
                    onClick={() => onConfirmRemoveAccess(teammate.userId || '')}
                  >
                    <i className="icon icon-eye" />
                  </Button>
                </div>
              ) : (
                <div className={styles.btn}>
                  <Button
                    type="ghost"
                    onClick={() => onConfirmAllowAccess(teammate.userId || '')}
                  >
                    <i className="icon icon-not-available-eye" />
                  </Button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
