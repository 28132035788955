import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row, Skeleton } from 'antd';
import cx from 'classnames';
import { isArray, isNil, unset } from 'lodash';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  CompletenessFilterEnum,
  FolderResponseModel,
  SortFieldEnum,
  SortOrderEnum,
} from 'api';
import ProjectCard from 'features/project/ProjectCard';
import ActionsBox from 'components/ActionsBox';
import Breadcrumb from 'components/Breadcrumb';
import CompletenessFilter from 'components/filters/CompletenessFilter';
import SortingFilter, {
  SortingFilterValueType,
  SortingMode,
} from 'components/filters/SortingFilter';
import SearchInput from 'components/filters/SearchInput';
import SearchLayout, {
  ChildrenProps,
  SearchLayoutMode,
} from 'components/search/SearchLayout';
import { spaceIdSelector } from 'redux/selectors/authenticationSelectors';

import noProject from 'assets/img/no-project.png';

import styles from 'components/search/SearchResults/SearchResults.module.less';

export enum ProjectsFiltersTypes {
  ProjectName = 'projectName',
  Sorting = 'sorting',
  Completeness = 'completeness',
}

interface ProjectsFilters {
  [ProjectsFiltersTypes.ProjectName]: string;
  [ProjectsFiltersTypes.Sorting]: SortingFilterValueType;
  [ProjectsFiltersTypes.Completeness]: CompletenessFilterEnum;
}

export default function UserHomePage() {
  const [isInitInfScrollNeeded, setIsInitInfScrollNeeded] = useState(false);
  const [isFilterUsed, setIsFilterUsed] = useState(false);
  const location = useLocation();
  const currentSpaceId = useSelector(spaceIdSelector);
  const { projectName, sortField, sortOrder, completeness } = queryString.parse(
    location.search,
  );

  const initialValues: ProjectsFilters = {
    [ProjectsFiltersTypes.ProjectName]:
      !isArray(projectName) && !isNil(projectName) ? projectName : '',
    [ProjectsFiltersTypes.Sorting]: {
      sortField:
        !isArray(sortField) && !isNil(sortField)
          ? +sortField
          : SortFieldEnum.Name,
      sortOrder:
        !isArray(sortOrder) && !isNil(sortOrder)
          ? +sortOrder
          : SortOrderEnum.Asc,
    },
    [ProjectsFiltersTypes.Completeness]:
      !isArray(completeness) && !isNil(completeness)
        ? +completeness
        : CompletenessFilterEnum.All,
  };

  const [filters, setFilters] = useState<ProjectsFilters>(initialValues);

  const noInitialDataNode = (
    <div className="noData">
      <div className="image">
        <img src={noProject} alt="no-project" />
      </div>
      <div className="title">Add a project that will contain your trackers</div>
      <div>Click on the “Add a project” button to create one</div>
    </div>
  );

  useEffect(() => {
    setIsInitInfScrollNeeded(true);
    setFilters({
      ...filters,
      [ProjectsFiltersTypes.Sorting]: {
        sortField: SortFieldEnum.Name,
        sortOrder: SortOrderEnum.Asc,
      },
    });
  }, [currentSpaceId]);

  const onChangeCompletenessFilter = (value: CompletenessFilterEnum) => {
    setFilters({
      ...filters,
      [ProjectsFiltersTypes.Completeness]: value,
    });
    setIsFilterUsed(true);
  };

  const handleSearch = (query: string) => {
    setFilters({
      ...filters,
      [ProjectsFiltersTypes.ProjectName]: query,
    });
  };

  const handleSort = (sortParams: SortingFilterValueType) => {
    setFilters({
      ...filters,
      [ProjectsFiltersTypes.Sorting]: sortParams,
    });
  };

  const enableInitInfScroll = () => setIsInitInfScrollNeeded(false);

  const renderBreadcrumb = () => (
    <Breadcrumb routes={[{ title: <i className="icon icon-home" /> }]} />
  );

  const renderData = (
    childrenProps: ChildrenProps<FolderResponseModel, any>,
  ) => {
    const { isInitialDataLoaded, isSearchMode, emptySearchResults, details } =
      childrenProps;

    return (
      <div>
        <div className={cx('h2', styles.skeletonTitle)}>
          <Skeleton
            active
            loading={!isInitialDataLoaded && isSearchMode}
            title={false}
            paragraph={{ rows: 1 }}
          >
            {!emptySearchResults && isSearchMode
              ? 'Projects search results'
              : ''}
          </Skeleton>
        </div>
        <Skeleton
          active
          loading={!isInitialDataLoaded && isSearchMode}
          title={false}
          className={styles.skeletonWrap}
          paragraph={{
            rows: 1,
          }}
        >
          <Row gutter={[20, 20]}>
            {details.map((project) => (
              <Col md={12} xs={24} key={project.id || ''}>
                <ProjectCard project={project} />
              </Col>
            ))}
          </Row>
        </Skeleton>
      </div>
    );
  };

  const renderSearchInput = () => (
    <SearchInput
      onChange={handleSearch}
      value={filters[ProjectsFiltersTypes.ProjectName]}
      placeholder="Search projects"
    />
  );

  const renderFilters = () => (
    <>
      <div>
        <SortingFilter
          value={filters[ProjectsFiltersTypes.Sorting]}
          onChange={handleSort}
          mode={SortingMode.Projects}
        />
      </div>
      <div>
        <CompletenessFilter
          value={filters[ProjectsFiltersTypes.Completeness]}
          onChange={onChangeCompletenessFilter}
        />
      </div>
    </>
  );

  const renderActions = (initInfinityScroll: () => void) => (
    <ActionsBox
      isTrackerCreateButtonVisible={false}
      reloadList={initInfinityScroll}
    />
  );

  const resultFilters = useMemo(() => {
    const newFilters = {
      query: filters[ProjectsFiltersTypes.ProjectName],
      ...filters,
    };
    unset(newFilters, [ProjectsFiltersTypes.ProjectName]);
    return newFilters;
  }, [filters]);

  return (
    <SearchLayout
      mode={SearchLayoutMode.Projects}
      filters={resultFilters}
      isFilterUsed={isFilterUsed}
      isInitInfScrollNeeded={isInitInfScrollNeeded}
      enableInitInfScroll={enableInitInfScroll}
      renderBreadcrumbsNode={renderBreadcrumb}
      renderActions={renderActions}
      searchNode={renderSearchInput()}
      filtersNode={renderFilters()}
      columns={2}
      noInitialDataNode={noInitialDataNode}
    >
      {renderData}
    </SearchLayout>
  );
}
