import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { App as AntdApp, ConfigProvider, Spin } from 'antd';
import { useNavigate } from 'react-router';

import AppLayout from 'components/AppLayout';
import { AppDispatch } from 'redux/store';
import {
  checkIsSignedAgreement,
  getUserInfo,
} from 'redux/reducers/authenticationSlice';
import { getAvailableSpace } from 'redux/reducers/spaceSlice';
import localStorage from 'utils/localStorage';
import {
  ALERT_TOASTER_OPTIONS,
  BASE_ALERTS_CONTAINER_ID,
} from 'utils/toastUtils';
import useGoogleAnalytics from 'hooks/useGoogleAnalytics';
import {
  isAuthenticatedSelector,
  isSignAgreementSelector,
  userSelector,
} from 'redux/selectors/authenticationSelectors';
import { getSpaceRole } from 'redux/reducers/roleSlice';
import usePreviousState from 'hooks/usePreviousState';
import { AgreementTypeEnum } from 'api';
import { useInterval } from 'hooks/useInterval';
import MasterTermsModal from 'components/modals/MasterTermsModal';
import 'api/fetchInterceptor';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import {
  darkThemeComponents,
  lightThemeComponents,
  mergedDarkToken,
  mergedLightToken,
} from 'styles/layoutModes';

import 'scripts/connectChatBot';

import 'antd/dist/reset.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/App.less';
import WrapperHelmetData from 'components/wrappers/WrapperHelmetData';

const interval = 60 * 60 * 1000; // 1 hour

export default function App() {
  const dispatch: AppDispatch = useDispatch();
  const [isAuthDataLoaded, setIsAuthDataLoaded] = useState(false);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const user = useSelector(userSelector);
  const isSignAgreement = useSelector(isSignAgreementSelector);
  const isLightTheme = useSelector(isLightThemeSelector);
  const navigate = useNavigate();

  const prevIsAuthenticated = usePreviousState(isAuthenticated);

  useEffect(() => {
    if (!isAuthenticated && prevIsAuthenticated === true) {
      navigate('/login');
    }
  }, [isAuthenticated]);

  useGoogleAnalytics();

  useInterval(() => {
    dispatch(checkIsSignedAgreement(AgreementTypeEnum.MasterTerms));
  }, interval);

  useEffect(() => {
    const loadInitialData = async () => {
      await dispatch(getAvailableSpace());
      await dispatch(getUserInfo());
      await dispatch(getSpaceRole());
      await dispatch(checkIsSignedAgreement(AgreementTypeEnum.MasterTerms));
      setIsAuthDataLoaded(true);
    };

    const token = localStorage?.getItem('jwt-token');

    if (token) {
      loadInitialData();
    } else {
      setIsAuthDataLoaded(true);
    }
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <>
      <WrapperHelmetData>
        <ConfigProvider
          theme={{
            components: isLightTheme
              ? lightThemeComponents
              : darkThemeComponents,
            token: isLightTheme ? mergedLightToken : mergedDarkToken,
          }}
        >
          <AntdApp>
            {!isAuthDataLoaded && <Spin />}

            {isAuthDataLoaded && (
              <>
                {user && !isSignAgreement && <MasterTermsModal />}
                <AppLayout />
                <ToastContainer
                  enableMultiContainer
                  {...ALERT_TOASTER_OPTIONS}
                  containerId={BASE_ALERTS_CONTAINER_ID}
                />
              </>
            )}
          </AntdApp>
        </ConfigProvider>
      </WrapperHelmetData>
    </>
  );
}
