import React from 'react';
import cx from 'classnames';

import Pagination, { LandingSectionsEnum } from '../Pagination/Pagination';

import styles from './ScheduleUpdatesSection.module.less';
import commonStyles from '../../LandingPage/LandingPage.module.less';

export default function ScheduleUpdatesSection() {
  return (
    <section
      className={styles.scheduleSection}
      id={LandingSectionsEnum.ScheduleUpdates}
    >
      <div className={commonStyles.container}>
        <div
          className={cx(
            styles.title,
            commonStyles.title,
            commonStyles.titleDark,
          )}
        >
          <h2>
            Get reminders for
            <br /> <span>approaching deadlines </span>
          </h2>
        </div>
        <div className={styles.text}>
          <p>
            If there is an approaching deadline on a stage and it hasn't been
            completed, an email notification is sent to remind you to complete
            the stage for your client.
          </p>
        </div>
      </div>
      <Pagination
        className={styles.pagination}
        activeSection={LandingSectionsEnum.ScheduleUpdates}
      />
    </section>
  );
}
