import { isUndefined } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'redux/reducers/rootReducer';
import { userSelector } from 'redux/selectors/authenticationSelectors';

export const activeTourStepSelector = (state: RootState) =>
  state.onboarding.activeTourStep;

export const isOnboardingRunningSelector = (state: RootState) =>
  !isUndefined(state.onboarding.activeTourStep);

export const isOnboardingManualSelector = (state: RootState) =>
  state.onboarding.isManual;

export const tourProgressSelector = (state: RootState) =>
  state.onboarding.tourProgress;

export const onboardingPageSelector = (state: RootState) =>
  state.onboarding.onboardingPage;

export const isVisibleOnboardingPageSelector = (onboardingPage: string) =>
  createSelector(userSelector, (user) => {
    switch (onboardingPage) {
      case '/':
        return !user?.onboardingPages?.isHomePageChecked;
    }
  });
