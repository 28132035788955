import React from 'react';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';

import { useFetchTemplatesDetails } from 'hooks/useFetchTemplatesDetails';
import Breadcrumb from 'components/Breadcrumb';
import StagesViewSection, {
  StagesMode,
} from 'components/ProgressTracker/StagesViewSection';
import TemplateDetailsSection from './TemplateDetailsSection';
import { TemplateSections } from '../TemplateListPage/UserTemplatesListPage';

type ParamsProps = {
  templateId: string;
};

export default function TemplateDetailsPage() {
  const { templateId } = useParams<ParamsProps>();
  const [templateDetails, isLoading, loadTemplate] = useFetchTemplatesDetails({
    templateId: templateId || '',
  });

  if (isLoading) return <Spin />;

  if (!templateDetails) return null;

  return (
    <div className="page-wrapper scroll-box ">
      <div className="page-box page-holder">
        <Breadcrumb
          routes={[
            {
              title: <i className="icon icon-arrow-left" />,
              link: `/templates?tab=${TemplateSections.MyTemplates}`,
            },
          ]}
        />

        <TemplateDetailsSection
          loadTemplate={loadTemplate}
          template={templateDetails}
        />

        <StagesViewSection
          details={templateDetails}
          mode={StagesMode.VIEW_TEMPLATE}
        />
      </div>
    </div>
  );
}
