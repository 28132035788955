import { UserSpaceRoleEnum } from 'api';

const getRoleDescription = (role?: UserSpaceRoleEnum) => {
  switch (role) {
    case UserSpaceRoleEnum.Basic:
      return 'Licensed users who have been invited as team members by the account administrator.';
    case UserSpaceRoleEnum.Admin:
      return 'Licensed user that set up the account or manages user access and billing';
  }
};

export { getRoleDescription };
