import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { canCreateSelector } from 'redux/selectors/progressTrackerSelectors';
import { RestrictionModalTypeEnum } from 'features/account/AccountSettingsPage/RestrictionModal';
import store, { AppDispatch } from 'redux/store';
import { onCheckTrackerRestrictions } from 'utils/trackerUtils';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from 'redux/selectors/authenticationSelectors';
import { SubscriptionTypeEnum, UserSpaceRoleEnum } from 'api';
import { openRestrictionModal } from 'redux/reducers/accountSlice';
import { roleInfoSelector } from 'redux/selectors/roleSelectors';

type RestrictionsParams = {
  restrictionType: RestrictionModalTypeEnum;
  onClose?: () => void;
  getRedirectUrlOnClose?: (paramId?: string) => string;
  paramName?: string;
  isFetchingInstant?: boolean;
};

export type AdditionalTrackerRestrictionProps = {
  canCreate?: boolean;
  checkCanCreate?: () => Promise<boolean>;
};

export const withTrackerRestrictionsControl =
  (restrictionParams: RestrictionsParams) =>
  <T,>(Component: FunctionComponent<T>) =>
  (props: T) => {
    const { ...restProps } = props;
    const {
      restrictionType,
      onClose,
      paramName,
      getRedirectUrlOnClose,
      isFetchingInstant = true,
    } = restrictionParams;
    const [canCreate, setCanCreate] = useState(false);
    const user = useSelector(userSelector);
    const roleInfo = useSelector(roleInfoSelector);
    const canCreateRedux = useSelector(canCreateSelector);
    const params = useParams();
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
      if (!isFetchingInstant) return;

      getTrackerRestrictions();
    }, [isFetchingInstant]);

    const handleCloseRestrictionModal = () => {
      if (!getRedirectUrlOnClose) return onClose?.();

      const url = getRedirectUrlOnClose(
        paramName ? params[paramName] : undefined,
      );
      return navigate(url);
    };

    const handleCheckTrackerRestrictions = useCallback(
      async (
        restrictionType: RestrictionModalTypeEnum,
        onClose?: () => void,
      ) => {
        if (
          restrictionType ===
            RestrictionModalTypeEnum.CreateTrackerFromTemplate &&
          user?.subscriptionType === SubscriptionTypeEnum.Basic
        ) {
          dispatch(
            openRestrictionModal({
              type: RestrictionModalTypeEnum.CreateTrackerFromTemplate,
              message:
                'Your pricing plan does not support reusable templates. Please upgrade your account.',
              onClose,
            }),
          );
          return false;
        }

        await onCheckTrackerRestrictions();

        const state = store.getState();

        if (
          (restrictionType ===
            RestrictionModalTypeEnum.CreateTrackerFromTemplate ||
            restrictionType === RestrictionModalTypeEnum.CreateTracker) &&
          !state.progressTracker.trackerRestrictions?.isCanCreate
        ) {
          dispatch(
            openRestrictionModal({
              type: restrictionType,
              message:
                roleInfo.roleId === UserSpaceRoleEnum.Basic
                  ? 'You have reached the limit of created trackers this month. ' +
                    'Please contact the space admin or wait for the next month.'
                  : 'You have reached the limit of created trackers this month.' +
                    ' Please upgrade your account or wait for the next month.',
              onClose,
            }),
          );
          return false;
        }

        return true;
      },
      [canCreateRedux],
    );

    const initialLoad = async () => {
      return await getTrackerRestrictions();
    };

    const getTrackerRestrictions = async () => {
      const canCreate = await handleCheckTrackerRestrictions(
        restrictionType,
        handleCloseRestrictionModal,
      );
      setCanCreate(canCreate);

      return canCreate;
    };

    return (
      <Component
        {...restProps}
        canCreate={canCreate}
        checkCanCreate={initialLoad}
      />
    );
  };
