import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { AppDispatch } from 'redux/store';
import {
  ProgressTrackerResponseModel,
  ProgressTrackerTemplateResponseModel,
} from 'api';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import AlertMessage from 'components/AlertMessage';
import { getTemplateDetails } from 'redux/reducers/templateSlice';

export const useFetchTemplateDetails = (progressTrackerId: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const [details, setDetails] = useState<ProgressTrackerResponseModel>();

  useEffect(() => {
    const initialLoad = async () => {
      setIsLoading(true);
      await loadPTDetails();
      setIsLoading(false);
    };

    initialLoad();
    // eslint-disable-next-line
  }, [progressTrackerId]);

  const loadPTDetails = useCallback(async () => {
    if (!progressTrackerId) return;

    const result = await dispatch(getTemplateDetails(progressTrackerId));

    if (!getTemplateDetails.fulfilled.match(result)) {
      AlertMessage.error(result.error.message || ApiErrorMessage);
      return;
    }

    setDetails(result.payload);
  }, []);

  return [details, isLoading, loadPTDetails] as [
    ProgressTrackerTemplateResponseModel,
    boolean,
    () => Promise<void>,
  ];
};
