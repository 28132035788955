import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AppDispatch } from 'redux/store';
import { openRestrictionModal } from 'redux/reducers/accountSlice';
import { RestrictionModalTypeEnum } from 'features/account/AccountSettingsPage/RestrictionModal/RestrictionModal';
import TemplateEdit from '../TemplateEdit';
import {
  isAdminSelector,
  userSelector,
} from 'redux/selectors/authenticationSelectors';
import { isReusableTemplateAvailable } from 'redux/reducers/templateSlice';

export default function TemplateCreatePage() {
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector(userSelector);
  const isAdmin = useSelector(isAdminSelector);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAction = async () => {
      const result = await dispatch(isReusableTemplateAvailable());

      if (
        isReusableTemplateAvailable.fulfilled.match(result) &&
        !result.payload
      ) {
        navigate('/templates');
        dispatch(
          openRestrictionModal({
            type: RestrictionModalTypeEnum.CreateTemplate,
          }),
        );
      }
    };

    !isAdmin && checkAction();
  }, [dispatch, user?.subscriptionType, isAdmin]);

  return <TemplateEdit />;
}
