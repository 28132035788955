import React, { useState } from 'react';
import { Form, Input } from 'antd';
import { FieldError, useWatch } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';

import PTModal, { PTModalProps } from 'components/PTModal';
import Controller from 'components/form/Controller';

type Props = {
  control: Control;
  error?: FieldError;
  onClose: () => void;
  onOk: () => Promise<void>;
} & Omit<PTModalProps, 'children'>;

export default function AddProjectModal(props: Props) {
  const { control, error, onClose, onOk, ...otherModalProps } = props;
  const [isSaving, setIsSaving] = useState(false);

  const folderNameWatched = useWatch({ control, name: 'folderName' });

  const handleSave = async () => {
    setIsSaving(true);
    await onOk();
    setIsSaving(false);
  };

  return (
    <PTModal
      {...otherModalProps}
      cancelText="Cancel"
      okText="Save"
      onOk={handleSave}
      onCancel={onClose}
      okButtonProps={{ loading: isSaving, disabled: !folderNameWatched }}
      title="Create a project"
      className="modal-root"
    >
      <Form layout="vertical">
        <div className="modal-scroll-wrapper">
          <Controller
            containerClassName="dark-input-group"
            label="Name this project"
            name="folderName"
            placeholder="Enter project name"
            as={Input}
            control={control}
            error={error}
            size="large"
          />
        </div>
      </Form>
    </PTModal>
  );
}
