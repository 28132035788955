import React from 'react';
import { Control } from 'react-hook-form/dist/types';

import BaseFileUpload, {
  BaseFileUploadProps,
  FileValue,
  UploadMode,
} from 'components/wrappers/BaseFileUpload';
import Controller from 'components/form/Controller';
import { getFilesForManualDownload, getS3Keys } from 'utils/fileUtils';

type Props = BaseFileUploadProps & {
  uploaderRef: any;
  defaultValue?: FileValue[];
  name: string;
  control: Control;
};

export default function FormFileUpload(props: Props) {
  const {
    name,
    control,
    defaultValue,
    uploaderRef,
    mode = UploadMode.InstantDownload,
    ...restProps
  } = props;

  const getValueFunction = (mode: UploadMode) => {
    switch (mode) {
      case UploadMode.ManualDownload:
        return getFilesForManualDownload;
      default:
        return getS3Keys;
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={(controllerProps) => {
        return (
          <BaseFileUpload
            {...restProps}
            mode={mode}
            ref={uploaderRef}
            onChange={(info) => {
              const { fileList } = info;

              const valueFunction = getValueFunction(mode);
              const value = valueFunction(fileList);

              controllerProps.onChange(value);
            }}
          />
        );
      }}
    />
  );
}
