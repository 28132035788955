import { FolderResponseModel } from 'api';
import { HomePageSteps, OnboardingPagesEnum } from 'enums/OnboardingEnums';

export const OBOARDING_STATUS_FIELD_NAMES = {
  [OnboardingPagesEnum.Home]: 'isHomePageChecked',
  [OnboardingPagesEnum.Template]: 'isTemplatePageChecked',
  [OnboardingPagesEnum.Project]: 'isProjectPageChecked',
  [OnboardingPagesEnum.Analytics]: 'isAnalyticsPageChecked',
};

export const HOME_PAGE_STEPS = [
  HomePageSteps.Projects,
  HomePageSteps.Templates,
  HomePageSteps.Analytics,
  HomePageSteps.Account,
  HomePageSteps.Filters,
];

export const HOME_PAGE_ONOBOARDING_PROJECTS: FolderResponseModel[] = [
  {
    name: 'Plans for 2022-2023',
    completenessTotalCount: 75,
    progressTrackerTotalCount: 2,
  },
  {
    name: 'Sport section. 2022',
    completenessTotalCount: 25,
    progressTrackerTotalCount: 10,
  },
  {
    name: 'Kid Bike',
    completenessTotalCount: 100,
    progressTrackerTotalCount: 4,
  },
  {
    name: 'Components',
    completenessTotalCount: 90,
    progressTrackerTotalCount: 7,
  },
  {
    name: 'Tour to Asia',
    completenessTotalCount: 20,
    progressTrackerTotalCount: 25,
  },
  {
    name: 'Plans for 2022',
    completenessTotalCount: 70,
    progressTrackerTotalCount: 2,
  },
  {
    name: 'TRANSIRO',
    completenessTotalCount: 45,
    progressTrackerTotalCount: 3,
  },
  {
    name: 'INFINITO R3',
    completenessTotalCount: 100,
    progressTrackerTotalCount: 1,
  },
  {
    name: 'Plans for 2025',
    completenessTotalCount: 70,
    progressTrackerTotalCount: 2,
  },
  {
    name: 'Insurance for the T',
    completenessTotalCount: 50,
    progressTrackerTotalCount: 9,
  },
  {
    name: 'Plans for 2024',
    completenessTotalCount: 20,
    progressTrackerTotalCount: 21,
  },
  {
    name: 'Plans for 2023-2030',
    completenessTotalCount: 25,
    progressTrackerTotalCount: 8,
  },
];
