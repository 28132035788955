import React from 'react';
import { Progress } from 'antd';
import cx from 'classnames';

import { PTStageResponseModel } from 'api';
import PTPopover from 'components/PTPopover';
import { useQueryMatch } from 'hooks/useQueryMatch';
import { getStageNumber } from './utils';
import { getDateForUserTimezone } from 'utils/dateUtils';
import { getStagePercentage } from 'components/DetailsSection/utils';

import styles from './../ProgressTrackerView.module.less';

type StageProgressProps = {
  stage: PTStageResponseModel;
  numberOfStage: number;
};

const StageProgress = React.memo((props: StageProgressProps) => (
  <Progress
    type="circle"
    percent={getStagePercentage(props.stage)}
    format={() => getStageNumber(props.numberOfStage)}
    strokeColor={'#00AA63'}
    strokeWidth={14}
    size={56}
  />
));

type Props = {
  stage: PTStageResponseModel;
  numberOfStage: number;
  popoverContent: React.ReactNode;
  stageButtons?: React.ReactNode;
  isCompletenessAvailable: boolean;
};

export default function StageBody(props: Props) {
  const {
    stage,
    numberOfStage,
    popoverContent,
    stageButtons,
    isCompletenessAvailable,
  } = props;
  const isMobile = useQueryMatch();

  return (
    <div
      className={cx({
        [styles.active]: getStagePercentage(stage) === 100,
      })}
    >
      <div className={styles.stageWrap}>
        {isMobile ? (
          <>
            <div
              className={styles.stageCount}
              id={`progress-animation-wrapper-${stage.id}`}
            >
              <StageProgress stage={stage} numberOfStage={numberOfStage} />
            </div>

            <div className={styles.stageInfo}>
              <div className={styles.stageHead}>
                {stage.dueDate && (
                  <div className={styles.stageDate}>
                    {getDateForUserTimezone(stage.dueDate)}
                  </div>
                )}
                {stageButtons && (
                  <ul className={styles.stageActions}>{stageButtons}</ul>
                )}
              </div>
              <div className={styles.stageName}>{stage.name}</div>
              {isCompletenessAvailable && (
                <div className={styles.stagePercent}>
                  {getStagePercentage(stage)} %
                </div>
              )}
              <div className={styles.stageText}>{stage.description}</div>
            </div>
          </>
        ) : (
          <>
            <PTPopover
              content={popoverContent}
              trigger={['click', 'hover']}
              placement="top"
              getPopupContainer={() =>
                document.getElementById('root') as HTMLElement
              }
            >
              <div
                className={styles.stageCount}
                id={`progress-animation-wrapper-${stage.id}`}
              >
                <StageProgress stage={stage} numberOfStage={numberOfStage} />
              </div>
            </PTPopover>

            <div className={styles.stageName}>{stage.name}</div>
          </>
        )}
      </div>
    </div>
  );
}
