import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { Button } from 'antd';
import cx from 'classnames';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

import {
  ProgressTrackerResponseModel,
  ProgressTrackerTemplateResponseModel,
  PTStageResponseModel,
  PTStageTemplateResponseModel,
  StageStatusEnum,
} from 'api';
import AlertMessage from 'components/AlertMessage';
import { StageView } from 'components/ProgressTracker/Stage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import TemplateStageView from 'features/template/TemplateDetailsPage/TemplateStageView';
import {
  setStageStatus,
  setStageStatusAdmin,
  SetStageStatusModel,
} from 'redux/reducers/progressTrackerSlice';
import { isAdminSelector } from 'redux/selectors/authenticationSelectors';
import { getEndedStage } from 'utils/trackerUtils';

import styles from './StagesViewSection.module.less';

export enum StagesMode {
  VIEW = 1,
  VIEW_SHARED = 2,
  VIEW_TEMPLATE = 3,
}

type Props = {
  details: ProgressTrackerResponseModel | ProgressTrackerTemplateResponseModel;
  onActionDone?: () => Promise<void>;
  mode: StagesMode;
};

const INIT_MAX_STAGE_NUMBER = 4;

export default function StagesViewSection(props: Props) {
  const isLightTheme = useSelector(isLightThemeSelector);
  const { details, onActionDone, mode } = props;
  const { stages } = details;
  const stagesCount = stages?.length || 0;
  const endedStage = getEndedStage(stages);

  const [isViewAllSteps, setViewAllSteps] = useState(false);
  const isAdmin = useSelector(isAdminSelector);

  const dispatch: AppDispatch = useDispatch();

  const handleStageAction = useCallback(
    async (data: SetStageStatusModel) => {
      const action = isAdmin ? setStageStatusAdmin : setStageStatus;
      const result = await dispatch(action(data));

      if (!action.fulfilled.match(result)) {
        AlertMessage.error(result.error.message || ApiErrorMessage);
        return;
      }

      await onActionDone?.();
    },
    [dispatch, onActionDone],
  );

  const handleSeeMoreButtonClick = () => {
    setViewAllSteps(true);
  };

  const renderSeeMoreButton = (updatesCount: number) => (
    <div className={cx(styles.btn, { [styles.inverse]: !isLightTheme })}>
      <div className={styles.btnCircle}></div>
      <div className={styles.btnWrap}>
        <Button
          key="seeMoreButton"
          type="link"
          onClick={handleSeeMoreButtonClick}
        >
          <span>See more updates ({updatesCount})</span>
        </Button>
      </div>
    </div>
  );

  const calculateStageClasses = (
    sequence: number,
    mode: StagesMode,
    status?: StageStatusEnum,
  ) => {
    const isShortView = !isViewAllSteps && stagesCount > INIT_MAX_STAGE_NUMBER;
    const isThirdDashed = isShortView && sequence === 3;
    const isLastDashed = isShortView && sequence === stagesCount;

    if (isThirdDashed) {
      if (mode === StagesMode.VIEW) {
        return status === StageStatusEnum.Done
          ? cx('dashed-first', 'dashed')
          : cx('dashed-first');
      } else {
        return cx('short-first');
      }
    } else if (isLastDashed) {
      if (mode === StagesMode.VIEW) {
        return status === StageStatusEnum.Done
          ? cx('dashed-last', 'dashed')
          : cx('dashed-last');
      } else {
        return cx('short-last');
      }
    }
  };

  const progressTrackerStagesMapper = (stages: PTStageResponseModel[]) =>
    stages.map((stage) => {
      return (
        <StageView
          key={stage.id}
          stage={stage}
          stageNumber={stage.sequence || 1}
          onStageAction={handleStageAction}
          className={calculateStageClasses(
            stage.sequence || 1,
            StagesMode.VIEW,
            stage.status,
          )}
          isActions={mode === StagesMode.VIEW}
          endedStage={endedStage}
        />
      );
    });

  const templateStagesMapper = (stages: PTStageTemplateResponseModel[]) => {
    return stages.map((stage) => (
      <TemplateStageView
        key={stage.id}
        stage={stage}
        className={calculateStageClasses(
          stage.sequence || 1,
          StagesMode.VIEW_TEMPLATE,
        )}
      />
    ));
  };

  const getCorrectDisplayedStages = () => {
    switch (mode) {
      case StagesMode.VIEW:
      case StagesMode.VIEW_SHARED:
        return progressTrackerStagesMapper;
      case StagesMode.VIEW_TEMPLATE:
        return templateStagesMapper;
    }
  };

  const stagesDisplaying = (
    stages: PTStageResponseModel[] | PTStageTemplateResponseModel[],
  ) => {
    const correctAction = getCorrectDisplayedStages();
    if (stagesCount > INIT_MAX_STAGE_NUMBER && !isViewAllSteps) {
      const firstStages = stages.slice(0, INIT_MAX_STAGE_NUMBER - 1);
      const lastStage = stages[stagesCount - 1];
      const firstPartStages = [...firstStages, lastStage];

      const displayedStages = correctAction(firstPartStages);
      displayedStages.splice(
        3,
        0,
        renderSeeMoreButton(stagesCount - INIT_MAX_STAGE_NUMBER),
      );

      return displayedStages;
    }
    return correctAction(stages);
  };

  if (!stages) return null;

  return <div className="cards-list">{stagesDisplaying(stages)}</div>;
}
