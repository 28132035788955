import { combineReducers } from '@reduxjs/toolkit';

import authSlice from './authenticationSlice';
import progressTrackerSlice from './progressTrackerSlice';
import projectSlice from './projectSlice';
import templateSlice from './templateSlice';
import notificationSlice from './notificationSlice';
import dashboardSlice from './dashboardSlice';
import accountSlice from './accountSlice';
import regionSlice from './regionSlice';
import spaceSlice from './spaceSlice';
import roleSlice from './roleSlice';
import userSlice from './userSlice';
import transactionSlice from './transactionSlice';
import onboardingSlice from './onboardingSlice';
import themeSlice from 'redux/reducers/themeSlice';

const rootReducer = combineReducers({
  auth: authSlice,
  progressTracker: progressTrackerSlice,
  project: projectSlice,
  template: templateSlice,
  notification: notificationSlice,
  dashboard: dashboardSlice,
  account: accountSlice,
  region: regionSlice,
  space: spaceSlice,
  role: roleSlice,
  user: userSlice,
  transaction: transactionSlice,
  onboarding: onboardingSlice,
  theme: themeSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
