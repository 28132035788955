import React from 'react';
import { Button, Progress } from 'antd';
import { useSelector } from 'react-redux';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import cx from 'classnames';

import { ArchivedProgressTrackerCardModel } from 'api';
import { getDateForUserTimezone } from 'utils/dateUtils';
import { getPercentage } from 'utils/percentageUtils';
import { getCorrectStrokeColor } from 'utils/trackerUtils';

type Props = {
  tracker: ArchivedProgressTrackerCardModel;
  handleDeleteClick: (trackerId: string) => void;
  handleUnArchiveClick: (trackerId: string) => void;
};

export default function ArchivedTrackerCard(props: Props) {
  const isLightTheme = useSelector(isLightThemeSelector);
  const { tracker, handleDeleteClick, handleUnArchiveClick } = props;
  const { percentageCompleteness = 0, isAnyStageEnded } = tracker;

  const roundedPercentage = getPercentage(percentageCompleteness);

  return (
    <div
      className={cx('ticket', {
        inverse: !isLightTheme,
      })}
    >
      <div className="ticket__left">
        <div className="ticket__progress">
          <Progress
            type="circle"
            percent={roundedPercentage}
            format={(percent) => `${percent}%`}
            strokeColor={getCorrectStrokeColor(
              roundedPercentage,
              isAnyStageEnded,
            )}
            strokeWidth={10}
            size={54}
          />
        </div>
        <div>
          <div className="ticket__name">{tracker?.name}</div>
          <div className="ticket__item">
            <span>Project:</span> {tracker?.folderName}
          </div>
          <div className="ticket__item">
            <span>Creation date: </span>
            {getDateForUserTimezone(tracker.createdDate)}
          </div>
        </div>
      </div>

      <div className="ticket__right">
        <div className="ticket__btn">
          <div>
            <Button
              type="ghost"
              onClick={() => handleUnArchiveClick(tracker?.id || '')}
            >
              <i className="icon icon-unarchived" />
            </Button>
          </div>
          <div>
            <Button
              type="ghost"
              onClick={() => handleDeleteClick(tracker?.id || '')}
            >
              <i className="icon icon-trash-can" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
