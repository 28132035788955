import { PTStageTemplateResponseModel } from 'api';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

type Props = {
  stage: PTStageTemplateResponseModel;
  className?: string;
};

export default function TemplateStageView(props: Props) {
  const { stage, className } = props;
  const isLightTheme = useSelector(isLightThemeSelector);

  return (
    <div className="cards-wrapper cards-wrapper--center">
      <div className="numeration-wrapper numeration-wrapper--center">
        <div
          className={cx(
            'numeration numeration--center stage',
            {
              inverse: !isLightTheme,
            },
            className,
          )}
        >
          {stage.sequence}
        </div>
      </div>
      <div className="cards cards--center">
        <div className="cards__name">{stage.name}</div>
        <div className="cards__description">{stage.description}</div>
      </div>
    </div>
  );
}
