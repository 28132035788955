import { NotificationTypeEnum, NotificationResponseModel } from 'api';

interface NotificationInfoModel {
  redirectUrl: string;
  subject: string;
  body: string;
  spaceId?: string;
}

export const getNotificationInfo = (
  notification: NotificationResponseModel,
): NotificationInfoModel => {
  const {
    details: { progressTrackerName, stageName, progressTrackerId },
    type,
    spaceId,
  } = notification;

  switch (type) {
    case NotificationTypeEnum.DailyDeadlineNotification:
      return {
        redirectUrl: `/details/${progressTrackerId}`,
        subject: 'Stage deadline date',
        body: `${stageName} for ${progressTrackerName} tracker is scheduled for today.`,
        spaceId,
      };

    default:
      return {
        redirectUrl: '',
        subject: '',
        body: '',
        spaceId,
      };
  }
};
