import * as Yup from 'yup';
import {
  CreatePaymentMethodCardData,
  PaymentMethod,
  Stripe,
} from '@stripe/stripe-js';
import * as api from '@stripe/stripe-js/types/api';

import { PriceTypesEnum } from 'enums/PriceTypesEnum';
import { BillingCycleEnum, IntentTypeEnum } from 'api';
import AlertMessage from 'components/AlertMessage';
import { maxLength, requiredMessage } from 'constants/ValidationConstants';

export interface NewCardFormValues {
  name: string;
  cardNumber: string;
  cvv: number | string;
  expiryDate: string;
  country: string;
  zip: string;
  isDefault: boolean;
}

export const newCardValidationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required(requiredMessage)
    .max(maxLength, 'Full name should be 120 characters maximum'),
  country: Yup.string().nullable().required(requiredMessage),
  zip: Yup.string()
    .required(requiredMessage)
    .max(20, 'Zip should be 20 characters maximum'),
  cardNumber: Yup.string().required(requiredMessage),
  cvv: Yup.number().required(requiredMessage),
  expiryDate: Yup.string().required(requiredMessage),
});

type SuccessPayment = { paymentIntent: api.PaymentIntent; error?: undefined };

export type SuccessPaymentParams = {
  paymentMethod?: string | PaymentMethod;
  intentType?: IntentTypeEnum;
  intentId?: string;
};

export const confirmCardPayment = async (
  stripe: Stripe | null,
  clientSecret: string,
  payment_method: string | Omit<CreatePaymentMethodCardData, 'type'>,
  intentType?: IntentTypeEnum,
  onSuccess?: (params: SuccessPaymentParams) => void,
) => {
  try {
    let confirmPaymentResult;
    let intentId;

    if (intentType === IntentTypeEnum.PaymentIntent || !intentType) {
      confirmPaymentResult = await stripe?.confirmCardPayment(clientSecret, {
        payment_method,
      });
      intentId = confirmPaymentResult?.paymentIntent?.id;
    } else {
      confirmPaymentResult = await stripe?.confirmCardSetup(clientSecret, {
        payment_method,
      });
      intentId = confirmPaymentResult?.setupIntent?.id;
    }

    if (confirmPaymentResult?.error) {
      AlertMessage.error(
        `Payment failed. ${confirmPaymentResult.error.message}`,
      );
      return;
    }

    onSuccess?.({
      intentType,
      intentId,
      paymentMethod:
        (confirmPaymentResult as SuccessPayment)?.paymentIntent
          ?.payment_method || '',
    });
  } catch (error) {
    AlertMessage.error('Payment failed.');
  }
};

export const getBillingCycleRequestValue = (billingCycle?: PriceTypesEnum) => {
  switch (billingCycle) {
    case PriceTypesEnum.Monthly:
      return BillingCycleEnum.Monthly;
    case PriceTypesEnum.Annual:
      return BillingCycleEnum.Annual;
  }
};
