import React, { useRef } from 'react';
import { Spin, Tabs, TabsProps } from 'antd';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Breadcrumb from 'components/Breadcrumb';
import { DetailsSection } from 'components/DetailsSection';
import StagesViewSection, {
  StagesMode,
} from 'components/ProgressTracker/StagesViewSection';
import { PTDetailsMode, useFetchPTDetails } from 'hooks/useFetchPTDetails';
import { isAdminSelector } from 'redux/selectors/authenticationSelectors';
import TrackerHistorySection from 'features/progressTracker/ProgressTrackerDetailsPage/TrackerHistorySection';
import CommentsSection from 'features/progressTracker/ProgressTrackerDetailsPage/CommentsSection';
import {
  isSpaceAdminSelector,
  isSuperAdminSelector,
} from 'redux/selectors/roleSelectors';

import styles from './ProgressTrackerDetailsPage.module.less';

enum TrackerTabs {
  Comments = 'comments',
  History = 'history',
}

export type ParamsType = {
  progressTrackerId: string;
};

export default function ProgressTrackerDetailsPage() {
  const { progressTrackerId } = useParams<ParamsType>();
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const isSpaceAdmin = useSelector(isSpaceAdminSelector);
  const isAdmin = useSelector(isAdminSelector);
  const [details, isLoading, loadPTDetails] = useFetchPTDetails(
    progressTrackerId || '',
    isAdmin ? PTDetailsMode.Admin : PTDetailsMode.Private,
  );
  const infiniteScrollParentRef = useRef(null);
  const hasAccessToHistorySection = isSuperAdmin || isSpaceAdmin || isAdmin;

  const getTabItems = (): TabsProps['items'] => {
    const item = [
      {
        key: TrackerTabs.Comments,
        label: 'Comments',
        children: <CommentsSection scrollRef={infiniteScrollParentRef} />,
      },
    ];

    if (!hasAccessToHistorySection) return item;

    return [
      ...item,
      {
        key: TrackerTabs.History,
        label: 'History',
        children: <TrackerHistorySection scrollRef={infiniteScrollParentRef} />,
      },
    ];
  };

  if (isLoading) return <Spin />;

  if (!details) return null;

  return (
    <div className="page-wrapper scroll-box " ref={infiniteScrollParentRef}>
      <div className="page-box page-holder">
        <Breadcrumb
          routes={[
            {
              title: <i className="icon icon-arrow-left" />,
              link: isAdmin ? `/trackers` : `/project/${details.folderId}`,
            },
          ]}
        />

        <DetailsSection details={details} />

        <StagesViewSection
          details={details}
          onActionDone={loadPTDetails}
          mode={StagesMode.VIEW}
        />

        <div className={styles.tabs}>
          <Tabs items={getTabItems()} defaultValue={TrackerTabs.Comments} />
        </div>
      </div>
    </div>
  );
}
