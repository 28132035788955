export const isValueValid = (regexp: RegExp, field: string) =>
  regexp.test(field);

export const obscureEmail = (email: string) => {
  const [name, domain] = email.split('@');
  const limit = name.length > 4 ? 4 : 1;
  return `${name.slice(0, limit)}${new Array(name.length - limit + 1).join(
    '*',
  )}@${domain}`;
};
