import React, { useMemo, useState } from 'react';
import { Col, Row } from 'antd';
import { isArray, isNil, unset } from 'lodash';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import {
  AdminSpaceResponseModel,
  AdminSpaceResponseModelPagedResponseModel,
  SortOrderEnum,
  SpaceSortFieldEnum,
} from 'api';
import Breadcrumb from 'components/Breadcrumb';
import SortingFilter, {
  SortingFilterValueType,
  SortingMode,
} from 'components/filters/SortingFilter';
import SearchInput from 'components/filters/SearchInput';
import SearchLayout, {
  ChildrenProps,
  SearchLayoutMode,
} from 'components/search/SearchLayout';
import SpaceCard from 'features/admin/SpacesPage/SpaceCard';

enum SpacesFiltersTypes {
  SpaceName = 'spaceName',
  Sorting = 'sorting',
}

interface SpacesFilters {
  [SpacesFiltersTypes.SpaceName]: string;
  [SpacesFiltersTypes.Sorting]: SortingFilterValueType;
}

export default function SpacesPage() {
  const location = useLocation();
  const { spaceName, sortField, sortOrder } = queryString.parse(
    location.search,
  );

  const initialValues: SpacesFilters = {
    [SpacesFiltersTypes.SpaceName]:
      !isArray(spaceName) && !isNil(spaceName) ? spaceName : '',
    [SpacesFiltersTypes.Sorting]: {
      sortField:
        !isArray(sortField) && !isNil(sortField)
          ? +sortField
          : SpaceSortFieldEnum.SpaceName,
      sortOrder:
        !isArray(sortOrder) && !isNil(sortOrder)
          ? +sortOrder
          : SortOrderEnum.Asc,
    },
  };
  const [filters, setFilters] = useState<SpacesFilters>(initialValues);

  const handleSearch = (query: string) => {
    setFilters({
      ...filters,
      [SpacesFiltersTypes.SpaceName]: query,
    });
  };

  const handleSort = (sortParams: SortingFilterValueType) => {
    setFilters({
      ...filters,
      [SpacesFiltersTypes.Sorting]: sortParams,
    });
  };

  const renderBreadcrumb = () => (
    <Breadcrumb
      routes={[
        { title: <i className="icon icon-home" />, link: '/' },
        { title: 'Spaces' },
      ]}
    />
  );

  const renderSorting = () => (
    <div>
      <SortingFilter
        value={filters[SpacesFiltersTypes.Sorting]}
        onChange={handleSort}
        mode={SortingMode.Spaces}
      />
    </div>
  );

  const renderSearchInput = () => (
    <SearchInput
      onChange={handleSearch}
      value={filters[SpacesFiltersTypes.SpaceName]}
      placeholder="Search spaces"
    />
  );

  const renderData = (
    childrenProps: ChildrenProps<
      AdminSpaceResponseModel,
      AdminSpaceResponseModelPagedResponseModel
    >,
  ) => (
    <Row gutter={[20, 20]}>
      {childrenProps.details.map((space) => (
        <Col md={12} xs={24} key={space.spaceId}>
          <SpaceCard space={space} />
        </Col>
      ))}
    </Row>
  );

  const resultFilters = useMemo(() => {
    const newFilters = {
      query: filters[SpacesFiltersTypes.SpaceName],
      ...filters,
    };
    unset(newFilters, [SpacesFiltersTypes.SpaceName]);
    return newFilters;
  }, [filters]);

  return (
    <SearchLayout
      mode={SearchLayoutMode.Spaces}
      filters={resultFilters}
      columns={2}
      renderBreadcrumbsNode={renderBreadcrumb}
      searchNode={renderSearchInput()}
      filtersNode={renderSorting()}
    >
      {renderData}
    </SearchLayout>
  );
}
