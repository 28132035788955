import { useCallback } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';

import SubscribeModal from 'components/ProgressTracker/SubscribeModal';
import { EmbeddedEventEnum } from 'enums/EmbeddedTracker.enum';

type ParamsProps = {
  progressTrackerId: string;
};

export default function EmbeddedSubscribePage() {
  const location = useLocation();
  const { subscribeType } = queryString.parse(location.search);
  const { progressTrackerId } = useParams<ParamsProps>();

  const handleClose = useCallback(() => {
    window.parent.postMessage({ type: EmbeddedEventEnum.closeModal }, '*');
  }, []);

  return (
    <SubscribeModal
      subscribeType={Number(subscribeType)}
      onClose={handleClose}
      ptId={progressTrackerId || ''}
    />
  );
}
