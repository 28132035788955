import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isNil } from 'lodash';

import PTModal, { PTModalProps } from 'components/PTModal';
import { AppDispatch } from 'redux/store';
import { generateTwoFACodeForActivation } from 'redux/reducers/authenticationSlice';
import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import TwoFAQrCodeConfirmModal from '../TwoFAQrCodeConfirmModal';
import { TwoFATypeEnum } from 'api';

import styles from '../../SecurityTab.module.less';

type Props = {
  onClose: () => void;
  onSuccess: () => void;
} & Omit<PTModalProps, 'children'>;

export default function TwoFAInstructionModal(props: Props) {
  const { onClose, onSuccess, open, ...otherModalProps } = props;
  const [isTwoFAQrCodeConfirmModal, setIsTwoFAQrCodeConfirmModal] =
    useState(false);
  const [twoFAQrCode, setTwoFAQrCode] = useState<string>();
  const [isTwoFAQrCodeLoaded, setIsTwoFAQrCodeLoaded] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  const handleTwoFAQrCodeConfirmModalOpen = () => {
    setIsTwoFAQrCodeConfirmModal(true);
  };

  const handleTwoFAQrCodeConfirmModalClose = () => {
    setIsTwoFAQrCodeConfirmModal(false);
  };

  const loadTwoFAQrCode = useCallback(async () => {
    const result = await dispatch(
      generateTwoFACodeForActivation(TwoFATypeEnum.Authenticator),
    );

    if (generateTwoFACodeForActivation.fulfilled.match(result)) {
      setTwoFAQrCode(result.payload.imgQrCodeUrl);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
    setIsTwoFAQrCodeLoaded(true);
  }, []);

  useEffect(() => {
    if (isTwoFAQrCodeConfirmModal || !open) return;

    loadTwoFAQrCode();
  }, [loadTwoFAQrCode, isTwoFAQrCodeConfirmModal, open]);

  return (
    <>
      <TwoFAQrCodeConfirmModal
        open={isTwoFAQrCodeConfirmModal}
        onClose={handleTwoFAQrCodeConfirmModalClose}
        onSuccess={onSuccess}
      />

      <PTModal
        {...otherModalProps}
        open={!isTwoFAQrCodeConfirmModal && open}
        onCancel={onClose}
        title="Instruction"
        okText="Next"
        onOk={handleTwoFAQrCodeConfirmModalOpen}
        cancelText="Cancel"
        className="modal-root"
      >
        <div className="modal-scroll-wrapper">
          <div className={styles.instruction}>
            <div>1. Download an authentication app.</div>
            <p>
              We recommend downloading Duo Mobile or Google Authenticator if you
              don't have one installed.
            </p>

            <div>2. Scan this barcode/QR code or copy the key.</div>
            <p>
              Scan this barcode/QR code in the authentication app or copy the
              key and paste it in the authentication app.
            </p>

            {isTwoFAQrCodeLoaded && !isNil(twoFAQrCode) && (
              <div className={styles.code}>
                <img src={twoFAQrCode} alt="QR code" />
              </div>
            )}

            <div>3. Copy and enter 6-digit code.</div>
            <p>
              After the barcode/QR code has been scanned or the key has been
              entered, your authentication app will generate a 6-digit code.
              Copy the code and then come back to Facebook to enter it.
            </p>
          </div>
        </div>
      </PTModal>
    </>
  );
}
