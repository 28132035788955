import React, { useState } from 'react';
import { Button } from 'antd';
import cx from 'classnames';
import { find } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

import { EditOnboardingRequestModel } from 'api';
import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { OBOARDING_STATUS_FIELD_NAMES } from 'constants/OnboardingConstants';
import { HomePageSteps, OnboardingPagesEnum } from 'enums/OnboardingEnums';
import {
  getUserInfo,
  setOnboardingPagesChecked,
} from 'redux/reducers/authenticationSlice';
import { confirmInvite } from 'redux/reducers/inviteSlice';
import {
  finishOnboarding,
  onNextStep,
  StepsType,
  updateOnboardingStatus,
} from 'redux/reducers/onboardingSlice';
import { getAvailableSpace } from 'redux/reducers/spaceSlice';
import {
  spaceIdSelector,
  userSelector,
} from 'redux/selectors/authenticationSelectors';
import {
  activeTourStepSelector,
  isOnboardingManualSelector,
} from 'redux/selectors/onboardingSelector';
import { AppDispatch } from 'redux/store';
import localStorage from 'utils/localStorage';
import { getLastStep, getStepsEnumByPage } from 'utils/onboardingUtils';
import { isInviteForThisAccount } from 'utils/inviteUtils';

import arrow1 from 'assets/img/arrow-onboarding-1.png';
import arrow2 from 'assets/img/arrow-onboarding-2.png';
import arrow3 from 'assets/img/arrow-onboarding-3.png';

import './OnboardingStep.less';

export interface OnboardingStepProps {
  className?: string;
  title: string | React.ReactNode;
  description: string | React.ReactNode;
  page: OnboardingPagesEnum;
}

const getArrowByStep = (step?: StepsType) => {
  if (!step) return;
  switch (step) {
    case HomePageSteps.Projects:
    case HomePageSteps.Analytics:
      return arrow3;
    case HomePageSteps.Templates:
      return arrow3;
    case HomePageSteps.Account:
      return arrow1;
    case HomePageSteps.Filters:
      return arrow2;
  }
};

export default function OnboardingStep(props: OnboardingStepProps) {
  const isLightTheme = useSelector(isLightThemeSelector);
  const { title, className, description, page } = props;
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(userSelector);
  const activeTourStep = useSelector(activeTourStepSelector);
  const isOnboardingManual = useSelector(isOnboardingManualSelector);
  const spaceId = useSelector(spaceIdSelector);
  const dispatch: AppDispatch = useDispatch();
  const stepsEnum = getStepsEnumByPage(page);
  const lastStep = getLastStep(stepsEnum);

  const confirmInvitation = async () => {
    if (!isInviteForThisAccount(user?.email)) return;

    const { inviteId } = JSON.parse(
      localStorage?.getItem('inviteId') as string,
    );
    const result = await dispatch(confirmInvite({ inviteId }));

    if (confirmInvite.fulfilled.match(result)) {
      const availableSpacesResult: any = await dispatch(getAvailableSpace());
      const spaceId = result.payload;

      const spaceName = find(availableSpacesResult.payload, {
        id: spaceId,
      }).name;

      dispatch(getUserInfo(spaceId));
      AlertMessage.success(
        `You successfully accepted an invitation to ${spaceName} workspace`,
      );
    }
    localStorage?.removeItem('inviteId');
  };

  const onCompleteOnboarding = async () => {
    if (isOnboardingManual) {
      return dispatch(
        setOnboardingPagesChecked({
          [OBOARDING_STATUS_FIELD_NAMES[page]]: true,
        }),
      );
    }

    const body: EditOnboardingRequestModel = {
      ...user?.onboardingPages,
      [OBOARDING_STATUS_FIELD_NAMES[page]]: true,
    };

    const result = await dispatch(updateOnboardingStatus(body));

    if (updateOnboardingStatus.fulfilled.match(result)) {
      await dispatch(getUserInfo(spaceId));
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const handleCompleteOnboarding = async () => {
    setIsLoading(true);
    await confirmInvitation();
    await onCompleteOnboarding();
    dispatch(finishOnboarding());
    setIsLoading(false);
  };

  const handleNextStepClick = async () => {
    if (activeTourStep) dispatch(onNextStep(activeTourStep));

    if (activeTourStep === lastStep) {
      await handleCompleteOnboarding();
    }
  };

  return (
    <div className={cx('baseStep', className)}>
      <div className={cx('baseStepArrow', className)}>
        <img src={getArrowByStep(activeTourStep)} alt="arrow" />
      </div>

      <div
        className={cx('baseStepModal', {
          darkMode: !isLightTheme,
        })}
      >
        <h3 className="baseStepTitle">{title}</h3>

        <div className="baseStepDesc">{description}</div>

        <div className="baseStepBtnBox">
          <div>
            <Button
              size="small"
              onClick={handleCompleteOnboarding}
              className={cx({ 'dark-btn-outline': !isLightTheme })}
            >
              Skip
            </Button>
          </div>
          <div>
            <Button
              loading={isLoading}
              size="small"
              type="primary"
              onClick={handleNextStepClick}
            >
              {activeTourStep === lastStep ? 'Finish' : 'Next'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
