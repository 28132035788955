import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

type Props = {
  id: string;
  children: React.ReactNode;
};

export default function Portal(props: Props) {
  const { id, children } = props;
  let ref: any = useRef(null);

  if (ref.current === null) {
    ref.current = document.createElement('div');
    ref.current.setAttribute('id', id);
  }

  useEffect(() => {
    document.body.appendChild(ref.current);
    return () => {
      document.body.removeChild(ref.current);
    };
  }, [ref]);

  return createPortal(children, ref.current);
}
