import {
  FolderSortFieldEnum,
  MessageHistorySortFieldEnum,
  ProgressTrackerSortFieldEnum,
  SortFieldEnum,
  SortOrderEnum,
  SpaceSortFieldEnum,
  UserSortFieldEnum,
} from 'api';
import { SortingFilterType } from 'components/filters/SortingFilter/SortingFilter';
import { CancellationHistorySortFieldEnum } from 'features/admin/CancellationHistoryPage/CancellationHistoryPage';

export const cancellationHistoryOptions = [
  {
    label: 'Cancel Date',
    icon: 'icon icon-arrow-up',
    value: {
      sortField: CancellationHistorySortFieldEnum.CancelDate,
      sortOrder: SortOrderEnum.Asc,
    },
  },
  {
    label: 'Cancel Date',
    icon: 'icon icon-arrow-down',
    value: {
      sortField: CancellationHistorySortFieldEnum.CancelDate,
      sortOrder: SortOrderEnum.Desc,
    },
  },
];

export const messagesLogFilterOptions = [
  {
    label: 'New',
    icon: 'icon icon-arrow-up',
    value: {
      sortField: MessageHistorySortFieldEnum.Date,
      sortOrder: SortOrderEnum.Asc,
    },
  },
  {
    label: 'New',
    icon: 'icon icon-arrow-down',
    value: {
      sortField: MessageHistorySortFieldEnum.Date,
      sortOrder: SortOrderEnum.Desc,
    },
  },
  {
    label: 'Project',
    icon: 'icon icon-arrow-up',
    value: {
      sortField: MessageHistorySortFieldEnum.ProjectName,
      sortOrder: SortOrderEnum.Asc,
    },
  },
  {
    label: 'Project',
    icon: 'icon icon-arrow-down',
    value: {
      sortField: MessageHistorySortFieldEnum.ProjectName,
      sortOrder: SortOrderEnum.Desc,
    },
  },
];

export const spacesFilterOptions = [
  {
    label: 'Space name',
    icon: 'icon icon-arrow-up',
    value: {
      sortField: SpaceSortFieldEnum.SpaceName,
      sortOrder: SortOrderEnum.Asc,
    },
  },
  {
    label: 'Space name',
    icon: 'icon icon-arrow-down',
    value: {
      sortField: SpaceSortFieldEnum.SpaceName,
      sortOrder: SortOrderEnum.Desc,
    },
  },
];

export const adminTrackersFilterOptions = [
  {
    label: 'Tracker name',
    icon: 'icon icon-arrow-up',
    value: {
      sortField: ProgressTrackerSortFieldEnum.ProgressTrackerName,
      sortOrder: SortOrderEnum.Asc,
    },
  },
  {
    label: 'Tracker name',
    icon: 'icon icon-arrow-down',
    value: {
      sortField: ProgressTrackerSortFieldEnum.ProgressTrackerName,
      sortOrder: SortOrderEnum.Desc,
    },
  },
  {
    label: 'Project name',
    icon: 'icon icon-arrow-up',
    value: {
      sortField: ProgressTrackerSortFieldEnum.FolderName,
      sortOrder: SortOrderEnum.Asc,
    },
  },
  {
    label: 'Project name',
    icon: 'icon icon-arrow-down',
    value: {
      sortField: ProgressTrackerSortFieldEnum.FolderName,
      sortOrder: SortOrderEnum.Desc,
    },
  },
  {
    label: 'Project owner',
    icon: 'icon icon-arrow-up',
    value: {
      sortField: ProgressTrackerSortFieldEnum.ProjectOwner,
      sortOrder: SortOrderEnum.Asc,
    },
  },
  {
    label: 'Project owner',
    icon: 'icon icon-arrow-down',
    value: {
      sortField: ProgressTrackerSortFieldEnum.ProjectOwner,
      sortOrder: SortOrderEnum.Desc,
    },
  },
];

export const usersFilterOptions: SortingFilterType[] = [
  {
    label: 'First name',
    icon: 'icon icon-arrow-up',
    value: {
      sortField: UserSortFieldEnum.FirstName,
      sortOrder: SortOrderEnum.Asc,
    },
  },
  {
    label: 'First name',
    icon: 'icon icon-arrow-down',
    value: {
      sortField: UserSortFieldEnum.FirstName,
      sortOrder: SortOrderEnum.Desc,
    },
  },
  {
    label: 'Last name',
    icon: 'icon icon-arrow-up',
    value: {
      sortField: UserSortFieldEnum.LastName,
      sortOrder: SortOrderEnum.Asc,
    },
  },
  {
    label: 'Last name',
    icon: 'icon icon-arrow-down',
    value: {
      sortField: UserSortFieldEnum.LastName,
      sortOrder: SortOrderEnum.Desc,
    },
  },
  {
    label: 'Email',
    icon: 'icon icon-arrow-up',
    value: {
      sortField: UserSortFieldEnum.Email,
      sortOrder: SortOrderEnum.Asc,
    },
  },
  {
    label: 'Email',
    icon: 'icon icon-arrow-down',
    value: {
      sortField: UserSortFieldEnum.Email,
      sortOrder: SortOrderEnum.Desc,
    },
  },
  {
    label: 'Space name',
    icon: 'icon icon-arrow-up',
    value: {
      sortField: UserSortFieldEnum.SpaceName,
      sortOrder: SortOrderEnum.Asc,
    },
  },
  {
    label: 'Space name',
    icon: 'icon icon-arrow-down',
    value: {
      sortField: UserSortFieldEnum.SpaceName,
      sortOrder: SortOrderEnum.Desc,
    },
  },
];

export const adminProjectsFilterOptions = [
  {
    label: 'Project name',
    icon: 'icon icon-arrow-up',
    value: {
      sortField: FolderSortFieldEnum.FolderName,
      sortOrder: SortOrderEnum.Asc,
    },
  },
  {
    label: 'Project name',
    icon: 'icon icon-arrow-down',
    value: {
      sortField: FolderSortFieldEnum.FolderName,
      sortOrder: SortOrderEnum.Desc,
    },
  },
  {
    label: 'Project owner',
    icon: 'icon icon-arrow-up',
    value: {
      sortField: FolderSortFieldEnum.ProjectOwner,
      sortOrder: SortOrderEnum.Asc,
    },
  },
  {
    label: 'Project owner',
    icon: 'icon icon-arrow-down',
    value: {
      sortField: FolderSortFieldEnum.ProjectOwner,
      sortOrder: SortOrderEnum.Desc,
    },
  },
  {
    label: 'Date',
    icon: 'icon icon-arrow-up',
    value: {
      sortField: FolderSortFieldEnum.CreatedDate,
      sortOrder: SortOrderEnum.Asc,
    },
  },
  {
    label: 'Date',
    icon: 'icon icon-arrow-down',
    value: {
      sortField: FolderSortFieldEnum.CreatedDate,
      sortOrder: SortOrderEnum.Desc,
    },
  },
];

export const completenessFilterOptions: SortingFilterType[] = [
  {
    label: '%',
    icon: 'icon icon-arrow-up',
    value: {
      sortField: SortFieldEnum.CompletenessStatus,
      sortOrder: SortOrderEnum.Asc,
    },
  },
  {
    label: '%',
    icon: 'icon icon-arrow-down',
    value: {
      sortField: SortFieldEnum.CompletenessStatus,
      sortOrder: SortOrderEnum.Desc,
    },
  },
];

export const baseFilterOptions = [
  {
    label: 'Name',
    icon: 'icon icon-arrow-up',
    value: {
      sortField: SortFieldEnum.Name,
      sortOrder: SortOrderEnum.Asc,
    },
  },
  {
    label: 'Name',
    icon: 'icon icon-arrow-down',
    value: {
      sortField: SortFieldEnum.Name,
      sortOrder: SortOrderEnum.Desc,
    },
  },
  {
    label: 'Date',
    icon: 'icon icon-arrow-up',
    value: {
      sortField: SortFieldEnum.Date,
      sortOrder: SortOrderEnum.Asc,
    },
  },
  {
    label: 'Date',
    icon: 'icon icon-arrow-down',
    value: {
      sortField: SortFieldEnum.Date,
      sortOrder: SortOrderEnum.Desc,
    },
  },
];

export const templateLibraryOptions = [
  {
    label: 'Name',
    icon: 'icon icon-arrow-up',
    value: {
      sortField: SortFieldEnum.Name,
      sortOrder: SortOrderEnum.Asc,
    },
  },
  {
    label: 'Name',
    icon: 'icon icon-arrow-down',
    value: {
      sortField: SortFieldEnum.Name,
      sortOrder: SortOrderEnum.Desc,
    },
  },
];

export const projectsFilterOptions = [
  ...baseFilterOptions,
  ...completenessFilterOptions,
];
