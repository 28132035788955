import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { isArray, isNil } from 'lodash';
import { Button, Col, Row, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import cx from 'classnames';
import { useDidUpdate } from 'rooks';

import {
  ProgressTrackerTemplateCardResponseModel,
  ProgressTrackerTemplateCardResponseModelPagedResponseModel,
  SortFieldEnum,
  SortOrderEnum,
} from 'api';
import { AppDispatch } from 'redux/store';
import SortingFilter, {
  SortingFilterValueType,
  SortingMode,
} from 'components/filters/SortingFilter';
import noTemplates from 'assets/img/no-template.png';
import {
  getPreSetupTemplates,
  PreSetupTemplatesModel,
} from 'redux/reducers/templateSlice';
import SearchInput from 'components/filters/SearchInput';
import { MIN_SEARCH_LENGTH } from 'components/search/SearchLayout';
import TemplateCard from '../TemplateCard';
import EmptySearchResults from 'components/search/SearchResults/EmptySearchResults';
import NoInitialData from 'components/NoInitialData';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import { PageDefaultSize } from 'constants/ApiConstant';
import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { DispatchedRequestType } from 'utils/searchUtils';
import { TemplateEnum } from '../UserTemplatesListPage';
import { typeConversionToNumberArray } from 'utils/convertUtils';
import { isAdminSelector } from 'redux/selectors/authenticationSelectors';
import CategoriesFilter, {
  CategoryFilterOptions,
} from 'components/filters/CategoriesFilter';

enum TemplatesFiltersTypes {
  Name = 'name',
  Sorting = 'sorting',
  Category = 'category',
}

interface TemplatesFilters {
  [TemplatesFiltersTypes.Name]: string;
  [TemplatesFiltersTypes.Sorting]: SortingFilterValueType;
  [TemplatesFiltersTypes.Category]: number[];
}

type Props = {
  scroll: any;
};

export default function TemplateLibraryTab(props: Props) {
  const { scroll } = props;
  const isAdmin = useSelector(isAdminSelector);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
  const [myTemplateList, setMyTemplateList] = useState<
    ProgressTrackerTemplateCardResponseModel[]
  >([]);
  const isLightTheme = useSelector(isLightThemeSelector);

  const { name, sortField, sortOrder, tab, category } = queryString.parse(
    location.search,
  );
  const latestRequestRef = useRef<DispatchedRequestType>();

  const initialValues: TemplatesFilters = {
    [TemplatesFiltersTypes.Name]: !isArray(name) && !isNil(name) ? name : '',
    [TemplatesFiltersTypes.Category]: typeConversionToNumberArray(category),
    [TemplatesFiltersTypes.Sorting]: {
      sortField:
        !isArray(sortField) && !isNil(sortField)
          ? +sortField
          : SortFieldEnum.Name,
      sortOrder:
        !isArray(sortOrder) && !isNil(sortOrder)
          ? +sortOrder
          : SortOrderEnum.Asc,
    },
  };
  const [filters, setFilters] = useState<TemplatesFilters>(initialValues);

  const isSearchMode =
    filters[TemplatesFiltersTypes.Name].length >= MIN_SEARCH_LENGTH;

  const emptySearchResults = isSearchMode && isInitialDataLoaded && !totalCount;

  const notFoundData =
    isInitialDataLoaded && !totalCount && !emptySearchResults;

  useDidUpdate(() => {
    const queryData: any = {
      [TemplatesFiltersTypes.Category]: filters[TemplatesFiltersTypes.Category],
      [TemplatesFiltersTypes.Name]: filters[TemplatesFiltersTypes.Name],
      sortOrder: filters[TemplatesFiltersTypes.Sorting].sortOrder,
      sortField: filters[TemplatesFiltersTypes.Sorting].sortField,
    };

    if (!isAdmin) {
      queryData.tab = tab;
    }

    navigate(`${location.pathname}?${queryString.stringify(queryData)}`, {
      replace: true,
    });

    initInfinityScroll();
  }, [filters]);

  useEffect(() => {
    initInfinityScroll();
  }, []);

  const noInitialDataNode = (
    <div className="noData">
      <div className="image">
        <img src={noTemplates} alt="no-project" />
      </div>
      <div className="title">
        Connect your templates to their associated projects
      </div>
      <div>
        Create your first template by clicking on the “Create a template” button
      </div>
    </div>
  );

  const handleCategories = (categories: number[]) => {
    setFilters({
      ...filters,
      [TemplatesFiltersTypes.Category]: categories,
    });
  };

  const handleSearch = (query: string) => {
    setFilters({
      ...filters,
      [TemplatesFiltersTypes.Name]: query,
    });
  };

  const handleSort = (sortParams: SortingFilterValueType) => {
    setFilters({
      ...filters,
      [TemplatesFiltersTypes.Sorting]: sortParams,
    });
  };

  const onSetData = useCallback(
    (payload: ProgressTrackerTemplateCardResponseModelPagedResponseModel) => {
      const loadedData = payload.details || [];
      const totalCount = payload.totalCount || 0;
      const newData = [...myTemplateList, ...loadedData];
      const hasMoreRows =
        !!loadedData.length &&
        !!newData.length &&
        newData.length < totalCount &&
        loadedData.length > 0;
      setHasMore(hasMoreRows);
      setMyTemplateList(newData);
      setTotalCount(totalCount);
    },
    [myTemplateList],
  );

  const onLoadMore = useCallback(
    async (pageNumber: number) => {
      if (pageNumber === 0 && !isSearchMode) {
        setIsLoading(true);
      }
      latestRequestRef.current?.abort?.();

      const requestBody: PreSetupTemplatesModel = {
        isAdmin,
        pageNumber,
        pageSize: PageDefaultSize,
        name: filters[TemplatesFiltersTypes.Name],
        sortOrder: filters[TemplatesFiltersTypes.Sorting].sortOrder,
        categories: !filters[TemplatesFiltersTypes.Category].includes(
          CategoryFilterOptions.All,
        )
          ? filters[TemplatesFiltersTypes.Category]
          : [],
      };

      const request = dispatch(getPreSetupTemplates(requestBody));

      latestRequestRef.current = request;
      const result = await request;

      if (getPreSetupTemplates.fulfilled.match(result)) {
        onSetData(result.payload);
      } else {
        if (result.meta.aborted) return;

        setHasMore(false);
        AlertMessage.error(result.error.message || ApiErrorMessage);
      }

      setIsLoading(false);
      setIsInitialDataLoaded(true);
    },
    [dispatch, onSetData],
  );

  const initInfinityScroll = () => {
    setIsVisible(false);
    setMyTemplateList([]);
    setIsInitialDataLoaded(false);
    setHasMore(true);
    setTotalCount(0);

    const timer = setTimeout(() => {
      // to destroy SearchLayout
      setIsVisible(true);
    }, 0);

    return () => clearTimeout(timer);
  };

  return (
    <>
      <div
        className={cx('banner', {
          inverse: !isLightTheme,
        })}
      >
        <div className="banner__left">
          <div>
            <h1 className="banner__name mb-8">
              With a few clicks, you can make any template yours.
            </h1>
            <h1 className="banner__description">
              Whether you need a tracking dashboard for a legal case, wedding
              planning, college application, or an insurance claim, you can use
              Apdated's customizable and responsive templates to get started.
            </h1>
          </div>
        </div>
        <div className="banner__actions">
          {isAdmin && (
            <Link to="/template/create">
              <Button size="large" className="dark-btn">
                Create a template
              </Button>
            </Link>
          )}
        </div>
      </div>

      <div className="filters-actions">
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={12}>
            <div className="filters-actions__wrap">
              <SearchInput
                onChange={handleSearch}
                value={filters[TemplatesFiltersTypes.Name]}
                placeholder="Search templates"
              />
            </div>
          </Col>

          <Col xs={24} sm={8}>
            <CategoriesFilter
              onChange={handleCategories}
              value={filters[TemplatesFiltersTypes.Category]}
              placeholder="Select categories"
            />
          </Col>

          <Col xs={24} sm={4}>
            <div className="filters-actions__drop">
              <div>
                <SortingFilter
                  value={filters[TemplatesFiltersTypes.Sorting]}
                  onChange={handleSort}
                  mode={SortingMode.TemplateLibrary}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div>
        {emptySearchResults && <EmptySearchResults />}

        {notFoundData &&
          (noInitialDataNode || (
            <NoInitialData text="You currently do not have any templates." />
          ))}
      </div>

      {isVisible && (
        <InfiniteScroll
          initialLoad={!isInitialDataLoaded}
          pageStart={-1}
          maxLength={totalCount}
          loadMore={onLoadMore}
          hasMore={hasMore && !isLoading}
          loader={<Spin key={0} />}
          useWindow={false}
          getScrollParent={() => scroll.current}
          threshold={-200}
        >
          <Row gutter={[20, 20]}>
            {myTemplateList.map((template) => (
              <Col md={12} xs={24} key={template.id}>
                <Link to={`/template-library/${template.id}/details`}>
                  <TemplateCard
                    template={template}
                    mode={TemplateEnum.TemplateLibrary}
                  />
                </Link>
              </Col>
            ))}
          </Row>
        </InfiniteScroll>
      )}
    </>
  );
}
