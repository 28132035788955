import { useParams } from 'react-router-dom';
import { Spin } from 'antd';

import ProgressTrackerEdit, {
  ViewMode,
} from 'components/ProgressTracker/ProgressTrackerEdit';
import { PTDetailsMode, useFetchPTDetails } from 'hooks/useFetchPTDetails';

type ParamsProps = {
  projectId: string;
  progressTrackerId: string;
};

export default function ProgressTrackerEditPage() {
  const { projectId, progressTrackerId } = useParams<ParamsProps>();
  const [details, isLoading] = useFetchPTDetails(
    progressTrackerId || '',
    PTDetailsMode.Private,
  );

  if (isLoading) return <Spin />;

  if (!details) return null;

  return (
    <ProgressTrackerEdit
      projectId={projectId || ''}
      progressTrackerId={progressTrackerId}
      initialValues={details}
      mode={ViewMode.ProgressTracker}
    />
  );
}
