import React, { useRef } from 'react';
import { Select } from 'antd';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { DesktopScreenQuery } from 'constants/ScreenQuery';
import { useQueryMatch } from 'hooks/useQueryMatch';
import { getUserInfo } from 'redux/reducers/authenticationSlice';
import { getSpaceRole } from 'redux/reducers/roleSlice';
import { spaceIdSelector } from 'redux/selectors/authenticationSelectors';
import { availableSpacesSelector } from 'redux/selectors/spacesSelectors';
import { AppDispatch } from 'redux/store';

import styles from './ChangeSpaceDropdown.module.less';
import { setTrackerRestrictions } from 'redux/reducers/progressTrackerSlice';

type Props = {
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
  onChange?: () => void;
};

export default function ChangeSpaceDropdown(props: Props) {
  const isLightTheme = useSelector(isLightThemeSelector);
  const { className, onClick, onChange } = props;
  const availableSpaces = useSelector(availableSpacesSelector);
  const currentSpaceId = useSelector(spaceIdSelector);
  const selectContainer = useRef<HTMLDivElement | null>(null);
  const isDesktop = useQueryMatch(DesktopScreenQuery);

  const spaceOptions = availableSpaces.map((space) => {
    return { key: space.id, value: space.id, label: space.name };
  });

  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  if (!availableSpaces.length) return null;

  const fetchSpaceRole = async () => {
    const result = await dispatch(getSpaceRole());

    if (!getSpaceRole.fulfilled.match(result)) {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const availableSpacesChange = async (space: string) => {
    onChange?.();
    await dispatch(getUserInfo(space));
    await fetchSpaceRole();
    dispatch(setTrackerRestrictions());
    navigate('/');
  };

  return (
    <div
      ref={selectContainer}
      className={cx(
        styles.selectContainer,
        {
          [styles.dark]: !isLightTheme,
          [styles.selectMobileContainer]: isDesktop,
        },
        className,
      )}
      onClick={onClick}
    >
      <Select
        onChange={availableSpacesChange}
        value={currentSpaceId}
        size="large"
        getPopupContainer={() => selectContainer.current as HTMLElement}
        options={spaceOptions}
      />
    </div>
  );
}
