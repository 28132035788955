import React from 'react';

import CustomHalfPieChart from 'components/CustomHalfPieChart';
import { SpaceAnalyticsResponseModel } from 'api';

import styles from './TrackersCompletenessChart.module.less';

const COLORS = ['#5A7EF2', '#E6E6E6'];
const STROKE_COLORS = ['rgba(90, 126, 242, 0.30)', 'rgba(230,230,230,0.2)'];

type Props = {
  spaceAnalyticsInfo: SpaceAnalyticsResponseModel;
};

export default function TrackersCompletenessChart(props: Props) {
  const {
    spaceAnalyticsInfo: { trackersCount, trackersCompletedCount },
  } = props;
  const trackersUnCompletedCount =
    (trackersCount || 0) - (trackersCompletedCount || 0);

  const data = [
    {
      name: 'Complete',
      value: trackersCompletedCount || 0,
      textColor: '#FFF',
    },
    {
      name: 'In-progress',
      value: trackersUnCompletedCount || 0,
      textColor: '#242424',
    },
  ];

  return (
    <div className={styles.chart}>
      <div className={styles.chartBox}>
        <h1 className={styles.title}>Trackers progress</h1>
        <div className={styles.trackers}>
          <h2>{trackersCount || 0}</h2>
          <p>Total trackers</p>
        </div>

        <div className={styles.info}>
          {data.map((data, index) => (
            <div key={index} className={styles.item}>
              <div
                style={{ background: COLORS[index] }}
                className={styles.itemSquare}
              />
              <div>{data.name}</div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.pieChart}>
        <CustomHalfPieChart
          data={data}
          colors={COLORS}
          strokeColors={STROKE_COLORS}
        />
      </div>
    </div>
  );
}
