import React, { useCallback, useState } from 'react';
import { Button, Col, Input, Popover } from 'antd';
import { useFormContext } from 'react-hook-form';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import cx from 'classnames';

import Controller from 'components/form/Controller';
import { requiredMessage } from 'constants/ValidationConstants';
import StageList from 'features/template/UseTemplatesPage/StageList';
import { UseTemplateFormValues } from '../UseTemplatesPage';

import styles from '../UseTemplatesPage.module.less';
import classes from 'components/ProgressTracker/ProgressTrackerEdit/ProgressTrackerEdit.module.less';

type Props = {
  index: number;
  defaultValue?: string;
};

export default React.memo(function (props: Props) {
  const isLightTheme = useSelector(isLightThemeSelector);
  const { index, defaultValue } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const { control, formState } = useFormContext<UseTemplateFormValues>();
  const { errors } = formState;

  const handleOpenPopover = useCallback(() => setIsPopoverOpen(true), []);

  const handleClosePopover = useCallback(() => setIsPopoverOpen(false), []);

  return (
    <Popover
      open={isPopoverOpen}
      destroyTooltipOnHide={false}
      showArrow={false}
      trigger="click"
      placement="bottom"
      content={<StageList trackerIndex={index} />}
      overlayInnerStyle={{ width: 'max-content' }}
      overlayClassName={styles.popover}
    >
      <Col xs={24} md={12}>
        <div className={cx(styles.wrapper, { [styles.dark]: !isLightTheme })}>
          <Controller
            name={`trackers[${index}].name`}
            control={control}
            rules={{
              required: requiredMessage,
              maxLength: {
                value: 120,
                message: 'Customer name should be 120 characters maximum',
              },
            }}
            error={errors.trackers?.[index]?.name}
            defaultValue={defaultValue}
            render={(field) => (
              <Input
                {...field}
                placeholder="Enter customer name"
                prefix={
                  <i
                    className={cx(classes.cursor, styles.cursorPosition, {
                      [styles.darkCursor]: !isLightTheme,
                    })}
                  />
                }
              />
            )}
            containerClassName={styles.input}
          />

          <div className={styles.items}>
            {errors.trackers?.[index]?.stages && (
              <ExclamationCircleOutlined style={{ color: 'red' }} />
            )}
            <div onClick={handleOpenPopover}>
              <Button type="ghost">
                <i className="icon icon-pencil" />
              </Button>
            </div>

            {isPopoverOpen && (
              <div onClick={handleClosePopover} className={styles.btn}>
                <Button type="primary">Save</Button>
              </div>
            )}
          </div>
        </div>
      </Col>
    </Popover>
  );
});
