import React from 'react';
import { Progress } from 'antd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import cx from 'classnames';

import { FolderResponseModel } from 'api';
import { getPercentage } from 'utils/percentageUtils';
import { getCorrectStrokeColor } from 'utils/trackerUtils';

type Props = {
  project: FolderResponseModel;
};

export default function PTCard(props: Props) {
  const isLightTheme = useSelector(isLightThemeSelector);
  const { project } = props;
  const {
    id,
    name,
    completenessTotalCount = 0,
    progressTrackerTotalCount,
  } = project;

  return (
    <Link
      to={`/project/${id}`}
      className={cx('ticket', {
        inverse: !isLightTheme,
      })}
    >
      <div className="ticket__left">
        <div className="ticket__progress">
          <Progress
            type="circle"
            percent={getPercentage(completenessTotalCount)}
            format={(percent) => `${percent}%`}
            strokeColor={getCorrectStrokeColor(
              getPercentage(completenessTotalCount),
            )}
            strokeWidth={10}
            size={54}
          />
        </div>

        <div>
          <div className="ticket__name">{name}</div>
          <div className="ticket__item">
            <span>Trackers:</span> {progressTrackerTotalCount}
          </div>
        </div>
      </div>
    </Link>
  );
}
