import { Progress } from 'antd';

import { ProgressTrackerResponseModel } from 'api';
import { getDateForUserTimezone } from 'utils/dateUtils';
import { getPTDueDate, getPTPercentage, getStagesToDo } from './utils';

import styles from './DetailsSectionMinimized.module.less';

type Props = {
  details: ProgressTrackerResponseModel;
};

export default function DetailsSectionMinimized(props: Props) {
  const { details } = props;
  const dueDate = getPTDueDate(details);

  return (
    <div className={styles.detailsCardHead}>
      <div className={styles.detailsProgress}>
        <Progress
          type="circle"
          percent={getPTPercentage(details)}
          strokeColor="#F5DF4D"
          strokeWidth={12}
          size={82}
          format={(percent) => `${percent}%`}
        />
      </div>
      <div className={styles.detailsInfo}>
        <h3 className={styles.detailsInfoTitle}>Details</h3>
        <ul className={styles.detailsInfoList}>
          <li>
            <span>
              Created:
              {getDateForUserTimezone(details.createdDate)}
            </span>
          </li>
          {dueDate && (
            <li>
              <span>Due date: {dueDate}</span>
            </li>
          )}
          <li>
            <span>Stages remaining: {getStagesToDo(details)}</span>
          </li>
        </ul>
      </div>
    </div>
  );
}
