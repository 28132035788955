import { useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import cx from 'classnames';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { ActiveUserResponseModel } from 'api';
import AlertMessage from 'components/AlertMessage';
import Checkbox from 'components/form/Checkbox';
import Controller from 'components/form/Controller';
import PTModal, { PTModalProps } from 'components/PTModal';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { requiredMessage } from 'constants/ValidationConstants';
import { transferSpaceOwnership } from 'redux/reducers/spaceSlice';
import { getActiveUsersInSpace } from 'redux/reducers/userSlice';
import { spaceIdSelector } from 'redux/selectors/authenticationSelectors';
import { AppDispatch } from 'redux/store';

import styles from '../SecurityTab.module.less';

const validationSchema = Yup.object().shape({
  newOwner: Yup.string().required(requiredMessage),
  isGrantingConfirmed: Yup.boolean()
    .oneOf([true], 'Yoo must confirm transferring the ownership.')
    .required(requiredMessage),
});

type TransferOwnershipForm = {
  newOwner: string;
  isGrantingConfirmed: boolean;
};

type Props = {
  onClose: () => void;
} & Omit<PTModalProps, 'children'>;

export default function TransferOwnershipModal(props: Props) {
  const { onClose, open, ...otherModalProps } = props;

  const [users, setUsers] = useState<ActiveUserResponseModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const spaceId = useSelector(spaceIdSelector);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const { control, handleSubmit, formState, watch } =
    useForm<TransferOwnershipForm>({
      mode: 'all',
      resolver: yupResolver(validationSchema),
      defaultValues: {
        newOwner: undefined,
        isGrantingConfirmed: false,
      },
    });
  const { errors, isSubmitting, isValid } = formState;

  useEffect(() => {
    if (!open) return;

    getActiveUsers();
  }, [spaceId, open]);

  const getActiveUsers = async () => {
    setIsLoading(true);
    const result = await dispatch(getActiveUsersInSpace(spaceId || ''));

    if (getActiveUsersInSpace.fulfilled.match(result)) {
      setUsers(result.payload);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
    setIsLoading(false);
  };

  const onSubmit = async (values: TransferOwnershipForm) => {
    const result = await dispatch(
      transferSpaceOwnership({ spaceId, nextOwnerId: values.newOwner }),
    );

    if (transferSpaceOwnership.fulfilled.match(result)) {
      AlertMessage.success('Space ownership successfully transferred.');
      navigate('/');
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  return (
    <PTModal
      {...otherModalProps}
      open={open}
      className={cx('modal-root', styles.modalTitle)}
      title="Transfer ownership"
      onOk={handleSubmit(onSubmit)}
      okButtonProps={{
        disabled: !isValid,
      }}
      confirmLoading={isSubmitting}
      onCancel={onClose}
      zIndex={999}
    >
      <Form layout="vertical">
        <div className="modal-scroll-wrapper">
          <p>Transfer ownership of your Apdated space to another user.</p>

          <Controller
            name="newOwner"
            control={control}
            error={errors.newOwner}
            label="New space owner *"
            render={(field) => (
              <Select
                {...field}
                loading={isLoading}
                size="large"
                showSearch
                optionFilterProp="children"
                placeholder="Select new space owner"
                options={users.map((user, index) => ({
                  value: user.id || '',
                  label: `${user.firstName} ${user.lastName}`,
                  key: user.id || index,
                }))}
              />
            )}
          />

          <p>
            You will no longer be able to manage users, or subscriptions
            associated with your Apdated space.
          </p>

          <div>
            <Controller
              name="isGrantingConfirmed"
              control={control}
              error={errors.isGrantingConfirmed}
              render={(field) => <Checkbox {...field}>Confirm</Checkbox>}
            />
          </div>
        </div>
      </Form>
    </PTModal>
  );
}
