import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { currentSpaceIdByStoreSelector } from '../selectors/spacesSelectors';
import { PageSize } from 'constants/ApiConstant';

import apiClient, {
  CompletenessFilterEnum,
  CreateCommentRequestModel,
  FileParameter,
  FileTypeEnum,
  MoveProgressTrackerToFolderRequestModel,
  ProgressTrackerHistorySortFieldEnum,
  ProgressTrackerListRequestModel,
  ProgressTrackerRequestModel,
  ProgressTrackerSortFieldEnum,
  RestrictionResponseModel,
  SortFieldEnum,
  SortOrderEnum,
  StageStatusEnum,
  SubscribeProgressTrackerRequestModel,
  SubscribeTypeEnum,
} from 'api';
import { RootState } from 'redux/reducers/rootReducer';

interface ProgressTrackerState {
  isProgressTrackerFormDirty: boolean;
  isCustomLogoLoading: boolean;
  customLogoUrl?: string;
  trackerRestrictions?: RestrictionResponseModel;
  isTrackerRestrictionsLoading: boolean;
}

const initialState: ProgressTrackerState = {
  isProgressTrackerFormDirty: false,
  isCustomLogoLoading: false,
  customLogoUrl: undefined,
  trackerRestrictions: undefined,
  isTrackerRestrictionsLoading: false,
};

interface GetProgressTrackerListModel {
  projectId: string;
  completenessFilter: CompletenessFilterEnum;
  sortField: SortFieldEnum;
  sortOrder: SortOrderEnum;
  pageNumber: number;
  name?: string;
  requestId?: number;
  pageSize: number;
}

export const getProjectPTsPaged = createAsyncThunk(
  'pt/getProjectPTsPaged',
  async ({
    projectId,
    completenessFilter,
    sortField,
    sortOrder,
    pageNumber,
    name,
    pageSize,
  }: GetProgressTrackerListModel) => {
    return apiClient.getProgressTrackerList(
      projectId,
      completenessFilter,
      sortField,
      sortOrder,
      name,
      pageSize,
      pageNumber,
    );
  },
);

export interface GetTrackerCommentsModel {
  trackerId: string;
  pageSize: number;
  pageNumber: number;
}

export const getTrackerComments = createAsyncThunk(
  'pt/getTrackerComments',
  async ({ trackerId, pageNumber, pageSize }: GetTrackerCommentsModel) => {
    return apiClient.getProgressTrackerComments(
      trackerId,
      pageSize,
      pageNumber,
    );
  },
);

export const getTrackerCommentsForAdmin = createAsyncThunk(
  'pt/getTrackerCommentsForAdmin',
  async ({ trackerId, pageNumber, pageSize }: GetTrackerCommentsModel) => {
    return apiClient.getAdminProgressTrackerComments(
      trackerId,
      pageSize,
      pageNumber,
    );
  },
);

export interface AddCommentToTrackerModel {
  trackerId: string;
  body: CreateCommentRequestModel;
}

export const addCommentToTracker = createAsyncThunk(
  'pt/addCommentToTracker',
  async ({ trackerId, body }: AddCommentToTrackerModel) => {
    return apiClient.addCommentToProgressTracker(trackerId, body);
  },
);

interface GetProgressTrackersPagedListModel {
  completenessFilter: CompletenessFilterEnum;
  sortField: SortFieldEnum;
  sortOrder: SortOrderEnum;
  pageNumber: number;
  name?: string;
  requestId?: number;
}

export const getCsvTemplateLink = createAsyncThunk(
  'pt/getCsvTemplate',
  async () => {
    return apiClient.getCommonFile(FileTypeEnum.TrackerCreationTemplate);
  },
);

export const createTrackersFromCsv = createAsyncThunk(
  'pt/createTrackersFromCsv',
  async (file: FileParameter, { rejectWithValue }) => {
    //Check these functions for cases if API functions will be renamed
    //We need both these functions to exist to be confident in the
    //correct way of sending the request
    if (
      !apiClient.createProgressTrackerListByS3TemplateFile ||
      // @ts-ignore
      !apiClient.processCreateProgressTrackerListByS3TemplateFile
    )
      return rejectWithValue(undefined);

    const postFileUrl =
      process.env.REACT_APP_API_BASE_URL + '/progress-trackers/list/file';

    const formData = new FormData();
    formData.append('SpaceId', currentSpaceIdByStoreSelector());
    formData.append('File', file.data, file.fileName);

    //We can't use NSwag generated API, because there is no boundary in content-type header
    //Content-type detected automatically there
    return fetch(postFileUrl, {
      body: formData,
      method: 'POST',
      headers: {},
    }).then((response: Response) => {
      //https://github.com/RicoSuter/NSwag/issues/2493
      //We are using default response processor from NSwag
      // @ts-ignore
      return apiClient.processCreateProgressTrackerListByS3TemplateFile(
        response,
      );
    });
  },
);

export const getProgressTrackersPaged = createAsyncThunk(
  'pt/getProgressTrackersPaged',
  async ({
    completenessFilter,
    sortField,
    sortOrder,
    pageNumber,
    name,
  }: GetProgressTrackersPagedListModel) => {
    return apiClient.getProgressTrackersBySpace(
      completenessFilter,
      sortField,
      sortOrder,
      name,
      PageSize,
      pageNumber,
    );
  },
);

interface GetAdminProgressTrackersRequestModel {
  name?: string;
  sortField: ProgressTrackerSortFieldEnum;
  sortOrder: SortOrderEnum;
  pageNumber: number;
  pageSize: number;
}

export const getAdminProgressTrackers = createAsyncThunk(
  'pt/getAdminProgressTrackers',
  async ({
    name,
    sortField,
    sortOrder,
    pageNumber,
    pageSize,
  }: GetAdminProgressTrackersRequestModel) => {
    return apiClient.getAdminProgressTrackerList(
      name,
      sortField,
      sortOrder,
      pageSize,
      pageNumber,
    );
  },
);

export const getAdminTrackerDetails = createAsyncThunk(
  'pt/getAdminTrackerDetails',
  async (trackerId: string) => {
    return apiClient.getProgressTrackerDetailsByAdmin(trackerId);
  },
);

interface MoveProgressTrackerModel {
  progressTrackerId: string;
  body: MoveProgressTrackerToFolderRequestModel;
}

export const unsubscribeTrackerUpdates = createAsyncThunk(
  'pt/unsubscribeTrackerUpdates',
  async (subscribeId: string) => {
    return apiClient.unsubscribeOnProgressTracker(subscribeId);
  },
);

export const moveProgressTracker = createAsyncThunk(
  'pt/moveProgressTracker',
  async ({ progressTrackerId, body }: MoveProgressTrackerModel) => {
    return apiClient.moveProgressTrackerToFolder(progressTrackerId, body);
  },
);

export const deleteProgressTracker = createAsyncThunk(
  'pt/deleteProgressTracker',
  async (trackerId: string) => {
    return apiClient.markForDelete(trackerId);
  },
);

export const getPTDetails = createAsyncThunk(
  'pt/getPTDetails',
  async (id: string) => {
    return apiClient.getProgressTrackerDetails(id);
  },
);

export const getPTDetailsBySearch = createAsyncThunk(
  'pt/getPTDetailsBySearch',
  async (id: string) => {
    return apiClient.getProgressTrackerBySearch(id);
  },
);

export const createPT = createAsyncThunk(
  'pt/createPT',
  async (data: ProgressTrackerRequestModel) => {
    return apiClient.createProgressTracker({
      ...data,
      spaceId: currentSpaceIdByStoreSelector(),
    });
  },
);

export const updatePT = createAsyncThunk(
  'pt/updatePT',
  async (data: ProgressTrackerRequestModel) => {
    return apiClient.updateProgressTracker(data);
  },
);

export interface SetStageStatusModel {
  stageId: string;
  status: StageStatusEnum;
}

export const setStageStatus = createAsyncThunk(
  'pt/setStageStatus',
  async ({ stageId, status }: SetStageStatusModel) => {
    return apiClient.setStageStatus(stageId, { status });
  },
);

export const setStageStatusAdmin = createAsyncThunk(
  'pt/setStageStatusAdmin',
  async ({ stageId, status }: SetStageStatusModel) => {
    return apiClient.setStageStatusByAdmin(stageId, { status });
  },
);

export interface GetTrackerSubscribersRequestModel {
  progressTrackerId: string;
  type: SubscribeTypeEnum;
  pageSize: number;
  pageNumber: number;
  searchQuery?: string;
  spaceId?: string;
}

export const getTrackerSubscribers = createAsyncThunk(
  'pt/getTrackerSubscribers',
  async ({
    progressTrackerId,
    type,
    searchQuery,
    pageNumber,
    pageSize,
    spaceId,
  }: GetTrackerSubscribersRequestModel) => {
    return apiClient.getProgressTrackerSubscribersList(
      progressTrackerId,
      spaceId,
      type,
      searchQuery,
      pageSize,
      pageNumber,
    );
  },
);

export const getAdminTrackerSubscribers = createAsyncThunk(
  'pt/getTrackerSubscribers',
  async ({
    progressTrackerId,
    type,
    searchQuery,
    pageNumber,
    pageSize,
  }: GetTrackerSubscribersRequestModel) => {
    return apiClient.getAdminProgressTrackerSubscribersList(
      progressTrackerId,
      type,
      searchQuery,
      pageSize,
      pageNumber,
    );
  },
);

export const subscribeOnProgressTracker = createAsyncThunk(
  'pt/subscribeOnProgressTracker',
  async (data: SubscribeProgressTrackerRequestModel) => {
    return apiClient.subscribeOnProgressTracker(data);
  },
);

export const createProgressTrackerList = createAsyncThunk(
  'pt/createProgressTrackerList',
  async (data: ProgressTrackerListRequestModel) => {
    return apiClient.createProgressTrackerList(data);
  },
);

export const checkTrackersRestriction = createAsyncThunk(
  'pt/checkTrackersRestriction',
  async () => {
    return apiClient.checkTrackersRestriction(currentSpaceIdByStoreSelector());
  },
  {
    condition: (_, { getState }) => {
      const { progressTracker } = getState() as RootState;

      return !progressTracker.trackerRestrictions;
    },
  },
);

export interface GetTrackerHistoryRequestModel {
  trackerId: string;
  sortField: ProgressTrackerHistorySortFieldEnum;
  sortOrder: SortOrderEnum;
  pageSize: number;
  pageNumber: number;
  isAdmin?: boolean;
}

export const getTrackerHistory = createAsyncThunk(
  'pt/getTrackerHistory',
  async ({
    trackerId,
    sortField,
    sortOrder,
    pageNumber,
    pageSize,
    isAdmin,
  }: GetTrackerHistoryRequestModel) => {
    return isAdmin
      ? apiClient.getAdminProgressTrackerHistory(
          trackerId,
          sortField,
          sortOrder,
          pageSize,
          pageNumber,
        )
      : apiClient.getProgressTrackerHistory(
          trackerId,
          sortField,
          sortOrder,
          pageSize,
          pageNumber,
        );
  },
);

const progressTrackerSlice = createSlice({
  name: 'pt',
  initialState,
  reducers: {
    setProgressTrackerFormDirty: (state, action: PayloadAction<boolean>) => {
      state.isProgressTrackerFormDirty = action.payload;
    },
    setCustomLogoUrl: (state, action: PayloadAction<string | undefined>) => {
      state.customLogoUrl = action.payload;
    },
    setIsCustomLogoLoading: (state, action: PayloadAction<boolean>) => {
      state.isCustomLogoLoading = action.payload;
    },
    setTrackerRestrictions: (
      state,
      action: PayloadAction<RestrictionResponseModel | undefined>,
    ) => {
      state.trackerRestrictions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkTrackersRestriction.fulfilled, (state, action) => {
      state.trackerRestrictions = action.payload;
      state.isTrackerRestrictionsLoading = false;
    });
    builder.addCase(checkTrackersRestriction.pending, (state, action) => {
      state.isTrackerRestrictionsLoading = true;
    });
    builder.addCase(checkTrackersRestriction.rejected, (state, action) => {
      state.isTrackerRestrictionsLoading = false;
    });
  },
});

const { reducer, actions } = progressTrackerSlice;
export const {
  setProgressTrackerFormDirty,
  setCustomLogoUrl,
  setIsCustomLogoLoading,
  setTrackerRestrictions,
} = actions;

export default reducer;
