import { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { RolesEnum } from 'enums/Roles.enum';
import { hasRequiredRoleSelector } from 'redux/selectors/authenticationSelectors';

type Props = {
  children: ReactNode;
  allowedRoles: RolesEnum[];
};

export default function Accessor(props: Props) {
  const { children, allowedRoles } = props;
  const isAllowed = useSelector(hasRequiredRoleSelector(allowedRoles));

  if (!isAllowed) return null;

  return <>{children}</>;
}
