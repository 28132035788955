import { useCallback, useEffect, useRef } from 'react';

export const useInterval = (func: (arg?: any) => void, ms: number = 5000) => {
  const lastTimeout = useRef<number>();

  const clear = useCallback(() => {
    clearInterval(lastTimeout.current);
  }, []);

  const handleCall = useCallback(async () => {
    try {
      await func();

      // @ts-ignore
      lastTimeout.current = setTimeout(handleCall, ms);
    } catch (e) {
      clear();
    }
  }, [clear, func]);

  useEffect(() => {
    // @ts-ignore
    lastTimeout.current = setTimeout(handleCall, ms);

    return clear;
  }, [ms, handleCall]);

  return clear;
};
