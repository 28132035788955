export const requiredMessage = 'Field is required';

export const maxLength = 120;

export const twoFACodeMask = [/\d/, /\d/, /\d/,'-', /\d/, /\d/, /\d/];

export const PasswordValidationRules = {
  LowercaseCharacter: new RegExp(`[a-z]+`),
  UppercaseCharacter: new RegExp(`[A-Z]+`),
  SpecialCharacter: new RegExp(`[!"#$%&'()*+,-./:;<=>?@[\\]^_\`{|}~]+`),
  MinimumCharacters: new RegExp(`.{8,}`),
  MaximumCharacters: new RegExp(`^.{1,50}$`),
  OneNumber: new RegExp(`[0-9]+`),
};

export const StageNameRules = {
  required: requiredMessage,
  maxLength: {
    value: 30,
    message: 'Stage name should be 30 characters maximum',
  },
};

export const StageDueDateRules = {
  valueAsDate: true,
};

export const StageDescriptionRules = {
  required: requiredMessage,
  maxLength: {
    value: 280,
    message: 'Stage description should be 280 characters maximum',
  },
};
