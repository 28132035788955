import React from 'react';

export const confirmIcon = React.createElement('span', {
  className: 'icon icon-info-circle confirm-icon',
});

export const archiveTrackerTitle =
  'The tracker will be moved to the archive folder. Continue?';

export const deleteTrackerTitle = (daysToDelete: number) =>
  `The tracker will be moved to the deleted trackers folder and will be permanently deleted after ${daysToDelete} days. Continue?`;
