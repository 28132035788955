import isNil from 'lodash/isNil';

export const phoneFormatter = (value: string) => {
  const valueTrimmed = value.trim();
  let first = valueTrimmed[0];

  if (isNil(value) || isNil(first)) {
    return '';
  }

  if (first !== '+' && isNaN(+first)) {
    first = '';
  }

  const replacedWithoutFirst = valueTrimmed.slice(1).replace(/\D/g, '');
  const result = `${first}${replacedWithoutFirst}`;

  return result;
};
