import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncThunk } from '@reduxjs/toolkit';

import { AppDispatch } from 'redux/store';
import {
  getAdminTrackerDetails,
  getPTDetails,
  getPTDetailsBySearch,
  setCustomLogoUrl,
  setIsCustomLogoLoading,
} from 'redux/reducers/progressTrackerSlice';
import {
  AdminProgressTrackerDetailedResponseModel,
  ProgressTrackerResponseModel,
} from 'api';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import AlertMessage from 'components/AlertMessage';
import { getSpaceRole } from 'redux/reducers/roleSlice';
import { getUserInfo } from 'redux/reducers/authenticationSlice';
import { currentSpaceNameIdSelector } from 'redux/selectors/spacesSelectors';

export enum PTDetailsMode {
  Public = 1,
  Private = 2,
  Admin = 3,
}

export const useFetchPTDetails = (
  progressTrackerId: string,
  mode: PTDetailsMode,
  isInstantLoad: boolean = true,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const [details, setDetails] = useState<ProgressTrackerResponseModel>();
  const { spaceId } = useSelector(currentSpaceNameIdSelector);

  useEffect(() => {
    if (!isInstantLoad) return;
    const initialLoad = async () => {
      setIsLoading(true);
      await loadPTDetails();
      setIsLoading(false);
    };

    initialLoad();
    // eslint-disable-next-line
  }, [progressTrackerId, isInstantLoad]);

  useEffect(() => {
    if (mode === PTDetailsMode.Public || mode === PTDetailsMode.Admin) return;

    if (details?.spaceId && spaceId !== details.spaceId) {
      (async () => {
        const result = await dispatch(getUserInfo(details.spaceId));

        if (getUserInfo.fulfilled.match(result)) {
          dispatch(getSpaceRole());
        } else {
          AlertMessage.error(result.error.message || ApiErrorMessage);
        }
      })();
    }
  }, [details, dispatch, mode, spaceId]);

  const getCorrectAction = () => {
    switch (mode) {
      case PTDetailsMode.Private:
        return getPTDetails;
      case PTDetailsMode.Public:
        return getPTDetailsBySearch;
      case PTDetailsMode.Admin:
        return getAdminTrackerDetails;
    }
  };

  const handleLoadPTDetails = async (
    action: AsyncThunk<
      ProgressTrackerResponseModel | AdminProgressTrackerDetailedResponseModel,
      string,
      {}
    >,
  ) => {
    const result = await dispatch(action(progressTrackerId));

    if (!action.fulfilled.match(result)) {
      AlertMessage.error(result.error.message || ApiErrorMessage);
      return;
    }

    if (result.payload?.spaceLogoUrl) {
      dispatch(setCustomLogoUrl(result.payload.spaceLogoUrl));
    }

    setDetails(result.payload);
  };

  const handleLoadPublicPTDetails = async () => {
    dispatch(setIsCustomLogoLoading(true));
    await handleLoadPTDetails(getPTDetailsBySearch);
    dispatch(setIsCustomLogoLoading(false));
  };

  const loadPTDetails = useCallback(async () => {
    const action = getCorrectAction();

    if (mode === PTDetailsMode.Public) {
      await handleLoadPublicPTDetails();
    } else {
      await handleLoadPTDetails(action);
    }
  }, [mode, dispatch]);

  return [details, isLoading, loadPTDetails] as [
    ProgressTrackerResponseModel | AdminProgressTrackerDetailedResponseModel,
    boolean,
    () => Promise<void>,
  ];
};
