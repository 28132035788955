import React from 'react';

import { CardMode, CreditCard } from 'components/payments/CreditCard';
import { PaymentMethodResponseModel } from 'api';
import SettingsDetailsSection from 'components/settings/SettingsDetailsSection';
import { MAX_CARD_NUMBER } from 'constants/PricingConstants';

import styles from '../CreditCardsTab.module.less';

type Props = {
  cards: PaymentMethodResponseModel[];
  isError: boolean;
  handleAddNewCard: () => void;
  handleDeleteCreditCard: (id: string) => Promise<void>;
};

export default function CardListSection(props: Props) {
  const { cards, handleAddNewCard, handleDeleteCreditCard, isError } = props;

  return (
    <>
      <SettingsDetailsSection
        title="Manage payment info"
        description="Add your credit card for a faster checkout process."
      />

      <div className={styles.title}>Billing</div>
      <div className="card-list">
        {cards.map((card) => (
          <div className="card-box" key={card.id}>
            <CreditCard
              card={card}
              onDeleteCreditCard={handleDeleteCreditCard}
              mode={CardMode.CardList}
            />
          </div>
        ))}
        {!isError && cards.length < MAX_CARD_NUMBER && (
          <div className="card-box">
            <div className="card card--btn" onClick={handleAddNewCard}>
              <div className={styles.icon}>
                <i className="icon icon-plus" />
              </div>
              <div>Add new card</div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
