import React, { useRef } from 'react';
import { Dropdown, MenuProps } from 'antd';

import styles from '../filters.module.less';

import { CompletenessFilterEnum } from 'api';

export type CompletenessFilterType = {
  label: string;
  value: CompletenessFilterEnum;
};

export const completenessStatuses = [
  {
    label: 'All ',
    value: CompletenessFilterEnum.All,
  },
  {
    label: '0 ',
    value: CompletenessFilterEnum.Equal0,
  },
  {
    label: '< 20 ',
    value: CompletenessFilterEnum.EqualOrLesser20,
  },
  {
    label: '< 40 ',
    value: CompletenessFilterEnum.EqualOrLesser40,
  },
  {
    label: '< 60 ',
    value: CompletenessFilterEnum.EqualOrLesser60,
  },
  {
    label: '< 80 ',
    value: CompletenessFilterEnum.EqualOrLesser80,
  },
  {
    label: '100 ',
    value: CompletenessFilterEnum.Equal100,
  },
];

type Props = {
  onChange: (value: CompletenessFilterEnum) => void;
  value: CompletenessFilterEnum;
};

export default function CompletenessFilter(props: Props) {
  const { value, onChange } = props;
  const selectContainer: any = useRef();

  const currentCompletenessStatus = completenessStatuses.find(
    (completenessStatus) => completenessStatus.value === value,
  );

  const filterOptions: MenuProps['items'] = completenessStatuses.map(
    (status, index) => ({
      key: `completeness-filter-item-${index}`,
      label: <div onClick={() => onChange(status.value)}>{status.label}%</div>,
    }),
  );

  return (
    <div ref={selectContainer}>
      <Dropdown
        menu={{ items: filterOptions }}
        trigger={['click']}
        placement="bottomRight"
        getPopupContainer={() => selectContainer.current}
      >
        <button className={styles.filterOpener}>
          {currentCompletenessStatus?.label}
          <span> %</span>
        </button>
      </Dropdown>
    </div>
  );
}
