import React, { ForwardedRef } from 'react';
import { Input } from 'antd';
import { ControllerRenderProps } from 'react-hook-form';
import $MaskedInput, { MaskedInputProps } from 'react-text-mask';
import { InputProps } from 'antd/lib/input';

type Props<T> = ControllerRenderProps<Record<string, T>> &
  MaskedInputProps &
  InputProps;

export default React.forwardRef(function <T>(
  props: Props<T>,
  ref: ForwardedRef<$MaskedInput>,
) {
  const { size, showMask = true, guide = false, ...restProps } = props;

  return (
    <$MaskedInput
      {...restProps}
      size={size}
      guide={guide}
      showMask={showMask}
      ref={ref}
      render={(ref, props) => (
        <Input
          {...restProps}
          {...props}
          ref={(input: any) => ref(input && input.input)}
        />
      )}
    />
  );
});
