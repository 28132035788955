import cx from 'classnames';
import { isString } from 'lodash';
import {
  toast,
  ToastContent,
  ToastContentProps,
  ToastOptions,
  TypeOptions,
} from 'react-toastify';

import {
  BASE_ALERTS_CONTAINER_ID,
  DEFAULT_TOAST_DURATION,
} from 'utils/toastUtils';

import styles from './AlertMessage.module.less';

type OwnProps = {
  type: TypeOptions;
  message: ToastContent;
};

type Props = OwnProps & Partial<ToastContentProps> & ToastOptions;

enum TYPE {
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
  DEFAULT = 'default',
}

const ALERT_DEFAULT_OPTIONS: ToastOptions = {
  containerId: BASE_ALERTS_CONTAINER_ID,
};

const getAlertIcon = (type?: TypeOptions) => {
  switch (type) {
    case TYPE.WARNING:
      return 'icon-danger';

    case TYPE.ERROR:
      return 'icon-close-square';

    case TYPE.SUCCESS:
      return 'icon-check';

    case TYPE.DEFAULT:
    default:
      return '';
  }
};

const getAlertTitle = (type?: TypeOptions) => {
  switch (type) {
    case TYPE.WARNING:
      return 'Please review!';

    case TYPE.ERROR:
      return 'Something went wrong.';

    case TYPE.SUCCESS:
      return 'Success!';

    case TYPE.DEFAULT:
    default:
      return '';
  }
};

function AlertComponent(props: Props) {
  const { type, closeToast, message, closeButton = true, className } = props;
  const alertIcon = getAlertIcon(type);
  const alertTitle = getAlertTitle(type);

  return (
    <div
      className={cx(styles.alertMessage, isString(className) ? className : '')}
    >
      {alertIcon && (
        <span
          className={cx(
            'icon Toastify__toast-icon',
            alertIcon,
            styles.alertIcon,
          )}
        />
      )}

      <div className={styles.alertBox}>
        {alertTitle && <div className={styles.alertTitle}>{alertTitle}</div>}
        <div className={styles.alertText}>{message}</div>
      </div>

      {closeButton && (
        <div className={styles.alertClose}>
          <span className="icon icon-close" onClick={closeToast} />
        </div>
      )}
    </div>
  );
}

const AlertMessage = (message: ToastContent, options: ToastOptions) => {
  const alertOptions = { ...ALERT_DEFAULT_OPTIONS, ...options };

  return toast(
    <AlertComponent {...alertOptions} type={TYPE.DEFAULT} message={message} />,
    alertOptions,
  );
};

AlertMessage.success = (message: ToastContent, options?: ToastOptions) => {
  const autoClose = options?.autoClose || DEFAULT_TOAST_DURATION;
  const alertOptions = {
    ...ALERT_DEFAULT_OPTIONS,
    ...options,
    className: 'successMessage',
  };

  return toast.success(
    <AlertComponent
      {...alertOptions}
      autoClose={autoClose}
      type={TYPE.SUCCESS}
      message={message}
    />,
    { ...alertOptions, autoClose },
  );
};

AlertMessage.error = (message: ToastContent, options?: ToastOptions) => {
  const alertOptions = {
    ...ALERT_DEFAULT_OPTIONS,
    ...options,
    autoClose: DEFAULT_TOAST_DURATION,
    className: 'errorMessage',
  };

  return toast.error(
    <AlertComponent {...alertOptions} type={TYPE.ERROR} message={message} />,
    alertOptions,
  );
};

AlertMessage.warning = (message: ToastContent, options?: ToastOptions) => {
  const alertOptions = {
    ...ALERT_DEFAULT_OPTIONS,
    ...options,
    className: 'warningMessage',
  };

  return toast.warning(
    <AlertComponent {...alertOptions} type={TYPE.WARNING} message={message} />,
    alertOptions,
  );
};

AlertMessage.dismiss = toast.dismiss;

export default AlertMessage;
