import React, { Ref, useCallback } from 'react';
import { Switch as $Switch } from 'antd';
import { SwitchProps } from 'antd/lib/switch';
import { ControllerRenderProps } from 'react-hook-form';

type Props = SwitchProps & ControllerRenderProps;

export default React.forwardRef((props: Props, ref: Ref<HTMLInputElement>) => {
  const { onChange: $onChange, name, value, ...other } = props;

  const onChange = useCallback(
    (checked: boolean) => {
      $onChange(checked);
    },
    [$onChange],
  );

  return <$Switch checked={value} onChange={onChange} {...other} ref={ref} />;
});
