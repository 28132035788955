import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { App, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import AlertMessage from 'components/AlertMessage';
import Controller from 'components/form/Controller';

import PTModal, { PTModalProps } from 'components/PTModal';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { createProject, updateProject } from 'redux/reducers/projectSlice';
import { AppDispatch } from 'redux/store';
import { ProjectInfoSimpleModel } from '../ProjectPage';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

import styles from './ProjectModal.module.less';

type Props = {
  onClose: () => void;
  mode: ProjectModalMode;
  projectInfo?: ProjectInfoSimpleModel;
  onUpdateProjectInfo?: () => void;
} & Omit<PTModalProps, 'children'>;

export interface ProjectFormValues {
  projectName: string;
}

export enum ProjectModalMode {
  ADD = 1,
  EDIT = 2,
}

const validationSchema = Yup.object().shape({
  projectName: Yup.string()
    .trim()
    .required('Field is required')
    .max(30, 'Project name should be 30 characters maximum'),
});

export default function ProjectModal(props: Props) {
  const {
    onClose,
    mode,
    projectInfo,
    onUpdateProjectInfo,
    open = true,
    ...otherModalProps
  } = props;
  const navigate = useNavigate();
  const isEditMode = mode === ProjectModalMode.EDIT;
  const isLightTheme = useSelector(isLightThemeSelector);

  const { errors, control, formState, handleSubmit, trigger } =
    useForm<ProjectFormValues>({
      resolver: yupResolver(validationSchema),
      mode: 'all',
      defaultValues: getInitialValues(),
    });
  const { isSubmitting, isDirty } = formState;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const { modal } = App.useApp();

  function getInitialValues(): ProjectFormValues {
    if (!isEditMode) {
      return {
        projectName: '',
      };
    }
    return {
      projectName: projectInfo?.projectName || '',
    };
  }

  const onCreate = async ({ projectName }: ProjectFormValues) => {
    setIsLoading(true);

    const result = await dispatch(
      createProject({
        name: projectName,
      }),
    );

    setIsLoading(false);

    if (!createProject.fulfilled.match(result)) {
      AlertMessage.error(result.error.message || ApiErrorMessage);
      return;
    }

    AlertMessage.success('Project successfully added.');
    navigate(`/project/${result.payload}`);

    onClose();
  };

  const onEdit = async ({ projectName }: ProjectFormValues) => {
    setIsLoading(true);

    const result = await dispatch(
      updateProject({
        name: projectName,
        folderId: projectInfo?.projectId,
      }),
    );

    setIsLoading(false);

    if (!updateProject.fulfilled.match(result)) {
      AlertMessage.error(result.error.message || ApiErrorMessage);
      return;
    }

    AlertMessage.success('Project successfully updated.');
    onUpdateProjectInfo && onUpdateProjectInfo();

    onClose();
  };

  const onSubmit = async (values: ProjectFormValues) => {
    switch (mode) {
      case ProjectModalMode.EDIT:
        return onEdit(values);
      case ProjectModalMode.ADD:
        return onCreate(values);
    }
  };

  const handleOk = () => {
    trigger();
    handleSubmit(onSubmit)();
  };

  const handleModalCancel = () => {
    if (isSubmitting) return;

    if (isDirty) {
      return modal.confirm({
        title: 'Changes won’t be saved. Are you sure?',
        icon: <span className="icon icon-info-circle confirm-icon" />,
        className: cx('confirm-modal', {
          'confirm-modal-dark-button': !isLightTheme,
        }),
        width: '496px',
        onOk() {
          onClose();
        },
        onCancel() {},
      });
    }
    onClose();
  };

  const getCorrectTitle = () => {
    switch (mode) {
      case ProjectModalMode.ADD:
        return 'Create a project';
      case ProjectModalMode.EDIT:
        return 'Edit project';
    }
  };

  return (
    <PTModal
      {...otherModalProps}
      open={open}
      cancelText="Cancel"
      okText="Save"
      onOk={handleOk}
      onCancel={handleModalCancel}
      okButtonProps={{ loading: isLoading }}
      title={getCorrectTitle()}
      closable
      className={cx('modal-root', styles.modalTitle)}
    >
      <Form layout="vertical">
        <div className="modal-scroll-wrapper">
          <Controller
            name="projectName"
            control={control}
            error={errors.projectName}
            label="Name this project (e.g. Permit Applications)"
            placeholder="Enter project name"
            as={Input}
            size="large"
            containerClassName={cx('dark-input-group', {
              'input-bg': isLightTheme,
            })}
          />
        </div>
      </Form>
    </PTModal>
  );
}
