import React from 'react';
import { Input } from 'antd';
import { useSelector } from 'react-redux';

import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import cx from 'classnames';

type Props = {
  onChange: (data: string) => void;
  value: string;
  placeholder?: string;
};

export default function SearchInput(props: Props) {
  const { onChange, value, placeholder } = props;
  const isLightTheme = useSelector(isLightThemeSelector);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value.trimStart());
  };

  return (
    <Input
      size="large"
      style={{ width: '100%' }}
      className={cx({
        'filters-actions__search': isLightTheme,
        'filters-actions__search_dark': !isLightTheme,
        lightSearch: isLightTheme,
        darkSearch: !isLightTheme,
      })}
      value={value}
      placeholder={placeholder}
      onChange={handleChange}
      prefix={<i className="icon icon-search" />}
      allowClear
    />
  );
}
