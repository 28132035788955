import React from 'react';
import { Collapse } from 'antd';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { Prices, SubscriptionTypes } from '../PricingTableDesktop';
import { SubscriptionResponseModel, SubscriptionTypeEnum } from 'api';
import SubscriptionButton from 'components/SubscriptionButton';
import { userSelector } from 'redux/selectors/authenticationSelectors';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';


import styles from '../PricingTab.module.less';

const { Panel } = Collapse;

type Props = {
  prices: Prices;
  subscriptions: SubscriptionTypes;
  onUpgradeSubscription?: (subscription: SubscriptionResponseModel) => void;
  onDowngradeSubscription?: (subscription: SubscriptionResponseModel) => void;
};

export default function PricingTableMobile(props: Props) {
  const isLightTheme = useSelector(isLightThemeSelector);
  const {
    prices,
    subscriptions,
    onDowngradeSubscription,
    onUpgradeSubscription,
  } = props;
  const { basicPrice, standardPrice, advancedPrice } = prices;
  const {
    basicSubscription,
    standardSubscription,
    advancedSubscription,
    enterpriseSubscription,
  } = subscriptions;
  const user = useSelector(userSelector);

  const isBasicPlan = user?.subscriptionType === SubscriptionTypeEnum.Basic;
  const isStandardPlan =
    user?.subscriptionType === SubscriptionTypeEnum.Standard;
  const isAdvancedPlan =
    user?.subscriptionType === SubscriptionTypeEnum.Advanced;
  const isEnterprisePlan =
    user?.subscriptionType === SubscriptionTypeEnum.Enterprise;

  return (
    <div className="modal-scroll-wrapper">
      <div className={styles.collapse}>
        <Collapse collapsible="header">
          <Panel
            showArrow={false}
            header={
              <div
                className={cx(styles.collapseHead, {
                  [styles.collapseHeadAccent]: isBasicPlan,
                  [styles.dark]: !isLightTheme,
                })}
              >
                <div className={styles.collapseHeadTransform}>
                  Price - <strong>$</strong>
                  <strong>0</strong> per month
                </div>
                <div>
                  <div className={styles.collapseHeadTitle}>
                    {basicSubscription?.name?.toUpperCase()}
                  </div>
                  <div className={styles.collapseHeadText}>
                    <strong>$</strong>
                    <strong className={styles.collapseHeadPrice}>
                      {basicPrice}
                    </strong>{' '}
                    per month
                  </div>
                </div>
              </div>
            }
            key={basicSubscription?.id || ''}
          >
            <div className={styles.tableBody}>
              <div className={styles.tableRow}>
                <div className={styles.tableCol}>Number of trackers</div>
                <div className={styles.tableCol}>
                  {basicSubscription?.numberOfTrackers}
                </div>
              </div>
              <div className={styles.tableRow}>
                <div className={styles.tableCol}>Number of projects</div>
                <div className={styles.tableCol}>
                  {basicSubscription?.numberOfProjects}
                </div>
              </div>
              <div className={styles.tableRow}>
                <div className={styles.tableCol}>Users</div>
                <div className={styles.tableCol}>Only you</div>
              </div>
              <div className={styles.tableRow}>
                <div className={styles.tableCol}>Reusable Templates</div>
                <div className={styles.tableCol}></div>
              </div>
            </div>
          </Panel>
        </Collapse>
        <Collapse collapsible="header">
          <Panel
            showArrow={false}
            header={
              <div
                className={cx(styles.collapseHead, {
                  [styles.collapseHeadAccent]: isStandardPlan,
                  [styles.dark]: !isLightTheme,
                })}
              >
                <div className={styles.collapseHeadTransform}>
                  Price - <strong>$</strong>
                  <strong>{standardPrice}</strong> per month
                </div>
                <div>
                  <div className={styles.collapseHeadTitle}>
                    {standardSubscription?.name?.toUpperCase()}
                  </div>
                  <div className={styles.collapseHeadText}>
                    <strong>$</strong>
                    <strong className={styles.collapseHeadPrice}>
                      {standardPrice}
                    </strong>{' '}
                    per month
                  </div>
                  <div>
                    {onDowngradeSubscription && onUpgradeSubscription && (
                      <SubscriptionButton
                        subscription={standardSubscription}
                        onUpgrade={onUpgradeSubscription}
                        onDowngrade={onDowngradeSubscription}
                      />
                    )}
                  </div>
                </div>
              </div>
            }
            key={standardSubscription?.id || ''}
          >
            <div className={styles.tableBody}>
              <div className={styles.tableRow}>
                <div className={styles.tableCol}>Number of trackers</div>
                <div className={styles.tableCol}>
                  {standardSubscription?.numberOfTrackers} per month
                </div>
              </div>
              <div className={styles.tableRow}>
                <div className={styles.tableCol}>Number of projects</div>
                <div className={styles.tableCol}>
                  {standardSubscription?.numberOfProjects} per month
                </div>
              </div>
              <div className={styles.tableRow}>
                <div className={styles.tableCol}>Users</div>
                <div className={styles.tableCol}>
                  Up to {standardSubscription?.memberCount} users
                </div>
              </div>
              <div className={styles.tableRow}>
                <div className={styles.tableCol}>Reusable Templates</div>
                <div className={styles.tableCol}>
                  <i className="icon icon-check-single"></i>
                </div>
              </div>
            </div>
          </Panel>
        </Collapse>
        <Collapse collapsible="header">
          <Panel
            showArrow={false}
            header={
              <div
                className={cx(styles.collapseHead, {
                  [styles.collapseHeadAccent]: isAdvancedPlan,
                  [styles.dark]: !isLightTheme,
                })}
              >
                <div className={styles.collapseHeadTransform}>
                  Price - <strong>$</strong>
                  <strong>{advancedPrice}</strong> per month
                </div>
                <div>
                  <div className={styles.collapseHeadTitle}>
                    {advancedSubscription?.name?.toUpperCase()}
                  </div>
                  <div className={styles.collapseHeadText}>
                    <strong>$</strong>
                    <strong className={styles.collapseHeadPrice}>
                      {advancedPrice}
                    </strong>{' '}
                    per month
                  </div>
                  <div>
                    {onDowngradeSubscription && onUpgradeSubscription && (
                      <SubscriptionButton
                        subscription={advancedSubscription}
                        onUpgrade={onUpgradeSubscription}
                        onDowngrade={onDowngradeSubscription}
                      />
                    )}
                  </div>
                </div>
              </div>
            }
            key={advancedSubscription?.id || ''}
          >
            <div className={styles.tableBody}>
              <div className={styles.tableRow}>
                <div className={styles.tableCol}>Number of trackers</div>
                <div className={styles.tableCol}>
                  {advancedSubscription?.numberOfTrackers} per month
                </div>
              </div>
              <div className={styles.tableRow}>
                <div className={styles.tableCol}>Number of projects</div>
                <div className={styles.tableCol}>
                  {advancedSubscription?.numberOfProjects} per month
                </div>
              </div>
              <div className={styles.tableRow}>
                <div className={styles.tableCol}>Users</div>
                <div className={styles.tableCol}>
                  Up to {advancedSubscription?.memberCount} users
                </div>
              </div>
              <div className={styles.tableRow}>
                <div className={styles.tableCol}>Reusable Templates</div>
                <div className={styles.tableCol}>
                  <i className="icon icon-check-single"></i>
                </div>
              </div>
            </div>
          </Panel>
        </Collapse>
        <Collapse collapsible="header">
          <Panel
            showArrow={false}
            header={
              <div
                className={cx(styles.collapseHead, {
                  [styles.collapseHeadAccent]: isEnterprisePlan,
                  [styles.dark]: !isLightTheme,
                })}
              >
                <div className={styles.collapseHeadTransform}>Contact Us</div>
                <div>
                  <div className={styles.collapseHeadTitle}>
                    {enterpriseSubscription?.name?.toUpperCase()}
                  </div>
                  <div className={styles.collapseHeadText}>
                    <strong className={styles.collapseHeadPrice}>
                      Contact Us
                    </strong>
                  </div>
                  <div>
                    {onDowngradeSubscription && onUpgradeSubscription && (
                      <SubscriptionButton
                        subscription={enterpriseSubscription}
                        onUpgrade={onUpgradeSubscription}
                        onDowngrade={onDowngradeSubscription}
                      />
                    )}
                  </div>
                </div>
              </div>
            }
            key={enterpriseSubscription?.id || ''}
          >
            <div className={styles.tableBody}>
              <div className={styles.tableRow}>
                <div className={styles.tableCol}>Number of trackers</div>
                <div className={styles.tableCol}>Unlimited</div>
              </div>
              <div className={styles.tableRow}>
                <div className={styles.tableCol}>Number of projects</div>
                <div className={styles.tableCol}>Unlimited</div>
              </div>
              <div className={styles.tableRow}>
                <div className={styles.tableCol}>Users</div>
                <div className={styles.tableCol}>Unlimited</div>
              </div>
              <div className={styles.tableRow}>
                <div className={styles.tableCol}>Reusable Templates</div>
                <div className={styles.tableCol}>
                  <i className="icon icon-check-single"></i>
                </div>
              </div>
            </div>
          </Panel>
        </Collapse>
      </div>
    </div>
  );
}
