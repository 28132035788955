import React, { Ref, useCallback } from 'react';
import { Checkbox as $Checkbox } from 'antd';
import { CheckboxChangeEvent, CheckboxProps } from 'antd/lib/checkbox/Checkbox';
import { ControllerRenderProps } from 'react-hook-form';

type Props = CheckboxProps & ControllerRenderProps;

export default React.forwardRef((props: Props, ref: Ref<HTMLInputElement>) => {
  const { onChange: $onChange, name, value, ...other } = props;

  const onChange = useCallback(
    (e: CheckboxChangeEvent) => {
      const value = e.target.checked;
      $onChange(value);
    },
    [$onChange],
  );

  return (
    <$Checkbox
      name={name}
      checked={value}
      onChange={onChange}
      {...other}
      ref={ref}
    />
  );
});
