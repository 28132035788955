import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { App, Skeleton } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { isArray, isNil, unset } from 'lodash';
import cx from 'classnames';

import { deleteProgressTracker } from 'redux/reducers/progressTrackerSlice';
import { AppDispatch } from 'redux/store';
import {
  archiveTracker,
  getUserGroupListForProject,
  UserGroupListBySpaceIdOptions,
} from 'redux/reducers/projectSlice';
import {
  CompletenessFilterEnum,
  ProgressTrackerCardModel,
  ProgressTrackerCardResponseModel,
  SortFieldEnum,
  SortOrderEnum,
  UserGroupListResponseModel,
} from 'api';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import ActionsBox from 'components/ActionsBox';
import Breadcrumb from 'components/Breadcrumb';
import AlertMessage from 'components/AlertMessage';
import CompletenessFilter from 'components/filters/CompletenessFilter';
import SortingFilter, {
  SortingFilterValueType,
  SortingMode,
} from 'components/filters/SortingFilter';
import SearchInput from 'components/filters/SearchInput';
import SearchLayout, {
  ChildrenProps,
  SearchLayoutMode,
} from 'components/search/SearchLayout';
import {
  archiveTrackerTitle,
  confirmIcon,
  deleteTrackerTitle,
} from 'constants/ConfirmationConstants';
import ProjectList from 'features/project/ProjectPage/ProjectList';
import ProjectModal, { ProjectModalMode } from 'features/project/ProjectModal';
import { userSelector } from 'redux/selectors/authenticationSelectors';
import { getDaysToDelete } from 'utils/confirmationUtils';
import { PageDefaultSize } from 'constants/ApiConstant';
import { isTrackerManagerSelector } from 'redux/selectors/roleSelectors';
import TeammateAvatarList from './TeammateAvatarList';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

import noTracker from 'assets/img/no-trackers.png';
import classes from './ProjectPage.module.less';
import styles from 'components/search/SearchResults/SearchResults.module.less';

enum ProjectTrackersFiltersTypes {
  TrackerName = 'trackerName',
  Sorting = 'sorting',
  Completeness = 'completeness',
}

interface ProjectTrackersFilters {
  [ProjectTrackersFiltersTypes.TrackerName]: string;
  [ProjectTrackersFiltersTypes.Sorting]: SortingFilterValueType;
  [ProjectTrackersFiltersTypes.Completeness]: CompletenessFilterEnum;
}

export interface ProjectInfoSimpleModel {
  projectName?: string;
  projectId: string;
}

type ParamsProps = {
  projectId: string;
};

export default function ProjectPage() {
  const [templateId, setTemplateId] = useState<string>();
  const [isFilterUsed, setIsFilterUsed] = useState(false);
  const [isInitInfScrollNeeded, setIsInitInfScrollNeeded] = useState(false);
  const [projectInfo, setProjectInfo] = useState<ProjectInfoSimpleModel>();
  const [projectName, setProjectName] = useState<string>();
  const dispatch: AppDispatch = useDispatch();
  const { modal } = App.useApp();
  const location = useLocation();
  const { projectId } = useParams<ParamsProps>();
  const user = useSelector(userSelector);
  const isLightTheme = useSelector(isLightThemeSelector);
  const isTeammate = useSelector(isTrackerManagerSelector);
  const [isInitialListOfTeammateLoaded, setInitialListOfTeammateLoaded] =
    useState(false);
  const [listOfUserGroup, setListOfUserGroup] = useState<
    UserGroupListResponseModel[]
  >([]);
  const { trackerName, sortField, sortOrder, completeness } = queryString.parse(
    location.search,
  );

  const initialValues: ProjectTrackersFilters = {
    [ProjectTrackersFiltersTypes.TrackerName]:
      !isArray(trackerName) && !isNil(trackerName) ? trackerName : '',
    [ProjectTrackersFiltersTypes.Sorting]: {
      sortField:
        !isArray(sortField) && !isNil(sortField)
          ? +sortField
          : SortFieldEnum.Name,
      sortOrder:
        !isArray(sortOrder) && !isNil(sortOrder)
          ? +sortOrder
          : SortOrderEnum.Asc,
    },
    [ProjectTrackersFiltersTypes.Completeness]:
      !isArray(completeness) && !isNil(completeness)
        ? +completeness
        : CompletenessFilterEnum.All,
  };
  const [filters, setFilters] = useState<ProjectTrackersFilters>(initialValues);

  const noInitialDataNode = (
    <div className="noData">
      <div className="image">
        <img src={noTracker} alt="no-project" />
      </div>
      <div className="title">
        A consistent and predictable journey creates customer confidence
      </div>
      <div className={cx('text', classes.noTrackersText)}>
        Click on the “Create a tracker” button, or add one from an existing{' '}
        <span>
          <Link to="/templates">template</Link>
        </span>
      </div>
    </div>
  );

  const enableInitInfScroll = () => setIsInitInfScrollNeeded(false);

  const initInfScroll = () => setIsInitInfScrollNeeded(true);

  const renderBreadcrumb = (projectName?: string) => (
    <Breadcrumb
      routes={[
        { title: <i className="icon icon-home" />, link: '/' },
        { title: projectName || '' },
      ]}
    />
  );

  const onChangeCompletenessFilter = (value: CompletenessFilterEnum) => {
    setFilters({
      ...filters,
      [ProjectTrackersFiltersTypes.Completeness]: value,
    });
    setIsFilterUsed(true);
  };

  const handleSearch = (query: string) => {
    setFilters({
      ...filters,
      [ProjectTrackersFiltersTypes.TrackerName]: query,
    });
  };

  const handleSort = (sortParams: SortingFilterValueType) => {
    setFilters({
      ...filters,
      [ProjectTrackersFiltersTypes.Sorting]: sortParams,
    });
  };

  const onDeleteProgressTracker = useCallback(
    async (id?: string) => {
      const result = await dispatch(deleteProgressTracker(id as string));

      if (deleteProgressTracker.fulfilled.match(result)) {
        initInfScroll();
        AlertMessage.success('Tracker was successfully deleted.');
      } else {
        AlertMessage.error(result.error.message || ApiErrorMessage);
      }
    },
    [dispatch, projectId],
  );

  const handleDeleteClick = (trackerId: string) => {
    const daysToDelete = getDaysToDelete(user?.subscriptionType);

    return modal.confirm({
      title: deleteTrackerTitle(daysToDelete),
      icon: confirmIcon,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      async onOk() {
        await onDeleteProgressTracker(trackerId);
      },
      onCancel() {},
      okText: 'Yes',
    });
  };

  const onArchiveProgressTracker = useCallback(
    async (id?: string) => {
      if (!id) return;

      const result = await dispatch(archiveTracker(id));

      if (!archiveTracker.fulfilled.match(result)) {
        AlertMessage.error(result.error.message || ApiErrorMessage);
        return;
      }

      initInfScroll();
      AlertMessage.success('Tracker was successfully archived.');
    },
    [dispatch, projectId],
  );

  const handleArchiveTracker = (trackerId: string) => {
    return modal.confirm({
      title: archiveTrackerTitle,
      icon: confirmIcon,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      async onOk() {
        await onArchiveProgressTracker(trackerId);
      },
      okText: 'Yes',
      onCancel() {},
    });
  };

  const handleDisplayProjectModal = () => {
    setProjectInfo({
      projectName: projectName || '',
      projectId: projectId || '',
    });
  };

  const onLoadListOfTeammate = useCallback(async (pageNumber: number) => {
    const requestBody: UserGroupListBySpaceIdOptions = {
      projectId: projectId || '',
      pageSize: PageDefaultSize,
      pageNumber,
    };

    const result = await dispatch(getUserGroupListForProject(requestBody));

    if (getUserGroupListForProject.fulfilled.match(result)) {
      setListOfUserGroup(result.payload.details || []);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  }, []);

  const initialLoadListOfTeammate = useCallback(async () => {
    await onLoadListOfTeammate(0);
    setInitialListOfTeammateLoaded(true);
  }, []);

  useEffect(() => {
    !isTeammate && initialLoadListOfTeammate();
  }, [initialLoadListOfTeammate, isTeammate]);

  const getCustomAvatarsLayout = () => {
    const isDisplayTeammateAvatarList =
      isInitialListOfTeammateLoaded && !isNil(listOfUserGroup);

    return (
      isDisplayTeammateAvatarList && (
        <TeammateAvatarList
          listOfUserGroup={listOfUserGroup}
          projectId={projectId || ''}
        />
      )
    );
  };

  const renderActions = () => (
    <ActionsBox
      isProjectButtonVisible={false}
      handleDisplayProjectModal={handleDisplayProjectModal}
      itemRenderer={getCustomAvatarsLayout()}
      templateId={templateId}
    />
  );

  const renderSearchInput = () => (
    <SearchInput
      onChange={handleSearch}
      value={filters[ProjectTrackersFiltersTypes.TrackerName]}
      placeholder="Search for tracker"
    />
  );

  const renderFilters = () => (
    <>
      <div>
        <SortingFilter
          value={filters[ProjectTrackersFiltersTypes.Sorting]}
          onChange={handleSort}
          mode={SortingMode.Projects}
        />
      </div>
      <div>
        <CompletenessFilter
          value={filters[ProjectTrackersFiltersTypes.Completeness]}
          onChange={onChangeCompletenessFilter}
        />
      </div>
    </>
  );

  const renderData = (
    childrenProps: ChildrenProps<
      ProgressTrackerCardModel,
      ProgressTrackerCardResponseModel
    >,
  ) => {
    const {
      isInitialDataLoaded,
      isSearchMode,
      emptySearchResults,
      details,
      data,
    } = childrenProps;

    setProjectName(projectName);
    setTemplateId(data?.progressTrackerTemplateId);

    return (
      <>
        <div className={cx('h2', styles.skeletonTitle)}>
          <Skeleton
            active
            loading={!isInitialDataLoaded && isSearchMode}
            title={false}
            paragraph={{ rows: 1 }}
          >
            {!emptySearchResults && isSearchMode
              ? 'Trackers search results'
              : ''}
          </Skeleton>
        </div>
        <Skeleton
          key={0}
          active
          loading={!isInitialDataLoaded && isSearchMode}
          title={false}
          className={styles.skeletonWrap}
          paragraph={{ rows: 1 }}
        >
          <ProjectList
            progressTrackers={details}
            projectId={projectId || ''}
            handleDeleteClick={handleDeleteClick}
            handleArchiveClick={handleArchiveTracker}
            handleMove={initInfScroll}
          />
        </Skeleton>
      </>
    );
  };

  const resultFilters = useMemo(() => {
    const newFilters = {
      query: filters[ProjectTrackersFiltersTypes.TrackerName],
      ...filters,
    };
    unset(newFilters, [ProjectTrackersFiltersTypes.TrackerName]);
    return newFilters;
  }, [filters]);

  return (
    <>
      {projectInfo && (
        <ProjectModal
          mode={ProjectModalMode.EDIT}
          projectInfo={projectInfo}
          onUpdateProjectInfo={initInfScroll}
          onClose={() => setProjectInfo(undefined)}
        />
      )}

      <SearchLayout
        mode={SearchLayoutMode.Trackers}
        filters={resultFilters}
        isFilterUsed={isFilterUsed}
        restRequestOptions={{ projectId }}
        isInitInfScrollNeeded={isInitInfScrollNeeded}
        enableInitInfScroll={enableInitInfScroll}
        renderBreadcrumbsNode={renderBreadcrumb}
        renderActions={renderActions}
        searchNode={renderSearchInput()}
        filtersNode={renderFilters()}
        columns={1}
        noInitialDataNode={noInitialDataNode}
      >
        {renderData}
      </SearchLayout>
    </>
  );
}
