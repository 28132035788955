import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Spin, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import InfiniteScroll from 'react-infinite-scroller';
import cx from 'classnames';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

import {
  PaymentHistoryResponseModel,
  PaymentHistoryResponseModelStripePagedList,
} from 'api';
import AlertMessage from 'components/AlertMessage';
import SettingsDetailsSection from 'components/settings/SettingsDetailsSection';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { PaymentStatusEnum } from 'enums/PaymentStatusEnum';
import {
  exportInvoiceHistory,
  getPaymentHistory,
} from 'redux/reducers/accountSlice';
import { AppDispatch } from 'redux/store';
import {
  spaceNameSelector,
  userSelector,
} from 'redux/selectors/authenticationSelectors';
import { BILLING_DATE_FORMAT, getDateForUserTimezone } from 'utils/dateUtils';
import { DispatchedRequestType } from 'utils/searchUtils';
import {
  DEFAULT_DOWNLOAD_FILE_ERROR_MESSAGE,
  downloadFileByObjectURL,
} from 'utils/fileUtils';

import styles from './InvoiceHistoryTab.module.less';

export const renderPaymentStatus = (status: string) => {
  switch (status) {
    case PaymentStatusEnum.Failed:
      return <div className={cx(styles.label, styles.failed)}>Failed</div>;
    case PaymentStatusEnum.Succeeded:
      return <div className={cx(styles.label, styles.success)}>Successful</div>;
  }
};

export default function InvoiceHistoryTab() {
  const isLightTheme = useSelector(isLightThemeSelector);
  const [payments, setPayments] = useState<PaymentHistoryResponseModel[]>([]);
  const [nextPage, setNextPage] = useState<string>();
  const [hasMore, setHasMore] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const infiniteScrollParentRef = useRef(null);
  const latestRequestRef = useRef<DispatchedRequestType>();
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector(userSelector);
  const spaceName = useSelector(spaceNameSelector);

  useEffect(() => {
    initInfinityScroll();
  }, []);

  const onSetPayments = useCallback(
    (payload: PaymentHistoryResponseModelStripePagedList) => {
      const loadedPayments = payload.details || [];
      const newPayments = [...payments, ...loadedPayments];

      setHasMore(!!payload?.nextPage);
      setNextPage(payload?.nextPage);
      setPayments(newPayments);
    },
    [payments],
  );

  const onLoadMore = useCallback(async () => {
    latestRequestRef.current?.abort?.();

    const request = dispatch(
      getPaymentHistory({
        userId: user?.id || '',
        page: nextPage,
        desc: true,
      }),
    );

    latestRequestRef.current = request;
    const result = await request;

    if (!getPaymentHistory.fulfilled.match(result)) {
      if (result.meta.aborted) return;

      setHasMore(false);
      AlertMessage.error(result.error.message || ApiErrorMessage);
      return;
    }

    onSetPayments(result.payload);
  }, [dispatch, onSetPayments]);

  const initInfinityScroll = () => {
    setIsVisible(false);
    setPayments([]);
    setHasMore(true);
    setNextPage(undefined);

    const timer = setTimeout(() => {
      // to destroy SearchLayout
      setIsVisible(true);
    }, 0);

    return () => clearTimeout(timer);
  };

  const getTotalAmount = (totalAmount?: number) =>
    `$${(totalAmount || 0) / 100}`;

  const renderPaymentStatus = (status: string) => {
    switch (status) {
      case PaymentStatusEnum.Failed:
        return <div className={cx(styles.label, styles.failed)}>Failed</div>;
      case PaymentStatusEnum.Succeeded:
        return (
          <div className={cx(styles.label, styles.success)}>Successful</div>
        );
    }
  };

  const downloadHistory = async () => {
    const result = await dispatch(exportInvoiceHistory(user?.id || ''));

    if (exportInvoiceHistory.fulfilled.match(result)) {
      try {
        const { data, fileName } = result.payload;
        const url = URL.createObjectURL(data);

        await downloadFileByObjectURL(
          url,
          fileName || `Apdated Payment History - ${spaceName}.xlsx`,
        );
      } catch {
        AlertMessage.error(DEFAULT_DOWNLOAD_FILE_ERROR_MESSAGE);
      }
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  return (
    <>
      <SettingsDetailsSection
        title="Payment history"
        description="Track all payments made on your Apdated account."
      />

      <div className={styles.invoice}>
        <div className={styles.box}>
          <div>Orders</div>
          <div>
            <Tooltip title="Export to Excel" placement="top" trigger="hover">
              <Button onClick={downloadHistory} type="text">
                <i className="icon icon-load" />
              </Button>
            </Tooltip>
          </div>
        </div>

        <div className={styles.tableBox} ref={infiniteScrollParentRef}>
          <table
            className={cx(styles.table, { [styles.tableDark]: !isLightTheme })}
          >
            <thead>
              <tr>
                <th className={cx(styles.tableHead, styles.tableLeftAligned)}>
                  Invoice ID
                </th>
                <th className={cx(styles.tableHead, styles.tableLeftAligned)}>
                  Billing date
                </th>
                <th className={styles.tableHead}>Total amount</th>
                <th className={styles.tableHead}>Status</th>
              </tr>
            </thead>
            {isVisible && (
              <InfiniteScroll
                loadMore={onLoadMore}
                hasMore={hasMore}
                loader={<Spin key={0} />}
                useWindow={false}
                getScrollParent={() => infiniteScrollParentRef.current}
                threshold={200}
                element="tbody"
              >
                {payments.map((payment) => (
                  <tr key={nanoid()} className={styles.tableRow}>
                    <td
                      className={cx(styles.tableCell, styles.tableLeftAligned)}
                    >
                      <a href={payment.invoiceUrl} target="_blank">
                        {payment.invoiceNumber}
                      </a>
                    </td>
                    <td
                      className={cx(styles.tableCell, styles.tableLeftAligned)}
                    >
                      {getDateForUserTimezone(
                        payment.billingDate,
                        BILLING_DATE_FORMAT,
                      )}
                    </td>
                    <td className={styles.tableCell}>
                      {getTotalAmount(payment.totalAmount)}
                    </td>
                    <td className={styles.tableCell}>
                      {renderPaymentStatus(payment?.status || '')}
                    </td>
                  </tr>
                ))}
              </InfiniteScroll>
            )}
          </table>
        </div>
      </div>
    </>
  );
}
