import React from 'react';
import { Avatar, Button } from 'antd';
import { Link } from 'react-router-dom';

import { UserGroupListResponseModel } from 'api';

import styles from './TeammateAvatarList.module.less';

type Props = {
  listOfUserGroup: UserGroupListResponseModel[];
  projectId: string;
};

export default function TeammateAvatarList(props: Props) {
  const { projectId, listOfUserGroup = [] } = props;

  const listOfUserGroupLength = listOfUserGroup?.length;

  if (!listOfUserGroupLength) return null;

  const firstUserGroups = listOfUserGroup.slice(0, 4);

  const renderTotalCount = () =>
    listOfUserGroupLength > 4
      ? '+ ' + listOfUserGroupLength
      : listOfUserGroupLength;

  return (
    <div className={styles.avatar}>
      <Link to={`/team-management/${projectId}`}>
        <Button>
          <Avatar.Group>
            {firstUserGroups.map((group) => (
              <Avatar key={group.id} src={group.imageGetUrl}>
                {group.name?.charAt(0)}
              </Avatar>
            ))}
          </Avatar.Group>
          <div className={styles.avatarCount}>{renderTotalCount()}</div>
        </Button>
      </Link>
    </div>
  );
}
