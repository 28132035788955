import React from 'react';

import styles from './OnboardingMask.module.less';

function getWindow() {
  const width = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0,
  );
  const height = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0,
  );
  return { width, height };
}

export default function OnboardingMask() {
  const { width, height } = getWindow();

  return (
    <div className={styles.tourMask}>
      <svg width={width} height={height}>
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          pointerEvents="auto"
          fill="transparent"
        />
      </svg>
    </div>
  );
}
