import { useCallback } from 'react';
import { Collapse, Progress } from 'antd';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';

import { ProgressTrackerResponseModel } from 'api';
import { getPTDueDate, getPTPercentage, getStagesToDo } from './utils';
import { copyToClipboard } from 'utils/textUtils';
import { ProgressTrackerModeEnum } from 'enums/ProgressTrackerMode.enum';
import { useQueryMatch } from 'hooks/useQueryMatch';
import { TabletOrMobileScreenQuery } from 'constants/ScreenQuery';
import AlertMessage from 'components/AlertMessage';
import DetailsPanelHeader from './DetailsPanelHeader';
import DetailsPanelBody from './DetailsPanelBody';
import { getDateForUserTimezone } from 'utils/dateUtils';

const { Panel } = Collapse;

type Props = {
  details: ProgressTrackerResponseModel;
  mode?: ProgressTrackerModeEnum;
  isDetailsVisible: boolean;
};

const getScriptText = (id: string) => `
(function () {
  var w = window;
  var d = document;

  var i = function () {
    i.c(arguments);
  };
  i.q = [];
  i.c = function (args) {
    i.q.push(args);
  };
  w.ProgressTracker = i;

  var l = function () {
    var s = d.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = '${process.env.REACT_APP_PUBLIC_URL}/static/pt-embedded.js';
    var x = d.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
  };
  if (document.readyState === 'complete') {
    l();
  } else if (w.attachEvent) {
    w.attachEvent('onload', l);
  } else {
    w.addEventListener('load', l, false);
  }
})();

window.ProgressTracker('init', { id: '${id}' });
`;

export default function DetailsSectionOld(props: Props) {
  const {
    details,
    mode = ProgressTrackerModeEnum.Private,
    isDetailsVisible,
  } = props;
  const { id, name, createdDate } = details;
  const isTabletOrMobile = useQueryMatch(TabletOrMobileScreenQuery);
  const sharedUrl = `${process.env.REACT_APP_PUBLIC_URL}/ptdetails/${details.id}`;
  const { pathname } = useLocation();
  const isSearchPage = pathname === '/search';
  const dueDate = getPTDueDate(details);

  const handleScriptCopy = useCallback(() => {
    const text = getScriptText(id as string);

    onCopy(text);
  }, [id]);

  const onCopy = (data: string) => {
    const isSuccess = copyToClipboard(data);

    if (isSuccess) {
      AlertMessage.success('Copied to clipboard.');
      return;
    }

    AlertMessage.error('Failed to copy.');
  };

  return (
    <div
      className={cx('details-wrap', {
        'search-page': isSearchPage,
        'details-hidden': !isDetailsVisible,
      })}
    >
      {!isTabletOrMobile && <div className="details-name">{name}</div>}
      <div className="details-card">
        <div className="details-card__head">
          <div className="details-card__progress">
            <Progress
              type="circle"
              percent={getPTPercentage(details)}
              strokeColor={'#F5DF4D'}
              strokeWidth={14}
              size={114}
              format={(percent) => `${percent}%`}
            />
          </div>
          <div className="details-card__info">
            <h3 className="details-card__caption">Details</h3>
            <ul>
              <li>
                <span>
                  Created:
                  {getDateForUserTimezone(createdDate)}
                </span>
              </li>
              {dueDate && (
                <li>
                  <span>Due date: {dueDate}</span>
                </li>
              )}
              <li>
                <span>Stages remaining: {getStagesToDo(details)}</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="details-card__body">
          <div className="details-card__collapse">
            <Collapse
              expandIconPosition="right"
              accordion
              expandIcon={({ isActive }) => (
                <div>
                  {isActive ? (
                    <span className="icon icon-angle-top" />
                  ) : (
                    <span className="icon icon-angle-down" />
                  )}
                </div>
              )}
            >
              {mode === ProgressTrackerModeEnum.Private && (
                <>
                  <Panel
                    key="details-url"
                    header={
                      <DetailsPanelHeader
                        icon="icon-url"
                        title="URL"
                        onClick={() => onCopy(sharedUrl)}
                      />
                    }
                  >
                    <DetailsPanelBody>{sharedUrl}</DetailsPanelBody>
                  </Panel>
                  <Panel
                    key="details-embedded-script"
                    header={
                      <DetailsPanelHeader
                        icon="icon-programming"
                        title="Embedded script"
                        onClick={handleScriptCopy}
                      />
                    }
                  >
                    <DetailsPanelBody>
                      {getScriptText(id as string)}
                    </DetailsPanelBody>
                  </Panel>
                </>
              )}
              <Panel
                key="details-id"
                header={
                  <DetailsPanelHeader
                    icon="icon-url"
                    title="ID"
                    onClick={() => onCopy(details.id as string)}
                  />
                }
              >
                <DetailsPanelBody>{details.id}</DetailsPanelBody>
              </Panel>
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );
}
