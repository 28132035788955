export enum SettingsTabEnum {
  Pricing = 'pricing',
  UserManagement = 'user-management',
  Security = 'security',
  CreditCard = 'credit-card',
  Payments = 'payments',
  Profile = 'profile',
  Notifications = 'notifications',
}

export const trackerManagerMessage =
  'You do not have access to view this page. Please check with your account admin.';
