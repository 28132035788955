import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import CardListSection from 'features/auth/BillingSettingsPage/CreditCardsTab/CardListSection';
import { PaymentMethodResponseModel } from 'api';
import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { deleteCreditCard, getCardList } from 'redux/reducers/accountSlice';
import { userSelector } from 'redux/selectors/authenticationSelectors';
import { AppDispatch } from 'redux/store';
import { AddNewCardSection } from 'components/payments';
import StripeContainer from 'components/stripe/StripeContainer';
import { getUserInfo } from 'redux/reducers/authenticationSlice';

export default function CreditCardsTab() {
  const [isLoading, setLoading] = useState(false);
  const [isCardListError, setIsCardListError] = useState(false);
  const [cards, setCards] = useState<PaymentMethodResponseModel[]>([]);
  const [isAddingNewCard, setIsAddingNewCard] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector(userSelector);

  useEffect(() => {
    getCards();
  }, []);

  const getCards = async () => {
    setLoading(true);
    const result = await dispatch(getCardList(user?.id || ''));

    if (getCardList.fulfilled.match(result)) {
      setIsCardListError(false);
      setCards(result.payload);
    } else {
      setIsCardListError(true);
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }

    setLoading(false);
  };

  const handleAddNewCardClick = () => {
    setIsAddingNewCard(true);
  };

  const handleCancelAddingCard = async (isReloadNeeded: boolean = false) => {
    setIsAddingNewCard(false);

    isReloadNeeded && (await getCards());
  };

  const handleDeleteCreditCard = async (id: string) => {
    const result = await dispatch(deleteCreditCard(id));

    if (deleteCreditCard.fulfilled.match(result)) {
      AlertMessage.success('The card was successfully deleted.');
      await dispatch(getUserInfo());
      await getCards();
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  if (isLoading) return <Spin />;

  return (
    <>
      {!isAddingNewCard && (
        <CardListSection
          cards={cards}
          handleAddNewCard={handleAddNewCardClick}
          handleDeleteCreditCard={handleDeleteCreditCard}
          isError={isCardListError}
        />
      )}

      {isAddingNewCard && (
        <StripeContainer>
          <AddNewCardSection onCancelAdding={handleCancelAddingCard} />
        </StripeContainer>
      )}
    </>
  );
}
