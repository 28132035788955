import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { Form, Input, Spin } from 'antd';
import { uniqueId } from 'lodash';
import { useForm } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import cx from 'classnames';

import {
  UserCommentResponseModel,
  UserCommentResponseModelPagedResponseModel,
} from 'api';
import AlertMessage from 'components/AlertMessage';
import Controller from 'components/form/Controller';
import NoInitialData from 'components/NoInitialData';
import SubmitButton from 'components/SubmitButton';
import { PageDefaultSize } from 'constants/ApiConstant';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { requiredMessage } from 'constants/ValidationConstants';
import {
  addCommentToUser,
  AddCommentToUserRequest,
  getUserComments,
  GetUserCommentsRequest,
} from 'redux/reducers/userSlice';
import { AppDispatch } from 'redux/store';
import { COMMENT_DATE_FORMAT, getDateForUserTimezone } from 'utils/dateUtils';
import { DispatchedRequestType } from 'utils/searchUtils';

import styles from './CommentSection.module.less';

const validationSchema = Yup.object().shape({
  comment: Yup.string()
    .required(requiredMessage)
    .max(200, 'Comment should be 200 characters maximum')
    .trim(),
});

type CommentForm = {
  comment: string;
};

type Params = {
  id: string;
};

type Props = {
  scrollRef: RefObject<HTMLDivElement>;
};

export default function CommentsSection(props: Props) {
  const { scrollRef } = props;
  const [comments, setComments] = useState<UserCommentResponseModel[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
  const { id } = useParams<Params>();
  const latestRequestRef = useRef<DispatchedRequestType>();
  const dispatch: AppDispatch = useDispatch();

  const { formState, control, handleSubmit, reset } = useForm<CommentForm>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: { comment: '' },
  });
  const { errors } = formState;

  useEffect(() => {
    initInfinityScroll();
  }, []);

  const initInfinityScroll = () => {
    setIsVisible(false);
    setComments([]);
    setIsInitialDataLoaded(false);
    setHasMore(true);
    setTotalCount(0);

    const timer = setTimeout(() => {
      // to destroy infinite scroll
      setIsVisible(true);
    }, 0);

    return () => clearTimeout(timer);
  };

  const onSetData = useCallback(
    (payload: UserCommentResponseModelPagedResponseModel) => {
      const loadedData = payload.details || [];
      const totalCount = payload.totalCount || 0;
      const newData = [...comments, ...loadedData];
      const hasMoreRows =
        !!loadedData.length &&
        !!newData.length &&
        newData.length < totalCount &&
        loadedData.length > 0;

      setHasMore(hasMoreRows);
      setComments(newData);
      setTotalCount(totalCount);
    },
    [comments],
  );

  const onLoadMore = useCallback(
    async (pageNumber: number) => {
      latestRequestRef.current?.abort?.();

      const requestBody: GetUserCommentsRequest = {
        pageSize: PageDefaultSize,
        userId: id || '',
        pageNumber,
      };

      const request = dispatch(getUserComments(requestBody));

      latestRequestRef.current = request;
      const result = await request;
      if (getUserComments.fulfilled.match(result)) {
        onSetData(result.payload);
      } else {
        if (result.meta.aborted) return;

        setHasMore(false);
        AlertMessage.error(result.error.message || ApiErrorMessage);
      }

      setIsInitialDataLoaded(true);
    },
    [dispatch, getUserComments, onSetData, latestRequestRef, id],
  );

  const onSubmit = async (values: CommentForm) => {
    const requestBody: AddCommentToUserRequest = {
      userId: id || '',
      body: {
        comment: values.comment,
      },
    };

    const result = await dispatch(addCommentToUser(requestBody));

    if (addCommentToUser.fulfilled.match(result)) {
      initInfinityScroll();
      reset();
      AlertMessage.success('The comment successfully added.');
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  return (
    <div className={styles.comments}>
      <div className={styles.title}>Comments</div>

      <Form onFinish={handleSubmit(onSubmit)}>
        <Controller
          name="comment"
          control={control}
          error={errors.comment}
          showValidateError={errors.comment?.type !== 'required'}
          containerClassName={cx(styles.input, 'dark-input-group')}
          render={(field) => (
            <Input
              {...field}
              maxLength={200}
              placeholder="Add a comment"
              suffix={<SubmitButton formState={formState}>Add</SubmitButton>}
            />
          )}
        />
      </Form>

      {isInitialDataLoaded && !comments.length && (
        <NoInitialData text={'There are no comments for this user.'} />
      )}

      {isVisible && (
        <InfiniteScroll
          initialLoad={!isInitialDataLoaded}
          loadMore={onLoadMore}
          maxLength={totalCount}
          pageStart={-1}
          hasMore={hasMore}
          loader={<Spin key={uniqueId()} />}
          useWindow={false}
          getScrollParent={() => scrollRef.current}
          threshold={-100}
          className={cx({
            [styles.wrapper]: isInitialDataLoaded && comments.length,
          })}
        >
          {comments?.map((comment) => (
            <div key={comment.id} className={styles.item}>
              <strong>
                {comment.createdUserFirstName} {comment.createdUserLastName}
              </strong>
              <p>
                <span>Date:</span>
                {getDateForUserTimezone(
                  comment.createdDate,
                  COMMENT_DATE_FORMAT,
                )}
              </p>
              <p>{comment.comment}</p>
            </div>
          ))}
        </InfiniteScroll>
      )}
    </div>
  );
}
