import  './NoInitialData.less';

type Props = {
  text: string;
};

export default function NoInitialData(props: Props) {
  const { text } = props;

  return (
    <div className="emptyBox">
      <div className="emptyText">{text}</div>
    </div>
  );
}
