import { AliasToken, OverrideToken } from 'antd/es/theme/interface';
import { merge } from 'lodash';

const lightModeToken: Partial<AliasToken> = {
  colorTextBase: '#242424',
  colorBgBase: '#ffffff',
  colorBgLayout: '#ffffff',
};

const darkModeToken: Partial<AliasToken> = {
  colorTextBase: '#ffffff',
  colorBgBase: '#191C21',
  colorBgLayout: '#191C21',
};

export const commonToken: Partial<AliasToken> = {
  borderRadius: 4,
  colorError: '#b91600',
  colorPrimary: '#f5df4d',
  fontFamily: '"Seaford", sans-serif',
};

export const mergedDarkToken = merge(darkModeToken, commonToken);
export const mergedLightToken = merge(lightModeToken, commonToken);

export const lightThemeComponents: OverrideToken = {};

export const darkThemeComponents: OverrideToken = {};
