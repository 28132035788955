import React, { useCallback, useEffect, useState } from 'react';
import { App, Avatar, Button, Collapse, Pagination, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import cx from 'classnames';
import { useDidUpdate } from 'rooks';
import { isNil } from 'lodash';
import { nanoid } from '@reduxjs/toolkit';

import NoInitialData from 'components/NoInitialData';
import { UserGroupListResponseModel, UserGroupUserResponseModel } from 'api';
import {
  allowUserGroupAccessToProject,
  getUserGroupListForProject,
  getUserListFromUserGroupByGroupId,
  removeUserGroupAccessToProject,
  UserGroupListBySpaceIdOptions,
  UserListFromUserGroupByGroupIdOptions,
} from 'redux/reducers/projectSlice';
import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { AppDispatch } from 'redux/store';
import { useQueryMatch } from 'hooks/useQueryMatch';
import { PageDefaultSize } from 'constants/ApiConstant';
import Breadcrumb from 'components/Breadcrumb';
import { DesktopScreenQuery } from 'constants/ScreenQuery';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import UserGroupDetails from './UserGroupDetails';

import styles from './TeamManagementPage.module.less';
import classes from '../ProjectPage/TeammateAvatarList/TeammateAvatarList.module.less';

type ParamsProps = {
  projectId: string;
};

export default function TeamManagementPage() {
  const { projectId } = useParams<ParamsProps>();
  const [idUserGroupOpen, setIdUserGroupOpen] = useState<string>();
  const [pageNumber, setPageNumber] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isListOfTeammateLoading, setIsListOfTeammateLoading] = useState(false);
  const [listOfTeammate, setUserGroupDetails] = useState<
    UserGroupUserResponseModel[]
  >([]);
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
  const [groupOfTeammatesList, setGroupOfTeammatesList] = useState<
    UserGroupListResponseModel[]
  >([]);
  const dispatch: AppDispatch = useDispatch();
  const { modal } = App.useApp();
  const isLightTheme = useSelector(isLightThemeSelector);
  const isMobile = useQueryMatch(DesktopScreenQuery);

  const onLoadListOfTeammate = useCallback(
    async (userGroupId: string) => {
      setIsListOfTeammateLoading(true);
      const requestBody: UserListFromUserGroupByGroupIdOptions = {
        projectId: projectId || '',
        userGroupId: userGroupId || '',
      };

      const result = await dispatch(
        getUserListFromUserGroupByGroupId(requestBody),
      );

      if (getUserListFromUserGroupByGroupId.fulfilled.match(result)) {
        setUserGroupDetails(result.payload || []);
      } else {
        AlertMessage.error(result.error.message || ApiErrorMessage);
      }
      setIsListOfTeammateLoading(false);
    },
    [projectId, PageDefaultSize, dispatch],
  );

  useDidUpdate(() => {
    !isNil(idUserGroupOpen) && onLoadListOfTeammate(idUserGroupOpen);
  }, [idUserGroupOpen]);

  const onLoadUserGroups = useCallback(
    async (pageNumber: number) => {
      const requestBody: UserGroupListBySpaceIdOptions = {
        projectId: projectId || '',
        pageSize: PageDefaultSize,
        pageNumber: pageNumber - 1,
      };

      const result = await dispatch(getUserGroupListForProject(requestBody));

      if (getUserGroupListForProject.fulfilled.match(result)) {
        setGroupOfTeammatesList(result.payload.details || []);
        setTotalCount(result.payload.totalCount || 0);
        setPageNumber(pageNumber);
      } else {
        AlertMessage.error(result.error.message || ApiErrorMessage);
      }
    },
    [projectId, PageDefaultSize, dispatch],
  );

  const initialLoadUserGroups = useCallback(async () => {
    await onLoadUserGroups(1);
    setIsInitialDataLoaded(true);
  }, []);

  useEffect(() => {
    initialLoadUserGroups();
  }, []);

  const notFoundData = isInitialDataLoaded && !totalCount;

  const handleChange = (key: string | string[]) => {
    setIdUserGroupOpen(key[0]);
  };

  const onAllowAccess = useCallback(
    async (userGroupId: string) => {
      const result = await dispatch(
        allowUserGroupAccessToProject({
          projectId: projectId || '',
          userGroupId,
        }),
      );

      if (allowUserGroupAccessToProject.fulfilled.match(result)) {
        await initialLoadUserGroups();
        setIdUserGroupOpen(undefined);

        AlertMessage.success(
          'Access to the project has been granted to the user group.',
        );
      } else {
        AlertMessage.error(result.error.message || ApiErrorMessage);
      }
    },
    [projectId, dispatch],
  );

  const onRemoveAccess = useCallback(
    async (userGroupId: string) => {
      const result = await dispatch(
        removeUserGroupAccessToProject({
          projectId: projectId || '',
          userGroupId,
        }),
      );

      if (removeUserGroupAccessToProject.fulfilled.match(result)) {
        setIdUserGroupOpen(undefined);
        await initialLoadUserGroups();
        AlertMessage.success(
          'Access to the project has been revoked from the user group.',
        );
      } else {
        AlertMessage.error(result.error.message || ApiErrorMessage);
      }
    },
    [projectId, dispatch],
  );

  const onConfirmAllowAccess = useCallback((groupId: string) => {
    modal.confirm({
      content: (
        <div>
          This user group has been granted access to the project and can now
          collaborate with other team members.
        </div>
      ),
      icon: <span className="icon icon-info-circle confirm-icon" />,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      width: '496px',
      cancelText: 'Cancel',
      okText: 'Ok',
      onOk: async () => await onAllowAccess(groupId),
    });
  }, []);

  const onConfirmRemoveAccess = useCallback((groupId: string) => {
    modal.confirm({
      content: (
        <div>
          This user group can no longer gain access to this project. Their
          previous history logs will be retained within the system.
        </div>
      ),
      icon: <span className="icon icon-info-circle confirm-icon" />,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      width: '496px',
      cancelText: 'Cancel',
      okText: 'Ok',
      onOk: () => onRemoveAccess(groupId),
    });
  }, []);

  const renderItems = () => {
    return groupOfTeammatesList.map((group) => {
      const hasAvatars = group.imageGetUrl?.filter(Boolean);

      return {
        key: group.id,
        label: (
          <div className={styles.header}>
            <div className={styles.headerTitle}>{group.name}</div>
            <div className={cx(classes.avatar, styles.headerAvatar)}>
              {hasAvatars ? (
                <Avatar.Group>
                  {(group.imageGetUrl || []).map((imageGetUrl) => (
                    <Avatar key={nanoid()} src={imageGetUrl} />
                  ))}
                </Avatar.Group>
              ) : (
                <span>{group.name?.charAt(0)}</span>
              )}
              <div className={classes.avatarCount}>
                + {group.imageGetUrl?.length}
              </div>
            </div>

            {!group.forUsersWithoutGroups && (
              <div className={styles.headerBox}>
                {group.isVisible ? (
                  <div className={styles.btn}>
                    <Button
                      type="ghost"
                      onClick={(event) => {
                        event.stopPropagation();
                        onConfirmRemoveAccess(group.id || '');
                      }}
                    >
                      <i className="icon icon-eye" />
                    </Button>
                  </div>
                ) : (
                  <div className={styles.btn}>
                    <Button
                      type="ghost"
                      onClick={(event) => {
                        event.stopPropagation();
                        onConfirmAllowAccess(group.id || '');
                      }}
                    >
                      <i className="icon icon-not-available-eye" />
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        ),
        children: isListOfTeammateLoading ? (
          <Spin />
        ) : (
          <UserGroupDetails
            listOfTeammate={listOfTeammate}
            onSuccess={onLoadListOfTeammate}
            projectId={projectId || ''}
          />
        ),
      };
    });
  };

  return (
    <div className="page-wrapper scroll-box ">
      <div className="page-box page-holder">
        <Breadcrumb
          routes={[
            {
              title: <i className="icon icon-arrow-left" />,
              link: `/project/${projectId}`,
            },
          ]}
        />

        <div className="banner">
          <div className="banner__left">
            <div className="banner__count">{totalCount}</div>
            <div className="banner__name">Groups</div>
          </div>
        </div>

        <div>
          {notFoundData && (
            <NoInitialData text={`You currently do not have any teammates.`} />
          )}
        </div>
        {!isInitialDataLoaded && <Spin />}

        {isInitialDataLoaded && (
          <div
            className={
              isMobile
                ? cx('modal-scroll-wrapper', styles.tab)
                : cx(styles.tab, styles.directionWrap)
            }
          >
            <div className={styles.directionBox}>
              <div className={styles.tableHead}>
                <div className={cx(styles.tableRow, styles.xsNone)}>
                  <div className={styles.tableCol}>Group name</div>
                  <div className={styles.tableCol}>Users</div>
                  <div className={cx(styles.tableCol, styles.actions)}>
                    Actions
                  </div>
                </div>
                <div className={cx(styles.tableRow, styles.lgNone)}>
                  <div className={styles.tableCol}>Users</div>
                </div>
              </div>

              <div className={styles.collapse}>
                <Collapse
                  items={renderItems()}
                  onChange={handleChange}
                  activeKey={idUserGroupOpen}
                  accordion={true}
                />
              </div>

              <div className={styles.pagination}>
                <Pagination
                  current={pageNumber}
                  pageSize={PageDefaultSize}
                  total={totalCount}
                  onChange={onLoadUserGroups}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
