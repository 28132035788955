import dayjs from 'dayjs';
import { sum } from 'lodash';

import {
  ProgressTrackerResponseModel,
  PTStageResponseModel,
  StageStatusEnum,
} from 'api';
import { dateFormat, formatDateForUserTimezone } from 'utils/dateUtils';

export const getPTDueDate = (pt: ProgressTrackerResponseModel): string => {
  const { stages } = pt;
  const sortedByDateStages = [...(stages as PTStageResponseModel[])]
    .filter((stage) => !!stage.dueDate)
    .sort((a: any, b: any) =>
      dayjs(a.dueDate).isBefore(dayjs(b.dueDate)) ? 1 : -1,
    );
  const lastStage = sortedByDateStages[0];

  if (!lastStage) return '';

  const formattedDate = formatDateForUserTimezone(lastStage.dueDate);

  return dayjs(formattedDate).format(dateFormat);
};

export const getPTPercentage = (pt: ProgressTrackerResponseModel): number => {
  const stages = pt.stages || [];
  const stagesCompleteness = stages.map(getStagePercentage);
  const totalCompleteness = sum(stagesCompleteness);

  return Math.round(totalCompleteness / stages.length);
};

export const getStagePercentage = (stage: PTStageResponseModel): number => {
  return stage.status === StageStatusEnum.Done ? 100 : 0;
};

export const getStagesToDo = (pt: ProgressTrackerResponseModel): number => {
  const stages = pt.stages as PTStageResponseModel[];
  const countOfTodoStages = stages.filter(
    (s) => s.status !== StageStatusEnum.Done,
  );

  return countOfTodoStages.length;
};
