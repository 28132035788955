import React from 'react';
import cx from 'classnames';
import commonStyles from './Pagination.module.less';

type Props = {
  activeSection: LandingSectionsEnum;
  className?: string;
};

export enum LandingSectionsEnum {
  KeepClientsInformed = 'keepClientsInformed',
  Customize = 'customize',
  BusinessGrowth = 'businessGrowth',
  EmbedIt = 'embedIt',
  ScheduleUpdates = 'scheduleUpdates',
  InformYourClient = 'informYourClient',
  StartTracker = 'startTracker',
}

export default function Pagination(props: Props) {
  const { activeSection, className = '' } = props;

  return (
    <ul
      className={cx(commonStyles.pagination, className, {
        [commonStyles.paginationDark]:
          // activeSection === LandingSectionsEnum.ScheduleUpdates ||
          // activeSection === LandingSectionsEnum.StartTracker ||
          activeSection === LandingSectionsEnum.BusinessGrowth,
        [commonStyles.paginationLeft]:
          activeSection === LandingSectionsEnum.InformYourClient,
      })}
    >
      <li
        className={cx({
          [commonStyles.active]:
            activeSection === LandingSectionsEnum.KeepClientsInformed,
        })}
      >
        <a
          href={`#${LandingSectionsEnum.KeepClientsInformed}`}
          className={cx({
            [commonStyles.active]:
              activeSection === LandingSectionsEnum.KeepClientsInformed,
          })}
        >
          <span>Start</span>
        </a>
      </li>
      <li
        className={cx({
          [commonStyles.active]:
            activeSection === LandingSectionsEnum.Customize,
        })}
      >
        <a
          href={`#${LandingSectionsEnum.Customize}`}
          className={cx({
            [commonStyles.active]:
              activeSection === LandingSectionsEnum.Customize,
          })}
        >
          <span>Customize</span>
        </a>
      </li>
      <li
        className={cx({
          [commonStyles.active]:
            activeSection === LandingSectionsEnum.BusinessGrowth,
        })}
      >
        <a
          href={`#${LandingSectionsEnum.BusinessGrowth}`}
          className={cx({
            [commonStyles.active]:
              activeSection === LandingSectionsEnum.BusinessGrowth,
          })}
        >
          <span>Grow your business</span>
        </a>
      </li>
      {/* <li>
        <a
          href={`#${LandingSectionsEnum.EmbedIt}`}
          className={cx({
            [commonStyles.active]:
              activeSection === LandingSectionsEnum.EmbedIt,
          })}
        >
          <span>Embed it</span>
        </a>
      </li> */}
      <li
        className={cx({
          [commonStyles.active]:
            activeSection === LandingSectionsEnum.ScheduleUpdates,
        })}
      >
        <a
          href={`#${LandingSectionsEnum.ScheduleUpdates}`}
          className={cx({
            [commonStyles.active]:
              activeSection === LandingSectionsEnum.ScheduleUpdates,
          })}
        >
          <span>Schedule updates</span>
        </a>
      </li>
      <li
        className={cx({
          [commonStyles.active]:
            activeSection === LandingSectionsEnum.InformYourClient,
        })}
      >
        <a
          href={`#${LandingSectionsEnum.InformYourClient}`}
          className={cx({
            [commonStyles.active]:
              activeSection === LandingSectionsEnum.InformYourClient,
          })}
        >
          <span>Inform your client</span>
        </a>
      </li>
      <li
        className={cx({
          [commonStyles.active]:
            activeSection === LandingSectionsEnum.StartTracker,
        })}
      >
        <a
          href={`#${LandingSectionsEnum.StartTracker}`}
          className={cx({
            [commonStyles.active]:
              activeSection === LandingSectionsEnum.StartTracker,
          })}
        >
          <span>Request a demo</span>
        </a>
      </li>
    </ul>
  );
}
