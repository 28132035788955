import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch } from 'redux/store';
import queryString from 'query-string';
import { Spin } from 'antd';

import { confirmAccount } from 'redux/reducers/authenticationSlice';
import AlertMessage from 'components/AlertMessage';

export default function ConfirmationPage() {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { uuid, code } = queryString.parse(location.search);
  const [isLoading, setIsLoading] = useState(false);

  const confirmSignUp = async () => {
    setIsLoading(true);

    const result = await dispatch(
      confirmAccount({
        userId: uuid as string,
        activationCode: code as string,
      }),
    );

    setIsLoading(false);
    navigate('/login', { replace: true });

    if (confirmAccount.fulfilled.match(result)) {
      AlertMessage.success('Thank you for confirming. Please log in.');
    }
  };

  useEffect(() => {
    confirmSignUp();
  }, []);

  return <div>{isLoading && <Spin size="large" />}</div>;
}
