import store from 'redux/store';
import { checkTrackersRestriction } from 'redux/reducers/progressTrackerSlice';
import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { PTStageResponseModel, StageStatusEnum } from 'api';

export const getCorrectStrokeColor = (
  status: number,
  isAnyStageEnded?: boolean,
) => {
  if (!isAnyStageEnded && status === 100) return '#00AA63';

  if (isAnyStageEnded && status === 100) return '#656565';

  return status === 0 ? '#ccc' : '#FEC021FF';
};

export const getUsedTimes = (usedTimes: number) => {
  if (usedTimes >= 10 && usedTimes < 100) {
    return `${usedTimes - (usedTimes % 10)}+`;
  }
  if (usedTimes < 1000 && usedTimes >= 100) {
    return `${usedTimes - (usedTimes % 100)}+`;
  }
  if (usedTimes >= 1000) {
    return `${Math.floor(usedTimes / 1000)}K`;
  }

  return usedTimes;
};

export const onCheckTrackerRestrictions = async () => {
  const result = await store.dispatch(checkTrackersRestriction());

  if (
    !checkTrackersRestriction.fulfilled.match(result) &&
    !result.meta.condition
  ) {
    AlertMessage.error(result.error.message || ApiErrorMessage);
  }
};

export const getEndedStage = (stages: PTStageResponseModel[] = []) =>
  stages.find(
    (stage: PTStageResponseModel) => stage.status === StageStatusEnum.End,
  );
