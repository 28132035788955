import { ReactNode } from 'react';
import { Button, ButtonProps } from 'antd';
import { FormState } from 'react-hook-form/dist/types/form';

type Props<T> = {
  formState: FormState<T>;
  children: ReactNode | string;
} & ButtonProps;

export default function SubmitButton<T>(props: Props<T>) {
  const { children, formState, loading: isManualLoading, ...restProps } = props;
  const { isSubmitting, isValid, isDirty } = formState;
  const isDisabled = !isDirty || isSubmitting || !isValid;

  return (
    <Button
      loading={isManualLoading || isSubmitting}
      htmlType="submit"
      disabled={isDisabled}
      {...restProps}
    >
      {children}
    </Button>
  );
}
