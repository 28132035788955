import React from 'react';
import FocusLock from 'react-focus-lock';

import OnboardingPortal from 'features/onboarding/OnboardingPortal';
import OnboardingMask from 'features/onboarding/OnboardingMask';

type Props = {
  id: string;
  children: React.ReactNode;
};

export default function BaseOnboarding(props: Props) {
  const { id, children } = props;

  return (
    <OnboardingPortal id={id}>
      <OnboardingMask />

      <FocusLock>{children}</FocusLock>
    </OnboardingPortal>
  );
}
