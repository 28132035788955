import React, { ReactNode, useEffect, useState } from 'react';
import { Spin, Switch } from 'antd';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

import EnterpriseUpgradeModal from './EnterpriseUpgradeModal';
import PricingDowngradeModal from './PricingDowngradeModal';
import PricingTableDesktop from './PricingTableDesktop';
import PricingTableMobile from './PricingTableMobile';
import PricingUpgradeSection from './PricingUpgradeSection';
import { SubscriptionResponseModel, SubscriptionTypeEnum } from 'api';
import { DesktopScreenQuery } from 'constants/ScreenQuery';
import { PriceTypesEnum } from 'enums/PriceTypesEnum';
import { useQueryMatch } from 'hooks/useQueryMatch';
import { getUserInfo } from 'redux/reducers/authenticationSlice';
import { AppDispatch } from 'redux/store';
import { userSelector } from 'redux/selectors/authenticationSelectors';
import { closeRestrictionModal } from 'redux/reducers/accountSlice';
import {
  isSubscriptionsLoadingSelector,
  subscriptionsSelector,
} from 'redux/selectors/aссountSelectors';
import { onLoadSubscriptions } from 'utils/subscriptionUtils';

import styles from './PricingTab.module.less';

export interface SubscriptionTableData {
  featureName: ReactNode;
  basic: ReactNode;
  standard: ReactNode;
  advanced: ReactNode;
  enterprise: ReactNode;
  title?: string;
}

export default function PricingTab() {
  const [priceType, setPriceType] = useState<PriceTypesEnum>();
  const isAnnual = priceType === PriceTypesEnum.Annual;
  const isLightTheme = useSelector(isLightThemeSelector);
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector(userSelector);
  const [isEnterpriseModalVisible, setIsEnterpriseModalVisible] =
    useState(false);
  const [downgradeModalState, setDowngradeModalState] = useState<{
    visible: boolean;
    subscription: SubscriptionResponseModel | null;
  }>({ visible: false, subscription: null });
  const [isUpgradeSectionVisible, setUpgradeSectionVisible] = useState(false);
  const [subscriptionForUpgrade, setSubscriptionForUpgrade] =
    useState<SubscriptionResponseModel>();
  const subscriptions = useSelector(subscriptionsSelector);
  const isSubscriptionsLoading = useSelector(isSubscriptionsLoadingSelector);
  const isMobile = useQueryMatch(DesktopScreenQuery);

  const basicSubscription = subscriptions?.find(
    (s) => s.type === SubscriptionTypeEnum.Basic,
  );
  const standardSubscription = subscriptions?.find(
    (s) =>
      s.type === SubscriptionTypeEnum.Standard &&
      s.prices?.[0]?.name === priceType,
  );
  const advancedSubscription = subscriptions?.find(
    (s) =>
      s.type === SubscriptionTypeEnum.Advanced &&
      s.prices?.[0]?.name === priceType,
  );
  const enterpriseSubscription = subscriptions?.find(
    (s) => s.type === SubscriptionTypeEnum.Enterprise,
  );

  const basicPrice = basicSubscription?.prices?.[0].price;
  const standardPrice = standardSubscription?.prices?.[0].price;
  const advancedPrice = advancedSubscription?.prices?.[0].price;

  const monthlyStandardPrice =
    isAnnual && standardPrice ? Math.round(standardPrice / 12) : standardPrice;
  const monthlyAdvancedPrice =
    isAnnual && advancedPrice ? Math.round(advancedPrice / 12) : advancedPrice;

  useEffect(() => {
    loadSubscriptions();
  }, []);

  useEffect(() => {
    if (subscriptions.length) {
      const isAnnual = !!subscriptions.find(
        (s) =>
          s.prices?.[0]?.name === PriceTypesEnum.Annual &&
          s.id === user?.subscriptionId,
      );

      const second = isAnnual ? PriceTypesEnum.Annual : PriceTypesEnum.Monthly;

      const priceTypeValue =
        user?.subscriptionType === SubscriptionTypeEnum.Basic
          ? PriceTypesEnum.Annual
          : second;

      setPriceType(priceTypeValue);
    }
  }, [subscriptions]);

  const loadSubscriptions = async () => {
    await onLoadSubscriptions();
  };

  const openUpgradeSection = (subscription: SubscriptionResponseModel) => {
    if (subscription.type === SubscriptionTypeEnum.Enterprise) {
      setIsEnterpriseModalVisible(true);
    } else {
      setSubscriptionForUpgrade(subscription);
      setUpgradeSectionVisible(true);
    }
  };

  const cancelUpgrading = async (isReloadNeeded: boolean = false) => {
    setUpgradeSectionVisible(false);

    isReloadNeeded && (await loadSubscriptions());
  };

  const closeUpgradeEnterpriseModal = () => {
    setIsEnterpriseModalVisible(false);
  };

  const openDowngradeModal = (
    subscription: SubscriptionResponseModel,
    event?: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    setDowngradeModalState({
      visible: true,
      subscription,
    });

    event?.stopPropagation();
  };

  const closeDowngradeModal = () => {
    setDowngradeModalState({
      visible: false,
      subscription: null,
    });
  };

  const onSuccessfulUpgrade = async () => {
    await dispatch(getUserInfo());
    dispatch(closeRestrictionModal());
  };

  if (isSubscriptionsLoading) return <Spin />;

  return (
    <span>
      <EnterpriseUpgradeModal
        open={isEnterpriseModalVisible}
        onClose={closeUpgradeEnterpriseModal}
      />

      <PricingDowngradeModal
        open={downgradeModalState.visible && !!downgradeModalState.subscription}
        subscription={downgradeModalState.subscription!}
        onClose={closeDowngradeModal}
        onSuccess={onSuccessfulUpgrade}
      />

      {!isUpgradeSectionVisible && subscriptions?.length && (
        <>
          <div
            className={cx('banner settings', {
              inverse: !isLightTheme,
            })}
          >
            <div className="h2">
              <h1>Compare our plans and pricing</h1>
            </div>
          </div>

          <div className={styles.box}>
            <div
              className={cx(styles.switch, {
                [styles.switchDark]: !isLightTheme,
              })}
            >
              <div className={isAnnual ? '' : styles.switchChecked}>
                Billed monthly
              </div>
              <div>
                <Switch
                  checked={isAnnual}
                  onClick={(isAnnual) =>
                    setPriceType(
                      isAnnual ? PriceTypesEnum.Annual : PriceTypesEnum.Monthly,
                    )
                  }
                  className="icon-switch"
                />
              </div>
              <div className={isAnnual ? styles.switchChecked : ''}>
                Billed annually
              </div>
            </div>
            <div
              className={cx('label', isAnnual ? 'annually' : 'monthly', {
                'label-dark': !isLightTheme,
              })}
            >
              <span>Get a 10%</span> discount by selecting an annual plan
            </div>
          </div>

          {isMobile ? (
            <PricingTableMobile
              prices={{
                basicPrice,
                standardPrice: monthlyStandardPrice,
                advancedPrice: monthlyAdvancedPrice,
              }}
              subscriptions={{
                basicSubscription,
                standardSubscription,
                advancedSubscription,
                enterpriseSubscription,
              }}
              onDowngradeSubscription={openDowngradeModal}
              onUpgradeSubscription={openUpgradeSection}
            />
          ) : (
            <PricingTableDesktop
              prices={{
                basicPrice,
                standardPrice: monthlyStandardPrice,
                advancedPrice: monthlyAdvancedPrice,
              }}
              subscriptions={{
                basicSubscription,
                standardSubscription,
                advancedSubscription,
                enterpriseSubscription,
              }}
              onUpgradeSubscription={openUpgradeSection}
              onDowngradeSubscription={openDowngradeModal}
            />
          )}
        </>
      )}
      {isUpgradeSectionVisible && (
        <PricingUpgradeSection
          subscription={subscriptionForUpgrade}
          onCancelUpgrading={cancelUpgrading}
          priceType={priceType}
        />
      )}
    </span>
  );
}
