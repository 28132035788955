import React, { useRef } from 'react';

import TemplateLibraryTab from 'features/template/TemplateListPage/TemplateLibraryTab';
import Breadcrumb from 'components/Breadcrumb';

export default function AdminTemplatesPage() {
  const infiniteScrollParentRef = useRef(null);

  return (
    <div className="page-wrapper scroll-box" ref={infiniteScrollParentRef}>
      <div className="page-box page-holder">
        <Breadcrumb
          routes={[
            {
              title: <i className="icon icon-home" />,
              link: '/',
            },
            {
              title: 'Templates',
            },
          ]}
        />

        <TemplateLibraryTab scroll={infiniteScrollParentRef} />
      </div>
    </div>
  );
}
