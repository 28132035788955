import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { App, Divider, Form, Input, Radio } from 'antd';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'redux/store';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import AlertMessage from 'components/AlertMessage';
import { closeRestrictionModal, upgradeToEnterprise } from 'redux/reducers/accountSlice';
import { userSelector } from 'redux/selectors/authenticationSelectors';
import PTModal, { PTModalProps } from 'components/PTModal';
import Controller from 'components/form/Controller';
import RadioGroup from 'components/form/RadioGroup/RadioGroup';
import { CompanySizeEnum } from 'api';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

// import styles from './EnterpriseUpgradeModal.module.less';

const companySizes = [
  {
    label: '0-100',
    value: CompanySizeEnum.From0To100,
  },
  {
    label: '101-1000',
    value: CompanySizeEnum.From101To1000,
  },
  {
    label: '1000+',
    value: CompanySizeEnum.From1000,
  },
];

type Props = {
  onClose: () => void;
} & Omit<PTModalProps, 'children'>;

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  companySize: CompanySizeEnum;
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required('Field is required')
    .max(120, `First name should be 120 characters maximum`),
  lastName: Yup.string()
    .trim()
    .required('Field is required')
    .max(120, `Last name should be 120 characters maximum`),
  email: Yup.string()
    .trim()
    .email('Email is invalid')
    .max(120, `Email should be 120 characters maximum`)
    .required('Field is required'),
  companySize: Yup.number().required('Field is required'),
});

export default function EnterpriseUpgradeModal(props: Props) {
  const { onClose, ...otherModalProps } = props;
  const user = useSelector(userSelector);
  const dispatch: AppDispatch = useDispatch();
  const isLightTheme = useSelector(isLightThemeSelector);
  const { modal } = App.useApp();

  const { errors, control, formState, handleSubmit, trigger } =
    useForm<FormValues>({
      resolver: yupResolver(validationSchema),
      mode: 'all',
      defaultValues: {
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        companySize: undefined,
      },
    });
  const { isSubmitting, isDirty } = formState;
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const handleModalCancel = () => {
    if (isSubmitting) return;

    if (isDirty) {
      return modal.confirm({
        title: 'Changes won’t be saved. Are you sure?',
        icon: <span className="icon icon-info-circle confirm-icon" />,
        className: cx('confirm-modal', {
          'confirm-modal-dark-button': !isLightTheme,
        }),
        width: '496px',
        onOk() {
          onClose();
        },
        onCancel() {},
      });
    }
    onClose();
  };

  const handleSend = () => {
    trigger();

    handleSubmit(onSubmit)();
  };

  const onSubmit = async (values: FormValues) => {
    const { firstName, lastName, email, companySize } = values;

    setIsFormSubmitting(true);

    const result = await dispatch(
      upgradeToEnterprise({
        firstName,
        lastName,
        businessEmail: email,
        companySize,
      }),
    );

    setIsFormSubmitting(false);

    if (upgradeToEnterprise.fulfilled.match(result)) {
      onClose();
      dispatch(closeRestrictionModal());
      AlertMessage.success(
        'Thank you for your inquiry. We will be contacting you shortly.',
      );
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  return (
    <PTModal
      {...otherModalProps}
      cancelText="Cancel"
      okText="Send"
      onOk={handleSend}
      onCancel={handleModalCancel}
      okButtonProps={{ loading: isFormSubmitting }}
      closable
      className="modal-root"
    >
      <Form layout="vertical">
        <div className="modal-scroll-wrapper">
          <p>
            Get in touch with our sales team. We will help you with the setup
            and work with you to choose the best options available.
          </p>

          <div>
            <Controller
              name="firstName"
              control={control}
              as={Input}
              label="First name"
              size="large"
              error={errors.firstName}
              containerClassName="dark-input-group"
            />

            <Divider className="dark-divider mt-0" />

            <Controller
              name="lastName"
              control={control}
              as={Input}
              label="Last name"
              size="large"
              error={errors.lastName}
              containerClassName="dark-input-group"
            />

            <Divider className="dark-divider mt-0" />

            <Controller
              name="email"
              control={control}
              as={Input}
              label="Business email"
              size="large"
              error={errors.email}
              containerClassName="dark-input-group"
            />

            <Divider className="dark-divider mt-0" />

            <Controller
              name="companySize"
              control={control}
              error={errors.companySize}
              label="Company size"
              render={(field) => (
                <div>
                  <RadioGroup {...field}>
                    {companySizes.map((size) => (
                      <Radio
                        key={`company-size-radio-${size.value}`}
                        value={size.value}
                      >
                        {size.label}
                      </Radio>
                    ))}
                  </RadioGroup>
                </div>
              )}
            />
          </div>
        </div>
      </Form>
    </PTModal>
  );
}
