import React from 'react';
import cx from 'classnames';

import Pagination, { LandingSectionsEnum } from '../Pagination/Pagination';

import styles from './BusinessGrowthSection.module.less';
import commonStyles from '../../LandingPage/LandingPage.module.less';

export default function BusinessGrowthSection() {
  return (
    <section
      className={styles.businessSection}
      id={LandingSectionsEnum.BusinessGrowth}
    >
      <div className={commonStyles.container}>
        <div className={styles.card}>
          <div className={styles.cardGrid}></div>
          <div className={cx(styles.cardTitle, commonStyles.title)}>
            <h2>
              <span>LESS ADMIN WORK. </span>
              <div>MORE BUSINESS GROWTH.</div>
            </h2>
          </div>
          <div className={styles.cardContent}>
            <div className={styles.cardText}>
              <p>
                On average, customers call at least <strong>once a week</strong>{' '}
                to inquire on the status of their legal cases, placed orders,
                applications, etc.
              </p>
              <p>
                Free yourself up to focus more on{' '}
                <strong>growing your business</strong> & less on answering
                status inquiry calls.
              </p>
            </div>
            <div className={cx(commonStyles.iconCard, styles.cardIcon)}>
              <i className="icon icon-schedule"></i>
            </div>
            <div className={cx(commonStyles.iconCard, styles.cardIconSecond)}>
              <i className="icon icon-triangle"></i>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        className={styles.pagination}
        activeSection={LandingSectionsEnum.BusinessGrowth}
      />
    </section>
  );
}
