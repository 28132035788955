import React, { useEffect, useRef, useState } from 'react';
import {
  App,
  Avatar,
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Tooltip,
} from 'antd';
import cx from 'classnames';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useWillUnmount } from 'rooks';
import * as Yup from 'yup';
import { UserOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import { isNil } from 'lodash';

import CancelSubscriptionModal from './CancelSubscriptionModal';
import {
  AttachmentResponseModel,
  BillingCycleEnum,
  CompanySizeEnum,
  IndustriesEnum,
  SubscriptionTypeEnum,
  TimeZoneEnum,
  UserResponseModel,
  UserUpdateRequestModel,
} from 'api';
import AlertMessage from 'components/AlertMessage';
import Controller from 'components/form/Controller';
import SettingsDetailsSection from 'components/settings/SettingsDetailsSection';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { maxLength, requiredMessage } from 'constants/ValidationConstants';
import LogoUploader from 'features/account/AccountSettingsPage/ProfileTab/LogoUploader';
import ChangeBillingCycleModal from 'features/account/AccountSettingsPage/ProfileTab/ChangeBillingCycleModal';
import { userSelector } from 'redux/selectors/authenticationSelectors';
import { AppDispatch } from 'redux/store';
import {
  checkIsSubdomainVisible,
  deleteLogo,
  getLogo,
  setProfileFormDirty,
  updateProfile,
} from 'redux/reducers/accountSlice';
import { getUserInfo } from 'redux/reducers/authenticationSlice';
import { alertBeforeUnload } from 'utils/confirmationUtils';
import {
  getDateForUserTimezone,
  NEXT_BILLING_DATE_DATE_FORMAT,
} from 'utils/dateUtils';
import {
  getCompanySizeOptions,
  getCountryPhoneCode,
  getTimezoneOptions,
} from 'utils/accountUtils';
import PhoneInput from 'components/form/PhoneInput';
import { countriesSelector } from 'redux/selectors/regionSelectors';
import { getAllCountries } from 'redux/reducers/regionSlice';
import { getCategoriesOptions } from 'utils/templateUtils';
import Checkbox from 'components/form/Checkbox/Checkbox';

import styles from './ProfileTab.module.less';

const validationSchema = Yup.object().shape(
  {
    firstName: Yup.string()
      .trim()
      .required(requiredMessage)
      .max(120, `First name should be ${maxLength} characters maximum`),
    lastName: Yup.string()
      .trim()
      .required(requiredMessage)
      .max(120, `Last name should be ${maxLength} characters maximum`),
    email: Yup.string()
      .trim()
      .email('Email is invalid')
      .max(maxLength, `Email should be ${maxLength} characters maximum`)
      .required(requiredMessage),
    subdomain: Yup.string()
      .trim()
      .max(30, 'Subdomain should be 30 characters maximum'),
    companyName: Yup.string()
      .trim()
      .max(50, 'Company name should be 50 characters maximum')
      .required(requiredMessage),
    companyWebSiteUrl: Yup.string()
      .trim()
      .max(50, 'Company website URL should be 50 characters maximum')
      .required(requiredMessage),
    companySize: Yup.string().trim().required(requiredMessage),
    jobTitle: Yup.string()
      .trim()
      .max(50, 'Job title should be 50 characters maximum'),
    customIndustry: Yup.string().when('industry', {
      is: (industry: IndustriesEnum) => +industry === IndustriesEnum.Other,
      then: Yup.string()
        .trim()
        .max(50, 'Industry should be 50 characters maximum')
        .required(requiredMessage),
    }),
    industry: Yup.string().when('customIndustry', {
      is: (customIndustry: string) => isNil(customIndustry),
      then: Yup.string().required(requiredMessage),
    }),
    phoneNumber: Yup.string()
      .trim()
      .max(15, 'Phone should be 15 characters maximum')
      .required(requiredMessage),
    isSmsAllowed: Yup.boolean(),
    countryCodeId: Yup.string().required(requiredMessage),
    timeZone: Yup.string().trim().required(requiredMessage),
    linkedinLink: Yup.string()
      .trim()
      .max(20, 'Linkedin link should be 20 characters maximum'),
    twitterLink: Yup.string()
      .trim()
      .max(20, 'Twitter link should be 20 characters maximum'),
    fbLink: Yup.string()
      .trim()
      .max(20, 'FB link should be 20 characters maximum'),
    instagramLink: Yup.string()
      .trim()
      .max(20, 'Instagram link should be 20 characters maximum'),
  },
  [['industry', 'customIndustry']],
);

type ProfileFormValues = {
  firstName?: string;
  lastName?: string;
  email?: string;
  subdomain?: string;
  companyName?: string;
  companyWebSiteUrl?: string;
  companySize?: CompanySizeEnum;
  jobTitle?: string;
  industry?: IndustriesEnum;
  customIndustry?: string;
  phoneNumber?: string;
  isSmsAllowed?: boolean;
  countryCodeId?: string;
  abbreviation?: string;
  countryCode?: string;
  timeZone?: TimeZoneEnum;
  linkedinLink?: string;
  twitterLink?: string;
  fbLink?: string;
  instagramLink?: string;
};

export default function ProfileTab() {
  const isLightTheme = useSelector(isLightThemeSelector);
  const [isUploading, setIsUploading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSubdomainVisible, setIsSubdomainVisible] = useState(false);
  const [logo, setLogo] = useState<AttachmentResponseModel>();
  const [isCancelSubscriptionModalOpen, setIsCancelSubscriptionModalOpen] =
    useState(false);
  const [isChangeBillingCycleModalOpen, setIsChangeBillingCycleModalOpen] =
    useState(false);
  const countries = useSelector(countriesSelector);
  const user = useSelector(userSelector);
  const [countryPhoneCode, setCountryPhoneCode] = useState<string | undefined>(
    getCountryPhoneCode(user?.userProfileDetails?.countryCodeId),
  );
  const uploaderRef: any = useRef(null);
  const dispatch: AppDispatch = useDispatch();
  const { modal } = App.useApp();
  const isBasicPlan = user?.subscriptionType === SubscriptionTypeEnum.Basic;
  const isStandardPlan =
    user?.subscriptionType === SubscriptionTypeEnum.Standard;
  const isEnterprisePlan =
    user?.subscriptionType === SubscriptionTypeEnum.Enterprise;

  const getInitialValues = (
    user: UserResponseModel | null,
  ): ProfileFormValues => {
    return {
      firstName: user?.firstName || undefined,
      lastName: user?.lastName || undefined,
      email: user?.email || undefined,
      subdomain: user?.subdomain || undefined,
      companyName: user?.userProfileDetails?.companyName || undefined,
      companyWebSiteUrl:
        user?.userProfileDetails?.companyWebsiteUrl || undefined,
      companySize: user?.userProfileDetails?.companySize || undefined,
      jobTitle: user?.userProfileDetails?.jobTitle || undefined,
      industry: user?.userProfileDetails?.industry || undefined,
      customIndustry:
        user?.userProfileDetails?.industry === IndustriesEnum.Other
          ? user.userProfileDetails?.otherIndustry
          : undefined,
      phoneNumber: user?.userProfileDetails?.phoneNumber || undefined,
      isSmsAllowed: user?.userProfileDetails?.isSmsAllowed || undefined,
      countryCodeId: user?.userProfileDetails?.countryCodeId || undefined,
      timeZone: user?.userProfileDetails?.timeZone || undefined,
      linkedinLink: user?.userProfileDetails?.linkedin || undefined,
      twitterLink: user?.userProfileDetails?.twitter || undefined,
      fbLink: user?.userProfileDetails?.facebook || undefined,
      instagramLink: user?.userProfileDetails?.instagram || undefined,
    };
  };

  const { control, handleSubmit, formState, errors, reset, watch } =
    useForm<ProfileFormValues>({
      resolver: yupResolver(validationSchema),
      mode: 'all',
      defaultValues: getInitialValues(user),
    });
  const { isDirty, isSubmitting } = formState;
  const industryValue = watch().industry;

  useEffect(() => {
    dispatch(getAllCountries());
  }, [dispatch]);

  useEffect(() => {
    getCompanyLogo();
    checkSubdomainVisibility();
  }, [user]);

  useEffect(() => {
    dispatch(setProfileFormDirty(isDirty));
    return alertBeforeUnload(isDirty);
  }, [isDirty]);

  useWillUnmount(() => {
    dispatch(setProfileFormDirty(false));
  });

  const getCompanyLogo = async () => {
    const result = await dispatch(getLogo());

    if (getLogo.fulfilled.match(result)) {
      setLogo(result.payload);
    } else {
      if (result.payload.status === 404) return;
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const checkSubdomainVisibility = async () => {
    if (!user?.spaceId) return;

    const result = await dispatch(checkIsSubdomainVisible(user?.spaceId));

    if (result.payload) {
      setIsSubdomainVisible(true);
    } else {
      setIsSubdomainVisible(false);
    }
  };

  const onEditCancel = () => {
    reset();
    setIsEditMode(false);
  };

  const showConfirmation = () =>
    modal.confirm({
      title: 'Changes won’t be saved. Are you sure?',
      icon: <span className="icon icon-info-circle confirm-icon" />,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      width: '496px',
      onOk() {
        onEditCancel();
      },
      onCancel() {},
    });

  const handleCancelClick = () => {
    isDirty ? showConfirmation() : onEditCancel();
  };

  const handleDeleteLogo = async () => {
    const result = await dispatch(deleteLogo(user?.id || ''));

    if (deleteLogo.fulfilled.match(result)) {
      setLogo(undefined);
      AlertMessage.success('Logo successfully deleted.');
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const onDeleteLogoClick = () => {
    modal.confirm({
      content: 'Are you sure you want to delete the logo?',
      icon: <span className="icon icon-info-circle confirm-icon" />,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      width: '440px',
      cancelText: 'Cancel',
      okText: 'Delete',
      onOk: handleDeleteLogo,
    });
  };

  const handleUploading = (isUploading: boolean) => {
    setIsUploading(isUploading);
  };

  const handleProfileEdit = () => setIsEditMode(true);

  const getAvatarIcon = () => {
    if (isUploading) {
      return <Spin />;
    }

    return logo ? (
      <img alt="Company logo" src={logo?.getFileUrl} />
    ) : (
      <UserOutlined />
    );
  };

  const handleCancelSubscriptionModalOpen = () =>
    setIsCancelSubscriptionModalOpen(true);

  const handleCancelSubscriptionModalClose = () =>
    setIsCancelSubscriptionModalOpen(false);

  const handleChangeBillingCycleModalClose = () =>
    setIsChangeBillingCycleModalOpen(false);

  const handleChangeBillingCycleModalOpen = () =>
    setIsChangeBillingCycleModalOpen(true);

  const renderSettingsRightSideNode = () => (
    <>
      {!isEditMode && (
        <div>
          <Button
            className={cx({
              'dark-btn': isLightTheme,
              'ant-btn-primary': !isLightTheme,
            })}
            size="large"
            loading={isSubmitting}
            onClick={handleProfileEdit}
          >
            Edit
          </Button>
        </div>
      )}
    </>
  );

  const onSubmit = async (values: ProfileFormValues) => {
    const requestBody: UserUpdateRequestModel = {
      firstName: values.firstName || undefined,
      lastName: values.lastName || undefined,
      subdomain: values.subdomain || undefined,
      id: user?.id,
      spaceId: user?.spaceId,
      email: user?.email,
      profileDetailsEditRequest: {
        id: !isNil(user?.userProfileDetails)
          ? user?.userProfileDetails.id
          : undefined,
        companyName: values.companyName || undefined,
        companyWebsiteUrl: values.companyWebSiteUrl || undefined,
        companySize: values.companySize || undefined,
        jobTitle: values.jobTitle || undefined,
        industry: values.industry || undefined,
        otherIndustry: values.customIndustry || undefined,
        countryCodeId: values.countryCodeId || undefined,
        phoneNumber: values.phoneNumber || undefined,
        isSmsAllowed: values.isSmsAllowed || undefined,
        linkedin: values.linkedinLink || undefined,
        twitter: values.twitterLink || undefined,
        facebook: values.fbLink || undefined,
        instagram: values.instagramLink || undefined,
        timeZone: values.timeZone || undefined,
      },
    };

    const result = await dispatch(updateProfile(requestBody));

    if (updateProfile.fulfilled.match(result)) {
      setIsEditMode(false);
      AlertMessage.success('Profile was successfully updated.');
      onSetDefaultValue();
    } else {
      reset();
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const onSetDefaultValue = async () => {
    const result = await dispatch(getUserInfo());

    if (getUserInfo.fulfilled.match(result)) {
      const initialValues = getInitialValues(result.payload);
      reset(initialValues);
    }
  };

  const handleCountryCodeSelect = (countryCode: string) => {
    const phoneCode = getCountryPhoneCode(countryCode);
    setCountryPhoneCode(phoneCode);
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
      <SettingsDetailsSection
        title="My account"
        description="Review and update your account details."
        formState={isEditMode ? formState : undefined}
        onClose={handleCancelClick}
        rightSideNode={renderSettingsRightSideNode()}
      />
      <Row gutter={[20, 20]}>
        <Col xs={24} lg={8} xl={6} className="d-flex">
          <div className={styles.stretch}>
            <div className={styles.card}>
              <div className={styles.cardAvatar}>
                <Avatar size={120} alt="Company logo" icon={getAvatarIcon()} />
              </div>
              <div className={styles.cardBox}>
                <div>
                  <LogoUploader
                    uploaderRef={uploaderRef}
                    logo={logo}
                    userId={user?.id}
                    onChange={setLogo}
                    onUploading={handleUploading}
                  />
                </div>
                {logo && (
                  <div className={styles.btn} onClick={onDeleteLogoClick}>
                    Remove
                  </div>
                )}
              </div>
              <div className={styles.cardName}>{user?.spaceName}</div>
            </div>
          </div>
        </Col>
        <Col xs={24} lg={16} xl={18} className="d-flex">
          <div className={styles.stretch}>
            <div className={styles.card}>
              <Row gutter={[10, 10]}>
                <Col xs={24} md={12}>
                  <Controller
                    name="firstName"
                    control={control}
                    label="First name"
                    placeholder="Enter first name"
                    as={Input}
                    error={errors.firstName}
                    disabled={!isEditMode}
                    containerClassName={styles.input}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <Controller
                    name="lastName"
                    label="Last name"
                    control={control}
                    placeholder="Enter last name"
                    as={Input}
                    error={errors.lastName}
                    disabled={!isEditMode}
                    containerClassName={styles.input}
                  />
                </Col>
              </Row>
              <Divider className="lighten-divider mt-0" />
              <Row gutter={[10, 10]}>
                <Col xs={24} md={12}>
                  <Controller
                    name="email"
                    label="Email"
                    control={control}
                    placeholder="Enter email"
                    as={Input}
                    error={errors.email}
                    disabled
                    containerClassName={styles.input}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <div className={styles.label}>Phone</div>

                  <div className={styles.phone}>
                    <Controller
                      name="countryCodeId"
                      control={control}
                      render={(field) => (
                        <Select
                          {...field}
                          onSelect={handleCountryCodeSelect}
                          disabled={!isEditMode}
                        >
                          {countries.map((country, index) => (
                            <Select.Option key={index} value={country.id}>
                              {country.id}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                      error={errors.countryCodeId}
                      containerClassName={styles.input}
                    />
                    <Controller
                      name="phoneNumber"
                      control={control}
                      placeholder="Enter phone number"
                      render={(field) => (
                        <PhoneInput
                          {...field}
                          placeholder="Enter phone number"
                          prefix={
                            !isNil(countryPhoneCode)
                              ? `+ ${countryPhoneCode}`
                              : null
                          }
                          disabled={!isEditMode}
                        />
                      )}
                      error={errors.phoneNumber}
                      containerClassName={styles.input}
                    />
                  </div>
                </Col>
              </Row>

              <Row gutter={[10, 10]}>
                <Col xs={0} md={12}></Col>
                <Col xs={24} md={12}>
                  <div className={styles.wrapper}>
                    <Controller
                      name="isSmsAllowed"
                      control={control}
                      error={errors.isSmsAllowed}
                      render={(field) => (
                        <Checkbox {...field} disabled={!isEditMode}>
                          By checking this box, you consent to receive text
                          messages from Apdated. Standard messaging and data
                          rates may apply. You can update your preferences from
                          this page or by replying 'STOP' to cancel.
                        </Checkbox>
                      )}
                    />
                  </div>
                </Col>
              </Row>
              <Divider className="lighten-divider mt-0" />
              <Row gutter={[10, 10]}>
                <Col xs={24} md={12}>
                  <Controller
                    name="companyName"
                    label="Company name *"
                    control={control}
                    placeholder="Enter company name"
                    as={Input}
                    error={errors.companyName}
                    disabled={!isEditMode}
                    containerClassName={styles.input}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <Controller
                    name="companySize"
                    label="Company size *"
                    control={control}
                    placeholder="Select company size"
                    as={
                      <Select size="large">
                        {getCompanySizeOptions().map((companySize) => (
                          <Select.Option
                            key={companySize.value}
                            value={companySize.value}
                          >
                            {companySize.label}
                          </Select.Option>
                        ))}
                      </Select>
                    }
                    disabled={!isEditMode}
                    error={errors.companySize}
                    containerClassName={styles.input}
                  />
                </Col>
                <Col xs={24} md={24}>
                  <Controller
                    name="companyWebSiteUrl"
                    label="Company website URL *"
                    control={control}
                    placeholder="Enter company website URL"
                    as={Input}
                    error={errors.companyWebSiteUrl}
                    disabled={!isEditMode}
                    containerClassName={styles.input}
                  />
                </Col>
                <Col xs={24} md={24}>
                  <Controller
                    name="jobTitle"
                    label="Job title"
                    control={control}
                    placeholder="Enter job title"
                    as={Input}
                    error={errors.jobTitle}
                    disabled={!isEditMode}
                    containerClassName={styles.input}
                  />
                </Col>
                <Col xs={24} md={24}>
                  <Controller
                    name="industry"
                    label={
                      industryValue === IndustriesEnum.Other
                        ? 'Industry'
                        : 'Industry *'
                    }
                    control={control}
                    placeholder="Select your industry"
                    as={
                      <Select size="large" disabled={!isEditMode}>
                        {getCategoriesOptions().map((industry) => (
                          <Select.Option
                            key={industry.value}
                            value={industry.value}
                          >
                            {industry.label}
                          </Select.Option>
                        ))}
                        <Select.Option
                          key={IndustriesEnum.Other}
                          value={IndustriesEnum.Other}
                        >
                          Other
                        </Select.Option>
                      </Select>
                    }
                    error={errors.industry}
                    containerClassName={styles.input}
                  />
                </Col>
                {industryValue === IndustriesEnum.Other && (
                  <Col xs={24} md={24}>
                    <Controller
                      name="customIndustry"
                      label="Industry name *"
                      control={control}
                      placeholder="Enter your industry"
                      as={Input}
                      error={errors.jobTitle}
                      disabled={!isEditMode}
                      containerClassName={styles.input}
                    />
                  </Col>
                )}
                <Col xs={24} md={24}>
                  <Controller
                    name="timeZone"
                    label="Time zone *"
                    control={control}
                    placeholder="Select time zone"
                    as={
                      <Select size="large" disabled={!isEditMode}>
                        {getTimezoneOptions().map((timezone, index) => (
                          <Select.Option key={index} value={timezone.value}>
                            {timezone.label}
                          </Select.Option>
                        ))}
                      </Select>
                    }
                    error={errors.timeZone}
                    disabled={!isEditMode}
                    containerClassName={styles.input}
                  />
                </Col>
              </Row>
              <Divider className="lighten-divider mt-0" />
              <Row gutter={[10, 10]}>
                <Col xs={24} md={24}>
                  <div className={styles.cardTitle}>Social media links</div>
                </Col>
                <Col xs={24} md={24}>
                  <Controller
                    name="linkedinLink"
                    label="LinkedIn"
                    control={control}
                    render={(field) => (
                      <Input
                        {...field}
                        disabled={!isEditMode}
                        prefix={<span>@</span>}
                      />
                    )}
                    error={errors.linkedinLink}
                    disabled={!isEditMode}
                    containerClassName={styles.input}
                  />
                </Col>
                <Col xs={24} md={24}>
                  <Controller
                    name="twitterLink"
                    label="X (ex Twitter)"
                    control={control}
                    render={(field) => (
                      <Input
                        {...field}
                        disabled={!isEditMode}
                        prefix={<span>@</span>}
                      />
                    )}
                    error={errors.twitterLink}
                    disabled={!isEditMode}
                    containerClassName={styles.input}
                  />
                </Col>
                <Col xs={24} md={24}>
                  <Controller
                    name="fbLink"
                    label="Facebook"
                    control={control}
                    render={(field) => (
                      <Input
                        {...field}
                        disabled={!isEditMode}
                        prefix={<span>@</span>}
                      />
                    )}
                    error={errors.fbLink}
                    containerClassName={styles.input}
                  />
                </Col>
                <Col xs={24} md={24}>
                  <Controller
                    name="instagramLink"
                    label="Instagram"
                    control={control}
                    render={(field) => (
                      <Input
                        {...field}
                        disabled={!isEditMode}
                        prefix={<span>@</span>}
                      />
                    )}
                    error={errors.instagramLink}
                    disabled={!isEditMode}
                    containerClassName={styles.input}
                  />
                </Col>
              </Row>
              {isSubdomainVisible && (
                <>
                  <Divider className="lighten-divider mt-0" />
                  <Row gutter={[10, 10]}>
                    <Col xs={24} md={24}>
                      <div
                        className={cx(styles.label, {
                          [styles.inverse]: !isLightTheme,
                        })}
                      >
                        Subdomain field
                        <Tooltip
                          placement="right"
                          title="Enter your company’s domain name. E.g., if your
                          company’s website is http://www.companyname.com, type
                          the word companyname into the “Enter subdomain”
                          field then save your changes."
                        >
                          <i className="icon icon-question-circle" />
                        </Tooltip>
                      </div>
                      <Controller
                        name="subdomain"
                        control={control}
                        placeholder="Enter subdomain"
                        suffix=".apdated.co"
                        as={Input}
                        error={errors.subdomain}
                        disabled={!isEditMode}
                        containerClassName={styles.input}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <div className={styles.summary}>
        <div className={styles.summaryTitle}>Subscription summary</div>

        <div className={styles.summarySubscriptions}>
          <div className={styles.box}>
            <div>
              Current subscription: <strong>{user?.subscriptionName}</strong>
              {!isBasicPlan &&
                !isEnterprisePlan &&
                !user?.isBillingCycleChangeRequested && (
                  <div
                    className={styles.summaryBtn}
                    onClick={() => setIsChangeBillingCycleModalOpen(true)}
                  >
                    Change billing cycle
                  </div>
                )}
            </div>
            {isStandardPlan && (
              <div className={styles.boxTrial}>
                Please note that by adding your credit card during the 14-day
                free trial period, it will automatically be charged at the end
                of the trial period. You can cancel your subscription before the
                end of the trial period to avoid being charged.
              </div>
            )}
            <div>
              {!isBasicPlan && !isEnterprisePlan && (
                <div>
                  Current billing cycle:{' '}
                  <strong>
                    {BillingCycleEnum[user?.billingCycle as number]}
                  </strong>
                </div>
              )}
            </div>
            <div>
              {user?.isSubscriptionCanceled ? (
                <>
                  Your subscription will be canceled on{' '}
                  <strong>
                    {getDateForUserTimezone(
                      user.nextPaymentDate,
                      NEXT_BILLING_DATE_DATE_FORMAT,
                    )}
                  </strong>
                </>
              ) : (
                <>
                  {!isBasicPlan && user?.nextPaymentDate && (
                    <>
                      Your next billing date is{' '}
                      <strong>
                        {getDateForUserTimezone(
                          user.nextPaymentDate,
                          NEXT_BILLING_DATE_DATE_FORMAT,
                        )}
                      </strong>
                    </>
                  )}
                </>
              )}
            </div>
          </div>

          {!user?.isSubscriptionCanceled && !isBasicPlan && (
            <div className={styles.cancelBtn}>
              <Button
                size="large"
                className={cx({
                  'dark-btn': isLightTheme,
                  'ant-btn-primary': !isLightTheme,
                })}
                onClick={handleCancelSubscriptionModalOpen}
              >
                Cancel subscription
              </Button>
            </div>
          )}
        </div>
      </div>

      <CancelSubscriptionModal
        open={isCancelSubscriptionModalOpen}
        onClose={handleCancelSubscriptionModalClose}
      />

      <ChangeBillingCycleModal
        open={isChangeBillingCycleModalOpen}
        onClose={handleChangeBillingCycleModalClose}
        onOpen={handleChangeBillingCycleModalOpen}
      />
    </Form>
  );
}
