import React, { useState } from 'react';
import { Button, Divider } from 'antd';
import { useSelector } from 'react-redux';

import SettingsDetailsSection from 'components/settings/SettingsDetailsSection';
import ChangePasswordSection from 'features/account/AccountSettingsPage/SecurityTab/СhangePasswordSection';
import TransferOwnershipModal from 'features/account/AccountSettingsPage/SecurityTab/TransferOwnershipModal';
import TwoFASection from 'features/account/AccountSettingsPage/SecurityTab/TwoFASection';
import { isAdminSelector } from 'redux/selectors/authenticationSelectors';
import { isSuperAdminSelector } from 'redux/selectors/roleSelectors';
import UserSessionsSection from './UserSessionsSection';

import styles from './SecurityTab.module.less';

export default function SecurityTab() {
  const [isTransferOwnershipModalOpen, setIsTransferOwnershipModalOpen] =
    useState(false);
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const isAdmin = useSelector(isAdminSelector);

  const handleTransferOwnershipModalOpen = () =>
    setIsTransferOwnershipModalOpen(true);

  const handleTransferOwnershipModalClose = () =>
    setIsTransferOwnershipModalOpen(false);

  return (
    <>
      <SettingsDetailsSection
        title="Security"
        description="Manage your account security."
      />

      <TwoFASection />

      <Divider />

      <ChangePasswordSection />

      <Divider />

      <UserSessionsSection />

      {isSuperAdmin && !isAdmin && (
        <>
          <div className={styles.title}>Transfer ownership</div>

          <div className={styles.transfer}>
            <div>
              The space owner is normally the person that created the company's
              account and is by default the site administrator.
            </div>
            <div>
              <Button
                type="primary"
                size="large"
                onClick={handleTransferOwnershipModalOpen}
              >
                Transfer ownership
              </Button>
            </div>
            <div className={styles.transferNote}>
              <b>Note:</b>
              <div>
                Once a transfer of ownership is completed, it cannot be undone.
                You will lose access to admin features that are exclusive to the
                site owner. Extra caution is advised when utilizing this
                feature.
              </div>
            </div>

            <TransferOwnershipModal
              open={isTransferOwnershipModalOpen}
              onClose={handleTransferOwnershipModalClose}
            />
          </div>
        </>
      )}
    </>
  );
}
