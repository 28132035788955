import React, { useEffect, useState } from 'react';
import { App, Button, Col, Form, Input, Radio, Row, Select, Spin } from 'antd';
import cx from 'classnames';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useDidUpdate } from 'rooks';

import Controller from 'components/form/Controller';
import PTModal, { PTModalProps } from 'components/PTModal';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import {
  PaymentMethodResponseModel,
  SpaceRoleResponseModel,
  SubscriptionSumDescriptionResponseModel,
  SubscriptionTypeEnum,
  UserSpaceRoleEnum,
} from 'api';
import { requiredMessage } from 'constants/ValidationConstants';
import RadioGroup from 'components/form/RadioGroup/RadioGroup';
import { getRolesToInvite } from 'redux/reducers/inviteSlice';
import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { AppDispatch } from 'redux/store';
import { getPriceDetails as getPriceDetailsApi } from 'redux/reducers/transactionSlice';
import { getCardList } from 'redux/reducers/accountSlice';
import { userSelector } from 'redux/selectors/authenticationSelectors';
import { subscriptionsSelector } from 'redux/selectors/aссountSelectors';
import { onLoadSubscriptions } from 'utils/subscriptionUtils';

import styles from '../EditInvitedUserModal/EditInvitedUserModal.module.less';

const userValidationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('Email format is invalid')
    .max(120, 'Email should be 120 characters maximum')
    .required(requiredMessage),
  roleId: Yup.string().required(requiredMessage),
});

const validationSchema = Yup.object().shape({
  emails: Yup.array()
    .of(Yup.string())
    .min(1, requiredMessage)
    .required(requiredMessage),
  users: Yup.array().of(userValidationSchema),
});

interface UserFormValues {
  email?: string;
  roleId?: UserSpaceRoleEnum;
}

export interface InviteUsersFormValues {
  emails: string[];
  users: UserFormValues[];
}

type Props = {
  onClose: () => void;
  onSuccess: (values: InviteUsersFormValues) => void;
} & Omit<PTModalProps, 'children'>;

export default function InviteUsersModal(props: Props) {
  const { onClose, onSuccess, open, ...otherModalProps } = props;
  const [isInitialLoaded, setIsInitialLoaded] = useState(false);
  const [rolesToInvite, setRolesToInvite] = useState<SpaceRoleResponseModel[]>(
    [],
  );
  const [priceDetails, setPriceDetails] =
    useState<SubscriptionSumDescriptionResponseModel>();
  const [isPriceGetting, setIsPriceGetting] = useState(false);
  const [currentCard, setCurrentCard] = useState<PaymentMethodResponseModel>();
  const user = useSelector(userSelector);
  const totalPrice = ((priceDetails?.totalSum || 0) / 100).toFixed(2);

  const dispatch: AppDispatch = useDispatch();
  const {
    errors,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting, isDirty },
    trigger,
  } = useForm<InviteUsersFormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: {
      emails: undefined,
      users: undefined,
    },
  });
  const { fields } = useFieldArray<UserFormValues>({
    control,
    name: 'users',
    keyName: 'id',
  });
  const isLightTheme = useSelector(isLightThemeSelector);
  const emailsValues = watch().emails;
  const usersValues = watch().users || [];
  const { modal } = App.useApp();
  const subscriptions = useSelector(subscriptionsSelector);
  const teammateInviteSubscription = subscriptions.find(
    (subscription) =>
      subscription.type === SubscriptionTypeEnum.TeammateInvite &&
      subscription.billingCycle === user?.billingCycle,
  );

  useEffect(() => {
    if (!open) return;

    onLoadSubscriptions();
  }, [dispatch, open]);

  useDidUpdate(() => {
    if (!open) return;

    onSetUserValues(emailsValues);
  }, [emailsValues, open]);

  const getPriceDetails = async (quantity: number) => {
    if (!quantity) return setPriceDetails(undefined);

    setIsPriceGetting(true);
    const result = await dispatch(
      getPriceDetailsApi({
        priceId: teammateInviteSubscription?.prices?.[0].priceId || '',
        defaultPaymentMethod: currentCard?.id || '',
        quantity,
        isInviteRequested: true,
        requestedBillingCycle: user?.billingCycle,
      }),
    );

    if (getPriceDetailsApi.fulfilled.match(result)) {
      setPriceDetails(result.payload);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
    setIsPriceGetting(false);
  };

  const onSetUserValues = async (emailsValues: string[] = []) => {
    const updated = emailsValues.map((email, index) => ({
      email: email || undefined,
      roleId: usersValues[index]?.roleId || undefined,
    }));
    setValue('users', updated);
    await trigger('users');
    getPriceDetails(emailsValues?.length || 0);
  };

  const handleModalCancel = () => {
    if (isSubmitting) return;

    if (isDirty) {
      return modal.confirm({
        title: 'Changes won’t be saved. Are you sure?',
        icon: <span className="icon icon-info-circle confirm-icon" />,
        className: cx('confirm-modal', {
          'confirm-modal-dark-button': !isLightTheme,
        }),
        width: '496px',
        onOk() {
          onClose();
        },
        onCancel() {},
        okText: 'Yes',
      });
    }
    onClose();
  };

  const onSubmit = (values: InviteUsersFormValues) => {
    onSuccess(values);
  };

  const handleOk = () => {
    handleSubmit(onSubmit)();
  };

  const fetchRolesToInvite = async () => {
    const result = await dispatch(getRolesToInvite());

    if (getRolesToInvite.fulfilled.match(result)) {
      setRolesToInvite(result.payload);
    } else {
      onClose();
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
    setIsInitialLoaded(true);
  };

  useEffect(() => {
    fetchRolesToInvite();
  }, []);

  const getCreditCards = async () => {
    const result = await dispatch(getCardList(user?.id || ''));

    if (getCardList.fulfilled.match(result)) {
      setCurrentCard(result.payload[0]);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  useEffect(() => {
    getCreditCards();
  }, []);

  return (
    <PTModal
      {...otherModalProps}
      open={open}
      title="Invite users"
      footer={
        <div className={styles.footer}>
          <div className={styles.footerPrice}>
            Price <span> {isPriceGetting ? <Spin /> : '$ ' + totalPrice}</span>
          </div>
          <div>
            <Button onClick={handleModalCancel}>Cancel</Button>

            <Button onClick={handleOk} loading={isSubmitting} type="primary">
              Pay & invite
            </Button>
          </div>
        </div>
      }
      className="modal-root"
    >
      <Form layout="vertical">
        <div className="modal-scroll-wrapper">
          <Row gutter={[0, 20]}>
            <Col xs={24}>
              <div className={styles.info}>
                <Row gutter={[0, 10]}>
                  <Col xs={24}>
                    <div className={styles.infoTitle}>Role Description</div>
                  </Col>
                  <Col xs={24}>
                    <Row gutter={[0, 0]}>
                      <Col xs={5}>Associate:</Col>
                      <Col xs={19}>
                        <div className={styles.infoText}>
                          Licensed users who have been invited as team members
                          by the account administrator.
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24}>
                    <Row gutter={[0, 0]}>
                      <Col xs={5}>Admin:</Col>
                      <Col xs={19}>
                        <div className={styles.infoText}>
                          Licensed users who have been invited as admins by the
                          account administrator.
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={24}>
              <div className={styles.input}>
                <Controller
                  name="emails"
                  control={control}
                  defaultValue={undefined}
                  error={errors.emails as any}
                  label="Email"
                  render={(field) => (
                    <Select
                      {...field}
                      mode="tags"
                      placeholder="Enter email address"
                    />
                  )}
                  size="large"
                />
              </div>
            </Col>

            <Col xs={24}>
              <div className={styles.wrapper}>
                {fields.map((field, index) => {
                  return (
                    <div key={field.id} className={styles.item}>
                      <Controller
                        name={`users[${index}].email`}
                        defaultValue={field?.email ?? undefined}
                        control={control}
                        error={errors.users?.[index]?.email ?? undefined}
                        placeholder="Enter email address"
                        disabled={true}
                        as={Input}
                        size="large"
                      />
                      <Controller
                        name={`users[${index}].roleId`}
                        control={control}
                        error={errors.users?.[index]?.roleId ?? undefined}
                        defaultValue={field?.roleId ?? undefined}
                        render={(field) => (
                          <RadioGroup {...field} className={styles.radioGroup}>
                            {isInitialLoaded ? (
                              rolesToInvite.map(({ roleId, roleName }) => (
                                <Radio key={roleId} value={roleId}>
                                  <div>{roleName}</div>
                                </Radio>
                              ))
                            ) : (
                              <Spin />
                            )}
                            ))
                          </RadioGroup>
                        )}
                      />
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </PTModal>
  );
}
