import React from 'react';

import { HOME_PAGE_STEPS } from 'constants/OnboardingConstants';
import { HomePageSteps, OnboardingPagesEnum } from 'enums/OnboardingEnums';
import OnboardingStep from 'features/onboarding/OnboardingStep';
import { Enum, EnumHelpers } from 'utils/enumUtils';

import 'features/onboarding/Ondoarding.less';

export const getStepsEnumByPage = (page?: OnboardingPagesEnum) => {
  switch (page) {
    default:
      return HomePageSteps;
  }
};

export const getStepsByPage = (page?: OnboardingPagesEnum) => {
  switch (page) {
    default:
      return HOME_PAGE_STEPS;
  }
};

export const getLastStep = <T extends Enum<T>>(stepsEnum: T) => {
  const steps = EnumHelpers.getEnumValues(stepsEnum);

  return steps[steps.length - 1];
};

export const getHomePageOnboardingSteps = () => {
  return {
    [HomePageSteps.Projects]: React.createElement(OnboardingStep, {
      page: OnboardingPagesEnum.Home,
      title: 'Add a project',
      description:
        'Add a project that will contain all your trackers that are related to that project.',
      className: 'project',
    }),
    [HomePageSteps.Templates]: React.createElement(OnboardingStep, {
      page: OnboardingPagesEnum.Home,
      title: 'Templates',
      description:
        'Create reusable templates with preset trackers for each of your projects.',
      className: 'templates',
    }),
    [HomePageSteps.Analytics]: React.createElement(OnboardingStep, {
      page: OnboardingPagesEnum.Analytics,
      title: 'Analytics',
      description:
        'View analytics to help you make actionable, data-driven decisions.',
      className: 'analytics',
    }),
    [HomePageSteps.Account]: React.createElement(OnboardingStep, {
      page: OnboardingPagesEnum.Home,
      title: 'Account settings',
      description: 'View and modify your account and billing settings.',
      className: 'settings',
    }),
    [HomePageSteps.Filters]: React.createElement(OnboardingStep, {
      page: OnboardingPagesEnum.Home,
      title: 'Filter',
      description:
        'Filter by percentage completion to only display projects that meet your selected criteria.',
      className: 'filter',
    }),
  };
};
