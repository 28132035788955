import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { App, Form, Input } from 'antd';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'redux/store';
import Controller from 'components/form/Controller';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import PTModal from 'components/PTModal';
import { getUserInfo, logout, setupSpaceName } from 'redux/reducers/authenticationSlice';
import { isSignAgreementSelector, userSelector } from 'redux/selectors/authenticationSelectors';
import AlertMessage from 'components/AlertMessage';
import { getAvailableSpace } from 'redux/reducers/spaceSlice';
import { getSpaceRole } from 'redux/reducers/roleSlice';
import { isInviteForThisAccount } from 'utils/inviteUtils';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

// import styles from './ProfileSetupModal.module.less';

interface FormValues {
  spaceName: string;
}

const validationSchema = Yup.object().shape({
  spaceName: Yup.string()
    .trim()
    .required('Field is required')
    .max(30, 'Space name should be 30 characters maximum'),
});

export default function ProfileSetupModal() {
  const { errors, control, formState, handleSubmit, trigger, reset } =
    useForm<FormValues>({
      resolver: yupResolver(validationSchema),
      mode: 'all',
      defaultValues: {
        spaceName: '',
      },
    });
  const { isSubmitting, isDirty } = formState;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const { modal } = App.useApp();
  const user = useSelector(userSelector);
  const isSignAgreement = useSelector(isSignAgreementSelector);
  const isLightTheme = useSelector(isLightThemeSelector);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (!user) return;

    const isModalVisible =
      isSignAgreement &&
      !user.spaceName &&
      !isInviteForThisAccount(user?.email);

    setIsModalVisible(isModalVisible);
  }, [user, isSignAgreement]);

  const onSubmit = async ({ spaceName }: FormValues) => {
    setIsLoading(true);

    const result = await dispatch(
      setupSpaceName({
        name: spaceName,
      }),
    );

    if (!setupSpaceName.fulfilled.match(result)) {
      setIsLoading(false);
      AlertMessage.error(result.error.message || ApiErrorMessage);
      return;
    }

    await dispatch(getAvailableSpace());
    await dispatch(getUserInfo());
    dispatch(getSpaceRole());
    setIsLoading(false);

    onClose();
  };

  const handleOk = () => {
    trigger();
    handleSubmit(onSubmit)();
  };

  const onClose = () => {
    reset();
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    if (isSubmitting) return;

    const confirmMessage = isDirty
      ? `Are you sure you want to log out? Entered data won't be saved.`
      : 'Are you sure you want to log out? You can always log back.';

    return modal.confirm({
      title: confirmMessage,
      icon: <span className="icon icon-info-circle confirm-icon" />,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      width: '496px',
      okText: 'Log out',
      onOk() {
        dispatch(logout());
        onClose();
      },
      onCancel() {},
    });
  };

  return (
    <PTModal
      open={isModalVisible}
      cancelText="Cancel"
      okText="Get started"
      onOk={handleOk}
      onCancel={handleModalCancel}
      okButtonProps={{ loading: isLoading }}
      title="Let’s get to know you"
      closable
      className="modal-root"
    >
      <Form layout="vertical">
        <div className="modal-scroll-wrapper">
          <p>
            {user?.firstName}, welcome to Apdated! Please enter your Company
            name.
          </p>
          <Controller
            name="spaceName"
            control={control}
            error={errors.spaceName}
            label="Company"
            placeholder="Enter Company name"
            as={Input}
            size="large"
          />
        </div>
      </Form>
    </PTModal>
  );
}
