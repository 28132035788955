import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiClient, {
  EditOwnershipRequestModel,
  SortOrderEnum,
  SpaceResponseModel,
  SpaceSortFieldEnum,
} from 'api';

import { logout } from './authenticationSlice';

interface SpacesState {
  availableSpaces: SpaceResponseModel[];
}

const initialState: SpacesState = {
  availableSpaces: [],
};

interface GetAdminSpacesRequestModel {
  sortField: SpaceSortFieldEnum;
  sortOrder: SortOrderEnum;
  name?: string;
  pageNumber: number;
  pageSize: number;
}

const handleAvailableSpaces = (
  state: SpacesState,
  spaces: SpaceResponseModel[],
) => {
  state.availableSpaces = spaces;
};

export const transferSpaceOwnership = createAsyncThunk(
  'space/transferSpaceOwnership',
  async (body: EditOwnershipRequestModel) => {
    return apiClient.makeAsUserOwner(body);
  },
);

export const getAvailableSpace = createAsyncThunk(
  'space/getAvailableSpace',
  async () => {
    return apiClient.getSpacesByUser();
  },
);

export const getAdminSpaces = createAsyncThunk(
  'space/getAdminSpaces',
  async ({
    sortField,
    sortOrder,
    name,
    pageNumber,
    pageSize,
  }: GetAdminSpacesRequestModel) => {
    return apiClient.getSpaceList(
      sortField,
      sortOrder,
      name,
      pageSize,
      pageNumber,
    );
  },
);

const spaceSlices = createSlice({
  name: 'space',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      Object.assign(state, initialState);
    });
    builder.addCase(getAvailableSpace.fulfilled, (state, action) => {
      handleAvailableSpaces(state, action.payload);
    });
  },
});

const { reducer } = spaceSlices;

export default reducer;
