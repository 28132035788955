(function () {
  const chatBotWrapperElement = document.getElementById('chat-bot-view');
  const chatBotScript = document.createElement('script');

  chatBotScript.type = 'text/javascript';
  chatBotScript.id = 'hs-script-loader';
  chatBotScript.async = true;
  chatBotScript.defer = true;
  chatBotScript.src = '//js-na1.hs-scripts.com/22752445.js';

  chatBotWrapperElement.append(chatBotScript);
})();
