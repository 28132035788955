import cx from 'classnames';
import { useSelector } from 'react-redux';

import {
  AttachmentResponseModel,
  PTStageResponseModel,
  StageStatusEnum,
} from 'api';
import AlertMessage from 'components/AlertMessage';
import StageActions from './components/StageActions';
import { getDateForUserTimezone } from 'utils/dateUtils';
import { downloadFile } from 'utils/fileUtils';
import { SetStageStatusModel } from 'redux/reducers/progressTrackerSlice';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

import styles from 'components/ProgressTracker/Stage/StageEdit.module.less';

type Props = {
  stage: PTStageResponseModel;
  onStageAction: (data: SetStageStatusModel) => Promise<void>;
  stageNumber: number;
  className?: string;
  isActions?: boolean;
  endedStage?: PTStageResponseModel;
};

const getCorrectStageClassName = (
  stageSequence: number,
  status?: StageStatusEnum,
  endedStageSequence?: number,
) => {
  if (!!endedStageSequence && stageSequence >= endedStageSequence) {
    return 'ended';
  }

  switch (status) {
    case StageStatusEnum.Done:
      return 'filled';
    case StageStatusEnum.Pending:
    default:
      return 'empty';
  }
};

export default function StageView(props: Props) {
  const isLightTheme = useSelector(isLightThemeSelector);
  const {
    stage,
    onStageAction,
    stageNumber,
    className,
    endedStage,
    isActions = true,
  } = props;

  const endedStageSequence = endedStage?.sequence;

  const isAnyStageEnded = !!endedStage;

  const handleDownloadAttachment = async (
    fileOptions: AttachmentResponseModel,
  ) => {
    if (!fileOptions) return;
    try {
      await downloadFile({
        downloadUrl: fileOptions.getFileUrl || '',
        fileName: fileOptions.fileName,
      });
    } catch (e: any) {
      AlertMessage.error(e.message);
    }
  };

  if (!stage) return null;

  return (
    <div className="cards-wrapper cards-wrapper--center">
      <div className="numeration-wrapper numeration-wrapper--center">
        <div
          className={cx(
            'numeration numeration--center',
            {
              inverse: !isLightTheme,
            },
            className,
            getCorrectStageClassName(
              stage.sequence,
              stage.status,
              endedStageSequence,
            ),
          )}
        >
          {stageNumber}
        </div>
      </div>
      <div
        className={cx('cards', 'cards--center', {
          'cards-ended': stage.status === StageStatusEnum.End,
        })}
      >
        {stage.status === StageStatusEnum.End && (
          <div className="ended-label">Ended</div>
        )}
        <div className={styles.box}>
          <div>
            <div className="cards__name">{stage.name}</div>
            {stage.dueDate && (
              <div className="cards__item">
                Due date:
                <span>{getDateForUserTimezone(stage.dueDate)}</span>
              </div>
            )}
            <div className="cards__description">{stage.description}</div>
          </div>
          <div>
            <div className="cards__box">
              <div>
                {stage.attachments?.map((attachment) => (
                  <div
                    className="cards__file"
                    onClick={() => handleDownloadAttachment(attachment)}
                    key={attachment.id}
                  >
                    <i className="icon icon-clip" />
                    <span>{attachment.fileName}</span>
                  </div>
                ))}
              </div>

              {isActions && (
                <StageActions
                  id={stage.id || ''}
                  status={stage.status}
                  onStageAction={onStageAction}
                  isAnyStageEnded={isAnyStageEnded}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
