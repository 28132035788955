import React from 'react';
import { uniqueId } from 'lodash';

import {
  AdminProgressTrackerHistoryResponseModel,
  ProgressTrackerChangeTypeEnum,
} from 'api';
import { trackerHistoryDateFormat } from 'constants/TrackerConstants';
import TrackerChangesCard from '../TrackerChanges';
import { getDateForUserTimezone } from 'utils/dateUtils';

import styles from './HistoryCard.module.less';

type Props = {
  historyDetails: AdminProgressTrackerHistoryResponseModel;
};

export default React.memo(function (props: Props) {
  const { historyDetails } = props;
  const { type, updatedByName, createdDate, id } = historyDetails;
  const historyId = id || uniqueId();

  const getHistoryLog = () => {
    switch (type) {
      case ProgressTrackerChangeTypeEnum.Archived:
        return (
          <div key={historyId} className={styles.card}>
            <span> {updatedByName}</span> archived tracker at{' '}
            {getDateForUserTimezone(createdDate, trackerHistoryDateFormat)}
          </div>
        );
      case ProgressTrackerChangeTypeEnum.Unarchived:
        return (
          <div className={styles.card} key={historyId}>
            <div>
              <span>{updatedByName}</span> unarchived tracker at{' '}
              {getDateForUserTimezone(createdDate, trackerHistoryDateFormat)}
            </div>
          </div>
        );
      case ProgressTrackerChangeTypeEnum.MarkedForDelete:
        return (
          <div className={styles.card} key={historyId}>
            <div>
              <span>{updatedByName}</span> deleted tracker at{' '}
              {getDateForUserTimezone(createdDate, trackerHistoryDateFormat)}
            </div>
          </div>
        );
      case ProgressTrackerChangeTypeEnum.Restored:
        return (
          <div className={styles.card} key={historyId}>
            <div>
              <span>{updatedByName}</span> restored tracker at{' '}
              {getDateForUserTimezone(createdDate, trackerHistoryDateFormat)}
            </div>
          </div>
        );
      case ProgressTrackerChangeTypeEnum.TrackerChanged:
        return <TrackerChangesCard historyDetails={historyDetails} />;
    }
  };

  return <>{getHistoryLog()}</>;
});
