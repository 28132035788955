import React, { useState } from 'react';
import { App, Button } from 'antd';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import { PaymentMethodResponseModel } from 'api';
import { userSelector } from 'redux/selectors/authenticationSelectors';
import { CardBrandsEnum } from 'enums/CardBrandsEnum';

import visa from 'assets/img/visa.png';
import master from 'assets/img/master-card.png';
import american from 'assets/img/american-express.png';
import discover from 'assets/img/discover.png';
import defaultCard from 'assets/img/default-card.png';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

export enum CardMode {
  View = 1,
  Edit = 2,
  CardList = 3,
}

type Props = {
  card: PaymentMethodResponseModel;
  mode: CardMode;
  onDeleteCreditCard?: (id: string) => Promise<void>;
  onChangeCreditCard?: () => void;
};

export default function CreditCard(props: Props) {
  const { card, onDeleteCreditCard, onChangeCreditCard, mode } = props;
  const { last4, id, expireYear, expireMonth, brand } = card;
  const shortExpireYear = expireYear?.toString().slice(-2);
  const [isDeleting, setIsDeleting] = useState(false);
  const isLightTheme = useSelector(isLightThemeSelector);
  const { modal } = App.useApp();
  const user = useSelector(userSelector);

  const deleteCard = async () => {
    setIsDeleting(true);
    await onDeleteCreditCard?.(id || '');
    setIsDeleting(false);
  };

  const handleDeleteCreditCard = async () => {
    return modal.confirm({
      title: 'Are you sure you want to delete this credit card?',
      icon: <span className="icon icon-info-circle confirm-icon" />,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      width: '496px',
      okText: 'Yes',
      onOk: deleteCard,
    });
  };

  const getPaymentSystemIcon = () => {
    switch (brand) {
      case CardBrandsEnum.VISA:
        return <img src={visa} alt="visa" />;
      case CardBrandsEnum.MASTER_CARD:
        return <img src={master} alt="master card" />;
      case CardBrandsEnum.AMEX:
        return <img src={american} alt="american express" />;
      case CardBrandsEnum.DISCOVER:
        return <img src={discover} alt="discover" />;

      default:
        return <img src={defaultCard} alt="default" />;
    }
  };

  return (
    <div className="card">
      <div className="card__head">
        <div className="card__img">{getPaymentSystemIcon()}</div>
      </div>

      <div className="card__number">**** **** **** {last4}</div>
      <div>
        Expires {expireMonth}-{shortExpireYear}
      </div>
      {user?.defaultPaymentMethodId === id && (
        <div className="card__default">Default</div>
      )}

      <div className="card__foot">
        {mode === CardMode.CardList && (
          <Button
            onClick={handleDeleteCreditCard}
            loading={isDeleting}
            type="text"
          >
            Delete
          </Button>
        )}

        {mode === CardMode.Edit && (
          <Button onClick={onChangeCreditCard} type="text">
            Change
          </Button>
        )}
      </div>
    </div>
  );
}
