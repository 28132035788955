import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { closeRestrictionModal } from 'redux/reducers/accountSlice';
import { userSelector } from 'redux/selectors/authenticationSelectors';
import { restrictionModalStateSelector } from 'redux/selectors/aссountSelectors';
import { AppDispatch } from 'redux/store';
import PTModal from 'components/PTModal';
import useModalCloseOnNavigate from 'hooks/useModalCloseOnNavigate';
import { isTrackerManagerSelector } from 'redux/selectors/roleSelectors';
import { SubscriptionTypeEnum } from 'api';

// import styles from './RestrictionModal.module.less';

export enum RestrictionModalTypeEnum {
  CreateTracker,
  CreateTrackerFromTemplate,
  CreateProject,
  CreateTemplate,
}

const isCancelRedirectNeeded = (type: RestrictionModalTypeEnum | null) => {
  return type === RestrictionModalTypeEnum.CreateTracker;
};

export default function RestrictionModal() {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { visible, type, message, onClose } = useSelector(restrictionModalStateSelector);
  const user = useSelector(userSelector);
  const isTrackerManager = useSelector(isTrackerManagerSelector);
  const cancelRedirectNeeded = isCancelRedirectNeeded(type);

  const onModalCancel = () => {
    dispatch(closeRestrictionModal());

    if (cancelRedirectNeeded && isTrackerManager) {
      navigate(-1);
    }
  };

  useModalCloseOnNavigate(onModalCancel);

  const handleCancelClick = () => {
    onModalCancel();

    onClose?.();

    if (cancelRedirectNeeded) {
      navigate(-1);
    }
  };

  const handleUpgradeClick = () => {
    navigate('/billing-settings?section=pricing');
  };

  const getMessage = () => {
    if (message) return message;

    if (!user) return '';

    const { subscriptionType } = user;
    switch (type) {
      case RestrictionModalTypeEnum.CreateTracker:
        switch (subscriptionType) {
          case SubscriptionTypeEnum.Basic:
            return isTrackerManager
              ? 'You have reached the limit of created trackers for this space. Please contact space admin.'
              : 'You have reached the limit of created trackers. Please upgrade your account.';

          default:
            return isTrackerManager
              ? 'You have reached the limit of created trackers for this space. Please contact space admin or wait till the next month.'
              : 'You have reached the limit of created trackers. Please upgrade your account or wait for the next month.';
        }

      case RestrictionModalTypeEnum.CreateProject:
        switch (subscriptionType) {
          case SubscriptionTypeEnum.Basic:
            return isTrackerManager
              ? 'You have reached the limit of created projects for this space. Please contact space admin.'
              : 'You have reached the limit of created projects. Please upgrade your account.';

          default:
            return isTrackerManager
              ? 'You have reached the limit of created projects. Please contact space admin or wait till the next month.'
              : 'You have reached the limit of created projects. Please upgrade your account or wait for the next month.';
        }

      case RestrictionModalTypeEnum.CreateTemplate:
        return isTrackerManager
          ? 'Your pricing plan does not support reusable templates. Please contact space admin.'
          : 'Your pricing plan does not support reusable templates. Please upgrade your account.';

      default:
        return null;
    }
  };

  return (
    <PTModal
      open={visible}
      className="modal-root"
      title="Pricing plan limit reached"
      okText={isTrackerManager ? 'OK' : 'Upgrade account'}
      onOk={isTrackerManager ? onModalCancel : handleUpgradeClick}
      cancelButtonProps={{
        ...(isTrackerManager && { style: { display: 'none' } }),
      }}
      onCancel={handleCancelClick}
      zIndex={999}
    >
      <div className="modal-scroll-wrapper">
        <p>{getMessage()}</p>
      </div>
    </PTModal>
  );
}
