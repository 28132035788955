import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Button, Input, Menu, Popover, Select, Spin, Tooltip } from 'antd';
import cx from 'classnames';
import mimeTypes from 'mime-types';
import { capitalize, isString, startCase, uniqueId } from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import { useDidUpdate } from 'rooks';

import RangePicker, { DateRangeType } from 'components/RangePicker';
import {
  AnalyticsMessageHistoryResponseModel,
  MessageHistorySortFieldEnum,
  MessageStatusEnum,
  MessageTypeEnum as CategoryTypeEnum,
  SortOrderEnum,
} from 'api';
import AlertMessage from 'components/AlertMessage';
import SortingFilter, {
  SortingFilterValueType,
  SortingMode,
} from 'components/filters/SortingFilter';
import NoInitialData from 'components/NoInitialData';
import { MIN_SEARCH_LENGTH } from 'components/search/SearchLayout';
import SettingsDetailsSection from 'components/settings/SettingsDetailsSection';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { ALL_VALUES_KEY } from 'constants/FilterConstants';
import ProjectsDropDown from 'features/analytics/ProjectsDropDown';
import useSearchQueryMatch from 'hooks/useSearchQueryMatch';
import { EnumHelpers } from 'utils/enumUtils';
import { DispatchedRequestType } from 'utils/searchUtils';
import { AppDispatch } from 'redux/store';
import {
  exportMessageLogs,
  ExportMessageLogsRequestModel,
  getEmailSmsAnalytics,
  GetEmailSmsAnalyticsRequest,
} from 'redux/reducers/dashboardSlice';
import { spaceNameSelector } from 'redux/selectors/authenticationSelectors';
import { BILLING_DATE_FORMAT, getDateForUserTimezone } from 'utils/dateUtils';
import {
  DEFAULT_DOWNLOAD_FILE_ERROR_MESSAGE,
  downloadFileByObjectURL,
} from 'utils/fileUtils';
import Breadcrumb from 'components/Breadcrumb';

import styles from 'features/auth/BillingSettingsPage/InvoiceHistoryTab/InvoiceHistoryTab.module.less';
import classes from './AnalyticsSection.module.less';

import noSmsLogs from 'assets/img/smsLogs.png';
import noEmailLogs from 'assets/img/emailLogs.svg';

const { Option } = Select;

export enum AnalyticFiltersTypes {
  Search = 'search',
  Category = 'category',
  MessageType = 'messageType',
  DeliveryStatus = 'deliveryStatus',
  Project = 'project',
  Sorting = 'sorting',
  DateRange = 'dateRange',
}

export enum MessageTypesEnum {
  Complete = 3,
  Reset = 4,
}

enum MessageTypeKeys {
  ApproveTrackerStage = 'ApproveTrackerStage',
  UndoTrackerStage = 'UndoTrackerStage',
}

export type MessageType = MessageTypesEnum | typeof ALL_VALUES_KEY;

type DeliveryStatusType = MessageStatusEnum | typeof ALL_VALUES_KEY;

type FilterValuesType =
  | number
  | string
  | SortingFilterValueType
  | DateRangeType;

type SortingType = {
  sortField: MessageHistorySortFieldEnum;
  sortOrder: SortOrderEnum;
};

interface AnalyticFilters {
  [AnalyticFiltersTypes.Search]: string;
  [AnalyticFiltersTypes.Category]: CategoryTypeEnum;
  [AnalyticFiltersTypes.MessageType]: MessageType;
  [AnalyticFiltersTypes.DeliveryStatus]: DeliveryStatusType;
  [AnalyticFiltersTypes.Sorting]: SortingType;
  [AnalyticFiltersTypes.Project]: string;
  [AnalyticFiltersTypes.DateRange]?: DateRangeType;
}

export default function AnalyticsSection() {
  const isLightTheme = useSelector(isLightThemeSelector);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [messages, setMessages] = useState<
    AnalyticsMessageHistoryResponseModel[]
  >([]);
  const [hasMore, setHasMore] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);
  const [isFilterUsed, setIsFilterUsed] = useState(false);
  const [isRangePickerOpen, setIsRangePickerOpen] = useState(false);
  const latestRequestRef = useRef<DispatchedRequestType>();
  const infiniteScrollParentRef = useRef(null);
  const spaceName = useSelector(spaceNameSelector);

  const { category, search, tab } = queryString.parse(location.search);
  const categories = EnumHelpers.getEnumValues(CategoryTypeEnum).map(
    (category) => category.toString(),
  );
  const isCategoryMatched = useSearchQueryMatch(categories, 'category');
  const emailTypes = EnumHelpers.getEnumValues(MessageTypesEnum);

  const initialFilters: AnalyticFilters = {
    [AnalyticFiltersTypes.Search]: isString(search) ? search : '',
    [AnalyticFiltersTypes.Category]:
      isString(category) && +category in CategoryTypeEnum
        ? +category
        : CategoryTypeEnum.Email,
    [AnalyticFiltersTypes.MessageType]: ALL_VALUES_KEY,
    [AnalyticFiltersTypes.DeliveryStatus]: ALL_VALUES_KEY,
    [AnalyticFiltersTypes.Project]: ALL_VALUES_KEY,
    [AnalyticFiltersTypes.Sorting]: {
      sortField: MessageHistorySortFieldEnum.Date,
      sortOrder: SortOrderEnum.Desc,
    },
  };
  const [filters, setFilters] = useState<AnalyticFilters>(initialFilters);
  const isSmsCategory = useMemo(
    () => +filters[AnalyticFiltersTypes.Category] === CategoryTypeEnum.Sms,
    [filters[AnalyticFiltersTypes.Category]],
  );

  const messageType = useMemo(
    () => (isSmsCategory ? 'SMS' : 'email'),
    [isSmsCategory],
  );

  const getCorrectImage = () => {
    return +isSmsCategory ? noSmsLogs : noEmailLogs;
  };

  const getCorrectPlaceholder = () => {
    return isSmsCategory
      ? 'Search by recipient phone number'
      : 'Search by recipient email address';
  };

  const isSearchMode =
    filters[AnalyticFiltersTypes.Search].length >= MIN_SEARCH_LENGTH;

  const emptySearchResults =
    (isSearchMode || isFilterUsed) && isInitialDataLoaded && !totalCount;

  const notFoundData =
    isInitialDataLoaded && !totalCount && !isFilterUsed && !emptySearchResults;

  useDidUpdate(() => {
    if (!isCategoryMatched) {
      const search = queryString.stringify({
        tab,
        category: CategoryTypeEnum.Email,
      });
      navigate({ search }, { replace: true });
    }
  }, [isCategoryMatched]);

  useEffect(() => {
    initInfinityScroll();
  }, [filters]);

  useDidUpdate(() => {
    const search = queryString.stringify({
      [AnalyticFiltersTypes.Search]: filters[AnalyticFiltersTypes.Search],
      [AnalyticFiltersTypes.Category]: filters[AnalyticFiltersTypes.Category],
      tab,
    });
    navigate({ search }, { replace: true });
  }, [
    filters[AnalyticFiltersTypes.Search],
    filters[AnalyticFiltersTypes.Category],
  ]);

  const isAllValues = useCallback(
    (key: AnalyticFiltersTypes) => filters[key] === ALL_VALUES_KEY,
    [filters],
  );

  const handleFilter = (
    filterName: AnalyticFiltersTypes,
    value?: FilterValuesType,
  ) => {
    setFilters({ ...filters, [filterName]: value });

    if (
      filterName !== AnalyticFiltersTypes.Category &&
      filterName !== AnalyticFiltersTypes.Search
    ) {
      setIsFilterUsed(true);
    }
  };

  const getDeliveryStatusClass = (deliveryStatus?: MessageStatusEnum) => {
    if (!deliveryStatus) return;

    switch (deliveryStatus) {
      case MessageStatusEnum.Sent:
        return classes.sent;
      case MessageStatusEnum.Opened:
        return classes.pending;
      case MessageStatusEnum.Delivered:
        return classes.active;
      case MessageStatusEnum.Rejected:
        return classes.rejected;
    }
  };

  const renderEmailType = (emailTypeKey: MessageTypeKeys) => {
    switch (emailTypeKey) {
      case MessageTypeKeys.UndoTrackerStage:
        return <div className={cx(styles.label, styles.failed)}>Reset</div>;
      case MessageTypeKeys.ApproveTrackerStage:
        return <div className={cx(styles.label, styles.success)}>Complete</div>;
    }
  };

  const handleCloseRangePicker = () => setIsRangePickerOpen(false);

  const getRequestBody = (
    pageNumber?: number,
  ): ExportMessageLogsRequestModel | GetEmailSmsAnalyticsRequest => {
    const messageStatus =
      filters[AnalyticFiltersTypes.DeliveryStatus] === ALL_VALUES_KEY
        ? undefined
        : (filters[AnalyticFiltersTypes.DeliveryStatus] as MessageStatusEnum);

    const projectId =
      filters[AnalyticFiltersTypes.Project] === ALL_VALUES_KEY
        ? undefined
        : filters[AnalyticFiltersTypes.Project];

    const messageSubject =
      filters[AnalyticFiltersTypes.MessageType] === ALL_VALUES_KEY
        ? [MessageTypesEnum.Complete, MessageTypesEnum.Reset]
        : [filters[AnalyticFiltersTypes.MessageType] as MessageTypesEnum];

    const { [AnalyticFiltersTypes.Sorting]: sorting } = filters;
    const { sortField, sortOrder } = sorting;

    let requestBody = {
      messageType: filters[AnalyticFiltersTypes.Category],
      search: filters[AnalyticFiltersTypes.Search],
      from: filters[AnalyticFiltersTypes.DateRange]?.from,
      to: filters[AnalyticFiltersTypes.DateRange]?.to,
      messageStatus,
      projectId,
      messageSubject,
      sortField,
      sortOrder,
    };

    if (pageNumber) {
      return { ...requestBody, pageNumber };
    }

    return requestBody;
  };

  const downloadLogs = async () => {
    const requestBody = getRequestBody();

    const result = await dispatch(exportMessageLogs(requestBody));

    if (exportMessageLogs.fulfilled.match(result)) {
      try {
        const { data, fileName } = result.payload;

        const url = URL.createObjectURL(data);

        const fileType = mimeTypes.extension(data.type);
        const newFileName = `Apdated ${messageType} Notification Logs - ${spaceName}.${fileType}`;

        await downloadFileByObjectURL(url, fileName || newFileName);
      } catch {
        AlertMessage.error(DEFAULT_DOWNLOAD_FILE_ERROR_MESSAGE);
      }
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const initInfinityScroll = () => {
    setIsVisible(false);
    setMessages([]);
    setIsInitialDataLoaded(false);
    setHasMore(true);
    setTotalCount(0);

    const timer = setTimeout(() => {
      // to destroy infinite scroll
      setIsVisible(true);
    }, 0);

    return () => clearTimeout(timer);
  };

  const onSetData = useCallback(
    async (result) => {
      const loadedData = result.payload.details || [];
      const totalCount = result.payload.totalCount || 0;
      const newData = [...messages, ...loadedData];
      const hasMoreRows =
        !!newData.length &&
        newData.length < totalCount &&
        loadedData.length > 0;

      setHasMore(hasMoreRows);
      setMessages(newData);
      setTotalCount(totalCount);
    },
    [messages],
  );

  const onLoadMore = useCallback(
    async (pageNumber: number) => {
      latestRequestRef.current?.abort?.();

      const requestBody = getRequestBody(
        pageNumber,
      ) as GetEmailSmsAnalyticsRequest;

      const request = dispatch(getEmailSmsAnalytics(requestBody));

      latestRequestRef.current = request;
      const result = await request;
      if (getEmailSmsAnalytics.fulfilled.match(result)) {
        await onSetData(result);
      } else {
        if (result.meta.aborted) return;

        setHasMore(false);
        AlertMessage.error(result.error.message || ApiErrorMessage);
      }

      setIsInitialDataLoaded(true);
    },
    [dispatch, getEmailSmsAnalytics, onSetData],
  );

  return (
    <>
      <div>
        <Breadcrumb
          routes={[
            {
              title: <i className="icon icon-home" />,
              link: '/',
            },
            {
              title: 'Notifications',
            },
          ]}
        />
      </div>
      <SettingsDetailsSection
        title="Drive growth through analytics"
        description="View analytics to help you make actionable, data-driven decisions."
      />
      <div className={classes.wrapper}>
        <div className={cx(classes.box, classes.border)}>
          <div
            className={cx(classes.search, {
              [classes.dark]: !isLightTheme,
            })}
          >
            <Input
              onChange={(e) =>
                handleFilter(AnalyticFiltersTypes.Search, e.target.value)
              }
              value={filters[AnalyticFiltersTypes.Search]}
              placeholder={getCorrectPlaceholder()}
              prefix={<i className="icon icon-search" />}
            />
          </div>

          <div
            className={cx(classes.menu, {
              [classes.menuInverse]: !isLightTheme,
            })}
          >
            <Menu
              mode="horizontal"
              selectedKeys={[filters[AnalyticFiltersTypes.Category].toString()]}
              onClick={(e) =>
                handleFilter(AnalyticFiltersTypes.Category, e.key)
              }
              items={[
                { key: CategoryTypeEnum.Email, label: 'Email' },
                { key: CategoryTypeEnum.Sms, label: 'SMS' },
              ]}
            />
          </div>
        </div>
        <div className={classes.box}>
          <div className={classes.title}>Notification history</div>
          <div className={classes.actions}>
            <div className={classes.filter}>
              <SortingFilter
                onChange={(value) =>
                  handleFilter(AnalyticFiltersTypes.Sorting, value)
                }
                value={filters[AnalyticFiltersTypes.Sorting]}
                mode={SortingMode.MessagesLog}
              />
            </div>
            <div>
              <Tooltip title="Export to Excel" placement="top" trigger="hover">
                <Button onClick={downloadLogs} type="text">
                  <i className="icon icon-load" />
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>

        <div className={classes.tableBox} ref={infiniteScrollParentRef}>
          <table
            className={cx(classes.table, {
              [classes.inverse]: !isLightTheme,
            })}
          >
            <thead>
              <tr>
                <th
                  className={classes.tableHead}
                  style={{
                    fontWeight: isAllValues(AnalyticFiltersTypes.Project)
                      ? 300
                      : 600,
                  }}
                >
                  <ProjectsDropDown
                    value={filters[AnalyticFiltersTypes.Project].toString()}
                    handleChange={handleFilter}
                  />
                </th>
                <th className={classes.tableHead}>Tracker Name</th>
                <th className={classes.tableHead}>
                  <div
                    className={cx(classes.headerDrop, {
                      [classes.headerDropDark]: !isLightTheme,
                    })}
                  >
                    <Select
                      showArrow
                      value={filters[AnalyticFiltersTypes.MessageType]}
                      onChange={(e) => {
                        handleFilter(AnalyticFiltersTypes.MessageType, e);
                      }}
                      style={{
                        fontWeight: isAllValues(
                          AnalyticFiltersTypes.MessageType,
                        )
                          ? 300
                          : 600,
                      }}
                    >
                      <Option key={ALL_VALUES_KEY}>
                        <div>{capitalize(messageType)} Type</div>
                      </Option>
                      {emailTypes.map((type) => (
                        <Option key={type}>
                          {startCase(MessageTypesEnum[type as number])}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </th>
                <th
                  className={classes.tableHead}
                  style={{
                    fontWeight:
                      filters[AnalyticFiltersTypes.DateRange]?.from ||
                      filters[AnalyticFiltersTypes.DateRange]?.to
                        ? 600
                        : 300,
                  }}
                >
                  <Popover
                    destroyTooltipOnHide
                    showArrow={false}
                    onOpenChange={(isOpen) => setIsRangePickerOpen(isOpen)}
                    open={isRangePickerOpen}
                    placement="bottom"
                    trigger="click"
                    content={
                      <RangePicker
                        onApplyDateRange={(dateRange: DateRangeType) =>
                          handleFilter(
                            AnalyticFiltersTypes.DateRange,
                            dateRange,
                          )
                        }
                        defaultFromDate={
                          filters[AnalyticFiltersTypes.DateRange]?.from
                        }
                        defaultToDate={
                          filters[AnalyticFiltersTypes.DateRange]?.to
                        }
                        onCloseRangePicker={handleCloseRangePicker}
                      />
                    }
                    overlayInnerStyle={{ width: 569 }}
                  >
                    <div className={classes.dateItem}>
                      Date <i className="icon icon-caret-down-solid"></i>
                    </div>
                  </Popover>
                </th>
                <th className={classes.tableHead}>Subscriber {messageType}</th>
                <th
                  className={classes.tableHead}
                  style={{
                    fontWeight: isAllValues(AnalyticFiltersTypes.DeliveryStatus)
                      ? 300
                      : 600,
                  }}
                >
                  <div
                    className={cx(classes.headerDrop, {
                      [classes.headerDropDark]: !isLightTheme,
                    })}
                  >
                    <Select
                      value={filters[
                        AnalyticFiltersTypes.DeliveryStatus
                      ]?.toString()}
                      onChange={(e) =>
                        handleFilter(AnalyticFiltersTypes.DeliveryStatus, e)
                      }
                    >
                      <Option key={ALL_VALUES_KEY}>Delivery Status</Option>
                      {EnumHelpers.getEnumValues(MessageStatusEnum).map(
                        (messageStatus) => (
                          <Option key={messageStatus}>
                            {startCase(
                              MessageStatusEnum[messageStatus as number],
                            )}
                          </Option>
                        ),
                      )}
                    </Select>
                  </div>
                </th>
              </tr>
            </thead>

            {emptySearchResults && (
              <tr>
                <td colSpan={6}>
                  <NoInitialData text="There is no information for your request." />
                </td>
              </tr>
            )}

            {notFoundData && (
              <tr>
                <td colSpan={6} className={classes.info}>
                  <div className={classes.image}>
                    <img src={getCorrectImage()} alt="Message" />
                  </div>

                  <NoInitialData
                    text={`You currently don't have any ${messageType} logs.`}
                  />
                </td>
              </tr>
            )}

            {isVisible && (
              <InfiniteScroll
                loadMore={onLoadMore}
                maxLength={totalCount}
                pageStart={-1}
                hasMore={hasMore}
                loader={<Spin key={uniqueId()} />}
                useWindow={false}
                getScrollParent={() => infiniteScrollParentRef.current}
                threshold={100}
                element="tbody"
              >
                {messages.map((message) => (
                  <tr
                    key={message.id}
                    className={cx({
                      [classes.tableRow]: !isLightTheme,
                    })}
                  >
                    <td className={classes.tableCell}>{message.projectName}</td>
                    <td className={cx(classes.tableCell, classes.analytics)}>
                      {message.trackerName}
                    </td>
                    <td className={classes.tableCell}>
                      {renderEmailType(
                        message?.messageSubject as MessageTypeKeys,
                      )}
                    </td>
                    <td className={cx(classes.tableCell, classes.date)}>
                      {getDateForUserTimezone(
                        message.createdDate,
                        BILLING_DATE_FORMAT,
                      )}
                    </td>
                    <td className={classes.tableCell}>{message.recipient}</td>
                    <td className={cx(classes.tableCell, classes.status)}>
                      <div
                        className={cx(
                          getDeliveryStatusClass(message.messageStatus),
                        )}
                      >
                        {startCase(
                          MessageStatusEnum[
                            message.messageStatus as MessageStatusEnum
                          ],
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </InfiniteScroll>
            )}
          </table>
        </div>
      </div>
    </>
  );
}
