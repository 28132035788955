import * as cheerio from 'cheerio';

/**
 * Copy text to clipboard in browser
 *
 * @param {string} text to be copied
 * @returns {boolean} success boolean
 */
export const copyToClipboard = (text: string): boolean => {
  const textarea = document.createElement('textarea');

  textarea.style.background = 'transparent';
  textarea.style.position = 'fixed';

  textarea.value = text;

  document.body.appendChild(textarea);
  textarea.focus();
  textarea.select();

  try {
    document.execCommand('copy');

    return true;
  } catch (err) {
    return false;
  } finally {
    document.body.removeChild(textarea);
  }
};

export const getNodeText = (node: string): string => {
  const $ = cheerio.load(node);

  return $('html *')
    .contents()
    .map(function () {
      return this.type === 'text' ? $(this).text() : '';
    })
    .get()
    .join('');
};
