import React, { SyntheticEvent, useState } from 'react';
import { App, Tooltip } from 'antd';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AppDispatch } from 'redux/store';
import { spaceIdSelector } from 'redux/selectors/authenticationSelectors';
import { getUserInfo } from 'redux/reducers/authenticationSlice';
import {
  getUnreadNotificationsCount,
  removeNotification,
  setNotificationStatus,
} from 'redux/reducers/notificationSlice';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { getNotificationInfo } from 'utils/notificationUtils';
import { isRedirectBlockedSelector } from 'redux/selectors/commonSelectors';
import { getDateFromNow } from 'utils/dateUtils';
import { NotificationResponseModel } from 'api';
import AlertMessage from 'components/AlertMessage';
import { getSpaceRole } from 'redux/reducers/roleSlice';

import styles from '../../HeaderNotifications.module.less';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

type Props = {
  notification: NotificationResponseModel;
  onNotificationClick: () => void;
};

export default function NotificationCard(props: Props) {
  const { notification, onNotificationClick } = props;
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { modal } = App.useApp();
  const [isLoading, setIsLoading] = useState(false);
  const { createdDate, id, isNew } = notification;
  const {
    redirectUrl,
    subject,
    body,
    spaceId: cardSpaceId,
  } = getNotificationInfo(notification);
  const isRedirectBlocked = useSelector(isRedirectBlockedSelector);
  const spaceId = useSelector(spaceIdSelector);
  const isLightTheme = useSelector(isLightThemeSelector);
  
  const showConfirmation = () => {
    modal.confirm({
      title: 'Changes won’t be saved. Are you sure?',
      icon: <span className="icon icon-info-circle confirm-icon" />,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      width: '496px',
      onOk() {
        onNotificationRedirect();
      },
      onCancel() {},
    });
  };

  const handleNotificationClick = () => {
    if (isRedirectBlocked) {
      return showConfirmation();
    }

    onNotificationRedirect();
  };

  const onNotificationRedirect = async () => {
    const url = redirectUrl;

    if (cardSpaceId && spaceId !== cardSpaceId) {
      const result = await dispatch(getUserInfo(cardSpaceId));

      if (getUserInfo.fulfilled.match(result)) {
        dispatch(getSpaceRole());
      } else {
        AlertMessage.error(result.error.message || ApiErrorMessage);
      }
    }

    isNew && markNotificationAsNew(false);

    if (!url) return;

    onNotificationClick();

    navigate(url);
  };

  const handleRemoveNotification = async (event: SyntheticEvent) => {
    event.stopPropagation();

    if (isLoading) return;

    setIsLoading(true);

    const result = await dispatch(removeNotification(id as number));

    if (removeNotification.fulfilled.match(result)) {
      await dispatch(getUnreadNotificationsCount());
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }

    setIsLoading(false);
  };

  const markNotificationAsNew = async (isNew: boolean) => {
    if (isLoading) return;

    setIsLoading(true);

    const result = await dispatch(
      setNotificationStatus({
        notificationId: id as number,
        isNew,
      }),
    );

    if (setNotificationStatus.fulfilled.match(result)) {
      await dispatch(getUnreadNotificationsCount());
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }

    setIsLoading(false);
  };

  const toggleNotificationReadStatus = (event: SyntheticEvent) => {
    event.stopPropagation();

    markNotificationAsNew(!isNew);
  };

  return (
    <div
      data-id={id}
      className={cx(styles.notifyCard, {
        'new-notification': isNew,
      })}
      onClick={handleNotificationClick}
    >
      <div className={styles.notifyCardHead}>
        <Tooltip
          overlayClassName="light-tooltip"
          title={isNew ? 'Mark as read' : 'Mark as unread'}
          trigger={['hover', 'focus']}
          placement="right"
          getPopupContainer={(triggerNode) =>
            triggerNode.parentNode as HTMLElement
          }
        >
          <div
            className={styles.notifyCardPoint}
            onClick={toggleNotificationReadStatus}
          />
        </Tooltip>
        <div className={styles.notifyCardTitle}>{subject}</div>
        <Tooltip
          overlayClassName="light-tooltip"
          title="Remove notification"
          trigger={['hover', 'focus']}
          placement="left"
          getPopupContainer={(triggerNode) =>
            triggerNode.parentNode as HTMLElement
          }
        >
          <div
            className={styles.notifyCardClose}
            onClick={handleRemoveNotification}
          >
            <span className="icon icon-close" />
          </div>
        </Tooltip>
      </div>

      <div className={styles.notifyCardBody}>
        <div className={styles.notifyCardText}>{body}</div>

        <div className={styles.notifyCardDate}>
          {getDateFromNow(createdDate)}
        </div>
      </div>
    </div>
  );
}
