export const trackerHistoryDateFormat = 'MMMM DD, YYYY [at] hh:mm A';

export const trackerCommentDateFormat = 'MMMM DD, YYYY [at] hh.mm A';

export const emptyStage = {
  name: '',
  dueDate: null,
  description: '',
  isManuallyAdded: false,
  attachments: undefined,
};
