import React, { useCallback, useState } from 'react';
import { Button, Dropdown, MenuProps, Progress } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import cx from 'classnames';

import { ProgressTrackerCardModel } from 'api';
import { getPercentage } from 'utils/percentageUtils';
import MoveProgressTrackerModal from '../ProgressTrackerDetailsPage/MoveProgressTrackerModal';
import { getDateForUserTimezone } from 'utils/dateUtils';
import { getCorrectStrokeColor } from 'utils/trackerUtils';

export type Handler = (id: string) => void;

type Props = {
  pt: ProgressTrackerCardModel;
  projectId: string;
  onDelete: Handler;
  onArchive: Handler;
  onMove: (id: string) => void;
};

export default function PTCard(props: Props) {
  const isLightTheme = useSelector(isLightThemeSelector);
  const { pt, projectId, onDelete, onArchive, onMove = () => {} } = props;
  const {
    id,
    name,
    percentageCompleteness = 0,
    stageDueDate,
    createdDate,
    uncompletedStageTotalCount,
    isAnyStageEnded,
  } = pt;
  const roundedPercentage = getPercentage(percentageCompleteness);

  const [isMovePTModalVisible, setIsMovePTModalVisible] = useState(false);

  const handleMoveClick = () => {
    setIsMovePTModalVisible(true);
  };

  const handleMoveModalCloseClick = () => {
    setIsMovePTModalVisible(false);
  };

  const handleArchiveClick = useCallback(() => {
    onArchive(id as string);
  }, [id]);

  const handleDeleteClick = useCallback(() => {
    onDelete(id as string);
  }, [id]);

  const trackerManagementMenu: MenuProps['items'] = [
    {
      key: 'edit',
      label: (
        <Link to={`/project/${projectId}/tracker-edit/${id}`}>
          <i className="icon icon-edit" />
          Edit
        </Link>
      ),
    },
    {
      key: 'move',
      label: (
        <div onClick={handleMoveClick}>
          <i className="icon icon-move" />
          Move
        </div>
      ),
    },
    {
      key: 'archive',
      label: (
        <div onClick={handleArchiveClick}>
          <i className="icon icon-archive" />
          Archive
        </div>
      ),
    },
    {
      key: 'delete',
      label: (
        <div onClick={handleDeleteClick}>
          <i className="icon icon-delete" />
          Delete
        </div>
      ),
    },
  ];

  const getTrackerManagementDropdown = () => (
    <Dropdown
      menu={{ items: trackerManagementMenu }}
      trigger={['click']}
      placement="bottom"
    >
      <Button type="ghost">
        <i className="icon icon-dots"></i>
      </Button>
    </Dropdown>
  );

  return (
    <>
      {isMovePTModalVisible && (
        <MoveProgressTrackerModal
          projectId={id as string}
          onClose={handleMoveModalCloseClick}
          onMove={onMove}
        />
      )}
      <Link
        to={`/details/${id}`}
        className={cx('ticket ticket--shadow', {
          inverse: !isLightTheme,
        })}
      >
        <div className="ticket__left">
          <div className="ticket__progress">
            <Progress
              type="circle"
              percent={roundedPercentage}
              format={(percent) => `${percent}%`}
              strokeColor={getCorrectStrokeColor(
                roundedPercentage,
                isAnyStageEnded,
              )}
              strokeWidth={10}
              size={54}
            />
          </div>
          <div>
            <div className="ticket__name">{name}</div>
            {stageDueDate && (
              <div className="ticket__item">
                <span>Due date: </span> {getDateForUserTimezone(stageDueDate)}
              </div>
            )}
          </div>
        </div>
        <div className="ticket__right">
          <div className="ticket__inner">
            <div className="ticket__item">
              <span>Created date: </span>
              {getDateForUserTimezone(createdDate)}
            </div>
            <div className="ticket__item">
              <span>Stages remaining: </span>
              {uncompletedStageTotalCount}
            </div>
          </div>

          <div onClick={(e) => e.preventDefault()}>
            {getTrackerManagementDropdown()}
          </div>
        </div>
      </Link>
    </>
  );
}
