import { createSelector } from '@reduxjs/toolkit';

import store from 'redux/store';
import { spaceIdSelector, spaceNameSelector } from './authenticationSelectors';
import { RootState } from 'redux/reducers/rootReducer';
import { isHasSpaceInAvailableSpaces } from 'utils/spaceUtils';

export const availableSpacesSelector = (state: RootState) =>
  state.space.availableSpaces;

export const currentSpaceNameIdSelector = createSelector(
  spaceNameSelector,
  spaceIdSelector,
  (spaceName, spaceId) => ({ spaceName, spaceId }),
);

export const hasAnySpaceSelector = createSelector(
  availableSpacesSelector,
  (availableSpaces) => !!availableSpaces?.length,
);

export const currentSpaceIdByStoreSelector = () => {
  const state = store.getState();
  const cachedSpaceId = localStorage?.getItem('space');
  const availableSpaces = availableSpacesSelector(state);
  const { spaceId } = currentSpaceNameIdSelector(state);

  const spaceIdActual = isHasSpaceInAvailableSpaces(
    availableSpaces,
    cachedSpaceId,
  )
    ? cachedSpaceId
    : spaceId;

  return (spaceIdActual ? spaceIdActual : availableSpaces[0]?.id) as string;
};
