import { useCallback, useEffect, useState } from 'react';
import { throttle } from 'lodash';

import { MobileScreenQuery } from 'constants/ScreenQuery';

export const useQueryMatch = (query: string = MobileScreenQuery) => {
  const initialValue = window.matchMedia(query).matches;

  const [isMatch, setIsMatch] = useState<any>(initialValue);

  useEffect(() => {
    window.addEventListener('resize', matchQuery);

    return () => {
      window.removeEventListener('resize', matchQuery);
    };
  }, []);

  const matchQuery = useCallback(
    throttle(() => {
      const isMobile = window.matchMedia(query).matches;
      setIsMatch(isMobile);
    }, 100),
    [],
  );

  return isMatch;
};
