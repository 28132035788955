import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import cx from 'classnames';

import { useQueryMatch } from 'hooks/useQueryMatch';
import { TabletOrMobileScreenQuery } from 'constants/ScreenQuery';

import './Breadcrumb.less';

type BreadcrumbRoute = {
  title: React.ReactNode;
  link?: string;
  icon?: React.ReactNode;
};

type Props = {
  routes: BreadcrumbRoute[];
};

export default function Breadcrumb(props: Props) {
  const isLightTheme = useSelector(isLightThemeSelector);

  const { routes } = props;
  const isTabletOrMobile = useQueryMatch(TabletOrMobileScreenQuery);

  const getRouteTitle = (route: BreadcrumbRoute) =>
    isTabletOrMobile && route.icon ? route.icon : route.title;

  return (
    <div
      className={cx("breadcrumb", {
        dark: !isLightTheme,
      })}
    >
      {routes.map((route, index) => (
        <React.Fragment key={`breadcrumb-${index}`}>
          {route.link ? (
            <Link to={route.link}>{getRouteTitle(route)}</Link>
          ) : (
            <>{getRouteTitle(route)}</>
          )}
          {index < routes.length - 1 && (
            <i className="icon icon-angle-right-light" />
          )}
        </React.Fragment>
      ))}
    </div>
  );
}
