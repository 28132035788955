import React from 'react';
import { App, Button, Dropdown, MenuProps } from 'antd';
import cx from 'classnames';
import { isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { PreSetupPTTemplateDetailsResponseModel } from 'api';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import { getCategoriesLabel, getCategoryColor } from 'utils/templateUtils';
import { confirmIcon } from 'constants/ConfirmationConstants';
import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { deletePreSetupTemplate } from 'redux/reducers/templateSlice';
import { AppDispatch } from 'redux/store';

type Props = {
  template: PreSetupPTTemplateDetailsResponseModel;
};

export default function AdminTemplateLibraryDetailsPage(props: Props) {
  const { template } = props;
  const { name, categoryId, id } = template;
  const isLightTheme = useSelector(isLightThemeSelector);
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { modal } = App.useApp();

  const onDeleteTemplate = async () => {
    if (isNil(id)) return;

    const result = await dispatch(deletePreSetupTemplate(id));

    if (deletePreSetupTemplate.fulfilled.match(result)) {
      AlertMessage.success('Template was successfully deleted.');
      navigate(`/templates`);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const handleDeleteTemplate = () => {
    return modal.confirm({
      title: 'The template will be removed from user accounts. Continue?',
      icon: confirmIcon,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      onOk: onDeleteTemplate,
      onCancel() {},
      okText: 'Yes',
    });
  };

  const templateMenu: MenuProps['items'] = [
    {
      key: 'delete',
      label: (
        <div onClick={handleDeleteTemplate}>
          <i className="icon icon-delete" />
          Delete
        </div>
      ),
    },
  ];

  return (
    <div
      className={cx('banner', {
        inverse: !isLightTheme,
      })}
    >
      <div className="banner__left">
        <div>
          <div className="banner__name mb-8">{name}</div>
          {!isNil(categoryId) && (
            <div
              className={cx('category-circle', getCategoryColor(categoryId))}
            >
              {getCategoriesLabel(categoryId)}
            </div>
          )}
        </div>
      </div>
      <div className="banner__actions">
        <div>
          <Link to={`/template/${id}/edit`}>
            <Button size="large" className="dark-btn">
              Edit
            </Button>
          </Link>
        </div>
        <div>
          <Dropdown
            menu={{ items: templateMenu }}
            trigger={['click']}
            placement="bottom"
          >
            <Button size="large" className="dark-btn">
              Options
            </Button>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
