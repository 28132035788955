export enum OnboardingPagesEnum {
  Home = 'home',
  Project = 'project',
  Template = 'templates',
  Analytics = 'analytics',
}

export enum HomePageSteps {
  Projects = 1,
  Templates = 2,
  Analytics = 5,
  Account = 3,
  Filters = 4,
}
