import { matchPath } from 'react-router-dom';

export const isHomePage = (pathname: string) => {
  return matchPath({ path: '/', end: true }, pathname);
};

export const isHelpCenterPage = (pathname: string) => {
  return matchPath({ path: '/help-center', end: true }, pathname);
};

export const isProjectPage = (pathname: string) => {
  return matchPath(
    {
      path: `/project/:projectId`,
      end: true,
    },
    pathname,
  );
};
