import { createSelector } from '@reduxjs/toolkit';
import { UserSpaceRoleEnum } from 'api';

import { RootState } from 'redux/reducers/rootReducer';

const selectRole = (state: RootState) => state.role;

export const roleInfoSelector = createSelector(
  selectRole,
  (role) => role.roleInfo || {},
);

export const isTrackerManagerSelector = createSelector(
  roleInfoSelector,
  (role) => role.roleId === UserSpaceRoleEnum.Basic,
);

export const isSpaceAdminSelector = createSelector(
  roleInfoSelector,
  (role) => role.roleId === UserSpaceRoleEnum.Admin,
);

export const isSuperAdminSelector = createSelector(
  roleInfoSelector,
  (role) => role.roleId === UserSpaceRoleEnum.SuperAdmin,
);