import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { Button, Spin, Tooltip } from 'antd';
import { nanoid } from '@reduxjs/toolkit';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import cx from 'classnames';

import {
  AdminPaymentHistoryResponseModel,
  AdminPaymentHistoryResponseModelStripePagedList,
} from 'api';
import AlertMessage from 'components/AlertMessage';
import Breadcrumb from 'components/Breadcrumb';
import { PageSize } from 'constants/ApiConstant';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { detailsSectionId } from 'constants/SearchConstants';
import { renderPaymentStatus } from 'features/auth/BillingSettingsPage/InvoiceHistoryTab/InvoiceHistoryTab';
import {
  exportTransactionsToExcel,
  getAdminTransactions,
} from 'redux/reducers/transactionSlice';
import { AppDispatch } from 'redux/store';
import {
  DispatchedRequestType,
  getPageSizeByWindowHeight,
} from 'utils/searchUtils';
import {
  DEFAULT_DOWNLOAD_FILE_ERROR_MESSAGE,
  downloadFileByObjectURL,
} from 'utils/fileUtils';
import { BILLING_DATE_FORMAT, getDateForUserTimezone } from 'utils/dateUtils';

import styles from './TransactionsPage.module.less';

export default function TransactionsPage() {
  const isLightTheme = useSelector(isLightThemeSelector);
  const [transactions, setTransactions] = useState<
    AdminPaymentHistoryResponseModel[]
  >([]);
  const [nextPage, setNextPage] = useState<string>();
  const [hasMore, setHasMore] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const infiniteScrollParentRef = useRef(null);
  const latestRequestRef = useRef<DispatchedRequestType>();
  const dispatch: AppDispatch = useDispatch();

  const detailsSectionHeight =
    document.getElementById(detailsSectionId)?.offsetHeight;
  const pageSizeByWindowHeight = getPageSizeByWindowHeight(
    detailsSectionHeight || 0,
    80,
  );

  useEffect(() => {
    initInfinityScroll();
  }, []);

  const onSetTransactions = useCallback(
    (payload: AdminPaymentHistoryResponseModelStripePagedList) => {
      const loadedTransactions = payload.details || [];
      const newTransactions = [...transactions, ...loadedTransactions];

      setHasMore(!!payload?.nextPage);
      setNextPage(payload?.nextPage);
      setTransactions(newTransactions);
    },
    [transactions],
  );

  const onLoadMore = useCallback(async () => {
    latestRequestRef.current?.abort?.();

    const request = dispatch(
      getAdminTransactions({
        pageSize:
          PageSize > pageSizeByWindowHeight ? PageSize : pageSizeByWindowHeight,
        pageNumber: nextPage,
      }),
    );

    latestRequestRef.current = request;
    const result = await request;

    if (!getAdminTransactions.fulfilled.match(result)) {
      if (result.meta.aborted) return;

      setHasMore(false);
      AlertMessage.error(result.error.message || ApiErrorMessage);
      return;
    }

    onSetTransactions(result.payload);
  }, [dispatch, onSetTransactions]);

  const initInfinityScroll = () => {
    setIsVisible(false);
    setTransactions([]);
    setHasMore(true);
    setNextPage(undefined);

    const timer = setTimeout(() => {
      // to destroy SearchLayout
      setIsVisible(true);
    }, 0);

    return () => clearTimeout(timer);
  };

  const downloadTransactions = async () => {
    setIsDownloading(true);
    const result = await dispatch(exportTransactionsToExcel());

    if (exportTransactionsToExcel.fulfilled.match(result)) {
      try {
        const { data, fileName } = result.payload;
        const url = URL.createObjectURL(data);

        await downloadFileByObjectURL(
          url,
          fileName || 'Apdated Transaction History.xlsx',
        );
      } catch {
        AlertMessage.error(DEFAULT_DOWNLOAD_FILE_ERROR_MESSAGE);
      }
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }

    setIsDownloading(false);
  };

  return (
    <div className="page-wrapper scroll-box ">
      <div className="page-box page-holder">
        <div id="details">
          <Breadcrumb
            routes={[
              {
                title: <i className="icon icon-home" />,
                link: '/',
              },
              { title: 'Transactions' },
            ]}
          />

          <div
            className={cx('banner', {
              inverse: !isLightTheme,
            })}
          >
            <div className="banner__name">Transactions</div>
            <div>
              <Tooltip title="Export to Excel" placement="top" trigger="hover">
                <Button
                  onClick={downloadTransactions}
                  type="text"
                  loading={isDownloading}
                >
                  <i className="icon icon-load" />
                </Button>
              </Tooltip>
            </div>
          </div>

            <div className={styles.wrapper} ref={infiniteScrollParentRef}>
              <table
                className={cx(styles.table, {
                  [styles.tableDark]: !isLightTheme,
                })}
              >
                <thead>
                  <tr>
                    <th className={styles.tableHead}>Company name</th>
                    <th className={styles.tableHead}>Billing date</th>
                    <th className={styles.tableHead}>Total amount</th>
                    <th className={styles.tableHead}>Status</th>
                    <th className={styles.tableHead}>Invoice ID</th>
                  </tr>
                </thead>
                {isVisible && (
                  <InfiniteScroll
                    loadMore={onLoadMore}
                    hasMore={hasMore}
                    loader={<Spin key={0} />}
                    useWindow={false}
                    getScrollParent={() => infiniteScrollParentRef.current}
                    threshold={200}
                    element="tbody"
                  >
                    {transactions.map((transaction) => (
                      <tr key={nanoid()}>
                        <td className={styles.tableCell}>
                          {transaction.spaceName}
                        </td>
                        <td className={styles.tableCell}>
                          <div>
                            {getDateForUserTimezone(
                              transaction.billingDate,
                              BILLING_DATE_FORMAT,
                            )}
                          </div>
                        </td>
                        <td className={styles.tableCell}>
                          ${(transaction.totalAmount || 0) / 100}
                        </td>
                        <td className={styles.tableCell}>
                          {renderPaymentStatus(transaction?.status || '')}
                        </td>
                        <td className={styles.tableCell}>
                          {transaction.invoiceUrl ? (
                            <a href={transaction.invoiceUrl} target="_blank">
                              {transaction.invoiceNumber}
                            </a>
                          ) : (
                            <span> {transaction.invoiceNumber}</span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </InfiniteScroll>
                )}
              </table>
            </div>
          
        </div>
      </div>
    </div>
  );
}
