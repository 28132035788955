import React, { useRef } from 'react';
import { Dropdown, MenuProps } from 'antd';
import { isEqual } from 'lodash';
import cx from 'classnames';

import {
  FolderSortFieldEnum,
  MessageHistorySortFieldEnum,
  ProgressTrackerSortFieldEnum,
  SortFieldEnum,
  SortOrderEnum,
  SpaceSortFieldEnum,
  UserSortFieldEnum,
} from 'api';

import {
  adminProjectsFilterOptions,
  adminTrackersFilterOptions,
  baseFilterOptions,
  cancellationHistoryOptions,
  messagesLogFilterOptions,
  projectsFilterOptions,
  spacesFilterOptions,
  templateLibraryOptions,
  usersFilterOptions,
} from 'components/filters/SortingFilter/utils';
import styles from '../filters.module.less';
import { CancellationHistorySortFieldEnum } from 'features/admin/CancellationHistoryPage/CancellationHistoryPage';

export type SortFieldType =
  | SortFieldEnum
  | UserSortFieldEnum
  | FolderSortFieldEnum
  | ProgressTrackerSortFieldEnum
  | SpaceSortFieldEnum
  | MessageHistorySortFieldEnum
  | CancellationHistorySortFieldEnum;

export type SortingFilterValueType = {
  sortField: SortFieldType;
  sortOrder: SortOrderEnum;
};

export type SortingFilterType = {
  label: string;
  icon: string;
  value: SortingFilterValueType;
};

export enum SortingMode {
  Projects = 1,
  Templates = 2,
  AdminProjects = 3,
  Users = 4,
  AdminTrackers = 5,
  Spaces = 6,
  MessagesLog = 7,
  CancellationHistory = 8,
  TemplateLibrary = 9,
}

type Props = {
  onChange: (value: SortingFilterValueType) => void;
  value: SortingFilterValueType;
  mode: SortingMode;
};

export default function SortingFilter(props: Props) {
  const { value, onChange, mode } = props;
  const selectContainer: any = useRef();

  const getFilterOptions = (mode: SortingMode) => {
    switch (mode) {
      case SortingMode.Templates:
        return baseFilterOptions;
      case SortingMode.AdminProjects:
        return adminProjectsFilterOptions;
      case SortingMode.AdminTrackers:
        return adminTrackersFilterOptions;
      case SortingMode.Projects:
        return projectsFilterOptions;
      case SortingMode.Users:
        return usersFilterOptions;
      case SortingMode.Spaces:
        return spacesFilterOptions;
      case SortingMode.MessagesLog:
        return messagesLogFilterOptions;
      case SortingMode.CancellationHistory:
        return cancellationHistoryOptions;
      case SortingMode.TemplateLibrary:
        return templateLibraryOptions;
    }
  };

  const filterOptions = getFilterOptions(mode);

  const currentOption = filterOptions.find(
    (filterOptions: SortingFilterType) =>
      filterOptions.value.sortOrder === value.sortOrder &&
      filterOptions.value.sortField === value.sortField,
  );

  const getFilterItems = (options: SortingFilterType[]): MenuProps['items'] =>
    options.map((option, index) => ({
      key: `sorting-filter-item-${index}`,
      label: (
        <div
          onClick={() => onChange(option.value)}
          className={cx({
            [styles.selectedValue]: isEqual(currentOption, option),
          })}
        >
          {option.label} <i className={option.icon} />
        </div>
      ),
    }));

  return (
    <div ref={selectContainer}>
      <Dropdown
        menu={{ items: getFilterItems(filterOptions) }}
        trigger={['click']}
        placement="bottomRight"
        getPopupContainer={() => selectContainer.current}
      >
        <button className={styles.filterOpener}>
          <span>Sort by </span> {currentOption?.label}{' '}
          <i className="icon icon-angle-down"></i>
        </button>
      </Dropdown>
    </div>
  );
}
