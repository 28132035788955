import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDidMount } from 'rooks';

import SuccessUpgradeSection from './PricingTab/SuccessUpgradeSection';
import SettingsLayout from 'components/settings/SettingsLayout';
import { SettingsTabEnum } from 'constants/SettingsConstants';
import { PaymentLocationState } from 'features/auth/BillingSettingsPage/PricingTab/PricingPaymentDetails/PriceDetailsSection';
import { isSuperAdminSelector } from 'redux/selectors/roleSelectors';

export default function BillingSettingsPage() {
  const { state } = useLocation();
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const AVAILABILITY_OF_TABS = {
    [SettingsTabEnum.CreditCard.toString()]: isSuperAdmin,
    [SettingsTabEnum.Payments.toString()]: isSuperAdmin,
    [SettingsTabEnum.Pricing.toString()]: isSuperAdmin,
  };
  const navigate = useNavigate();
  const { search } = useLocation();

  useDidMount(() => {
    navigate(
      { search },
      {
        state: { isPaidSuccessfully: false },
        replace: true,
      },
    );
  });

  return (
    <>
      {state?.isPaidSuccessfully && <SuccessUpgradeSection />}

      {!state?.isPaidSuccessfully && (
        <SettingsLayout
          availableTabs={AVAILABILITY_OF_TABS}
          defaultTab={SettingsTabEnum.CreditCard}
        />
      )}
    </>
  );
}
