import { useEffect, useState } from 'react';
import { App, Col, Divider, Form, Radio, Row, Spin } from 'antd';
import cx from 'classnames';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import PTModal, { PTModalProps } from 'components/PTModal';
import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { confirmIcon } from 'constants/ConfirmationConstants';
import {
  cancelSubscription,
  getCancellationReasons as getCancellationReasonsApi,
} from 'redux/reducers/accountSlice';
import { AppDispatch } from 'redux/store';
import { CancellationReasonResponseModel } from 'api';
import Controller from 'components/form/Controller';
import RadioGroup from 'components/form/RadioGroup';
import { getUserInfo } from 'redux/reducers/authenticationSlice';
import { userSelector } from 'redux/selectors/authenticationSelectors';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import {
  getDateForUserTimezone,
  NEXT_BILLING_DATE_DATE_FORMAT,
} from 'utils/dateUtils';

import styles from './CancelSubscriptionModal.module.less';

const validationSchema = Yup.object().shape({
  cancellationReasonId: Yup.number().required('Field is required'),
});

enum CancellationReasonsEnum {
  NoCompatible = 1,
  TooExpensive = 2,
  TooComplicated = 3,
  DidntUse = 4,
  NoIntegrate = 5,
  NoLongerNeed = 6,
}

interface CancellationFormValues {
  cancellationReasonId: number;
}

type Props = {
  onClose: () => void;
} & Omit<PTModalProps, 'children'>;

export default function CancelSubscriptionModal(props: Props) {
  const { onClose, open, ...otherModalProps } = props;
  const [cancellationReasons, setCancellationReasons] = useState<
    CancellationReasonResponseModel[]
  >([]);
  const [isnReasonsLoading, setIsReasonsLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const { modal } = App.useApp();
  const user = useSelector(userSelector);
  const isLightTheme = useSelector(isLightThemeSelector);

  const { errors, formState, handleSubmit, control } =
    useForm<CancellationFormValues>({
      resolver: yupResolver(validationSchema),
      mode: 'all',
      defaultValues: {
        cancellationReasonId: undefined,
      },
    });
  const { isDirty, isSubmitting } = formState;

  useEffect(() => {
    if (!open) return;

    getCancellationReasons();
  }, [open]);

  const getCancellationReasons = async () => {
    setIsReasonsLoading(true);
    const result = await dispatch(getCancellationReasonsApi());

    if (getCancellationReasonsApi.fulfilled.match(result)) {
      setCancellationReasons(result.payload);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
    setIsReasonsLoading(false);
  };

  const onSubscriptionCancellation = async (values: CancellationFormValues) => {
    const result = await dispatch(
      cancelSubscription(values.cancellationReasonId),
    );

    if (cancelSubscription.fulfilled.match(result)) {
      await dispatch(getUserInfo());
      onClose();

      AlertMessage.warning(
        `Your subscription expires on ${getDateForUserTimezone(
          user?.nextPaymentDate,
          NEXT_BILLING_DATE_DATE_FORMAT,
        )}} and will not be renewed.`,
      );
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const handleSubscriptionCancellation = async (
    values: CancellationFormValues,
  ) => {
    onClose();

    return modal.confirm({
      title:
        'We’re sorry to see you go. Your account will be downgraded to the basic plan at the end of your subscription, and you will no longer be charged.',
      icon: confirmIcon,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      async onOk() {
        await onSubscriptionCancellation(values);
      },
      onCancel() {},
      okText: 'Confirm Cancellation',
      cancelText: 'I changed my mind',
    });
  };

  const getReasonIcon = (reasonId: number) => {
    switch (reasonId) {
      case CancellationReasonsEnum.NoCompatible:
        return <i className="icon icon-cloud-block" />;
      case CancellationReasonsEnum.TooExpensive:
        return <i className="icon icon-file-contract-dollar" />;
      case CancellationReasonsEnum.TooComplicated:
        return <i className="icon icon-wrench" />;
      case CancellationReasonsEnum.DidntUse:
        return <i className="icon icon-chart-down" />;
      case CancellationReasonsEnum.NoIntegrate:
        return <i className="icon icon-sitemap" />;
      case CancellationReasonsEnum.NoLongerNeed:
        return <i className="icon icon-x-add" />;
    }
  };

  return (
    <PTModal
      {...otherModalProps}
      open={open}
      className={cx('modal-root', styles.modal)}
      title="Feedback survey"
      onOk={handleSubmit(handleSubscriptionCancellation)}
      okButtonProps={{
        disabled: !isDirty,
      }}
      okText="Submit"
      cancelText="Not Now"
      confirmLoading={isSubmitting}
      onCancel={onClose}
      zIndex={999}
    >
      <Form layout="vertical">
        <div className="modal-scroll-wrapper">
          <p>
            To finish processing the cancellation, please share your honest
            feedback with us to help us improve.
          </p>

          <Divider />

          <Controller
            name="cancellationReasonId"
            control={control}
            error={errors.cancellationReasonId}
            label="What was the reason you decided to cancel your subscription?"
            size="large"
            render={(field) => (
              <RadioGroup {...field}>
                <Row gutter={[10, 10]}>
                  {cancellationReasons.map((cancellationReason) => (
                    <Col xs={24} lg={12}>
                      <div className={styles.card}>
                        <Radio.Button
                          value={cancellationReason.id}
                          key={cancellationReason.id}
                        >
                          <div className={styles.cardBox}>
                            <div className={styles.cardIcon}>
                              {getReasonIcon(cancellationReason.id || 0)}
                            </div>
                            {cancellationReason.reason}
                          </div>
                        </Radio.Button>
                      </div>
                    </Col>
                  ))}
                </Row>
              </RadioGroup>
            )}
          />
          {isnReasonsLoading && <Spin />}
        </div>
      </Form>
    </PTModal>
  );
}
