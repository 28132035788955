import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { App, Button, Pagination, Spin } from 'antd';
import { isNil } from 'lodash';

import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import UserGroupModal from 'components/modals/UserGroupModal';
import { UserGroupDetailsResponseModel, UserGroupListResponseModel } from 'api';
import { useQueryMatch } from 'hooks/useQueryMatch';
import {
  deleteUserGroup,
  getUserGroupById,
  getUserGroupListBySpace,
} from 'redux/reducers/projectSlice';
import { PageDefaultSize } from 'constants/ApiConstant';
import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { AppDispatch } from 'redux/store';
import { mapUserGroupToInitialValues } from 'utils/groupUtils';
import { confirmIcon } from 'constants/ConfirmationConstants';
import { DesktopScreenQuery } from 'constants/ScreenQuery';

import styles from '../UserManagementTab/UserManagementTab.module.less';
import userGroups from 'assets/img/userGroups.svg';

export default function UserGroupsTab() {
  const [isUserGroupDetailsLoading, seIsUserGroupDetailsLoading] =
    useState(false);
  const [userGroupDetails, seUserGroupDetails] =
    useState<UserGroupDetailsResponseModel>();
  const [isUserGroupsLoaded, setIsUserGroupsLoaded] = useState(false);
  const [userGroupList, setUserGroupList] = useState<
    UserGroupListResponseModel[]
  >([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [isUserGroupModalOpen, setIsUserGroupModalOpen] = useState(false);
  const { modal } = App.useApp();

  const isLightTheme = useSelector(isLightThemeSelector);
  const dispatch: AppDispatch = useDispatch();
  const isMobile = useQueryMatch(DesktopScreenQuery);

  const hasNoUserGroup = !userGroupList.length && isUserGroupsLoaded;
  const hasAnyUserGroup = !!userGroupList.length && isUserGroupsLoaded;

  const loadUserGroupDetails = async (id: string) => {
    seIsUserGroupDetailsLoading(true);
    const result = await dispatch(getUserGroupById(id));

    if (getUserGroupById.fulfilled.match(result)) {
      seUserGroupDetails(result.payload);
      handleOpenUserGroupModal();
    }
    seIsUserGroupDetailsLoading(false);
  };

  const onDeleteUserGroup = async (id: string) => {
    const result = await dispatch(deleteUserGroup(id));

    if (deleteUserGroup.fulfilled.match(result)) {
      loadUserGroupBySpace(1);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const handleDeleteUser = async (userGroupId: string) => {
    return modal.confirm({
      title: 'Are you sure you want to delete this group?',
      icon: confirmIcon,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      async onOk() {
        await onDeleteUserGroup(userGroupId);
      },
      onCancel() {},
      okText: 'Yes',
    });
  };
  const handleOpenUserGroupModal = () => {
    setIsUserGroupModalOpen(true);
  };

  const handleCloseUserGroupModal = () => {
    setIsUserGroupModalOpen(false);
    seUserGroupDetails(undefined);
  };

  const loadUserGroupBySpace = async (pageNumber: number) => {
    const result = await dispatch(
      getUserGroupListBySpace({
        name: undefined,
        pageNumber: pageNumber - 1,
        pageSize: PageDefaultSize,
      }),
    );

    if (getUserGroupListBySpace.fulfilled.match(result)) {
      setUserGroupList(result.payload.details || []);
      setTotalCount(result.payload.totalCount || 0);
      setPageNumber(pageNumber);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
    setIsUserGroupsLoaded(true);
  };

  useEffect(() => {
    loadUserGroupBySpace(1);
  }, []);

  return (
    <>
      <UserGroupModal
        open={isUserGroupModalOpen}
        onCancel={handleCloseUserGroupModal}
        initialValues={
          !isNil(userGroupDetails)
            ? mapUserGroupToInitialValues(userGroupDetails)
            : undefined
        }
        onSuccess={() => loadUserGroupBySpace(1)}
      />

      <div
        className={cx('banner', {
          inverse: !isLightTheme,
        })}
      >
        <div className="banner__left">
          <div>
            <div className="banner__name mb-8">User groups</div>
            <div>Organize and manage user access to projects.</div>
          </div>
        </div>
        <div className="banner__actions">
          <div>
            <Button
              onClick={handleOpenUserGroupModal}
              className={cx({
                'dark-btn': isLightTheme,
                'ant-btn-primary': !isLightTheme,
              })}
              size="large"
            >
              Create user group
            </Button>
          </div>
        </div>
      </div>

      {!isUserGroupsLoaded && <Spin />}

      {hasNoUserGroup && (
        <div className="noData">
          <div className="image">
            <img src={userGroups} alt="no-invited-groups" />
          </div>
          <div className="title">
            User groups promote efficient collaboration within teams that need
            the same access permissions or restrictions.
          </div>
          <div className="text">
            Click on the
            <Button
              onClick={handleOpenUserGroupModal}
              type="link"
              className={styles.btn}
            >
              Create user group
            </Button>
            button to add a new group.
          </div>
        </div>
      )}

      {hasAnyUserGroup && (
        <div
          className={
            isMobile
              ? cx('modal-scroll-wrapper', styles.tab)
              : cx(styles.tab, styles.directionWrap)
          }
        >
          <div className={styles.directionBox}>
            <div className={styles.tableHead}>
              <div
                className={cx(
                  styles.tableRow,
                  styles.userGroups,
                  styles.xsNone,
                )}
              >
                <div className={styles.tableCol}>Group Name</div>
                <div
                  className={styles.tableCol}
                  style={{ textAlign: 'center' }}
                >
                  Number of users
                </div>
                <div className={cx(styles.tableCol, styles.actions)}>
                  Actions
                </div>
              </div>

              <div className={cx(styles.tableRow, styles.lgNone)}>
                <div className={styles.tableCol}>Users group</div>
              </div>
            </div>
            <div className={styles.tableBody}>
              {userGroupList.map((userGroup) => {
                const { id, userCount, name } = userGroup;

                return (
                  <div
                    className={cx(styles.tableRow, styles.userGroups)}
                    key={id}
                  >
                    <div className={styles.tableCol}>{name}</div>
                    <div
                      className={cx(styles.tableCol, styles.info)}
                      style={{ justifyContent: 'center' }}
                    >
                      {userCount}
                    </div>

                    <div className={cx(styles.tableCol, styles.actions)}>
                      <div>
                        <Button
                          type="ghost"
                          onClick={() => loadUserGroupDetails(id || '')}
                          loading={isUserGroupDetailsLoading}
                        >
                          <i className="icon icon-pencil" />
                        </Button>
                      </div>

                      <div>
                        <Button
                          onClick={() => handleDeleteUser(id || '')}
                          type="ghost"
                        >
                          <i className="icon icon-trash-can" />
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className={styles.pagination}>
              <Pagination
                current={pageNumber}
                pageSize={PageDefaultSize}
                total={totalCount}
                onChange={loadUserGroupBySpace}
                showSizeChanger={false}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
