import React, { useCallback, useState } from 'react';
import { App, Button, Dropdown, MenuProps } from 'antd';
import { isNil } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import cx from 'classnames';

import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import {
  ProgressTrackerTemplateEditRequestModel,
  ProgressTrackerTemplateRequestModel,
  ProgressTrackerTemplateResponseModel,
} from 'api';
import { getDateForUserTimezone } from 'utils/dateUtils';
import { getUsedTimes } from 'utils/trackerUtils';
import { createProject } from 'redux/reducers/projectSlice';
import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { AppDispatch } from 'redux/store';
import ProjectListModal from 'features/template/TemplateEdit/ProjectListModal';
import AddProjectModal from 'features/template/TemplateEdit/AddProjectModal';
import {
  createTemplate,
  deleteProgressTrackerTemplate,
  updateTemplate as updateTemplateApi,
} from 'redux/reducers/templateSlice';
import UseTemplateQuantityModal from 'features/template/UseTemplateQuantityModal';
import { isTrackerRestrictionsLoadingSelector } from 'redux/selectors/progressTrackerSelectors';
import {
  AdditionalTrackerRestrictionProps,
  withTrackerRestrictionsControl,
} from 'hocs/withTrackerRestrictionsControl';
import { RestrictionModalTypeEnum } from 'features/account/AccountSettingsPage/RestrictionModal';
import {
  TemplateEnum,
  TemplateSections,
} from '../../TemplateListPage/UserTemplatesListPage';
import { confirmIcon } from 'constants/ConfirmationConstants';

type Props = {
  template: ProgressTrackerTemplateResponseModel;
  mode?: TemplateEnum;
  loadTemplate: () => Promise<void>;
};

interface TemplateProjectValues {
  folderId: string;
  folderName: string;
}

const validationSchema = Yup.object().shape(
  {
    folderId: Yup.string()
      .nullable()
      .when('folderName', {
        is: (folderName: string) => isNil(folderName),
        then: Yup.string().required('Field is required'),
      }),
    folderName: Yup.string()
      .nullable()
      .when('folderId', {
        is: (folderId: string) => isNil(folderId),
        then: Yup.string().required('Field is required'),
      })
      .max(30, 'Project name should be 30 characters maximum'),
  },
  [['folderName', 'folderId']],
);

function TemplateDetailsSection(
  props: Props & AdditionalTrackerRestrictionProps,
) {
  const {
    template,
    checkCanCreate,
    mode = TemplateEnum.MyTemplates,
    loadTemplate,
  } = props;
  const { id, name, usedTimes, folderId, folderName, stages, isDraft } =
    template;
  const [isProjectListModalOpen, setIsProjectListModalOpen] = useState(false);
  const [isAddProjectModalOpen, setIsAddProjectModalOpen] = useState(false);
  const [isQuantityModalOpen, setIsQuantityModalOpen] = useState(false);
  const isLightTheme = useSelector(isLightThemeSelector);
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const isTrackerRestrictionsLoading = useSelector(
    isTrackerRestrictionsLoadingSelector,
  );
  const { modal } = App.useApp();
  const myTemplate = mode === TemplateEnum.MyTemplates;
  const { errors, control, trigger, handleSubmit } =
    useForm<TemplateProjectValues>({
      resolver: yupResolver(validationSchema),
      mode: 'all',
      defaultValues: { folderId: undefined, folderName: undefined },
    });

  const handleUseClick = useCallback(async () => {
    const canCreate = await checkCanCreate?.();

    if (isTrackerRestrictionsLoading) return;

    if (!canCreate) return;

    if (!folderId || !folderName) {
      setIsProjectListModalOpen(true);
      return;
    }

    setIsQuantityModalOpen(true);
  }, [isTrackerRestrictionsLoading, checkCanCreate, folderId, folderName]);

  const handleCloseQuantityModal = () => setIsQuantityModalOpen(false);

  const handleOpenQuantityModal = () => setIsQuantityModalOpen(true);

  const handleAddProjectModalOpen = () => {
    setIsAddProjectModalOpen(true);
  };

  const handleAddProjectModalClose = () => {
    setIsAddProjectModalOpen(false);
    setIsProjectListModalOpen(true);
  };

  const handleProjectListModalCancel = () => {
    setIsProjectListModalOpen(false);
  };

  const createNewProject = async (folderName: string) => {
    const result = await dispatch(createProject({ name: folderName }));

    if (createProject.fulfilled.match(result)) {
      return result.payload;
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const editTemplate = async (folderId: string) => {
    const data: ProgressTrackerTemplateEditRequestModel = {
      id,
      name,
      folderId,
      stages,
    };

    const result = await dispatch(updateTemplateApi(data));

    if (updateTemplateApi.fulfilled.match(result)) {
      AlertMessage.success('Project successfully assigned.');
      await loadTemplate();
      setIsAddProjectModalOpen(false);
      setIsProjectListModalOpen(false);
      setIsQuantityModalOpen(true);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
      setIsAddProjectModalOpen(false);
      setIsProjectListModalOpen(true);
    }
  };

  const onSaveTemplateToMyList = async (folderId?: string) => {
    const requestBody: ProgressTrackerTemplateRequestModel = {
      stages: stages,
      folderId,
      name: name,
    };
    const result = await dispatch(createTemplate(requestBody));

    if (createTemplate.fulfilled.match(result)) {
      AlertMessage.success('The template was successfully added.');
      setIsAddProjectModalOpen(false);
      setIsProjectListModalOpen(false);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const onSubmit = async (values: TemplateProjectValues) => {
    const folderName = values?.folderName?.trim();

    if (folderName) {
      const projectId = await createNewProject(folderName);

      if (!myTemplate) return await onSaveTemplateToMyList(projectId);

      if (projectId) {
        await editTemplate(projectId);
      }
    } else {
      if (!myTemplate) return await onSaveTemplateToMyList(values.folderId);
      await editTemplate(values.folderId);
    }
  };

  const onDeleteTemplate = async () => {
    if (isNil(id)) return;

    const result = await dispatch(deleteProgressTrackerTemplate(id));

    if (deleteProgressTrackerTemplate.fulfilled.match(result)) {
      AlertMessage.success('Template was successfully deleted.');
      navigate(`/templates?tab=${TemplateSections.MyTemplates}`);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const handleDeleteTemplate = () => {
    return modal.confirm({
      title: 'The template will be removed from your account. Continue?',
      icon: confirmIcon,
      className: cx('confirm-modal', {
        'confirm-modal-dark-button': !isLightTheme,
      }),
      onOk: onDeleteTemplate,
      onCancel() {},
      okText: 'Yes',
    });
  };

  const handleSave = async () => {
    await trigger();
    await handleSubmit(onSubmit)();
  };

  const templateMenu: MenuProps['items'] = [
    {
      key: 'delete',
      label: (
        <div onClick={handleDeleteTemplate}>
          <i className="icon icon-delete" />
          Delete
        </div>
      ),
    },
  ];

  return (
    <div
      className={cx('banner', {
        inverse: !isLightTheme,
      })}
    >
      <div className="banner__left">
        <div>
          <div className="banner__name mb-8">{name}</div>
          {myTemplate && (
            <>
              <div className="banner__item">
                Usage count: <span>{getUsedTimes(usedTimes || 0)}</span>
              </div>
              <div className="banner__item">
                Created:{' '}
                <span>
                  {getDateForUserTimezone(
                    (template as ProgressTrackerTemplateResponseModel)
                      .createdDate,
                  )}
                </span>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="banner__actions">
        {!isDraft && (
          <div>
            <Button
              loading={isTrackerRestrictionsLoading}
              onClick={handleUseClick}
              size="large"
              className="dark-btn-outline"
            >
              Use Template
            </Button>
          </div>
        )}
        {myTemplate && (
          <div>
            <Link to={`/template/${id}/edit`}>
              <Button size="large" className="dark-btn">
                Edit
              </Button>
            </Link>
          </div>
        )}
        <div>
          <Dropdown
            menu={{ items: templateMenu }}
            trigger={['click']}
            placement="bottom"
          >
            <Button size="large" className="dark-btn">
              Options
            </Button>
          </Dropdown>
        </div>
      </div>

      <ProjectListModal
        open={isProjectListModalOpen}
        onOk={handleSave}
        onCancel={handleProjectListModalCancel}
        onAddProjectModalOpen={handleAddProjectModalOpen}
        control={control}
        error={errors.folderId}
      />

      <AddProjectModal
        open={isAddProjectModalOpen}
        control={control}
        error={errors.folderName}
        onClose={handleAddProjectModalClose}
        onOk={handleSave}
      />

      <UseTemplateQuantityModal
        open={isQuantityModalOpen}
        onClose={handleCloseQuantityModal}
        onOpen={handleOpenQuantityModal}
        mode={mode}
      />
    </div>
  );
}

export default withTrackerRestrictionsControl({
  restrictionType: RestrictionModalTypeEnum.CreateTrackerFromTemplate,
  isFetchingInstant: false,
})(TemplateDetailsSection);
