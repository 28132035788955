import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useDidUpdate } from 'rooks';

export default function useModalCloseOnNavigate(onClose: () => void) {
  const location = useLocation();

  useDidUpdate(() => {
    onClose();
  }, [location.pathname]);

  useEffect(() => {
    return () => {
      onClose();
    };
    // eslint-disable-next-line
  }, []);

  return null;
}
