import { useMemo, useState } from 'react';
import { Button, Calendar, DatePicker, Menu } from 'antd';
import { HeaderRender } from 'antd/lib/calendar/generateCalendar';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { isNull, startCase } from 'lodash';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import cx from 'classnames';
import { useSelector } from 'react-redux';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

import { EnumHelpers } from 'utils/enumUtils';
import { dateFormat } from 'utils/dateUtils';

import styles from './RangePicker.module.less';

dayjs.extend(isBetween);

enum DatePresetsEnum {
  Today = 1,
  Yesterday = 2,
  Last7Days = 3,
  ThisMonth = 4,
  LastMonth = 5,
  Custom = 6,
}

const calendarMonthDateFormat = 'MMMM YYYY';

const MINIMAL_DATE = dayjs('01/01/2018', dateFormat);

type DateType = dayjs.Dayjs | null;

export type DateRangeType = {
  from?: Date;
  to?: Date;
};

type Props = {
  onApplyDateRange: (value: DateRangeType) => void;
  onCloseRangePicker: () => void;
  defaultFromDate?: Date;
  defaultToDate?: Date;
  isSubscriptionEnd?: boolean;
};

export default function RangePicker(props: Props) {
  const isLightTheme = useSelector(isLightThemeSelector);
  const {
    onApplyDateRange,
    defaultFromDate,
    defaultToDate,
    onCloseRangePicker,
    isSubscriptionEnd = false,
  } = props;

  const [currentCalendarMonth, setCurrentCalendarMonth] = useState(
    dayjs().add(1, 'day'),
  );
  const [isFromDateActive, setIsFromActive] = useState(true);
  const [isToDateActive, setIsToDateActive] = useState(false);
  const [fromDate, setFromDate] = useState<DateType>(
    defaultFromDate ? dayjs(defaultFromDate) : null,
  );
  const [toDate, setToDate] = useState<DateType>(
    defaultToDate ? dayjs(defaultToDate) : null,
  );
  const [presetValue, setPresetValue] = useState<DatePresetsEnum>(
    DatePresetsEnum.Custom,
  );

  const presetItems = EnumHelpers.getEnumValues(DatePresetsEnum).map(
    (value) => ({
      key: value,
      label: startCase(DatePresetsEnum[+value]),
    }),
  );

  const validRange = useMemo((): [dayjs.Dayjs, dayjs.Dayjs] => {
    const endValidDate = isSubscriptionEnd ? dayjs().add(1, 'year') : dayjs();

    if ((fromDate && !toDate) || (fromDate && toDate && isToDateActive)) {
      return [fromDate, endValidDate];
    }

    if ((!fromDate && toDate) || (fromDate && toDate && isFromDateActive)) {
      return [MINIMAL_DATE, toDate];
    }

    return [MINIMAL_DATE, endValidDate];
  }, [fromDate, toDate, isFromDateActive, isToDateActive]);

  const [validFromDate, validToDate] = validRange;

  const handleFromDateActive = () => {
    setIsFromActive(true);
    setIsToDateActive(false);
  };

  const handleToDateActive = () => {
    setIsFromActive(false);
    setIsToDateActive(true);
  };

  const checkIsDateInValidRange = (date: DateType) =>
    date?.isBetween(validFromDate, validToDate, undefined, '[]');

  const handleFromDateChange = (date: DateType) => {
    const isDateValid = checkIsDateInValidRange(date);

    if (isDateValid || isNull(date)) {
      setFromDate(date);
    }
  };

  const handleToDateChange = (date: DateType) => {
    const isDateValid = checkIsDateInValidRange(date);

    if (isDateValid || isNull(date)) {
      setToDate(date);
    }
  };

  const handlePresetClick = (preset: DatePresetsEnum) => {
    setPresetValue(preset);

    switch (preset) {
      case DatePresetsEnum.Today:
        setFromDate(dayjs());
        setToDate(dayjs());
        break;
      case DatePresetsEnum.Yesterday:
        setFromDate(dayjs().subtract(1, 'day'));
        setToDate(dayjs());
        break;
      case DatePresetsEnum.Last7Days:
        setFromDate(dayjs().subtract(7, 'day'));
        setToDate(dayjs());
        break;
      case DatePresetsEnum.ThisMonth:
        setFromDate(dayjs().startOf('month'));
        setToDate(dayjs());
        break;
      case DatePresetsEnum.LastMonth:
        setFromDate(dayjs().subtract(1, 'month').startOf('month'));
        setToDate(dayjs().subtract(1, 'month').endOf('month'));
        break;
      case DatePresetsEnum.Custom:
        setFromDate(null);
        setToDate(null);
        break;
    }
  };

  const handleCalendarDateChange = (date: dayjs.Dayjs) => {
    isFromDateActive ? setFromDate(date) : setToDate(date);
    setPresetValue(DatePresetsEnum.Custom);
    setCurrentCalendarMonth(date.clone().date(currentCalendarMonth.date()));
  };

  const handleApplyDateRange = () => {
    const from = fromDate
      ? new Date(fromDate.format('YYYY-MM-DD 00:00:00'))
      : undefined;
    const to = toDate
      ? new Date(toDate.format('YYYY-MM-DD 23:59:59'))
      : undefined;

    onApplyDateRange({ from, to });
    onCloseRangePicker();
  };

  const goToPreviousMonth = () => {
    const newMonth = currentCalendarMonth.clone().subtract(1, 'months');
    setCurrentCalendarMonth(newMonth);
  };

  const goToNextMonth = () => {
    const newMonth = currentCalendarMonth.clone().add(1, 'months');
    setCurrentCalendarMonth(newMonth);
  };

  const renderCalendarHeader: HeaderRender<dayjs.Dayjs> = ({ value }) => {
    return (
      <span onClick={goToPreviousMonth} style={{ cursor: 'pointer' }}>
        <LeftOutlined /> {value.format(calendarMonthDateFormat)}
      </span>
    );
  };

  const calendarDateCellRender = (date: dayjs.Dayjs) => {
    if (date.isSame(fromDate, 'date') || date.isSame(toDate, 'date')) {
      return <div className={styles.today}>{date.date()}</div>;
    }

    if (date.isAfter(fromDate) && date.isBefore(toDate)) {
      return (
        <div style={{ backgroundColor: 'rgba(252, 210, 40, 0.15)' }}>
          {date.date()}
        </div>
      );
    }

    return <div>{date.date()}</div>;
  };

  return (
    <div className={styles.pickerWrapper}>
      <div className={styles.pickerSider}>
        <Menu
          items={presetItems}
          mode="vertical"
          onClick={(e) => handlePresetClick(+e.key)}
          selectedKeys={[presetValue.toString()]}
        />
        <div className={styles.pickerBox}>
          <div>
            <Button onClick={onCloseRangePicker}  className={cx({ 'dark-btn-outline': !isLightTheme })}>Cancel</Button>
          </div>
          <div>
            <Button type="primary" onClick={handleApplyDateRange}>
              Apply
            </Button>
          </div>
        </div>
      </div>

      <div className={styles.pickerMain}>
        <div className={styles.pickerBox}>
          <div className={styles.pickerWrap}>
            <DatePicker
              format={dateFormat}
              value={fromDate}
              onClick={handleFromDateActive}
              onChange={handleFromDateChange}
              style={{ borderColor: isFromDateActive ? '#191c21' : '#f0f0f0' }}
              popupClassName={styles.picker}
              autoFocus
              allowClear
            />
          </div>

          <div className={styles.pickerWrap}>
            <DatePicker
              format={dateFormat}
              value={toDate}
              onClick={handleToDateActive}
              onChange={handleToDateChange}
              popupClassName={styles.picker}
              style={{ borderColor: isToDateActive ? '#191c21' : '#f0f0f0' }}
              allowClear
            />
          </div>
        </div>

        <div className={styles.calendar}>
          <Calendar
            fullscreen={false}
            value={currentCalendarMonth}
            validRange={validRange}
            onSelect={handleCalendarDateChange}
            headerRender={renderCalendarHeader}
            dateFullCellRender={calendarDateCellRender}
          />
          <span style={{ cursor: 'pointer' }} onClick={goToNextMonth}>
            {currentCalendarMonth
              .clone()
              .add(1, 'months')
              .format(calendarMonthDateFormat)}{' '}
            <RightOutlined />
          </span>
        </div>
      </div>
    </div>
  );
}
