import React from 'react';
import { Control, FieldErrors } from 'react-hook-form/dist/types';

import apiClient from 'api';
import { generateUploadFileList, getFileUploadMessages } from 'utils/fileUtils';
import UploadList from 'components/wrappers/BaseFileUpload/UploadList';
import FormFileUpload from 'components/form/FormFileUpload';
import { FileValue } from 'components/wrappers/BaseFileUpload';

const FILE_TYPES = ['DOC', 'DOCX', 'PDF', 'EXCEL', 'RTF', 'PPT', 'JPG', 'PNG'];

const fileUploadMessages = getFileUploadMessages({
  fileTypes: FILE_TYPES,
});

type Props = {
  uploaderRef: any;
  name: string;
  control: Control;
  error?: FieldErrors | string;
  defaultValue?: FileValue[];
};

export default function FileTrackerUploader(props: Props) {
  const { name, control, error, uploaderRef, defaultValue } = props;

  const defaultFileList = defaultValue
    ? generateUploadFileList(defaultValue)
    : [];

  const uploadFileLinkFunction = apiClient.createAttachment.bind(apiClient);

  return (
    <FormFileUpload
      uploaderRef={uploaderRef}
      name={name}
      defaultValue={defaultValue}
      error={error}
      control={control}
      defaultFileList={defaultFileList}
      uploadLinkFunction={uploadFileLinkFunction}
      messages={fileUploadMessages}
      getUploadList={UploadList}
      maxFileCount={1}
      acceptedFileSize={10}
      acceptedFileFormat={[
        '.doc',
        '.docx',
        '.pdf',
        '.rtf',
        '.jpg',
        '.jpeg',
        '.png',
        '.ppt',
        'application/msword',
        'application/pdf',
        'application/rtf',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-powerpoint',
        'image/jpeg',
        'image/png',
      ]}
    >
      {() => <></>}
    </FormFileUpload>
  );
}
