import React, { ReactNode } from 'react';
import { Button } from 'antd';
import { FormState } from 'react-hook-form/dist/types/form';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { isNil } from 'lodash';

import SubmitButton from 'components/SubmitButton';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

type Props<T> = {
  title?: string;
  description?: string | ReactNode;
  formState?: FormState<T>;
  customSubmitButton?: ReactNode;
  onClose?: () => void;
  rightSideNode?: ReactNode;
};

export default function SettingsDetailsSection<T>(props: Props<T>) {
  const isLightTheme = useSelector(isLightThemeSelector);
  const {
    title,
    description,
    formState,
    customSubmitButton,
    rightSideNode,
    onClose,
  } = props;

  return (
    <div
      className={cx('banner', {
        inverse: !isLightTheme,
      })}
    >
      <div className="banner__left">
        <div>
          {!isNil(title) && <h1 className="banner__name mb-8">{title}</h1>}
          {!isNil(description) && <h2 className='banner__description'>{description}</h2>}
        </div>
      </div>
      <div className="banner__actions">
        {formState && (
          <>
            <div>
              <Button
                onClick={onClose}
                size="large"
                className={cx({ 'dark-btn-outline': !isLightTheme })}
              >
                Cancel
              </Button>
            </div>

            {customSubmitButton || (
              <div>
                <SubmitButton
                  formState={formState}
                  className="dark-btn"
                  size="large"
                >
                  Save
                </SubmitButton>
              </div>
            )}
          </>
        )}
        {rightSideNode}
      </div>
    </div>
  );
}
