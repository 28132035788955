import React, { useEffect } from 'react';

import { UploadItemProps } from 'components/wrappers/BaseFileUpload';

import styles from '../ProfileTab.module.less';

type Props = UploadItemProps & { onUploading: (isUploading: boolean) => void };

export default function UploadButton(props: Props) {
  const { isUploading, isLoading, onUploading } = props;

  useEffect(() => {
    onUploading(isUploading || isLoading);
  }, [isUploading, isLoading]);

  return <div className={styles.btn}>Add</div>;
}
