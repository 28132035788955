import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import { AppDispatch } from 'redux/store';
import { getSpaceLastActivity } from 'redux/reducers/dashboardSlice';
import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { PageSmallSize } from 'constants/ApiConstant';
import {
  FolderChangeTypeEnum,
  ProgressTrackerChangeTypeEnum,
  SpaceLastActivityResponseModel,
} from 'api';
import { formatDate, groupActivitiesByDate } from 'utils/dashboardUtils';
import LastActivityWidget from '../LastActivityWidget';

import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

import styles from '../LastActivityWidget/LastActivityWidget.module.less';
import classes from './LastActivitySection.module.less';

export const getActivityIcon = (info: SpaceLastActivityResponseModel) => {
  if (info.folderChangeType === FolderChangeTypeEnum.FolderChanged)
    return (
      <div className={cx(classes.icon, classes.reset)}>
        <i className="icon icon-tracker" />
      </div>
    );
  if (info.folderChangeType === FolderChangeTypeEnum.Created)
    return (
      <div className={cx(classes.icon, classes.project)}>
        <i className="icon icon-work" />
      </div>
    );
  if (info.trackerChangeType === ProgressTrackerChangeTypeEnum.TrackerChanged)
    return (
      <div className={cx(classes.icon, classes.reset)}>
        <i className="icon icon-tracker" />
      </div>
    );
  if (info.trackerChangeType === ProgressTrackerChangeTypeEnum.Archived)
    return (
      <div className={cx(classes.icon, classes.archivated)}>
        <i className="icon icon-tracker" />
      </div>
    );
  if (info.trackerChangeType === ProgressTrackerChangeTypeEnum.Unarchived)
    return (
      <div className={cx(classes.icon, classes.completed)}>
        <i className="icon icon-tracker" />
      </div>
    );
  if (info.trackerChangeType === ProgressTrackerChangeTypeEnum.MarkedForDelete)
    return (
      <div className={cx(classes.icon, classes.deleted)}>
        <i className="icon icon-tracker" />
      </div>
    );
  if (info.trackerChangeType === ProgressTrackerChangeTypeEnum.Restored)
    return (
      <div className={cx(classes.icon, classes.reset)}>
        <i className="icon icon-stage" />
      </div>
    );
  if (info.trackerChangeType === ProgressTrackerChangeTypeEnum.Created)
    return (
      <div className={cx(classes.icon, classes.tracker)}>
        <i className="icon icon-tracker" />
      </div>
    );
};

export const getActivityHeadText = (info: SpaceLastActivityResponseModel) => {
  if (info.folderChangeType === FolderChangeTypeEnum.FolderChanged)
    return (
      <div>
        {info.userName} changed folder "{info.objectName}"
      </div>
    );
  if (info.folderChangeType === FolderChangeTypeEnum.Created)
    return (
      <div>
        {info.userName} created folder "{info.objectName}"
      </div>
    );
  if (info.trackerChangeType === ProgressTrackerChangeTypeEnum.TrackerChanged)
    return (
      <div>
        {info.userName} changed tracker "{info.objectName}"
      </div>
    );
  if (info.trackerChangeType === ProgressTrackerChangeTypeEnum.Archived)
    return (
      <div>
        {info.userName} archived tracker "{info.objectName}"
      </div>
    );
  if (info.trackerChangeType === ProgressTrackerChangeTypeEnum.Unarchived)
    return (
      <div>
        {info.userName} unarchived tracker "{info.objectName}"
      </div>
    );
  if (info.trackerChangeType === ProgressTrackerChangeTypeEnum.MarkedForDelete)
    return (
      <div>
        {info.userName} marked for delete tracker "{info.objectName}"
      </div>
    );
  if (info.trackerChangeType === ProgressTrackerChangeTypeEnum.Restored)
    return (
      <div>
        {info.userName} restored tracker "{info.objectName}"
      </div>
    );
  if (info.trackerChangeType === ProgressTrackerChangeTypeEnum.Created)
    return (
      <div>
        {info.userName} created tracker "{info.objectName}"
      </div>
    );
};

export default function LastActivitySection() {
  const [isLastActivitiesWidgetOpen, setIsLastActivitiesWidgetOpen] =
    useState(false);
  const [lastActivityList, setLastActivityList] = useState<
    SpaceLastActivityResponseModel[]
  >([]);
  const infiniteScrollParentRef = useRef(null);
  const dispatch: AppDispatch = useDispatch();

  const lastActivitiesLoad = async (pageNumber: number) => {
    const result = await dispatch(
      getSpaceLastActivity({
        pageSize: PageSmallSize,
        pageNumber,
      }),
    );

    if (getSpaceLastActivity.fulfilled.match(result)) {
      setLastActivityList(result.payload.details || []);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  useEffect(() => {
    lastActivitiesLoad(0);
  }, []);

  const handleLastActivitiesWidgetClose = () => {
    setIsLastActivitiesWidgetOpen(false);
  };

  const handleLastActivitiesWidgetOpen = () => {
    setIsLastActivitiesWidgetOpen(true);
  };

  const groupedActivities = groupActivitiesByDate(lastActivityList);

  const isLightTheme = useSelector(isLightThemeSelector);

  return (
    <div className={classes.activity}>
      <div className={classes.activityWrap}>
        <div className={classes.activityTitle}>
          <div>Last Activity</div>
          <div>
            <Button onClick={handleLastActivitiesWidgetOpen} type="link">
              View all activity
            </Button>
          </div>
        </div>
        {Object.keys(groupedActivities).map((date) => {
          if (!groupedActivities[date]?.length) return null;
          return (
            <div key={date}>
              <h2 className={classes.date}>{date}</h2>
              {groupedActivities[date]?.map((activity, index) => (
                <div
                  key={(activity.userId || '') + index}
                  className={classes.item}
                >
                  {getActivityIcon(activity)}
                  <div>
                    {getActivityHeadText(activity)}
                    <p>{formatDate(activity?.activityDate)}</p>
                  </div>
                </div>
              ))}
            </div>
          );
        })}
        <div
          className={cx(styles.widget, {
            [styles.dark]: !isLightTheme,
            [styles.active]: isLastActivitiesWidgetOpen,
          })}
          ref={infiniteScrollParentRef}
        >
          {isLastActivitiesWidgetOpen && (
            <LastActivityWidget
              onClose={handleLastActivitiesWidgetClose}
              innerScroll={infiniteScrollParentRef}
            />
          )}
        </div>
      </div>
    </div>
  );
}
