import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isNil } from 'lodash';

import { OnboardingPagesEnum } from 'enums/OnboardingEnums';
import BaseOnboarding from 'features/onboarding/BaseOnboarding';
import OnboardnigProgress from 'features/onboarding/OnboardingProgress';
import {
  activeTourStepSelector,
  onboardingPageSelector,
} from 'redux/selectors/onboardingSelector';
import { getHomePageOnboardingSteps } from 'utils/onboardingUtils';

const getOnboardingSteps = (page?: OnboardingPagesEnum) => {
  if (!page) return;

  switch (page) {
    //It's default because there is only one page for the onboarding now.
    // We'll need to add all pages later

    default:
      return getHomePageOnboardingSteps();
  }
};

export default function Onboarding() {
  const activeTourStep = useSelector(activeTourStepSelector);
  const onboardingPage = useSelector(onboardingPageSelector);
  const tourSteps = useMemo(
    () => getOnboardingSteps(onboardingPage),
    [onboardingPage],
  );

  if (isNil(activeTourStep)) return null;

  return (
    <BaseOnboarding id="welcomeTour">
      {!isNil(tourSteps) && <>{tourSteps[activeTourStep]}</>}

      <OnboardnigProgress />
    </BaseOnboarding>
  );
}
