import React, { useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { isNil } from 'lodash';
import { FieldError, useWatch } from 'react-hook-form';
import { Control } from 'react-hook-form/dist/types';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import { FolderResponseModel } from 'api';
import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import Controller from 'components/form/Controller';
import PTModal, { PTModalProps } from 'components/PTModal';
import { getProjectsWithoutTemplates } from 'redux/reducers/projectSlice';

const { Option } = Select;

type Props = {
  onOk: () => void;
  onCancel: () => void;
  onAddProjectModalOpen: () => void;
  control: Control;
  error?: FieldError;
  defaultProject?: FolderResponseModel;
  isTemplateFromLibrary?: boolean;
  resetField?: (fieldName: string) => void;
} & Omit<PTModalProps, 'children'>;

const NEW_PROJECT_VALUE = 'new';

export default function ProjectListModal(props: Props) {
  const {
    onOk,
    onCancel,
    onAddProjectModalOpen,
    control,
    error,
    defaultProject,
    isTemplateFromLibrary,
    resetField,
    open,
    ...otherModalProps
  } = props;
  const [projects, setProjects] = useState<FolderResponseModel[]>([]);
  const [isProjectsLoading, setProjectsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  const selectedFolderId = useWatch({ name: 'folderId', control });

  useEffect(() => {
    if (!open) return;

    getTemplateProjects();
  }, [defaultProject, open]);

  useEffect(() => {
    if (!open) return;

    if (selectedFolderId !== NEW_PROJECT_VALUE) return;

    resetField && resetField('folderId');
    onCancel();
    onAddProjectModalOpen();
  }, [selectedFolderId, open]);

  const getTemplateProjects = async () => {
    setProjectsLoading(true);
    const result = await dispatch(getProjectsWithoutTemplates());
    setProjectsLoading(false);

    if (getProjectsWithoutTemplates.fulfilled.match(result)) {
      const newProjects = result.payload.filter(
        (project) => !isNil(project.id) && !isNil(project.name),
      );
      if (defaultProject) {
        newProjects.push({
          id: defaultProject.id,
          name: defaultProject.name,
        });
      }
      setProjects(newProjects);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  const handleSave = async () => {
    setIsSaving(true);
    await onOk();
    setIsSaving(false);
  };

  const handleCloseModal = () => {
    if (!isTemplateFromLibrary || !resetField) return onCancel();
    resetField('folderId');
    onCancel();
  };

  return (
    <PTModal
      {...otherModalProps}
      open={open}
      title="Your projects"
      onCancel={handleCloseModal}
      onOk={handleSave}
      okText="Save"
      okButtonProps={{ loading: isSaving, disabled: !selectedFolderId }}
      className="modal-root"
    >
      <Form layout="vertical">
        <div className="modal-scroll-wrapper">
          <Controller
            name="folderId"
            label="Add to the project"
            placeholder="Select project"
            as={
              <Select size="large" loading={isProjectsLoading}>
                <Option value={NEW_PROJECT_VALUE}>Add to a new project</Option>
                {projects.map((project) => (
                  <Option key={project.id} value={project.id || ''}>
                    {project.name}
                  </Option>
                ))}
              </Select>
            }
            control={control}
            error={error}
          />
        </div>
      </Form>
    </PTModal>
  );
}
