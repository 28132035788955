import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  hasRequiredRoleSelector,
  hasRequiredTeammateRoleSelector,
  isAuthenticatedSelector,
} from 'redux/selectors/authenticationSelectors';
import { RolesEnum } from 'enums/Roles.enum';
import { UserSpaceRoleEnum } from 'api';
import { isTrackerManagerSelector } from 'redux/selectors/roleSelectors';

type Props = {
  allowedRoles: RolesEnum[];
  teammateRoles?: UserSpaceRoleEnum[];
};

// It redirects to the login
// screen if you're not yet authenticated.
export default function PrivateRoute(props: Props) {
  const { allowedRoles, teammateRoles = [] } = props;
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const isTeammateRole = useSelector(isTrackerManagerSelector);
  const isAllowed = useSelector(hasRequiredRoleSelector(allowedRoles));
  const hasTeammateAccessToRole = useSelector(
    hasRequiredTeammateRoleSelector(teammateRoles),
  );

  const teammateHasAccess =
    isTeammateRole && teammateRoles.length > 0 ? hasTeammateAccessToRole : true;

  if (!isAuthenticated) return <Navigate to="/login" />;

  if (!isAllowed || !teammateHasAccess) return <Navigate to="/" />;

  return <Outlet />;
}
