import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'lodash';
import { Col, Input, Row, Spin } from 'antd';
import cx from 'classnames';

import { InviteUsersFormValues } from '../InviteUsersModal';
import { PaymentMethodResponseModel, SubscriptionTypeEnum } from 'api';
import AlertMessage from 'components/AlertMessage';
import {
  AddNewCardSection,
  ChangeCreditCardSection,
} from 'components/payments';
import StripeContainer from 'components/stripe/StripeContainer';
import { onLoadSubscriptions } from 'utils/subscriptionUtils';
import {
  isSubscriptionsLoadingSelector,
  subscriptionsSelector,
} from 'redux/selectors/aссountSelectors';
import { userSelector } from 'redux/selectors/authenticationSelectors';
import { CardMode, CreditCard } from 'components/payments/CreditCard';
import { getCardList } from 'redux/reducers/accountSlice';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import { AppDispatch } from 'redux/store';
import LicensePaymentForm from './LicensePaymentForm';

import styles from './LicensePaymentSection.module.less';

type Props = {
  usersDetails: InviteUsersFormValues;
  onClose: () => void;
};

export default function LicensePaymentSection(props: Props) {
  const { usersDetails, onClose } = props;
  const [creditCards, setCreditCards] =
    useState<PaymentMethodResponseModel[]>();
  const [currentCard, setCurrentCard] = useState<PaymentMethodResponseModel>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [isAddingNewCreditCard, setIsAddingNewCreditCard] = useState(false);
  const [isChangingCurrentCard, setIsChangingCurrentCard] = useState(false);

  const subscriptions = useSelector(subscriptionsSelector);
  const isSubscriptionsLoading = useSelector(isSubscriptionsLoadingSelector);
  const user = useSelector(userSelector);
  const teammateInviteSubscription = subscriptions.find(
    (subscription) =>
      subscription.type === SubscriptionTypeEnum.TeammateInvite &&
      subscription.billingCycle === user?.billingCycle,
  );

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    onLoadSubscriptions();
  }, [dispatch]);

  const getCreditCards = async () => {
    setIsLoading(true);
    const result = await dispatch(getCardList(user?.id || ''));

    if (getCardList.fulfilled.match(result)) {
      setCreditCards(result.payload);
      setCurrentCard(result.payload[0]);
      setError(false);
    } else {
      setError(true);
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getCreditCards();
  }, []);

  const handleCardChange = () => setIsChangingCurrentCard(true);

  const handleCancelChangingCurrentCard = () => setIsChangingCurrentCard(false);

  const handleChangeCurrentCard = (
    newCurrentCard: PaymentMethodResponseModel,
  ) => {
    setCurrentCard(newCurrentCard);
    handleCancelChangingCurrentCard();
  };

  const handleAddNewCardClick = () => {
    handleCancelChangingCurrentCard();
    setIsAddingNewCreditCard(true);
  };

  const onCancelAdding = async (isAdded: boolean = false) => {
    if (isAdded) {
      await getCreditCards();
    }

    if (creditCards && creditCards?.length >= 1) {
      handleCardChange();
    }

    setIsAddingNewCreditCard(false);
  };

  if (isError) onClose();

  if (isSubscriptionsLoading || isLoading) return <Spin />;

  return (
    <StripeContainer>
      {isChangingCurrentCard && (
        <ChangeCreditCardSection
          title="Manage payment info"
          description="Add your credit card for a faster checkout process."
          onAddNewCardClick={handleAddNewCardClick}
          creditCards={creditCards}
          onChangeCurrentCard={handleChangeCurrentCard}
          onCancel={handleCancelChangingCurrentCard}
        />
      )}

      {(!currentCard || isAddingNewCreditCard) && (
        <AddNewCardSection onCancelAdding={onCancelAdding} />
      )}

      {currentCard && !isAddingNewCreditCard && !isChangingCurrentCard && (
        <>
          <div className={styles.details}>
            <div className={styles.detailsCard}>
              {!isNil(currentCard) && (
                <CreditCard
                  card={currentCard}
                  key={currentCard.id}
                  mode={CardMode.Edit}
                  onChangeCreditCard={handleCardChange}
                />
              )}
            </div>

            <div className={styles.detailsInfo}>
              <Row gutter={[10, 20]}>
                <Col xs={24}>
                  <div className={styles.label}>Name on card</div>
                  <div className={cx(styles.input, 'dark-input-group')}>
                    <Input
                      aria-label="Name"
                      disabled
                      value={currentCard.customerName}
                    />
                  </div>
                </Col>
              </Row>

              <Row gutter={[10, 0]}>
                <Col xs={24} md={12}>
                  <div className={styles.label}>Country</div>
                  <div className={cx(styles.input, 'dark-input-group')}>
                    <Input
                      disabled
                      aria-label="Country"
                      value={currentCard?.countryName || ''}
                    />
                  </div>
                </Col>

                <Col xs={24} md={12}>
                  <div className={styles.label}>ZIP/Postal code</div>
                  <div className={cx(styles.input, 'dark-input-group')}>
                    <Input
                      disabled
                      aria-label="ZIP/Postal code"
                      value={currentCard?.postalCode}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <LicensePaymentForm
            creditCardId={currentCard?.id || ''}
            onCancelPayment={onClose}
            priceId={teammateInviteSubscription?.prices?.[0].priceId}
            usersDetails={usersDetails}
          />
        </>
      )}
    </StripeContainer>
  );
}
