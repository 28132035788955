import React, { useCallback } from 'react';
import { App, Button } from 'antd';
import { useFieldArray, useFormContext } from 'react-hook-form';
import cx from 'classnames';
import { useSelector } from 'react-redux';

import { StageEdit } from 'components/ProgressTracker/Stage';
import { StageEditMode } from 'components/ProgressTracker/Stage/StageEdit';
import { generateMockId } from 'components/ProgressTracker/Stage/utils';
import { emptyStage } from 'constants/TrackerConstants';
import { UseTemplateFormValues } from 'features/template/UseTemplatesPage/UseTemplatesPage';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

import styles from 'components/ProgressTracker/ProgressTrackerEdit/ProgressTrackerEdit.module.less';

type Props = {
  trackerIndex: number;
};

export default React.memo(function (props: Props) {
  const { trackerIndex } = props;
  const isLightTheme = useSelector(isLightThemeSelector);
  const { control, formState } = useFormContext<UseTemplateFormValues>();
  const { fields, remove, swap, append } = useFieldArray({
    control,
    name: `trackers[${trackerIndex}].stages`,
    keyName: 'key',
  });
  const { errors, touched } = formState;
  const isAddingDisabled = fields.length >= 20;
  const { modal } = App.useApp();

  const handleDeleteStageClick = useCallback(
    (index: number) => {
      modal.confirm({
        content: 'Are you sure you want to delete the stage?',
        icon: <span className="icon icon-info-circle confirm-icon" />,
        className: cx('confirm-modal', {
          'confirm-modal-dark-button': !isLightTheme,
        }),
        width: '496px',
        cancelText: 'Cancel',
        okText: 'Delete',
        onOk: () => remove(index),
      });
    },
    [remove],
  );

  const handleAddStageClick = () => {
    append({ ...emptyStage, id: generateMockId(), isManuallyAdded: true });
  };

  return (
    <div style={{ width: 565, height: 430, overflowY: 'auto' }}>
      <div className="cards-list">
        {fields.map((field, index) => (
          <StageEdit
            key={field.key}
            field={field}
            fieldName={`trackers[${trackerIndex}].stages[${index}]`}
            control={control}
            index={index}
            error={errors.trackers?.[trackerIndex]?.stages?.[index]}
            touched={!!touched.trackers?.[trackerIndex]?.stages?.[index]}
            onDelete={handleDeleteStageClick}
            onDrop={swap}
            mode={StageEditMode.TemplateUse}
          />
        ))}
      </div>

      <div className={styles.btn} style={{ position: 'static' }}>
        <div className={styles.btnWrap}>
          <Button
            type="link"
            onClick={handleAddStageClick}
            disabled={isAddingDisabled}
            title={
              isAddingDisabled
                ? 'There can only be a maximum of 20 stages for each tracker'
                : undefined
            }
          >
            <div className={styles.btnAdd}>
              <div className={styles.btnCircle}>
                <i className="icon icon-plus" />
              </div>
              <div className={styles.btnText}>Add row</div>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
});
