import { Slide, toast, ToastContainerProps } from 'react-toastify';
import { isEmpty, isObject } from 'lodash';
import { PayloadAction, SerializedError } from '@reduxjs/toolkit';

import { ApiErrorMessage } from 'constants/ApiErrorMessage';

export type ErrorType = {
  errors: { [key: string]: string[] };
};

export const BASE_ALERTS_CONTAINER_ID = 'BASE_ALERTS_CONTAINER_ID';

export const DEFAULT_TOAST_DURATION = 5000;

export const ALERT_TOASTER_OPTIONS: ToastContainerProps = {
  className: 'alerts-container',
  toastClassName: 'alert-toast',
  newestOnTop: true,
  hideProgressBar: true,
  pauseOnHover: false,
  pauseOnFocusLoss: false,
  draggable: false,
  closeOnClick: false,
  autoClose: false,
  transition: Slide,
  closeButton: false,
  position: toast.POSITION.TOP_CENTER,
};

export const getErrorMessages = (
  response: PayloadAction<ErrorType | undefined, string, {}, SerializedError>,
) => {
  const errors = response.payload?.errors;

  if (isObject(errors) && !isEmpty(errors)) {
    const keys = Object.keys(errors);

    return keys
      .map((key) => {
        return errors[key].join(' ');
      })
      .join(' ');
  } else {
    return response.error.message || ApiErrorMessage;
  }
};
