import React from 'react';
import { Progress } from 'antd';
import { useSelector } from 'react-redux';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';
import cx from 'classnames';

import { FolderListResponseModel } from 'api';
import { getPercentage } from 'utils/percentageUtils';
import { getDateForUserTimezone } from 'utils/dateUtils';
import { getCorrectStrokeColor } from 'utils/trackerUtils';

type Props = {
  project: FolderListResponseModel;
};

export default function ProjectCard(props: Props) {
  const isLightTheme = useSelector(isLightThemeSelector);
  const { project } = props;
  const {
    name,
    completenessStatus = 0,
    progressTrackerTotalCount,
    createdDate,
    projectOwner,
  } = project;

  const percentage = getPercentage(completenessStatus);

  return (
    <div
      className={cx('ticket', {
        inverse: !isLightTheme,
      })}
    >
      <div className="ticket__left">
        <div className="ticket__progress">
          <Progress
            type="circle"
            percent={percentage}
            format={(percent) => `${percent}%`}
            strokeColor={getCorrectStrokeColor(percentage)}
            strokeWidth={10}
            size={54}
          />
        </div>
        <div>
          <div>
            <div className="ticket__name">{name}</div>
            <div className="ticket__item">
              <span>Trackers:</span> {progressTrackerTotalCount}
            </div>
          </div>
          <div>
            <div className="ticket__item">
              <span>Created date: </span>
              {getDateForUserTimezone(createdDate)}
            </div>
            <div className="ticket__item">
              <span>Owner: </span> {projectOwner}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
