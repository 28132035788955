import React, { RefObject } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

import { SubscriptionResponseModel, SubscriptionTypeEnum } from 'api';
import SubscriptionButton from 'components/SubscriptionButton';
import { featureList } from 'constants/PricingConstants';
import { userSelector } from 'redux/selectors/authenticationSelectors';

import styles from './PricingTableDesktop.module.less';

export type Prices = {
  basicPrice?: number;
  standardPrice?: number;
  advancedPrice?: number;
};

export type SubscriptionTypes = {
  basicSubscription?: SubscriptionResponseModel;
  standardSubscription?: SubscriptionResponseModel;
  advancedSubscription?: SubscriptionResponseModel;
  enterpriseSubscription?: SubscriptionResponseModel;
};

type Props = {
  prices: Prices;
  subscriptions: SubscriptionTypes;
  onUpgradeSubscription?: (subscription: SubscriptionResponseModel) => void;
  onDowngradeSubscription?: (subscription: SubscriptionResponseModel) => void;
  tableRef?: RefObject<HTMLDivElement>;
  onScroll?: (e: React.UIEvent<HTMLDivElement>) => void;
};

export default function PricingTableDesktop(props: Props) {
  const isLightTheme = useSelector(isLightThemeSelector);
  const {
    prices,
    subscriptions,
    onUpgradeSubscription,
    onDowngradeSubscription,
    tableRef,
    onScroll,
  } = props;
  const { basicPrice, standardPrice, advancedPrice } = prices;
  const {
    basicSubscription,
    standardSubscription,
    advancedSubscription,
    enterpriseSubscription,
  } = subscriptions;
  const user = useSelector(userSelector);

  const isBasicPlan = user?.subscriptionType === SubscriptionTypeEnum.Basic;
  const isStandardPlan =
    user?.subscriptionType === SubscriptionTypeEnum.Standard;
  const isAdvancedPlan =
    user?.subscriptionType === SubscriptionTypeEnum.Advanced;
  const isEnterprisePlan =
    user?.subscriptionType === SubscriptionTypeEnum.Enterprise;

  const isSubscriptionBtnAvailable =
    onDowngradeSubscription && onUpgradeSubscription;

  return (
    <div className={cx(styles.tableWrap)} ref={tableRef}>
      <div className={cx(styles.table, { [styles.tableDark]: !isLightTheme })}>
        <div className={styles.tableHead}>
          <div className={cx(styles.tableRow, styles.fake)}></div>
          <div className={styles.tableRow}>
            <div className={cx(styles.tableCell, styles.side)}>
              <div className={cx(styles.head, styles.side)}>
                <div>FEATURES & FUNCTIONALITY</div>
              </div>
            </div>
            <div className={styles.tableCell}>
              <div
                className={cx(styles.head, {
                  [styles.current]: isBasicPlan,
                })}
              >
                <div>{basicSubscription?.name}</div>
                <div className={styles.headPrice}>
                  ${basicPrice} <span>/ Month</span>
                </div>
                <div className={styles.noSubscriptionBlock}></div>
              </div>
            </div>
            <div className={styles.tableCell}>
              <div
                className={cx(styles.head, {
                  [styles.current]: isStandardPlan,
                })}
              >
                <div>{standardSubscription?.name}</div>
                <div className={styles.headPrice}>
                  ${standardPrice} <span>/ Month</span>
                </div>
                <div
                  className={
                    isSubscriptionBtnAvailable
                      ? styles.headBtn
                      : styles.noSubscriptionBlock
                  }
                >
                  {onDowngradeSubscription && onUpgradeSubscription && (
                    <SubscriptionButton
                      className="dark-btn"
                      block
                      subscription={standardSubscription}
                      onUpgrade={onUpgradeSubscription}
                      onDowngrade={onDowngradeSubscription}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={cx(styles.tableCell, styles.label)}>
              <div
                className={cx(styles.head, {
                  [styles.current]: isAdvancedPlan,
                })}
              >
                <div>{advancedSubscription?.name}</div>
                <div className={styles.headPrice}>
                  ${advancedPrice} <span>/ Month</span>
                </div>

                <div
                  className={
                    isSubscriptionBtnAvailable
                      ? styles.headBtn
                      : styles.noSubscriptionBlock
                  }
                >
                  {onDowngradeSubscription && onUpgradeSubscription && (
                    <SubscriptionButton
                      className="dark-btn"
                      block
                      subscription={advancedSubscription}
                      onUpgrade={onUpgradeSubscription}
                      onDowngrade={onDowngradeSubscription}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={styles.tableCell}>
              <div
                className={cx(styles.head, {
                  [styles.current]: isEnterprisePlan,
                })}
              >
                <div>{enterpriseSubscription?.name}</div>

                <div
                  className={
                    isSubscriptionBtnAvailable
                      ? styles.headBtn
                      : styles.noSubscriptionBlock
                  }
                >
                  {onDowngradeSubscription && onUpgradeSubscription && (
                    <SubscriptionButton
                      className="dark-btn"
                      block
                      subscription={enterpriseSubscription}
                      onUpgrade={onUpgradeSubscription}
                      onDowngrade={onDowngradeSubscription}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.tableBody}>
          {featureList.map((feature, index) => {
            if (feature.title) {
              return (
                <div key={index} className={cx(styles.tableRow, styles.fake)}>
                  {feature.title}
                </div>
              );
            } else {
              return (
                <div key={index} className={styles.tableRow}>
                  <div className={cx(styles.tableCell, styles.side)}>
                    {feature.featureName}
                  </div>
                  <div className={styles.tableCell}>{feature.basic}</div>
                  <div className={styles.tableCell}>{feature.standard}</div>
                  <div className={cx(styles.tableCell, styles.label)}>
                    {feature.advanced}
                  </div>
                  <div className={styles.tableCell}>{feature.enterprise}</div>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}
