import { useState } from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import cx from 'classnames';

import { unsubscribeTrackerUpdates } from 'redux/reducers/progressTrackerSlice';
import { AppDispatch } from 'redux/store';
import AlertMessage from 'components/AlertMessage';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';

import styles from './UnsubscribeFromUpdate.module.less';
import img from 'assets/img/unsubscribe.png';

type Params = {
  subscribeId: string;
};

export default function UnsubscribeFromUpdate() {
  const [isUnsubscribed, setIsUnsubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { subscribeId } = useParams<Params>();
  const dispatch: AppDispatch = useDispatch();

  const handleUnsubscribe = async () => {
    setIsLoading(true);
    const result = await dispatch(unsubscribeTrackerUpdates(subscribeId || ''));

    if (unsubscribeTrackerUpdates.fulfilled.match(result)) {
      setIsUnsubscribed(true);
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
    setIsLoading(false);
  };

  return (
    <div className="page-wrapper scroll-box ">
      <div className="page-box page-holder">
        {!isUnsubscribed && (
          <div className={styles.unsubscribe}>
            <div className={styles.unsubscribeBox}>
              <div className={styles.unsubscribeImg}>
                <img src={img} alt="logo" />
              </div>
              <div className={styles.unsubscribeText}>
                <div className={styles.unsubscribeTitle}>
                  Are you sure you want to unsubscribe?
                </div>
                <div>
                  If you unsubscribe, you will stop receiving status updates.
                </div>
              </div>
            </div>
            <div className={styles.unsubscribeBtn}>
              <div>
                <Button
                  size="large"
                  className="yellow-btn"
                  loading={isLoading}
                  onClick={handleUnsubscribe}
                >
                  Unsubscribe
                </Button>
              </div>
            </div>
          </div>
        )}

        {isUnsubscribed && (
          <div className={cx(styles.unsubscribe, styles.successfully)}>
            <div className={styles.unsubscribeBox}>
              <i className="icon icon-triangle" />
              <div className={styles.unsubscribeTitle}>
                Unsubscribed successfully
              </div>
            </div>
            <div>
              You will no longer receive progress updates in the future.
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
