import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { App, Button, Form, Input } from 'antd';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import cx from 'classnames';

import { AppDispatch } from 'redux/store';
import Controller from 'components/form/Controller';
import { forgotPassword } from 'redux/reducers/authenticationSlice';
import { ApiErrorMessage } from 'constants/ApiErrorMessage';
import AlertMessage from 'components/AlertMessage';
import { isLightThemeSelector } from 'redux/selectors/themeSelectors';

import styles from './ForgotPasswordPage.module.less';
import commonStyles from '../auth.module.less';

import imgLogo from 'assets/img/landing/apdated-logo.svg';

interface FormValues {
  email: string;
}

const validationSchema = Yup.object({
  email: Yup.string()
    .required('Field is required')
    .email('Email format is invalid')
    .max(120, 'Email should be 120 characters maximum'),
});

export default function ForgotPasswordPage() {
  const dispatch: AppDispatch = useDispatch();
  const { modal } = App.useApp();
  const isLightTheme = useSelector(isLightThemeSelector);

  const { handleSubmit, control, errors, formState, reset } =
    useForm<FormValues>({
      resolver: yupResolver(validationSchema),
      mode: 'all',
      defaultValues: {
        email: '',
      },
    });
  const { isDirty, isSubmitting, isValid } = formState;
  const navigate = useNavigate();

  const handleBackToLogin = (e: React.MouseEvent) => {
    if (isDirty) {
      e.preventDefault();

      return modal.confirm({
        title: 'Changes won’t be saved. Are you sure?',
        icon: <span className="icon icon-info-circle confirm-icon" />,
        className: cx('confirm-modal', {
          'confirm-modal-dark-button': !isLightTheme,
        }),
        width: '496px',
        onOk() {
          navigate(`/login`);
        },
        onCancel() {},
      });
    }
  };

  const onSubmit = async ({ email }: FormValues) => {
    const result = await dispatch(
      forgotPassword({
        email,
      }),
    );

    if (forgotPassword.fulfilled.match(result)) {
      AlertMessage.success(
        `We have sent password reset instructions to ${email}. If no email is received, please try again.`,
      );
      reset();
    } else {
      AlertMessage.error(result.error.message || ApiErrorMessage);
    }
  };

  return (
    <div className={commonStyles.authPage}>
      <div className={commonStyles.authWrap}>
        <div className={commonStyles.authRow}>
          <div className={commonStyles.authForm}>
            <div className={commonStyles.authFormWrap}>
              <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
                <div className={commonStyles.authFormBox}>
                  <div className={commonStyles.authFormLogo}>
                    <img src={imgLogo} alt="logo" />
                  </div>

                  <div className={commonStyles.authFormTitle}>
                    Reset password
                  </div>
                  <Controller
                    name="email"
                    control={control}
                    as={Input}
                    label="Email address"
                    size="large"
                    error={errors.email}
                    containerClassName="dark-input-group"
                  />

                  <div className={styles.forgotCaption}>
                    Please enter your email address to reset your password.
                  </div>

                  <div className={commonStyles.authBtnBox}>
                    <div className={commonStyles.authBtn}>
                      <Link onClick={handleBackToLogin} to="/login">
                        <Button block>Back to Log In</Button>
                      </Link>
                    </div>
                    <div className={commonStyles.authBtn}>
                      <Button
                        loading={isSubmitting}
                        disabled={!isValid}
                        htmlType="submit"
                        type="primary"
                        block
                      >
                        Reset Password
                      </Button>
                    </div>
                  </div>
                  <div className={styles.forgotContact}>
                    Still having trouble? Email us:{' '}
                    <a href="mailto:support@apdated.co">support@apdated.co</a>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
